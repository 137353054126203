import React from 'react';
import { IonContent, IonActionSheet } from '@ionic/react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface ConfirmationProps {
  isAlertActive: boolean;
  closeAlert: () => void;
  discardChanges: () => void;
}

type Props = WithTranslation & ConfirmationProps;

const ActionConfirmationAlert: React.FunctionComponent<Props> = ({ isAlertActive, closeAlert, discardChanges, t }: Props) => {
  return (
    <>
      <IonContent>
        <IonActionSheet
          isOpen={isAlertActive}
          header={t('post.discard-changes')}
          onDidDismiss={closeAlert}
          buttons={[
            {
              text: t('post.discard'),
              role: 'destructive',
              handler: discardChanges,
            },
            {
              text: t('post.keep'),
              role: 'cancel',
              handler: closeAlert,
            },
          ]}
        />
      </IonContent>
    </>
  );
};

export default withTranslation()(ActionConfirmationAlert);
