import { isTest } from '../../environment';

export const postListRadius = isTest ? [0, 10] : [20000, 50000]; // Radius in km

export const itemsPerPage = isTest ? 8 : 12; // Number of item per page when fetching posts

export const minPostsBeforeMaxRadiusCard = isTest ? 8 : 20; // The minimum number of posts before the maxRadiusSocialCard
export const postsBeforeTreeOrPromotionalCard = 12; // The number of posts to display before the TreeSocialCard

export const socialCardSlotSize = 2; // The number of slots occupied by a socialCard in the postGrid
export const monthsBeforeDeadlinePostReached = 6; // Number of month before notifying the user that their post will be deleted
export const monthsBeforeDeletePost = 8; // Number of month before post will be deleted

export const searchQueryKeys = [
  'searchText',
  'postType',
  'categorySelection[object]',
  'categorySelection[service]',
  'dateOrder',
  'locationCenter[latitude]',
  'locationCenter[longitude]',
  'locationRadius',
  'locationAddress',
  'itemsPerPage',
  'createdByManagedDataType',
  'createdByManagedDataTypeCertified',
  'createdByManaged',
];
