import { IonModal, IonButton, IonToolbar, IonTitle, IonHeader, IonContent, IonTextarea, IonButtons, IonItem, IonLabel, IonAlert, IonText } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { ReviewType, User } from '../../store/users/types';
import UserItem from '../UserItem';
import { actions } from '../../store';
import { EntityReference } from '../../utils/hydra';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import './AddReviewModal.scss';
import { extractId } from '../../utils/helpers';
import { PostRequest } from '../../store/postRequests/types';
import i18next from 'i18next';
import { sendReviewLog } from '../../utils/analytics/analyticsHelper';

interface Props {
  isOpen: boolean;
  reviewType?: ReviewType;
  reviewedUser?: User;
  reviewedPostRequest?: PostRequest;
  closeModal: () => void;
  setReviewType?: (reviewType: ReviewType) => void;
  userIsRequester: boolean;
}

interface ReviewModalState {
  comment?: string;
  isSubmitAlertOpen: boolean;
  submitButtonIsLoading: boolean;
}

interface DispatchProps {
  addUserReview: (reviewedUser: EntityReference, reviewType: ReviewType, reviewedPostRequest?: EntityReference, comment?: string) => Promise<void>;
  setToastMessage(message: string | null): void;
}

const propsToDispatch = {
  addUserReview: actions.users.addUserReview,
  setToastMessage: actions.layout.setToastMessageAction,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type ReviewModalProps = Props & DispatchProps;

class AddReviewModal extends Component<ReviewModalProps, ReviewModalState> {
  constructor(props: ReviewModalProps) {
    super(props);
    this.state = {
      comment: undefined,
      isSubmitAlertOpen: false,
      submitButtonIsLoading: false,
    };
  }

  public render(): ReactNode {
    const submitDisabled = (!this.state.comment && this.props.reviewType === 'negative') || !this.props.reviewType || this.state.submitButtonIsLoading;

    return (
      <>
        <IonModal isOpen={this.props.isOpen} onDidDismiss={this.closeModal} className="review-modal safe-area-ios--large-screen">
          <IonHeader translucent>
            <IonToolbar className="header">
              <IonButton color="primary" fill="clear" onClick={this.closeModal}>
                <Trans i18nKey="common.cancel" />
              </IonButton>
              <IonTitle className="title">
                <Trans i18nKey={this.props.reviewType ? `review.${this.props.reviewType}-review` : 'review.personal-review'} />
              </IonTitle>
              <IonButtons slot="end" onClick={this.verifyIfUserNeedsToComment}>
                <IonButton disabled={submitDisabled} className="cancel-btn" fill="clear" onClick={this.submitReview}>
                  <Trans i18nKey="common.submit" />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            {this.props.setReviewType ? (
              <div className="recommend-actions">
                <IonText>
                  <Trans i18nKey="chat.would-you-recommend" values={{ userName: this.props.reviewedUser?.name }} />
                </IonText>
                <IonItem lines="none">
                  <IonButton
                    fill={this.props.reviewType === 'negative' ? 'solid' : 'outline'}
                    color="primary"
                    size="small"
                    onClick={() => {
                      if (this.props.setReviewType) this.props.setReviewType('negative');
                    }}
                  >
                    <Trans i18nKey="common.no" />
                  </IonButton>
                  <IonButton
                    fill={this.props.reviewType === 'positive' ? 'solid' : 'outline'}
                    color="primary"
                    size="small"
                    onClick={() => {
                      if (this.props.setReviewType) this.props.setReviewType('positive');
                    }}
                  >
                    <Trans i18nKey="common.yes" />
                  </IonButton>
                </IonItem>
              </div>
            ) : (
              <>
                <UserItem user={this.props.reviewedUser} />
                <span />
              </>
            )}

            <IonItem mode="md" className="review-textarea">
              <IonLabel position="floating" className="review-textarea-label">
                <Trans i18nKey={!this.props.reviewType ? 'review.share-exp-with-user' : 'review.write-review'} values={{ userName: this.props.reviewedUser?.name }} />
              </IonLabel>
              <IonTextarea autocapitalize="sentences" value={this.state.comment} rows={4} onIonChange={this.updateComment} />
            </IonItem>
          </IonContent>

          <IonAlert
            cssClass="confirmation-alert"
            onDidDismiss={() => this.setState({ isSubmitAlertOpen: false })}
            header={i18next.t('review.confirm-submit')}
            message={i18next.t('review.confirm-submit-text')}
            buttons={[
              {
                text: i18next.t('chat.not-yet'),
                role: 'cancel',
                cssClass: 'secondary',
              },
              {
                text: i18next.t('common.yes'),
                handler: this.submitReview,
              },
            ]}
            isOpen={this.state.isSubmitAlertOpen}
          />
        </IonModal>
      </>
    );
  }

  private submitReview = (): void => {
    if (!this.props.reviewType) return;
    const reviewedPostRequestId = this.props.reviewedPostRequest ? extractId(this.props.reviewedPostRequest) : undefined;
    const comment = !this.state.comment ? undefined : this.state.comment;
    this.setState({ submitButtonIsLoading: true }, () => {
      this.props.addUserReview(extractId(this.props.reviewedUser), this.props.reviewType as ReviewType, comment, reviewedPostRequestId).finally(this.closeModal);
      sendReviewLog(!this.props.reviewedPostRequest?.userReviews || this.props.reviewedPostRequest?.userReviews.totalItems === 0, this.props.userIsRequester, comment);
    });
  };

  private verifyIfUserNeedsToComment = (): void => {
    if (this.props.reviewType === 'negative' && !this.state.comment) {
      this.props.setToastMessage(i18next.t('review.comment-is-required', { userName: this.props.reviewedUser?.name }));
    }
  };

  private closeModal = (): void => {
    this.setState({ comment: undefined, submitButtonIsLoading: false });
    this.props.closeModal();
  };

  private updateComment = (e: CustomEvent): void => {
    this.setState({ comment: e.detail !== null ? e.detail.value : undefined });
  };
}

export default connect(null, mapDispatchToProps)(AddReviewModal);
