import { IonCard, IonCol } from '@ionic/react';
import React from 'react';
import { ManagedUserInfos } from '../store/layout/types';
import { openUrlInNewTabIfExternalUrl } from '../utils/helpers';
import { useHistory } from 'react-router';
import './FirstCellCard.scss';

interface PromotionalCardProps {
  managedUserInfos?: ManagedUserInfos;
}

const FirstCellCard: React.FunctionComponent<PromotionalCardProps> = ({ managedUserInfos }: PromotionalCardProps) => {
  const history = useHistory();

  if (!managedUserInfos?.firstCellImage) {
    return null;
  }

  const onPromotionalCardClick = (): void => {
    if (!managedUserInfos?.firstCellRedirectUrl) {
      return;
    }
    openUrlInNewTabIfExternalUrl(managedUserInfos.firstCellRedirectUrl, history);
  };

  const style = {
    backgroundImage: `url(${managedUserInfos.firstCellImage})`,
    cursor: managedUserInfos.firstCellRedirectUrl ? 'pointer' : 'normal',
  };

  return (
    <IonCol size="6" size-lg="3">
      <IonCard className="first-cell-image" button data-cy="first-cell" style={style} onClick={onPromotionalCardClick} />
    </IonCol>
  );
};

export default React.memo(FirstCellCard);
