import { IonGrid, IonIcon, IonLabel, IonRow } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { actions, RootState } from '../store';
import { Category } from '../store/categories/types';
import { FullPost, Location } from '../store/posts/types';
import { Thematic } from '../store/thematics/types';
import { extractId, isEqualIgnoringFunctions } from '../utils/helpers';
import { ItemsCollection } from '../utils/hydra';
import { getItemTranslation } from '../utils/translation';
import { SuggestionListCollectionPlaceholder } from './placeholders/SuggestionListPlaceholder';
import PostsSlider from './PostsSlider';
import { Trans } from 'react-i18next';
import IonColInView from './IonColInView';
import { getThematicCollection } from '../store/posts/selectors';
import { SEARCH_TAB } from '../utils/TabNames';
import { createIsCurrentUserFunc, isCurrentUserFunc } from '../store/app/selectors';
import { getCategories } from '../store/categories/selectors';

import './SuggestionList.scss';

interface SuggestionListProps {
  thematic: Thematic;
  currentPage: string;
  thematicNumber?: number;
  locationData?: Location;
  postsSize?: 'small' | 'large';
}

interface SuggestionListStateProps {
  isCurrentUser: isCurrentUserFunc;
  collection: ItemsCollection<FullPost> | undefined;
  categories: Category[];
}

interface DispatchProps {
  fetchPostsByCategory: (collectionName: string, thematicCategories: Category[], loadNextPage: boolean, sortByCreatedDate?: boolean, postTypes?: string[]) => Promise<void>;
}

const mapStateToProps = (state: RootState, ownProps: SuggestionListProps): SuggestionListStateProps => ({
  isCurrentUser: createIsCurrentUserFunc(state),
  collection: getThematicCollection(state, ownProps.thematic.name),
  categories: getCategories(state),
});

const propsToDispatch = {
  fetchPostsByCategory: actions.posts.fetchPostsByCategories,
};

type ListProps = SuggestionListProps & SuggestionListStateProps & DispatchProps;

// TODO: refactor and clean the component
class SuggestionList extends Component<ListProps> {
  public constructor(props: ListProps) {
    super(props);
  }

  public componentDidMount(): void {
    this.fetchSuggestionPostsBySelectedCategory(this.props.thematic);
  }

  public shouldComponentUpdate(nextProps: Readonly<ListProps>): boolean {
    return !isEqualIgnoringFunctions(nextProps, this.props);
  }

  public componentDidUpdate(prevProps: Readonly<ListProps>): void {
    if (this.props.categories.length && !prevProps.categories.length) {
      this.fetchSuggestionPostsBySelectedCategory(this.props.thematic);
    }
  }

  private fetchSuggestionPostsBySelectedCategory(thematic: Thematic, loadNextPage = false): void {
    const categories: Category[] = this.props.categories.filter(category => thematic.categories.includes(extractId(category)));
    const sortByCreatedDate = !!this.props.locationData;
    if (!categories.length) {
      return;
    }

    const postTypes = this.props.currentPage !== SEARCH_TAB ? ['offer'] : ['offer', 'need'];
    this.props.fetchPostsByCategory(thematic.name, categories, loadNextPage, sortByCreatedDate, postTypes);
  }

  public render(): ReactNode {
    const { thematic, thematicNumber, currentPage, locationData, postsSize, collection } = this.props;

    // If there is no suggestions, we do not show the empty component
    if (!collection || collection.totalItems === 0) {
      return null;
    }

    const suggestionsGridComponent = (
      <IonGrid className="suggestions-grid">
        {currentPage === SEARCH_TAB && (
          <div className="suggestions-thematic-title">
            <div className="suggestions-thematic-number" style={{ background: '#' + thematic.color }}>
              {thematicNumber}
            </div>
            <IonLabel>{getItemTranslation(thematic, thematic.name)}</IonLabel>
          </div>
        )}
        <IonRow className="suggestions-slider">
          {collection.isLoading && <SuggestionListCollectionPlaceholder />}
          {!collection.isLoading && <PostsSlider collectionName={thematic.name} postsCollection={collection} currentLocation={locationData} postsSize={postsSize || 'small'} />}
        </IonRow>
      </IonGrid>
    );

    if (currentPage !== SEARCH_TAB) {
      return (
        <IonColInView height={418} size="12" className="suggestions-wrapper">
          <h3 className="suggestions-title">
            <Trans i18nKey="post.suggestions" />
            <span className="align-button">
              <IonIcon icon="/assets/navigation/arrow-forward-outline.svg" />
            </span>
          </h3>
          <div className="clear-both" />
          {suggestionsGridComponent}
        </IonColInView>
      );
    }

    return <div className="suggestions-wrapper">{suggestionsGridComponent}</div>;
  }
}

export default connect(mapStateToProps, propsToDispatch)(SuggestionList);
