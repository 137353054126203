import { applyMiddleware, compose, createStore, Middleware, Store } from 'redux';
import * as schema from './normalizrSchema';
import thunk from 'redux-thunk';
import { createMigrate, persistReducer, persistStore, PersistConfig } from 'redux-persist';
import localforage from 'localforage';

import rootReducer, { RootState } from './root-reducer';
import { getMiddlewares } from './index';
import { PersistPartial } from './types';
import { resetAppAction } from './app/actions';
import migrations from './migrations';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const storeMiddlewares: Middleware[] = [thunk.withExtraArgument({ schema }), ...getMiddlewares()];

const persistConfig: PersistConfig<RootState> = {
  key: 'root',
  storage: localforage,
  blacklist: ['layout', 'map', 'navigation', 'posts'],
  version: 2,
  migrate: createMigrate(migrations),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

function configureStore(initialState?: RootState & PersistPartial): Store<RootState> {
  return createStore(persistedReducer, initialState, composeEnhancers(applyMiddleware(...storeMiddlewares)));
}

// export store singleton instance
// pass an optional param to rehydrate state on app start
export const store = configureStore();
export const persistor = persistStore(store, {}, () => {
  const state = store.getState();

  if (state.app.isAuthenticationRequestLoading) {
    store.dispatch(resetAppAction());
  }
});
