import React, { PureComponent, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IonButton, IonIcon, IonPage, IonText } from '@ionic/react';
import * as Yup from 'yup';
import { appIsDisabled } from '../environment';
import DynamicForm, { FormFields, FormValues } from '../components/DynamicForm';
import { UserFormValues } from '../components/UserForm';
import { FormikHelpers } from 'formik';
import get from 'lodash/get';
import { actions, RootState } from '../store';
import InputPasswordToggle from '../components/inputs/InputPasswordToggle';
import { CurrentUser } from '../store/users/types';
import { getCurrentUser } from '../store/app/selectors';
import { extractId } from '../utils/helpers';

import '../components/ForgotPasswordModal.scss';

interface State {
  errors: Record<string, unknown>;
  errorMessage: string;
  field: string;
  formSubmitted: boolean;
}

interface DispatchProps {
  requestResetPasswordAction(plainPassword: string, token: string): void;
}

const propsToDispatch = {
  requestResetPasswordAction: actions.app.requestResetPasswordAction,
};

interface StoreProps {
  currentUser: CurrentUser;
}

const mapStateToProps = (state: RootState): StoreProps => ({
  currentUser: getCurrentUser(state),
});

type Props = StoreProps & WithTranslation & DispatchProps & RouteComponentProps<Record<string, never>>;

class ResetPasswordPage extends PureComponent<Props, State> {
  static constraints = {
    passwordMinLength: 8,
  };

  allFields: FormFields = [
    {
      label: 'login.reset-password-new',
      name: 'plainPassword',
      formComponent: InputPasswordToggle,
      options: { autocapitalize: 'off', type: 'password', required: true, clearOnEdit: false, autocomplete: 'new-password', clearInput: true },
      withoutIonItem: true,
    },
    {
      label: 'login.reset-password-confirm',
      name: 'confirmPassword',
      formComponent: InputPasswordToggle,
      options: { autocapitalize: 'off', type: 'password', required: true, clearOnEdit: false, autocomplete: 'new-password', clearInput: true, textWrap: true },
      withoutIonItem: true,
    },
  ];

  validationSchema = Yup.object().shape({
    plainPassword: Yup.string().min(ResetPasswordPage.constraints.passwordMinLength, 'login.reset-password-error-length').required('user.password-required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('plainPassword'), ''], 'login.reset-password-error-not-match')
      .required('user.confirm-password-required'),
  });

  state = {
    errors: {},
    errorMessage: '',
    field: '',
    formSubmitted: false,
  };

  public render(): ReactNode {
    const { errorMessage, formSubmitted } = this.state;
    const { t } = this.props;

    if (extractId(this.props.currentUser)) {
      return (
        <IonPage className="reset-password-page light-page light-page-display forgot-password" data-cy="reset-password-page">
          <IonIcon icon="/assets/navigation/close.svg" className="close-icon cursor-pointer" onClick={() => this.props.history.push('/me')} />
          <header>
            <h1>
              <Trans i18nKey="login.forgot-password-title" />
            </h1>

            <hr />
          </header>

          <IonText className="ion-margin">
            <Trans i18nKey="login.reset-password-already-logged-in" values={{ username: this.props.currentUser.name, email: this.props.currentUser.email }} />
          </IonText>

          <IonButton shape="round" routerLink="/me/password">
            <Trans i18nKey="user.edit-password" />
          </IonButton>
        </IonPage>
      );
    }

    return (
      <IonPage className="reset-password-page light-page light-page-display" data-cy="reset-password-page">
        <div className="forgot-password">
          {/* TODO: Resize the icon to be in 20px x 20px dimension */}
          {!appIsDisabled && <IonIcon icon="/assets/navigation/close.svg" className="close-icon cursor-pointer" onClick={() => this.props.history.push('/login')} />}
          <header>
            <h1>
              <Trans i18nKey="login.reset-password-title" />
            </h1>

            <hr />
          </header>

          <IonText className="info-msg" color="ion-color-dark">
            <div>
              <Trans i18nKey={!formSubmitted ? 'login.reset-password-text' : 'login.reset-password-text-form-submitted'} />
            </div>
          </IonText>

          {!formSubmitted && (
            <div>
              <div data-cy="error-msg" style={{ visibility: errorMessage ? 'visible' : 'hidden' }} className="error-msg">
                <IonText color="light">{errorMessage}</IonText>
              </div>
              <DynamicForm
                onSubmitErrors={this.onSubmitErrors}
                onAfterSubmitFormSuccess={this.onAfterSubmitFormSuccess}
                onSubmitForm={this.submitForm}
                onFieldChange={this.handleInputChange}
                formFields={this.allFields}
                validationSchema={this.validationSchema}
                initialValues={{ plainPassword: '', confirmPassword: '' }}
                submitButtonText={t('login.reset-password-submit')}
                onBlur={this.onBlur}
              />
            </div>
          )}
        </div>
      </IonPage>
    );
  }

  private onBlur = (field: string, values: Readonly<FormValues>): void => {
    const { t } = this.props;

    this.validationSchema
      .validate(values, { abortEarly: false })
      .then(() => {
        this.setState({ errorMessage: '' });
      })
      .catch(value => {
        this.setState({ errorMessage: t(get(value, 'errors[0]', 'user.password-required')) });
      });
  };

  private handleInputChange = (): void => {
    if (this.state.errorMessage) {
      this.setState({
        errorMessage: '',
      });
    }
  };

  private onAfterSubmitFormSuccess = async (values: Readonly<UserFormValues>, actions: FormikHelpers<FormValues>): Promise<void> => {
    actions.resetForm({ values: { ...values, plainPassword: '', confirmPassword: '' } });

    if (appIsDisabled) {
      this.setState({ formSubmitted: true });
    } else {
      this.props.history.push('/login');
    }
  };

  private submitForm = async (values: Readonly<UserFormValues>): Promise<void> => {
    if (!values.plainPassword || !values.confirmPassword) {
      return;
    }

    return this.props.requestResetPasswordAction(values.confirmPassword, this.props.history.location.search.replace('?token=', ''));
  };

  private onSubmitErrors = async (actions: FormikHelpers<FormValues>, errors: Record<string, string>): Promise<void> => {
    this.setState({ errorMessage: get(errors, '_error', 'Error') });
  };
}

export default connect(mapStateToProps, propsToDispatch)(withTranslation()(withRouter(ResetPasswordPage)));
