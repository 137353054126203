import { createAction, createAsyncAction } from 'typesafe-actions';
import { fetchWithAuthAsWhoAmI } from '../../utils/authDataAccess';
import { flattenCurrentUser } from '../app/actions';
import { normalizeAndDispatchEntity } from '../entities/selectors';
import { ThunkResult } from '../types';
import { createCurrentUserByDefaultKeys, createUserByDefaultKeys } from '../users/reducer';
import { CurrentUser, CurrentUserApiFields, User } from '../users/types';
import { JWTToken } from '../app/types';
import { parseHydraCollection } from '../../utils/helpers';
import { CollectionResponse, HydraCollectionResponse } from '../../utils/hydra';
import * as appActions from '../app/actions';
import { Dispatch } from 'redux';
import { RootState } from '../root-reducer';

export const updateWhoAmIAction = createAction('currentUser/UPDATE_WHO_AM_I', (whoAmI: CurrentUser) => whoAmI)();

export const updateWhoAmITokenAction = createAction('currentUser/UPDATE_WHO_AM_I_TOKEN', (whoAmIToken: JWTToken) => whoAmIToken)();

export const fetchManagedUsersAction = createAsyncAction('currentUser/FETCH_MANAGED_USERS_REQUEST', 'currentUser/FETCH_MANAGED_USERS_SUCCESS', 'currentUser/FETCH_MANAGED_USERS_FAILURE')<
  void,
  CollectionResponse<User>,
  Error
>();

export const addExistingManagedUser = createAsyncAction(
  'currentUser/ADD_EXISTING_MANAGED_USER_REQUEST',
  'currentUser/ADD_EXISTING_MANAGED_USER_SUCCESS',
  'currentUser/ADD_EXISTING_MANAGED_USER_FAILURE',
)<void, CurrentUser, Error>();

export const addOrUpdateManagedUserAction = createAction('currentUser/ADD_UPDATE_MANAGED_USER', (managedUser: User) => managedUser)();

export function authenticateAsWhoAmI() {
  return (dispatch: Dispatch, getState: () => RootState): void => {
    if (!getState().whoAmI.whoAmIToken) {
      console.error('Unable to authenticate as WhoAmI, as the whoAmIToken is not set.');
      return;
    }
    dispatch(appActions.managedUserLoginAction.success(getState().whoAmI.whoAmIToken));
    dispatch(appActions.resetAppForNewUserAction());
  };
}

export function fetchManagedUsers(): ThunkResult<Promise<User[]>> {
  return async (dispatch): Promise<User[]> => {
    dispatch(fetchManagedUsersAction.request());

    return fetchWithAuthAsWhoAmI('/users/me/managed_users', { method: 'GET' })
      .then(response => response.json() as Promise<HydraCollectionResponse<User>>)
      .then(data => parseHydraCollection(data))
      .then(data => {
        dispatch(fetchManagedUsersAction.success(data));
        return data.items;
      })
      .catch(e => {
        dispatch(fetchManagedUsersAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function addNewManagedUser(managedDataId: number, plainPassword: string): ThunkResult<Promise<CurrentUser>> {
  return async (dispatch): Promise<CurrentUser> => {
    dispatch(addExistingManagedUser.request());

    return fetchWithAuthAsWhoAmI('/users/me/managed_users', { method: 'POST', data: { managedDataId, plainPassword } })
      .then(response => response.json() as Promise<CurrentUserApiFields>)
      .then(data => flattenCurrentUser(data))
      .then(data => {
        dispatch(addExistingManagedUser.success(createCurrentUserByDefaultKeys(data, true)));
        normalizeAndDispatchEntity(createUserByDefaultKeys(data), dispatch);
        return data;
      })
      .catch(e => {
        dispatch(addExistingManagedUser.failure(e));
        return Promise.reject(e);
      });
  };
}
