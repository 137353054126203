import React from 'react';
import { IonRow, IonCol, IonText } from '@ionic/react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';

import './UserComponents.scss';
import { organizationTypePublicSector, User } from '../../store/users/types';

interface Props {
  isCurrentUser: boolean;
  completedPostRequestsAsHelper?: number;
  user: User | undefined;
}

type UserSocialImpactProps = Props & WithTranslation;

const UserSocialImpact: React.FunctionComponent<UserSocialImpactProps> = ({ isCurrentUser, completedPostRequestsAsHelper, user }: UserSocialImpactProps) => {
  if (!completedPostRequestsAsHelper || !user?.status?.environmentalImpact?.saved_objects || !user?.status?.environmentalImpact?.carbon_footprint) {
    return <></>;
  }

  return (
    <IonRow responsive-sm className="social-impact">
      {user?.managedData?.type === organizationTypePublicSector && (
        <>
          <IonCol size="12">
            <div className="title">
              <IonText color="dark">
                <Trans i18nKey={'organization.my-environmental-impact'} />
              </IonText>
            </div>
          </IonCol>

          <IonCol size="6">
            <div data-cy="social-impact-value" className="social-impact-value">
              {user?.status?.environmentalImpact?.saved_objects || 0} {/*TODO check and update when API will be ready*/}
            </div>
            <div className="help without-border">
              <IonText color="medium">
                <Trans i18nKey="organization.saved-objects" />
              </IonText>
            </div>
          </IonCol>

          <IonCol size="6">
            <div data-cy="social-impact-value" className="social-impact-value">
              {user?.status?.environmentalImpact?.carbon_footprint || 0} {/*TODO check and update when API will be ready*/}
            </div>
            <div className="help without-border">
              <IonText color="medium">
                <Trans i18nKey="organization.carbon-footprint" />
              </IonText>
            </div>
          </IonCol>
        </>
      )}
      <IonCol size="12">
        <div className="title">
          <IonText color="dark">
            <Trans i18nKey={`${isCurrentUser ? 'user.my-social-impact' : 'user.social-impact'}`} />
          </IonText>
        </div>
      </IonCol>
      <IonCol size="12">
        <div data-cy="social-impact-value" className="social-impact-value">
          {completedPostRequestsAsHelper}
        </div>
      </IonCol>
      <IonCol size="12">
        <div className="help">
          <IonText color="medium">
            <Trans i18nKey="user.helped" count={completedPostRequestsAsHelper} />
          </IonText>
        </div>
      </IonCol>
    </IonRow>
  );
};

export default React.memo(withTranslation()(UserSocialImpact));
