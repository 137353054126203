import { EntityReference, HydraEntity, ReferencedCollection } from '../../utils/hydra';
import { Media } from '../app/types';
import { NotificationReason } from '../notifications/types';
import { PostRequest } from '../postRequests/types';
import { Address, Location } from '../posts/types';

export const organizationTypePublicSector = 'public_sector';
export const organizationTypeNgo = 'ngo';
export const organizationTypePublicStructure = 'public_structure';
export const organizationTypeReuseOrganization = 'reuse_organization';
export const organizationTypesToValidate = [organizationTypePublicSector, organizationTypePublicStructure, organizationTypeReuseOrganization];

export type organizationType = typeof organizationTypeNgo | typeof organizationTypePublicSector | typeof organizationTypePublicStructure | typeof organizationTypeReuseOrganization;
export type FollowUserType = 'followers' | 'following';
export type DisabledPrivacySettings = 'privacy_settings_personalisation' | 'privacy_settings_measurement' | 'privacy_settings_storage' | 'privacy_settings_transmission';

export const defaultPictures: string[] = [
  '/assets/avatars/yellow.png',
  '/assets/avatars/pink.png',
  '/assets/avatars/blue.png',
  '/assets/avatars/green.png',
  '/assets/avatars/blue-2.png',
  '/assets/avatars/yellow-2.png',
  '/assets/avatars/green-2.png',
  '/assets/avatars/pink-2.png',
];

export interface CurrentUserPersonalDataFields {
  firstname: string;
  lastname: string;
  name?: string;
  phone: string;
  gender?: 'woman' | 'man' | 'other';
  newsletterSubscribed: boolean;
  lastTosAcceptedDate?: Date;
  appleId?: string | null;
  facebookId?: string | null;
}

interface CurrentUserCommonFields {
  // Common user fields
  locale: string;
  email: string;
  avatar: File[] | Media | null;
  images: File[] | Media[];
  aboutMe?: string;
  aboutMeOnIndigo?: string;
  favoriteCategoryThematics: EntityReference[];
  favoriteUsers?: EntityReference[];
  createdAt?: string;
  status: CurrentUserStatus;
  allowPushNotifications: boolean;
  disabledPushNotifications: NotificationReason[];
  allowMailNotifications: boolean;
  disabledMailNotifications: NotificationReason[];
  address?: Address;
  addressLocation?: {
    latitude?: number | null;
    longitude?: number | null;
  };
  managed?: boolean;
  passwordMustBeChanged: boolean;
  preferences?: Preferences;
  managedData?: ManagedData;
  disabled?: boolean;
  disabledPrivacySettings: DisabledPrivacySettings[];
}

export interface Preferences {
  userDeclinedNewsletterSubscription?: boolean;
  userDeclinedAvatarUpload?: boolean;
}

export interface Area {
  address?: Address;
  location?: Location;
  locationHistory?: LocationHistory[];
}

export interface LocationHistory {
  address?: Address;
  location?: Location;
}

export interface ManagedData extends ManagedDataFields {
  id?: number; // Page Id
  publicSectorData?: PublicSectorData;
}

export interface ManagedDataFields {
  type?: organizationType; // Page category
  activity?: string;
  certified?: boolean;
  website?: string;
  contact?: ManagedContact;
}

export interface ManagedContact {
  firstname?: string;
  lastname?: string;
  phone?: string;
  email?: string;
  occupation?: string;
}

export interface PublicSectorData {
  cityCodes?: [];
  cityKey?: string;
  welcomeImageUrl?: string;
  welcomeText?: string;
  promotionalBgImage?: string;
  promotionalBgImageLargeScreen?: string;
  promotionalModalImage?: string;
  promotionalModalText?: string;
  firstCellImage?: string;
  firstCellRedirectUrl?: string;
}

export interface CurrentUserFields extends CurrentUserCommonFields, CurrentUserPersonalDataFields {}

export interface CurrentUserStatus {
  createdPosts: number;
  createdOfferPosts: number;
  createdNeedPosts: number;
  offerPostNotFinished: number;
  needPostNotFinished: number;
  completedPostRequests: number;
  favoriteUsers: number;
  requestPostsByUser: number;
  completedPostRequestsAsHelper: number;
  unreadChats: number;
  unreadNotifications: number;
  environmentalImpact?: {
    saved_objects: number;
    carbon_footprint: number;
  };
}

export interface UserStatus {
  completedPostRequests: number;
  completedPostRequestsAsHelper: number;
  unreadChats?: number;
  unreadNotifications?: number;
  environmentalImpact?: {
    saved_objects: number;
    carbon_footprint: number;
  };
}

export interface CurrentUserEditFields extends CurrentUserFields {
  '@id'?: EntityReference;
  plainPassword?: string;
  confirmPassword?: string; // This value should not be sent to the server
  facebookAccessToken?: string;
  appleAccessToken?: string;
  // Managed Data for editing
  type?: organizationType; // Page category
  activity?: string;
  certified?: boolean;
  website?: string;
  contact?: ManagedContact;
}

export interface CurrentUser extends CurrentUserFields, HydraEntity {
  username: string;
  name: string;
  facebookId: string | null;
}

export interface CurrentUserApiFields extends CurrentUser {
  personalData: CurrentUserPersonalDataFields;
}

export interface User extends HydraEntity {
  // Basic user data the API always returns
  name: string;
  avatar?: Media | null;
  managed?: boolean; // Is it an human user, or a page

  // Public user data, the API returns it when asking for an user
  images?: Media[];
  aboutMe?: string;
  aboutMeOnIndigo?: string;

  favoriteCategoryThematics?: EntityReference[];

  collections?: {
    // User Posts
    servicesOffers?: ReferencedCollection; // Post[] - User service offers, displayed on the current user profile page
    objectsOffers?: ReferencedCollection; // Post[] - User objects offers, displayed on the current user profile page
    offer?: ReferencedCollection; // Post[] - User offer posts
    need?: ReferencedCollection; // Post[] - User need post

    // User Favorites
    favoriteUsers?: ReferencedCollection; // User[] the other users this user likes
    favoritedByUsers?: ReferencedCollection; // User[] the other users who like this user

    // User Reviews
    personalReviews?: ReferencedCollection; // UserReview[] linked to the user
    postRequestReviews?: ReferencedCollection; // UserReview[] linked to a postRequest
    postRequestReviewsResponses?: ReferencedCollection; // UserReview[] Responses to the post request reviews
    positivePostRequestReviews?: ReferencedCollection; // UserReview[] Positive reviews
  };

  status?: UserStatus;
  createdAt?: string;
  disabled?: boolean;

  managedData?: ManagedData;
}

export interface UserReview extends HydraEntity {
  type: ReviewType;
  reviewedUser: User;
  reviewedPostRequest: PostRequest | null;
  comment: string | null;
  createdBy: User;
  createdAt: string;
}

export type ReviewType = 'positive' | 'negative';

export interface UserApiFields extends User {
  personalData?: CurrentUserPersonalDataFields;
}

export interface UserState {
  isItemLoading: boolean;
  isFavoriteLoading: boolean;
}

export type UserCollectionName = keyof NonNullable<User['collections']>;
export type UserFavoriteCollectionName = 'favoriteUsers' | 'favoritedByUsers';
export type UserReviewsCollectionName = 'personalReviews' | 'postRequestReviews' | 'postRequestReviewsResponses' | 'positivePostRequestReviews';
export type UserManagedCollectionName = 'managedUsers';
