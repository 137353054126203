import { EntityReference, HydratedCollection, ReferencedCollection } from '../../utils/hydra';
import { denormalizeEntityCollection } from '../entities/selectors';
import { RootState } from '../index';
import { User } from '../users/types';
import { isSameHydraEntity } from '../../utils/helpers';
import { BlockedUsersCollectionName } from './types';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';
import { getEntities } from '../app/selectors';
import { EntitiesState } from '../entities/types';

export type CurrentUserBlockedAndCurrentUserIsBlockedCollections = {
  currentUserBlockedCollection: ReferencedCollection;
  currentUserIsBlockedCollection: ReferencedCollection;
};

export type HydratedCurrentUserBlockedAndCurrentUserIsBlockedCollections = {
  currentUserBlockedCollection: HydratedCollection<User>;
  currentUserIsBlockedCollection: HydratedCollection<User>;
};

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const getCurrentUserBlockedCollection = (state: RootState): ReferencedCollection => state.blockedUsers.collections.currentUserBlockedCollection;
const getCurrentUserIsBlockedCollection = (state: RootState): ReferencedCollection => state.blockedUsers.collections.currentUserIsBlockedCollection;
const getUserId = (state: RootState, userId: EntityReference): EntityReference => userId;
const getCollectionName = (state: RootState, userId: EntityReference, collectionName: Partial<BlockedUsersCollectionName>): Partial<BlockedUsersCollectionName> => collectionName;

const getCurrentUserBlockedAndCurrentUserIsBlockedCollections = createSelector(
  getCurrentUserBlockedCollection,
  getCurrentUserIsBlockedCollection,
  (currentUserBlockedCollection: ReferencedCollection, currentUserIsBlockedCollection: ReferencedCollection): CurrentUserBlockedAndCurrentUserIsBlockedCollections => ({
    currentUserBlockedCollection,
    currentUserIsBlockedCollection,
  }),
);

export const getHydratedCurrentUserBlockedAndCurrentUserIsBlockedCollections = createDeepEqualSelector(
  [getCurrentUserBlockedAndCurrentUserIsBlockedCollections, getEntities],
  (colls: CurrentUserBlockedAndCurrentUserIsBlockedCollections, entities: EntitiesState): HydratedCurrentUserBlockedAndCurrentUserIsBlockedCollections => {
    return {
      currentUserBlockedCollection: denormalizeEntityCollection<User>(entities, colls.currentUserBlockedCollection),
      currentUserIsBlockedCollection: denormalizeEntityCollection<User>(entities, colls.currentUserIsBlockedCollection),
    };
  },
);

export const isUserBlocked = createDeepEqualSelector(
  [getHydratedCurrentUserBlockedAndCurrentUserIsBlockedCollections, getUserId, getCollectionName],
  (coll: HydratedCurrentUserBlockedAndCurrentUserIsBlockedCollections, userId: EntityReference, collectionName: Partial<BlockedUsersCollectionName>): boolean =>
    !!coll[collectionName].ids.find((id: EntityReference) => isSameHydraEntity(id, userId)),
);
