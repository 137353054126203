export const ROUTES_WITHOUT_FOOTER = [
  '/register_organization',
  '/login_organization',
  '/select_profiles',
  /\/global_notification\/[\w-]+$/,
  /\/display_notification\/[\w-]+$/,
  '/map',
  '/login',
  '/register',
  '/reset-password',
];

// Store the page we must redirect the user to after login / register
// We could have used the localStorage, but we don't really want to keep this value after the page closes
let redirectPathAfterLogin: string | null = null;

// Store the page we must redirect the user after close homepage
// We could have used the localStorage, but we don't really want to keep this value after the page closes
let redirectPathAfterCloseHomepage: string | null = null;

const notSavedRedirectPages = ['/homepage', '/select_profiles'];

export const userBurgerMenuRoutes = ['/me/notifications', '/me/privacy', '/me/security', '/me/account', '/me/help', '/me/about'];

export const getRedirectPathAfterCloseHomepage = (): string => {
  return redirectPathAfterCloseHomepage || '/posts';
};

export const setRedirectAfterLogin = (pathname: string | null, pathnameIfCanceled: string = location.pathname): void => {
  redirectPathAfterCloseHomepage = pathnameIfCanceled;
  if (notSavedRedirectPages.includes(location.pathname) || pathname === '/me') {
    console.info('Ignoring set redirect path after login', pathname);
    return;
  }
  console.info('Set redirect path after login', pathname);
  redirectPathAfterLogin = pathname;
};

const getAndClearRedirectAfterLogin = (): string | null => {
  const pathname = redirectPathAfterLogin;
  console.info('Get and clear redirect path', pathname);
  redirectPathAfterLogin = null;
  return pathname;
};

export const clearRedirectAfterLogin = (): void => {
  if (null === redirectPathAfterLogin) {
    return;
  }
  console.info('Clear redirect path');
  redirectPathAfterLogin = null;
};

export const getRedirectPathAfterLogin = (): string => {
  return getAndClearRedirectAfterLogin() || '/posts';
};

export interface historyState {
  openMenuModal?: boolean;
  direction?: string;
  tabDirection?: string;
}
