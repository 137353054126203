import { extractId } from '../../utils/helpers';
import { WhoAmIState } from './types';
import { ActionType, getType } from 'typesafe-actions';
import * as currentUserActions from './actions';
import findIndex from 'lodash/findIndex';

const defaultCurrentUserState: WhoAmIState = {
  whoAmIToken: null,
  whoAmIUser: null,
};

export default (state = defaultCurrentUserState, action: ActionType<typeof currentUserActions>): WhoAmIState => {
  switch (action.type) {
    case getType(currentUserActions.fetchManagedUsersAction.success):
      return { ...state, managedUsers: action.payload };
    case getType(currentUserActions.updateWhoAmIAction):
      if (!action.payload.managed && !action.payload.managedData) {
        return { ...state, whoAmIUser: action.payload };
      }
      return { ...state };
    case getType(currentUserActions.updateWhoAmITokenAction):
      return { ...state, whoAmIToken: action.payload };
    case getType(currentUserActions.addOrUpdateManagedUserAction): {
      if (!state.managedUsers) {
        return { ...state, managedUsers: { items: [action.payload], totalItems: 1 } };
      }
      const index = findIndex(state.managedUsers.items, { '@id': extractId(action.payload) });
      if (index !== -1) {
        const updatedItems = state.managedUsers.items.map((value, i) => {
          if (i === index) {
            return { ...value, ...action.payload };
          }
          return value;
        });

        return { ...state, managedUsers: { ...state.managedUsers, items: updatedItems } };
      }
      return { ...state, managedUsers: { ...state.managedUsers, items: state.managedUsers.items.concat(action.payload), totalItems: state.managedUsers.totalItems + 1 } };
    }
    default:
      return state;
  }
};
