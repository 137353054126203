import { IonContent, IonPage, IonText } from '@ionic/react';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { logError } from '../utils/logs/userLogs';
import './UnsupportedBrowserPage.scss';

export const UnsupportedBrowserPage: React.FunctionComponent = () => {
  const unsupportedBrowserRequest = async (): Promise<void> => {
    try {
      return logError('unsupported_browser');
    } catch (e) {
      // OSEF
    }
  };

  useEffect(() => {
    unsupportedBrowserRequest();
  }, []);

  return (
    <IonPage data-cy="empty-page">
      <IonContent className="empty-page">
        <div className="flex-center">
          <div className="ion-text-center w-100">
            <h1>
              <Trans i18nKey="error.title" />
            </h1>
            <IonText className="text-medium pre-wrap">
              <Trans i18nKey="error.unsupported-browser-page-text" />
            </IonText>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
