import { combineReducers, Reducer } from 'redux';
import { RootStateOrAny } from 'react-redux';
import app from './app/reducer';
import blockedUsers from './blockedUsers/reducer';
import categories from './categories/reducer';
import chats from './chats/reducer';
import whoAmI from './whoAmI/reducer';
import entities from './entities/reducer';
import layout from './layout/reducer';
import navigation from './navigation/reducer';
import notifications from './notifications/reducer';
import postRequests from './postRequests/reducer';
import posts from './posts/reducer';
import thematics from './thematics/reducer';
import users from './users/reducer';
import { AppState } from './app/types';
import { BlockedUsersState } from './blockedUsers/types';
import { CategoryState } from './categories/types';
import { ChatState } from './chats/types';
import { WhoAmIState } from './whoAmI/types';
import { EntitiesState } from './entities/types';
import { LayoutState } from './layout/types';
import { NavigationState } from './navigation/types';
import { NotificationsState } from './notifications/types';
import { PostRequestState } from './postRequests/types';
import { PostStoreState } from './posts/types';
import { ThematicState } from './thematics/types';
import { UserState } from './users/types';
import { ActionType } from 'typesafe-actions';
import { RootActions } from './index';

export interface RootState {
  app: AppState;
  blockedUsers: BlockedUsersState;
  categories: CategoryState;
  chats: ChatState;
  entities: EntitiesState;
  layout: LayoutState;
  navigation: NavigationState;
  notifications: NotificationsState;
  postRequests: PostRequestState;
  posts: PostStoreState;
  thematics: ThematicState;
  users: UserState;
  whoAmI: WhoAmIState;
}

export const appReducer: Reducer<RootState> = combineReducers<RootState>({
  app,
  blockedUsers,
  categories,
  chats,
  entities,
  layout,
  navigation,
  notifications,
  postRequests,
  posts,
  thematics,
  users,
  whoAmI,
});

const rootReducer = (state: RootStateOrAny, action: ActionType<RootActions>): RootState => {
  if (action.type === 'RESET_APP_FOR_NEW_USER') {
    const { app, whoAmI, thematics, navigation, layout } = state;
    state = { app, whoAmI, thematics, navigation, layout };
  }

  if (action.type === 'RESET_APP') {
    const { layout } = state;
    state = { layout };
  }

  return appReducer(state, action);
};

export default rootReducer;
