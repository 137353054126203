import { EntityReference, HydraEntity, ItemsCollection, ReferencedCollection } from '../../utils/hydra';
import { Media } from '../app/types';
import { PostRequest } from '../postRequests/types';
import { User } from '../users/types';
import { Category } from '../categories/types';

export type Post = PartialPost | FullPost;

export const postTransitionFinish = 'finish';
export const postTransitionDelete = 'delete';

export const postTypes = <const>['offer', 'need'];
export type PostType = (typeof postTypes)[number];

export const postCategoryTypes = <const>['object', 'service'];
export type PostCategoryType = (typeof postCategoryTypes)[number];

export const objectsUniverses = <const>['child', 'man', 'woman'];
export type ObjectsUniverse = (typeof objectsUniverses)[number];

export const weightUnits = <const>['kg', 'g'];
export type WeightUnit = (typeof weightUnits)[number];

export const editableStates = ['draft', 'pending', 'published', 'fully_requested'];
export const currentUserDisplayedStates = ['draft', 'published', 'fully_requested', 'moderated', 'pending', 'finished'];
export const currentUserStatesWithBanner = ['fully_requested', 'moderated', 'pending', 'finished'];
export const publicStates = ['published', 'fully_requested'];

export interface PostPublicLocation {
  latitude?: number | null;
  longitude?: number | null;
  fakeLocationRadius?: number | null;
  locality?: string | null;
  postalCode?: string | null;
  country?: string | null;
}

export interface PostStateHistory {
  from: PostState;
  to: PostState;
  reason?: Reason;
  date: string;
}

export type PostState = 'draft' | 'published' | 'fully_requested' | 'finished' | 'deleted' | 'moderated' | 'pending';
export const postStateCanBeFinished = ['published', 'fully_requested'];

type Reason = 'post_request_accepted' | 'post_request_canceled' | 'invalid_state' | 'deleted_creator' | 'too_old';

export interface PartialPost extends HydraEntity {
  title: string;
  createdBy: User;
  state: PostState;
  stateHistory?: PostStateHistory[];
  type: PostType;
  categoryType: PostCategoryType;
  images?: File[] | Media[];
  requestsLimit?: number | null;
  publicLocation?: PostPublicLocation;
  geohash: string;

  // Post owner data
  location?: {
    latitude?: number | null;
    longitude?: number | null;
  };

  // Client added data
  requests?: ReferencedCollection; // PostRequest[]
  currentUserOpenRequest?: PostRequest;
  publicChat?: EntityReference;
}

export interface FullPost extends PartialPost {
  description: string;
  address: Address;
  location: Location;
  createdAt: Date;
  updatedAt: Date;
  images: Media[];
  category: EntityReference | null;
  universe: ObjectsUniverse | null;
  size: string | null;
  estimatedWeight: number | null;
  quantity: number | null;
  discriminants: EntityReference[];
}

export interface PostCluster extends HydraEntity {
  center: google.maps.LatLngLiteral;
  count: number;
}

export interface EditablePost {
  '@id'?: EntityReference;
  title: string;
  description: string;
  address: Address;
  location: {
    latitude?: number | null;
    longitude?: number | null;
  };
  requestsLimit?: number | null;
  imagesUpload?: File[] | Media[];
  categoryType: PostCategoryType | '';
  category: string | null;
  images?: File[] | Media[];
  type: string;
  displayOnMapAccepted: boolean;
  universe?: ObjectsUniverse | null;
  size?: string | null;
  estimatedWeight?: number | null;
  quantity?: number | null;
  discriminants?: EntityReference[];
}

export interface Location {
  latitude: number;
  longitude: number;
}

export interface Address {
  formatted: string;
  locality?: string;
  country?: string;
  postalCode?: string;
  cityCode?: string;
}

export interface PostStoreState {
  itemIsLoading: boolean;
  mapIsLoading: boolean;
  collections: {
    // We don't use ReferencedCollection here, as we don't want to persist all listed posts
    offer: ItemsCollection<FullPost>; // Post[] - Used in Homepage. "offer" and not "offers" in order to match the type name
    need: ItemsCollection<FullPost>; // Post[] - Used in Homepage. "need" and not "needs" in order to match the type name
    search: ItemsCollection<FullPost>; // Post[] - Used in search page
    recommendation: ItemsCollection<FullPost>; // Post[] - Used in search page
    [thematicName: string]: ItemsCollection<FullPost>; // Post[] - Used in search page for the suggestions
  };
  searchParams: SearchQueryParameters;
  savedSearchList?: SavedSearch[]; // Used in Search page
  savedSearchListIsLoading: boolean;
}

export type PostCollectionName = keyof NonNullable<PostStoreState['collections']>;

export const searchOrders = ['asc', 'desc'];
export type SearchOrder = (typeof searchOrders)[number];

interface SelectedCategoryItem {
  isSelected: boolean;
  category?: Category;
}

export interface CategorySelection {
  object: SelectedCategoryItem;
  service: SelectedCategoryItem;
}

// Warning: as soon as this interface is modified, please update the filterQueryParamToSearchParam and filterSearchParamToQueryParam function in /posts/actions.ts
export interface SearchQueryParameters {
  searchText?: string;
  postType: PostType;
  categorySelection: CategorySelection;
  childCategories?: Category[] | null;
  universe?: ObjectsUniverse;
  size?: string;
  dateOrder: SearchOrder;
  locationCenter?: Location;
  locationRadius?: number;
  locationAddress: Address | null;
  itemsPerPage?: number;
  createdByManagedData?: CreatedByManagedDataType;
}

export interface CreatedByManagedDataType {
  name: string;
  value: string;
  type: string;
}

export interface PostsPageFetchParams {
  type: PostType;
  location?: Location;
  loadNextPage: boolean;
  radius?: number;
  extendCollection: boolean;
  excludePrevRadius: boolean;
}

// Warning: as soon as this interface is modified, please update the filterQueryParamToSearchParam and filterSearchParamToQueryParam function in /posts/actions.ts
export interface SearchFilter {
  search: string;
  type?: PostType;
  'order[updatedAt]'?: SearchOrder;
  'fakeLocation[center]'?: string;
  'fakeLocation[radius]'?: number;
  'category[]'?: string[];
  childCategories?: string[];
  universe?: ObjectsUniverse;
  size?: string;
  categoryType?: PostCategoryType;
  postType?: PostType;

  locationAddress?: string;
}

export interface SavedSearch extends HydraEntity {
  searchFilters: SearchFilter;
  user: EntityReference;
  lastFetchDate: Date;
  createdAt: Date;
  updatedAt: Date;
  newPosts: number;
}

// Those interfaces are used for the estimation modal

interface AnalyzerPredictionAverage {
  estimate: number;
  min: number;
  max: number;
}

export interface AnalyzerResult {
  co2: { [key: string]: number };
  weight: { [key: string]: number };
  price: { [key: string]: number };
}

interface AnalyzerItemDescription {
  bow: string;
  unit: string;
  category: string;
  subcategory: string;
  weight_kg?: number;
  indicative_weight_kg?: number;
  facteur_emission?: number;
}

export interface AnalyzerRawResult {
  score?: number;
  item_desc?: AnalyzerItemDescription;
}

interface AnalyzerPredictions {
  co2_prediction_kg?: AnalyzerPredictionAverage;
  weight_prediction_kg?: AnalyzerPredictionAverage;

  text_result?: AnalyzerResult;
  vision_result?: AnalyzerResult;

  raw_text_results?: { [key: string]: AnalyzerRawResult };
  raw_vision_results?: { [key: string]: AnalyzerRawResult };
}

export interface AnalyzerPrediction {
  prediction: {
    createdAt: string;
    updatedAt: string;
    id: EntityReference;
    predictions: AnalyzerPredictions;
  };
}

export interface EmissionRule {
  itemCategoryType: string;
  itemCategory: {
    name: string;
    itemMinWeight: number;
    itemMaxWeight: number;
  };
  itemCategoryDiscriminant: EntityReference | null;
  itemDiscriminant: string | null;
  emissionFactor: number | null;
  emissionFactorUnit: string | null;
  emissionFactorIsPer: 'tons' | 'item' | null;
  emissionFactorIncertitudePercent: number | null;
  emissionFactorSource: string | null;
  recyclingOrReusePercent: number | null;
  recyclingOrReuseSource: string | null;
  averageWeight: number | null;
  averageWeightIncertitudePercent: number | null;
  wasteIncinerationEmissionFactor: number | null;
  wasteIncinerationCategory: string | null;
  wasteIncinerationEmissionIncertitudePercent: number | null;
  wasteIncinerationSource: string | null;
  wasteStorageEmissionFactor: number | null;
  wasteStorageCategory: string | null;
  wasteStorageEmissionIncertitudePercent: number | null;
  wasteStorageSource: string | null;
  wasteCompostingEmissionFactor: number | null;
  wasteCompostingCategory: string | null;
  wasteCompostingEmissionIncertitudePercent: number | null;
  wasteCompostingSource: string | null;
  wasteAverageEmissionFactor: number | null;
  wasteAverageCategory: string | null;
  wasteAverageEmissionIncertitudePercent: number | null;
  wasteAverageSource: string | null;
  comment: string | null;
  id: string | null;
}

export interface PostEstimateData {
  estimatedEmission: number | null;
  emissionUnit: string;
  emissionCertainty: number;
  estimatedWeight: number | null;
  estimatedWeightCertainty: number;
  itemsQuantity: number | null;
  categoryAverageWeight: number | null;
  categoryWeightCertainty: number;
  weightedCategory: {
    name: string;
    itemMinWeight?: number;
    itemMaxWeight?: number;
    itemDefaultWeight?: number;
  } | null;
  pricedCategory: {
    name: string;
    itemDefaultPrice?: number;
  } | null;
  bestRule: EmissionRule | null;
  categoryAndDiscriminantRules: EmissionRule[];
  categoryRules: EmissionRule[];
  estimatedPrice: number | null;
}
