import { IonButton, IonContent, IonModal } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import ThematicsList from '../ThematicsList';
import { EntityReference } from '../../utils/hydra';
import i18next from 'i18next';
import CommonHeader from '../CommonHeader';

import './AddUserThematicsModal.scss';

interface StateProps {
  favoriteCategoryThematicsIds: EntityReference[];
}

const mapStateToProps = (state: RootState): StateProps => ({
  favoriteCategoryThematicsIds: state.app.currentUser.favoriteCategoryThematics,
});

interface DispatchProps {
  fetchThematics: () => Promise<void>;
  fetchLoggedUser: () => Promise<void>;
  updateCurrentUserThematics: (thematics: EntityReference[]) => void;
}

const propsToDispatch = {
  fetchThematics: actions.thematicsActions.fetchThematics,
  fetchLoggedUser: actions.app.fetchLoggedUser,
  updateCurrentUserThematics: actions.app.updateCurrentUserThematics,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

interface ModalProps {
  closeModal: () => void;
  isOpen: boolean;
  isUserProfile?: boolean;
}

type ThematicsProps = DispatchProps & ModalProps & StateProps;

interface ThematicsPageState {
  favoriteCategoryThematics: EntityReference[];
  thematicsUpdateLoading: boolean;
}

class AddUserThematicsModal extends Component<ThematicsProps, ThematicsPageState> {
  public constructor(props: ThematicsProps) {
    super(props);

    this.state = {
      favoriteCategoryThematics: props.favoriteCategoryThematicsIds,
      thematicsUpdateLoading: false,
    };
  }

  public componentDidMount(): void {
    this.props.fetchThematics().then(() => this.props.fetchLoggedUser());
  }

  public updateSelectionList(thematics: EntityReference[]): void {
    this.setState({ favoriteCategoryThematics: thematics });
  }

  public render(): ReactNode {
    return (
      <IonModal isOpen={this.props.isOpen} onDidDismiss={this.props.closeModal} className="safe-area-ios--large-screen">
        <CommonHeader
          title={i18next.t(this.props.isUserProfile ? 'user.tags-you-follow' : 'thematics.add-tags-to-follow')}
          stopPropagation
          onBackButtonClick={this.props.closeModal}
          isBackButtonDisabled={this.state.thematicsUpdateLoading}
        />

        <IonContent className="add-tags-content">
          <ThematicsList onThematicSelection={thematic => this.updateSelectionList(thematic)} favoriteCategoryThematicsIds={this.props.favoriteCategoryThematicsIds} />
        </IonContent>

        <IonButton shape="round" className="full-width-button" data-cy="save-user-thematics" onClick={this.updateUserThematics}>
          {i18next.t('common.save')}
        </IonButton>
      </IonModal>
    );
  }

  private updateUserThematics = async (): Promise<void> => {
    if (this.state.thematicsUpdateLoading) {
      return;
    }
    this.setState({ thematicsUpdateLoading: true });
    await this.props.updateCurrentUserThematics(this.state.favoriteCategoryThematics);
    this.setState({ thematicsUpdateLoading: false }, () => this.props.closeModal());
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUserThematicsModal);
