import { IonButton, IonContent, IonPage, IonText } from '@ionic/react';
import { FormikHelpers, FormikProps } from 'formik';
import React, { Component, ReactNode } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import * as Yup from 'yup';
import InputPasswordToggle from '../components/inputs/InputPasswordToggle';
import { actions, RootState } from '../store';
import DynamicForm, { FormFields, FormValues, InputFieldComponent } from '../components/DynamicForm';
import { JWTToken } from '../store/app/types';
import { CurrentUser, User } from '../store/users/types';
import { extractId } from '../utils/helpers';
import { CollectionResponse, EntityReference } from '../utils/hydra';
import find from 'lodash/find';
import matchesProperty from 'lodash/matchesProperty';
import CommonHeader from '../components/CommonHeader';
import './LoginOrganizationPage.scss';

const getSubmitButton: React.FunctionComponent<FormikProps<FormValues>> = (formikBag: FormValues, text: string) => (
  <IonButton type="submit" shape="round" className="login-button" disabled={!formikBag.isValid || formikBag.isValidating || formikBag.isSubmitting} data-cy="submit-button">
    {text}
  </IonButton>
);

interface StateProps {
  managedUsersCollection: CollectionResponse<User> | undefined;
}

const mapStateToProps = (state: RootState): StateProps => ({
  managedUsersCollection: state.whoAmI.managedUsers,
});

interface DispatchProps {
  addNewManagedUser(postId: number, reason: string): Promise<CurrentUser>;
  authenticateAsManagedUser(user: EntityReference): Promise<JWTToken>;
  fetchManagedUsers(): Promise<User[]>;
  setToastMessage(message: string | null): void;
}

const propsToDispatch = {
  addNewManagedUser: actions.whoAmI.addNewManagedUser,
  authenticateAsManagedUser: actions.app.authenticateAsManagedUser,
  fetchManagedUsers: actions.whoAmI.fetchManagedUsers,
  setToastMessage: actions.layout.setToastMessageAction,
};

interface ReportFormValues extends FormValues {
  pageId: number | undefined;
  password: string;
}

interface State {
  helpPageIsShowed: boolean;
  errorMessage?: string;
}

type LoginOrganizationProps = WithTranslation & DispatchProps & RouteComponentProps<Record<string, never>> & StateProps;

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

class LoginOrganizationPage extends Component<LoginOrganizationProps, State> {
  allFields: FormFields = [
    {
      label: 'organization.page-id',
      name: 'pageId',
      formComponent: InputFieldComponent,
      options: { autocapitalize: 'off', required: true, type: 'number', clearOnEdit: false, clearInput: true },
    },
    {
      label: 'organization.page-pswd',
      name: 'password',
      formComponent: InputPasswordToggle,
      withoutIonItem: true,
      options: { autocapitalize: 'off', type: 'password', required: true, clearOnEdit: false, autocomplete: 'new-password', clearInput: true },
    },
  ];
  validationSchema = Yup.object().shape({
    pageId: Yup.string().required('organization.page-id-required'),
    password: Yup.string().required('organization.page-password-required'),
  });
  initialFormValues: ReportFormValues = {
    pageId: undefined,
    password: '',
  };

  public constructor(props: LoginOrganizationProps) {
    super(props);
    this.state = {
      helpPageIsShowed: false,
    };
  }

  public render(): ReactNode {
    const pageTitle = !this.state.helpPageIsShowed ? this.props.t('organization.login-existing-page') : this.props.t('organization.help-login');

    return (
      <IonPage className="login-organization-page full-screen-page" data-cy="login-organization-page">
        <CommonHeader addIonHeader={true} title={''} onBackButtonClick={this.handleBackButtonClick} stopPropagation={this.state.helpPageIsShowed} />

        <IonContent className="login-organization-content">
          <h1 className="page-title">{pageTitle}</h1>

          {!this.state.helpPageIsShowed ? (
            <div className="login-form">
              {this.state.errorMessage && (
                <div className="error-msg">
                  <IonText color="light">{this.state.errorMessage}</IonText>
                </div>
              )}
              <DynamicForm
                onAfterSubmitFormSuccess={this.onAfterSubmitFormSuccess}
                onSubmitErrors={this.onAfterSubmitError}
                onSubmitForm={this.submitForm}
                formFields={this.allFields}
                validationSchema={this.validationSchema}
                initialValues={this.initialFormValues}
                dynamicFormSubmitButton={formikBag => getSubmitButton(formikBag, this.props.t('user.login'))}
              />
              <IonButton onClick={() => this.setState({ helpPageIsShowed: true })} color="primary" fill="clear" className="need-help-button">
                <Trans i18nKey="organization.need-help-for-step" />
              </IonButton>
            </div>
          ) : (
            <div className="login-organization-help">
              <IonText className="login-organization-help-description">
                <Trans i18nKey="organization.help-login-description" />
              </IonText>

              <ol>
                <li>
                  <Trans i18nKey="organization.switch-to-the-profile-page" components={[<strong key="switch-to-the-profile-page" />]} />
                </li>
                <li>
                  <Trans
                    i18nKey="organization.click-on-the-menu-of-the-profile-page"
                    components={[<strong key="click-on-the-menu-of-the-profile-page" />, <img key="burger" src="/assets/ios-menu.svg" alt="burger" />]}
                  />
                </li>
                <li>
                  <Trans i18nKey="organization.click-on-page-security" components={[<strong key="click-on-page-security" />]} />
                </li>
                <li>
                  <Trans i18nKey="organization.click-on-login-id-and-password" components={[<strong key="click-on-login-id-and-password" />]} />
                </li>
                <li>
                  <Trans i18nKey="organization.create-a-password-for-the-page-and-save" components={[<strong key="create-a-password-for-the-page-and-savee" />]} />
                </li>
              </ol>

              <IonText className="login-organization-helpc">
                <Trans i18nKey="organization.help-login-after" color="dark" />
              </IonText>
            </div>
          )}
        </IonContent>
      </IonPage>
    );
  }

  private handleBackButtonClick = (): void => {
    if (this.state.helpPageIsShowed) {
      this.setState({ helpPageIsShowed: false });
      return;
    }
  };

  private onAfterSubmitError = async (): Promise<void> => {
    this.setState({ errorMessage: this.props.t('login.invalid-credentials') });
  };

  private onAfterSubmitFormSuccess = async (values: Readonly<FormValues>, actions: FormikHelpers<FormValues>): Promise<void> => {
    actions.resetForm({ values: this.initialFormValues });
    this.props.history.push('/me');
  };

  private submitForm = async (values: Readonly<FormValues>): Promise<void> => {
    if (!values) {
      return;
    }
    const pageId = parseInt(values.pageId);

    await this.props.addNewManagedUser(pageId, values.password);
    const managedUsers = await this.props.fetchManagedUsers();

    const managedUser = find(managedUsers, matchesProperty('managedData.id', pageId));
    if (managedUser) {
      await this.props.authenticateAsManagedUser(extractId(managedUser));
      this.props.setToastMessage(this.props.t('organization.page-added', { pageName: managedUser.name }));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(LoginOrganizationPage)));
