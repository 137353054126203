import { IonContent, IonPage } from '@ionic/react';
import React, { Component, MouseEventHandler, ReactNode } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import UserForm, { AvatarMediaUploaderComponent, UserFormValues } from '../components/UserForm';
import { actions, RootState, selectors } from '../store';
import { CurrentUser } from '../store/users/types';
import { defaultEditableUser } from '../store/users/reducer';
import { User } from '../store/users/types';
import { createObjectByDefaultKeys, extractId } from '../utils/helpers';
import './EditProfilePage.scss';
import ActionConfirmationAlert from '../components/ActionConfirmationAlert';
import { FormikProps } from 'formik';
import { FormValues } from '../components/DynamicForm';
import { Thematic } from '../store/thematics/types';
import { EntityReference } from '../utils/hydra';
import withRouterAndRef from '../utils/withRouterAndRef';
import isEqual from 'lodash/isEqual';

const getLocationHeader: React.FunctionComponent<MouseEventHandler> = onClose => {
  return (
    <div className="header-content edit-profile-header">
      <Trans i18nKey="user.edit-profile" />
      <div className="cancel" onClick={onClose}>
        <Trans i18nKey="common.cancel" />
      </div>
    </div>
  );
};

interface StateProps {
  currentUser: CurrentUser;
  getUserData: () => User | undefined;
  currentUserThematics: Thematic[];
}

const mapStateToProps = (state: RootState): StateProps => ({
  currentUser: state.app.currentUser,
  getUserData: () => selectors.entities.denormalizeEntity(state.entities, extractId(state.app.currentUser)),
  currentUserThematics: selectors.thematics.filterThematicsByIds(state.thematics.thematics, state.app.currentUser.favoriteCategoryThematics),
});

interface DispatchProps {
  refreshLoggedUser(): Promise<void>;
  updateUser(user: Partial<UserFormValues>): Promise<CurrentUser>;
  setToastMessage(message: string | null): void;
  updateCurrentUserThematics: (thematics: EntityReference[]) => Promise<void>;
}

const propsToDispatch = {
  refreshLoggedUser: actions.app.fetchLoggedUser,
  updateUser: actions.app.updateUser,
  setToastMessage: actions.layout.setToastMessageAction,
  updateCurrentUserThematics: actions.app.updateCurrentUserThematics,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

interface EditProfileProps {
  form?: FormikProps<FormValues>;
  currentUserThematics?: Thematic[];
}

interface State {
  showConfirmationAlert: boolean;
  previouslyAddedThematics: EntityReference[];
  dirty: boolean;
  initialValues: Record<string, unknown>;
}

type Props = EditProfileProps & WithTranslation & StateProps & DispatchProps & RouteComponentProps<Record<string, never>>;

class EditProfilePage extends Component<Props, State> {
  public UserDataFields: (keyof UserFormValues)[] = ['avatar', 'lastname', 'firstname', 'favoriteCategoryThematics', 'aboutMe', 'aboutMeOnIndigo', 'address', 'addressLocation'];
  public UserManagedDataFields: (keyof UserFormValues)[] = ['avatar', 'name', 'favoriteCategoryThematics', 'website', 'aboutMe', 'aboutMeOnIndigo', 'address', 'addressLocation'];

  constructor(props: Props) {
    super(props);

    this.state = {
      showConfirmationAlert: false,
      previouslyAddedThematics: this.props.currentUser.favoriteCategoryThematics,
      dirty: false,
      initialValues: { ...createObjectByDefaultKeys(defaultEditableUser, { ...this.props.currentUser, ...this.props.getUserData() }), website: this.props.currentUser.managedData?.website },
    };
  }

  public componentDidMount(): void {
    this.props.refreshLoggedUser();
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.currentUser.favoriteCategoryThematics !== this.props.currentUser.favoriteCategoryThematics) {
      this.setState({ previouslyAddedThematics: this.props.currentUser.favoriteCategoryThematics });
    }
  }

  public render(): ReactNode {
    return (
      <IonPage className="edit-profile-page" data-cy="edit-profile-page">
        <IonContent className="content-wrapper">
          <div className="header-content">
            <Trans i18nKey={this.props.currentUser.managed ? 'user.edit-managed-profile' : 'user.edit-profile'} />
            <Link to="/me" className="cancel" onClick={this.handleCloseLinkClick}>
              <Trans i18nKey="common.cancel" />
            </Link>
            <ActionConfirmationAlert isAlertActive={this.state.showConfirmationAlert} closeAlert={this.keepPostForm} discardChanges={this.closePostForm} />
          </div>
          <UserForm
            setDirty={this.setIsDirty}
            initialValues={this.state.initialValues}
            fields={this.props.currentUser.managed ? this.UserManagedDataFields : this.UserDataFields}
            postUserAction={this.updateUser}
            redirectionRoute="/me"
            submitButtonText={this.props.t('common.save')}
            currentUserThematics={this.props.currentUserThematics}
            shouldAvatarComponentChange={true}
            touchOnLoad
            locationModalHeader={getLocationHeader}
            overriddenFields={[
              {
                name: 'avatar',
                formComponent: AvatarMediaUploaderComponent,
                itemLines: 'none',
              },
            ]}
          />
        </IonContent>
      </IonPage>
    );
  }

  private handleCloseLinkClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();

    if (typeof this === 'undefined') {
      return; // TODO Never call a function if the component does not exist anymore
    }

    const { previouslyAddedThematics, dirty } = this.state;
    const { favoriteCategoryThematics } = this.props.currentUser;

    if (dirty || !isEqual(favoriteCategoryThematics, previouslyAddedThematics)) {
      this.setState({ showConfirmationAlert: true });
    } else {
      this.goBack();
    }
  };

  private goBack = (): void => {
    if (this.props.history.length > 0) {
      // this.props.history.goBack(); // TODO Fix the about:blank cypress bug
      this.props.history.push('/me'); // TODO Remove
    } else {
      this.props.history.push('/me');
    }
  };

  private keepPostForm = (): void => {
    this.setState({ showConfirmationAlert: false });
  };

  private closePostForm = async (): Promise<void> => {
    this.setState({ showConfirmationAlert: false });
    try {
      await this.props.updateCurrentUserThematics(this.state.previouslyAddedThematics);
    } catch (e) {
      //Show error in toast message
    }

    this.goBack();
  };

  private setIsDirty = (dirty: boolean): void => {
    if (dirty !== this.state.dirty) {
      this.setState({ dirty });
    }
  };

  private updateUser = async (user: Partial<UserFormValues>): Promise<CurrentUser> => {
    const userUpdated = await this.props.updateUser(user);
    this.props.setToastMessage('user.update-success');
    return userUpdated;
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouterAndRef(withTranslation()(EditProfilePage)));
