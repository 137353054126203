import React, { MutableRefObject } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ReactComponentType } from '../components/types';

interface ForwardRefComponentProps<T> extends RouteComponentProps {
  forwardRef: ((instance: T | null) => void) | MutableRefObject<T | null> | null;
}

export default (Wrapped: ReactComponentType): React.ForwardRefExoticComponent<React.RefAttributes<unknown>> => {
  const WithRouter = withRouter(({ forwardRef, ...otherProps }: ForwardRefComponentProps<unknown>) => {
    return <Wrapped ref={forwardRef} {...otherProps} />;
  });
  const WithRouterAndRef = React.forwardRef((props, ref) => <WithRouter {...props} forwardRef={ref} />);

  const name = Wrapped.displayName || Wrapped.name;
  WithRouterAndRef.displayName = `withRouterAndRef(${name})`;

  return WithRouterAndRef;
};
