import { createAsyncAction } from 'typesafe-actions';
import { fetchWithAuth } from '../../utils/authDataAccess';
import { addParametersToUrl } from '../../utils/dataAccess';
import { extractId, extractRawId, parseHydraCollection } from '../../utils/helpers';
import { CollectionResponse, EntityReference, HydraCollectionResponse, ReferencedCollectionResponse } from '../../utils/hydra';
import { normalizeAndDispatchCollectionResponse, normalizeAndDispatchEntity } from '../entities/selectors';
import { Post } from '../posts/types';
import { ThunkResult } from '../types';
import { PostRequest, PostRequestTransition } from './types';
import { UserReview } from '../users/types';

interface PostRequestUpdateData {
  post: Post;
  transition: PostRequestTransition;
}

export const fetchPostRequestsAction = createAsyncAction('post_requests/FETCH_POST_REQUESTS_REQUEST', 'post_requests/FETCH_POST_REQUESTS_SUCCESS', 'post_requests/FETCH_POST_REQUESTS_FAILURE')<
  { postId: EntityReference },
  { postId: EntityReference; collection: ReferencedCollectionResponse },
  { postId: EntityReference; error: Error }
>();

export const fetchPostRequestsWithUserAction = createAsyncAction(
  'post_requests/FETCH_POST_REQUESTS_WITH_USER_REQUEST',
  'post_requests/FETCH_POST_REQUESTS_WITH_USER_SUCCESS',
  'post_requests/FETCH_POST_REQUESTS_WITH_USER_FAILURE',
)<void, void, Error>();

export const fetchUserPostRequestsAction = createAsyncAction(
  'post_requests/FETCH_USER_POST_REQUESTS_REQUEST',
  'post_requests/FETCH_USER_POST_REQUESTS_SUCCESS',
  'post_requests/FETCH_USER_POST_REQUESTS_FAILURE',
)<void, ReferencedCollectionResponse, Error>();

export const fetchPostRequestReviewsAction = createAsyncAction(
  'post_requests/FETCH_POST_REQUESTS_REVIEWS_REQUEST',
  'post_requests/FETCH_POST_REQUESTS_REVIEWS_SUCCESS',
  'post_requests/FETCH_POST_REQUESTS_REVIEWS_FAILURE',
)<EntityReference, { postRequestId: EntityReference; collection: ReferencedCollectionResponse }, { postRequestId: EntityReference; error: Error }>();

export const fetchUserOpenedPostRequestAction = createAsyncAction('post_requests/FETCH_OPENED_REQUEST', 'post_requests/FETCH_OPENED_SUCCESS', 'post_requests/FETCH_OPENED_FAILURE')<
  void,
  { postId: EntityReference; postRequestId: EntityReference },
  Error
>();

export const createPostRequestAction = createAsyncAction('post_requests/CREATE_REQUEST', 'post_requests/CREATE_SUCCESS', 'post_requests/CREATE_FAILURE')<
  void,
  { postId: EntityReference; postRequestId: EntityReference },
  Error
>();

export const updatePostRequestStateAction = createAsyncAction('post_requests/EDIT_REQUEST', 'post_requests/EDIT_SUCCESS', 'post_requests/EDIT_FAILURE')<void, PostRequestUpdateData, Error>();

export const cancelPostRequestAction = createAsyncAction('post_requests/CANCEL_REQUEST', 'post_requests/CANCEL__SUCCESS', 'post_requests/CANCEL_FAILURE')<void, PostRequestUpdateData, Error>();

const openedPostRequestStates: string[] = ['waiting', 'accepted'];

export function fetchPostRequests(postId: EntityReference, withoutResult = false): ThunkResult<Promise<ReferencedCollectionResponse>> {
  let page = postId + '/requests';

  if (withoutResult) {
    page = addParametersToUrl(page, { itemsPerPage: 0 });
  }

  return async dispatch => {
    dispatch(fetchPostRequestsAction.request({ postId }));

    return fetchWithAuth(page)
      .then(response => response.json() as Promise<HydraCollectionResponse<PostRequest>>)
      .then(data => parseHydraCollection(data))
      .then(data => {
        const collection = normalizeAndDispatchCollectionResponse(data, dispatch);
        dispatch(fetchPostRequestsAction.success({ postId, collection }));
        return collection;
      })
      .catch(error => {
        dispatch(fetchPostRequestsAction.failure({ postId, error }));
        return Promise.reject(error);
      });
  };
}

export function fetchPostRequestsWithUser(post: Post, user: EntityReference): ThunkResult<Promise<CollectionResponse<PostRequest>>> {
  const postId = extractId(post);
  return async dispatch => {
    dispatch(fetchPostRequestsWithUserAction.request());

    return fetchWithAuth(postId + '/requests?user=' + user)
      .then(response => response.json() as Promise<HydraCollectionResponse<PostRequest>>)
      .then(data => parseHydraCollection(data))
      .then(data => {
        dispatch(fetchPostRequestsWithUserAction.success());
        return data;
      })
      .catch(error => {
        dispatch(fetchPostRequestsWithUserAction.failure(error));
        return Promise.reject(error);
      });
  };
}

export function fetchPostRequestReviews(postRequestId: EntityReference): ThunkResult<Promise<void>> {
  return async dispatch => {
    dispatch(fetchPostRequestReviewsAction.request(postRequestId));

    return fetchWithAuth(postRequestId + '/reviews')
      .then(response => response.json() as Promise<HydraCollectionResponse<UserReview>>)
      .then(data => parseHydraCollection(data))
      .then(data => normalizeAndDispatchCollectionResponse(data, dispatch))
      .then(collection => {
        dispatch(fetchPostRequestReviewsAction.success({ postRequestId, collection }));
      })
      .catch(error => {
        dispatch(fetchPostRequestReviewsAction.failure({ postRequestId, error }));
        return Promise.reject(error);
      });
  };
}

export function fetchUserPostRequests(): ThunkResult<Promise<void>> {
  return async dispatch => {
    dispatch(fetchUserPostRequestsAction.request());

    return fetchWithAuth('/users/me/post_requests')
      .then(response => response.json() as Promise<HydraCollectionResponse<PostRequest>>)
      .then(data => parseHydraCollection(data))
      .then(data => normalizeAndDispatchCollectionResponse(data, dispatch))
      .then(collection => {
        dispatch(fetchUserPostRequestsAction.success(collection));
      })
      .catch(e => {
        dispatch(fetchUserPostRequestsAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function fetchUserOpenedPostRequest(post: Post): ThunkResult<Promise<void>> {
  return async dispatch => {
    dispatch(fetchUserOpenedPostRequestAction.request());

    // TODO Allow full IRI instead of raw ID in API
    let page = '/users/me/post_requests?post=' + extractRawId(post);
    page += openedPostRequestStates.map(item => '&state[]=' + item).join('');

    return fetchWithAuth(page)
      .then(response => response.json() as Promise<HydraCollectionResponse<PostRequest>>)
      .then(data => parseHydraCollection(data))
      .then(data => normalizeAndDispatchCollectionResponse(data, dispatch))
      .then(data => {
        if (data.totalItems > 1) {
          throw new Error('More than one running request');
        }
        dispatch(fetchUserOpenedPostRequestAction.success({ postId: extractId(post), postRequestId: data.ids[0] }));
      })
      .catch(e => {
        dispatch(fetchUserOpenedPostRequestAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function createPostRequest(post: Post): ThunkResult<Promise<PostRequest>> {
  return async dispatch => {
    dispatch(createPostRequestAction.request());

    return fetchWithAuth('post_requests', {
      method: 'POST',
      data: { post: post['@id'] },
    })
      .then(response => response.json() as Promise<PostRequest>)
      .then(postRequest => {
        normalizeAndDispatchEntity(postRequest, dispatch);
        dispatch(createPostRequestAction.success({ postId: extractId(post), postRequestId: extractId(postRequest) }));
        return postRequest;
      })
      .catch(e => {
        dispatch(createPostRequestAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function updatePostRequestState(postRequest: PostRequest, transition: PostRequestTransition): ThunkResult<Promise<void>> {
  return async dispatch => {
    dispatch(updatePostRequestStateAction.request());

    return fetchWithAuth(extractId(postRequest) + '/state', {
      method: 'PUT',
      data: { transition },
    })
      .then(response => response.json() as Promise<PostRequest>)
      .then(data => normalizeAndDispatchEntity(data, dispatch))
      .then(() => {
        dispatch(updatePostRequestStateAction.success({ post: postRequest.post, transition }));
      })
      .catch(e => {
        dispatch(updatePostRequestStateAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function cancelPostRequest(postRequest: PostRequest): ThunkResult<Promise<void>> {
  return async dispatch => {
    dispatch(cancelPostRequestAction.request());

    return fetchWithAuth(extractId(postRequest) + '/state', {
      method: 'PUT',
      data: { transition: 'cancel' },
    })
      .then(response => response.json() as Promise<PostRequest>)
      .then(data => normalizeAndDispatchEntity(data, dispatch))
      .then(() => {
        dispatch(cancelPostRequestAction.success({ post: postRequest.post, transition: 'cancel' }));
      })
      .catch(e => {
        dispatch(cancelPostRequestAction.failure(e));
        return Promise.reject(e);
      });
  };
}
