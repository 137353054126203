import { IonChip, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonText } from '@ionic/react';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { chatFilters, ChatQueryFilters } from '../store/chats/types';
import CommonHeader from './CommonHeader';
import sortedUniq from 'lodash/sortedUniq';

import './ChatFiltersButton.scss';

interface ChatFiltersChipListProps {
  list: string[];
  title: string;
  onItemClick: (item: string[]) => void;
  selectedItems: string[];
}

const ChatFiltersChipList: React.FunctionComponent<ChatFiltersChipListProps> = ({ list, title, selectedItems, onItemClick }: ChatFiltersChipListProps) => {
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>(selectedItems.find(filter => list.includes(filter)));
  const onClick = (item: string): void => {
    setSelectedFilter(selectedFilter === item ? undefined : item);
    const newSelectedFilters = selectedItems.filter(filter => filter !== selectedFilter);

    onItemClick(selectedFilter !== item ? sortedUniq([...newSelectedFilters, item]) : [...newSelectedFilters]);
  };

  return (
    <div className="chat-filters-chip-list">
      <IonItem>
        <IonText>
          <Trans i18nKey={`chat-filters.${title}`} />
        </IonText>
      </IonItem>
      <IonList>
        {list.map(item => {
          const isSelected = selectedItems.includes(item);
          return (
            <IonChip key={item} data-cy={`chip-selection-${item}`} className={`chat-filters-item text-overflow-chip ${isSelected ? 'is-selected' : ''}`} onClick={() => onClick(item)}>
              <div className="text-overflow-chip-content">
                <IonLabel>
                  <Trans i18nKey={`chat-filters.chip.${item}`} />
                </IonLabel>
                {isSelected && <IonIcon className="ion-no-margin" size="small" icon="/assets/icon/checkmark-white.svg" />}
              </div>
            </IonChip>
          );
        })}
      </IonList>
    </div>
  );
};

interface ChatsFilterModalProps {
  initialChatsFilters: ChatQueryFilters;
  setChatFilters: (newFilters: ChatQueryFilters) => void;
}

const ChatFiltersButton: React.FunctionComponent<ChatsFilterModalProps> = ({ initialChatsFilters, setChatFilters }: ChatsFilterModalProps) => {
  const [filters, setFilters] = useState<ChatQueryFilters>(initialChatsFilters);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const dismissSelection = (): void => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (!modalIsOpen) {
      return;
    }

    setFilters(initialChatsFilters);
  }, [modalIsOpen]);

  useEffect(() => {
    setChatFilters(filters);
  }, [filters]);

  return (
    <>
      <IonChip className="chat-filters-button" data-cy="chat-filters-button" onClick={() => setModalIsOpen(true)}>
        <IonIcon className="ion-no-margin" size="small" icon="/assets/icon/filters.svg" />
        <Trans i18nKey="search.filters" />
      </IonChip>
      <IonModal isOpen={modalIsOpen} onDidDismiss={dismissSelection} className="chat-filters-modal safe-area-ios" data-cy="chat-filters-modal">
        <IonHeader>
          <CommonHeader title={i18next.t('chat-filters.filters')} stopPropagation onBackButtonClick={dismissSelection} />
        </IonHeader>
        <IonContent>
          {chatFilters.map(chatFilter => {
            return (
              <div key={chatFilter.title}>
                <ChatFiltersChipList
                  list={chatFilter.filters}
                  title={chatFilter.title}
                  onItemClick={items => setFilters({ ...filters, selectedFilters: items })}
                  selectedItems={filters.selectedFilters}
                />
              </div>
            );
          })}
        </IonContent>
      </IonModal>
    </>
  );
};

export default ChatFiltersButton;
