import React from 'react';
import { IonContent, IonItem, IonLabel, IonSkeletonText, IonThumbnail, IonSpinner, IonToolbar } from '@ionic/react';
import { ObjectPlaceholder, ServicePlaceholder } from './PostsPlaceholder';

import './ProfilePagePlaceholder.scss';
import './HomePagePlaceholder.scss';
import './CommonPlaceholderStyles.scss';

interface Props {
  isCurrentUser: boolean;
}

const ObjectPostPlaceholderProfilePage: React.FunctionComponent = () => {
  return (
    <div className="object-posts-wrapper">
      <p className="object-posts-wrapper-title">
        <IonSkeletonText style={{ width: '22%' }} />
      </p>

      <div className="posts-items-wrapper">
        <div className="post-item">
          <ObjectPlaceholder />
        </div>
        <div className="post-item">
          <ObjectPlaceholder />
        </div>
      </div>
    </div>
  );
};

const ServicePostPlaceholderProfilePage: React.FunctionComponent = () => {
  return (
    <>
      <div className="posts-divider">
        <IonItem lines="none" className="high-group">
          <IonLabel>
            <p>
              <IonSkeletonText style={{ width: '39%' }} />
            </p>
          </IonLabel>
        </IonItem>
      </div>

      <div className="service-posts-wrapper">
        <p className="service-posts-wrapper-title">
          <IonSkeletonText style={{ width: '22%' }} />
        </p>

        <div className="posts-items-wrapper">
          <div className="post-item">
            <ServicePlaceholder />
          </div>
          <div className="post-item">
            <ServicePlaceholder />
          </div>
        </div>
      </div>
    </>
  );
};

const ProfilePagePlaceholder: React.FunctionComponent<Props> = ({ isCurrentUser }: Props) => {
  return (
    <>
      <IonToolbar no-border />
      <IonContent className="user-profile-placeholder common-placeholder" data-cy="profile-page-placeholder">
        <IonSpinner className="common-placeholder-spinner" data-cy="profile-page-loader" />

        <IonThumbnail className="picture-placeholder">
          <IonSkeletonText />
        </IonThumbnail>

        <div className="content-wrapper">
          <IonItem lines="none">
            <IonSkeletonText className="big-media" />
          </IonItem>

          <IonLabel>
            <p>
              <IonSkeletonText style={{ width: '35%' }} className="small-media" />
            </p>
          </IonLabel>

          <IonLabel className="flex-wrap-top-item">
            <p>
              <IonSkeletonText className="medium-media" />
              <IonSkeletonText className="extra-small-media" />
            </p>
            <p>
              <IonSkeletonText className="medium-media" />
              <IonSkeletonText className="extra-small-media" />
            </p>
          </IonLabel>

          <IonLabel>
            <p>
              <IonSkeletonText style={{ width: '35%' }} className="small-media" />
            </p>
          </IonLabel>

          <IonLabel className="flex-wrap-top-item">
            <p>
              <IonSkeletonText className="medium-media" />
              <IonSkeletonText className="extra-small-media" />
            </p>
          </IonLabel>
          <hr />

          <IonItem lines="none" className="high-group">
            <IonLabel>
              <p>
                <IonSkeletonText style={{ width: '22%' }} />
              </p>
              <p>
                <IonSkeletonText style={{ width: '100%' }} />
              </p>
              <p>
                <IonSkeletonText style={{ width: '100%' }} />
              </p>
              <p>
                <IonSkeletonText style={{ width: '100%' }} />
              </p>
            </IonLabel>
          </IonItem>

          <IonItem lines="none" className="high-group">
            <IonLabel>
              <p>
                <IonSkeletonText style={{ width: '22%' }} />
              </p>
              <p>
                <IonSkeletonText style={{ width: '100%' }} />
              </p>
              <p>
                <IonSkeletonText style={{ width: '100%' }} />
              </p>
              <p>
                <IonSkeletonText style={{ width: '100%' }} />
              </p>
            </IonLabel>
          </IonItem>

          <IonItem lines="none" className="medium-group">
            <IonLabel>
              <p>
                <IonSkeletonText style={{ width: '22%' }} />
              </p>
              <p>
                <IonSkeletonText style={{ width: '93%' }} />
              </p>
              <p>
                <IonSkeletonText style={{ width: '99%' }} />
              </p>
              <p>
                <IonSkeletonText style={{ width: '84%' }} />
              </p>
            </IonLabel>
          </IonItem>

          <IonItem lines="none" className="high-group">
            <IonLabel>
              <p>
                <IonSkeletonText style={{ width: '22%' }} />
              </p>
              <p>
                <IonSkeletonText style={{ width: '100%' }} />
              </p>
              <p>
                <IonSkeletonText style={{ width: '100%' }} />
              </p>
              <p>
                <IonSkeletonText style={{ width: '100%' }} />
              </p>
            </IonLabel>
          </IonItem>

          <IonItem lines="none" className="high-group">
            <IonLabel>
              <p>
                <IonSkeletonText style={{ width: '22%' }} />
              </p>
              <p>
                <IonSkeletonText style={{ width: '100%' }} />
              </p>
              <p>
                <IonSkeletonText style={{ width: '100%' }} />
              </p>
              <p>
                <IonSkeletonText style={{ width: '100%' }} />
              </p>
            </IonLabel>
          </IonItem>
        </div>

        {isCurrentUser ? (
          <ObjectPostPlaceholderProfilePage />
        ) : (
          <>
            <ObjectPostPlaceholderProfilePage />
            <ServicePostPlaceholderProfilePage />
          </>
        )}
      </IonContent>
    </>
  );
};

export default ProfilePagePlaceholder;
