import { createAction, createAsyncAction } from 'typesafe-actions';
import { fetchWithAuth } from '../../utils/authDataAccess';
import { extractId, fetchAllItems, parseHydraCollection } from '../../utils/helpers';
import { EntityReference, HydraCollectionResponse, ReferencedCollectionResponse } from '../../utils/hydra';
import { normalizeAndDispatchCollectionResponse, normalizeAndDispatchEntity } from '../entities/selectors';
import { ThunkResult } from '../types';
import { Chat, ChatCollectionName, ChatQueryFilters, Message, UserChat, UserChatState } from './types';
import { addParametersToUrl } from '../../utils/dataAccess';
import i18next from 'i18next';

export const resetUserChatCollection = createAction('chats/RESET_COLLECTION', (collectionName: ChatCollectionName) => collectionName)();

export const fetchCurrentUserChatsAction = createAsyncAction('chats/FETCH_LIST_REQUEST', 'chats/FETCH_LIST_SUCCESS', 'chats/FETCH_LIST_FAILURE')<
  ChatCollectionName,
  { collectionName: ChatCollectionName; collection: ReferencedCollectionResponse },
  { collectionName: ChatCollectionName; error: Error }
>();

export const fetchCurrentUserChatAction = createAsyncAction('chats/FETCH_ITEM_REQUEST', 'chats/FETCH_ITEM_SUCCESS', 'chats/FETCH_ITEM_FAILURE')<void, void, Error>();

export const fetchUserChatsByChatAction = createAsyncAction('chats/FETCH_USERS_REQUEST', 'chats/FETCH_USERS_SUCCESS', 'chats/FETCH_USERS_FAILURE')<
  string,
  { collectionName: string; collection: ReferencedCollectionResponse },
  { collectionName: string; error: Error }
>();

export const createDirectChatAction = createAsyncAction('chats/CREATE_REQUEST', 'chats/CREATE_SUCCESS', 'chats/CREATE_FAILURE')<void, EntityReference, Error>();

export const fetchChatsMessagesAction = createAsyncAction('chats/FETCH_MESSAGES_REQUEST', 'chats/FETCH_MESSAGES_SUCCESS', 'chats/FETCH_MESSAGES_FAILURE')<
  EntityReference,
  { chatId: EntityReference; collection: ReferencedCollectionResponse },
  { chatId: EntityReference; error: Error }
>();

export const createChatMessageAction = createAsyncAction('chats/CREATE_MESSAGE_REQUEST', 'chats/CREATE_MESSAGE_SUCCESS', 'chats/CREATE_MESSAGE_FAILURE')<
  void,
  { chatId: EntityReference; messageId: EntityReference },
  Error
>();

export const updateUserChatStateAction = createAsyncAction('chats/UPDATE_CHAT_STATE_REQUEST', 'chats/UPDATE_CHAT_STATE_SUCCESS', 'chats/UPDATE_CHAT_STATE_FAILURE')<
  void,
  UserChatState,
  { error: Error; state: UserChatState }
>();
export const updateUserChatAction = createAsyncAction('chats/UPDATE_CHAT_REQUEST', 'chats/UPDATE_CHAT_SUCCESS', 'chats/UPDATE_CHAT_FAILURE')<void, void, Error>();

export function resetCollection(collectionName: ChatCollectionName): ThunkResult<Promise<void>> {
  return async dispatch => {
    dispatch(resetUserChatCollection(collectionName));
  };
}

const getChatFetchUrl = (baseUrl: string, chatFilter: ChatQueryFilters, userId?: string): string => {
  const defaultParams: { [key: string]: string | undefined } = {
    itemsPerPage: '10',
    'chat.postRequest.post.id': chatFilter.postId,
    'order[chat.lastActionDate]': 'DESC',
    'order[chat.createdAt]': 'DESC',
  };

  if (chatFilter.search) {
    defaultParams['search'] = chatFilter.search;
  }

  chatFilter.selectedFilters.forEach(filter => {
    switch (filter) {
      case 'unread':
        defaultParams['withUnreadActions'] = 'true';
        break;
      case 'read':
        defaultParams['withUnreadActions'] = 'false';
        break;
      case 'archived':
        defaultParams.state = 'archived';
        break;
      case 'active':
        defaultParams.state = 'active';
        break;
      case 'offer':
        defaultParams['chat.postRequest.post.type'] = 'offer';
        defaultParams['chat.postRequest.post.createdBy'] = userId;
        break;
      case 'need':
        defaultParams['chat.postRequest.post.type'] = 'need';
        defaultParams['chat.postRequest.post.createdBy'] = userId;
        break;
      case 'ask':
        defaultParams['chat.postRequest.post.type'] = 'offer';
        defaultParams['chat.postRequest.user'] = userId;
        break;
      case 'help':
        defaultParams['chat.postRequest.post.type'] = 'need';
        defaultParams['chat.postRequest.user'] = userId;
        break;
      case 'object':
        defaultParams['chat.postRequest.post.categoryType'] = filter;
        break;
      case 'service':
        defaultParams['chat.postRequest.post.categoryType'] = filter;
        break;
      case 'direct-chat':
        defaultParams['chat.type'] = 'direct';
        break;
      case 'fully_requested':
        defaultParams['chat.postRequest.state'] = 'accepted';
        break;
      case 'deleted':
        defaultParams['chat.postRequest.state[]'] = 'rejected';
        defaultParams['&chat.postRequest.state[]'] = 'canceled';
        break;
      case 'finished':
        defaultParams['chat.postRequest.state'] = 'completed';
        break;
      case 'pending':
        defaultParams['chat.postRequest.state'] = 'waiting';
        break;
    }
  });

  return addParametersToUrl(baseUrl, defaultParams);
};

export function fetchCurrentUserChats(
  page = '/users/me/chats',
  collectionName: ChatCollectionName = 'currentUserChats',
  loadNextPage = false,
  searchParams?: ChatQueryFilters,
): ThunkResult<Promise<void>> {
  return async (dispatch, getState) => {
    page = searchParams
      ? getChatFetchUrl(page, searchParams, getState().app.currentUser['@id'])
      : addParametersToUrl(page, { itemsPerPage: '10', 'order[chat.lastActionDate]': 'DESC', 'order[chat.createdAt]': 'DESC' });

    dispatch(fetchCurrentUserChatsAction.request(collectionName));

    if (loadNextPage) {
      page = getState().chats.collections[collectionName].nextPage || page;
    }

    return fetchWithAuth(page)
      .then(response => response.json() as Promise<HydraCollectionResponse<UserChat>>)
      .then(data => parseHydraCollection(data))
      .then(data => normalizeAndDispatchCollectionResponse(data, dispatch))
      .then(collection => {
        dispatch(fetchCurrentUserChatsAction.success({ collectionName, collection }));
      })
      .catch(error => {
        dispatch(fetchCurrentUserChatsAction.failure({ collectionName, error }));
        return Promise.reject(error);
      });
  };
}

export function fetchUserChat(chatId: EntityReference): ThunkResult<Promise<void>> {
  return async dispatch => {
    dispatch(fetchCurrentUserChatAction.request());

    return fetchAllItems<UserChat>('/users/me/chats?chat.id=' + chatId)
      .then(items => {
        if (!items.length) {
          throw new Error(i18next.t('chat.unable-to-find-chat'));
        }

        return items[0];
      })
      .then(item => {
        normalizeAndDispatchEntity(item, dispatch);
        dispatch(fetchCurrentUserChatAction.success());
      })
      .catch(error => {
        dispatch(fetchCurrentUserChatAction.failure(error));
        return Promise.reject(error);
      });
  };
}

export function fetchUserChatsByChat(chat: Chat): ThunkResult<Promise<void>> {
  const collectionName = extractId(chat);

  return async dispatch => {
    dispatch(fetchUserChatsByChatAction.request(collectionName));
    return fetchWithAuth(extractId(chat) + '/users')
      .then(response => response.json() as Promise<HydraCollectionResponse<UserChat>>)
      .then(data => parseHydraCollection(data))
      .then(data => normalizeAndDispatchCollectionResponse(data, dispatch))
      .then(collection => {
        dispatch(fetchUserChatsByChatAction.success({ collectionName, collection }));
      })
      .catch(error => {
        dispatch(fetchUserChatsByChatAction.failure({ collectionName, error }));
        return Promise.reject(error);
      });
  };
}

export function createUserDirectChat(userId: EntityReference): ThunkResult<Promise<EntityReference>> {
  return async dispatch => {
    dispatch(createDirectChatAction.request());

    return fetchWithAuth('/chats/direct', {
      method: 'POST',
      data: {
        with: userId,
      },
    })
      .then(response => response.json() as Promise<Chat>)
      .then(data => normalizeAndDispatchEntity(data, dispatch))
      .then(id => {
        dispatch(createDirectChatAction.success(id));
        return id;
      })
      .catch(e => {
        dispatch(createDirectChatAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function fetchChatMessages(chatId: EntityReference, page = '/messages'): ThunkResult<Promise<void>> {
  return async dispatch => {
    dispatch(fetchChatsMessagesAction.request(chatId));

    if (page === '/messages') {
      page = chatId + page;
      page = addParametersToUrl(page, { itemsPerPage: '20' });
    }

    return fetchWithAuth(page)
      .then(response => response.json() as Promise<HydraCollectionResponse<Message>>)
      .then(data => parseHydraCollection(data))
      .then(data => normalizeAndDispatchCollectionResponse(data, dispatch))
      .then(collection => {
        dispatch(fetchChatsMessagesAction.success({ chatId, collection }));
      })
      .catch(error => {
        dispatch(fetchChatsMessagesAction.failure({ chatId, error }));
        return Promise.reject(error);
      });
  };
}

export function sendChatMessage(chatId: EntityReference, message: string): ThunkResult<Promise<EntityReference>> {
  return async dispatch => {
    dispatch(createChatMessageAction.request());

    return fetchWithAuth('/chat_messages', {
      method: 'POST',
      data: {
        chat: chatId,
        text: message,
      },
    })
      .then(response => response.json() as Promise<Message>)
      .then(data => normalizeAndDispatchEntity(data, dispatch))
      .then(messageId => {
        dispatch(createChatMessageAction.success({ chatId, messageId }));
        return messageId;
      })
      .catch(e => {
        dispatch(createChatMessageAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function getUserDirectChat(userId: EntityReference): Promise<Chat> {
  return fetchWithAuth('/chats/direct?with=' + userId).then(response => response.json() as Promise<Chat>); // TODO Use the store
}

export function updateUserChatState(chatId: EntityReference, state: UserChatState, lastReadMessageDate: Date): ThunkResult<Promise<void>> {
  return async dispatch => {
    dispatch(updateUserChatStateAction.request());

    return fetchWithAuth(`${chatId}`, {
      method: 'PUT',
      data: {
        state,
        lastReadMessageDate,
      },
    })
      .then(() => {
        dispatch(updateUserChatStateAction.success(state));
      })
      .catch(error => {
        dispatch(updateUserChatStateAction.failure({ error, state }));
        return Promise.reject(error);
      });
  };
}

export function updateUserChat(chatId: EntityReference, userChat: Partial<UserChat>): ThunkResult<Promise<void>> {
  return async dispatch => {
    dispatch(updateUserChatAction.request());

    return fetchWithAuth(`${chatId}`, {
      method: 'PUT',
      data: { ...userChat },
    })
      .then(res => res.json())
      .then(item => {
        dispatch(updateUserChatAction.success(item));
      })
      .catch(e => {
        dispatch(updateUserChatAction.failure(e));
        return Promise.reject(e);
      });
  };
}
