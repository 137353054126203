import React from 'react';
import { IonCol, IonRow, IonText } from '@ionic/react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import './AboutInfoComponent.scss';

interface Props {
  title: string;
  description: string | undefined;
}

type AboutInfoProps = Props & WithTranslation;

const AboutInfoComponent: React.FunctionComponent<AboutInfoProps> = ({ title, description }: AboutInfoProps) => {
  if (!description?.trim()) {
    return null;
  }

  return (
    <>
      <IonRow responsive-sm className="about">
        <IonCol size="12">
          <div className="about-title">
            <IonText color="dark">
              <Trans i18nKey={`user.${title}`} />
            </IonText>
          </div>
        </IonCol>
        <IonCol size="12" className="about-description">
          <IonText color="dark">
            <p color="dark">{description}</p>
          </IonText>
        </IonCol>
      </IonRow>
    </>
  );
};

export default React.memo(withTranslation()(AboutInfoComponent));
