import { InputChangeEventDetail } from '@ionic/core';
import { FormikHelpers } from 'formik';
import { i18n, TFunction } from 'i18next';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEqualWith from 'lodash/isEqualWith';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';
import moment from 'moment';
import React, { Component } from 'react';
import { FormValues } from '../components/DynamicForm';
import { itemIsAMedia } from '../components/inputs/InputMediaUploader';
import { uploadMediaListAndGetErrors } from '../store/app/actions';
import { JWT, Media } from '../store/app/types';
import { GlobalNotificationData, ModeratedPostData, ModeratedReportToReporterData, NotificationReason, UserNotification } from '../store/notifications/types';
import { filterSearchParamToQueryParam } from '../store/posts/actions';
import { FullPost, Post, PostPublicLocation, SavedSearch, SearchQueryParameters, WeightUnit } from '../store/posts/types';
import { CurrentUser, organizationTypesToValidate, User } from '../store/users/types';
import { BlockedUsersCollectionName } from '../store/blockedUsers/types';
import { HydratedCurrentUserBlockedAndCurrentUserIsBlockedCollections } from '../store/blockedUsers/selectors';
import { fetchWithAuthIfPossible } from './authDataAccess';
import { FetchOptions } from './dataAccess';
import { CollectionResponse, EntityReference, HydraCollectionResponse, HydraEntity, ItemsCollection, ReferencedCollection } from './hydra';
import { OpenNativeSettings } from '@ionic-native/open-native-settings';
import { getI18n } from 'react-i18next';
import { getAppLanguage } from './translation';
import { accentFold } from './stringsHelper';
import { monthsBeforeDeadlinePostReached, monthsBeforeDeletePost } from '../store/posts/variables';
import { QueryParameters } from './dataAccess';
import linkifyStr from 'linkify-string';
import _ from 'lodash';
import { clientUrl } from '../environment';
import { History } from 'history';

export const widthMobileMin = 500;
export const isMobileQuery = { query: `(max-width: ${widthMobileMin}px)` }; // Usage : const isMobile = useMediaQuery(isMobileQuery)
export const widthTabletMin = 991;
export const defaultEmptyEmailDomain = '@dummy.indigo.world';
export const uuidReg = '[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}';
const validPictureExtension = ['jpeg', 'jpg', 'png'];
const filterParamsForCountActive = ['searchText', 'postType', 'universe', 'size', 'locationAddress', 'createdByManagedData'];

export const extractId = (item: EntityReference | Partial<HydraEntity> | undefined, ignoreError = false): EntityReference | '' => {
  if (ignoreError && typeof item === 'undefined') {
    return '';
  }

  if (typeof item === 'undefined') {
    throw new Error('Undefined hydra entity');
  }

  if (typeof item === 'string') {
    return item;
  }

  if (ignoreError && typeof item['@id'] === 'undefined') {
    return '';
  }

  if (typeof item['@id'] === 'undefined') {
    throw new Error('Invalid hydra entity');
  }

  return item['@id'];
};

export const extractRawId = (item: HydraEntity | EntityReference): string => {
  return extractId(item).split('/')[2] || '';
};

export const isSameHydraEntity = (a: Partial<HydraEntity> | EntityReference | null | undefined, b: Partial<HydraEntity> | EntityReference | null | undefined): boolean => {
  if ((null === a || undefined === a) && (null === b || undefined === b)) {
    return true;
  }

  if (null === a || undefined === a || null === b || undefined === b) {
    return false;
  }

  return extractId(a, true) === extractId(b, true);
};

export const isSameEntityGenerator =
  (a: Partial<HydraEntity> | EntityReference) =>
  (b: Partial<HydraEntity> | EntityReference): boolean => {
    return isSameHydraEntity(a, b);
  };

export const setStatePromise = (that: Component, newState: Component['state']): Promise<void> =>
  new Promise(resolve => {
    that.setState(newState, () => resolve());
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const createObjectByDefaultKeys = <I>(defaultObject: I, data: any): I => {
  const newObject: Partial<I> = {};

  for (const key in defaultObject) {
    if (!Object.prototype.hasOwnProperty.call(defaultObject, key)) {
      continue;
    }

    if (Object.prototype.hasOwnProperty.call(data, key)) {
      newObject[key] = data[key];
    } else {
      newObject[key] = defaultObject[key];
    }
  }

  return newObject as I;
};

/**
 * Recursively replaces all detected Entities by their IRI.
 */
export const replaceEntitiesByIds = (data: unknown): unknown => {
  if (_.isArray(data)) {
    return data.map(item => replaceEntitiesByIds(item));
  }
  if (_.isObject(data)) {
    const id = extractId(data, true);
    if (id) {
      return id;
    }

    return _.mapValues(data, item => replaceEntitiesByIds(item));
  }

  return data;
};

/**
 * Recursively replaces all detected IRIs by the related Entity if possible.
 */
export const replaceIdsByEntities = (data: unknown, entities: HydraEntity[]): unknown => {
  if (_.isArray(data)) {
    return data.map(item => replaceIdsByEntities(item, entities));
  }
  if (_.isObject(data)) {
    return _.mapValues(data, item => replaceIdsByEntities(item, entities));
  }
  if (_.isString(data) && data.match(/^\/\w+\/.*/)) {
    const foundEntity = entities.find(entity => isSameHydraEntity(entity, data));
    return foundEntity || data;
  }

  return data;
};

export const getPromisedValueWithRetry = async <T>(callback: () => Promise<T>, maxTries = 20): Promise<T> => {
  return new Promise(function (resolve, reject) {
    let value: T | undefined | null;

    resolveIfTrue(
      async () => {
        value = await callback();
        return value !== undefined && value !== null;
      },
      () => resolve(value as T),
      reject,
      maxTries,
    );
  });
};

export const resolveIfTrue = async (callback: () => boolean | Promise<boolean>, resolve: () => void, reject: (reason?: string) => void, maxTries = 1): Promise<void> => {
  if (await callback()) {
    resolve();
  } else if (maxTries <= 0) {
    reject(`Unable to resolve after ${maxTries}x100ms`);
  } else {
    setTimeout(resolveIfTrue, 100, callback, resolve, reject, maxTries - 1);
  }
};

export const promiseTimeout = <T>(ms: number, promise: Promise<T>): Promise<T> => {
  const timeout = new Promise((resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      reject('Promise timed out in ' + ms + 'ms.');
    }, ms);
  });

  return Promise.race([promise, timeout]) as Promise<T>;
};

export const parseHydraCollection = <T extends HydraEntity>(data: HydraCollectionResponse<T>): CollectionResponse<T> => {
  const items: T[] = data['hydra:member'];
  const totalItems: number = data['hydra:totalItems'];
  const nextPage: string | undefined = get(data, ['hydra:view', 'hydra:next']);
  const previousPage: string | undefined = get(data, ['hydra:view', 'hydra:previous']);

  return {
    items,
    totalItems,
    nextPage,
    previousPage,
    isFirstPage: typeof previousPage === 'undefined',
  };
};

export const fetchAllItems = <T extends HydraEntity>(url: string, fetchOptions?: FetchOptions, previousItems: T[] = []): Promise<T[]> => {
  return fetchWithAuthIfPossible(url, fetchOptions)
    .then(response => response.json() as Promise<HydraCollectionResponse<T>>)
    .then(data => {
      const collection = parseHydraCollection(data);
      if (collection.nextPage) {
        return fetchAllItems(collection.nextPage, fetchOptions, previousItems.concat(collection.items));
      }
      return previousItems.concat(collection.items);
    });
};

export const arrayDifference = <T>(arr1: T[], arr2: T[]): T[] => {
  return arr1.filter(x => !arr2.includes(x)).concat(arr2.filter(x => !arr1.includes(x)));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const arrayAreEqual = (arr1: any[], arr2: any[]): boolean => {
  if (!arr1 || !arr2) return false;
  if (arr1.length !== arr2.length) return false;

  for (let i = 0, l = arr1.length; i < l; i++) {
    if (arr1[i] instanceof Array && arr2[i] instanceof Array) {
      if (!arrayAreEqual(arr1[i], arr2[i])) return false;
    } else if (arr1[i] !== arr2[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false;
    }
  }
  return true;
};

export const getBlobByUrl = async (url: string): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open('GET', url);

    request.responseType = 'blob';

    request.onload = () => {
      resolve(request.response);
    };

    request.onerror = e => {
      reject(e);
    };

    request.send();
  });
};

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @param  {Boolean} shallowCompare   Use === instead of _.equals
 * @return {Object}        Return a new object who represent the diff
 */
export function differenceBetween<T>(object: T, base: T, shallowCompare = false): Partial<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function changes(object: any, base: any): Partial<T> {
    return transform<T, T>(object, function (result, value, key) {
      const eq = shallowCompare ? value === base[key as keyof T] : isEqual(value, base[key as keyof T]);
      if (!eq) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (result as any)[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}

// Those lines can be useful to test componentDidUpdate state & props
// console.log('Props diff: ' + JSON.stringify(differenceBetween(this.props, prevProps, false)));
// console.log('State diff: ' + JSON.stringify(differenceBetween(this.state, prevState, false)));
// You can pass true as the third argument in order to compare by reference

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function withoutFunctionCustomizer(objValue: any, othValue: any): boolean | undefined {
  if (isFunction(objValue) && isFunction(othValue)) {
    return true;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export function isEqualIgnoringFunctions(object: any, base: any): boolean {
  return isEqualWith(object, base, withoutFunctionCustomizer);
}

export type InputChangeEvent = InputChangeEventDetail & { checked: boolean };

export function getValueFromHtmlInputChangeEvent(e: CustomEvent<InputChangeEvent>): string | string[] | boolean | null {
  if (e.target && (e.target as Element).firstChild && ((e.target as Element).firstChild as HTMLInputElement).autocomplete === 'on') {
    return ((e.target as Element).firstChild as HTMLInputElement).value;
  }
  if (Object.hasOwnProperty.call(e.detail, 'checked')) {
    return e.detail.checked;
  }

  if (e.detail?.value !== undefined) {
    // Fixes https://github.com/ionic-team/ionic-framework/issues/24584#issuecomment-1064340670
    if ((e.target as Element)?.tagName === 'ION-SELECT' && (e.target as HTMLIonSelectElement)?.multiple && !Array.isArray(e.detail.value)) {
      return e.detail.value ? e.detail.value.split(',') : [];
    }

    return e.detail.value;
  }

  if (e.srcElement && (e.srcElement as Element).firstChild) {
    return ((e.srcElement as Element).firstChild as HTMLInputElement).value;
  }

  if (e.target && (e.target as Element).firstChild) {
    return ((e.target as Element).firstChild as HTMLInputElement).value;
  }

  return null;
}

/* getKeysAndValuesFromObject function is used to retrieve keys and values from nested forms in order to update them.
 * Example: { location: { latitude: number, longitude: number } } */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getKeysAndValuesFromObject(object: Record<string, any>): { key: string; value: any }[] {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const keysValues: { key: string; value: any }[] = [];
  Object.keys(object).forEach(key => {
    if (typeof key !== 'object') {
      keysValues.push({ key, value: object[key] });
    } else {
      Object.keys(key).forEach(key1 => {
        keysValues.push({ key: key1, value: object[key][key1] });
      });
    }
  });
  return keysValues;
}

export async function uploadAndValidateFiles(values: FormValues, actions: FormikHelpers<FormValues>, name: string, t: TFunction): Promise<void> {
  let fieldValues: (Media | File)[] | Media | File = values[name] || [];
  if (!Array.isArray(fieldValues)) {
    fieldValues = [fieldValues];
  }
  const mediaUploadResults = await uploadMediaListAndGetErrors(fieldValues);
  const errors: string[] = [];

  for (let i = 0; i < mediaUploadResults.length; i++) {
    const mediaOrError = mediaUploadResults[i];
    if (itemIsAMedia(mediaOrError)) {
      // If the upload result for the current file is a Media, we update it in the field value
      fieldValues[i] = mediaOrError;
      continue;
    }

    errors.push(t('media.item-error', { number: i + 1, error: mediaOrError.message }));
  }

  actions.setFieldValue(name, fieldValues, false);

  if (errors.length) {
    // Can be an array so when retrieving error in formik, make sure to deal with array
    actions.setErrors({ [name]: errors });
    throw new Error();
  }
}

const degreesToRadians = (degrees: number): number => {
  return (degrees * Math.PI) / 180;
};

const distanceBetweenCoords = (coords: GeolocationCoordinates, lat2: number, lon2: number): number => {
  if (!coords) {
    return 0;
  }
  const earthRadiusKm = 6371;
  const currentLat = coords.latitude;
  const currentLon = coords.longitude;

  const dLat = degreesToRadians(currentLat - lat2);
  const dLon = degreesToRadians(currentLon - lon2);

  const lat1 = degreesToRadians(coords.latitude);
  lat2 = degreesToRadians(lat2);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  //The return value of units is meters.
  return earthRadiusKm * c * 1000;
};

export const formattedDistanceBetweenCoords = (coords: GeolocationCoordinates, position: PostPublicLocation): string => {
  if (typeof position.latitude !== 'number' || typeof position.longitude !== 'number') {
    return '';
  }
  const distance = distanceBetweenCoords(coords, position.latitude, position.longitude);

  if (distance >= 10000) {
    return Math.round(distance / 1000).toFixed(0) + ' km';
  }

  if (distance >= 1000) {
    // The plus is used to avoid string like '7.0 km'
    return +(distance / 1000).toFixed(1) + ' km';
  }

  return distance.toFixed(0) + ' m';
};

export const formatDateFromNow = (time: Date | string, withoutSuffix = false): string => {
  return moment(new Date(time)).fromNow(withoutSuffix);
};

export const formatDateFromNowWithLessThanOneHour = (time: Date | string, t: TFunction, withoutSuffix = false): string => {
  if (moment().diff(moment(time), 'hours') < 1) {
    return t('common.less-than-one-hour');
  }
  return formatDateFromNow(time, withoutSuffix);
};

export const postDeadlineReached = (time: Date | string): boolean => {
  return moment(time)
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0)
    .isSameOrBefore(moment().subtract(monthsBeforeDeadlinePostReached, 'month').set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0));
};

export const getDaysBeforeDeletePost = (time: Date | string): number => {
  return moment(time).diff(moment().subtract(monthsBeforeDeletePost, 'month'), 'days');
};

export const fontScale = (letter: string): string => {
  let size: string;
  if (letter.length <= 3) {
    size = 'title-size-large';
  } else if (letter.length > 3 && letter.length <= 7) {
    size = 'title-size-medium-large';
  } else if (letter.length > 7 && letter.length <= 18) {
    size = 'title-size-medium';
  } else {
    size = 'title-size-small';
  }
  return size;
};

export const getUserAvatar = (user?: Partial<User> | CurrentUser | string): string => {
  if (typeof user === 'string' || typeof user === 'undefined') {
    return user || '/assets/avatar.svg';
  }

  if (!user.disabled && (user.avatar as Media)?.contentUrl) {
    return (user.avatar as Media)?.contentUrl;
  }

  return '/assets/avatar.svg';
};

export let webpSupport = true; // This value can be false while the setWebpSupport function has not finished

function setWebpSupport(): void {
  const webP = new Image();
  webP.onload = webP.onerror = function () {
    webpSupport = webP.height == 2;
  };
  webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
}

setWebpSupport();

export const isDataFresh = (coll: ReferencedCollection | ItemsCollection<HydraEntity> | undefined, ttl = 60 * 1000): boolean => {
  if (undefined === coll?.lastFetchDate) {
    return false;
  }

  return +new Date() - +new Date(coll.lastFetchDate) < ttl; // The data is fresh if the request has been made less than 1 minute ago
};

export const isLoadingFresh = (coll: ReferencedCollection | undefined): boolean => {
  if (undefined === coll?.lastFetchStartDate) {
    return false;
  }

  return +new Date() - +new Date(coll.lastFetchStartDate) < 2 * 1000; // The data is fresh if the request has been started more than 2 seconds ago
};

export const isEntityInArray = (entity: Partial<HydraEntity>, entitiesArray: (EntityReference | Partial<HydraEntity>)[] | undefined): boolean => {
  return !!entitiesArray?.find(entityFromArray => {
    return isSameHydraEntity(entityFromArray, entity);
  });
};

const BASE64_MARKER = ';base64,';
const uploadedFilesB64Cache: WeakMap<File, string> = new WeakMap<File, string>();

export const getMediaSrc = (item: Media): string => {
  if (!itemIsAMedia(item)) {
    throw new Error('This item is not a Media');
  }
  return item.contentUrl;
};

export const getItemSrc = (item: Media | File): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (itemIsAMedia(item)) {
      return resolve(getMediaSrc(item));
    }

    if (!item) {
      return reject();
    }

    if (uploadedFilesB64Cache.has(item)) {
      return resolve(uploadedFilesB64Cache.get(item) as string);
    }

    const reader = new FileReader();
    reader.onload = e => {
      // convert image file to base64 string
      const result = e.target?.result;
      if (!result) {
        return reject();
      }

      if (typeof result === 'string') {
        uploadedFilesB64Cache.set(item, result);
        return resolve(result);
      }

      reject('we should not have an arraybuffer here');
    };

    reader.onerror = e => {
      console.error('Unable to read the file', e);
      return reject(e);
    };
    reader.readAsDataURL(item);
  });
};

// The cordova-plugin-file vendor overrides the File native object. As we need it for images upload, we must save the original one.
// This line must be set in the index.html file, before other scripts:
//    window.originalFile = window.File;
export const createNewFile = (fileBits: BlobPart[], fileName: string, options?: FilePropertyBag): File => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const win = window as any;
  if (typeof win.originalFile !== 'undefined') {
    return new win.originalFile(fileBits, fileName, options);
  }

  return new win.File(fileBits, fileName, options);
};

export const urltoFile = async (url?: string, filename?: string, mimeType?: string): Promise<File> => {
  if (!url) {
    return Promise.reject();
  }
  if (url.indexOf(BASE64_MARKER) > 0) {
    mimeType = url.split(BASE64_MARKER)[0].split(':')[1];
    filename = 'uploaded-file-' + new Date().getTime() + '.' + mimeType.split('/')[1];
  }

  const file = await fetch(url)
    .then(res => res.arrayBuffer())
    .then(buf => createNewFile([buf], filename || 'default', { type: mimeType }));

  uploadedFilesB64Cache.set(file, url);
  return file;
};

export async function resizeImage(file: File): Promise<Blob> {
  const AUTHORIZED_MIME_TYPES = ['image/jpg', 'image/jpeg', 'image/png'];
  const DEFAULT_MIME_TYPE = 'image/jpeg';
  const MAX_WIDTH = 1500;
  const MAX_HEIGHT = 1500;
  const MIN_RESIZE_SIZE = 10 * 1000; // Avoid resizing the image if its size is less than 10kB, for predefined avatars for instance.

  const src = await getItemSrc(file);
  const fileType = file.type;
  const targetFileType = AUTHORIZED_MIME_TYPES.includes(fileType) ? fileType : DEFAULT_MIME_TYPE;

  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = src;
    image.onload = () => {
      const canvas = document.createElement('canvas');

      let width = image.width;
      let height = image.height;

      if (file.size && file.size <= MIN_RESIZE_SIZE && fileType === targetFileType) {
        console.info(`No need to resize a ${file.size}B image`);
        resolve(file);
        return;
      }

      if (image.width > image.height) {
        width = Math.min(image.width, MAX_WIDTH);
        height *= width / image.width;
      } else {
        height = Math.min(image.height, MAX_HEIGHT);
        width *= height / image.height;
      }

      canvas.width = width;
      canvas.height = height;

      console.info(`Resizing image from ${fileType} ${image.width}x${image.height} to ${targetFileType} ${width}x${height}`);
      const context = canvas.getContext('2d');

      if (!context) {
        reject();
        return;
      }

      context.drawImage(image, 0, 0, width, height);

      canvas.toBlob(resolve as BlobCallback, targetFileType, 0.82);
    };
  });
}

export const unfocusInput = (inputRef: React.RefObject<HTMLIonSearchbarElement | HTMLIonInputElement>): void => {
  if (!inputRef.current) {
    return;
  }

  inputRef.current.getInputElement().then(value => value.blur());
};

export const getItemById = <T extends HydraEntity>(array: T[], id: EntityReference): T | undefined => {
  return array.find(item => isSameHydraEntity(item, id));
};

export const scrollToLastPosition = (ionContentRef: React.RefObject<HTMLIonContentElement>, scrollingPoint: number): void => {
  const contentRef = ionContentRef.current;
  if (!contentRef) {
    return;
  }

  if (scrollingPoint !== 0) {
    contentRef.scrollToPoint(0, scrollingPoint);
  }
};

export const parseJwt = (token: string | null | undefined): JWT | null => {
  if (!token) {
    return null;
  }

  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const userDisallowsNotifications = (currentUser: CurrentUser, notificationReason?: NotificationReason): boolean => {
  return !currentUser.allowPushNotifications || (notificationReason !== undefined && currentUser.disabledPushNotifications.includes(notificationReason));
};

export const getWidthAndHeightFromSvgString = (svgString: string): { width: number | null; height: number | null } => {
  const parser = new DOMParser();
  const docIcon = parser.parseFromString(svgString, 'text/html');
  let width = docIcon.querySelector('svg')?.getAttribute('width');
  let height = docIcon.querySelector('svg')?.getAttribute('height');
  const viewBox = docIcon.querySelector('svg')?.getAttribute('viewBox');

  if (!width && !height && viewBox) {
    width = viewBox.split(' ')[2];
    height = viewBox.split(' ')[3];
  }

  return { width: width ? parseInt(width) : null, height: height ? parseInt(height) : null };
};

export const insertInString = (string: string, index: number, value: string): string => {
  return string.substring(0, index) + value + string.substring(index);
};

export const addViewBoxInSvgString = (svgString: string): string => {
  if (!svgString) {
    return svgString;
  }

  const iconSize = getWidthAndHeightFromSvgString(svgString);

  if (!iconSize.width || !iconSize.height) {
    return svgString;
  }

  svgString = svgString.replace(/ viewBox="[\d\s]+"/, '');

  return insertInString(svgString, svgString.indexOf('>'), ` viewBox="-5 -5 ${iconSize.width + 10} ${iconSize.height + 10}"`);
};

export const addColorInSvg = (svgString: string, color: string): string => {
  if (!svgString) {
    return svgString;
  }

  // In case the received icon color is already set
  if (svgString.includes('fill=')) {
    return svgString?.replace(/fill="([#]?\w+)"/g, `fill="${color}"`);
  }

  // Add the color at the end of the <svg> tag
  return insertInString(svgString, svgString.indexOf('>'), ` fill="${color}"`);
};

export const isCurrentUser = (currentUser: CurrentUser, user: Partial<User> | undefined): boolean => isSameHydraEntity(user, currentUser);

export const isUserBlocked = (coll: HydratedCurrentUserBlockedAndCurrentUserIsBlockedCollections, userId: EntityReference, collectionName: BlockedUsersCollectionName): boolean => {
  return !!coll[collectionName].ids.find((id: EntityReference) => isSameHydraEntity(id, userId));
};

// This function allows to avoid focusing the "reset" button when using the TAB key in any IonInput with the "clearInput" attribute
export const ignoreResetButtonOnTab = (e: React.KeyboardEvent<HTMLIonInputElement>): void => {
  if (e?.key !== 'Tab') {
    return;
  }

  const resetButtonEl: HTMLButtonElement | null | undefined = (e.target as HTMLElement).parentElement?.querySelector('button[aria-label=reset]');
  if (!resetButtonEl) {
    return;
  }

  resetButtonEl.tabIndex = -1;
  e.stopPropagation();
};

export const onKeyboardEnterExecuteFunction = (e: React.KeyboardEvent<HTMLIonInputElement> | React.MouseEvent<HTMLIonButtonElement>, fn: () => void | Promise<void>): void => {
  if ((e as React.KeyboardEvent<HTMLIonInputElement>)?.key !== 'Enter') {
    return;
  }

  fn();
};

export const onTextareaCtrlEnter = (e: React.KeyboardEvent<HTMLIonTextareaElement>, fn: () => void | Promise<void>): void => {
  if (!e.ctrlKey || e.key !== 'Enter') {
    return;
  }

  fn();
};

export const getDateLabel = (messageDate: Date): string => {
  if (moment().diff(messageDate, 'weeks') > 1) {
    return moment(messageDate).format('llll');
  }
  return formatDateFromNow(messageDate);
};

export const scrollToBottom = (duration: number, ionContentRef: React.RefObject<HTMLIonContentElement>): void => {
  if (!ionContentRef.current) {
    return;
  }

  ionContentRef.current.scrollToBottom(duration);
};

export const handleScrollPosition = (ionContentRef: React.RefObject<HTMLIonContentElement>, fn: (value: HTMLElement) => void | Promise<void>): void => {
  if (!ionContentRef.current) {
    return;
  }

  ionContentRef.current.getScrollElement().then(fn);
};

export const getPrivacyAndTosLanguage = (language?: string): string => {
  if (!language) {
    return '%language%';
  }

  const validLanguagePrivacyAndTos = ['en', 'fr'];
  const languageSubstr = language?.substr(0, 2);
  return validLanguagePrivacyAndTos.includes(languageSubstr) ? languageSubstr : 'en';
};

export const routePrivacy = (): string => {
  return '/privacy';
};

export const routeTos = (language?: string): string => {
  return 'https://assets.indigo.world/website/cgu_indigo_' + getPrivacyAndTosLanguage(language) + '_v2.pdf';
};

export const openNativeSettings = async (): Promise<void> => {
  try {
    await OpenNativeSettings.open('application_details');
  } catch (e) {
    console.error(e);
  }
};

export const addStyleToShadowRoot = (nodes: NodeList, style: string): void => {
  nodes.forEach(function (node) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const HTMLScriptElement = <HTMLScriptElement>node;
    const shadow = HTMLScriptElement?.shadowRoot || HTMLScriptElement?.attachShadow({ mode: 'open' });
    if (shadow) {
      shadow.innerHTML += style;
    }
  });
};

export const rotateIconForRtlShadowRoot = (containerClassName: string, className: string): void => {
  if (getI18n().dir() !== 'rtl') {
    return;
  }
  return addStyleToShadowRoot(document.querySelectorAll(containerClassName), `<style> ${className} { transform: rotate(180deg); }</style>`);
};

export const getUserUrlFromActiveTab = (isCurrentUser: boolean, activeTab: string | undefined, user: EntityReference | Partial<User>): string => {
  return isCurrentUser ? '/me' : activeTab ? `/${activeTab}${extractId(user)}` : extractId(user);
};

interface acceptedPostalCodesAndLimitBorough {
  [id: number]: number;
}

// beginning of postalCodes and number limit for boroughs (Paris, Marseille, Lyon)
const postalCodesAndLimitBoroughs: acceptedPostalCodesAndLimitBorough = {
  750: 20,
  130: 16,
  690: 9,
};

const countriesForGetBorough = ['FR'];

export const getBoroughIfExist = (country: string, postalCode: string): string => {
  if (!countriesForGetBorough.includes(country)) {
    return '';
  }

  const startPostalCode = postalCode.substring(0, 3);
  if (undefined === postalCodesAndLimitBoroughs[parseInt(startPostalCode) as number]) {
    return '';
  }

  const endPostalCode = postalCode.slice(postalCode.length - 2);
  if (parseInt(endPostalCode) > postalCodesAndLimitBoroughs[parseInt(startPostalCode) as number] || parseInt(endPostalCode) === 0) {
    return '';
  }

  return endPostalCode;
};

export const titleTagForPostDate = (post: FullPost, i18n: i18n): string => {
  const updatedAt = post.createdAt !== post.updatedAt ? `\n${i18n.t('post.updated')} ${formatDateFromNow(post.updatedAt)}` : '';
  return `${i18n.t('post.published')} ${formatDateFromNow(post.createdAt)} ${updatedAt}`;
};

export const getPostTitle = (post: Post, t: TFunction, currentUserIsCreator = false): string => {
  if (currentUserIsCreator) {
    return post.title;
  }
  return post.state !== 'moderated' ? post.title : t('post.state-info.moderated-title');
};

export const getDisabledUserName = (user: User, t: TFunction): string => {
  return user.name === 'Deleted Account' ? t('user.deleted') : t('user.disabled');
};

export const organizationTypeNeedsValidation = (user: User): boolean => {
  if (!user.managedData?.type) {
    return false;
  }

  return organizationTypesToValidate.includes(user.managedData?.type);
};

export const messageNotificationModal = (userNotification: UserNotification, t: TFunction): string => {
  switch (userNotification.notification.reason) {
    case 'moderated_post':
      return t('notification.moderated_post_modal', { postTitle: (userNotification.notification.data as ModeratedPostData).postTitle, routeTos: routeTos(getAppLanguage()) });
    case 'moderated_report_to_reporter':
      return t(`notification.moderated_report_to_reporter_${(userNotification.notification.data as ModeratedReportToReporterData).type}_modal`, {
        postTitle: (userNotification.notification.data as ModeratedReportToReporterData).postTitle,
        blockedUserDisplayName: (userNotification.notification.data as ModeratedReportToReporterData).blockedUserDisplayName,
      });
    case 'global_message':
      return (userNotification.notification.data as GlobalNotificationData).message;
    default:
      return '';
  }
};

export const titleNotificationModal = (userNotification: UserNotification, t: TFunction): string => {
  switch (userNotification.notification.reason) {
    case 'moderated_post':
      return t('notification.moderated_post', { postTitle: (userNotification.notification.data as ModeratedPostData).postTitle });
    case 'moderated_report_to_reporter':
      return t(`notification.moderated_report_to_reporter_${(userNotification.notification.data as ModeratedReportToReporterData).type}`, {
        postTitle: (userNotification.notification.data as ModeratedPostData).postTitle,
        blockedUserDisplayName: (userNotification.notification.data as ModeratedReportToReporterData).blockedUserDisplayName,
      });
    case 'global_message':
      return 'Indigo World';
    default:
      return '';
  }
};

export const userTosAreAccepted = (currentUser: CurrentUser): boolean => {
  // We verify the user has an email to be sure he is loaded
  return currentUser.lastTosAcceptedDate !== null && !!currentUser.email;
};

export const userTosUpToDate = (currentUser: CurrentUser, tosMinDate: string): boolean => {
  // We verify the user has an email to be sure he is loaded
  return moment(currentUser.lastTosAcceptedDate).isAfter(moment(tosMinDate)) && !!currentUser.email;
};

export const openUrlInNewTab = (url: string): void => {
  window.open(url, '_blank')?.focus();
};

export const openUrlInNewTabIfExternalUrl = (url: string, history: History): void => {
  try {
    const urlObj = new URL(url);
    const domain = urlObj.hostname;
    const clientDomain = new URL(clientUrl).hostname;

    if (domain === window.location.hostname || domain === clientDomain) {
      history.push(urlObj.pathname + urlObj.search);
      return;
    }
  } catch (e) {
    console.error(e);
  }

  openUrlInNewTab(url);
};

export const stringifyError = (error: Error, space: string): string => {
  const plainObject: { [key: string]: unknown } = {};
  Object.getOwnPropertyNames(error).forEach((key: string) => {
    plainObject[key] = error?.[key as keyof Error] ?? '';
  });
  return JSON.stringify(plainObject, null, space);
};

export const searchNormalize = (s: string): string => {
  if (!s) {
    return '';
  }

  return accentFold(s.toLowerCase());
};

export const savedSearchParamsAreEqual = (params: QueryParameters, otherParams: QueryParameters): boolean => {
  return isEqual(params, otherParams);
};

export const getSavedSearchWithSameFilter = (SavedSearchList: SavedSearch[], searchParams: SearchQueryParameters): SavedSearch | undefined => {
  const searchParamsFilters: QueryParameters = filterSearchParamToQueryParam(searchParams);

  return (SavedSearchList || []).find(item => {
    if (!item?.searchFilters) {
      return false;
    }

    return savedSearchParamsAreEqual(searchParamsFilters, { ...item.searchFilters });
  });
};

export const chatListMessageFormat = (text: string, t: TFunction): string => {
  const regex = new RegExp(`https?:\\/\\/\\S+(\\.${validPictureExtension.join('|\\.')})`, 'si');

  return text.replace(regex, () => {
    return `[${t('common.image')}]`;
  });
};

export const chatMessageFormat = (text: string): string => {
  const regex = new RegExp(`^https?:\\/\\/(?!\\S+(?:\\.${validPictureExtension.join('|\\.')}))\\S+`, 'si');

  let result = linkifyStr(text, {
    validate: {
      url: (value: string) => regex.test(value),
    },
  });
  result = chatMessageImageFormat(result);
  result = phoneNumberClickable(result);

  return result;
};

const chatMessageImageFormat = (text: string): string => {
  const regex = new RegExp(`https?:\\/\\/\\S+(\\.${validPictureExtension.join('|\\.')})`, 'gi');

  return text.replace(regex, img => {
    return '<div class="chat-img"><a href="' + img + '" target="_blank"><img src="/assets/icon/download.svg" class="icon-download" /></a><img src="' + img + '" alt="img"/></div>';
  });
};

const phoneNumberClickable = (text: string): string => {
  const phoneRegex = '(?:(?:\\+|00)\\d{2,3}[\\s.-]{0,3}(?:\\(0\\)[\\s.-]{0,3})?|0)[1-9](?:(?:[\\s.-]?\\d{2}){4}|\\d{2}(?:[\\s.-]?\\d{3}){2})';
  // Only phone numbers at the beginning of the string, or preceded by a space are captured
  const selectablePhoneRegex = new RegExp(`(?:^${phoneRegex}|\\s${phoneRegex})`, 'g');

  return text.replace(selectablePhoneRegex, phone => {
    return '<a href="tel:' + phone.replace(/\s/g, '') + '">' + phone + '</a>';
  });
};

export const getFixedWeight = (weight: number, fractionDigits = 3): number => {
  if (weight === 0) {
    return 0;
  }

  return parseFloat(weight?.toFixed(fractionDigits));
};

export const getWeightInUnit = (weightInKg: number, unit: WeightUnit): number => {
  if (unit === 'g') {
    return getFixedWeight(weightInKg * 1000, 0);
  }

  return getFixedWeight(weightInKg);
};

export const getWeightWithAppropriateUnit = (weightInKg: number): string => {
  const showWeightInKg = parseInt(weightInKg.toString()) > 0;
  const unit: WeightUnit = showWeightInKg ? 'kg' : 'g';

  return `${getWeightInUnit(weightInKg, unit)} ${unit}`;
};

export const countActiveSearchParams = (params: SearchQueryParameters): number => {
  let countFilterParams = filterParamsForCountActive.filter(filter => {
    const keyTyped = filter as keyof typeof params;
    if (_.has(params, filter) && params[keyTyped]) {
      return filter;
    }
  }).length;

  params.categorySelection.object.category && countFilterParams++;
  params.categorySelection.service.category && countFilterParams++;
  params.categorySelection.object.isSelected && countFilterParams++;
  params.categorySelection.service.isSelected && countFilterParams++;

  return countFilterParams;
};
