import { ActionType, getType } from 'typesafe-actions';
import { POSTS_TAB } from '../../utils/TabNames';
import * as navigationActions from './actions';
import { NavigationState } from './types';
import { getPreviousTabHistory } from './utils';

export const defaultState: NavigationState = {
  activeTab: undefined,
  tabHistory: {},
};

export default (state = defaultState, action: ActionType<typeof navigationActions>): NavigationState => {
  switch (action.type) {
    case getType(navigationActions.setActiveTabAction):
      return { ...state, activeTab: action.payload };
    case getType(navigationActions.setTabHistoryAction):
      if (action.payload.pathname === '/' + action.payload.tab) {
        return {
          ...state,
          tabHistory: { ...state.tabHistory, [action.payload.tab]: [{ pathname: action.payload.pathname }] },
        };
      }

      if (action.payload.pathname.includes('/posts/create/') && action.payload.tab === POSTS_TAB) {
        if (state.tabHistory[POSTS_TAB]?.[0]?.pathname?.includes('/posts/create/')) {
          // If we are switching from need to offer or reverse in the CreatePostPage, we don't want to stack those url in the tab history.
          // Instead, we replace the previous tabHistory pathname with the new one.
          // ex: '/posts/create/need' will become '/posts/create/offer'
          const historyReplaced = state.tabHistory[POSTS_TAB];
          historyReplaced[0] = { pathname: action.payload.pathname };
          return {
            ...state,
            tabHistory: { ...state.tabHistory, [POSTS_TAB]: historyReplaced },
          };
        }
      }

      if (action.payload.direction === 'back') {
        const previousTabHistory = getPreviousTabHistory(state.tabHistory[action.payload.tab] ?? []);
        if (previousTabHistory.length < 1) {
          return state;
        }

        return {
          ...state,
          tabHistory: { ...state.tabHistory, [action.payload.tab]: previousTabHistory },
        };
      }

      return {
        ...state,
        tabHistory: { ...state.tabHistory, [action.payload.tab]: [{ pathname: action.payload.pathname }, ...(state.tabHistory[action.payload.tab] ?? [])] },
      };
    default:
      return state;
  }
};
