import React, { PureComponent, ReactNode } from 'react';
import { IonImg } from '@ionic/react';

interface State {
  src: string | undefined;
}

interface ComponentProps {
  src?: string | Promise<string>;
  useNativeImg?: boolean;
  className?: string;
}

type Props = ComponentProps & Omit<React.ComponentProps<typeof IonImg>, 'src'>;

export default class AsyncImg extends PureComponent<Props, State> {
  private _isMounted = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      src: typeof this.props.src === 'string' ? this.props.src : undefined,
    };
  }

  componentDidMount(): void {
    this._isMounted = true;
    if (typeof this.props.src === 'object' && typeof this.props.src.then !== 'undefined') {
      this.props.src.then(src => {
        if (!this._isMounted) {
          return;
        }
        this.setState({ src });
      });
    }
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  render(): ReactNode {
    if (this.props.useNativeImg) {
      return (
        <div className={this.props.className ?? ''}>
          <img alt="" src={this.state.src} />
        </div>
      );
    }
    return <IonImg {...this.props} src={this.state.src} />;
  }
}
