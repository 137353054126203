import { IonButton, IonThumbnail, IonContent, IonModal, IonToolbar, IonButtons, IonIcon } from '@ionic/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import { getManagedUserInfos } from '../../store/layout/selector';
import { ManagedUserInfos } from '../../store/layout/types';

import './PromotionalWelcomeModal.scss';

interface StateProps {
  managedUserInfos?: ManagedUserInfos;
}

const mapStateToProps = (state: RootState): StateProps => ({
  managedUserInfos: getManagedUserInfos(state),
});

type Props = StateProps;

const PromotionalWelcomeModal: React.FunctionComponent<Props> = (props: Props) => {
  const [displayPromotionalWelcomeModal, setDisplayPromotionalWelcomeModal] = useState(false);
  const closeModal = (): void => {
    // Add to localeStorage so that the PromotionalWelcomeModal is displayed only every x days (currently 7)
    localStorage.setItem('promotionalWelcomeModalLastDisplayDate', moment().toISOString());
    setDisplayPromotionalWelcomeModal(false);
  };

  useEffect(() => {
    setDisplayPromotionalWelcomeModal(
      window.location.pathname === '/posts' &&
        !!props.managedUserInfos &&
        (!localStorage.getItem('promotionalWelcomeModalLastDisplayDate') || moment(localStorage.getItem('promotionalWelcomeModalLastDisplayDate')).isBefore(moment().subtract(7, 'd'))),
    );
  }, [props.managedUserInfos]);

  return (
    <IonModal isOpen={displayPromotionalWelcomeModal} id="promotional-welcome-modal" className="promotional-welcome-modal safe-area-ios" onDidDismiss={closeModal}>
      <IonToolbar>
        <IonButtons slot="end">
          <IonButton onClick={closeModal} data-cy="close-button" className="close-button">
            <IonIcon slot="icon-only" src="/assets/navigation/close.svg" color="dark" />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonContent>
        <div className="flex-center">
          <div className="ion-text-center">
            <IonThumbnail>
              <img src={props.managedUserInfos?.welcomeImageUrl} alt={props.managedUserInfos?.welcomeFrom} />
            </IonThumbnail>
            <ReactMarkdown>{props.managedUserInfos?.welcomeText || ''}</ReactMarkdown>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default connect(mapStateToProps)(PromotionalWelcomeModal);
