import { ActionType, StateType } from 'typesafe-actions';
import { Middleware } from 'redux';
import { RehydrateAction } from 'redux-persist/lib/types';

import rootReducer from './root-reducer';

import * as chatsSelectors from './chats/selectors';
import * as entitiesSelectors from './entities/selectors';
import * as navigationSelectors from './navigation/selectors';
import * as postsSelectors from './posts/selectors';
import * as thematicsSelectors from './thematics/selectors';
import * as notificationsSelectors from './notifications/selectors';

import * as appActions from './app/actions';
import * as blockedUsersActions from './blockedUsers/actions';
import * as categoriesActions from './categories/actions';
import * as chatsActions from './chats/actions';
import * as whoAmIActions from './whoAmI/actions';
import * as entitiesActions from './entities/actions';
import * as layoutActions from './layout/actions';
import * as navigationActions from './navigation/actions';
import * as notificationsActions from './notifications/actions';
import * as postRequestsActions from './postRequests/actions';
import * as postsActions from './posts/actions';
import * as reportsActions from './reports/actions';
import * as thematicsActions from './thematics/actions';
import * as usersActions from './users/actions';

import { appMiddleware } from './app/middleware';
import { blockMiddleware } from './blockedUsers/middleware';
import { chatMiddleware } from './chats/middleware';
import { layoutMiddleware } from './layout/middleware';
import { notificationMiddleware } from './notifications/middleware';
import { postMiddleware } from './posts/middleware';
import { reportMiddleware } from './reports/middleware';
import { storeMiddleware } from './middleware';
import { userMiddleware } from './users/middleware';

export { store as default, store, persistor } from './store';
export { default as rootReducer } from './root-reducer';

export const selectors = {
  chats: chatsSelectors,
  entities: entitiesSelectors,
  navigation: navigationSelectors,
  posts: postsSelectors,
  thematics: thematicsSelectors,
  notifications: notificationsSelectors,
};

export const actions = {
  app: appActions,
  blockedUsersActions: blockedUsersActions,
  categoriesActions: categoriesActions,
  chats: chatsActions,
  entities: entitiesActions,
  layout: layoutActions,
  navigation: navigationActions,
  notifications: notificationsActions,
  postRequests: postRequestsActions,
  posts: postsActions,
  reportsActions: reportsActions,
  thematicsActions: thematicsActions,
  users: usersActions,
  whoAmI: whoAmIActions,
};

export function getMiddlewares(): Middleware[] {
  return [storeMiddleware, appMiddleware, layoutMiddleware, chatMiddleware, notificationMiddleware, postMiddleware, userMiddleware, reportMiddleware, blockMiddleware];
}

export type RootState = StateType<typeof rootReducer>;
export type RootActions = ActionType<typeof actions | RehydrateAction>;
