import { IonAvatar, IonContent, IonItem, IonLabel, IonList, IonIcon, IonFab, IonFabButton, IonButton, IonText, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonLoading } from '@ionic/react';
import isEqual from 'lodash/isEqual';
import React, { Component, ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { Chat, Message } from '../store/chats/types';
import { UserReview, ReviewType, User, CurrentUser } from '../store/users/types';
import { HydratedCollection } from '../utils/hydra';
import {
  chatMessageFormat,
  extractId,
  getDateLabel,
  getUserAvatar,
  getUserUrlFromActiveTab,
  handleScrollPosition,
  isCurrentUser,
  isEqualIgnoringFunctions,
  isSameHydraEntity,
  scrollToBottom,
} from '../utils/helpers';
import moment from 'moment';
import { PostRequest, PostRequestStateHistory } from '../store/postRequests/types';
import RoutingItem from './common/RoutingItem';
import AddReviewModal from './connected/AddReviewModal';
import last from 'lodash/last';
import i18next from 'i18next';
import { PostStateHistory } from '../store/posts/types';

import './MessageList.scss';

const maxTimeToSendMessageAfterPostRequestCreation = 10; // This const is used to determine if we can display the post request creation systemMessage

interface Props {
  messages: HydratedCollection<Message>;
  chat: Chat;
  fetchNextMessagesPage: () => void;
  currentUser: CurrentUser;
  reviews?: UserReview[];
  activeTab?: string;
}

interface State {
  showFabButton: boolean;
  modalReviewIsOpen: boolean;
  reviewType: ReviewType;
}

type SystemMessagesType = (PostRequestStateHistory | PostStateHistory | UserReview)[];

class MessagesList extends Component<Props, State> {
  private readonly ionContentRef = React.createRef<HTMLIonContentElement>();
  private readonly ionInfiniteScrollRef = React.createRef<HTMLIonInfiniteScrollElement>();

  public constructor(props: Props) {
    super(props);
    this.state = {
      showFabButton: false,
      modalReviewIsOpen: false,
      reviewType: 'positive',
    };
  }

  public componentDidMount(): void {
    setTimeout(() => scrollToBottom(0, this.ionContentRef), 500);
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (extractId(prevProps.chat, true) !== extractId(this.props.chat, true)) {
      scrollToBottom(200, this.ionContentRef);
      return;
    }

    if (prevProps.messages.totalItems === 0 && this.props.messages.totalItems > 0) {
      setTimeout(() => scrollToBottom(200, this.ionContentRef));
      return;
    }

    if (prevProps.chat.postRequest?.stateHistory.length !== this.props.chat.postRequest?.stateHistory.length) {
      setTimeout(() => scrollToBottom(200, this.ionContentRef));
    }

    if (prevProps.messages.nextPage && prevProps.messages.nextPage !== this.props.messages.nextPage) {
      this.ionInfiniteScrollRef.current?.complete();
      return;
    }

    if (prevProps.messages.items.length !== this.props.messages.items.length) {
      setTimeout(() => scrollToBottom(200, this.ionContentRef));
      return;
    }
  }

  public shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>): boolean {
    return !isEqualIgnoringFunctions(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public render(): ReactNode {
    const messagesList = this.props.messages.items;
    const { chat, messages, reviews, currentUser, fetchNextMessagesPage } = this.props;
    let systemMessages: SystemMessagesType = [];

    // postStateHistory will list the post stateHistory where a post exchange was accepted or canceled.
    // We keep only the changes that were made after the first action of the current chat.
    // These state will be used to show system messages.
    let postStateHistory: PostStateHistory[] = [];

    if (chat.postRequest?.post.stateHistory && chat.postRequest.state === 'waiting') {
      postStateHistory = chat.postRequest?.post.stateHistory.filter(
        stateHistory => (stateHistory.reason === 'post_request_accepted' || stateHistory.reason === 'post_request_canceled') && stateHistory.date > (chat.postRequest?.stateHistory[0].date || 0),
      );
    }

    if (chat.postRequest && reviews) {
      systemMessages = [
        ...[...chat.postRequest.stateHistory, ...postStateHistory].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()),
        ...reviews.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()),
      ];
    }

    /* TODO Add loader (placeholder ?) for messages */
    return (
      <IonContent ref={this.ionContentRef} scrollEvents onIonScrollEnd={this.handleScrollPosition} className="messages-list has-footer">
        <IonLoading spinner="bubbles" showBackdrop={false} isOpen={messages.isLoading && !messages.lastFetchDate} />
        {messages.totalItems === 0 && chat.type === 'direct' && !messages.isLoading && messages.lastFetchDate ? (
          <IonLabel className="ion-text-center no-messages">
            <Trans i18nKey="chat.no-messages-found" />
          </IonLabel>
        ) : (
          <>
            {this.state.showFabButton && (
              <IonFab vertical="bottom" horizontal="center" slot="fixed">
                <IonFabButton className="scroll-down-fab-button" color="white" size="small" onClick={() => scrollToBottom(200, this.ionContentRef)}>
                  <IonIcon icon="/assets/navigation/chevron-down.svg" mode="md" color="primary" />
                </IonFabButton>
              </IonFab>
            )}

            <IonInfiniteScroll ref={this.ionInfiniteScrollRef} position="top" threshold="15%" onIonInfinite={fetchNextMessagesPage} disabled={!messages.nextPage}>
              <IonInfiniteScrollContent loading-spinner="circles" />
            </IonInfiniteScroll>

            <IonList lines="none" className="messages-list">
              {messagesList.map((message: Message, key) => {
                const showTimeLabel = !messagesList[key - 1] || this.showTimeLabel(new Date(messagesList[key - 1].createdAt), new Date(message.createdAt));
                const showUserAvatar =
                  !isCurrentUser(currentUser, message.createdBy) && (messagesList[key + 1] === undefined || !isSameHydraEntity(message.createdBy, messagesList[key + 1].createdBy));

                return (
                  <React.Fragment key={key}>
                    {chat.type === 'post_request' && this.getSystemMessageToShow(messagesList[key - 1] ? new Date(messagesList[key - 1].createdAt) : null, message, systemMessages)}
                    {showTimeLabel && <IonLabel className="date capitalize-first-letter">{getDateLabel(new Date(message.createdAt))}</IonLabel>}
                    {this.messageItem(message, showUserAvatar)}
                  </React.Fragment>
                );
              })}

              {chat.type === 'post_request' && chat.postRequest && messages.lastFetchDate && (
                <>
                  {!messagesList.length
                    ? // If no messages, we display all the system messages
                      (systemMessages ?? chat.postRequest.stateHistory).map(item => this.systemMessage(item))
                    : // We verify if there is system messages to show after the last message
                      this.getSystemMessageToShow(null, last(messagesList) as Message, systemMessages, true)}
                </>
              )}
            </IonList>
          </>
        )}
        {/* The Modal must always be present in the DOM, as if it's open while a parent component render even once without it, for instance with a ternary condition */}
        <AddReviewModal
          isOpen={this.state.modalReviewIsOpen}
          closeModal={() => this.setState({ modalReviewIsOpen: false })}
          reviewedUser={this.props.chat.properties?.firstUsers?.[0]}
          reviewedPostRequest={this.props.chat.postRequest as PostRequest}
          reviewType={this.state.reviewType}
          userIsRequester={isCurrentUser(this.props.currentUser, this.props.chat.postRequest?.user)}
        />
      </IonContent>
    );
  }

  private getSystemMessageToShow = (prevMessageDate: Date | null, nextMessage: Message, systemMessages: SystemMessagesType, verifyIfSystemMessagesAfterLastMessage = false): ReactNode => {
    const prevMessageMoment = prevMessageDate ? moment(prevMessageDate) : null;
    const nextMessageMoment = moment(new Date(nextMessage.createdAt));

    if (!this.props.chat.postRequest) {
      return <></>;
    }

    let filteredStates: SystemMessagesType;

    if (!verifyIfSystemMessagesAfterLastMessage) {
      filteredStates = systemMessages.filter(item => {
        if (!prevMessageMoment) {
          return !this.props.messages.nextPage ? moment(new Date((item as PostRequestStateHistory).date || (item as UserReview).createdAt)).isBefore(nextMessageMoment) : false;
        }

        return moment(new Date((item as PostRequestStateHistory).date || (item as UserReview).createdAt)).isBetween(prevMessageMoment, nextMessageMoment);
      });
    } else {
      filteredStates = systemMessages.filter(item => nextMessageMoment.isBefore(moment(new Date((item as PostRequestStateHistory).date || (item as UserReview).createdAt))));
    }

    return filteredStates
      .filter(systemMessage => {
        // We don't want to show the first systemMessage of the conversation
        // Since the ticket !1162, when a user creates a post request, he sends a first message directly after the chat creation
        // To avoid empty chats we will show this systemMessage if no messages has been sent by the post requester in X seconds after the post request creation (currently 10)
        if (!MessagesList.instanceOfReview(systemMessage) && systemMessage.to === 'waiting' && isSameHydraEntity(nextMessage.createdBy, this.props.chat.postRequest?.user)) {
          const maxDateToSendMessage = moment(new Date(systemMessage.date)).add(maxTimeToSendMessageAfterPostRequestCreation, 'seconds').toDate();
          return new Date(nextMessage.createdAt) >= maxDateToSendMessage;
        }
        return true;
      })
      .map(filteredState => this.systemMessage(filteredState));
  };

  private systemMessage = (systemMessage: PostRequestStateHistory | PostStateHistory | UserReview): ReactNode => {
    const userIsRequester = isCurrentUser(this.props.currentUser, this.props.chat.postRequest?.user);
    const otherUser = this.props.chat.properties.firstUsers[0];
    const positiveReviewsNumber = otherUser?.collections?.positivePostRequestReviews?.totalItems;

    // Check type between StateHistory and Review
    if (MessagesList.instanceOfReview(systemMessage)) {
      return this.systemMessageReview(systemMessage);
    }

    let postStateHistory;

    if (systemMessage.to === 'rejected' && this.props.chat.postRequest?.post?.stateHistory) {
      postStateHistory = this.props.chat.postRequest?.post?.stateHistory.filter(value => value.to === 'deleted')[0];
    }

    let messageSystem = `chat.system-message-${userIsRequester ? 'requester' : 'creator'}-${systemMessage.to}-${this.props.chat.postRequest?.post.categoryType}-${
      this.props.chat.postRequest?.post.type
    }`;

    if (systemMessage.to === 'rejected' && systemMessage.from === 'accepted') {
      messageSystem += '-' + 'canceled_transaction';
    }

    if (systemMessage.reason) {
      messageSystem += '-' + systemMessage.reason;
    }

    if (postStateHistory?.reason === 'too_old' || postStateHistory?.reason === 'deleted_creator') {
      messageSystem += '-' + postStateHistory.reason;
    }

    return (
      <React.Fragment key={systemMessage.to}>
        {(i18next.t(messageSystem) !== messageSystem || systemMessage.to === 'waiting') && (
          <>
            <IonLabel className="date capitalize-first-letter">{getDateLabel(new Date(systemMessage.date || ''))}</IonLabel>
            <IonItem lines="none" className="message-item system-message">
              {(userIsRequester || systemMessage.to === 'waiting') && systemMessage.to !== 'rejected' && (
                <RoutingItem route={getUserUrlFromActiveTab(isCurrentUser(this.props.currentUser, otherUser), this.props.activeTab, otherUser)}>
                  <IonAvatar slot="start" className="message-avatar">
                    <IonImg alt="" src={getUserAvatar(otherUser)} />
                  </IonAvatar>
                </RoutingItem>
              )}

              <IonLabel color="dark" className={`message-text-label ${!userIsRequester && systemMessage.to !== 'waiting' ? 'current-user-text' : ''}`}>
                <Trans i18nKey={systemMessage.to !== 'waiting' ? messageSystem : 'chat.system-message-waiting'} values={{ userName: otherUser.name }} />

                {systemMessage.to === 'waiting' && (
                  <IonText className="user-rating">
                    {positiveReviewsNumber !== undefined && (positiveReviewsNumber !== 0 ? i18next.t('chat.user-recommended', { count: positiveReviewsNumber }) : i18next.t('chat.no-ratings'))}
                  </IonText>
                )}
              </IonLabel>
            </IonItem>
          </>
        )}
        {systemMessage.to === 'completed' && !this.props.reviews?.length && <>{this.completedSystemMessage()}</>}
      </React.Fragment>
    );
  };

  private static instanceOfReview(object: UserReview | PostRequestStateHistory | PostStateHistory): object is UserReview {
    return 'reviewedUser' in object;
  }

  private systemMessageReview = (review: UserReview): ReactNode => {
    if (!this.props.reviews) {
      return;
    }
    const currentUserReview = this.props.reviews.find(value => isCurrentUser(this.props.currentUser, value.createdBy));

    if (!isCurrentUser(this.props.currentUser, review.createdBy)) {
      return (
        <div key={extractId(review)} className="recommend-user-message">
          <IonText>
            <Trans i18nKey={`review.other-user-review-is-${review.type}`} values={{ userName: review.createdBy.name }} />
          </IonText>
          <IonText className="comment">{review.comment}</IonText>

          {!currentUserReview && review.comment && <span className="separation-line" />}

          {!currentUserReview && this.recommendUserCallToAction(review.createdBy)}
        </div>
      );
    }

    return (
      <div key={extractId(review)} className="recommend-user-message">
        <IonText>
          <Trans i18nKey={`review.current-user-review-is-${review.type}`} values={{ userName: review.reviewedUser.name }} />
        </IonText>
        <IonText className="comment">{review.comment}</IonText>
      </div>
    );
  };

  private completedSystemMessage = (): ReactNode => {
    const otherUser = this.props.chat.properties.firstUsers[0];

    return <div className="recommend-user-message">{this.recommendUserCallToAction(otherUser)}</div>;
  };

  private recommendUserCallToAction = (otherUser: User): ReactNode => {
    return (
      <>
        <IonText>
          <Trans i18nKey="chat.would-you-recommend" values={{ userName: otherUser.name }} />
        </IonText>
        <IonItem lines="none">
          <IonButton fill="outline" color="primary" size="small" onClick={() => this.setState({ modalReviewIsOpen: true, reviewType: 'negative' })}>
            <Trans i18nKey="common.no" />
          </IonButton>
          <IonButton color="primary" size="small" onClick={() => this.setState({ modalReviewIsOpen: true, reviewType: 'positive' })}>
            <Trans i18nKey="common.yes" />
          </IonButton>
        </IonItem>
      </>
    );
  };

  // TODO. Once we'll want to share app links in messages, add regex verification for deeplinks.
  private messageItem = (message: Message, showUserAvatar: boolean): ReactNode => {
    return (
      <IonItem lines="none" className={`message-item ${!showUserAvatar ? 'padding-start' : ''}`}>
        {showUserAvatar && (
          <RoutingItem route={getUserUrlFromActiveTab(isCurrentUser(this.props.currentUser, message.createdBy), this.props.activeTab, message.createdBy)}>
            <IonAvatar slot="start" className="message-avatar">
              <IonImg alt="" src={getUserAvatar(message.createdBy)} />
            </IonAvatar>
          </RoutingItem>
        )}
        <IonLabel data-cy="chat-message" color="dark" className={`message-text-label ${isCurrentUser(this.props.currentUser, message.createdBy) ? 'current-user-text' : ''}`}>
          <span
            dangerouslySetInnerHTML={{
              __html: chatMessageFormat(message.text),
            }}
          />
        </IonLabel>
      </IonItem>
    );
  };

  private showTimeLabel = (previousMessageDate: Date, actualMessageDate: Date): boolean => {
    const prevMessageMoment = moment(previousMessageDate);
    const actualMessageMoment = moment(actualMessageDate);

    if (moment().diff(actualMessageMoment, 'days') > 1) {
      return actualMessageMoment.diff(prevMessageMoment, 'hours') > 12;
    }

    if (moment().diff(actualMessageMoment, 'hours') > 1) {
      return actualMessageMoment.diff(prevMessageMoment, 'hours') > 2;
    }

    if (moment().diff(actualMessageMoment, 'hours', true) > 0.5) {
      return actualMessageMoment.diff(prevMessageMoment, 'minutes') > 15;
    }

    return actualMessageMoment.diff(prevMessageMoment, 'minutes') > 3;
  };

  private handleScrollPosition = (): void => {
    handleScrollPosition(this.ionContentRef, (value: HTMLElement) =>
      this.setState({ showFabButton: value.scrollHeight > (this?.ionContentRef?.current?.scrollHeight || value.scrollWidth) + value.scrollTop + 100 }),
    );
  };
}

export default MessagesList;
