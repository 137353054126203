import { IonContent, IonPage, IonRefresher, IonRefresherContent } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import PostRequestsList, { ItemWithPostInfos } from '../components/PostRequestsList';
import { actions, RootState } from '../store';
import { denormalizeEntityCollection } from '../store/entities/selectors';
import { PostRequest } from '../store/postRequests/types';
import { HydratedCollection } from '../utils/hydra';
import CommonHeader from '../components/CommonHeader';

interface StateProps {
  postRequests: HydratedCollection<PostRequest>;
}

const mapStateToProps = (state: RootState): StateProps => ({
  postRequests: denormalizeEntityCollection<PostRequest>(state.entities, state.postRequests.currentUserPostRequests),
});

interface DispatchProps {
  fetchPostRequests: () => Promise<void>;
}

const propsToDispatch = {
  fetchPostRequests: actions.postRequests.fetchUserPostRequests,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type PostRequestsProps = StateProps & DispatchProps & WithTranslation;

class PostRequestsPage extends Component<PostRequestsProps> {
  private readonly ionRefresherRef: React.RefObject<HTMLIonRefresherElement>;

  public constructor(props: PostRequestsProps) {
    super(props);
    this.ionRefresherRef = React.createRef<HTMLIonRefresherElement>();
  }

  public componentDidMount(): void {
    this.doRefresh();
  }

  public componentDidUpdate(prevProps: Readonly<PostRequestsProps>): void {
    if (this.ionRefresherRef.current && !this.props.postRequests.isLoading && prevProps.postRequests.isLoading) {
      this.ionRefresherRef.current.complete();
    }
  }

  public render(): ReactNode {
    return (
      <IonPage>
        <CommonHeader addIonHeader={true} title={<Trans i18nKey="post-request.my-post-requests" />} />

        <IonContent>
          <IonRefresher ref={this.ionRefresherRef} onIonRefresh={this.doRefresh} slot="fixed">
            <IonRefresherContent />
          </IonRefresher>

          <PostRequestsList postRequests={this.props.postRequests} ItemComponent={ItemWithPostInfos} />
        </IonContent>
      </IonPage>
    );
  }

  private doRefresh = (): void => {
    this.props.fetchPostRequests();
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PostRequestsPage));
