import { IonButton, IonIcon, IonThumbnail, IonText, IonActionSheet, IonAlert, ActionSheetButton } from '@ionic/react';
import { save as saveIcon, trash as trashIcon } from 'ionicons/icons';
import React, { Component, ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Media } from '../../store/app/types';
import { arrayAreEqual, arrayDifference, getItemSrc, openNativeSettings, urltoFile, extractId } from '../../utils/helpers';
import { FormValue } from '../DynamicForm';
import { Capacitor } from '@capacitor/core';
import AsyncImg from '../AsyncImg';
import ChooseDefaultPictureModal from '../ChooseDefaultPictureModal';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import './InputMediaUploader.scss';

const AUTHORIZED_FILE_MIME_TYPES: string = ['image/jpg', 'image/jpeg', 'image/png'].join(',');
const AUTHORIZED_FILE_EXTENSIONS: string[] = ['jpg', 'jpeg', 'png'];

export function itemIsAMedia(item: Media | unknown): item is Media {
  if (!item) {
    return false;
  }
  return (item as Media).contentUrl !== undefined;
}

export interface PicturePlaceholderProps {
  imageSrc: Promise<string>;
  deleteItem: () => void;
}

export interface InputMediaUploaderProps {
  onShowActionSheetChange?: (value: boolean) => void;
  isMediaUploaderField: boolean;
  maxFiles?: number;
  multiple?: boolean;
  value?: (Media | File)[];
  onValueChange?: (value: FormValue) => void;
  onError?: (value: string, options?: Record<string, unknown>) => void;
  picturePlaceholder?: React.FunctionComponent<PicturePlaceholderProps>;
  addPicturePlaceholder?: ReactNode;
  defaultPictures?: string[];
  displayValidationIcon?: boolean;
  instantUpload?: boolean;
}

interface State {
  filesAndMedia: (Media | File)[];
  deletedMedia: Media[];
  showActionSheet: boolean;
  showCameraPermissionAlert: boolean;
  errorAlertMessage: string;
  showWebActionSheet: boolean;
  chooseDefaultPictureModalIsOpen: boolean;
}

type Props = InputMediaUploaderProps & WithTranslation;

class InputMediaUploader extends Component<Props, State> {
  refInput: React.RefObject<HTMLInputElement>;
  mediaUploaderContainerRef: React.RefObject<HTMLDivElement>;
  newFilesIds: WeakMap<File, string>;

  constructor(props: Props) {
    super(props);

    let defaultValue = this.props.value || [];
    if (!Array.isArray(defaultValue) && defaultValue) {
      defaultValue = [defaultValue];
    }
    this.state = {
      filesAndMedia: [...defaultValue],
      deletedMedia: [],
      showActionSheet: false,
      showWebActionSheet: false,
      chooseDefaultPictureModalIsOpen: false,
      showCameraPermissionAlert: false,
      errorAlertMessage: '',
    };
    this.refInput = React.createRef();
    this.mediaUploaderContainerRef = React.createRef();
    this.newFilesIds = new WeakMap<File, string>();
  }

  public shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>): boolean {
    return !arrayAreEqual(nextProps.value || [], this.props.value || []) || nextState !== this.state;
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (Array.isArray(this.props.value) && !arrayAreEqual(prevProps.value || [], this.props.value || [])) {
      this.setState({ filesAndMedia: [...(this.props.value || [])] });

      return;
    }

    if (!this.props.onValueChange) {
      return;
    }

    const difference = arrayDifference<Media | File>(Array.isArray(this.props.value) ? this.props.value : [this.props.value as never] || [], this.state.filesAndMedia);

    if (difference.length) {
      this.props.onValueChange(this.state.filesAndMedia);
    }
  }

  private actionSheetButtons(): ActionSheetButton[] {
    const defaultButtons = [
      {
        text: this.props.i18n.t('media.take-picture'),
        handler: this.uploadCameraPhoto,
      },
      {
        text: this.props.i18n.t('media.choose-from-gallery'),
        handler: this.openFilesSelector,
      },
    ];

    if (this.props.defaultPictures) {
      //The avatar word is hardcoded for now, and should be passed by props as soon as we need to use the default pictures for anything else.
      defaultButtons.push({ text: this.props.i18n.t('media.choose-avatar'), handler: () => this.setState({ chooseDefaultPictureModalIsOpen: true }) });
    }

    return defaultButtons;
  }

  private webActionSheetButtons(): ActionSheetButton[] {
    const defaultButtons = [
      {
        text: this.props.i18n.t('media.select-image'),
        handler: this.openFilesSelector,
      },
    ];

    if (this.props.defaultPictures) {
      //The avatar word is hardcoded for now, and should be passed by props as soon as we need to use the default pictures for anything else.
      defaultButtons.push({ text: this.props.i18n.t('media.choose-avatar'), handler: () => this.setState({ chooseDefaultPictureModalIsOpen: true }) });
    }

    return defaultButtons;
  }

  private updateShowActionSheet(value: boolean): void {
    this.setState({ showActionSheet: value });
    if (this.props.onShowActionSheetChange) {
      this.props.onShowActionSheetChange(value);
    }
  }

  private onClickThumbnail(): void {
    if (Capacitor.isNativePlatform()) {
      this.updateShowActionSheet(true);
      return;
    }

    if (this.props.defaultPictures) {
      this.setState({ showWebActionSheet: true });
      return;
    }

    this.openFilesSelector();
  }

  public render(): ReactNode {
    const { t, picturePlaceholder, addPicturePlaceholder, maxFiles, multiple, defaultPictures, displayValidationIcon, instantUpload } = this.props;
    const { filesAndMedia, showActionSheet, showWebActionSheet } = this.state;

    return (
      <div ref={this.mediaUploaderContainerRef} className={`media-uploader ion-flex ion-wrap ${filesAndMedia.length > 0 ? 'media-uploader-with-preview' : ''}`}>
        {displayValidationIcon && <img alt="validation" className={`icon-svg-validation icon-media ${filesAndMedia.length > 0 ? 'field-valid' : ''}`} src="/assets/form/icon-validation.svg" />}
        {!instantUpload && (
          <>
            {filesAndMedia.map((item, key) => (
              <IonThumbnail key={itemIsAMedia(item) ? extractId(item) : this.newFilesIds.get(item) || key} className="preview">
                {picturePlaceholder ? (
                  picturePlaceholder({ imageSrc: getItemSrc(item), deleteItem: () => this.deleteItem(item) })
                ) : (
                  <>
                    <IonButton onClick={() => this.deleteItem(item)} size="small" color="danger" className="delete-button">
                      <IonIcon icon={trashIcon} />
                    </IonButton>
                    <AsyncImg alt={'' + key} src={getItemSrc(item)} />
                  </>
                )}
                {itemIsAMedia(item) && <IonIcon icon={saveIcon} title={t('media.item-saved')} className="saved-icon" color="success" />}
                {filesAndMedia.length > 1 && (
                  <IonText className="image-number" color="white">
                    {key + 1}
                  </IonText>
                )}
              </IonThumbnail>
            ))}
          </>
        )}

        {instantUpload ? (
          <div onClick={() => this.onClickThumbnail()}>{addPicturePlaceholder}</div>
        ) : (
          <IonThumbnail className="add-picture-thumbnail" hidden={!!maxFiles && filesAndMedia.length >= maxFiles} onClick={() => this.onClickThumbnail()}>
            {addPicturePlaceholder ? (
              addPicturePlaceholder
            ) : (
              <IonButton fill="clear" className="add-button" size="large" color="dark">
                +
              </IonButton>
            )}
          </IonThumbnail>
        )}
        <input accept={AUTHORIZED_FILE_MIME_TYPES} ref={this.refInput} hidden type="file" multiple={!!multiple} onChange={e => this.addPictures(e.target.files)} />

        <IonActionSheet isOpen={showActionSheet} onDidDismiss={() => this.updateShowActionSheet(false)} buttons={this.actionSheetButtons()} />
        <IonActionSheet isOpen={showWebActionSheet} onDidDismiss={() => this.setState({ showWebActionSheet: false })} buttons={this.webActionSheetButtons()} />

        <IonAlert
          cssClass="confirmation-alert"
          isOpen={this.state.showCameraPermissionAlert}
          onDidDismiss={() => this.setState({ showCameraPermissionAlert: false })}
          header={t('media.your-camera-is-not-activated')}
          message={t('media.activate-your-camera')}
          buttons={[
            {
              text: t('media.access-to-parameters'),
              handler: () => openNativeSettings(),
            },
            {
              text: t('common.cancel'),
              role: 'cancel',
              cssClass: 'secondary',
            },
          ]}
        />

        <IonAlert
          cssClass="confirmation-alert"
          isOpen={!!this.state.errorAlertMessage}
          onDidDismiss={() => this.setState({ errorAlertMessage: '' })}
          header={this.state.errorAlertMessage}
          buttons={[
            {
              text: t('common.close'),
              role: 'close',
              cssClass: 'secondary',
            },
          ]}
        />
        {defaultPictures && (
          <ChooseDefaultPictureModal
            defaultPictures={defaultPictures}
            addPicture={this.addPicture}
            isOpen={this.state.chooseDefaultPictureModalIsOpen}
            closeModal={() => this.setState({ chooseDefaultPictureModalIsOpen: false })}
          />
        )}
      </div>
    );
  }

  private openFilesSelector = (): void => {
    if (!this.refInput.current) {
      return;
    }

    this.refInput.current.click();
    this.updateShowActionSheet(false);
  };

  private addNewFile(selectorFiles: File, files: File[]): void {
    if (!!this.props.maxFiles && this.state.filesAndMedia.length >= this.props.maxFiles) {
      return;
    }

    const lastDotInString = selectorFiles.name.lastIndexOf('.');
    const fileExtension = selectorFiles.name.substring(lastDotInString + 1);

    if (!AUTHORIZED_FILE_EXTENSIONS.includes(fileExtension.toLowerCase())) {
      if (this.props.onError) {
        this.props.onError('media.item-unauthorized-extension-error', { formats: AUTHORIZED_FILE_EXTENSIONS.join(', ') });
      }
      return;
    }

    files.push(selectorFiles);
    this.newFilesIds.set(selectorFiles, Math.random().toFixed(10));
  }

  private addPicture = (selectorFile: File): void => {
    const files: File[] = [];
    this.addNewFile(selectorFile, files);
    this.setState({ filesAndMedia: [...this.state.filesAndMedia.concat(files)] }, this.handleScrollPosition);
  };

  private addPictures = (selectorFiles: FileList | null): void => {
    if (null === selectorFiles || !selectorFiles.length) {
      return;
    }
    const files: File[] = [];

    if (this.props.instantUpload) {
      this.addNewFile(selectorFiles[selectorFiles.length - 1], files);
      this.setState({ filesAndMedia: files }, this.handleScrollPosition);
      return;
    }

    for (let i = 0; i < selectorFiles.length; i++) {
      this.addNewFile(selectorFiles[i], files);
      if (this.props.maxFiles && this.props.maxFiles <= this.state.filesAndMedia.length + files.length) {
        break;
      }
    }

    this.setState({ filesAndMedia: [...this.state.filesAndMedia.concat(files)] }, this.handleScrollPosition);
  };

  private handleScrollPosition(): void {
    const contentRef = this.mediaUploaderContainerRef.current;
    if (!contentRef) {
      return;
    }
    // When adding a new picture, the div will scroll to the right in order to make the 'add image button' always visible
    contentRef.scrollBy(contentRef.scrollWidth - contentRef.offsetWidth - contentRef.scrollLeft, 0);
  }

  deleteItem(item: Media | File): void {
    if (itemIsAMedia(item)) {
      this.setState({ filesAndMedia: this.state.filesAndMedia.filter(o => o !== item), deletedMedia: [...this.state.deletedMedia, item] });
    } else {
      this.setState({ filesAndMedia: this.state.filesAndMedia.filter(o => o !== item) });
      if (this.refInput.current) this.refInput.current.value = '';
      if (this.newFilesIds.has(item)) {
        this.newFilesIds.delete(item);
      }
    }
  }

  //Method for opening gallery or camera. Only for mobile devices.
  private uploadCameraPhoto = async (): Promise<void> => {
    this.updateShowActionSheet(false);
    const options = {
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Camera,
    };

    let photoFile;

    try {
      photoFile = await Camera.getPhoto(options);
    } catch (e) {
      if (e.message?.match(/user cancelled photos/i)) {
        return;
      }
      if (e.message?.match(/user denied/i)) {
        this.setState({ showCameraPermissionAlert: true });
      } else {
        if (this.props.onError) {
          this.props.onError(e.message ? e.message : this.props.t('error.error-with-camera'));
          return;
        }
        this.setState({ errorAlertMessage: e.message ? e.message : this.props.t('error.error-with-camera') });
      }
    }

    if (!photoFile) {
      return;
    }

    const fileData = await urltoFile(photoFile.dataUrl);

    this.newFilesIds.set(fileData, Math.random().toFixed(10));

    if (!fileData) {
      this.setState({ showCameraPermissionAlert: true });
      return;
    }
    const filesAndMedia = this.props.instantUpload ? [fileData] : [...this.state.filesAndMedia.concat(fileData)];
    this.setState({ filesAndMedia }, this.handleScrollPosition);
  };
}

export default withTranslation()(InputMediaUploader);
