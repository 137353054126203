import { IonButton, IonCard, IonCardContent, IonCol, IonContent, IonRow, IonText, IonActionSheet, IonAlert, IonPage, withIonLifeCycle } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import ErrorComponent from '../ErrorComponent';
import { actions, RootState, selectors } from '../store';
import { BlockedUsersCollectionName } from '../store/blockedUsers/types';
import { getUserDirectChat } from '../store/chats/actions';
import { Chat } from '../store/chats/types';
import { getHydratedFullPostCollection } from '../store/posts/selectors';
import { getUserById } from '../store/users/selectors';
import { ReviewType, User, UserFavoriteCollectionName, organizationTypePublicSector, Area, UserCollectionName } from '../store/users/types';
import { ExtendableError } from '../utils/dataAccessError';
import { EntityReference, HydratedCollection, ReferencedCollection, ReferencedCollectionResponse } from '../utils/hydra';
import { FullPost, Location } from '../store/posts/types';
import { extractId, getUserAvatar, isDataFresh, isSameHydraEntity, openUrlInNewTab } from '../utils/helpers';
import FollowButton from '../components/FollowButton';
import { Thematic } from '../store/thematics/types';
import UserPostsSlider from '../components/UserPostsSlider';
import ProfilePagePlaceholder from '../components/placeholders/ProfilePagePlaceholder';
import ReportModal from '../components/ReportModal';
import AddReviewModal from '../components/connected/AddReviewModal';
import AboutInfoComponent from '../components/user/AboutInfoComponent';
import UserSocialImpact from '../components/user/UserSocialImpact';
import UserExperience from '../components/user/UserExperience';
import UserThematics from '../components/user/UserThematics';
import UserOverview from '../components/user/UserOverview';
import { isUserBlocked } from '../store/blockedUsers/selectors';
import withRouterAndRef from '../utils/withRouterAndRef';
import CommonHeader from '../components/CommonHeader';
import CertifiedChip from '../components/chip/CertifiedChip';
import { sendProfilePageViewedLog } from '../utils/analytics/analyticsHelper';
import UserMediaSlider from '../components/UserMediaSlider';
import ImageFadeIn from '../components/ImageFadeIn';
import { createIsCurrentUserFunc, getUserArea, getUserIsAdmin, isCurrentUserFunc } from '../store/app/selectors';
import { supervisorUrl } from '../environment';
import RoutingGuard from '../components/common/RoutingGuard';
import './CurrentUserProfilePage.scss';
import './UserProfilePage.scss';

type State = {
  user?: User;
  directChatId: EntityReference;
  directChatStatusIsLoading: boolean;
  showActionSheet: boolean;
  isReportModalOpen: boolean;
  isReportLoading: boolean;
  isReviewModalOpen: boolean;
  showBlockConfirmationAlert: boolean;
  contentRendering: boolean;
  reviewType: ReviewType | undefined;
  userFetchError?: ExtendableError;
};

type StateProps = {
  user: User | undefined;
  userIsLoading: boolean;
  getPosts: (posts: ReferencedCollection | undefined) => HydratedCollection<FullPost>;
  getUserDirectChat: (userId: EntityReference) => Promise<Chat>;
  isCurrentUser: isCurrentUserFunc;
  currentUser: User | undefined;
  userThematics: (user: User) => Thematic[];
  isUserBlocked: (userId: EntityReference, collectionName: BlockedUsersCollectionName) => boolean;
  isUserBlockRequestLoading: boolean;
  activeTab: string | undefined;
  userIsAdmin: boolean;
  userArea?: Area;
  isFullyLogged?: boolean;
};

const mapStateToProps = (state: RootState, props: PageProps): StateProps => ({
  user: getUserById(state, props),
  userIsLoading: state.users.isItemLoading,
  getPosts: (posts: ReferencedCollection | undefined) => getHydratedFullPostCollection(state, posts),
  getUserDirectChat: getUserDirectChat,
  isCurrentUser: createIsCurrentUserFunc(state),
  currentUser: selectors.entities.denormalizeEntity(state.entities, extractId(state.app.currentUser, true)),
  userThematics: (user: User): Thematic[] => selectors.thematics.filterThematicsByIds(state.thematics.thematics, user.favoriteCategoryThematics || []),
  isUserBlocked: (userId: EntityReference, collectionName: BlockedUsersCollectionName) => isUserBlocked(state, userId, collectionName),
  isUserBlockRequestLoading: state.blockedUsers.itemIsLoading,
  activeTab: state.navigation.activeTab,
  userIsAdmin: getUserIsAdmin(state),
  userArea: getUserArea(state),
  isFullyLogged: state.app.isFullyLogged,
});

interface DispatchProps {
  fetchUser: (userId: EntityReference) => Promise<void>;
  createDirectChat: (userId: EntityReference) => Promise<EntityReference>;
  fetchUserPosts: (userId: EntityReference, collectionName: UserCollectionName, loadNextPage: boolean) => Promise<ReferencedCollectionResponse>;
  fetchUserChat: (chatId: EntityReference) => void;
  fetchThematics: () => Promise<void>;
  fetchUserFavorites: (userId: EntityReference, collectionName: UserFavoriteCollectionName) => Promise<void>;
  fetchPositiveUserReviewsNumber: (userId: EntityReference) => Promise<void>;
  blockUser(postId: EntityReference): Promise<void>;
  unblockUser(postId: EntityReference): Promise<void>;
}

const propsToDispatch = {
  fetchUser: actions.users.fetchUser,
  createDirectChat: actions.chats.createUserDirectChat,
  fetchUserPosts: actions.posts.fetchUserPosts,
  fetchUserChat: actions.chats.fetchUserChat,
  fetchThematics: actions.thematicsActions.fetchThematics,
  fetchUserFavorites: actions.users.fetchUserFavorites,
  fetchPositiveUserReviewsNumber: actions.users.fetchPositiveUserPostRequestReviewsNumber,
  blockUser: actions.blockedUsersActions.blockUser,
  unblockUser: actions.blockedUsersActions.unblockUser,
};

interface PageProps {
  userId: EntityReference | null;
}

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type UserProfileProps = PageProps & RouteComponentProps<Record<string, never>> & DispatchProps & StateProps & WithTranslation;

class UserProfilePage extends Component<UserProfileProps, State> {
  _isMounted = false;

  public constructor(props: UserProfileProps) {
    super(props);

    this.state = {
      directChatId: '',
      directChatStatusIsLoading: true,
      showActionSheet: false,
      isReportModalOpen: false,
      isReportLoading: false,
      isReviewModalOpen: false,
      showBlockConfirmationAlert: false,
      contentRendering: false,
      reviewType: undefined,
    };
  }

  public componentDidMount(): void {
    this._isMounted = true;
    this.refreshUserData();
    this.props.fetchThematics();
    if (this.props.user) {
      setTimeout(this.refreshUserCollections, 500); // Delay collections fetch
    }
  }

  public componentWillUnmount(): void {
    this._isMounted = false;
  }

  public componentDidUpdate(prevProps: Readonly<UserProfileProps>): void {
    if (this.props.userId !== prevProps.userId) {
      this.refreshUserData();
    }

    if (this.props.user && !isSameHydraEntity(this.props.user, prevProps.user)) {
      this.refreshUserCollections();
    }
  }

  // TODO Use reselect for all injected props & use PureComponent or shouldComponentUpdate

  public ionViewDidEnter(): void {
    setTimeout(() => this.setState({ contentRendering: true }));
  }

  public ionViewWillLeave(): void {
    if (this._isMounted) {
      this.setState({ contentRendering: false });
    }
  }

  public render(): ReactNode {
    const { showActionSheet, isReportLoading, showBlockConfirmationAlert, contentRendering } = this.state;
    const { userId, currentUser, user, t, userIsLoading, isCurrentUser, activeTab, userArea, isFullyLogged } = this.props;
    const currentLocation = userArea?.location as Location;

    if (!userId) {
      return <Redirect to="/" />;
    }

    if (!user && this.state.userFetchError !== undefined) {
      return <ErrorComponent error={this.state.userFetchError} doRefresh={() => this.refreshUserData()} />;
    }

    if (!user || userIsLoading || !contentRendering) {
      return (
        <IonPage data-cy="user-profile-page">
          <ProfilePagePlaceholder isCurrentUser={false} />
        </IonPage>
      );
    }

    const isUserBlocked = this.props.isUserBlocked(userId, 'currentUserBlockedCollection');
    const isCurrentUserBlocked = this.props.isUserBlocked(userId, 'currentUserIsBlockedCollection');
    const isUserDisabled = user.disabled;

    const userActionSheetButtons = [
      {
        text: this.getBlockUserActionSheetButtonText(isUserBlocked),
        role: 'destructive',
        handler: () => this.setState({ showBlockConfirmationAlert: true }),
      },
      {
        text: t('review.write-personal'),
        handler: () => this.setState({ isReviewModalOpen: true }),
      },
      {
        text: isReportLoading ? t('common.loading') : t('user.report'),
        role: 'destructive',
        handler: () => this.setState({ isReportModalOpen: true }),
      },
      {
        text: t('common.cancel'),
        role: 'cancel',
      },
    ];

    if (this.props.userIsAdmin) {
      userActionSheetButtons.push({
        text: this.props.t('admin.see-in-supervisor'),
        role: 'destructive',
        handler: () => openUrlInNewTab(`${supervisorUrl}/#/users/${encodeURIComponent(user['@id'])}/show`),
      });
    }

    const userThematics = this.props.userThematics(user);
    return (
      <IonPage data-cy="user-profile-page">
        {!isCurrentUser(userId) ? (
          <CommonHeader addIonHeader={true} openMenu={this.toggleActionSheet} menuIcon="/assets/navigation/more.svg" menuDataCy="user-profile-options" />
        ) : (
          <CommonHeader addIonHeader={true} menuDataCy="user-profile-options" />
        )}

        <IonContent className="user-profile-page">
          <IonCard className="user-profile-image profile-image">
            <IonCardContent className={user?.images?.length === 0 ? 'd-flex' : ''}>
              {!isCurrentUserBlocked && !isUserBlocked && !isUserDisabled ? (
                <UserMediaSlider isCurrentUser={false} user={user} activeTab={activeTab} />
              ) : (
                <>
                  <ImageFadeIn src={getUserAvatar(user)} alt="avatar" data-cy="user-avatar" />
                  <div className="profile-image-gradient" data-cy="profile-picture" />
                </>
              )}
              {!isUserDisabled && (
                <div className="user-profile-image-description">
                  <div className="profile-image-title">
                    <IonText color="light">{user.name}</IonText>
                  </div>

                  <div className="follow-button-position">
                    {user?.managedData?.certified && <CertifiedChip />}
                    {!isCurrentUser(userId) && !isCurrentUserBlocked && !isUserBlocked && currentUser && (
                      <FollowButton currentUser={currentUser} userId={extractId(user)} color="primary" unfollowColor="light" refreshUserFavorites />
                    )}
                  </div>
                </div>
              )}
            </IonCardContent>
          </IonCard>

          {isUserBlocked && !isUserDisabled && (
            <IonRow responsive-sm className="common-margin">
              <IonCol>
                <IonButton className="main-button" color="danger" fill="outline" onClick={() => this.setState({ showBlockConfirmationAlert: true })}>
                  <Trans i18nKey={'user.you-have-blocked-this-profile'} />
                </IonButton>
              </IonCol>
            </IonRow>
          )}

          {isUserDisabled && (
            <IonRow responsive-sm className="common-margin" data-cy="button-account-disabled">
              <IonCol>
                <IonButton className="main-button" color="danger" fill="outline">
                  <Trans i18nKey={'user.account-disabled'} />
                </IonButton>
              </IonCol>
            </IonRow>
          )}

          {isCurrentUserBlocked && !isUserDisabled && (
            <IonRow responsive-sm className="common-margin">
              <IonCol>
                <IonButton className="main-button" color="medium" fill="outline">
                  <Trans i18nKey={'user.user-not-found'} />
                </IonButton>
              </IonCol>
            </IonRow>
          )}

          {!isCurrentUserBlocked && !isUserBlocked && !isUserDisabled && (
            <>
              <IonRow responsive-sm className="common-margin">
                <IonCol>{this.getDirectChatButton(user)}</IonCol>
              </IonRow>

              {user?.managedData?.type !== organizationTypePublicSector && (
                <UserSocialImpact isCurrentUser={isCurrentUser(user)} completedPostRequestsAsHelper={user.status?.completedPostRequestsAsHelper} user={user} />
              )}

              <UserExperience user={user} />

              {!!userThematics?.length && <UserThematics thematics={userThematics} title={t('user.interests')} />}

              <UserOverview user={user} />

              {user.aboutMe && <AboutInfoComponent title="aboutMe" description={user.aboutMe} />}

              {user.aboutMeOnIndigo && <AboutInfoComponent title="aboutMeOnIndigo" description={user.aboutMeOnIndigo} />}

              {!!user.collections?.offer?.totalItems && (
                <UserPostsSlider
                  postsCollection={this.props.getPosts(user.collections?.offer)}
                  title={t('user.offers', { username: user.name })}
                  currentLocation={currentLocation}
                  isCurrentUser={isCurrentUser}
                  userId={userId}
                  collectionName={'offer'}
                />
              )}

              {!!user.collections?.need?.totalItems && (
                <>
                  <div className="help-user">
                    <IonText color="dark">{t('post.help-user', { username: user.name })}</IonText>
                  </div>
                  <UserPostsSlider
                    postsCollection={this.props.getPosts(user.collections?.need)}
                    title={t('user.needs', { username: user.name })}
                    currentLocation={currentLocation}
                    isCurrentUser={isCurrentUser}
                    userId={userId}
                    collectionName={'need'}
                  />
                </>
              )}
            </>
          )}
        </IonContent>

        {!isUserDisabled && (
          <>
            <IonActionSheet data-cy="user-option-action-sheet" isOpen={showActionSheet} onDidDismiss={this.toggleActionSheet} buttons={userActionSheetButtons} />
            <IonAlert
              cssClass="confirmation-alert"
              isOpen={showBlockConfirmationAlert}
              onDidDismiss={() => this.setState({ showBlockConfirmationAlert: false })}
              message={isUserBlocked ? t('user.unblock-description', { username: user.name }) : t('user.block-description', { username: user.name })}
              header={isUserBlocked ? t('user.unblock-title', { username: user.name }) : t('user.block-title', { username: user.name })}
              buttons={[
                {
                  text: t('common.cancel'),
                  role: 'cancel',
                  cssClass: 'secondary',
                },
                {
                  text: isUserBlocked ? t('user.unblock') : t('user.block'),
                  handler: () => this.toggleBlockUser(isUserBlocked, userId),
                },
              ]}
            />
          </>
        )}

        {isFullyLogged && !isUserDisabled && (
          <AddReviewModal
            isOpen={this.state.isReviewModalOpen}
            reviewType={this.state.reviewType}
            reviewedUser={user}
            closeModal={() => this.setState({ isReviewModalOpen: false, reviewType: undefined })}
            setReviewType={(reviewType: ReviewType) => this.setState({ reviewType })}
            userIsRequester={false}
          />
        )}
        {isFullyLogged && !isUserDisabled && (
          <ReportModal closeModal={this.closeModal} isOpen={this.state.isReportModalOpen} userId={extractId(user)} setReportLoadingState={isReportLoading => this.setState({ isReportLoading })} />
        )}
      </IonPage>
    );
  }

  private getBlockUserActionSheetButtonText = (isUserBlocked: boolean): string => {
    const { isUserBlockRequestLoading, t } = this.props;

    if (isUserBlockRequestLoading) return t('common.loading');
    if (isUserBlocked) return t('user.unblock');
    return t('user.block');
  };

  private toggleBlockUser = (isUserBlocked: boolean, userId: EntityReference): void => {
    if (isUserBlocked) {
      this.props.unblockUser(userId);
      return;
    }

    this.props.blockUser(userId);
  };

  private closeModal = (): void => {
    this.setState({ isReportModalOpen: false });
  };

  private toggleActionSheet = (): void => {
    this.setState({ showActionSheet: !this.state.showActionSheet });
  };

  private getDirectChatButton = (user: User): ReactNode => {
    if (this.state.directChatStatusIsLoading || this.props.isCurrentUser(user)) {
      return <></>;
    }

    if (this.state.directChatId) {
      return (
        <RoutingGuard>
          <IonButton routerLink={this.state.directChatId} className="main-button" color="primary">
            <Trans i18nKey={'chat.go-to-chat'} />
          </IonButton>
        </RoutingGuard>
      );
    }

    return (
      <RoutingGuard>
        <IonButton onClick={this.createDirectChat} className="main-button" color="primary" data-cy="create-direct-chat-button">
          <Trans i18nKey={'chat.create-chat'} />
        </IonButton>
      </RoutingGuard>
    );
  };

  private createDirectChat = async (): Promise<void> => {
    if (!this.props.userId) {
      return;
    }

    try {
      const chatId: string = await this.props.createDirectChat(this.props.userId);
      this.setState({ directChatId: chatId });
      await this.props.fetchUserChat(chatId); // Fetch userChat here to avoid convulsion issue on chat page on IOS
      this.props.history.push(chatId); // TODO Remove history
    } catch (e) {
      // Error when creating the chat
    }
  };

  private refreshUserData(): void {
    if (!this.props.userId) {
      return;
    }

    // Refresh or load the user from the API
    this.props
      .fetchUser(this.props.userId)
      .then(() => {
        this.setState({ userFetchError: undefined });
      })
      .catch((e: ExtendableError) => {
        this.setState({ userFetchError: e });
      });

    this.props.fetchUserFavorites(this.props.userId, 'favoritedByUsers').catch(() => {
      // Do nothing
    });

    if (this.props.isFullyLogged) {
      this.props.fetchPositiveUserReviewsNumber(this.props.userId);
    }

    this.loadUserDirectChat();
    sendProfilePageViewedLog();
  }

  private refreshUserCollections = (): void => {
    if (!this._isMounted) {
      return;
    }

    const userId = extractId(this.props.user);
    if (!userId) {
      return;
    }

    if (!isDataFresh(this.props.user?.collections?.offer)) {
      this.props.fetchUserPosts(userId, 'offer', false);
    }

    if (!isDataFresh(this.props.user?.collections?.need)) {
      this.props.fetchUserPosts(userId, 'need', false);
    }
  };

  private loadUserDirectChat(): void {
    const { userId } = this.props;
    if (!userId || this.props.isCurrentUser(userId)) {
      return;
    }

    this.setState({ directChatStatusIsLoading: true });
    // TODO Use the store for direct chat data, and remove this useless state
    this.props
      .getUserDirectChat(userId)
      .then(data => this.setState({ directChatId: extractId(data), directChatStatusIsLoading: false }))
      .catch(() => this.setState({ directChatId: '', directChatStatusIsLoading: false }));
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withIonLifeCycle(withRouterAndRef(UserProfilePage))));
