import { IonBadge, IonItem, IonLabel, IonList } from '@ionic/react';
import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PostRequest } from '../store/postRequests/types';
import { extractId } from '../utils/helpers';
import { HydratedCollection } from '../utils/hydra';

function getStateColor(state: 'accepted' | 'completed' | 'waiting' | 'canceled' | 'rejected'): string {
  if (state === 'accepted' || state === 'completed') {
    return 'success';
  }
  if (state === 'waiting') {
    return 'warning';
  }
  return 'danger';
}

interface ItemProps {
  postRequest: PostRequest;
}

export const ItemWithPostInfos: React.FunctionComponent<ItemProps> = ({ postRequest }: ItemProps) => (
  <>
    <IonLabel>
      <Link to={extractId(postRequest.post)}>{postRequest.post.title}</Link>
    </IonLabel>
    <IonLabel>
      <Link to={extractId(postRequest.post.createdBy)}>{postRequest.post.createdBy.name}</Link>
    </IonLabel>
  </>
);

export const ItemWithUserInfos: React.FunctionComponent<ItemProps> = ({ postRequest }: ItemProps) => (
  <IonLabel>
    <Link to={extractId(postRequest.user)}>{postRequest.user.name}</Link>
  </IonLabel>
);

export interface PostRequestListProps {
  postRequests: HydratedCollection<PostRequest>;
  ItemComponent: typeof ItemWithPostInfos | typeof ItemWithUserInfos;
}

const PostRequestsList: React.FunctionComponent<PostRequestListProps> = ({ postRequests, ItemComponent }: PostRequestListProps) => {
  if (postRequests.totalItems === 0) {
    return (
      <IonItem>
        <IonLabel>
          <Trans i18nKey={postRequests.isLoading ? 'common.loading' : 'post-request.no-post-requests-found'} />
        </IonLabel>
      </IonItem>
    );
  }

  return (
    <IonList className="post-requests-list">
      {postRequests.items.map((postRequest: PostRequest) => {
        return (
          <IonItem key={extractId(postRequest)}>
            <IonBadge color={getStateColor(postRequest.state)}>
              <Trans i18nKey={'post-request.state.' + postRequest.state} />
            </IonBadge>
            <IonLabel>{postRequest.createdAt}</IonLabel>
            <ItemComponent postRequest={postRequest} />
          </IonItem>
        );
      })}
    </IonList>
  );
};

export default PostRequestsList;
