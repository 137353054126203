import i18next from 'i18next';

export const appLanguages = ['en', 'el', 'fi', 'fr', 'it', 'pl', 'pt', 'ar', 'ur', 'fa'];
export const ltrLanguages = ['ar', 'ur', 'fa'];
const arabicNumbers = ['۰', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
const farsiNumbers = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'];

export interface Translatable {
  translations: { [key: string]: string };
}

export function getAppLanguage(): string {
  return i18next.language.substring(0, 2);
}

export function getAppLanguages(): readonly string[] {
  return i18next.languages;
}

export function translateDigits(number: number): string | number {
  switch (getAppLanguage()) {
    case 'ar' || 'ur':
      return String(number)
        .split('')
        .map(char => arabicNumbers[Number(char)])
        .join('');
    case 'fa':
      return String(number)
        .split('')
        .map(char => farsiNumbers[Number(char)])
        .join('');
    default:
      return number;
  }
}

export function languageIsLtr(langage: string): boolean {
  return ltrLanguages.includes(langage);
}

export const getItemTranslation = (item: Translatable, defaultTranslation: string | undefined = undefined): string => {
  if (Object.prototype.hasOwnProperty.call(item?.translations || {}, getAppLanguage())) {
    return item.translations[getAppLanguage()];
  }

  if (Object.prototype.hasOwnProperty.call(item?.translations || {}, 'en')) {
    return item.translations['en'];
  }

  return defaultTranslation || '-';
};
