import i18next from 'i18next';
import React, { useEffect } from 'react';
import { IonContent, IonModal, IonButton, IonHeader, IonToolbar, IonButtons, IonIcon } from '@ionic/react';
import { Media } from '../store/app/types';
import { getItemSrc } from '../utils/helpers';
import { setStatusBarStyle } from '../utils/statusBarHelper';
import { Capacitor } from '@capacitor/core';
import { isMobileNavigator } from '../environment';
import ImageFadeIn from './ImageFadeIn';
import { Navigation, Pagination, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import './GalleryModal.scss';

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  moreButtonAction?: () => void;
  updateSlideIndex?: (currentMediaIndex: number) => void;
  images?: Media[];
  initialSlide: number;
}

const GalleryModal: React.FunctionComponent<ModalProps> = (props: ModalProps) => {
  const { initialSlide, images, isOpen, updateSlideIndex } = props;

  // We need to update the status bar style for Ios devices
  const closeGalleryModal = (): void => {
    setStatusBarStyle(true);
    props.closeModal();
  };

  useEffect(() => {
    setStatusBarStyle(false);
  }, []);

  const showNavigationButtons = Capacitor.getPlatform() === 'web' && !isMobileNavigator;
  return (
    <IonModal isOpen={isOpen} className="modal-gallery safe-area-ios" onDidDismiss={props.closeModal} data-cy="modal-gallery">
      <IonHeader>
        <IonToolbar className="modal-gallery-toolbar">
          <IonButtons slot="start">
            <IonButton onClick={closeGalleryModal} data-cy="close-button">
              <IonIcon className="close-icon" slot="icon-only" src="/assets/navigation/close-white.svg" color="light" />
            </IonButton>
          </IonButtons>
          {props.moreButtonAction !== undefined && (
            <IonButtons slot="end">
              <IonButton onClick={props.moreButtonAction} data-cy="more-button">
                <IonIcon slot="icon-only" src="/assets/navigation/more-white.svg" color="light" />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent className="modal-gallery-content">
        {images && images.length > 0 && (
          <Swiper
            modules={[Navigation, Pagination, Keyboard]}
            autoHeight={false}
            keyboard={true}
            pagination={false}
            navigation={showNavigationButtons}
            initialSlide={initialSlide}
            cssMode={false}
            observer={true}
            slidesPerView={1}
            speed={400}
            grabCursor={true}
            zoom={true}
            key={images.map((item: Media | File, key: number) => key).join('_')}
            className="full-height"
            onSlideChange={e => {
              updateSlideIndex ? updateSlideIndex(e.activeIndex) : '';
            }}
          >
            {images.map((image: Media | File, key: number) => (
              <SwiperSlide key={key} data-cy="images-slide">
                <ImageFadeIn useNativeImg thumbnail src={getItemSrc(image)} className="modal-gallery-image swiper-zoom-container" alt={i18next.t('user.user-picture')} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </IonContent>
    </IonModal>
  );
};

export default GalleryModal;
