import React, { Component, ReactNode } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { actions, RootState } from '../store';
import DynamicForm, { CheckboxFieldComponent, FormFields, FormValues, TextareaFieldComponent } from '../components/DynamicForm';
import * as Yup from 'yup';
import i18next from 'i18next';
import InputMediaUploader from '../components/inputs/InputMediaUploader';
import { getAddPicturePlaceholder, getPicturePlaceholder } from '../components/PostForm';
import { IonContent, IonPage, IonText } from '@ionic/react';
import { getMediaSrc } from '../utils/helpers';
import { GlobalNotificationData } from '../store/notifications/types';
import { defaultState } from '../store/notifications/reducer';
import SelectField from '../components/inputs/SelectField';
import { localesLabels } from '../i18n';
import { getUserIsGlobalNotifier } from '../store/app/selectors';
import CommonHeader from '../components/CommonHeader';
import BackButton from '../components/BackButton';
import '../components/PostForm.scss';
import './CreateGlobalNotificationPage.scss';

interface DispatchProps {
  setGlobalNotification(notification: GlobalNotificationData): void;
}

const propsToDispatch = {
  setGlobalNotification: actions.notifications.setGlobalNotificationAction,
};

interface StateProps {
  userCanCreateGlobalNotification: boolean;
  globalNotification: GlobalNotificationData;
}

const mapStateToProps = (state: RootState): StateProps => ({
  userCanCreateGlobalNotification: getUserIsGlobalNotifier(state),
  globalNotification: state.notifications.globalNotification,
});

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type CreateGlobalNotificationProps = RouteComponentProps<Record<string, never>> & DispatchProps & WithTranslation & StateProps;

class CreateGlobalNotificationPage extends Component<CreateGlobalNotificationProps> {
  static constraints = {
    textMinLength: 2,
  };

  allFields: FormFields = [
    {
      label: 'notification.add-a-photo',
      name: 'image',
      formComponent: InputMediaUploader,
      options: { maxFiles: 1, multiple: false, required: false, isMediaUploaderField: true, picturePlaceholder: getPicturePlaceholder, addPicturePlaceholder: getAddPicturePlaceholder },
      itemLines: 'none',
    },
    {
      label: 'notification.text',
      name: 'message',
      formComponent: TextareaFieldComponent,
      options: { autocapitalize: 'off', required: true, clearOnEdit: false, clearInput: true, autoGrow: true },
    },
    {
      label: 'notification.send-by-push',
      labelOptions: { position: undefined },
      name: 'sendByPush',
      formComponent: CheckboxFieldComponent,
      options: { required: false, clearOnEdit: false, clearInput: true, slot: 'start' },
      itemLines: 'none',
    },
    {
      label: 'notification.send-by-email',
      labelOptions: { position: undefined },
      name: 'sendByMail',
      formComponent: CheckboxFieldComponent,
      options: { required: false, clearOnEdit: false, clearInput: true, slot: 'start' },
      itemLines: 'none',
    },
    {
      label: 'notification.choose-lang',
      name: 'targetedLocales',
      formComponent: SelectField,
      withoutIonItem: true,
      options: { required: true, selectOptions: localesLabels, multiple: true },
    },
  ];

  validationSchema = Yup.object().shape({
    image: Yup.mixed().notRequired(),
    targetedLocales: Yup.array().of(Yup.string()).min(1, i18next.t('notification.choose-lang-required')).required(),
    message: Yup.string()
      .min(CreateGlobalNotificationPage.constraints.textMinLength, i18next.t('post.error-min', { min: CreateGlobalNotificationPage.constraints.textMinLength }))
      .required(),
  });

  public render(): ReactNode {
    const { userCanCreateGlobalNotification, globalNotification, t } = this.props;

    if (!userCanCreateGlobalNotification) {
      return <Redirect to="/" />;
    }

    return (
      <IonPage data-cy="create-notification-page">
        <CommonHeader
          addIonHeader={true}
          backButton={<BackButton />}
          onBackButtonClick={this.clearGlobalNotification}
          stopPropagation
          title={<Trans i18nKey="notification.notification-for-all-users" />}
        />
        <IonContent className="post-form create-notification-page">
          <IonText className="alert-info">
            <Trans i18nKey="notification.notification-for-all-users-helper" />
            <br />
            <br />
            <a className="markdown-help" href="https://github.com/adam-p/markdown-here/wiki/Markdown-Here-Cheatsheet" target="_blank" rel="noopener noreferrer">
              {i18next.t('notification.markdown-help')}
            </a>
          </IonText>
          <DynamicForm
            onSubmitForm={this.submitForm}
            formFields={this.allFields}
            validationSchema={this.validationSchema}
            initialValues={globalNotification}
            submitButtonText={t('notification.preview')}
          />
        </IonContent>
      </IonPage>
    );
  }

  private clearGlobalNotification = (): void => {
    this.props.setGlobalNotification(defaultState.globalNotification);
  };

  private submitForm = async (values: Readonly<FormValues>): Promise<void> => {
    const notification = { ...this.props.globalNotification, ...values, imageUrl: values.image ? getMediaSrc(values.image) : '' };

    this.props.setGlobalNotification(notification as GlobalNotificationData);
    this.props.history.push('/notifications/global/preview');
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(CreateGlobalNotificationPage)));
