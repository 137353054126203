import { IonContent, IonText, IonButton } from '@ionic/react';
import { FormikProps } from 'formik';
import React, { Component, ReactNode } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { actions, RootState } from '../../store';
import { CurrentUser } from '../../store/users/types';
import CommonHeader from '../CommonHeader';
import { ModalItemProps } from './ProfileMenuModal';
import * as Yup from 'yup';
import DynamicForm, { FormFields, FormValues, InputFieldComponent, InputWithValidationFieldComponent, TextareaFieldComponent } from '../DynamicForm';
import './HelpMenuItem.scss';

interface StateProps {
  currentUser: CurrentUser;
}

const mapStateToProps = (state: RootState): StateProps => ({
  currentUser: state.app.currentUser,
});

interface DispatchProps {
  helpRequestAction(email: string, subject: string, message: string): void;
}

const propsToDispatch = {
  helpRequestAction: actions.app.helpRequestAction,
};

type Props = StateProps & DispatchProps & ModalItemProps & WithTranslation & RouteComponentProps<Record<string, never>>;

interface HelpMenuItemState {
  email?: string;
  subject?: string;
  message?: string;
  errorMessage?: string;
}

const HelpFormVariables = { minTextLength: 2, maxSubjectLength: 140, maxMessageLength: 4000 };

interface HelpFormValues extends FormValues {
  email?: string;
  subject?: string;
  message?: string;
}

const submitButton: React.FunctionComponent<FormikProps<FormValues>> = ({ isValid, isSubmitting, isValidating }: FormikProps<FormValues>) => (
  <div className="bottom-section">
    <IonButton className="full-width-button" type="submit" shape="round" expand="block" size="large" disabled={!isValid || isValidating || isSubmitting}>
      <Trans i18nKey="common.send" />
    </IonButton>
  </div>
);

class HelpMenuItem extends Component<Props, HelpMenuItemState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      errorMessage: undefined,
    };
  }

  fields: FormFields = [
    {
      label: 'help.email',
      name: 'email',
      formComponent: InputWithValidationFieldComponent,
      options: { autocapitalize: 'off', type: 'email', required: true, clearOnEdit: false, clearInput: false },
    },
    {
      label: 'help.subject',
      name: 'subject',
      formComponent: InputFieldComponent,
      options: { autocapitalize: 'on', type: 'text', required: true, clearOnEdit: false, clearInput: false, minlength: 2, maxlength: 140 },
    },
    {
      label: 'help.message',
      name: 'message',
      formComponent: TextareaFieldComponent,
      options: {
        autocapitalize: 'on',
        type: 'text',
        required: true,
        clearOnEdit: false,
        clearInput: false,
        rows: 4,
        minlength: 2,
        maxlength: 6000,
      },
      bottomExample: this.props.t('help.message-placeholder'),
    },
  ];

  initialFormValues: HelpFormValues = {
    email: this.props.currentUser.email,
    subject: undefined,
    message: undefined,
  };

  validationSchema = Yup.object({
    email: Yup.string().email(this.props.t('help.error-email')).nullable().required(this.props.t('help.error-email-required')),
    subject: Yup.string().min(HelpFormVariables.minTextLength, this.props.t('help.error-subject-required')),
    message: Yup.string().min(HelpFormVariables.minTextLength, this.props.t('help.error-message-required')),
  });

  public render(): ReactNode {
    return (
      <>
        <CommonHeader title={<Trans i18nKey="profile-menu.help" />} />

        <IonContent>
          <div className="help-menu-item">
            <IonText className="help-description">
              <Trans i18nKey="help.help-description" />
            </IonText>
            <DynamicForm
              onSubmitForm={this.submitForm}
              formFields={this.fields}
              validationSchema={this.validationSchema}
              initialValues={this.initialFormValues}
              dynamicFormSubmitButton={submitButton}
            />
          </div>
        </IonContent>
      </>
    );
  }

  private submitForm = async (values: Readonly<HelpFormValues>): Promise<void> => {
    if (!values.email || !values.message || !values.subject) {
      return;
    }
    try {
      await this.props.helpRequestAction(values.email, values.subject, values.message);
      this.props.history.push('/me');
    } catch (error) {
      // Error while sending help request
    }
  };
}

export default connect(mapStateToProps, propsToDispatch)(withTranslation()(withRouter(HelpMenuItem)));
