import React, { CSSProperties, PureComponent, ReactNode } from 'react';
import { IonImg } from '@ionic/react';
import { webpSupport } from '../utils/helpers';
import { isTest } from '../environment';
import AsyncImg from './AsyncImg';
import { InViewContext } from './IonColInView';

import './ImageFadeIn.scss';

const imageLoadedStyle = {
  opacity: '1',
};

interface State {
  loaded: boolean;
  useThumbnail: boolean;
}

interface Props {
  src: string | Promise<string>;
  opacityTransition?: number; // seconds
  thumbnail?: boolean; // Displays the thumbnail if exists
  useNativeImg?: boolean;
  // Default props
  alt?: string;
  style?: CSSProperties;
  className?: string;
  'data-cy'?: string;
}

class ImageFadeIn extends PureComponent<Props, State> {
  private _isMounted = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      loaded: false,
      useThumbnail: props.thumbnail || false,
    };
  }

  onImageLoad = (): void => {
    if (this._isMounted) {
      this.setState({ loaded: true });
    }
  };

  onImageError = (): void => {
    if (this.state.useThumbnail) {
      // The thumbnail does not exist
      this.setState({ useThumbnail: false });
    }
  };

  componentDidMount(): void {
    this._isMounted = true;
  }

  componentWillUnmount(): void {
    this._isMounted = false;
  }

  getSrc(): string | Promise<string> {
    const src = this.props.src;

    if (typeof src !== 'string') {
      return src;
    }

    // As ion-img does not handle srcset, we must detect the webp support by ourself.
    if (!this.state.useThumbnail || isTest || src.indexOf('.svg') > 0 || !webpSupport) {
      return src;
    }

    return src.replace('assets.indigo.world/', 'assets.indigo.world/thumb/') + '.webp';
  }

  render(): ReactNode {
    const { style, alt, className } = this.props;
    const opacityTransition = this.props.opacityTransition ?? 0.2;
    const src = this.getSrc();
    const isIntoInViewObserver = this.context;

    const imageStyle: CSSProperties = {
      opacity: '0',
      transition: opacityTransition > 0 ? `opacity ${opacityTransition}s ease 0s` : undefined,
    };

    const imageProps = {
      alt,
      src: src as never,
      style: this.state.loaded ? { ...style, ...imageStyle, ...imageLoadedStyle } : imageStyle,
      'data-cy': this.props['data-cy'],
    };

    if (isIntoInViewObserver || (this.props.useNativeImg && typeof src === 'string')) {
      // We don't want to use IonImg if we are already into an item using `useInView`
      return (
        <div className={(className ?? '') + ' native-ion-img'}>
          <img decoding="async" {...imageProps} onError={this.onImageError} onLoad={this.onImageLoad} />
        </div>
      );
    }

    const Component = typeof src === 'string' ? IonImg : AsyncImg;
    return <Component {...imageProps} className={className} useNativeImg={this.props.useNativeImg} onIonError={this.onImageError} onIonImgDidLoad={this.onImageLoad} />;
  }
}

ImageFadeIn.contextType = InViewContext;

export default ImageFadeIn;
