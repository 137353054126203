import { IonContent, IonButton, IonIcon, IonItem, IonText, IonActionSheet, IonImg, IonSpinner, IonPage, withIonLifeCycle, IonInput, IonRouterLink, IonAvatar, IonFooter } from '@ionic/react';
import { send } from 'ionicons/icons';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import RoutingGuard from '../components/common/RoutingGuard';
import { PostAnalyzerData, PostEmissionData } from '../components/PostAnalyzerData';
import ShareButtons from '../components/ShareButtons';
import { isMobileNavigator, supervisorUrl } from '../environment';
import ErrorComponent from '../ErrorComponent';
import { addParametersToUrl } from '../utils/dataAccess';
import { ExtendableError } from '../utils/dataAccessError';
import { browserShare, deviceCanShare, getSharePostUrl } from '../utils/shareHelper';
import { POSTS_TAB } from '../utils/TabNames';
import { actions, RootState } from '../store';
import { Post, FullPost, PartialPost, editableStates, publicStates } from '../store/posts/types';
import { Media } from '../store/app/types';
import { Area, CurrentUser, UserCollectionName } from '../store/users/types';
import { EntityReference, HydratedCollection, ReferencedCollectionResponse } from '../utils/hydra';
import { bindActionCreators, Dispatch } from 'redux';
import { cancelableStates, PostRequest } from '../store/postRequests/types';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { User } from '../store/users/types';
import {
  extractId,
  isSameHydraEntity,
  formatDateFromNow,
  isDataFresh,
  getUserAvatar,
  onKeyboardEnterExecuteFunction,
  formattedDistanceBetweenCoords,
  getBoroughIfExist,
  titleTagForPostDate,
  getPostTitle,
  openUrlInNewTab,
  formatDateFromNowWithLessThanOneHour,
  postDeadlineReached,
  getDaysBeforeDeletePost,
} from '../utils/helpers';
import { getCenter, getStaticGoogleMapImage } from '../utils/geolocationHelpers';
import { getPostByPostId, getUserHydratedOfferAndNeedCollections, isFullPost, UserOfferAndNeedHydratedCollections } from '../store/posts/selectors';
import { RouteComponentProps } from 'react-router';
import { Capacitor } from '@capacitor/core';
import FollowButton from '../components/FollowButton';
import UserPostsSlider from '../components/UserPostsSlider';
import UserItem from '../components/UserItem';
import { getChipComponent } from '../components/PostCard';
import DeletePostModal from '../components/DeletePostModal';
import PostPagePlaceholder, { PostTitlePlaceholder, PostUserPlaceholder, PostsUserListPlaceholder, PostHelperPlaceholder } from '../components/placeholders/PostPagePlaceholder';
import ReportModal from '../components/ReportModal';
import { locationIsAPostPage } from '../utils/windowLocationHelper';
import withRouterAndRef from '../utils/withRouterAndRef';
import CommonHeader from '../components/CommonHeader';
import i18next from 'i18next';
import { getHydratedChatMessages } from '../store/chats/selectors';
import { Message } from '../store/chats/types';
import { getUser } from '../store/users/selectors';
import { sendPostPageViewLog } from '../utils/analytics/analyticsHelper';
import ImageFadeIn from '../components/ImageFadeIn';
import GalleryModal from '../components/GalleryModal';
import { createIsCurrentUserFunc, getUserArea, getUserIsAdmin, getUserIsAnalyzer, getUserIsModerator, isCurrentUserFunc } from '../store/app/selectors';
import PostStateChip from '../components/chip/PostStateChip';
import PostCreationSuccessModal from '../components/PostCreationSuccessModal';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import PersonalizedMessageModal from '../components/PersonalizedMessageModal';
import ChatRedirectionButton from '../components/connected/ChatRedirectionButton';
import { Category } from '../store/categories/types';
import { getItemTranslation } from '../utils/translation';
import { translateSize, translateUniverse } from '../utils/sizesHelper';
import { getCategoryItemsType } from '../utils/categoriesHelpers';
import { getCategories } from '../store/categories/selectors';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import '../components/placeholders/PostPagePlaceholder.scss';
import './PostPage.scss';

interface State {
  displayAnalyzeData: boolean;
  isActionSheetActive: boolean;
  showDeleteModal: boolean;
  showPersonalizedMessageModal: boolean;
  showReportModal: boolean;
  isReportLoading: boolean;
  newMessage: string;
  isInputFocused: boolean;
  goToPostRequestChatPage: boolean;
  isImagesModalOpen: boolean;
  slideModalIndex: number;
  messageIsBeingSent: boolean;
  postFetchError?: ExtendableError;
}

interface StateProps {
  post: Post | undefined;
  getPublicMessages: (chatId: EntityReference | null) => HydratedCollection<Message>;
  getUserById(id: EntityReference): User | undefined;
  getUserPostsCollection: (userId: EntityReference | undefined) => UserOfferAndNeedHydratedCollections;
  isCurrentUser: isCurrentUserFunc;
  isCurrentUserPostRequestLoading: boolean;
  currentUser: CurrentUser;
  activeTab: string | undefined;
  userCanModeratePost: boolean;
  userIsAdmin: boolean;
  userIsAnalyzer: boolean;
  userArea?: Area;
  lastCreatedPostId: EntityReference | null;
  categories: Category[];
}

const mapStateToProps = (state: RootState, props: PostPageProps): StateProps => ({
  post: getPostByPostId(state, props),
  getPublicMessages: (chatId: EntityReference | null) => getHydratedChatMessages(state, { chatId }),
  getUserById: (id: EntityReference) => getUser(state, id),
  getUserPostsCollection: (userId: EntityReference | undefined) => getUserHydratedOfferAndNeedCollections(state, userId),
  isCurrentUser: createIsCurrentUserFunc(state),
  isCurrentUserPostRequestLoading: state.postRequests.itemIsLoading,
  currentUser: state.app.currentUser,
  activeTab: state.navigation.activeTab,
  userCanModeratePost: getUserIsModerator(state),
  userIsAdmin: getUserIsAdmin(state),
  userIsAnalyzer: getUserIsAnalyzer(state),
  userArea: getUserArea(state),
  lastCreatedPostId: state.app.lastCreatedPostId,
  categories: getCategories(state),
});

interface DispatchProps {
  createPostRequest: (post: Post) => Promise<PostRequest>;
  cancelPostRequest: (postRequest: PostRequest) => Promise<void>;
  fetchPost: (post: EntityReference) => Promise<void>;
  fetchPostFromPostsStore: (post: EntityReference) => Promise<void>;
  fetchUserOpenedPostRequest: (post: Post) => Promise<void>;
  fetchUserPosts: (userId: EntityReference, collectionName: UserCollectionName, loadNextPage: boolean) => Promise<ReferencedCollectionResponse>;
  fetchChatMessages: (chatId: EntityReference, page: string) => void;
  updatePostState: (post: PartialPost | FullPost | undefined, transition: string, reason: EntityReference | undefined | null) => Promise<void>;
  sendChatMessage: (chatId: EntityReference, message: string) => Promise<EntityReference>;
  setToastMessage: (message: string) => void;
  refreshPostUpdatedAt(postId: string): Promise<EntityReference>;
  setAddPostModalIsOpenAction(addPostModalIsOpen: boolean): void;
  setLastCreatedPostIdAction(lastCreatedPostId: EntityReference | null): void;
}

const propsToDispatch = {
  createPostRequest: actions.postRequests.createPostRequest,
  cancelPostRequest: actions.postRequests.cancelPostRequest,
  fetchPost: actions.posts.fetchPost,
  fetchPostFromPostsStore: actions.posts.fetchPostFromPostsStore,
  fetchUserPosts: actions.posts.fetchUserPosts,
  fetchUserOpenedPostRequest: actions.postRequests.fetchUserOpenedPostRequest,
  fetchChatMessages: actions.chats.fetchChatMessages,
  updatePostState: actions.posts.updatePostState,
  sendChatMessage: actions.chats.sendChatMessage,
  setToastMessage: actions.layout.setToastMessageAction,
  refreshPostUpdatedAt: actions.posts.refreshPostUpdatedAt,
  setAddPostModalIsOpenAction: actions.app.setAddPostModalIsOpenAction,
  setLastCreatedPostIdAction: actions.app.setLastCreatedPostIdAction,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

export interface PostPageProps {
  postId: EntityReference | null;
}

type ViewPostProps = PostPageProps & DispatchProps & StateProps & WithTranslation & RouteComponentProps;

class PostPage extends Component<ViewPostProps, State> {
  private readonly ionContentRef = React.createRef<HTMLIonContentElement>();
  private readonly ionInputRef = React.createRef<HTMLIonInputElement>();
  private readonly ionSlidesRef = React.createRef<HTMLIonSlidesElement>();
  private readonly sliderModalRef = React.createRef<HTMLIonSlidesElement>();
  public constructor(props: ViewPostProps) {
    super(props);

    this.state = {
      newMessage: '',
      displayAnalyzeData: new URLSearchParams(this.props.location.search).get('estimate') === 'true',
      isActionSheetActive: false,
      showDeleteModal: false,
      showReportModal: false,
      showPersonalizedMessageModal: false,
      isReportLoading: false,
      isInputFocused: false,
      goToPostRequestChatPage: false,
      isImagesModalOpen: false,
      slideModalIndex: 0,
      messageIsBeingSent: false,
    };
  }

  public componentDidMount(): void {
    if (!this.props.postId) {
      return;
    }

    this.refreshPost();

    if (!extractId(this.props.currentUser)) {
      // The following requests require authentication
      return;
    }

    this.refreshPostSubData();
    sendPostPageViewLog(this.props.post);
  }

  /* This ShouldUpdate must not verify state et props diff since some props are using reselect */
  public shouldComponentUpdate(): boolean {
    return !!this.props.postId && locationIsAPostPage();
  }

  public componentDidUpdate(prevProps: ViewPostProps, prevState: State): void {
    if (this.props.postId !== prevProps.postId) {
      this.refreshPost();
    }

    if (this.ionContentRef.current && this.props.postId !== prevProps.postId) {
      setTimeout(() => this.ionContentRef.current?.scrollToTop(1000), 500);
    }

    if (!extractId(this.props.currentUser)) {
      // The following requests require authentication
      return;
    }

    if (!isSameHydraEntity(this.props.post, prevProps.post)) {
      this.refreshPostSubData();
    }
    if (this.props.postId !== prevProps.postId && !isSameHydraEntity(this.props.post?.createdBy, this.props.currentUser)) {
      sendPostPageViewLog(this.props.post);
    }
    if (this.state.goToPostRequestChatPage && this.props.post?.currentUserOpenRequest?.chat) {
      this.setState({ goToPostRequestChatPage: false }, () => this.props.history.push(this.props.post?.currentUserOpenRequest?.chat as string));
    }
    if ((!prevProps.post?.publicChat && this.props.post?.publicChat) || (!this.state.messageIsBeingSent && prevState.messageIsBeingSent != this.state.messageIsBeingSent)) {
      this.getPublicChatMessages();
    }
  }

  private refreshPostSubData = (): void => {
    const { post, isCurrentUser, fetchUserPosts, fetchUserOpenedPostRequest } = this.props;
    if (!post) {
      return;
    }

    this.getPublicChatMessages();

    if (!isDataFresh(post.createdBy.collections?.offer)) {
      fetchUserPosts(extractId(post.createdBy), 'offer', false);
    }

    if (!isDataFresh(post.createdBy.collections?.need)) {
      fetchUserPosts(extractId(post.createdBy), 'need', false);
    }

    if (PostPage.postIsRequestable(post) && !isCurrentUser(post.createdBy)) {
      fetchUserOpenedPostRequest(post);
    }
  };

  private refreshPost(): void {
    // This function can be run without authentication
    const { fetchPost, fetchPostFromPostsStore, postId } = this.props;
    if (!postId) {
      return;
    }
    // If we come from a list, the post is not already into the entities store,
    // but we can get it from the posts store
    fetchPostFromPostsStore(postId);

    fetchPost(postId)
      .then(() => {
        this.setState({ postFetchError: undefined });
      })
      .catch((e: ExtendableError) => {
        this.setState({ postFetchError: e });
      });
  }

  private getPublicChatMessages(): void {
    if (!this.props.post?.publicChat) {
      return;
    }

    this.props.fetchChatMessages(this.props.post.publicChat, this.props.post.publicChat + '/messages?itemsPerPage=1&order[createdAt]=ASC');
  }

  private createPostRequest = async (firstMessage: string): Promise<void> => {
    const { post, createPostRequest, currentUser } = this.props;

    if (!firstMessage) {
      this.props.setToastMessage(this.props.t('unable-to-send-message'));
      return;
    }

    if (!post || !extractId(currentUser)) {
      return;
    }

    await createPostRequest(post)
      .then(postRequest => {
        this.sendMessage(postRequest?.chat, firstMessage);
        this.setState({ goToPostRequestChatPage: true });
      })
      .catch(() => {
        // Prevent error
      });
  };

  private cancelPostRequest = async (postRequest: PostRequest): Promise<void> => {
    await this.props.cancelPostRequest(postRequest);
    this.props.fetchUserOpenedPostRequest(postRequest.post);
  };

  private updateUserPostByReason = async (transition: string, reason?: string | undefined | null): Promise<void> => {
    const { updatePostState, history, post } = this.props;
    if (!post) {
      return;
    }
    this.setState({ showDeleteModal: false });
    try {
      await updatePostState(post, transition, reason);
      history.push('/me');
    } catch (e) {
      // Show toast message
    }
  };

  private static postIsRequestable(post: Post): boolean {
    return publicStates.includes(post.state);
  }

  private slideNext = (): void => {
    if (!this.ionSlidesRef.current) {
      return;
    }

    this.ionSlidesRef.current.slideNext();
  };

  private slidePrev = (): void => {
    if (!this.ionSlidesRef.current) {
      return;
    }

    this.ionSlidesRef.current.slidePrev();
  };

  private updateSliderModal = async (): Promise<void> => {
    if (!this.sliderModalRef.current) {
      return;
    }

    await this.sliderModalRef.current.update();
  };

  private renderMainSlider(post: FullPost): ReactNode {
    if (!post.images?.length || (post.state === 'moderated' && !this.props.isCurrentUser(post.createdBy))) {
      return null;
    }

    const showNavigationButtons = Capacitor.getPlatform() === 'web' && !isMobileNavigator && post.images?.length > 1;
    return (
      // without key IonSlider can`t unmount slides and it causes application crash when we change page
      <Swiper
        modules={[Navigation, Pagination]}
        watchOverflow={true}
        autoHeight={false}
        pagination={true}
        navigation={showNavigationButtons}
        initialSlide={0}
        cssMode={false}
        observer={true}
        slidesPerView={1}
        speed={400}
        grabCursor={true}
        zoom={true}
        className="post-images-slider"
        key={post.images.map(image => extractId(image)).join('_')}
      >
        {post.images.map((image: Media, key: number) => (
          <SwiperSlide key={extractId(image)}>
            <div
              className="cursor-pointer container-img"
              onClick={() => {
                this.setState({ isImagesModalOpen: true, slideModalIndex: key }, this.updateSliderModal);
              }}
              data-cy="post-images-preview"
            >
              <ImageFadeIn src={image.contentUrl} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    );
  }

  private handleScroll = (): void => {
    const contentRef = this.ionContentRef.current;

    if (!contentRef) {
      return;
    }
  };

  private toggleActionSheet = (): void => {
    this.setState({ isActionSheetActive: !this.state.isActionSheetActive });
  };

  private sendMessage = (chatId?: string, message?: string): void => {
    const newMessage = message?.trim();

    if (!chatId || !newMessage || this.state.messageIsBeingSent) {
      return;
    }

    this.setState({ messageIsBeingSent: true });
    this.props
      .sendChatMessage(chatId, newMessage)
      .then(() => {
        this.setState({ newMessage: '', messageIsBeingSent: false });
      })
      .catch(() => {
        this.setState({ messageIsBeingSent: false });
      });
  };

  public sharePost = (): void => {
    if (!this.props.postId) {
      return;
    }

    browserShare(this.props.t('post.share-post-title'), this.props.t('post.share-post-message'), getSharePostUrl(this.props.postId)).catch(e => {
      this.props.setToastMessage(e);
    });
  };

  private updatePost = async (): Promise<void> => {
    if (!this.props.post) {
      return;
    }

    try {
      await this.props.refreshPostUpdatedAt(extractId(this.props.post));
      this.props.setToastMessage(this.props.t('post.ad-updated'));
    } catch (e) {
      console.error(e);
    }
  };

  public render(): ReactNode {
    const { i18n, t, currentUser, getUserById, post, getUserPostsCollection, userArea, categories } = this.props;
    const { newMessage, isImagesModalOpen, messageIsBeingSent, displayAnalyzeData } = this.state;
    const currentLocation = userArea?.location;
    const fullPost = post as FullPost;
    const category = fullPost ? (categories.find(category => category['@id'] === fullPost.category) as Category) : null;

    if (!post && (!this.props.postId || this.state.postFetchError !== undefined)) {
      return <ErrorComponent error={this.state.postFetchError || 404} doRefresh={() => this.refreshPost()} />;
    }

    if (!post || !isFullPost(post)) {
      // The current user request is in progress
      return (
        <IonPage data-cy="post-page">
          <CommonHeader addIonHeader={true} title="" openMenu={this.toggleActionSheet} menuIcon="/assets/navigation/more.svg" menuDataCy="open-actions-sheet" />
          <IonContent>
            <PostPagePlaceholder />
          </IonContent>
        </IonPage>
      );
    }
    const currentUserFull = getUserById(extractId(currentUser));
    const mapCenter = getCenter(post as FullPost);
    const postCreationSuccessModalOpen = post && post.createdBy && this.props.isCurrentUser(post.createdBy) && this.props.lastCreatedPostId === this.props.postId;
    const mapImgSrc = getStaticGoogleMapImage(mapCenter, post.publicLocation?.fakeLocationRadius || 500, 8, post.type === 'offer' ? '0x0000ff33' : '0xff000033');
    const currentUserIsCreator = this.props.isCurrentUser(post.createdBy);
    const userPosts = getUserPostsCollection(extractId(post.createdBy));
    const userOtherNeeds = { ...userPosts.need, items: userPosts.need.items.filter(value => extractId(value) !== extractId(post)) };
    const userOtherOffers = { ...userPosts.offer, items: userPosts.offer.items.filter(value => extractId(value) !== extractId(post)) };
    const userActionSheetButtons = [
      {
        text: this.state.isReportLoading ? this.props.i18n.t('common.loading') : this.props.userCanModeratePost ? this.props.i18n.t('post.report-post-admin') : this.props.i18n.t('post.report-post'),
        role: 'destructive',
        handler: () => this.setState({ showReportModal: true }),
      },
      {
        text: this.props.t('common.cancel'),
        role: 'cancel',
      },
    ];

    const ownerActionSheetButtons = [
      {
        text: this.props.i18n.t('post.refresh-advert'),
        handler: () => this.updatePost(),
      },
      {
        text: this.props.i18n.t('post.edit-post-button'),
        handler: () => this.props.history.push(this.props.activeTab === POSTS_TAB ? `${this.props.postId}/edit` : `/${this.props.activeTab}${this.props.postId}/edit`),
      },
      {
        text: this.props.i18n.t('post.delete-post'),
        role: 'destructive',
        handler: () => this.setState({ showDeleteModal: true }),
      },
      {
        text: this.props.t('common.cancel'),
        role: 'cancel',
      },
    ];

    const actionSheetButtons = currentUserIsCreator ? ownerActionSheetButtons : userActionSheetButtons;

    if (this.props.userIsAdmin) {
      actionSheetButtons.push({
        text: this.props.t('admin.see-in-supervisor'),
        role: 'destructive',
        handler: () => openUrlInNewTab(`${supervisorUrl}/#/posts/${encodeURIComponent(post['@id'])}/show`),
      });
    }

    if (this.props.userIsAnalyzer) {
      actionSheetButtons.push({
        text: this.props.t('post-analyzer.analyze-post'),
        role: 'destructive',
        handler: () => this.setState({ displayAnalyzeData: true }),
      });
    }

    const actionSheetShareButton = {
      text: this.props.i18n.t('post.share-post'),
      handler: () => this.sharePost(),
    };

    // Adding a share button if supported
    if (deviceCanShare) {
      actionSheetButtons.splice(actionSheetButtons.length - 1, 0, actionSheetShareButton);
    }

    const publicMessages = this.props.getPublicMessages(post?.publicChat ?? null);
    const canEditPost = editableStates.includes(post.state);
    const isCancelable = !currentUserIsCreator && post.currentUserOpenRequest !== undefined && cancelableStates.includes(post.currentUserOpenRequest?.state);
    const createdAt = formatDateFromNow(post.createdAt);
    const updatedAt = formatDateFromNow(post.updatedAt);
    const firstPublishedState = post.stateHistory?.find(state => publicStates.includes(state.to));

    return (
      <IonPage data-cy="post-page">
        <CommonHeader
          addIonHeader={true}
          openMenu={this.toggleActionSheet}
          menuIcon={this.props.userIsAdmin || canEditPost ? '/assets/navigation/more.svg' : undefined}
          menuDataCy="open-actions-sheet"
        />
        <IonContent ref={this.ionContentRef} onIonScroll={this.handleScroll} scrollEvents={true}>
          <div className="post-page">
            {!post && <IonSpinner className="common-placeholder-spinner" data-cy="post-page-loader" />}

            {post?.title ? this.renderMainSlider(post as FullPost) : <PostTitlePlaceholder />}
            {post?.createdBy ? (
              <>
                <IonItem className="created-by-item" lines="none" detail={false}>
                  <UserItem
                    user={post?.createdBy}
                    routingComponent={!!extractId(this.props.currentUser)}
                    chipComponent={getChipComponent(post)}
                    isCurrentUser={this.props.isCurrentUser(post?.createdBy)}
                  />
                  {!currentUserIsCreator && currentUserFull && <FollowButton currentUser={currentUserFull} userId={extractId(post.createdBy)} color="dark" fill="outline" />}
                </IonItem>
                <span className="separator" />
              </>
            ) : (
              <PostUserPlaceholder />
            )}

            <div className="info">
              <div className="info-wrapper">
                {post.state === 'fully_requested' && <PostStateChip state={post.state} />}

                <div className="info-title">{getPostTitle(post, t, currentUserIsCreator)}</div>
                {category && (
                  <div className="category">
                    {getItemTranslation(category, category.name)}
                    {post.universe && <span> - {translateUniverse(post.universe)}</span>}
                    {post.size && <span> - {translateSize(getCategoryItemsType(categories, category), post.universe, post.size)}</span>}
                  </div>
                )}
                {post.publicLocation && (
                  <div className="info-distance">
                    {post.publicLocation.locality}
                    {post.publicLocation.country && post.publicLocation.postalCode && ` ${getBoroughIfExist(post.publicLocation.country, post.publicLocation.postalCode)}`}
                    {currentLocation && post.publicLocation && post.publicLocation.locality && <> &bull; </>}
                    {currentLocation && post.publicLocation && `${formattedDistanceBetweenCoords(currentLocation as GeolocationCoordinates, post.publicLocation)}`}
                  </div>
                )}

                <div className="info-time capitalize-first-letter">
                  {currentUserIsCreator ? (
                    <>
                      {post.createdAt !== post.updatedAt && <div>{`${t('post.updated')} ${updatedAt}`}</div>}
                      <div>{`${t('post.created')} ${createdAt}`}</div>
                    </>
                  ) : post.createdAt !== post.updatedAt ? (
                    <div title={titleTagForPostDate(post, i18n)}>{`${t('post.updated')} ${updatedAt}`}</div>
                  ) : (
                    <div title={titleTagForPostDate(post, i18n)}>{`${t('post.published')} ${createdAt}`}</div>
                  )}
                </div>

                {!currentUserIsCreator && this.props.isCurrentUserPostRequestLoading && (
                  <IonButton disabled={true} color="primary" fill="solid" className="main-button" data-cy="create-post-request-button">
                    {t('common.loading')}
                  </IonButton>
                )}

                {!currentUserIsCreator && post.currentUserOpenRequest === undefined && PostPage.postIsRequestable(post) && !this.props.isCurrentUserPostRequestLoading && (
                  <RoutingGuard>
                    <IonButton
                      onClick={() => this.setState({ showPersonalizedMessageModal: true })}
                      disabled={this.props.isCurrentUserPostRequestLoading}
                      color="primary"
                      fill="solid"
                      className="main-button"
                      data-cy="create-post-request-button"
                    >
                      {t(`post.create-post-request-${post.type === 'need' ? 'need' : 'offer-' + post.categoryType}`)}
                    </IonButton>
                  </RoutingGuard>
                )}

                {isCancelable && !this.props.isCurrentUserPostRequestLoading && (
                  <IonButton
                    onClick={() => this.cancelPostRequest(post.currentUserOpenRequest as PostRequest)}
                    disabled={this.props.isCurrentUserPostRequestLoading}
                    color="danger"
                    fill="outline"
                    className="main-button"
                    data-cy="cancel-post-request-button"
                  >
                    <Trans i18nKey="post-request.cancel-request" />
                  </IonButton>
                )}

                {currentUserIsCreator && <ChatRedirectionButton post={post} />}

                {post.state === 'moderated' && (
                  <p className="post-state-info post-state-info-moderated">
                    <Trans i18nKey="post.state-info.moderated-text" />
                  </p>
                )}

                {post.state === 'pending' && (
                  <p className="post-state-info post-state-info-pending">
                    <Trans i18nKey="post.state-info.pending-text" />
                  </p>
                )}

                {post.state === 'deleted' && (
                  <IonButton className="main-button" color="danger" fill="solid" disabled>
                    {t('post.deleted')}
                  </IonButton>
                )}

                {post.state === 'finished' && (
                  <IonButton className="main-button" color="dark" fill="solid" disabled>
                    {t('chat.completed')}
                  </IonButton>
                )}

                {!postDeadlineReached(post.updatedAt) && firstPublishedState && PostPage.postIsRequestable(post) && currentUserIsCreator && (
                  <p className="post-state-info post-online-for">
                    <Trans i18nKey="post.ad-online-for" values={{ date: formatDateFromNowWithLessThanOneHour(firstPublishedState.date, t, true) }} />
                  </p>
                )}

                {postDeadlineReached(post.updatedAt) && PostPage.postIsRequestable(post) && currentUserIsCreator && (
                  <>
                    <div className="post-state-info post-will-be-deleted">
                      {getDaysBeforeDeletePost(post.updatedAt) <= 0 ? (
                        <Trans i18nKey="post.ad-will-be-soon-deleted" />
                      ) : (
                        <Trans i18nKey="post.ad-will-be-deleted" values={{ count: getDaysBeforeDeletePost(post.updatedAt) }} />
                      )}
                    </div>
                    <div className="post-will-be-deleted-buttons-container">
                      <IonButton color="danger" onClick={() => this.updatePost()} fill="solid" className="main-button danger-button button-left" data-cy="button-refresh-post">
                        {t('post.refresh')}
                      </IonButton>
                      <IonButton color="danger" onClick={() => this.setState({ showDeleteModal: true })} fill="solid" className="main-button danger-button button-right" data-cy="button-delete-post">
                        {t('post.delete-post')}
                      </IonButton>
                    </div>
                  </>
                )}
              </div>
            </div>
            {post.description && (post.state !== 'moderated' || currentUserIsCreator) && (
              <div className="description">
                <IonText className="description-title" color="dark">
                  <Trans i18nKey="post.description" />
                </IonText>
                <IonText className="description-content" color="dark">
                  {post.description}
                </IonText>
              </div>
            )}

            {displayAnalyzeData && <PostEmissionData postId={extractId(post)} runAnalyze />}
            {displayAnalyzeData && <PostAnalyzerData postId={extractId(post)} runAnalyze />}

            {post.publicLocation !== undefined && post.state !== 'moderated' && (
              <a
                className="map"
                href={addParametersToUrl('https://www.google.com/maps/search/?api=1', { query: post.publicLocation.latitude + ',' + post.publicLocation.longitude })}
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="map-wrapper">
                  <ImageFadeIn src={mapImgSrc} alt={t('post.google-map')} />
                </div>
              </a>
            )}

            {post.publicChat && !!extractId(this.props.currentUser) && (
              <div className="public-chat">
                {publicMessages.totalItems !== 0 && (
                  <IonRouterLink routerLink={`/${this.props.activeTab}${this.props.activeTab === POSTS_TAB ? '' : '/posts'}${post.publicChat}`}>
                    <IonText color="medium" className="comment-page-link">
                      <Trans i18nKey="post.view-comment" values={{ count: publicMessages.totalItems }} />
                    </IonText>
                  </IonRouterLink>
                )}

                {publicMessages.items.length !== 0 && (
                  <>
                    <IonItem lines="none" className="last-comment">
                      <IonText>
                        <b>{publicMessages.items[publicMessages.items.length - 1].createdBy.name} </b> {publicMessages.items[publicMessages.items.length - 1].text}
                      </IonText>
                    </IonItem>

                    <IonFooter>
                      <IonItem lines="none" className="add-comment-input">
                        <IonAvatar slot="start" className={!this.state.isInputFocused ? 'small-avatar' : ''}>
                          <IonImg alt={currentUser.name} src={getUserAvatar(currentUser)} />
                        </IonAvatar>
                        <IonInput
                          ref={this.ionInputRef}
                          className="message-input"
                          placeholder={i18next.t('chat.add-comment')}
                          value={newMessage}
                          autocapitalize="on"
                          onIonChange={e => {
                            this.setState({ newMessage: e.detail.value || '' });
                          }}
                          onKeyDown={e => onKeyboardEnterExecuteFunction(e, () => this.sendMessage(this.props.post?.publicChat, this.state.newMessage))}
                          onIonFocus={() => this.setState({ isInputFocused: true })}
                          onIonBlur={() => this.setState({ isInputFocused: false })}
                          spellcheck={true}
                          autocorrect={'on'}
                          autocomplete={'on'}
                        />
                        <IonButton
                          slot="end"
                          disabled={messageIsBeingSent}
                          onClick={() => this.sendMessage(this.props.post?.publicChat, this.state.newMessage)}
                          fill="clear"
                          size="default"
                          className="send-button"
                        >
                          <IonIcon icon={send} mode="md" />
                        </IonButton>
                      </IonItem>
                    </IonFooter>
                  </>
                )}
              </div>
            )}

            {this.props.postId && post.state !== 'moderated' && (
              <ShareButtons shareUrl={getSharePostUrl(this.props.postId)} shareTitle={t('post.share-post-title')} shareMessage={t('post.share-post-message')} shareComponentTitle="post.share-post" />
            )}

            {!userOtherOffers.items.length && userOtherOffers.isLoading ? (
              <PostsUserListPlaceholder />
            ) : (
              <React.Fragment>
                {userOtherOffers.items.length > 0 && (
                  <UserPostsSlider
                    postsCollection={userOtherOffers}
                    collectionName={'offer'}
                    title={currentUserIsCreator ? t('post.you-also-offer') : t('post.also-offer', { username: post.createdBy.name })}
                    currentLocation={currentLocation}
                    isCurrentUser={this.props.isCurrentUser}
                    userId={extractId(post.createdBy)}
                  />
                )}
              </React.Fragment>
            )}

            {userOtherNeeds.isLoading ? (
              <>
                <PostHelperPlaceholder />
                <PostsUserListPlaceholder />
              </>
            ) : (
              <>
                {userOtherNeeds.items.length > 0 && (
                  <>
                    <div className="help-user">
                      <IonText color="dark">{t('post.help-user', { username: post.createdBy.name })}</IonText>
                    </div>
                    <UserPostsSlider
                      postsCollection={userOtherNeeds}
                      title={currentUserIsCreator ? t('post.you-also-ask-for') : t('post.ask-for', { username: post.createdBy.name })}
                      currentLocation={currentLocation}
                      isCurrentUser={this.props.isCurrentUser}
                      collectionName="need"
                    />
                  </>
                )}
              </>
            )}
          </div>

          {currentUserIsCreator && (
            <DeletePostModal
              postTypeCategory={post.categoryType}
              postType={post.type}
              postId={extractId(post)}
              isOpen={this.state.showDeleteModal}
              closeModal={() => this.setState({ showDeleteModal: false })}
              updatePostState={this.updateUserPostByReason}
              postState={post.state}
            />
          )}

          {!currentUserIsCreator && (
            <ReportModal
              isOpen={this.state.showReportModal}
              closeModal={() => this.setState({ showReportModal: false })}
              postId={extractId(post)}
              setReportLoadingState={isReportLoading => this.setState({ isReportLoading })}
            />
          )}

          {!currentUserIsCreator && (
            <PersonalizedMessageModal
              isOpen={this.state.showPersonalizedMessageModal}
              onCloseModal={() => this.setState({ showPersonalizedMessageModal: false })}
              onMessageSelection={this.createPostRequest}
              postType={this.props.post?.type}
              messageReceiver={this.props.post?.createdBy}
            />
          )}

          <IonActionSheet data-cy="post-action-sheet" isOpen={this.state.isActionSheetActive} onDidDismiss={this.toggleActionSheet} buttons={actionSheetButtons} />

          <GalleryModal isOpen={isImagesModalOpen} closeModal={() => this.setState({ isImagesModalOpen: false })} images={post.images} initialSlide={this.state.slideModalIndex} />
        </IonContent>
        {currentUserIsCreator && <PostCreationSuccessModal isOpen={postCreationSuccessModalOpen && !!this.props.postId} post={this.props.post} />}
      </IonPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withIonLifeCycle(withRouterAndRef(PostPage))));
