import { Components } from '@ionic/core';
import { IonButton, IonCheckbox, IonCol, IonInput, IonItem, IonLabel, IonRow, IonText, IonTextarea } from '@ionic/react';
import { ErrorMessage, Field, FieldProps, Form, FormikHelpers, FormikProps } from 'formik';
import React, { Component, ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ObjectSchema } from 'yup';
import { Formik } from 'formik';
import { InputMediaUploaderProps } from './inputs/InputMediaUploader';
import { ReactComponentType } from './types';
import { getKeysAndValuesFromObject, getValueFromHtmlInputChangeEvent, InputChangeEvent, onKeyboardEnterExecuteFunction, uploadAndValidateFiles } from '../utils/helpers';
import i18n from 'i18next';
import { ThematicsTagsProps } from './ThematicTagItems';
import { isEqual } from 'lodash';
import { SelectFieldProps } from './inputs/SelectField';
import './DynamicForm.scss';
import { TextAreaFieldComponent } from './UserForm';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FormValue = any;

export interface FormValues {
  [key: string]: FormValue;
}

export interface FormConstraints {
  [key: string]: unknown;
}

export type FormFieldOptionType =
  | Partial<SelectFieldOptions>
  | Partial<Components.IonInput>
  | InputMediaUploaderProps
  | Partial<ThematicsTagsProps>
  | Partial<Components.IonTextarea>
  | Partial<SelectFieldProps>
  | (Partial<Components.IonCheckbox> & { slot: string })
  | { submitOnChange?: boolean };

export interface FormField {
  labelNumber?: number;
  label?: string;
  labelOptions?: Partial<Components.IonLabel>;
  name: string;
  formComponent: ReactComponentType;
  options?: FormFieldOptionType;
  withoutIonItem?: boolean;
  itemLines?: 'none' | 'full' | 'inset' | 'border';
  bottomExample?: string;
  disableAutomaticSubmission?: boolean;
}

export interface SelectFieldOptions extends Components.IonSelect {
  selectOptions: SelectOptions;
  textWrap?: boolean;
}

export type SelectOptions = { value: string; label: string }[];

export type FormFields = FormField[];

interface DynamicFormProps {
  formFields: FormFields;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validationSchema: ObjectSchema<any>;
  initialValues: FormValues;
  onSubmitForm: (values: Readonly<FormValues>) => Promise<void>;
  onSubmitErrors?: (actions: FormikHelpers<FormValues>, errors: Record<string, string>) => Promise<void>;
  onAfterSubmitFormSuccess?: (values: Readonly<FormValues>, actions: FormikHelpers<FormValues>) => Promise<void>;
  // constraints is only used for translations in ErrorMessage
  constraints?: FormConstraints;
  submitButtonText?: string;
  onFieldChange?: (field: string, value: FormValue, form: FormikProps<FormValues>) => void;
  onBlur?: (field: string, values: Readonly<FormValues>) => void;
  dynamicFormSubmitButton?: React.FunctionComponent<FormikProps<FormValues>>;
  touchOnLoad?: boolean;
  fieldsToTouchOnLoad?: string[];
}

type Props = WithTranslation & DynamicFormProps;

export const InputFieldComponent: ReactComponentType = ({ ...props }: React.HTMLAttributes<unknown> & Components.IonInput) => {
  return <IonInput {...props} label={undefined} />;
};

export const TextareaFieldComponent: ReactComponentType = ({ ...props }: React.HTMLAttributes<unknown> & Components.IonTextarea & { bottomExample?: string }) => {
  return <IonTextarea {...props} label={undefined} />;
};

export const InputWithValidationFieldComponent: ReactComponentType = ({ ...props }: React.HTMLAttributes<unknown> & Components.IonInput) => {
  return (
    <>
      <IonInput {...props} clearInput={false} label={undefined} />
      <img alt="validation" className="icon-svg-validation" src="/assets/form/icon-validation.svg" />
    </>
  );
};

export const CheckboxFieldComponent: ReactComponentType = (props: Components.IonCheckbox) => {
  return <IonCheckbox {...props} checked={!!props?.value} color="primary" />;
};

class DynamicForm extends Component<Props> {
  private readonly formikRef: React.MutableRefObject<FormikProps<FormValues> | undefined>;

  constructor(props: Props) {
    super(props);
    this.formikRef = React.createRef() as React.MutableRefObject<FormikProps<FormValues> | undefined>;
  }

  componentDidMount(): void {
    this.verifyFormIsValid();
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.formFields === this.props.formFields) {
      return;
    }

    this.verifyFormIsValid();
  }

  private verifyFormIsValid(): void {
    const { touchOnLoad, fieldsToTouchOnLoad, formFields } = this.props;
    const formikRef = this.formikRef.current;
    if (!formikRef) {
      return;
    }

    formikRef.validateForm().then(value => {
      if (!Object.keys(value)) {
        return;
      }
      formFields.forEach(field => {
        const shouldTouchThisField: boolean = fieldsToTouchOnLoad ? fieldsToTouchOnLoad.includes(field.name) : false;
        formikRef.setFieldTouched(field.name, !!touchOnLoad || shouldTouchThisField);
      });
    });
  }

  private static inputChanged(form: FormikProps<FormValues>, input: FormField): void {
    if (form.status && form.status[input.name]) {
      delete form.status[input.name];
    }
  }

  private static getMediaFieldIsMultiple(field: FormField): boolean {
    const options: InputMediaUploaderProps = field.options as InputMediaUploaderProps;
    return !!options.multiple;
  }

  private renderFields(form: FormikProps<FormValues>): ReactNode {
    const inputsCount = this.props.formFields.length;

    return this.props.formFields.map((input: FormField, index: number) => {
      if (!input.formComponent) {
        return <></>;
      }

      const fieldHasError = (form.status && form.status[input.name]) || (form.errors[input.name] && form.touched[input.name]);
      const fieldCanSubmit = index === inputsCount - 1 && !input.disableAutomaticSubmission;

      return (
        <React.Fragment key={input.name}>
          {/* If no className is given to IonItem in case of success, the border stay red even without any errors */}
          {input.withoutIonItem ? (
            this.renderFormField(input, form, fieldCanSubmit)
          ) : (
            <>
              <IonItem className={`${fieldHasError ? 'ion-invalid' : 'no-error-field'}`} lines={input.itemLines === 'border' ? 'none' : input.itemLines} mode="md">
                {input.label && (
                  <IonLabel position="floating" color={fieldHasError ? 'danger' : 'dark'} className={input.labelNumber ? 'label-with-number' : ''} {...input.labelOptions}>
                    {input.labelNumber && <div className={this.getNumberClasses(form, fieldHasError, input)}>{input.labelNumber}</div>}
                    {this.props.t(input.label)} {(input.options as Partial<Components.IonInput>)?.required && ' *'}
                  </IonLabel>
                )}
                {this.renderFormField(input, form, fieldCanSubmit)}
              </IonItem>
              {input.bottomExample && (
                <IonText className="bottom-example" color="medium">
                  {this.props.t(input.bottomExample)}
                </IonText>
              )}
            </>
          )}
          {(form.errors || form.status) && (
            <p className="ion-no-margin form-error-message">
              <ErrorMessage name={input.name} render={(errorMessage: string): string => this.props.t(errorMessage, this.props.constraints)} />
              {form.status && form.status[input.name] ? form.status[input.name] : ''}
            </p>
          )}
        </React.Fragment>
      );
    });
  }

  // Warning with formik on update state --> https://github.com/jaredpalmer/formik/pull/1503
  render(): ReactNode {
    return (
      // innerRef is a recent feature of formik and is poorly typed
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      <Formik innerRef={this.formikRef as any} onSubmit={this.onSubmit} validationSchema={this.props.validationSchema} initialValues={this.props.initialValues} enableReinitialize>
        {(formikBag: FormikProps<FormValues>): ReactNode => (
          <Form className="dynamic-form" noValidate>
            {this.renderFields(formikBag)}
            {this.props.dynamicFormSubmitButton ? (
              this.props.dynamicFormSubmitButton(formikBag)
            ) : (
              <IonRow responsive-sm>
                <IonCol className="dynamic-form-buttons">
                  <IonButton type="submit" shape="round" className="ion-margin" disabled={!formikBag.isValid || formikBag.isValidating || formikBag.isSubmitting}>
                    {this.props.submitButtonText || this.props.t('common.save')}
                  </IonButton>
                </IonCol>
              </IonRow>
            )}
          </Form>
        )}
      </Formik>
    );
  }

  private getNumberClasses = (form: FormikProps<FormValues>, fieldHasError: boolean, input: FormField): string => {
    return `number ${
      (!Array.isArray(form.values[input.name]) && form.values[input.name] && !fieldHasError) || (Array.isArray(form.values[input.name]) && form.values[input.name].length > 0) ? 'number-green' : ''
    }`;
  };

  private renderFormField = (input: FormField, form: FormikProps<FormValues>, isLastField: boolean): ReactNode => {
    const Comp: ReactComponentType = input.formComponent;
    const { handleBlur } = form;
    return (
      <Field name={input.name}>
        {(fieldProps: FieldProps<FormValues>): ReactNode => {
          const field = fieldProps.field;
          const form = fieldProps.form;
          const meta = fieldProps.meta;

          let value: FormValue = field.value;

          // Fix https://github.com/reduxjs/react-redux/issues/41 bug with Formik field values
          if (Array.isArray(value)) {
            value = [...value];
          }

          const submitFormIfValid = (): void => {
            if (form.isValid && !form.isValidating && !form.isSubmitting) {
              this.onSubmit(form.values, form);
            }
          };

          return (
            <Comp
              form={form} // TODO Remove
              {...input.options}
              name={input.name}
              label={input.label}
              value={value}
              onIonFocus={(e: CustomEvent<void>): void => {
                this.handleFocus(e);
              }}
              onIonBlur={(e: CustomEvent<void>): void => {
                form.setFieldTouched(field.name, e.returnValue);
                if (this.props.onBlur) {
                  this.props.onBlur(field.name, form.values);
                }
                handleBlur(e);
              }}
              onIonInput={(): void => {
                DynamicForm.inputChanged(form, input);
              }}
              onIonChange={(e: CustomEvent<InputChangeEvent>): void => {
                this.changeField({ field, form, meta }, getValueFromHtmlInputChangeEvent(e));
              }}
              onValueChange={(newValue: FormValue, resetStatus = true) => {
                this.changeField({ field, form, meta }, newValue, resetStatus);
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLIonInputElement>) =>
                onKeyboardEnterExecuteFunction(e, () => {
                  if (!isLastField || input.formComponent === TextAreaFieldComponent) {
                    e.stopPropagation();
                    return;
                  }

                  submitFormIfValid();
                })
              }
              submitFormIfValid={submitFormIfValid}
            />
          );
        }}
      </Field>
    );
  };

  private handleFocus(e?: CustomEvent): void {
    if (e && e.target) {
      const ionInputElement = e.target as HTMLIonInputElement;
      if (ionInputElement.tagName !== 'ION-INPUT') {
        return;
      }

      ionInputElement
        .getInputElement()
        .then((input: HTMLInputElement) => {
          const initialType = input.type;
          input.type = 'text';
          input.selectionStart = input.selectionEnd = input.value.length;
          input.type = initialType;
        })
        .catch();
    }
  }

  private getUploadFields(): FormField[] {
    return this.props.formFields.filter(field => field.options && (field.options as InputMediaUploaderProps).isMediaUploaderField);

    // Unable to retrieve InputMediaUploader name in production --> withI18next(t) instead of withI18next(InputMediaUploader) in dev
    // return this.props.formFields.filter(field => field.formComponent.name && field.formComponent.name.toString().includes('InputMediaUploader'));
  }

  private onSubmit = async (values: FormValues, actions: FormikHelpers<FormValues>): Promise<void> => {
    if (this.getUploadFields().length) {
      try {
        await this.handleUploadFiles(values, actions);
      } catch (e) {
        // Error messages and new values have been set in the previous method
        actions.setSubmitting(false);
        return;
      }

      // Get values another time in order to have the file data updated in handleUploadFiles
      for (const field of this.getUploadFields()) {
        values[field.name] = this.formikRef.current?.values[field.name];
      }
    }

    const postedValues: FormValues = Object.assign({}, values);

    for (const field of this.getUploadFields()) {
      if (!DynamicForm.getMediaFieldIsMultiple(field)) {
        postedValues[field.name] = Array.isArray(postedValues[field.name]) && postedValues[field.name].length ? postedValues[field.name][0] : null;
      }
    }

    try {
      await this.props.onSubmitForm(postedValues);
    } catch (e) {
      actions.setStatus(e.errors ? e.errors : e);
      if (this.props.onSubmitErrors) {
        await this.props.onSubmitErrors(actions, e.errors ? e.errors : e);
      }
      actions.setSubmitting(false);
      return;
    }

    if (this.props.onAfterSubmitFormSuccess) {
      this.props.onAfterSubmitFormSuccess(values, actions);
    }
  };

  private async handleUploadFiles(values: FormValues, actions: FormikHelpers<FormValues>): Promise<void> {
    for (const field of this.getUploadFields()) {
      if (!values[field.name] || (Array.isArray(values[field.name]) && values[field.name].length === 0)) {
        // If image field isn't required, we continue to submit the form
        if (!(field.options as { required: boolean }).required) {
          continue;
        }

        actions.setFieldError(field.name, i18n.t('media.item-required', { name: field.name }));
        throw new Error(`No value for ${field.name} field`);
      }

      await uploadAndValidateFiles(values, actions, field.name, this.props.t);
    }
  }

  private changeField({ field, form }: FieldProps<FormValues>, value: FormValue, resetStatus = false): void {
    if (null === value || undefined === value) {
      form.setFieldValue(field.name, ''); // It is need to clear form value if value of input is empty
      return;
    }

    if (typeof value === 'object' && !Array.isArray(value)) {
      getKeysAndValuesFromObject(value).forEach(keyValue => {
        form.setFieldValue(keyValue.key, keyValue.value);
      });
    } else {
      // Using isEqual because [] !== []
      // This if fixes an infinite loop issue, see https://github.com/ionic-team/ionic-framework/issues/20106
      if (!isEqual(form.values[field.name], value)) {
        form.setFieldValue(field.name, value);
      }
    }

    if (resetStatus) {
      form.setStatus({ [field.name]: undefined });

      if (typeof value === 'object' && !Array.isArray(value)) {
        getKeysAndValuesFromObject(value).forEach(keyValue => {
          form.setStatus({ [keyValue.key]: undefined });
        });
      } else {
        form.setStatus({ [field.name]: undefined });
      }
    }

    if (this.props.onFieldChange) {
      this.props.onFieldChange(field.name, value, form);
    }
  }
}

export default withTranslation()(DynamicForm);
