import React from 'react';
import { IonRow, IonCol, IonText, IonRouterLink } from '@ionic/react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { extractId } from '../../utils/helpers';
import { RootState } from '../../store';
import { CurrentUser, User } from '../../store/users/types';
import i18next from 'i18next';

import './UserComponents.scss';

interface Props {
  user: User;
  currentUser?: CurrentUser;
}

type UserExperienceProps = Props & WithTranslation;

const UserExperience: React.FunctionComponent<UserExperienceProps> = ({ user, currentUser }: UserExperienceProps) => {
  const positiveReviewsNumber = user?.collections?.positivePostRequestReviews?.totalItems;
  const completedPostRequests = currentUser ? currentUser.status?.completedPostRequests : user.status?.completedPostRequests;
  const activeTab = useSelector((state: RootState) => state.navigation.activeTab);
  const userLink = activeTab ? `/${activeTab}${extractId(user)}` : extractId(user);

  return (
    <>
      <IonRow responsive-sm className="experience">
        <IonCol size="12">
          <IonText color="dark" className="title">
            <Trans i18nKey="user.experience" />
          </IonText>
        </IonCol>
        <IonCol size="12">
          <IonText color="dark" className="text-info">
            {completedPostRequests !== 0 ? (
              <Trans i18nKey={`${currentUser ? 'user.current-user-exchanges' : 'user.user-exchanges'}`} values={{ count: completedPostRequests, userName: user.name }} />
            ) : (
              <Trans i18nKey={`${currentUser ? 'user.current-user-no-exchanges' : 'user.user-no-exchanges'}`} values={{ userName: user.name }} />
            )}
          </IonText>
          {positiveReviewsNumber !== undefined && positiveReviewsNumber > 0 && (
            <IonText className="positive-reviews">
              <Trans i18nKey={`${currentUser ? 'user.current-user-succeeded-exchanges' : 'user.succeeded-exchanges'}`} values={{ count: positiveReviewsNumber, userName: user.name }} />
            </IonText>
          )}
        </IonCol>
        <IonCol size="12">
          <IonRouterLink routerLink={userLink + '/reviews'}>
            <IonText color="primary">{i18next.t('review.read-reviews')}</IonText>
          </IonRouterLink>
        </IonCol>
      </IonRow>
    </>
  );
};

export default React.memo(withTranslation()(UserExperience));
