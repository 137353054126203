import React, { ReactNode } from 'react';
import { IonButton, IonContent, IonHeader, IonIcon, IonModal } from '@ionic/react';
import { Trans } from 'react-i18next';
import { ErrorMessage, Field, FieldProps, FormikProps } from 'formik';
import { FormValue, FormValues } from './DynamicForm';
import InputPlaceAutocomplete from './inputs/InputPlaceAutocomplete';
import { EditablePost } from '../store/posts/types';
import i18next from 'i18next';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAddressChange: (value: FormValue) => void;
}

class AddressModal extends React.Component<Props> {
  public render(): ReactNode {
    return (
      <IonModal isOpen={this.props.isOpen} onDidDismiss={this.props.onClose} className="safe-area-ios" data-cy="address-modal">
        <IonHeader>
          <IonButton onClick={this.props.onClose} fill="clear" className="header-back-button">
            <IonIcon icon="/assets/navigation/close.svg" color="dark" />
          </IonButton>
          <h1 className="header-title">
            <Trans i18nKey="post.place" />
          </h1>
        </IonHeader>
        <IonContent>
          <Field name={'address.formatted'}>
            {(fieldProps: FieldProps<FormValues>): ReactNode => {
              const field = fieldProps.field;
              const form = fieldProps.form;
              const value: FormValue = field.value;
              const statusError = form.status && form.status['address.formatted'];
              const fieldHasError = statusError || (form.errors['address'] && form.touched['address']);

              return (
                <>
                  <InputPlaceAutocomplete showLabel form={form as FormikProps<EditablePost>} name={'address.formatted'} value={value} onValueChange={this.onAddressSelect} />
                  {fieldHasError && (
                    <>
                      <p className="ion-no-margin form-error-message">
                        <ErrorMessage name={'address.formatted'} render={(errorMessage: string): string => i18next.t(errorMessage)} />
                      </p>
                      {statusError && <p className="ion-no-margin form-error-message">{form.status['address.formatted']}</p>}
                    </>
                  )}
                  <p className="ion-margin">
                    <Trans i18nKey="post.explanation-address-use" />
                  </p>
                </>
              );
            }}
          </Field>
        </IonContent>
      </IonModal>
    );
  }

  private onAddressSelect = (value: FormValue): void => {
    this.props.onAddressChange(value);
    this.props.onClose();
  };
}

export default AddressModal;
