import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { setRedirectAfterLogin } from '../../utils/routeHelpers';
import './RoutingGuard.scss';

interface Props {
  children?: ReactElement;
  positionAbsolute?: boolean;
  route?: string;
}

interface StateProps {
  isConnected: boolean | undefined;
}

const mapStateToProps = (state: RootState): StateProps => ({
  isConnected: state.app.isFullyLogged,
});

const mapDispatchToProps = {
  setPopoverConnectionIsOpenAction: actions.app.setPopoverConnectionIsOpenAction,
};

type RoutingGuardProps = Props & StateProps & typeof mapDispatchToProps;

const RoutingGuard: React.FunctionComponent<RoutingGuardProps> = ({ children, positionAbsolute, isConnected, route, setPopoverConnectionIsOpenAction }: RoutingGuardProps) => {
  const preventUnauthorizedRouting = (e: React.MouseEvent): void => {
    if (isConnected) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();
    setRedirectAfterLogin(route ?? location.pathname);
    setPopoverConnectionIsOpenAction(true);
  };

  return (
    <div className={`routing-guard ${positionAbsolute ? 'position-absolute' : ''}`} onClickCapture={preventUnauthorizedRouting}>
      {children}
    </div>
  );
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(RoutingGuard));
