import { IonButton, IonChip, IonContent, IonLabel, IonLoading, IonModal } from '@ionic/react';
import { orderBy, round } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { stringifyError } from '../utils/helpers';
import CommonHeader from './CommonHeader';
import { RootState } from '../store';
import { connect } from 'react-redux';
import { fetchPostAnalyzedData, fetchPostEstimateData } from '../store/posts/actions';
import { EntityReference } from '../utils/hydra';
import { AnalyzerPrediction, AnalyzerRawResult, AnalyzerResult, EmissionRule, PostEstimateData } from '../store/posts/types';
import { getUserIsAnalyzer } from '../store/app/selectors';
import './PostAnalyzerData.scss';

interface EstimatedItemLabelProps {
  label: string;
  certainty?: number;
}

const EstimatedItemLabel: React.FunctionComponent<EstimatedItemLabelProps> = (props: EstimatedItemLabelProps) => {
  return (
    <p className="estimated-item-label">
      <Trans i18nKey={props.label} />
      {props.certainty !== undefined && (
        <span className="certainty">
          <Trans i18nKey="post-analyzer.certainty" /> : {round(props.certainty * 100)} %
        </span>
      )}
    </p>
  );
};

interface EstimatedTag {
  label: string;
  opacity?: number;
}

interface EstimatedTagProps {
  title: string;
  tagList: EstimatedTag[];
}

const EstimatedTags: React.FunctionComponent<EstimatedTagProps> = ({ title, tagList }: EstimatedTagProps) => {
  if (!tagList?.length) {
    return null;
  }

  return (
    <div className="estimated-item estimated-item-tag-list">
      <EstimatedItemLabel label={title} />
      <div>
        {tagList.map(item => {
          const style = { backgroundColor: `rgba(0, 200, 0, ${item.opacity ?? 1}`, color: 'var(--ion-color-white)' };
          return (
            <IonChip key={item.label} style={item.opacity !== undefined ? style : {}}>
              <IonLabel>{item.label}</IonLabel>
            </IonChip>
          );
        })}
      </div>
    </div>
  );
};

interface EstimatedValueProps {
  label: string;
  unit?: string;
  value?: number | string | null;
  certainty?: number;
  ignoreEmptyValues?: boolean;
}

const EstimatedValue: React.FunctionComponent<EstimatedValueProps> = ({ label, value, unit = undefined, certainty = undefined, ignoreEmptyValues = true }: EstimatedValueProps) => {
  if (value === undefined && ignoreEmptyValues) {
    return null;
  }

  if (typeof value !== 'string') {
    value = round(value || 0, 3) || 0;
  }

  return (
    <div className="estimated-item">
      <EstimatedItemLabel label={label} certainty={certainty} />
      <p className="estimated-item-value">{`${value}  ${unit ?? ''}`}</p>
    </div>
  );
};

interface EstimatedCategoryProps {
  estimatedCategory?: AnalyzerRawResult;
}

const EstimatedCategory: React.FunctionComponent<EstimatedCategoryProps> = (props: EstimatedCategoryProps) => {
  if (!props.estimatedCategory) {
    return null;
  }

  const { score, item_desc } = props.estimatedCategory;
  const estimatedTags: EstimatedTag[] = [item_desc?.category, item_desc?.subcategory]
    .map(item => {
      return { label: item } as EstimatedTag;
    })
    .filter(item => !!item.label);

  return (
    <div className="estimated-data-block-outline">
      <EstimatedValue label={item_desc?.bow ?? ''} value={(score || 0) * 100} unit="%" />

      <EstimatedTags title="post.category" tagList={estimatedTags} />

      <EstimatedValue label="post-analyzer.indicative-weight-kg" value={item_desc?.indicative_weight_kg} unit="kg" />
      <EstimatedValue label="post-analyzer.emission-factor" value={item_desc?.facteur_emission} unit={item_desc?.unit} />
      <EstimatedValue label="post-analyzer.weight-kg" value={item_desc?.weight_kg} unit="kg" />
    </div>
  );
};

interface RuleBlockProps {
  rule: EmissionRule;
}

const RuleBlock: React.FunctionComponent<RuleBlockProps> = ({ rule }: RuleBlockProps) => {
  return (
    <div className="estimated-data-block-outline">
      <EstimatedValue label="Category" value={rule.itemCategory?.name} />
      <EstimatedValue label="CategoryDiscriminant" value={rule.itemCategoryDiscriminant || '-'} />
      <EstimatedValue
        label="Emission Factor"
        value={rule.emissionFactor || 0}
        unit={`${rule.emissionFactorUnit || ''} / ${rule.emissionFactorIsPer || ''}`}
        certainty={(100 - (rule.emissionFactorIncertitudePercent || 0)) / 100}
      />
      <EstimatedValue label="Emission Factor source" value={rule.emissionFactorSource} />
      <EstimatedValue label="Recycling or Reuse" value={rule.recyclingOrReusePercent} unit="%" />
      <EstimatedValue label="Recycling or Reuse source" value={rule.recyclingOrReuseSource} />
      <EstimatedValue label="Average weight" value={rule.averageWeight} unit="kg" certainty={(100 - (rule.averageWeightIncertitudePercent || 0)) / 100} />
      {rule.wasteIncinerationEmissionFactor && (
        <div className="estimated-data-block-outline">
          <EstimatedValue label="Incineration category" value={rule.wasteIncinerationCategory} />
          <EstimatedValue
            label="Incineration Emission Factor"
            value={rule.wasteIncinerationEmissionFactor}
            unit="kgCO2e/ton"
            certainty={(100 - (rule.wasteIncinerationEmissionIncertitudePercent || 0)) / 100}
          />
        </div>
      )}
      {rule.wasteStorageEmissionFactor && (
        <div className="estimated-data-block-outline">
          <EstimatedValue label="Storage category" value={rule.wasteStorageCategory} />
          <EstimatedValue label="Storage Emission Factor" value={rule.wasteStorageEmissionFactor} unit="kgCO2e/tonne" certainty={(100 - (rule.wasteStorageEmissionIncertitudePercent || 0)) / 100} />
        </div>
      )}
      {rule.wasteCompostingEmissionFactor && (
        <div className="estimated-data-block-outline">
          <EstimatedValue label="Composting category" value={rule.wasteCompostingCategory} />
          <EstimatedValue
            label="Composting Emission Factor"
            value={rule.wasteCompostingEmissionFactor}
            unit="kgCO2e/ton"
            certainty={(100 - (rule.wasteCompostingEmissionIncertitudePercent || 0)) / 100}
          />
        </div>
      )}
      {rule.wasteAverageEmissionFactor && (
        <div className="estimated-data-block-outline">
          <EstimatedValue label="Average category" value={rule.wasteAverageCategory} />
          <EstimatedValue label="Average Emission Factor" value={rule.wasteAverageEmissionFactor} unit="kgCO2e/ton" certainty={(100 - (rule.wasteAverageEmissionIncertitudePercent || 0)) / 100} />
        </div>
      )}
    </div>
  );
};

interface EstimatedDataBlockProps {
  origin: 'image' | 'text';
  result?: AnalyzerResult;
  rawResult?: { [key: string]: AnalyzerRawResult };
}

const EstimatedDataBlock: React.FunctionComponent<EstimatedDataBlockProps> = (props: EstimatedDataBlockProps) => {
  const { result, rawResult } = props;

  if (!result || (!result.co2 && !result.price && !result.weight)) {
    return null;
  }

  // Estimated categories ordered by precision desc
  let rawResultItems = Object.keys(rawResult || {}).map(key => rawResult?.[key]);
  rawResultItems = orderBy(rawResultItems, item => (item?.score || 0) * 100, 'desc');

  // Estimated categories for the post with their precision percentage
  const tagList = rawResultItems
    .map(item => {
      const bow = item?.item_desc?.bow || ''; // Estimated category of the post
      const score = item?.score || 0; // Digit between 1 and 0 representing the precision of the category estimation
      const chipOpacity = 0.25 + score * 0.75; // We want the chip to always be visible, so we set a minimum opacity to 25% and we increase according to the score of the item

      return { label: `${bow} ${(score * 100).toFixed(0)}%`, opacity: chipOpacity } as EstimatedTag;
    })
    .filter(item => !!item.label);

  return (
    <>
      <div className="estimated-item">
        <EstimatedItemLabel label={`post-analyzer.estimation-via-${props.origin}`} />
      </div>
      <div className="estimated-data-block estimated-data-block-outline">
        <EstimatedTags title="post-analyzer.estimated-tags" tagList={tagList} />
        {rawResultItems.map(item => (
          <EstimatedCategory key={item?.item_desc?.bow} estimatedCategory={item} />
        ))}

        <EstimatedValue label="post-analyzer.estimated-co2-best" value={result?.co2?.co2_prediction_best_kg} unit="kg" />
        <EstimatedValue label="post-analyzer.estimated-co2-weighted" value={result?.co2?.co2_prediction_weighted_kg} unit="kg" />
        <EstimatedValue label="post-analyzer.estimated-co2-min" value={result?.co2?.co2_prediction_min_kg} unit="kg" />
        <EstimatedValue label="post-analyzer.estimated-co2-max" value={result?.co2?.co2_prediction_max_kg} unit="kg" />
        <EstimatedValue label="post-analyzer.estimated-co2-mean" value={result?.co2?.co2_prediction_mean_kg} unit="kg" />

        <EstimatedValue label="post-analyzer.estimated-weight-best" value={result?.weight?.weight_prediction_best_kg} unit="kg" />
        <EstimatedValue label="post-analyzer.estimated-weight-weighted" value={result?.weight?.weight_prediction_weighted_kg} unit="kg" />
        <EstimatedValue label="post-analyzer.estimated-weight-min" value={result?.weight?.weight_prediction_min_kg} unit="kg" />
        <EstimatedValue label="post-analyzer.estimated-weight-max" value={result?.weight?.weight_prediction_max_kg} unit="kg" />
        <EstimatedValue label="post-analyzer.estimated-weight-mean" value={result?.weight?.weight_prediction_mean_kg} unit="kg" />

        <EstimatedValue label="post-analyzer.estimated-price" value={result?.price?.weight_prediction_best_kg} unit="kg" />
      </div>
    </>
  );
};

interface AnalyzedDataProps {
  analyzedData: AnalyzerPrediction;
  postBeingAnalyzed: boolean;
}

const AnalyzedDataContent: React.FunctionComponent<AnalyzedDataProps> = ({ analyzedData, postBeingAnalyzed }: AnalyzedDataProps) => {
  if (typeof analyzedData?.prediction?.predictions === 'undefined') {
    return null;
  }

  const { vision_result, text_result, co2_prediction_kg, weight_prediction_kg, raw_vision_results, raw_text_results } = analyzedData.prediction.predictions;

  return (
    <div className="analyzed-posts-estimated-data">
      <IonLoading spinner="bubbles" isOpen={postBeingAnalyzed} />
      {!postBeingAnalyzed && (
        <div>
          <div className="estimated-item">
            <p className="estimated-item-label">
              <Trans i18nKey="post-analyzer.estimation-date" /> : <span className="estimated-item-date">{moment(analyzedData.prediction.createdAt).format('DD MMM YYYY, HH:mm')}</span>
            </p>
          </div>

          <EstimatedDataBlock result={vision_result} rawResult={raw_vision_results} origin={'image'} />
          <EstimatedDataBlock result={text_result} rawResult={raw_text_results} origin={'text'} />

          <div className="estimated-data-block">
            <EstimatedValue label="post-analyzer.estimated-co2-min" value={co2_prediction_kg?.min} unit="kg" ignoreEmptyValues={false} />
            <EstimatedValue label="post-analyzer.estimated-co2-max" value={co2_prediction_kg?.max} unit="kg" ignoreEmptyValues={false} />
            <EstimatedValue label="post-analyzer.estimated-co2-mean" value={co2_prediction_kg?.estimate} unit="kg" ignoreEmptyValues={false} />
            <EstimatedValue label="post-analyzer.estimated-weight-min" value={weight_prediction_kg?.min} unit="kg" />
            <EstimatedValue label="post-analyzer.estimated-weight-max" value={weight_prediction_kg?.max} unit="kg" />
            <EstimatedValue label="post-analyzer.estimated-weight-mean" value={weight_prediction_kg?.estimate} unit="kg" />
          </div>
        </div>
      )}
    </div>
  );
};

interface PostEmissionDataContentProps {
  analyzedData: PostEstimateData;
  postBeingAnalyzed: boolean;
}

const PostEmissionDataContent: React.FunctionComponent<PostEmissionDataContentProps> = ({ analyzedData, postBeingAnalyzed }: PostEmissionDataContentProps) => {
  return (
    <div className="analyzed-posts-estimated-data">
      <IonLoading spinner="bubbles" isOpen={postBeingAnalyzed} />
      {!postBeingAnalyzed && (
        <div>
          <div className="estimated-data-block">
            <EstimatedValue
              label="post-analyzer.estimated-co2"
              value={analyzedData?.estimatedEmission || 0}
              unit={analyzedData?.emissionUnit || ''}
              ignoreEmptyValues={false}
              certainty={analyzedData?.emissionCertainty}
            />
            <EstimatedValue label="post-analyzer.quantity" value={analyzedData?.itemsQuantity || undefined} />
            <EstimatedValue label="post-analyzer.estimated-price" value={analyzedData?.estimatedPrice || 0} unit="€" ignoreEmptyValues={false} />

            {analyzedData?.weightedCategory?.name && (
              <div className="estimated-data-block estimated-data-block-outline">
                <EstimatedValue label="post-analyzer.weighted-category" value={analyzedData?.weightedCategory?.name} />
                <EstimatedValue label="post-analyzer.estimated-weight-mean" value={`${analyzedData?.weightedCategory?.itemMinWeight} -> ${analyzedData?.weightedCategory?.itemMaxWeight}`} unit="kg" />
                <EstimatedValue label="post-analyzer.estimated-weight-default" value={analyzedData?.weightedCategory?.itemDefaultWeight} unit="kg" />
                <EstimatedValue label="post-analyzer.category-average-weight" value={analyzedData?.categoryAverageWeight} unit="kg" certainty={analyzedData?.categoryWeightCertainty} />
              </div>
            )}
            {analyzedData?.bestRule && (
              <div className="estimated-data-block estimated-data-block-outline">
                <div className="estimated-item">
                  <EstimatedItemLabel label={`post-analyzer.best-rule`} />
                </div>
                <RuleBlock rule={analyzedData.bestRule} />
              </div>
            )}
            {analyzedData?.categoryAndDiscriminantRules?.length > 0 && (
              <div className="estimated-data-block estimated-data-block-outline">
                <div className="estimated-item">
                  <EstimatedItemLabel label={`post-analyzer.category-material-rules`} />
                </div>
                {analyzedData?.categoryAndDiscriminantRules.map(rule => (
                  <RuleBlock key={rule.id} rule={rule} />
                ))}
              </div>
            )}
            {analyzedData?.categoryRules?.length > 0 && (
              <div className="estimated-data-block estimated-data-block-outline">
                <div className="estimated-item">
                  <EstimatedItemLabel label={`post-analyzer.category-rules`} />
                </div>
                {analyzedData?.categoryRules.map(rule => (
                  <RuleBlock key={rule.id} rule={rule} />
                ))}
              </div>
            )}
            {analyzedData?.pricedCategory?.name && (
              <div className="estimated-data-block estimated-data-block-outline">
                <EstimatedValue label="post-analyzer.weighted-category" value={analyzedData?.pricedCategory?.name} />
                <EstimatedValue label="post-analyzer.estimated-price-default" value={analyzedData?.pricedCategory?.itemDefaultPrice} unit="€" />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

interface RawDataButtonProps {
  analyzedData: AnalyzerPrediction | PostEstimateData;
}

const RawDataButton: React.FunctionComponent<RawDataButtonProps> = (props: RawDataButtonProps) => {
  const [showRawData, setShowRawData] = useState(false);

  return (
    <>
      <IonButton color="light" onClick={() => setShowRawData(true)}>
        <Trans i18nKey={'post-analyzer.show-raw-results'} />
      </IonButton>

      <IonModal className="analyzed-posts-raw-data-modal safe-area-ios" isOpen={showRawData} data-cy="analyzed-posts-raw-data-modal" onDidDismiss={() => setShowRawData(false)}>
        <CommonHeader title={<Trans i18nKey="post.estimates" />} stopPropagation onBackButtonClick={() => setShowRawData(false)} />
        <IonContent>
          <pre className="analyzed-posts-raw-data-json">
            <code>{JSON.stringify(props.analyzedData, undefined, '  ')}</code>
          </pre>
        </IonContent>
      </IonModal>
    </>
  );
};

type Props = {
  postId: EntityReference;
  runAnalyze?: boolean; // If false, displaying a button instead of running the analysis
};

interface StateProps {
  userIsAnalyzer?: boolean;
}

const mapStateToProps = (state: RootState): StateProps => ({
  userIsAnalyzer: getUserIsAnalyzer(state),
});

type PostAnalyzerDataProps = Props & StateProps;

// Estimate made in ML Consulting
const PostAnalyzerDataComponent: React.FunctionComponent<PostAnalyzerDataProps> = (props: PostAnalyzerDataProps) => {
  if (!props.userIsAnalyzer) {
    return null;
  }

  const [analyzedData, setAnalyzedData] = useState<AnalyzerPrediction | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [postBeingAnalyzed, setPostBeingAnalyzed] = useState<boolean>(false);

  const fetchAnalyzedData = async (force = false): Promise<void> => {
    setPostBeingAnalyzed(true);
    try {
      const analyzedPostData = await fetchPostAnalyzedData(props.postId, force);
      setAnalyzedData(analyzedPostData);
    } catch (e) {
      setError(e);
    }
    setPostBeingAnalyzed(false);
  };

  useEffect(() => {
    setAnalyzedData(null);
    setError(null);
    if (props.runAnalyze) {
      fetchAnalyzedData();
    }
  }, [props.postId]);

  return (
    <div className="container-analyzed-posts">
      <hr />
      <h3>ML Academy estimate</h3>

      <IonButton data-cy="analyzed-posts-data-button" onClick={() => fetchAnalyzedData(!!analyzedData)}>
        <Trans i18nKey={!analyzedData && !error ? 'post-analyzer.analyze-post' : 'post-analyzer.restart-post-analysis'} />
      </IonButton>
      {analyzedData && (
        <>
          <RawDataButton analyzedData={analyzedData} />
          <AnalyzedDataContent analyzedData={analyzedData} postBeingAnalyzed={postBeingAnalyzed} />
        </>
      )}
      {error && (
        <div className="estimated-item">
          <EstimatedItemLabel label="post-analyzer.post-analyser-error" />
          <pre className="analyzed-posts-raw-data-json">
            <code>{stringifyError(error, '   ')}</code>
          </pre>
        </div>
      )}

      {!error && analyzedData && !analyzedData?.prediction?.predictions && (
        <div className="estimated-data-block">
          <div className="estimated-item text-center">
            <p className="estimated-item-label">
              <Trans i18nKey="post-analyzer.no-analyzed-data" />
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

type PostEstimateDataProps = Props & StateProps;

// Estimate made in Indigo
const PostEmissionDataComponent: React.FunctionComponent<PostEstimateDataProps> = (props: PostEstimateDataProps) => {
  if (!props.userIsAnalyzer) {
    return null;
  }

  const [analyzedData, setAnalyzedData] = useState<PostEstimateData | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [postBeingAnalyzed, setPostBeingAnalyzed] = useState<boolean>(false);

  const fetchAnalyzedData = async (force = false): Promise<void> => {
    setPostBeingAnalyzed(true);
    try {
      const analyzedPostData = await fetchPostEstimateData(props.postId, force);
      setAnalyzedData(analyzedPostData);
    } catch (e) {
      setError(e);
    }
    setPostBeingAnalyzed(false);
  };

  useEffect(() => {
    setAnalyzedData(null);
    setError(null);
    if (props.runAnalyze) {
      fetchAnalyzedData();
    }
  }, [props.postId]);

  return (
    <div className="container-analyzed-posts">
      <hr />
      <h3>Indigo emission estimate</h3>
      <IonButton data-cy="analyzed-posts-data-button" onClick={() => fetchAnalyzedData(!!analyzedData)}>
        <Trans i18nKey={!analyzedData && !error ? 'post-analyzer.analyze-post' : 'post-analyzer.restart-post-analysis'} />
      </IonButton>
      {analyzedData && (
        <>
          <RawDataButton analyzedData={analyzedData} />
          <PostEmissionDataContent analyzedData={analyzedData} postBeingAnalyzed={postBeingAnalyzed} />
        </>
      )}
      {error && (
        <div className="estimated-item">
          <EstimatedItemLabel label="post-analyzer.post-analyser-error" />
          <pre className="analyzed-posts-raw-data-json">
            <code>{stringifyError(error, '   ')}</code>
          </pre>
        </div>
      )}

      {!error && !analyzedData && (
        <div className="estimated-data-block">
          <div className="estimated-item text-center">
            <p className="estimated-item-label">
              <Trans i18nKey="post-analyzer.no-analyzed-data" />
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export const PostAnalyzerData = connect(mapStateToProps, null)(PostAnalyzerDataComponent);
export const PostEmissionData = connect(mapStateToProps, null)(PostEmissionDataComponent);
