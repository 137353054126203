import React, { useState } from 'react';
import { IonItem, IonLabel, IonChip, IonIcon, IonLoading } from '@ionic/react';
import { checkmark } from 'ionicons/icons';
import { Trans } from 'react-i18next';
import { extractId, isSameEntityGenerator, isSameHydraEntity } from '../utils/helpers';
import { Thematic } from '../store/thematics/types';
import { EntityReference } from '../utils/hydra';
import { RootState, selectors } from '../store';
import { getItemTranslation } from '../utils/translation';
import { connect } from 'react-redux';

interface StateProps {
  thematics: Thematic[];
  areThematicsLoading: boolean;
}

const mapStateToProps = (state: RootState): StateProps => ({
  thematics: state.thematics.thematics,
  areThematicsLoading: state.thematics.isLoading,
});

interface ThematicsListProps {
  onThematicSelection: (selection: EntityReference[]) => void;
  favoriteCategoryThematicsIds: EntityReference[];
}

interface ThematicComponentProps {
  thematic: Thematic;
  isSelected: boolean;
  onClick?: () => void;
}

interface ChipStyle {
  cursor?: string;
  borderColor?: string;
  '--background': string;
  '--color': string;
}

const getUpdatedSelectedThematicsIds = (selectedThematicIds: EntityReference[], allThematics: Thematic[], toggledThematic: Thematic): EntityReference[] => {
  const thematicId = extractId(toggledThematic);

  // If not in the list, we add it
  if (!selectedThematicIds.find(isSameEntityGenerator(toggledThematic))) {
    return selectedThematicIds.concat([thematicId]);
  }

  // We remove the id of the list
  return selectedThematicIds.filter(id => {
    const currentThematic = allThematics.find(isSameEntityGenerator(id));

    // If in the list, we remove it
    if (!currentThematic || isSameHydraEntity(currentThematic, toggledThematic)) {
      return false;
    }

    // If no parent or another parent, we keep it
    return !currentThematic.parent || !isSameHydraEntity(currentThematic.parent, toggledThematic);
  });
};

const selectThematic = (
  favoriteCategoriesIds: EntityReference[],
  thematics: Thematic[],
  thematic: Thematic,
  onThematicSelection: (thematics: EntityReference[]) => void,
  setThematicSelection: (thematics: EntityReference[]) => void,
): void => {
  const newFavoriteThematics = getUpdatedSelectedThematicsIds(favoriteCategoriesIds, thematics, thematic);
  onThematicSelection(newFavoriteThematics);
  setThematicSelection(newFavoriteThematics);
};

export const ThematicComponent: React.FunctionComponent<ThematicComponentProps> = ({ thematic, isSelected, onClick }: ThematicComponentProps) => {
  const hexaColor: string = '#' + thematic.color;

  let style: ChipStyle = {
    '--background': hexaColor,
    '--color': 'white',
  };

  if (!onClick) {
    style['cursor'] = 'default';
  }

  if (isSelected) {
    style = {
      borderColor: hexaColor,
      '--background': 'white',
      '--color': hexaColor,
    };
  }

  return (
    <IonChip key={extractId(thematic)} outline={isSelected} className={'chip ion-chip'} onClick={onClick} style={style}>
      {isSelected && <IonIcon icon={checkmark} style={{ color: hexaColor }} />}
      <IonLabel>{getItemTranslation(thematic, thematic.name)}</IonLabel>
    </IonChip>
  );
};

const ThematicsList: React.FunctionComponent<ThematicsListProps & StateProps> = ({
  thematics,
  areThematicsLoading,
  favoriteCategoryThematicsIds,
  onThematicSelection,
}: ThematicsListProps & StateProps) => {
  const [favoriteCategoriesIds, setFavoriteCategories] = useState(favoriteCategoryThematicsIds);

  if (areThematicsLoading && thematics.length !== 0) {
    return <IonLoading spinner="bubbles" isOpen={true} showBackdrop={false} />;
  }

  if (thematics.length === 0) {
    return (
      <IonItem>
        <IonLabel className="ion-text-center">
          <Trans i18nKey="thematics.no-thematics-found" />
        </IonLabel>
      </IonItem>
    );
  }

  return (
    <div className="thematic-collection">
      {selectors.thematics.getParentThematics(thematics).map((parent: Thematic) => (
        <React.Fragment key={extractId(parent)}>
          <ThematicComponent
            thematic={parent}
            isSelected={favoriteCategoriesIds.find(isSameEntityGenerator(parent)) !== undefined}
            onClick={() => selectThematic(favoriteCategoriesIds, thematics, parent, onThematicSelection, setFavoriteCategories)}
          />

          {favoriteCategoriesIds.find(isSameEntityGenerator(parent)) &&
            selectors.thematics
              .getChildrenOfThematic(thematics, parent)
              .map((children: Thematic) => (
                <ThematicComponent
                  key={extractId(children)}
                  thematic={children}
                  isSelected={favoriteCategoriesIds.find(isSameEntityGenerator(children)) !== undefined}
                  onClick={() => selectThematic(favoriteCategoriesIds, thematics, children, onThematicSelection, setFavoriteCategories)}
                />
              ))}
        </React.Fragment>
      ))}
    </div>
  );
};

export default connect(mapStateToProps)(ThematicsList);
