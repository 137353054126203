import { IonContent, IonCardContent, IonText, IonButton, IonPage } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CommonHeader from '../components/CommonHeader';
import * as Yup from 'yup';
import DynamicForm, { FormFields, FormValue, FormValues, TextareaFieldComponent } from '../components/DynamicForm';
import { FormikHelpers, FormikProps } from 'formik';
import { actions } from '../store';
import { bindActionCreators, Dispatch } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router';

import './DeleteAccountPage.scss';

const getSubmitButton: React.FunctionComponent<FormikProps<FormValues>> = (formikBag: FormValues, text: string) => (
  <IonButton
    color="danger"
    type="submit"
    shape="round"
    className="ion-margin delete-account-submit-button"
    disabled={!formikBag.isValid || formikBag.isValidating || formikBag.isSubmitting}
    data-cy="delete-account-submit-button"
  >
    {text}
  </IonButton>
);

interface DeleteAccountFormValues extends FormValues {
  reason: string;
}

interface DispatchProps {
  deleteCurrentUser: (reason: string) => void;
}

const propsToDispatch = {
  deleteCurrentUser: actions.users.deleteCurrentUser,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);
type DeleteAccountsProps = RouteComponentProps<Record<string, never>> & WithTranslation & DispatchProps;

class DeleteAccountPage extends Component<DeleteAccountsProps> {
  allFields: FormFields = [
    {
      label: 'user.delete-my-account-placeholder',
      labelOptions: { position: 'stacked', color: 'var(--ion-color-light-contrast)' },
      name: 'reason',
      formComponent: TextareaFieldComponent,
      options: { autocapitalize: 'none', required: true, clearOnEdit: false, clearInput: true },
    },
  ];

  validationSchema = Yup.object().shape({
    reason: Yup.string().required('user.reason-required'),
  });

  initialFormValues: DeleteAccountFormValues = {
    reason: '',
  };

  public constructor(props: DeleteAccountsProps) {
    super(props);
  }

  public render(): ReactNode {
    const { t } = this.props;

    return (
      <IonPage data-cy="delete-account-page">
        <CommonHeader addIonHeader={true} title={<Trans i18nKey="profile-menu.delete-account" />} />
        <IonContent className="ion-padding delete-account-page">
          <IonCardContent>
            <IonText color="medium" className="delete-account-page-title">
              <Trans i18nKey="user.delete-my-account-title" />
            </IonText>

            <DynamicForm
              onAfterSubmitFormSuccess={this.onAfterSubmitFormSuccess}
              onSubmitForm={this.submitForm}
              formFields={this.transformFormValues()}
              validationSchema={this.validationSchema}
              initialValues={this.initialFormValues}
              onFieldChange={this.transformText}
              dynamicFormSubmitButton={formikBag => getSubmitButton(formikBag, t('user.delete-my-account-submit-button'))}
            />
            <div className="delete-account-page-description-wrapper">
              <IonText color="medium" className="delete-account-page-description">
                <Trans i18nKey="user.delete-page-description" />
              </IonText>
            </div>
          </IonCardContent>
        </IonContent>
      </IonPage>
    );
  }

  private transformFormValues = (): FormFields => {
    return this.allFields.map(field => {
      field.options = { ...field.options };
      return field;
    });
  };

  private transformText = (field: string, value: FormValue, form: FormikProps<FormValues>): void => {
    if (value.length === 1) {
      form.setFieldValue(field, (value as string).toUpperCase());
    }
  };

  private onAfterSubmitFormSuccess = async (values: Readonly<FormValues>, actions: FormikHelpers<FormValues>): Promise<void> => {
    actions.resetForm({ values: { ...values, reason: '' } });
  };

  private submitForm = async (values: Readonly<FormValues>): Promise<void> => {
    if (!values.reason) {
      return;
    }

    try {
      await this.props.deleteCurrentUser(values.reason);
    } catch {
      // catch deleting user error
    }
  };
}

export default connect(null, mapDispatchToProps)(withTranslation()(withRouter(DeleteAccountPage)));
