import { createAction, createAsyncAction } from 'typesafe-actions';
import { fetchWithAuth } from '../../utils/authDataAccess';
import { extractId, parseHydraCollection } from '../../utils/helpers';
import { EntityReference, HydraCollectionResponse, ReferencedCollectionResponse } from '../../utils/hydra';
import { normalizeAndDispatchCollectionResponse, normalizeAndDispatchEntity } from '../entities/selectors';
import { ThunkResult } from '../types';
import { UserNotification, GlobalNotificationData, NotificationReason } from './types';
import { addParametersToUrl } from '../../utils/dataAccess';
import { PushNotificationSchema } from '../../capacitor/PushNotifications';

const notificationReasonsFilter: NotificationReason[] = [
  'global_message',
  'new_follower',
  'followed_new_post',
  'recommended_users',
  'user_review',
  'post_too_old',
  'post_deleted',
  'moderated_post',
  'moderated_report_to_reporter',
];

export const fetchNotificationsAction = createAsyncAction('notifications/FETCH_REQUEST', 'notifications/FETCH_SUCCESS', 'notifications/FETCH_FAILURE')<void, ReferencedCollectionResponse, Error>();

export const notificationReceivedAction = createAction('notifications/RECEIVED', (notification: PushNotificationSchema) => notification)();

export const setGlobalNotificationAction = createAction('notifications/SET_GLOBAL_NOTIFICATION', (notification: GlobalNotificationData) => notification)();
export const sendGlobalNotificationAction = createAsyncAction(
  'notifications/SEND_GLOBAL_NOTIFICATION_REQUEST',
  'notifications/SEND_GLOBAL_NOTIFICATION_SUCCESS',
  'notifications/SEND_GLOBAL_NOTIFICATION_FAILURE',
)<void, void, Error>();

export const updateNotificationStatusAction = createAction('notifications/UPDATE_NOTIFICATION_STATUS')<void>();
export const updateAllNotificationsStatusAction = createAction('notifications/UPDATE_ALL_NOTIFICATION_STATUS')<void>();

export function fetchUserNotification(loadNextPage = false, page = '/users/me/notifications'): ThunkResult<Promise<void>> {
  return async (dispatch, getState) => {
    dispatch(fetchNotificationsAction.request());

    if (loadNextPage) {
      page = getState().notifications.currentUserNotifications?.nextPage || page;
    }

    return fetchWithAuth(page)
      .then(response => response.json() as Promise<HydraCollectionResponse<UserNotification>>)
      .then(data => parseHydraCollection(data))
      .then(data => normalizeAndDispatchCollectionResponse(data, dispatch))
      .then(collection => {
        dispatch(fetchNotificationsAction.success(collection));
      })
      .catch(e => {
        dispatch(fetchNotificationsAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function fetchNotifications(loadNextPage = false, page = '/users/me/notifications'): ThunkResult<Promise<void>> {
  page = addParametersToUrl(page, { 'notification.reason[]': notificationReasonsFilter });

  return fetchUserNotification(loadNextPage, page);
}

export function fetchUserNotificationByNotificationId(notificationId: EntityReference): ThunkResult<Promise<void>> {
  let page = '/users/me/notifications';
  page = addParametersToUrl(page, { 'notification.id': notificationId });

  return fetchUserNotification(false, page);
}

export function updateNotificationStatus(userNotification: UserNotification, read = true): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    return fetchWithAuth(extractId(userNotification), {
      method: 'PUT',
      data: { read },
    })
      .then(response => response.json() as Promise<UserNotification>)
      .then(data => {
        dispatch(updateNotificationStatusAction());
        normalizeAndDispatchEntity(data, dispatch);
      })
      .then(() => {
        // Return void
      });
  };
}

export function updateAllNotificationStatus(read = true): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    const page = addParametersToUrl('/users/me/notifications/read_all', { 'notification.reason[]': notificationReasonsFilter });
    return fetchWithAuth(page, {
      method: 'PUT',
      data: { read },
    })
      .then(() => {
        dispatch(updateAllNotificationsStatusAction());
      })
      .then(() => {
        // Return void
      });
  };
}

export function sendGlobalNotification(): ThunkResult<Promise<void>> {
  return async (dispatch, getState) => {
    dispatch(sendGlobalNotificationAction.request());

    const message = getState().notifications.globalNotification;

    return fetchWithAuth('/notifications/global', {
      method: 'POST',
      data: {
        imageUrl: message.imageUrl,
        message: message.message,
        sendByPush: message.sendByPush,
        sendByMail: message.sendByMail,
        targetedLocales: message.targetedLocales,
      },
    })
      .then(() => {
        dispatch(sendGlobalNotificationAction.success());
      })
      .catch(e => {
        dispatch(sendGlobalNotificationAction.failure(e));
        return Promise.reject(e);
      });
  };
}
