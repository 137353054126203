import React from 'react';
import { IonContent, IonRow, IonCol, IonSpinner } from '@ionic/react';
import HomePagePlaceholder from './HomePagePlaceholder';
import { ObjectPlaceholder, ServicePlaceholder } from './PostsPlaceholder';

import './HomePagePlaceholder.scss';
import './CommonPlaceholderStyles.scss';

interface ContentProps {
  isSpinnerShouldBeShown?: boolean;
}

interface Props {
  isSearchResult: boolean;
  isSpinnerShouldBeShown?: boolean;
}

export const SearchPageContentPlaceholder: React.FunctionComponent<ContentProps> = ({ isSpinnerShouldBeShown }: ContentProps) => (
  <>
    {/*To prevent spinner if we cancel filter modal*/}
    {isSpinnerShouldBeShown && <IonSpinner className="common-placeholder-spinner" data-cy="search-page-loader" />}

    <IonRow className="homepage-placeholder-row">
      <IonCol size="6" className="homepage-placeholder-col">
        <ObjectPlaceholder />
      </IonCol>

      <IonCol size="6" className="homepage-placeholder-col">
        <ServicePlaceholder />
      </IonCol>
    </IonRow>
  </>
);

const SearchPagePlaceholder: React.FunctionComponent<Props> = ({ isSearchResult, isSpinnerShouldBeShown }: Props) => {
  if (isSearchResult) {
    return (
      <IonContent>
        <HomePagePlaceholder />
      </IonContent>
    );
  }

  return (
    <IonContent className="homepage-placeholder common-placeholder" data-cy="search-page-placeholder">
      <SearchPageContentPlaceholder isSpinnerShouldBeShown={isSpinnerShouldBeShown} />
    </IonContent>
  );
};

export default SearchPagePlaceholder;
