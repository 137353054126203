import React, { Component, ReactNode } from 'react';
import { IonItem, IonLabel, IonSegment, IonSegmentButton, IonSelect, IonSelectOption } from '@ionic/react';
import { FormikProps } from 'formik';
import i18next from 'i18next';
import { FormFieldOptionType, FormValues, SelectOptions } from '../DynamicForm';
import './SelectField.scss';

export interface SelectFieldProps {
  value?: string | string[] | null;
  form: FormikProps<FormValues>;
  name: string;
  label: string;
  options: FormFieldOptionType;
  required: boolean;
  selectOptions: SelectOptions;
  multiple?: boolean;
  useIonSegment?: boolean;
  displayValidationIcon?: boolean;
  textWrap?: boolean;
}

class SelectField extends Component<SelectFieldProps> {
  public render(): ReactNode {
    const { form, name, label, required, selectOptions, value, displayValidationIcon, textWrap, useIonSegment, multiple } = this.props;
    const fieldHasError = (form.status && form.status[name]) || (form.errors[name] && form.touched[name]);
    const fieldHasValue = !!value;

    if (useIonSegment && !multiple) {
      return (
        <IonItem className={`without-border ${fieldHasError ? 'ion-invalid' : 'no-error-field'} ${fieldHasValue ? 'item-has-value' : ''}`} mode="md">
          {displayValidationIcon && <img alt="validation" className="icon-svg-validation icon-svg-validation-segment" src="/assets/form/icon-validation.svg" />}
          <IonSegment id="categoryType" {...this.props} value={value as string} mode="ios">
            {selectOptions.map((object, i) => (
              <IonSegmentButton key={i + object.value} value={object.value}>
                {object.label}
              </IonSegmentButton>
            ))}
          </IonSegment>
        </IonItem>
      );
    }

    return (
      <IonItem className={`${fieldHasError ? 'ion-invalid' : 'no-error-field'} ${fieldHasValue ? 'item-has-value' : ''}`} mode="md">
        {displayValidationIcon && <img alt="validation" className="icon-svg-validation" src="/assets/form/icon-validation.svg" />}
        {label && (
          <IonLabel position={value ? 'floating' : undefined} class={textWrap ? 'ion-text-wrap' : ''} color={fieldHasError ? 'danger' : 'dark'}>
            {i18next.t(label)} {required && ' *'}
          </IonLabel>
        )}

        <IonSelect {...this.props} mode="ios" label={undefined}>
          {selectOptions.map((object, i) => (
            <IonSelectOption key={i + object.value} value={object.value}>
              {object.label}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    );
  }
}

export default SelectField;
