import { IonButton, IonContent, IonPage, IonText } from '@ionic/react';
import React, { ErrorInfo, ReactNode } from 'react';
import './ErrorBoundary.scss';
import { Trans } from 'react-i18next';
import { recordFirebaseCrashlyticsException } from './capacitor/FirebaseCrashlytics';
import { appVersion } from './environment';
import { reloadWindowLocation } from './utils/windowLocationHelper';
import { logError } from './utils/logs/userLogs';
import { Capacitor } from '@capacitor/core';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error?: Error;
  info?: ErrorInfo;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error };
  }

  public componentDidCatch(error: Error, info: ErrorInfo): void {
    console.error('ErrorBoundary caught an error', error, info);
    logError({ error, errorInfo: info });

    if (Capacitor.getPlatform() !== 'web') {
      // FirebaseCrashlytics is not available on web for now
      recordFirebaseCrashlyticsException({ message: `Error name: ${error.name} \n\n Error message: ${error.message} \n\n Error stack: ${error.stack} \n\n Error infos: ${info.componentStack}` });
    }
  }

  public render(): ReactNode {
    if (this.state.hasError) {
      return (
        <IonPage>
          <IonContent className="error-page">
            <div className="flex-center">
              <div className="ion-text-center w-100">
                <h1>
                  <Trans i18nKey="error.title" />
                </h1>
                <IonText className="text-medium">
                  <Trans i18nKey="error.error-occurred" />
                </IonText>
                <br />
                <IonText className="text-medium">{this.state.error && this.state.error.message}</IonText>
                <div className="mt-20">
                  <IonButton className="mr-10" onClick={() => reloadWindowLocation()} color="primary">
                    <Trans i18nKey="error.button-reload" />
                  </IonButton>

                  <IonButton onClick={() => ErrorBoundary.resetApp()} color="danger">
                    <Trans i18nKey="error.button-reset" />
                  </IonButton>
                </div>
                <div className="mt-20">
                  <IonText className="app-version ion-text-center" color="medium">
                    Version: {appVersion}
                  </IonText>
                </div>
              </div>
            </div>
          </IonContent>
        </IonPage>
      );
    }

    return this.props.children;
  }

  private static resetApp(): void {
    window.indexedDB.deleteDatabase('localforage');
    window.localStorage.clear();
    reloadWindowLocation();
  }
}

export default ErrorBoundary;
