import { useGoogleMap } from '@react-google-maps/api';
import React, { CSSProperties, useRef } from 'react';
import { createPortal } from 'react-dom';

interface Props {
  position: number;
  children: React.ReactChild;
}

export const mapControlButtonStyle: CSSProperties = {
  background: 'white',
  border: '0',
  position: 'relative',
  cursor: 'pointer',
  userSelect: 'none',
  overflow: 'hidden',
  padding: 0,
  width: 40,
  height: 40,
  right: 10,
  boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
  borderRadius: 2,
};

const MapControl: React.FunctionComponent<Props> = ({ position, children }: Props) => {
  const map = useGoogleMap();
  const containerRef = useRef(document.createElement('div'));

  React.useEffect(() => {
    if (map) {
      map.controls[position].push(containerRef.current);
    }
  }, [map, position, containerRef]);

  return createPortal(children, containerRef.current);
};

export default React.memo(MapControl);
