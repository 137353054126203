import React, { ReactNode, useEffect, useState } from 'react';
import { IonButton, IonContent, IonIcon, IonModal, IonText } from '@ionic/react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { actions, RootState } from '../store';
import { FullPost, Post } from '../store/posts/types';
import { EntityReference } from '../utils/hydra';
import { getCategoryDiscriminants, getCategoryWithMinAndMaxWeight } from '../utils/categoriesHelpers';
import PostCo2EstimationSteps, { Co2EstimationStep } from './PostCo2EstimationSteps';
import CommonHeader from './CommonHeader';
import { Category, Discriminant } from '../store/categories/types';
import { extractId } from '../utils/helpers';

import './PostCreationSuccessModal.scss';

interface CloseModalButtonsProps {
  onCloseModal: (createNewPost?: boolean) => void;
  color: string;
}

interface ThanksForContributionProps {
  onCloseModal: (createNewPost?: boolean) => void;
  setShowEstimationSteps: (value: boolean) => void;
  displayEstimateWeightButton: boolean;
}

interface EstimateCo2SuccessProps {
  onCloseModal: (createNewPost?: boolean) => void;
}

const CloseModalButtons: React.FunctionComponent<CloseModalButtonsProps> = ({ onCloseModal, color }: CloseModalButtonsProps) => {
  return (
    <div className="button-section">
      <IonButton data-cy="button-post-new-ad" color={color} onClick={() => onCloseModal(true)} shape="round" expand="block" size="large">
        <Trans i18nKey="post-creation-success-modal.post-new-ad" />
      </IonButton>

      <IonButton data-cy="open-user-post-button" fill="outline" color={color} onClick={() => onCloseModal()} shape="round" expand="block" size="large">
        <Trans i18nKey="post-creation-success-modal.view-my-ad" />
      </IonButton>
    </div>
  );
};

const PostCreationSuccessBlock: React.FunctionComponent<ThanksForContributionProps> = ({ setShowEstimationSteps, displayEstimateWeightButton, onCloseModal }: ThanksForContributionProps) => {
  return (
    <div className="post-creation-success-block">
      <div className="ion-text-center media">
        <img src="/assets/success-created-post.svg" alt="Welcome" />
      </div>

      <IonText className="title display-block">
        <Trans i18nKey="post-creation-success-modal.thanks-for-contribution" />
      </IonText>

      <IonText className="text display-block">
        <Trans i18nKey="post-creation-success-modal.ad-will-be-published" />
      </IonText>

      {displayEstimateWeightButton && (
        <>
          <IonText className="text text-black display-block">
            <Trans i18nKey="post-creation-success-modal.help-us-estimate-ecological-impact" />
          </IonText>

          <IonText className="text text-black-small display-block">
            <Trans i18nKey="post-creation-success-modal.indicating-weight-discriminants" />
          </IonText>
        </>
      )}

      {displayEstimateWeightButton && (
        <div className="bottom-section">
          <div className="background hemisphere-down">
            <img src="/assets/background/hemisphere-down-white.svg" alt="Indigo" />
          </div>

          <div className="estimate-co2-buttons">
            <IonButton data-cy="button-estimate-co2" color="success" onClick={() => setShowEstimationSteps(true)} shape="round" expand="block" size="large">
              <Trans i18nKey="post-creation-success-modal.estimate-co2" />
            </IonButton>
            <IonButton className="button-estimate-later" data-cy="button-estimate-later" color="white" onClick={() => onCloseModal()} shape="round" expand="block" size="large">
              <Trans i18nKey="post-creation-success-modal.estimate-later" />
            </IonButton>
          </div>
        </div>
      )}

      {!displayEstimateWeightButton && <CloseModalButtons onCloseModal={onCloseModal} color="primary" />}
    </div>
  );
};

const Co2EstimationSuccessBlock: React.FunctionComponent<EstimateCo2SuccessProps> = ({ onCloseModal }: EstimateCo2SuccessProps) => {
  return (
    <div className="estimate-co2-success" data-cy="estimate-co2-success">
      <IonText className="title display-block">
        <Trans i18nKey="post-creation-success-modal.thank-you" />
      </IonText>

      <IonText className="text display-block">
        <Trans i18nKey="post-creation-success-modal.allow-to-avoid-co2-production" />
      </IonText>

      <IonText className="text-grey display-block">
        <Trans i18nKey="post-creation-success-modal.ad-will-be-published" />
      </IonText>

      <div className="media">
        <img src="/assets/recycling.svg" alt="recycling" />
      </div>

      <IonText className="text-grey small-text display-block">
        <Trans i18nKey="post-creation-success-modal.data-being-studied" />
      </IonText>

      <CloseModalButtons color="success" onCloseModal={onCloseModal} />
    </div>
  );
};

interface PostCreationSuccessModalProps {
  isOpen: boolean;
  post?: Post;
}

interface DispatchProps {
  setAddPostModalIsOpenAction(addPostModalIsOpen: boolean): void;
  setLastCreatedPostIdAction(lastCreatedPostId: EntityReference | null): void;
  editUserPost(post: Post): Promise<EntityReference>;
}

const propsToDispatch = {
  setAddPostModalIsOpenAction: actions.app.setAddPostModalIsOpenAction,
  setLastCreatedPostIdAction: actions.app.setLastCreatedPostIdAction,
  editUserPost: actions.posts.editUserPost,
};

interface StateProps {
  categories: Category[];
}

const mapStateToProps = (state: RootState): StateProps => ({
  categories: state.categories.categories,
});

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type Props = PostCreationSuccessModalProps & DispatchProps & StateProps;
type ContentProps = Props & { onCloseModal: (createNewPost?: boolean) => void };

const PostCreationSuccessModalContent: React.FunctionComponent<ContentProps> = (props: ContentProps) => {
  const [showEstimationSteps, setShowEstimationSteps] = useState<boolean>(false);
  const [estimationIsDone, setEstimationIsDone] = useState<boolean>(false);
  const { onCloseModal } = props;
  const post = props.post as FullPost;
  const category = props.categories.find(cat => cat['@id'] === post.category);
  const categoryWithMinAndMaxWeight = category ? getCategoryWithMinAndMaxWeight(props.categories, category) : undefined;
  const categoryHasMinAndMaxWeight = categoryWithMinAndMaxWeight?.itemMinWeight != null && categoryWithMinAndMaxWeight?.itemMaxWeight != null;
  const categoryDiscriminants = category ? getCategoryDiscriminants(props.categories, category) : [];
  const canEstimatePost = props.post?.type === 'offer' && props.post.categoryType === 'object' && (categoryHasMinAndMaxWeight || !!categoryDiscriminants.length);

  const updatePost = (quantity?: number | null, estimatedWeight?: number | null, discriminants?: Discriminant[] | null): void => {
    if (!props.post) {
      throw new Error('The post in undefined');
    }

    props
      .editUserPost({
        ...props.post,
        estimatedWeight: estimatedWeight ? parseFloat(estimatedWeight.toString()) : null,
        quantity: quantity ? parseInt(quantity.toString()) : undefined,
        discriminants: (discriminants || []).map(item => extractId(item)),
      })
      .then(() => {
        setShowEstimationSteps(false);
        setEstimationIsDone(true);
      })
      .catch(() => {
        // Catch error
      });
  };

  const closeModal = (): ReactNode => (
    <IonButton fill="clear" className="close-modal-button" onClick={() => onCloseModal()}>
      <IonIcon icon="/assets/icon/icon-close-modal.svg" />
    </IonButton>
  );

  useEffect(() => {
    if (!props.isOpen) {
      return;
    }

    setShowEstimationSteps(false);
    setEstimationIsDone(false);
  }, [props.isOpen]);

  const estimationSteps: Co2EstimationStep[] = [];
  if (categoryWithMinAndMaxWeight) {
    estimationSteps.push('weight');
  }
  if (categoryDiscriminants.length) {
    estimationSteps.push('discriminants');
  }

  return (
    <>
      {!showEstimationSteps && (
        <>
          <CommonHeader title={''} backButton={<></>} nextButton={closeModal()} />
          <IonContent>
            {(!estimationIsDone || !canEstimatePost) && (
              <PostCreationSuccessBlock setShowEstimationSteps={setShowEstimationSteps} onCloseModal={onCloseModal} displayEstimateWeightButton={canEstimatePost && !estimationIsDone} />
            )}

            {canEstimatePost && estimationIsDone && <Co2EstimationSuccessBlock onCloseModal={onCloseModal} />}
          </IonContent>
        </>
      )}
      {showEstimationSteps && props.isOpen && (
        <PostCo2EstimationSteps category={categoryWithMinAndMaxWeight} discriminants={categoryDiscriminants} steps={estimationSteps} updatePost={updatePost} onStepsEnd={() => onCloseModal()} />
      )}
    </>
  );
};

export const PostCreationSuccessModal: React.FunctionComponent<Props> = (props: Props) => {
  const onCloseModal = (createNewPost = false): void => {
    props.setLastCreatedPostIdAction(null);
    if (createNewPost) {
      setTimeout(() => {
        props.setAddPostModalIsOpenAction(true);
      });
    }
  };

  return (
    <IonModal isOpen={props.isOpen} onDidDismiss={() => onCloseModal()} className="post-co2-estimation-modal safe-area-ios" data-cy="post-creation-success-modal">
      <PostCreationSuccessModalContent {...props} onCloseModal={onCloseModal} />
    </IonModal>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PostCreationSuccessModal);
