import { IonLoading } from '@ionic/react';
import isEqual from 'lodash/isEqual';
import React, { PureComponent, ReactNode } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { isProduction } from '../../environment';
import { actions, RootState } from '../../store';
import { getCategories } from '../../store/categories/selectors';
import { FetchMapClusters, FetchMapPosts, MapSearchParams } from '../../store/posts/actions';
import { isAPost } from '../../store/posts/selectors';
import { FullPost, PostCluster } from '../../store/posts/types';
import { gpsMapsDistance, mapCollectionName, roundSearch } from '../../utils/mapPostsLoader';

interface LoaderProps {
  map?: google.maps.Map;
  createPostMarker: (post: FullPost) => google.maps.Marker;
  createClusterMarker: (cluster: PostCluster) => google.maps.Marker;
}

interface State {
  searchOptions?: MapSearchParams;
}

interface StateProps {
  categoriesCount: number;
  mapIsLoading: boolean;
}

const mapStateToProps = (state: RootState): StateProps => ({
  categoriesCount: getCategories(state).length,
  mapIsLoading: state.posts.mapIsLoading,
});

interface DispatchProps {
  fetchPosts: FetchMapPosts;
  fetchPostsClusters: FetchMapClusters;
  fetchCategories(): void;
}

const propsToDispatch = {
  fetchPosts: actions.posts.fetchMapPosts,
  fetchPostsClusters: actions.posts.fetchMapClusters,
  fetchCategories: actions.categoriesActions.fetchPostCategories,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type Props = LoaderProps & StateProps & DispatchProps;

class PostsMapLoader extends PureComponent<Props, State> {
  private _isMounted = false;
  private postsCollections: { [coll: string]: { totalPostsCount: number; nextPage?: string } } = {};
  private markersCollections: { [coll: string]: { [id: string]: google.maps.Marker } } = {};
  private refreshTimer?: NodeJS.Timeout;
  private searchCircle?: google.maps.Circle;

  public constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public componentDidMount(): void {
    if (!this.props.categoriesCount) {
      // TODO Refresh categories sometimes ?
      this.props.fetchCategories();
    }
    this._isMounted = true;
    this.addMapListeners();
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (prevProps.map !== this.props.map) {
      this.addMapListeners();
    }

    if (!isEqual(prevState.searchOptions, this.state.searchOptions)) {
      this.loadNextPage(this.state.searchOptions).catch(() => {
        // Do nothing
      });
      this.displaySearchBox();
    }
  }

  private displaySearchBox(): void {
    if (isProduction) {
      return;
    }

    if (!this.props.map || !this.state.searchOptions?.lat || !this.state.searchOptions?.lng || !this.state.searchOptions?.radius) {
      return;
    }

    if (this.searchCircle) {
      this.searchCircle.setMap(null);
      this.searchCircle = undefined;
    }

    this.searchCircle = new google.maps.Circle({
      strokeColor: '#FF0000',
      strokeOpacity: 0.4,
      strokeWeight: 1,
      fillColor: '#FF0000',
      fillOpacity: 0.1,
      map: this.props.map,
      center: new google.maps.LatLng(this.state.searchOptions?.lat, this.state.searchOptions?.lng),
      radius: this.state.searchOptions?.radius,
    });
  }

  public addMapListeners(): void {
    this.props.map?.addListener('idle', this.calculateSearch);
    this.props.map?.addListener('zoom_changed', () => {
      const currentMarkersCollName = this.getMarkersCollectionsName(this.props.map?.getZoom() || 0);

      // Hide markers for other zoom layers
      for (const key of Object.keys(this.markersCollections)) {
        Object.keys(this.markersCollections[key]).forEach(id => {
          const newMap = key === currentMarkersCollName ? this.props.map || null : null;
          if (this.markersCollections[key][id].getMap() !== newMap) {
            this.markersCollections[key][id].setMap(newMap);
          }
        });
      }
    });
  }

  public componentWillUnmount(): void {
    this._isMounted = false;
  }

  public render(): ReactNode {
    return <IonLoading isOpen={this.props.mapIsLoading} showBackdrop={false} cssClass="map-spinner" />;
  }

  private clusterMode = (zoom: number): boolean => {
    // TODO Display posts when there are less than 200 posts to display on screen
    return zoom < 16;
  };

  private getMarkersCollectionsName = (zoom: number): string => {
    return this.clusterMode(zoom) ? `zoom_${zoom}` : 'default';
  };

  private loadNextPage = async (search: MapSearchParams | undefined): Promise<void> => {
    if (!this._isMounted || !search || !isEqual(search, this.state.searchOptions)) {
      return;
    }

    const clusterMode = this.clusterMode(search.zoom);
    const postsCollName = mapCollectionName(search);
    const markersCollName = this.getMarkersCollectionsName(search.zoom);
    const response = await this.props[clusterMode ? 'fetchPostsClusters' : 'fetchPosts'](search, this.postsCollections[postsCollName]?.nextPage);

    this.postsCollections[postsCollName] = {
      totalPostsCount: response.totalItems,
      nextPage: response.nextPage,
    };

    if (!this.markersCollections[markersCollName]) {
      this.markersCollections[markersCollName] = {};
    }

    response.items.forEach((item: FullPost | PostCluster) => {
      if (typeof this.markersCollections[markersCollName][item['@id']] !== 'undefined') {
        return;
      }

      const marker = isAPost(item) ? this.props.createPostMarker(item) : this.props.createClusterMarker(item);
      // The zoom can have changed since the request start
      marker.setMap(markersCollName === this.getMarkersCollectionsName(this.props.map?.getZoom() || 0) ? this.props.map || null : null);
      this.markersCollections[markersCollName][item['@id']] = marker;
    });

    if (response.nextPage) {
      setTimeout(() => this.loadNextPage(search), 100);
    }
  };

  private calculateSearch = (): void => {
    const bounds = this.props.map?.getBounds();
    const zoom = this.props.map?.getZoom() || 0;
    if (!bounds) {
      return;
    }

    if (this.refreshTimer !== undefined) {
      clearTimeout(this.refreshTimer);
    }

    const mapCenter = bounds.getCenter();
    const searchOptions: MapSearchParams = roundSearch({
      lat: mapCenter.lat(),
      lng: mapCenter.lng(),
      radius: gpsMapsDistance(mapCenter, bounds.getNorthEast()),
      zoom,
    });

    if (searchOptions.radius && !isEqual(searchOptions, this.state.searchOptions)) {
      this.refreshTimer = setTimeout(() => {
        this.setState({ searchOptions });
      }, 100);
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PostsMapLoader);
