// Polyfills
import 'react-app-polyfill/stable';
// import 'eventsource/lib/eventsource-polyfill';
// Manually adding the Promise.allSettled polyfill, as the current react-scripts babel config does not contain it
// See https://github.com/zloirock/core-js/issues/793 and https://github.com/facebook/create-react-app/issues/8779
import 'core-js/modules/esnext.promise.all-settled';

// Imports
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { setFirebaseCrashlyticsEnabled } from './capacitor/FirebaseCrashlytics';
import { gmapsApiKey, isSelfSignedSsl, isTest, navigatorLanguage } from './environment';
import './i18n';
import { persistor, store } from './store';
import { initAnalytics } from './utils/analytics/analyticsHelper';
import { loadGoogleMapsScript } from './utils/geolocationHelpers';
import { register } from './utils/serviceWorker';
import localforage from 'localforage';

initAnalytics();

// The gmaps script can be replaced on layoutActions.setAppLanguageAction event
loadGoogleMapsScript(window.localStorage.getItem('lastGMapsLanguage') || navigatorLanguage, gmapsApiKey).catch(() => {
  // nothing
});

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

defineCustomElements(window);
SplashScreen.hide();

if ('web' !== Capacitor.getPlatform() && !isTest) {
  setFirebaseCrashlyticsEnabled(true);
}
if ('web' === Capacitor.getPlatform() && !isTest && !isSelfSignedSsl) {
  register('service-worker-main.js');
}

// expose store when run in Cypress
if (isTest) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).store = store;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).localforage = localforage;
}

// reactLoaded variable is used in index.html to warn the user if his browser can't display the app
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).reactLoaded = true;
