import { IonIcon } from '@ionic/react';
import React from 'react';
import { Trans } from 'react-i18next';
import './NetworkAlert.scss';

/* We cannot put the icon in the public folder since we need to access the icon while begin offline */
const iconNoInternet =
  "data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.7 10.35A13.7 13.7 0 009.22 6.62l2.22 2.23c3.06-.15 6.17.93 8.5 3.27l1.76-1.77zm-3.53 3.53a8.64 8.64 0 00-3.96-2.26l3.12 3.12.84-.86zm-15-8.78L5.9 7.79a13.4 13.4 0 00-3.6 2.56l1.76 1.77c1.1-1.1 2.36-1.91 3.71-2.45l1.98 1.98a8.54 8.54 0 00-3.91 2.23v.01l1.75 1.76a6.21 6.21 0 014.35-1.82l6.23 6.23 1.13-1.11L4.32 3.98 3.18 5.1zM9.37 17.4L12 20.06l2.65-2.65a3.74 3.74 0 00-5.3 0z' fill='%23fff'/%3E%3Cpath d='M21.7 10.35A13.7 13.7 0 009.22 6.62l2.22 2.23c3.06-.15 6.17.93 8.5 3.27l1.76-1.77zm-3.53 3.53a8.64 8.64 0 00-3.96-2.26l3.12 3.12.84-.86zm-15-8.78L5.9 7.79a13.4 13.4 0 00-3.6 2.56l1.76 1.77c1.1-1.1 2.36-1.91 3.71-2.45l1.98 1.98a8.54 8.54 0 00-3.91 2.23v.01l1.75 1.76a6.21 6.21 0 014.35-1.82l6.23 6.23 1.13-1.11L4.32 3.98 3.18 5.1zM9.37 17.4L12 20.06l2.65-2.65a3.74 3.74 0 00-5.3 0z' fill='%23fff'/%3E%3C/svg%3E";

const NetworkAlert: React.FunctionComponent = () => {
  return (
    <div className="network-alert">
      <Trans i18nKey="app.no-network" />
      <IonIcon icon={iconNoInternet} color="white" />
    </div>
  );
};

export default NetworkAlert;
