import React from 'react';
import { IonText } from '@ionic/react';
import { User, UserReview } from '../store/users/types';
import { Trans } from 'react-i18next';
import UserItem from './UserItem';
import moment from 'moment';
import './ReviewItem.scss';

interface ComponentProps {
  user: Partial<User> | undefined;
  review: UserReview;
  isResponse?: boolean;
}

type Props = ComponentProps;

const ReviewItem: React.FunctionComponent<Props> = ({ user, review, isResponse }: Props) => {
  if (!user) {
    return <></>;
  }

  return (
    <div className={`review-item ${isResponse ? 'review-item-response' : ''}`}>
      <div className="user-block">
        <UserItem user={review.createdBy} />
        <IonText className="review-date">{moment(new Date(review.createdAt)).format('MMM YYYY ')}</IonText>
      </div>
      <div className="review-content">
        <IonText className="review-recommendation-type" color="dark">
          <Trans i18nKey={`${isResponse ? 'review.response' : `review.user-recommend-type-${review.type}`}`} />
        </IonText>
        <IonText className="review-comment">{review.comment}</IonText>
      </div>
    </div>
  );
};

export default ReviewItem;
