import { HydraViolation, HydraViolationCode, StringErrors } from './dataAccess';

export class ExtendableError extends Error {
  public status: number;
  public responseData?: unknown;

  public constructor(message = '', status: number, responseData?: unknown) {
    super(message);
    this.status = status;
    this.responseData = responseData;

    // extending Error is weird and does not propagate `message`
    Object.defineProperty(this, 'message', {
      configurable: true,
      enumerable: false,
      value: message,
      writable: true,
    });
  }
}

export class SubmissionError extends ExtendableError {
  public errors: StringErrors;
  public violations: HydraViolation[];

  public constructor(error: string, violations: HydraViolation[], status: number, responseData?: unknown) {
    super('post.submit-validation-failed', status, responseData); // TODO Use another translation key not related to post

    this.errors = { _error: error };
    this.violations = violations || [];
    violations.forEach(violation => (this.errors[violation.propertyPath] = violation.message));
  }

  public getViolationCodesForPath(path: string): HydraViolationCode[] {
    return this.violations.filter(violation => violation.propertyPath === path).map(violation => violation.code);
  }

  public pathHasViolation(path: string, code: HydraViolationCode): boolean {
    return this.getViolationCodesForPath(path).includes(code);
  }

  public getErrorsMessage(): string {
    return Object.keys(this.errors)
      .filter(key => key !== '_error')
      .map(path => path + ': ' + this.errors[path])
      .join('\n');
  }
}

export class AuthenticationError extends ExtendableError {
  private response: Response;

  public constructor(response: Response, message?: string, responseData?: unknown) {
    super(message ?? '401 Unauthorized', 401, responseData);
    this.response = response;
  }
}

export function errorIsSubmissionError(error: Error | SubmissionError): error is SubmissionError {
  return typeof (error as SubmissionError)?.violations !== undefined;
}
