import React from 'react';
import { IonIcon } from '@ionic/react';

import './IndigoFermeBanner.scss';
import { IndigoFermeBannerData } from './store/app/types';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

interface IndigoFermeBannerProps {
  onClose: () => void;
  data: IndigoFermeBannerData;
}

const IndigoFermeBanner: React.FC<IndigoFermeBannerProps> = ({ onClose, data }) => {
  return (
    <div className="indigo-ferme-banner-container">
      <div className="indigo-ferme-banner-modal-close">
        <IonIcon className="close-modal-button" icon={'/assets/icon/icon-close-modal.svg'} onClick={onClose} />
      </div>
      <ReactMarkdown
        components={{
          p: ({ children }) => <p className="indigo-ferme-banner-p">{children}</p>,
          a: ({ children, href }) => (
            <a href={href} className="indigo-ferme-banner-a">
              {children}
            </a>
          ),
        }}
      >
        {data.data.text}
      </ReactMarkdown>
    </div>
  );
};

export default IndigoFermeBanner;
