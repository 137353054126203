import { Capacitor } from '@capacitor/core';
import { Geolocation, Position } from '@capacitor/geolocation';
import { decode, encode, GeographicPoint } from 'ngeohash';
import { gmapsApiKey, isHttps } from '../environment';
import { DevicePosition } from '../store/layout/types';
import { Address, FullPost, Location, Post } from '../store/posts/types';
import { resolveIfTrue } from './helpers';

type MapTypeStyle = google.maps.MapTypeStyle;

const staticGoogleMapStyle =
  // eslint-disable-next-line max-len
  'element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x616161&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:poi.park%7Celement:geometry.fill%7Ccolor:0xd1f7ea&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road%7Celement:geometry.fill%7Cvisibility:on&style=feature:road%7Celement:geometry.stroke%7Ccolor:0xffffff%7Csaturation:-100%7Cweight:1&style=feature:road%7Celement:labels.text.fill%7Ccolor:0xffff80&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Ccolor:0xc0c0c0%7Cweight:1.5&style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&style=feature:water%7Celement:geometry.fill%7Ccolor:0xbcd2ea&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e';

export type PlaceResult = google.maps.places.PlaceResult;

export type GeocoderResult = google.maps.GeocoderResult;

export const defaultCenter = { lat: 48.866667, lng: 2.333333 };

// You MUST ALWAYS wait for this promise to be resolved BEFORE using any google.maps.*** object
export const waitForGoogleMaps = (): Promise<void> => {
  return new Promise(function (resolve, reject) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resolveIfTrue(() => typeof (window as any)?.google?.maps !== 'undefined', resolve, reject, 30);
  });
};

let googleMapsScriptIsLoading = false;
let googleMapsLoadingTries = 0;
let googleMapsLoadedTimer: NodeJS.Timeout;

export const loadGoogleMapsScript = (lang: string, key: string, force = false): Promise<void> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const win = window as any;
  const newScriptSrc = `https://maps.googleapis.com/maps/api/js?libraries=geometry,drawing,places&key=${key}&language=${lang}`;

  if (!force && document.querySelector(`script[src^="${newScriptSrc}"]`)) {
    return Promise.resolve();
  }

  window.localStorage.setItem('lastGMapsLanguage', lang);
  console.log(`Loading Google Maps script for lang ${lang}`);

  if (!force && googleMapsScriptIsLoading) {
    console.log('Another GoogleMaps script is already loading');

    return Promise.reject('Another Google Maps script is already loading, skipping this one');
  }

  googleMapsScriptIsLoading = true;

  document.querySelectorAll('script[src^="https://maps.googleapis.com"]').forEach(script => {
    script.remove();
  });

  if (win.google) {
    delete win.google.maps;
  }

  const newAPI = document.createElement('script');
  newAPI.src = newScriptSrc + '&callback=googleMapsAPILoaded';

  googleMapsLoadedTimer = setTimeout(() => {
    if (typeof win?.google?.maps !== 'undefined' || googleMapsLoadingTries >= 3) {
      return;
    }
    // Unable to load the google maps script in less than X seconds, retrying
    googleMapsLoadingTries++;
    loadGoogleMapsScript(lang, key, true);
  }, 5000);

  win.googleMapsAPILoaded = () => {
    googleMapsScriptIsLoading = false;
    if (googleMapsLoadedTimer) {
      clearTimeout(googleMapsLoadedTimer);
    }
    const event = new CustomEvent('googleMapsAPILoaded');
    win.dispatchEvent(event);
  };

  const apiLoaded = new Promise<void>(resolve => {
    const resolveCallback = (): void => {
      resolve();
      win.removeEventListener('googleMapsAPILoaded', resolveCallback);
    };
    win.addEventListener('googleMapsAPILoaded', resolveCallback);
  });

  const head = document.querySelector('head');
  if (!head) {
    return Promise.reject();
  }

  head.appendChild(newAPI);

  return apiLoaded;
};

function extractFromAddressComponent(components: google.maps.GeocoderAddressComponent[]): { locality: string; country: string; postalCode: string } {
  const address = {
    locality: '',
    country: '',
    postalCode: '',
  };

  components.forEach(function (comp: google.maps.GeocoderAddressComponent) {
    switch (comp.types[0]) {
      case 'locality':
      case 'postal_town':
        address.locality = comp.long_name;
        break;
      case 'country':
        address.country = comp.short_name;
        break;
      case 'postal_code':
        address.postalCode = comp.short_name;
        break;
    }
  });
  return address;
}

function getPostalCode(addressObject: PlaceResult | GeocoderResult): Promise<string> {
  const geocoder = new google.maps.Geocoder();
  const address = addressObject.geometry?.location
    ? {
        location: {
          lat: addressObject.geometry.location.lat(),
          lng: addressObject.geometry.location.lng(),
        },
      }
    : {
        address: addressObject.formatted_address,
      };
  return new Promise((resolve, reject) => {
    let postalCode = '';
    geocoder.geocode(address, (results: GeocoderResult[] | null) => {
      (results || []).forEach(item => {
        const comp = item.address_components.find((comp: google.maps.GeocoderAddressComponent) => comp.types[0] === 'postal_code' && comp.types.length === 1);
        if (comp) {
          postalCode = comp.short_name;
        }
      });
      if (postalCode.length > 0) {
        resolve(postalCode);
      } else {
        reject('Postal code have not found');
      }
    });
  });
}

export async function getAddressValuesFromGoogleResults(addressObject: PlaceResult | GeocoderResult): Promise<{ address: Address; location: Location }> {
  // TODO manage to obtain postal_code from every request or find other ways to deal with those specific cases
  const addressElementsExtracted = extractFromAddressComponent(addressObject.address_components || []);
  let formatted = '';

  if ('name' in addressObject && addressObject.name && addressObject.formatted_address) {
    formatted = addressObject.formatted_address.includes(addressObject.name) ? addressObject.formatted_address : addressObject.name.concat(', ', addressObject.formatted_address);
  }
  if (!addressElementsExtracted.postalCode) {
    try {
      addressElementsExtracted.postalCode = await getPostalCode(addressObject);
    } catch {
      // catch postal code getting error
    }
  }

  return {
    location: {
      latitude: addressObject.geometry?.location ? addressObject.geometry.location.lat() : 0,
      longitude: addressObject.geometry?.location ? addressObject.geometry.location.lng() : 0,
    },
    address: {
      formatted: formatted || (addressObject.formatted_address as string),
      locality: addressElementsExtracted.locality,
      country: addressElementsExtracted.country,
      postalCode: addressElementsExtracted.postalCode,
    },
  };
}

// Some other fields are available
type geolocationAPIResponse = {
  error?: {
    message: string;
    status: string;
    code: number;
  };
  accuracy: number;
  location: {
    lat: number;
    lng: number;
  };
};

// We won't accept the IP position if the user position accuracy is beyond a 20km diameter
const accuracyMaxRadiusTolerated = 10000;

const accuracyNotToleratedErrorMessage = (accuracy: number): string => {
  /* accuracy: The accuracy of the estimated location, in meters. This represents the radius of a circle around the given location.*/
  return "User position accuracy wasn't reliable enough to use it. The user position is in a " + (accuracy / 1000).toFixed(2) + 'km radius';
};

export async function getPositionByIP(): Promise<Position> {
  const result = await fetch('https://www.googleapis.com/geolocation/v1/geolocate?key=' + gmapsApiKey, {
    method: 'POST',
    headers: { Accept: 'application/json' },
  }).then(response => response.json() as Promise<geolocationAPIResponse>);

  if (typeof result.error !== 'undefined' || typeof result.location?.lat === 'undefined') {
    return Promise.reject(result.error);
  }

  /* accuracy: The accuracy of the estimated location, in meters. This represents the radius of a circle around the given location.*/
  if (result.accuracy > accuracyMaxRadiusTolerated) {
    return Promise.reject(new Error(accuracyNotToleratedErrorMessage(result.accuracy)));
  }

  return {
    timestamp: +new Date(),
    coords: {
      accuracy: result.accuracy,
      latitude: result.location.lat,
      longitude: result.location.lng,
      altitude: null,
      altitudeAccuracy: null,
      heading: null,
      speed: null,
    },
  };
}

const geolocationTimeout = 10 * 1000;

const geoOptions: PositionOptions = {
  enableHighAccuracy: false,
  timeout: geolocationTimeout,
};

export const clientIsWebHttp = !Capacitor.isNativePlatform() && !isHttps;

export const geolocationIsDenied = async (): Promise<boolean> => {
  try {
    await Geolocation.getCurrentPosition(geoOptions);
  } catch (e) {
    /* On web, error message will be: User denied Geolocation */
    /* On native devices, error message will be: User denied location permission */
    return e.message === 'User denied Geolocation' || e.message === 'User denied location permission';
  }

  return false;
};

// GeolocationPositionError {code: 1, message: "User denied Geolocation"}
// GeolocationPositionError {code: 2, message: "Network location provider at 'https://www.googleapis.com/' : No response received."}
export const getGeolocationError = async (): Promise<Error | null> => {
  try {
    await Geolocation.getCurrentPosition(geoOptions);
  } catch (e) {
    return e;
  }

  return null;
};

export async function getGeocoderResultFromDevicePosition(devicePosition: DevicePosition): Promise<GeocoderResult[]> {
  const location = getLatLngFromDevicePosition(devicePosition);

  await waitForGoogleMaps();

  return new Promise(resolve => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location }, (results: GeocoderResult[] | null) => {
      if (!results) {
        return;
      }
      resolve(results);
    });
  });
}

export function getPostPosition(post: FullPost | Post): google.maps.LatLngLiteral {
  if (post?.location?.latitude && post?.location?.longitude) {
    return {
      lat: post.location.latitude,
      lng: post.location.longitude,
    };
  }

  if (post?.publicLocation?.latitude && post?.publicLocation?.longitude) {
    return {
      lat: post.publicLocation.latitude,
      lng: post.publicLocation.longitude,
    };
  }

  throw new Error('Post does not have any location data');
}

export function getCenter(post: Post): google.maps.LatLngLiteral {
  try {
    return getPostPosition(post);
  } catch (e) {
    return defaultCenter;
  }
}

export function roundWithPrecision(position: number, precision = 0.05): number {
  const floatNumber = (precision + '.').split('.')[1].length;
  // Round 48.854744 to 48.85 and 2.3175 to 2.30 for precision = 0.05

  return parseFloat((Math.round(position / precision) * precision).toFixed(floatNumber));
}

export function roundWithGeohash(coords: GeographicPoint, precision: number): GeographicPoint {
  const encoded = encode(coords.latitude, coords.longitude, precision);

  return decode(encoded);
}

export function getPrecisionByRadius(radius: number | undefined): number {
  if ('undefined' === typeof radius) {
    return 0.01;
  } else if (radius > 50000) {
    return 1;
  } else if (radius > 10000) {
    return 0.3;
  } else if (radius > 5000) {
    return 0.1;
  }

  return 0.05;
}

export function getLatLngFromDevicePosition(location: DevicePosition): google.maps.LatLngLiteral {
  return { lat: location.latitude, lng: location.longitude };
}

// radius is in meters
export function getStaticGoogleMapImage(mapCenter: google.maps.LatLngLiteral, radius = 500, detail = 8, color = '0xff000033'): string {
  let staticMapSrc = 'https://maps.googleapis.com/maps/api/staticmap?key=' + gmapsApiKey;
  staticMapSrc += '&center=' + mapCenter.lat + ',' + mapCenter.lng;
  staticMapSrc += '&zoom=14&format=png&maptype=roadmap&size=480x360&style=' + staticGoogleMapStyle;
  staticMapSrc += `&path=color:${color}|fillcolor:${color}`;

  const r = 6371;

  const pi = Math.PI;

  const _lat = (mapCenter.lat * pi) / 180;
  const _lng = (mapCenter.lng * pi) / 180;
  const d = radius / 1000 / r;

  for (let i = 0; i <= 360; i += detail) {
    const bearing = (i * pi) / 180;

    let pLat = Math.asin(Math.sin(_lat) * Math.cos(d) + Math.cos(_lat) * Math.sin(d) * Math.cos(bearing));
    const pLng = ((_lng + Math.atan2(Math.sin(bearing) * Math.sin(d) * Math.cos(_lat), Math.cos(d) - Math.sin(_lat) * Math.sin(pLat))) * 180) / pi;
    pLat = (pLat * 180) / pi;

    staticMapSrc += '|' + pLat + ',' + pLng;
  }

  return staticMapSrc;
}

export const googleMapStyle: MapTypeStyle[] = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#d1f7ea',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ffffff',
      },
      {
        saturation: -100,
      },
      {
        weight: 1,
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#ffff80',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'road.highway',
    stylers: [
      {
        color: '#c0c0c0',
      },
      {
        weight: 1.5,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#c9c9c9',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#bcd2ea',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
];

export const getLocationFromGoogleMapsLatLngLiteral = (location?: { latitude?: null | number; longitude?: null | number }): Location | undefined => {
  if (!location) {
    return undefined;
  }

  if (location.longitude !== null && location.latitude !== null) {
    return location as Location;
  }
  return undefined;
};
