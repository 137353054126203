import get from 'lodash/get';
import set from 'lodash/set';
import keyBy from 'lodash/keyBy';
import uniq from 'lodash/uniq';
import { CollectionResponse, EntityReference, HydraEntity, IndexedStore, ItemsCollection, ReferencedCollection, ReferencedCollectionResponse } from './hydra';
import { cloneDeep } from 'lodash';

export const createReferencedCollection = (): ReferencedCollection => {
  return {
    ids: [],
    isLoading: false,
    totalItems: 0,
  };
};

export const createItemsCollection = <T extends HydraEntity>(): ItemsCollection<T> => {
  return {
    items: {},
    isLoading: false,
    totalItems: 0,
  };
};

export const initReferencedCollection = (oldCollection: ReferencedCollection | undefined = undefined, isLoading = false): ReferencedCollection => {
  if (oldCollection === undefined) {
    oldCollection = createReferencedCollection();
  }

  return { ...oldCollection, isLoading, lastFetchStartDate: isLoading ? new Date() : undefined };
};

export const initItemsCollection = <T extends HydraEntity>(oldCollection: ItemsCollection<T> | undefined = undefined, isLoading = false): ItemsCollection<T> => {
  if (oldCollection === undefined) {
    oldCollection = createItemsCollection<T>();
  }

  return { ...oldCollection, isLoading, lastFetchStartDate: isLoading ? new Date() : undefined };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
export const updateItemValue = <T extends HydraEntity>(list: IndexedStore<T>, id: EntityReference, propertyPath: keyof T | string[], newValue: any): IndexedStore<T> => {
  if (typeof id === 'undefined' || typeof list[id] === 'undefined') {
    return list;
  }

  return {
    ...list,
    [id]: set(cloneDeep(list[id]), propertyPath, newValue),
  };
};

export const updateItemValues = <T extends HydraEntity>(list: IndexedStore<T>, id: EntityReference, newValues: Partial<T>): IndexedStore<T> => {
  if (typeof id === 'undefined' || (typeof list[id] === 'undefined' && typeof newValues['@id'] === 'undefined')) {
    return list;
  }

  return {
    ...list,
    [id]: {
      ...(list[id] || {}),
      ...newValues,
    },
  };
};

export const updateItemWithCollectionLoading = <T extends HydraEntity>(list: IndexedStore<T>, id: EntityReference, collectionPath: keyof T | string[], isLoading: boolean): IndexedStore<T> => {
  const oldColl = get(list[id], collectionPath) as ReferencedCollection | undefined;
  const newColl = initReferencedCollection(oldColl, isLoading);

  return updateItemValues(list, id, set(list[id] || {}, collectionPath, newColl) as Partial<T>);
};

export const reduceAndMergeItems = <T extends HydraEntity>(mergedItems: IndexedStore<T>, [id, item]: [string, T | unknown]): IndexedStore<T> => {
  return updateItemValues(mergedItems, id, item as Partial<T>);
};

export const mergeReferencedCollections = (data: ReferencedCollectionResponse, oldCollection: ReferencedCollection | undefined): ReferencedCollection => {
  return {
    ids: data.isFirstPage || oldCollection === undefined ? [...data.ids] : uniq(oldCollection.ids.concat(data.ids)),
    totalItems: data.totalItems,
    nextPage: data.nextPage,
    lastFetchDate: new Date(),
    isLoading: false,
  };
};

export const mergeItemsCollections = <T extends HydraEntity>(data: CollectionResponse<T>, oldCollection: ItemsCollection<T> | undefined): ItemsCollection<T> => {
  return {
    items: { ...(data.isFirstPage || !oldCollection?.items ? {} : oldCollection.items), ...keyBy(data.items, '@id') },
    totalItems: data.totalItems,
    nextPage: data.nextPage,
    lastFetchDate: new Date(),
    isLoading: false,
  };
};

export const extendItemsCollections = <T extends HydraEntity>(data: CollectionResponse<T>, oldCollection: ItemsCollection<T> | undefined): ItemsCollection<T> => {
  const oldCollectionItems = oldCollection?.items ?? {};
  const newCollectionItems = { ...oldCollectionItems, ...keyBy(data.items, '@id') };

  return {
    items: newCollectionItems,
    totalItems: data.totalItems - Object.keys(data.items).length + Object.keys(newCollectionItems).length,
    nextPage: data.nextPage,
    lastFetchDate: new Date(),
    isLoading: false,
  };
};
