/* eslint-disable @typescript-eslint/no-explicit-any */
import { Capacitor } from '@capacitor/core';
import { HTTP, HTTPResponse } from '@ionic-native/http';
import { FetchOptions } from './dataAccess';

export function headersToObject(headers: HeadersInit): Record<string, string> {
  if (typeof headers !== 'object' || typeof headers.entries !== 'function') {
    throw Error('Not supported header type');
  }

  const vals: Record<string, string> = {};
  const entries = headers.entries() as unknown as [string, string][];
  const keyVals = [...entries];

  keyVals.forEach(([key, val]) => {
    vals[key] = val;
  });
  return vals;
}

// TODO Remove this function and only deal with HttpResponse on the app.
function nativeResponseToFetchResponse(response: HTTPResponse): Response {
  const headers: Record<string, string> = {};
  if (Array.isArray(response.headers)) {
    for (const { key, value } of response.headers) {
      headers[key] = value;
    }
  }

  return new Response(response.data || null, {
    headers: headers,
    status: response.status,
  });
}

function runNativeFetch(url: string, options?: FetchOptions): Promise<Response> {
  const method = options?.method?.toLowerCase() || 'get';
  const nativeOptions: any = { method, serializer: 'utf8', responseType: 'text' };

  if (options?.headers?.entries) {
    // This is a Header object, we need to flatten it
    nativeOptions.headers = headersToObject(options.headers);
  }

  if (options?.data !== undefined) {
    nativeOptions.data = JSON.stringify(options.data);
  } else if (options?.body !== undefined && typeof options.body === 'string') {
    nativeOptions.data = options.body;
  }

  nativeOptions.data = JSON.stringify(options?.data) || options?.body;

  return HTTP.sendRequest(url, nativeOptions as any).then(nativeResponseToFetchResponse);
}

function runBrowserFetch(url: string, options?: FetchOptions): Promise<Response> {
  const browserFetchOptions: RequestInit = {
    method: options?.method || 'GET',
    headers: options?.headers,
    body: options?.body,
  };

  if (typeof options?.data !== 'undefined') {
    browserFetchOptions.body = JSON.stringify(options.data);
  }

  return fetch(url, browserFetchOptions);
}

export default function nativeFetch(url: string, options?: FetchOptions): Promise<Response> {
  if (Capacitor.getPlatform() === 'web') {
    return runBrowserFetch(url, options);
  }

  // Don't use the native http call for images upload for now, as it's another function to implement.
  if (options?.body instanceof FormData) {
    return runBrowserFetch(url, options);
  }

  try {
    return runNativeFetch(url, options).catch(e => {
      console.error('Error in promise when running native fetch', e);
      return runBrowserFetch(url, options);
    });
  } catch (e) {
    console.error('Error when running native fetch', e);
    return runBrowserFetch(url, options);
  }
}
