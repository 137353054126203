import React from 'react';
import { IonSpinner } from '@ionic/react';
import { PostsRowPlaceholder } from './PostsPlaceholder';
import './HomePagePlaceholder.scss';
import './CommonPlaceholderStyles.scss';

const HomePagePlaceholder: React.FunctionComponent = () => {
  return (
    <div className="homepage-placeholder common-placeholder" data-cy="homepage-placeholder">
      <IonSpinner className="common-placeholder-spinner" data-cy="homepage-loader" />
      <PostsRowPlaceholder />
      <PostsRowPlaceholder reverse />
    </div>
  );
};

export default HomePagePlaceholder;
