import { setAppInfo, setDeviceId, setDeviceInfo } from '../environment';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { Capacitor } from '@capacitor/core';

export async function setDeviceVersion(): Promise<void> {
  setDeviceInfo(await Device.getInfo());
  setDeviceId(await Device.getId());
  if (Capacitor.isNativePlatform()) {
    setAppInfo(await App.getInfo());
  }
}

/**
 * Device.getInfo
 * Chrome on Ubuntu
 {
  appBuild: "",
  appId: "",
  appName: "",
  appVersion: "",
  isVirtual: false,
  manufacturer: "Google Inc.",
  model: "X11",
  operatingSystem: "unknown",
  osVersion: "x86.64",
  platform: "web",
  uuid: "8c7e71f0-14c7-4db9-9797-97fdce0c1a94",
}
 */

/**
 * Chrome on Android
 {
  appBuild: "",
  appId: "",
  appName: "",
  appVersion: "",
  isVirtual: false,
  manufacturer: "Google Inc.",
  model: "CLT-L29",
  operatingSystem: "android",
  osVersion: "Android 10",
  platform: "web",
  uuid: "a9d11748-4b05-4e6c-af14-f56c5209ac2c",
}
 */

/**
 * Android app
 {
  appBuild: "401007",
  appId: "com.cooperativeIndigo.indigo",
  appName: "Indigo",
  appVersion: "4.1.7-65-15e321a-dirty",
  diskFree: 0,
  diskTotal: 1650479104,
  isVirtual: false,
  manufacturer: "HUAWEI",
  memUsed: 5862984,
  model: "CLT-L29",
  operatingSystem: "android",
  osVersion: "10",
  platform: "android",
  uuid: "4c22d793b53f488d",
}
 */

/**
 * iOS app
 {
  "diskFree": 196477255680,
  "diskTotal": 499933818880,
  "manufacturer": "Apple",
  "appVersion": "4.1.8",
  "model": "iPhone",
  "operatingSystem": "ios",
  "appBuild": "202104291156",
  "uuid": "EB6F9968-B673-4874-BFD7-6AC8C5AC8374",
  "appId": "com.cooperative-indigo.indigo",
  "osVersion": "12.4",
  "memUsed": 166092800,
  "name": "iPhone 6 Plus",
  "appName": "Indigo",
  "isVirtual": true,
  "platform": "ios"
}
 */

/**
 * Safari on iOS
{
  appBuild: "",
  appId: "",
  appName: "",
  appVersion: "",
  isVirtual: false,
  manufacturer: "Apple Computer, Inc.",
  model: "iPhone",
  operatingSystem: "ios",
  osVersion: "12.4",
  platform: "web",
  uuid: "bb3be479-99f5-4f12-876f-b4a08ae88302",
}
 */

/**
 * Chrome on OSX
{
  appBuild: "",
  appId: "",
  appName: "",
  appVersion: "",
  isVirtual: false,
  manufacturer: "Google Inc.",
  model: "Macintosh",
  operatingSystem: "mac",
  osVersion: "10.15.7",
  platform: "web",
  uuid: "26b9ed52-24f8-453a-99f4-12f07c732915",
}
 */

/**
 * Safari on OSX
{
  appBuild: "",
  appId: "",
  appName: "",
  appVersion: "",
  isVirtual: false,
  manufacturer: "Apple Computer, Inc.",
  model: "Macintosh",
  operatingSystem: "mac",
  osVersion: "10.15.7",
  platform: "web",
  uuid: "06b11b5c-d48a-4bb3-bd30-8a7d8b23c0f4",
}
 */
