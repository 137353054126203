import { IonContent, IonThumbnail, IonText, IonLabel, IonBadge, IonPage, IonSpinner } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { actions, RootState } from '../store';
import { CurrentUser } from '../store/users/types';
import { User } from '../store/users/types';
import { extractId, getUserAvatar, isEqualIgnoringFunctions, isSameHydraEntity } from '../utils/helpers';
import { CollectionResponse, EntityReference } from '../utils/hydra';
import { JWTToken } from '../store/app/types';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { Trans } from 'react-i18next';
import { setStatusBarStyle } from '../utils/statusBarHelper';
import { locationIsProfileSelectionPage } from '../utils/windowLocationHelper';
import ImageFadeIn from '../components/ImageFadeIn';
import { getRedirectPathAfterLogin } from '../utils/routeHelpers';
import { isTest } from '../environment';
import './SelectProfilePage.scss';

interface StateProps {
  currentUser: CurrentUser;
  whoAmI: CurrentUser | null;
  managedUsersCollection: CollectionResponse<User> | undefined;
}

const mapStateToProps = (state: RootState): StateProps => ({
  currentUser: state.app.currentUser,
  whoAmI: state.whoAmI.whoAmIUser ?? null,
  managedUsersCollection: state.whoAmI.managedUsers,
});

interface DispatchProps {
  authenticateAsManagedUser(user: EntityReference): Promise<JWTToken>;
  authenticateAsWhoAmI(): void;
  fetchLoggedUser: () => Promise<void>;
  fetchManagedUsers: () => Promise<User[]>;
}

const propsToDispatch = {
  authenticateAsManagedUser: actions.app.authenticateAsManagedUser,
  authenticateAsWhoAmI: actions.whoAmI.authenticateAsWhoAmI,
  fetchLoggedUser: actions.app.fetchLoggedUser,
  fetchManagedUsers: actions.whoAmI.fetchManagedUsers,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

interface State {
  isSelectingUser: boolean;
}

type SelectProfilePageProps = StateProps & DispatchProps & RouteComponentProps<Record<string, never>>;

class SelectProfilePage extends Component<SelectProfilePageProps, State> {
  public constructor(props: SelectProfilePageProps) {
    super(props);
    this.state = {
      isSelectingUser: false,
    };
  }

  public shouldComponentUpdate(nextProps: Readonly<SelectProfilePageProps>): boolean {
    if (locationIsProfileSelectionPage()) {
      return !isEqualIgnoringFunctions(nextProps, this.props);
    }
    return false;
  }

  public componentDidMount(): void {
    // TODO Explain why this line or remove
    if (!isSameHydraEntity(this.props.whoAmI, this.props.currentUser)) {
      this.props.authenticateAsWhoAmI();
    }

    this.props.fetchLoggedUser();
    if (this.props.whoAmI) {
      this.props.fetchManagedUsers();
    }

    if (isTest && localStorage.getItem('test-login-select-whoami')) {
      this.selectUser(extractId(this.props.currentUser), 'current');
      localStorage.removeItem('test-login-select-whoami');
      console.info('Bypassing managed user page');
    }
  }

  public componentDidUpdate(prevProps: Readonly<SelectProfilePageProps>): void {
    if (!isSameHydraEntity(prevProps.currentUser, this.props.currentUser) || !isSameHydraEntity(prevProps.whoAmI, this.props.whoAmI)) {
      this.props.fetchLoggedUser();
      this.props.fetchManagedUsers();
    }
  }

  public render(): ReactNode {
    const { whoAmI, managedUsersCollection } = this.props;

    if (managedUsersCollection?.totalItems === 0) {
      setStatusBarStyle();
      return <Redirect to={getRedirectPathAfterLogin()} />;
    }
    setStatusBarStyle(false);

    return (
      <IonPage className="select-profile-page" data-cy="select-profile-page">
        <IonContent>
          {!whoAmI && <IonSpinner className="common-placeholder-spinner" data-cy="select-profile-page-loader" />}
          {whoAmI && (
            <div className="select-profile-list">
              <IonText className="select-profile-title" color="light">
                <Trans i18nKey="user.who-is-it" />
              </IonText>
              <div className="select-profile-wrapper">
                <div onClick={() => this.selectUser(extractId(whoAmI), 'current')} className="select-profile-item" data-cy="current-user-select-item">
                  <IonThumbnail className="select-profile-avatar">
                    <img src={getUserAvatar(whoAmI)} alt="avatar" />

                    {(whoAmI?.status?.unreadNotifications || 0) + (whoAmI?.status?.unreadChats || 0) > 0 ? (
                      <IonBadge color="danger" className="count-badge" data-cy="select-page-notification-counter-for-who-am-i">
                        {(whoAmI?.status?.unreadNotifications || 0) + (whoAmI?.status?.unreadChats || 0)}
                      </IonBadge>
                    ) : null}
                  </IonThumbnail>
                  <IonLabel className="user-description">
                    <IonText className="user-name" color="light">
                      {whoAmI.name}
                    </IonText>
                  </IonLabel>
                </div>

                {managedUsersCollection?.items.map((user, index) => {
                  if (user.disabled) {
                    return <React.Fragment key={extractId(user)} />;
                  }
                  return (
                    <div
                      key={extractId(user)}
                      onClick={() => this.selectUser(extractId(this.props.managedUsersCollection?.items[index]), 'managed')}
                      className="select-profile-item"
                      data-cy="managed-user-select-item"
                    >
                      <IonThumbnail className="select-profile-avatar">
                        <ImageFadeIn thumbnail src={getUserAvatar(user)} alt="avatar" />

                        {(user.status?.unreadNotifications || 0) + (user.status?.unreadChats || 0) > 0 ? (
                          <IonBadge color="danger" className="count-badge" data-cy="select-page-notification-counter-for-managed-user">
                            {(user.status?.unreadNotifications || 0) + (user.status?.unreadChats || 0)}
                          </IonBadge>
                        ) : null}
                      </IonThumbnail>
                      <IonLabel className="user-description">
                        <IonText className="user-name" color="light">
                          {user.name}
                        </IonText>
                      </IonLabel>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </IonContent>
      </IonPage>
    );
  }

  private selectUser = async (userId: EntityReference, type: 'current' | 'managed'): Promise<void> => {
    const { currentUser, history, authenticateAsManagedUser, authenticateAsWhoAmI } = this.props;
    const isSameUser = isSameHydraEntity(currentUser, userId);

    // Avoid multi click that can lead to resetting to redirectPathAfterLogin to null
    if (this.state.isSelectingUser) {
      return;
    }
    this.setState({ isSelectingUser: true });

    if (!isSameUser) {
      if (type === 'current') {
        authenticateAsWhoAmI();
      } else {
        try {
          await authenticateAsManagedUser(userId);
        } catch {
          return;
        }
      }
    }

    setStatusBarStyle();
    history.push(getRedirectPathAfterLogin());
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SelectProfilePage));
