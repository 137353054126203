/* eslint-disable @typescript-eslint/no-explicit-any */
import { Capacitor } from '@capacitor/core';
import { apiEntrypoint, appVersion, deviceId, deviceInfo } from '../environment';
import { AuthenticationError, ExtendableError, SubmissionError } from './dataAccessError';
import { getAppLanguages } from './translation';
import fetch, { headersToObject } from './fetch';

export const JSON_MIME_TYPE = 'application/json';
export const JSOND_MIME_TYPE = 'application/ld+json';

export interface FetchOptions {
  method?: string;
  headers?: Headers | Record<string, string>;
  data?: Record<string, any>;
  body?: string | FormData;
}

export interface StrictFetchOptions {
  method: string;
  headers: Headers;
  data?: Record<string, any>;
  body?: string | FormData;
}

export const NOT_UNIQUE_ERROR = '23bd9dbf-6b9b-41cd-a99e-4844bcf3077f'; // api/vendor/symfony/doctrine-bridge/Validator/Constraints/UniqueEntity.php

export type HydraViolationCode = typeof NOT_UNIQUE_ERROR | string; // uuid, the Symfony unique id of the error

export interface HydraViolation {
  code: HydraViolationCode;
  propertyPath: string;
  message: string;
}

export interface StringErrors {
  _error: string;

  [key: string]: string;
}

export function initFetchOptions(options?: FetchOptions): StrictFetchOptions {
  if ('undefined' === typeof options) {
    options = {};
  }

  const headers = new Headers();
  if ((options.body || options.data) && !(options.body instanceof FormData)) {
    headers.set('Content-Type', JSON_MIME_TYPE);
  }

  if (options?.headers) {
    if (options.headers instanceof Headers) {
      options.headers = headersToObject(options.headers);
    }

    for (const key of Object.keys(options.headers)) {
      headers.set(key, options.headers[key]);
    }
  }

  return {
    method: options.method || 'GET',
    headers,
    data: options.data || undefined,
    body: options.body || undefined,
  };
}

export function jsonFetch(page: string, fetchOptions?: FetchOptions): Promise<Response> {
  const options = initFetchOptions(fetchOptions);

  if (null === options.headers.get('Accept')) {
    options.headers.set('Accept', JSON_MIME_TYPE);
  }

  if (null === options.headers.get('Accept-Language')) {
    options.headers.set('Accept-Language', getAppLanguages().join(','));
  }

  options.headers.set('X-Indigo', Capacitor.getPlatform() + '/' + appVersion);

  if (deviceInfo?.webViewVersion) {
    options.headers.set('X-WebView', deviceInfo?.webViewVersion);
  }

  if (deviceId?.identifier) {
    // Add the device unique ID to all requests. This identifier can change if the user re-installs the app, or flushes the localstorage on web.
    options.headers.set('X-Device-Id', deviceId?.identifier);
  }

  return fetch(new URL(page, apiEntrypoint).toString(), options);
}

export async function createSubmissionError(response: Response): Promise<ExtendableError> {
  const data = await response.json();
  const error: string = data['hydra:description'] || response.statusText;

  if (!data.violations) {
    return new ExtendableError(error, response.status, data);
  }

  return new SubmissionError(error, data.violations, response.status, data);
}

export function hydraFetch(page: string, fetchOptions?: FetchOptions): Promise<Response> {
  const options = initFetchOptions(fetchOptions);

  if (null === options.headers.get('Accept')) {
    options.headers.set('Accept', JSOND_MIME_TYPE);
  }

  if ('undefined' !== options.body && !(options.body instanceof FormData) && null === options.headers.get('Content-Type')) {
    options.headers.set('Content-Type', JSOND_MIME_TYPE);
  }

  return jsonFetch(page, options).then(async response => {
    if (response.ok) {
      return new Promise((resolve): void => resolve(response)) as Promise<Response>;
    }

    if (response.status === 401) {
      const data = await response.json();
      const error: string | undefined = data?.message;

      throw new AuthenticationError(response, error);
    }

    throw await createSubmissionError(response);
  });
}

export async function throwErrorIfAny(response: Response): Promise<Response> {
  if (response.ok) {
    return response;
  }

  const data = await response.json();
  const error: string = data['hydra:description'] || data['message'] || (typeof data['error'] !== 'undefined' ? data['error']['message'] : false) || data['title'] || response.statusText;

  throw Error(error);
}

function addParameterToUrl(baseUrl: string, key: string, value: string | number | string[]): string {
  if (Array.isArray(value)) {
    value.forEach(val => (baseUrl = addParameterToUrl(baseUrl, key, val)));
  } else {
    baseUrl += baseUrl.split('?')[1] ? '&' : '?';
    baseUrl += `${key}=${encodeURIComponent(value)}`;
  }

  return baseUrl;
}

export type QueryParameters = { [key: string]: string | number | string[] | undefined };

export function addParametersToUrl(url: string, params: QueryParameters): string {
  Object.keys(params).forEach(key => {
    const paramValue = params[key];
    if (paramValue !== undefined) {
      url = addParameterToUrl(url, key, paramValue);
    }
  });
  return url;
}
