import React, { Component, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import CommonHeader from '../components/CommonHeader';
import { IonContent, IonItem, IonLabel, IonPage, IonToggle, withIonLifeCycle } from '@ionic/react';
import SettingsToggleComponent from '../components/SettingsToggleComponent';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { actions, RootState } from '../store';
import { bindActionCreators, Dispatch } from 'redux';
import { CurrentUser, CurrentUserEditFields } from '../store/users/types';
import { extractId } from '../utils/helpers';
import { notificationsChoice } from '../components/SettingsToggle';
import { sendNotificationAuthorizationLog } from '../utils/analytics/analyticsHelper';
import './NotificationSettingsPage.scss';

interface State {
  disabledNotifications: string[];
  isPushNotificationsAllowed: boolean;
  notificationType: string;
  allowNotificationsName: userAllowNotificationsType;
  disabledNotificationsName: userDisabledNotificationsType;
  isSubmitting: boolean;
}

interface StateProps {
  currentUser: CurrentUser;
}

const mapStateToProps = (state: RootState): StateProps => ({
  currentUser: state.app.currentUser,
});

const propsToDispatch = {
  updateUser: actions.app.updateUser,
  fetchLoggedUser: actions.app.fetchLoggedUser,
};

interface DispatchProps {
  updateUser(user: Partial<CurrentUserEditFields>): Promise<CurrentUser>;
  fetchLoggedUser: () => Promise<void>;
}

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type Props = StateProps & RouteComponentProps<Record<string, never>> & WithTranslation & DispatchProps;
type userAllowNotificationsType = 'allowMailNotifications' | 'allowPushNotifications';
type userDisabledNotificationsType = 'disabledMailNotifications' | 'disabledPushNotifications';
type notificationTypeType = 'mail' | 'push';

function strUcFirst(a: string): string {
  return (a + '').charAt(0).toUpperCase() + a.substr(1);
}

class NotificationSettingsPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const type: string = strUcFirst(this.props.match.params.type);
    const allowNotificationsName: userAllowNotificationsType = ('allow' + type + 'Notifications') as userAllowNotificationsType;
    const disabledNotificationsName: userDisabledNotificationsType = ('disabled' + type + 'Notifications') as userDisabledNotificationsType;

    this.state = {
      allowNotificationsName: allowNotificationsName,
      disabledNotificationsName: disabledNotificationsName,
      disabledNotifications: this.props.currentUser[disabledNotificationsName],
      isPushNotificationsAllowed: this.props.currentUser[allowNotificationsName],
      notificationType: this.props.match.params.type,
      isSubmitting: false,
    };
  }

  public ionViewDidEnter(): void {
    this.setState({ disabledNotifications: this.props.currentUser[this.state.disabledNotificationsName] });
  }

  updateUserNotifications = async (userNotifications: Partial<CurrentUser>): Promise<void> => {
    //TODO rework updateUser function
    await this.props
      .updateUser(userNotifications)
      .then(() => this.props.fetchLoggedUser())
      .finally(() => this.setState({ isSubmitting: false }));
  };

  allowPushNotificationsHandler = async (): Promise<void> => {
    const { isPushNotificationsAllowed } = this.state;
    this.setState({ isPushNotificationsAllowed: !isPushNotificationsAllowed, isSubmitting: true }, () => {
      sendNotificationAuthorizationLog(!isPushNotificationsAllowed);
      const newUserAllowPushNotificationsValues: Partial<CurrentUser> = {
        '@id': extractId(this.props.currentUser),
        [this.state.allowNotificationsName]: !this.props.currentUser[this.state.allowNotificationsName],
      };
      this.updateUserNotifications(newUserAllowPushNotificationsValues);
    });
  };

  disableNotifications = (e: CustomEvent): void => {
    const { checked, value } = e.detail;
    const { disabledNotifications } = this.state;
    const newDisabledNotifications = {
      disabledNotifications: checked ? disabledNotifications.filter(item => value !== item) : [...disabledNotifications, value],
    };
    const newUserDisablePushNotificationsValues: Partial<CurrentUser> = {
      '@id': extractId(this.props.currentUser),
      [this.state.disabledNotificationsName]: newDisabledNotifications.disabledNotifications,
    };
    //TODO rework updateUser function
    this.setState(
      {
        disabledNotifications: newDisabledNotifications.disabledNotifications,
        isSubmitting: true,
      },
      () => {
        this.updateUserNotifications(newUserDisablePushNotificationsValues);
      },
    );
  };

  render(): ReactNode {
    const { isPushNotificationsAllowed, disabledNotifications, isSubmitting } = this.state;
    const notificationTypeName: notificationTypeType = this.state.notificationType as notificationTypeType;

    return (
      <IonPage className="notification-settings-page" data-cy="notification-settings-page">
        <CommonHeader addIonHeader={true} title={this.props.t(`profile-menu.${this.state.notificationType}-notifications`)} />
        <IonContent>
          <IonItem lines="full">
            <IonLabel>{this.props.t(`notification-settings.allow-${this.state.notificationType}-notifications`)}</IonLabel>
            <IonToggle data-cy="allow-notification" color="medium" disabled={isSubmitting} checked={isPushNotificationsAllowed} onIonChange={this.allowPushNotificationsHandler} />
          </IonItem>
          <div data-cy="notifications-settings">
            {isPushNotificationsAllowed && (
              <SettingsToggleComponent
                notificationsData={notificationsChoice[notificationTypeName]}
                enableDisableNotification={e => this.disableNotifications(e)}
                disabledOptions={disabledNotifications}
                isSubmitting={isSubmitting}
              />
            )}
          </div>
        </IonContent>
      </IonPage>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(withIonLifeCycle(NotificationSettingsPage))));
