import { IonActionSheet } from '@ionic/react';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { EntityReference } from '../../utils/hydra';
import { actions } from '../../store';
import { SearchQueryParameters } from '../../store/posts/types';

interface DispatchProps {
  deleteSavedSearch: (savedSearchId: EntityReference) => Promise<void>;
}

const propsToDispatch = {
  deleteSavedSearch: actions.posts.deleteSavedSearch,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

interface SavedSearchOptionActionSheetProps {
  savedSearchId?: EntityReference;
  searchFilters?: SearchQueryParameters;
  isOpen: boolean;
  closeActionSheet: () => void;
  onShareClick: (searchFilters?: SearchQueryParameters) => void;
  onSavedSearchDelete?: () => void;
}

type Props = SavedSearchOptionActionSheetProps & DispatchProps & WithTranslation;

const SavedSearchOptionActionSheet: React.FunctionComponent<Props & WithTranslation> = ({
  searchFilters,
  savedSearchId,
  isOpen,
  closeActionSheet,
  onSavedSearchDelete,
  deleteSavedSearch,
  onShareClick,
  t,
}: Props & WithTranslation) => {
  const deleteSavedSearchClick = (): void => {
    if (!savedSearchId) {
      return;
    }

    deleteSavedSearch(savedSearchId)
      .then(() => {
        if (onSavedSearchDelete) {
          onSavedSearchDelete();
        }
      })
      .catch(() => {
        // Prevent error
      });
  };

  if (!savedSearchId) {
    return null;
  }

  const shareClick = (): void => {
    if (!searchFilters) {
      return;
    }

    onShareClick(searchFilters);
  };

  return (
    <>
      <IonActionSheet
        data-cy="saved-search-action-sheet"
        isOpen={isOpen}
        onDidDismiss={closeActionSheet}
        buttons={[
          {
            text: t('search.share'),
            handler: shareClick,
          },
          {
            text: t('search.delete-saved-search'),
            role: 'destructive',
            handler: deleteSavedSearchClick,
          },
          {
            text: t('common.cancel'),
            role: 'cancel',
          },
        ]}
      />
    </>
  );
};

export default connect(null, mapDispatchToProps)(withTranslation()(SavedSearchOptionActionSheet));
