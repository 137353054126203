import React from 'react';
import { IonModal } from '@ionic/react';
import PostCo2EstimationSteps, { Co2EstimationStep } from './PostCo2EstimationSteps';
import { Category, Discriminant } from '../store/categories/types';

import './PostCreationSuccessModal.scss';

interface PostCo2EstimationModalProps {
  isOpen: boolean;
  updatePost: (quantity?: number | null, weight?: number | null, discriminants?: Discriminant[]) => void;
  category?: Category;
  onCloseModal: () => void;
  quantityProps?: number;
  estimatedWeightProps?: number;
  discriminants: Discriminant[];
  discriminantsProps: Discriminant[];
  steps: Co2EstimationStep[];
}

type Props = PostCo2EstimationModalProps;

export const PostCo2EstimationModal: React.FunctionComponent<Props> = (props: Props) => {
  return (
    <IonModal isOpen={props.isOpen} onDidDismiss={props.onCloseModal} className="post-co2-estimation-modal safe-area-ios" data-cy="post-co2-estimation-modal">
      {/* This condition is used to unmount the PostEstimation Steps component when the modal is closed */}
      {props.isOpen && <PostCo2EstimationSteps {...props} onStepsEnd={props.onCloseModal} />}
    </IonModal>
  );
};

export default PostCo2EstimationModal;
