import React from 'react';
import { IonContent, IonItem, IonModal, IonText, IonList, IonIcon } from '@ionic/react';
import { CurrentUser, User } from '../../store/users/types';
import UserItem from '../UserItem';
import './SwitchProfileModal.scss';
import { extractId } from '../../utils/helpers';
import { EntityReference } from '../../utils/hydra';
import { JWTToken } from '../../store/app/types';
import { Trans } from 'react-i18next';

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  whoAmI: CurrentUser | null;
  currentUser: CurrentUser;
  managedUsers?: User[];
  authenticateAsManagedUser: (user: EntityReference) => Promise<JWTToken>;
  authenticateAsWhoAmI: () => void;
  openAddPageModal: () => void;
}

const SwitchProfileModal: React.FunctionComponent<ModalProps> = (props: ModalProps) => {
  const connectedAsWhoAmI = props.whoAmI && extractId(props.currentUser) === extractId(props.whoAmI);
  const contentHeight = 200 + (props.managedUsers || [])?.length * 80;

  const getClass = (): string => {
    if ((contentHeight * 100) / window.innerHeight >= 60) {
      return 'switch-profile-modal-60';
    }

    return `switch-profile-modal-${Math.round((contentHeight * 100) / window.innerHeight)}`;
  };

  return (
    <IonModal isOpen={props.isOpen} onDidDismiss={props.closeModal} className={`switch-profile-modal safe-area-ios--no-padding-top ${getClass()}`}>
      <IonContent>
        <IonList>
          <IonItem lines="none" className="basic-item with-line-bottom selected-item">
            <UserItem user={props.currentUser as User} size="large" routingComponent={false} />
            <IonIcon icon="/assets/form/checkmark-circle-sharp.svg" color="primary" />
          </IonItem>
          {!connectedAsWhoAmI && (
            <IonItem
              onClick={() => {
                props.authenticateAsWhoAmI();
                props.closeModal();
              }}
              lines="none"
              className="basic-item with-line-bottom"
            >
              <UserItem user={props.whoAmI as User} size="large" routingComponent={false} />
            </IonItem>
          )}
          {props.managedUsers?.map(managedUser => {
            if (!connectedAsWhoAmI && extractId(props.currentUser) === extractId(managedUser)) {
              return <React.Fragment key={extractId(managedUser)} />;
            }
            return (
              <IonItem
                key={extractId(managedUser)}
                onClick={() => {
                  props.authenticateAsManagedUser(extractId(managedUser));
                  props.closeModal();
                }}
                lines="none"
                className="basic-item with-line-bottom"
                disabled={managedUser?.disabled}
              >
                <UserItem user={managedUser} size="large" routingComponent={false} />
              </IonItem>
            );
          })}
          <IonItem
            className="create-page-item"
            lines="none"
            button
            onClick={() => {
              props.openAddPageModal();
              props.closeModal();
            }}
            detail={false}
          >
            <IonIcon icon="/assets/icon/plus.svg" />
            <IonText>
              <Trans i18nKey="organization.login-or-create" />
            </IonText>
          </IonItem>
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default SwitchProfileModal;
