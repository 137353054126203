import isEqual from 'lodash/isEqual';
import React, { Component, ReactNode } from 'react';
import { IonModal, IonText, IonButton } from '@ionic/react';
import { Trans } from 'react-i18next';
import { RootState } from '../store';
import { connect } from 'react-redux';
import { setStatusBarStyle } from '../utils/statusBarHelper';
import { getUserCanCreateUsers, getUserIsGlobalNotifier } from '../store/app/selectors';
import './AddPostModal.scss';

interface State {
  triggerAnimation: boolean;
  closeButtonImageUrl: string;
}

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

interface StateProps {
  userCanCreateGlobalNotification: boolean;
  userCanCreateUsers: boolean;
}

const mapStateToProps = (state: RootState): StateProps => ({
  userCanCreateGlobalNotification: getUserIsGlobalNotifier(state),
  userCanCreateUsers: getUserCanCreateUsers(state),
});

type Props = StateProps & ModalProps;

const closeButtonImageUrl = '/assets/icon-close-popup.svg';
const closeButtonImageUrlClicked = '/assets/icon-close-popup-hover.svg';

class AddPostModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      triggerAnimation: true,
      closeButtonImageUrl: closeButtonImageUrl,
    };
  }

  public shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>): boolean {
    return nextProps.isOpen !== this.props.isOpen || !isEqual(nextState, this.state);
  }

  public componentDidUpdate(): void {
    if (this.props.isOpen) {
      setStatusBarStyle(false);
    }
  }

  public render(): ReactNode {
    const { isOpen, closeModal, userCanCreateGlobalNotification, userCanCreateUsers } = this.props;
    const { triggerAnimation } = this.state;

    return (
      <>
        <IonModal data-cy="add-posts-ion-modal" isOpen={isOpen} onDidDismiss={closeModal} className="add-posts-ion-modal safe-area-ios--no-padding-top" showBackdrop={false}>
          <div className={triggerAnimation ? 'add-post-modal fade-up' : 'add-post-modal fade-down'}>
            {userCanCreateGlobalNotification && (
              <div className="pop-app-request-btn-wrapper">
                <IonButton className="pop-app-request-btn" color="success" routerLink="/notifications/global/create" onClick={this.handleClick}>
                  <Trans i18nKey="notification.notification-for-all-users" />
                </IonButton>
              </div>
            )}
            {userCanCreateUsers && (
              <div className="pop-app-request-btn-wrapper">
                <IonButton className="pop-app-request-btn" color="success" routerLink="/users/create/new" onClick={this.handleClick}>
                  <Trans i18nKey="register.pre-registration-form" />
                </IonButton>
              </div>
            )}
            <div className="btn-list">
              <div className="btn-wrapper btn-first">
                <IonButton className="icon-btn" fill="clear" routerLink="/posts/create/need" onClick={this.handleClick}>
                  <div className="btn-container">
                    <img src="/assets/bt-post-request.svg" alt="Post a new need" className="transform-scale" />
                    <IonText className="btn-text">
                      <Trans i18nKey="common.i-need" />
                    </IonText>
                  </div>
                </IonButton>
              </div>
              <div className="btn-wrapper btn-second">
                <IonButton className="icon-btn" fill="clear" routerLink="/posts/create/offer" onClick={this.handleClick}>
                  <div className="btn-container">
                    <img src="/assets/bt-post-offer.svg" alt="Post a new offer" className="transform-scale" />
                    <IonText className="btn-text">
                      <Trans i18nKey="common.i-offer" />
                    </IonText>
                  </div>
                </IonButton>
              </div>
            </div>
            <div className="btn-wrapper btn-wrapper--close close-fade-btn">
              <IonButton fill="clear" onClick={this.handleClick} className="close-btn">
                <img src="/assets/icon-close-popup.svg" alt="Close" />
              </IonButton>
            </div>
          </div>
        </IonModal>
      </>
    );
  }

  handleClick = (): void => {
    this.setState({ closeButtonImageUrl: closeButtonImageUrlClicked, triggerAnimation: false });
    setTimeout(() => {
      this.setState({
        triggerAnimation: true,
      });
      setStatusBarStyle();
      this.props.closeModal();
    }, 150);
  };
}

export default connect(mapStateToProps)(AddPostModal);
