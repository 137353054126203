import { AnyAction, Middleware } from 'redux';
import { actions, RootState } from '../index';
import * as blockedUsersActions from './actions';
import * as layoutActions from '../layout/actions';
import { ActionType, getType } from 'typesafe-actions';
import { ThunkResultDispatch } from '../types';
import { fetchBlockedUsers } from './actions';

export const blockMiddleware: Middleware<unknown, RootState> = store => next => (action: ActionType<typeof actions.app | AnyAction>) => {
  typeof action === 'function' ? action(next) : next(action);

  const dispatch = store.dispatch as ThunkResultDispatch;

  if (action.type === getType(blockedUsersActions.blockUserAction.success)) {
    dispatch(fetchBlockedUsers('currentUserBlockedCollection'));
    dispatch(layoutActions.setToastMessageAction('user.block-user-success'));
  }

  if (action.type === getType(blockedUsersActions.blockUserAction.failure)) {
    dispatch(layoutActions.setToastMessageAction('user.block-user-fail'));
  }

  if (action.type === getType(blockedUsersActions.unblockUserAction.success)) {
    dispatch(fetchBlockedUsers('currentUserBlockedCollection'));
    dispatch(layoutActions.setToastMessageAction('user.unblock-user-success'));
  }

  if (action.type === getType(blockedUsersActions.unblockUserAction.failure)) {
    dispatch(layoutActions.setToastMessageAction('user.unblock-user-fail'));
  }
};
