import { IonChip, IonIcon, IonLabel } from '@ionic/react';
import { filterSearchParamToQueryParam } from '../../store/posts/actions';
import SavedSearchOptionActionSheet from './SavedSearchOptionActionSheet';
import React, { useEffect, useState } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { actions, RootState } from '../../store';
import { getCurrentUser } from '../../store/app/selectors';
import { defaultSearchParams } from '../../store/posts/reducer';
import { getSavedSearchList, getSavedSearchListIsLoading, getSearchParams } from '../../store/posts/selectors';
import { SavedSearch, SearchQueryParameters } from '../../store/posts/types';
import { CurrentUser } from '../../store/users/types';
import { extractId, getSavedSearchWithSameFilter, savedSearchParamsAreEqual } from '../../utils/helpers';
import { EntityReference } from '../../utils/hydra';

// This is a client-side protection to limit the number of saved search by user
// TODO: apply it on server-side
const maxSavedSearchByUser = 12;

interface StateProps {
  savedSearchList?: SavedSearch[];
  savedSearchListIsLoading: boolean;
  currentUser: CurrentUser;
  searchParams: SearchQueryParameters;
}

const mapStateToProps = (state: RootState): StateProps => ({
  savedSearchList: getSavedSearchList(state),
  savedSearchListIsLoading: getSavedSearchListIsLoading(state),
  currentUser: getCurrentUser(state),
  searchParams: getSearchParams(state),
});

interface DispatchProps {
  createSavedSearch: (searchFilters: SearchQueryParameters) => Promise<void>;
  setToastMessage: (message: string) => void;
}

const propsToDispatch = {
  createSavedSearch: actions.posts.createSavedSearch,
  setToastMessage: actions.layout.setToastMessageAction,
};

interface SavedSearchOptionButtonsProps {
  onShareClick: () => void;
}

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type Props = StateProps & DispatchProps & SavedSearchOptionButtonsProps & WithTranslation;

const SavedSearchOptionButtons: React.FunctionComponent<Props> = (props: Props) => {
  const [showActionSheet, setShowActionSheet] = useState<boolean>(false);
  const [currentSavedSearchId, setCurrentSavedSearchId] = useState<EntityReference | undefined>(undefined);

  const createSavedSearch = (): void => {
    if ((props.savedSearchList || []).length >= maxSavedSearchByUser) {
      props.setToastMessage(props.t('search.too-many-saved-searches', { count: maxSavedSearchByUser }));
      return;
    }

    props.createSavedSearch(props.searchParams).catch(() => {
      // Prevent error
    });
  };

  const searchCanBeSaved = (): boolean => {
    // We allow only connected users to save searches
    if (!props.currentUser) {
      return false;
    }

    // We are only checking the important params to consider if we can save a search
    // The post type is not checked for that reason
    const searchParams = { ...filterSearchParamToQueryParam(props.searchParams), type: undefined };
    const defaultParams = { ...filterSearchParamToQueryParam(defaultSearchParams), type: undefined };
    return !savedSearchParamsAreEqual(searchParams, defaultParams);
  };

  useEffect(() => {
    if (props.savedSearchListIsLoading) {
      return;
    }

    const savedSearchWithSameFilters = getSavedSearchWithSameFilter(props.savedSearchList || [], props.searchParams);
    setCurrentSavedSearchId(savedSearchWithSameFilters ? extractId(savedSearchWithSameFilters, true) : undefined);
  }, [props.savedSearchListIsLoading, props.searchParams]);

  if (!searchCanBeSaved()) {
    return null;
  }

  return (
    <>
      {!currentSavedSearchId && (
        <IonChip data-cy="save-search-button" className="save-search-button warning-ion-chip" disabled={props.savedSearchListIsLoading} onClick={createSavedSearch}>
          <IonIcon src="/assets/icon/icon-last-search.svg" color="dark" />
          <IonLabel color="dark">
            <Trans i18nKey="search.create-saved-search" />
          </IonLabel>
        </IonChip>
      )}
      {currentSavedSearchId && (
        <>
          <IonChip
            data-cy="save-search-button"
            className="save-search-button warning-ion-chip-outline"
            disabled={props.savedSearchListIsLoading}
            color="warning"
            outline={true}
            onClick={() => setShowActionSheet(true)}
          >
            <IonIcon src="/assets/icon/icon-last-search.svg" color="dark" />
            <IonLabel color="dark">
              <Trans i18nKey="search.saved-search-options" />
            </IonLabel>
          </IonChip>
          <SavedSearchOptionActionSheet
            savedSearchId={currentSavedSearchId}
            isOpen={showActionSheet}
            closeActionSheet={() => setShowActionSheet(false)}
            onSavedSearchDelete={() => setCurrentSavedSearchId(undefined)}
            onShareClick={props.onShareClick}
          />
        </>
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SavedSearchOptionButtons));
