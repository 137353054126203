import React from 'react';
import { IonCol } from '@ionic/react';
import { useInView } from 'react-intersection-observer';
import { useMediaQuery } from 'react-responsive';
import { isMobileQuery } from '../utils/helpers';
import { deviceInfo, isTest } from '../environment';

type IonColInViewProps = React.ComponentProps<typeof IonCol> & {
  height: number;
};

export const InViewContext = React.createContext(false);

const IonColInView: React.FunctionComponent<IonColInViewProps> = ({ children, height, ...props }: IonColInViewProps) => {
  const isMobile = !isTest && useMediaQuery(isMobileQuery);
  // There is a weird render issue on old iOS devices.
  // TODO Fix it and remove this condition
  const isOldIOS = 'ios' === deviceInfo?.platform && parseInt(deviceInfo?.osVersion || '0') < 14;
  const removeHiddenItems = isMobile && !isOldIOS;
  const { ref, inView } = useInView({ threshold: 0, skip: !removeHiddenItems, initialInView: true });

  return (
    <IonCol {...props} ref={ref} style={{ height: `calc(${height}px + (calc(var(--ion-grid-column-padding, 5px) * 2)))` }}>
      {!removeHiddenItems || inView ? <InViewContext.Provider value={isMobile}>{children}</InViewContext.Provider> : null}
    </IonCol>
  );
};

export default IonColInView;
