import React, { ReactElement } from 'react';
import { IonItem } from '@ionic/react';
import { useHistory } from 'react-router';

import './RoutingItem.scss';

interface RoutingItemProps {
  route: string;
  children?: ReactElement;
}

const RoutingItem: React.FunctionComponent<RoutingItemProps> = ({ route, children }: RoutingItemProps) => {
  const history = useHistory();
  //Do not use rooterLink, it prevents the selection of text for the messageList
  return (
    <IonItem
      lines="none"
      button={false}
      detail={false}
      onClick={() => {
        history.push(route);
      }}
      className="routing-ion-item"
    >
      {children}
    </IonItem>
  );
};

export default React.memo(RoutingItem);
