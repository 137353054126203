import i18next from 'i18next';
import React from 'react';
import { IonButton, IonIcon, IonText } from '@ionic/react';
import { NotificationReason } from '../store/notifications/types';
import { extractId } from '../utils/helpers';
import { CurrentUser, CurrentUserEditFields } from '../store/users/types';
import { Trans } from 'react-i18next';
import { PushNotifications } from '../capacitor/PushNotifications';
import { Dialog } from '@capacitor/dialog';
import './TurnOnNotificationBanner.scss';

interface NotificationProps {
  updateUser(user: Partial<CurrentUserEditFields>): Promise<CurrentUser>;
  currentUser: CurrentUser;
  showNotificationBanner: (isOpen: boolean) => void;
  disabledNotifications: NotificationReason[];
  isChatPage: boolean;
  refreshPageData?: () => void;
}
type Props = NotificationProps;

const TurnOnNotificationBanner: React.FunctionComponent<Props> = ({ updateUser, currentUser, showNotificationBanner, disabledNotifications, isChatPage, refreshPageData }: Props) => {
  const turnOnNotifications = async (): Promise<void> => {
    const permission = await PushNotifications.requestPermissions();

    if (permission.receive !== 'granted') {
      await Dialog.alert({
        title: i18next.t('notification.allow'),
        message: i18next.t('notification.must-allow'),
      });

      return;
    }

    await updateUser({
      '@id': extractId(currentUser),
      allowPushNotifications: true,
      disabledPushNotifications: disabledNotifications.filter(item => item !== 'direct_chat_new_message'),
    });
    showNotificationBanner(false);
    //TODO find way to trigger requestPermission if user blocked notification permissions in web client
  };

  return (
    <div className="banner-wrapper" data-cy="banner-content-wrapper">
      <IonButton className="close-icon-button" onClick={() => showNotificationBanner(false)} fill="clear" color="light" size="small" data-cy="close-icon-button">
        <IonIcon icon="/assets/navigation/close.svg" className="close-icon" slot="icon-only" />
      </IonButton>
      <div className="title-wrapper">
        <IonText>{isChatPage ? <Trans i18nKey={'notification.turn-on-notification-chat-page-title'} /> : <Trans i18nKey={'notification.turn-on-notification-chats-page-title'} />}</IonText>
      </div>
      <hr style={{ marginBottom: 0 }} />
      <div className={isChatPage ? 'buttons-container divider' : 'buttons-container'}>
        {isChatPage && (
          <IonButton expand="full" onClick={refreshPageData} data-cy="turn-on-notifications-button">
            <Trans i18nKey={'chat.refresh-page'} />
          </IonButton>
        )}
        <IonButton expand="full" onClick={turnOnNotifications} data-cy="turn-on-notifications-button">
          <Trans i18nKey={'notification.turn-on'} />
        </IonButton>
      </div>
    </div>
  );
};

export default TurnOnNotificationBanner;
