import { IonButton, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonSegment, IonSegmentButton, IonText, IonTextarea, IonToggle, IonList, IonChip } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { getCategories } from '../store/categories/selectors';
import { getItemTranslation, translateDigits } from '../utils/translation';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { actions, RootState, selectors } from '../store';
import { EditablePost, FullPost, PostType, objectsUniverses, postCategoryTypes } from '../store/posts/types';
import { Category, Discriminant } from '../store/categories/types';
import { Formik, Form, FormikProps, FormikHelpers } from 'formik';
import InputMediaUploader, { PicturePlaceholderProps } from '../components/inputs/InputMediaUploader';
import { EntityReference } from '../utils/hydra';
import { locationIsPostCreatePage, locationPathnameIncludes, locationIsACreationPage } from '../utils/windowLocationHelper';
import { IconComponent } from './common/Icon';
import { FormValue, FormValues } from './DynamicForm';
import * as Yup from 'yup';
import AddressModal from '../components/AddressModal';
import {
  createObjectByDefaultKeys,
  extractId,
  getKeysAndValuesFromObject,
  getPromisedValueWithRetry,
  isEqualIgnoringFunctions,
  isSameHydraEntity,
  uploadAndValidateFiles,
  getWeightWithAppropriateUnit,
} from '../utils/helpers';
import {
  getCategoryParents,
  categoryWithSize,
  getSizeLabel,
  getCategoryItemsType,
  getCategoryWithMinAndMaxWeight,
  getCategoryDiscriminants,
  getCategoryAverageWeight,
} from '../utils/categoriesHelpers';
import i18next from 'i18next';
import CategoriesModal from '../components/CategoriesModal';
import ActionConfirmationAlert from './ActionConfirmationAlert';
import { CurrentUser } from '../store/users/types';
import AsyncImg from './AsyncImg';
import { Capacitor } from '@capacitor/core';
import { isTest } from '../environment';
import { getUserIsManagedAndNgo } from '../store/app/selectors';
import { Co2EstimationStep, maxEstimatedWeight, maxQuantity, minEstimatedWeight, minQuantity } from './PostCo2EstimationSteps';
import SizesModal, { SizeData } from './SizesModal';
import PostCo2EstimationModal from './PostCo2EstimationModal';

import './PostForm.scss';

interface State {
  addressModalIsOpen: boolean;
  initialValues: Partial<EditablePost>;
  isCategoryModalOpen: boolean;
  isConfirmationAlertOpen: boolean;
  isEstimationCo2ModalOpen: boolean;
  estimationSteps: Co2EstimationStep[];
  isSizeModalOpen: boolean;
  category: Category | null;
  type: string;
  categoryClicked: Category | undefined;
}

interface Props {
  type?: PostType;
  initialValues?: Partial<EditablePost>;
  submit: (post: FullPost) => Promise<EntityReference> | void;
  headerTitleKey?: string;
  submitButtonTextKey: string;
  editionMode?: boolean;
}

interface StateProps {
  categories: Category[];
  currentUser: CurrentUser;
  whoAmI: CurrentUser | null;
  authorIsNgoManagedUser: boolean;
  activeTabPreviousPathname: () => string | undefined;
}

const mapStateToProps = (state: RootState): StateProps => ({
  categories: getCategories(state),
  currentUser: state.app.currentUser,
  whoAmI: state.whoAmI.whoAmIUser ?? null,
  authorIsNgoManagedUser: getUserIsManagedAndNgo(state),
  activeTabPreviousPathname: () => selectors.navigation.activeTabPreviousPathname(state),
});

interface DispatchProps {
  fetchCategories(): void;
  setToastMessage(message: string | null): void;
  setLastCreatedPostIdAction(lastCreatedPostId: EntityReference | null): void;
}

const propsToDispatch = {
  fetchCategories: actions.categoriesActions.fetchPostCategories,
  setToastMessage: actions.layout.setToastMessageAction,
  setLastCreatedPostIdAction: actions.app.setLastCreatedPostIdAction,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type PostFormProps = RouteComponentProps & DispatchProps & WithTranslation & StateProps & Props;

const initialPostValues: Partial<EditablePost> = {
  title: '',
  description: '',
  categoryType: 'object',
  type: '',
  category: null,
  address: {
    formatted: '',
    locality: '',
    country: '',
    postalCode: '',
  },
  location: {
    latitude: null,
    longitude: null,
  },
  images: [],
  requestsLimit: 1,
  displayOnMapAccepted: false,
  universe: null,
  size: null,
  estimatedWeight: null,
  quantity: null,
  discriminants: [],
};

export const getPicturePlaceholder: React.FunctionComponent<PicturePlaceholderProps> = ({ imageSrc, deleteItem }: PicturePlaceholderProps) => (
  <>
    <AsyncImg alt="preview" src={imageSrc} className="preview" />
    <IonButton fill="clear" className="delete-icon delete-button" size="default" onClick={() => deleteItem()}>
      <IonIcon icon="/assets/form/close-circle-milkygray.svg" className="icon-medium-size" />
    </IonButton>
  </>
);

export const getAddPicturePlaceholder = (
  <>
    <IonButton className="add-photo-button" fill="clear">
      <img src="/assets/icon/icon-add-photo.svg" alt="add-icon" className="add-photo-button-big-img" />
      <img src="/assets/icon/icon-add-photo-error.svg" alt="add-icon-error" className="add-photo-button-big-img-error" />
      <span className="add-photo-button-small-img">+</span>
    </IonButton>
  </>
);

class PostForm extends Component<PostFormProps, State> {
  static constraints = {
    titleMinLength: 2,
    titleMaxLength: 140,
    descriptionMinLength: 3,
    descriptionMaxLength: 2000,
    requestsLimitMin: 1,
    requestsLimitMax: 1000,
  };

  postValidationFields = Yup.object({
    categoryType: Yup.string()
      .oneOf([...postCategoryTypes])
      .required(i18next.t('post.type-required')),
    title: Yup.string()
      .min(PostForm.constraints.titleMinLength, i18next.t('post.error-min', { min: PostForm.constraints.titleMinLength }))
      .max(PostForm.constraints.titleMaxLength, i18next.t('post.error-max', { max: PostForm.constraints.titleMaxLength }))
      .required(i18next.t('post.title-required')),
    description: Yup.string()
      .min(PostForm.constraints.descriptionMinLength, i18next.t('post.error-min', { min: PostForm.constraints.descriptionMinLength }))
      .max(PostForm.constraints.descriptionMaxLength, i18next.t('post.error-max', { max: PostForm.constraints.descriptionMaxLength }))
      .required(i18next.t('post.description-required')),
    quantity: Yup.number()
      .nullable()
      .max(maxQuantity, i18next.t('post.error-max-quantity', { max: maxQuantity }))
      .min(minQuantity, i18next.t('post.error-min-quantity', { min: minQuantity })),
    estimatedWeight: Yup.number()
      .nullable()
      .max(maxEstimatedWeight, i18next.t('post.error-max-weight', { max: maxEstimatedWeight }))
      .min(minEstimatedWeight, i18next.t('post.error-min-weight', { min: minEstimatedWeight })),
    images: Yup.array().when('categoryType', {
      is: 'object',
      then: Yup.array().when('type', {
        is: 'offer',
        then: Yup.array().required(i18next.t('post.images-required')),
      }),
    }),
    category: Yup.string().nullable().required(i18next.t('post.category-required')),
    location: Yup.object().shape({
      latitude: Yup.number().required(),
      longitude: Yup.number().required(),
    }),
    displayOnMapAccepted: Yup.boolean().required().equals([this.showShareMyLocation()]),
    universe: Yup.string().when('categoryType', {
      is: 'object',
      then: Yup.string()
        .oneOf([...objectsUniverses, null])
        .nullable(),
      otherwise: Yup.string().nullable(true).oneOf([null]),
    }),
    size: Yup.string().when('categoryType', {
      is: 'object',
      then: Yup.string().nullable(),
      otherwise: Yup.string().nullable(true).oneOf([null]),
    }),
  });

  validationSchemaWithParticipants = Yup.object({
    requestsLimit: Yup.number()
      .when('categoryType', {
        is: 'service',
        then: Yup.number().min(PostForm.constraints.requestsLimitMin, i18next.t('post.participants-error-min')).max(PostForm.constraints.requestsLimitMax, i18next.t('post.participants-error-max')),
      })
      .nullable(),
  });

  typeSelectFieldOptions = [
    {
      label: i18next.t('post.form-object'),
      value: 'object',
    },
    {
      label: i18next.t('post.form-service'),
      value: 'service',
    },
  ];

  private readonly ionContentRef: React.RefObject<HTMLIonContentElement>;
  private formikRef: FormikProps<Partial<EditablePost>> | null = null;

  constructor(props: PostFormProps) {
    super(props);

    const currentCategory = props.categories.find(category => category['@id'] === get(this.props.initialValues, 'category'));
    const type = props.type || get(props, 'initialValues.type', 'offer');
    this.ionContentRef = React.createRef<HTMLIonContentElement>();
    this.state = {
      addressModalIsOpen: false,
      isCategoryModalOpen: false,
      isConfirmationAlertOpen: false,
      isEstimationCo2ModalOpen: false,
      estimationSteps: [],
      isSizeModalOpen: false,
      initialValues: { ...this.getInitialFormValues(), type },
      category: currentCategory ?? null,
      type,
      categoryClicked: undefined,
    };
  }

  public componentDidMount(): void {
    if (!this.props.categories.length) {
      this.props.fetchCategories();
    }
  }

  public componentDidUpdate(prevProps: Readonly<PostFormProps>): void {
    // Reinitialize fields such as address & post type. For example when switching from /posts/create/offer to /posts/create/need
    if (locationIsPostCreatePage() && get(this.props, 'match.params.type') !== this.state.initialValues?.type) {
      this.setState({
        initialValues: {
          ...this.getInitialFormValues(),
          type: get(this.props, 'match.params.type'),
          address: this.state.initialValues.address,
          location: this.state.initialValues.location,
        },
      });
    }

    if (prevProps.initialValues === this.props.initialValues) {
      return;
    }

    /* This setState reinitialize the form. */
    const currentCategory = this.props.categories.find(category => isSameHydraEntity(category, get(this.props.initialValues, 'category')));
    this.setState({
      initialValues: {
        ...this.getInitialFormValues(),
        type: get(this.props, 'initialValues.type') || this.state.type,
        address: get(this.props, 'initialValues.address') || this.state.initialValues.address,
        location: get(this.props, 'initialValues.location') || this.state.initialValues.location,
      },
      category: currentCategory ?? null,
    });
  }

  public shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>): boolean {
    if (!(locationIsPostCreatePage() || (nextProps.initialValues && locationPathnameIncludes(extractId(nextProps.initialValues) + '/edit')))) {
      return false;
    }
    return !isEqualIgnoringFunctions(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  private getInitialFormValues = (): Partial<EditablePost> => {
    let initialValues = initialPostValues;

    if (this.props.initialValues) {
      initialValues = { ...createObjectByDefaultKeys(initialPostValues, this.props.initialValues), '@id': extractId(this.props.initialValues) };
    }

    if (this.props.currentUser.addressLocation?.latitude && !initialValues.address?.formatted) {
      initialValues.address = this.props.currentUser.address;
      initialValues.location = this.props.currentUser.addressLocation;
    }

    return initialValues;
  };

  public showShareMyLocation(): boolean {
    return Capacitor.getPlatform() === 'ios' || isTest;
  }

  public render(): ReactNode {
    const type = get(this.props, 'match.params.type');
    const { t, categories, headerTitleKey, setToastMessage, submitButtonTextKey, authorIsNgoManagedUser } = this.props;
    const { initialValues, category, addressModalIsOpen, isCategoryModalOpen, isConfirmationAlertOpen, isSizeModalOpen, categoryClicked } = this.state;
    const validationSchema =
      type === 'offer' || (authorIsNgoManagedUser && type === 'need') ? this.postValidationFields.concat(this.validationSchemaWithParticipants as never) : this.postValidationFields;

    return (
      <Formik
        innerRef={p => (this.formikRef = p)}
        initialValues={initialValues}
        onSubmit={(values: FormValues, actions) => {
          this.submitForm(values as unknown as FullPost, actions);
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(form: FormikProps<FormValues>) => {
          let fieldNumber = 0;
          const selectedCategory = this.props.categories.find(cat => cat['@id'] === form.values.category);
          const categoryWithMinAndMaxWeight = selectedCategory ? getCategoryWithMinAndMaxWeight(this.props.categories, selectedCategory) : undefined;
          const isCategoryWithMinAndMaxWeight = categoryWithMinAndMaxWeight?.itemMinWeight != null && categoryWithMinAndMaxWeight?.itemMaxWeight != null;
          const categoryDiscriminants = selectedCategory ? getCategoryDiscriminants(this.props.categories, selectedCategory) : [];
          const categoryAverageWeight = !!categoryWithMinAndMaxWeight && !form.values.estimatedWeight && form.values.quantity ? getCategoryAverageWeight(categoryWithMinAndMaxWeight) : undefined;

          const canEstimatePost = form.values.type === 'offer' && form.values.categoryType === 'object' && (isCategoryWithMinAndMaxWeight || !!categoryDiscriminants.length);

          return (
            <IonContent ref={this.ionContentRef} className="post-page">
              <div className="post-form">
                <h1>
                  <Trans i18nKey={headerTitleKey ?? `post.create-post-tile-${type || initialValues.type}`} />
                  <Link to="/" className="cancel" onClick={e => this.handleCloseLinkClick(e as React.MouseEvent<HTMLAnchorElement>, form)}>
                    <Trans i18nKey="common.cancel" />
                  </Link>
                </h1>
                <Form onSubmit={form.handleSubmit}>
                  <div className="items">
                    <IonItem mode="md" className={`without-border ${form.values.categoryType ? 'chosen-item' : ''} ${PostForm.isFieldInvalid(form, 'categoryType') ? 'ion-invalid' : ''}`}>
                      <div className="number">{translateDigits(++fieldNumber)}</div>
                      {/* Mode ios is necessary here because of the parent (IonItem) that is in mode="md" */}
                      <IonSegment id="categoryType" onIonChange={e => this.inputChanged(e, form)} value={form.values.categoryType} mode="ios">
                        <IonSegmentButton value="object">
                          <IonLabel>
                            <Trans i18nKey="post.form-object" />
                          </IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="service">
                          <IonLabel>
                            <Trans i18nKey="post.form-service" />
                          </IonLabel>
                        </IonSegmentButton>
                      </IonSegment>
                    </IonItem>

                    {PostForm.isFieldInvalid(form, 'categoryType') && this.getErrorMessage(form, 'categoryType')}

                    {form.values.categoryType === 'object' && form.values.type === 'offer' && (
                      <>
                        <IonItem
                          mode="md"
                          className={`without-border ${form.values.images.length > 0 ? 'chosen-item add-photo' : ''}  ${PostForm.isFieldInvalid(form, 'images') ? 'ion-invalid' : ''}`}
                        >
                          <IonLabel position="stacked" className="label-with-number" color={PostForm.isFieldInvalid(form, 'images') ? 'danger' : 'dark'}>
                            <div className="number">{translateDigits(++fieldNumber)}</div>
                            <Trans i18nKey="post.add-photos" />
                          </IonLabel>
                          <div className="add-photo-field" data-cy="upload-post-media">
                            <InputMediaUploader
                              value={form.values.images}
                              maxFiles={5}
                              multiple
                              isMediaUploaderField
                              onError={(message: string, options?: Record<string, unknown>) => setToastMessage(t(message, options))}
                              onValueChange={values => PostForm.setUploadedFiles(values, form)}
                              picturePlaceholder={getPicturePlaceholder}
                              addPicturePlaceholder={getAddPicturePlaceholder}
                            />
                          </div>
                        </IonItem>
                        {PostForm.isFieldInvalid(form, 'images') && this.getErrorMessage(form, 'images')}
                      </>
                    )}

                    <IonItem
                      mode="md"
                      data-cy="input-title"
                      className={`ion-item-with-margin ${form.values.title && !form.errors.title ? 'chosen-item' : ''} ${PostForm.isFieldInvalid(form, 'title') ? 'ion-invalid' : ''}`}
                    >
                      <IonLabel position="floating" className="label-with-number" color={PostForm.isFieldInvalid(form, 'title') ? 'danger' : 'dark'}>
                        <Trans i18nKey="post.title" />
                      </IonLabel>
                      <IonInput
                        className="post-form-input"
                        value={form.values.title}
                        name="title"
                        placeholder={
                          (form.values.type === 'need' && !authorIsNgoManagedUser && form.values.categoryType && t(`post.request-example-title-${form.values.categoryType}`)) ||
                          (form.values.type === 'need' && authorIsNgoManagedUser && t('post.we-need-volunteers')) ||
                          (form.values.type === 'offer' && !authorIsNgoManagedUser && form.values.categoryType && t(`post.example-title-${form.values.categoryType}`)) ||
                          ''
                        }
                        onIonChange={e => this.inputChanged(e, form)}
                        onIonBlur={e => PostForm.handleBlur(e, form, 'title')}
                        onIonFocus={PostForm.handleFocus}
                        autocapitalize="on"
                        autocorrect="on"
                        data-cy="title"
                      />
                      <div className="number digit-number-with-margin">{translateDigits(++fieldNumber)}</div>
                    </IonItem>
                    {PostForm.isFieldInvalid(form, 'title') && this.getErrorMessage(form, 'title')}

                    <IonItem
                      mode="md"
                      data-cy="textarea-description"
                      className={`ion-item-with-margin textarea-item ${form.values.description && !form.errors.description ? 'chosen-item' : ''} ${
                        PostForm.isFieldInvalid(form, 'description') ? 'ion-invalid' : ''
                      }`}
                    >
                      <IonLabel position="floating" className="textarea-label label-with-number" color={PostForm.isFieldInvalid(form, 'description') ? 'danger' : 'dark'}>
                        <Trans i18nKey="post.description" />
                      </IonLabel>
                      <IonTextarea
                        className="post-form-input textarea"
                        value={form.values.description}
                        name="description"
                        placeholder={
                          (form.values.type === 'need' && !authorIsNgoManagedUser && form.values.categoryType && t(`post.request-example-description-${form.values.categoryType}`)) ||
                          (form.values.type === 'need' && authorIsNgoManagedUser && t('post.we-are-preparing-an-event')) ||
                          (form.values.type === 'offer' && !authorIsNgoManagedUser && form.values.categoryType && t(`post.example-description-${form.values.categoryType}`)) ||
                          ''
                        }
                        onIonChange={e => this.inputChanged(e, form)}
                        onIonBlur={e => PostForm.handleBlur(e, form, 'description')}
                        onIonFocus={PostForm.handleFocus}
                        rows={5}
                        wrap="off"
                        autocapitalize="on"
                      />
                      <div className="number digit-number-with-margin digit-number-textarea">{translateDigits(++fieldNumber)}</div>
                    </IonItem>

                    {PostForm.isFieldInvalid(form, 'description') && this.getErrorMessage(form, 'description')}

                    {!category ? this.categorySelection(form, ++fieldNumber) : this.categorySelected(category, ++fieldNumber)}
                    {PostForm.isFieldInvalid(form, 'category') && this.getErrorMessage(form, 'category')}

                    {categoryWithSize(categories, category) && (
                      <IonItem
                        data-cy="toggle-sizes-modal-btn"
                        className={`ion-item-with-margin selection-item ${form.values.size ? 'chosen-item' : ''} ${
                          PostForm.isFieldInvalid(form, 'size') || PostForm.isFieldInvalid(form, 'universe') ? 'ion-invalid' : ''
                        }`}
                        onClick={() => this.setState({ isSizeModalOpen: true })}
                        mode="md"
                      >
                        <IonLabel
                          className={`label-with-number ${form.values.size ? 'item-has-value' : ''}`}
                          color={PostForm.isFieldInvalid(form, 'size') || PostForm.isFieldInvalid(form, 'universe') ? 'danger' : 'dark'}
                          position="floating"
                        >
                          <Trans i18nKey="sizes.select-size" />
                        </IonLabel>
                        <span className="value-label">{category && getSizeLabel(form.values, categories, category)}</span>
                        <IonButton className="arrow-forward-button" fill="clear" color="blue" slot="end">
                          <IonIcon icon="/assets/navigation/ellipse_outline.svg" />
                        </IonButton>
                        <div className="number digit-number-with-margin">{translateDigits(++fieldNumber)}</div>
                      </IonItem>
                    )}
                    {PostForm.isFieldInvalid(form, 'size') && this.getErrorMessage(form, 'size')}
                    {PostForm.isFieldInvalid(form, 'universe') && this.getErrorMessage(form, 'universe')}

                    <IonItem
                      data-cy="toggle-address-modal-btn"
                      className={`ion-item-with-margin selection-item ${form.values.location.latitude && form.values.location.longitude && form.values.address?.formatted ? 'chosen-item' : ''} ${
                        PostForm.isFieldInvalid(form, 'location') ? 'ion-invalid' : ''
                      }`}
                      onClick={() => this.toggleAddressModal(true)}
                      mode="md"
                    >
                      <IonLabel
                        className={`label-with-number ${form.values.address?.formatted ? 'item-has-value' : ''}`}
                        color={PostForm.isFieldInvalid(form, 'location') ? 'danger' : 'dark'}
                        position="floating"
                      >
                        <Trans i18nKey="user.address" /> *
                      </IonLabel>
                      <span className="value-label">{form.values.address?.formatted || ''}</span>
                      <div className="number digit-number-with-margin">{translateDigits(++fieldNumber)}</div>
                    </IonItem>
                    {PostForm.isFieldInvalid(form, 'location') && this.getErrorMessage(form, 'location')}

                    {/*If platform is ios or test mode, display share location validation */}
                    {this.showShareMyLocation() && (
                      <IonItem className="share-location-item" detail={false} lines="none" data-cy="share-location-toggle-item">
                        <IonToggle
                          name="displayOnMapAccepted"
                          color="medium"
                          checked={form.values.displayOnMapAccepted}
                          onIonChange={() => form.setFieldValue('displayOnMapAccepted', !form.values.displayOnMapAccepted)}
                        />
                        <IonText className={`share-location-text ${PostForm.isFieldInvalid(form, 'displayOnMapAccepted') ? 'danger' : ''}`}>
                          <Trans i18nKey="post.share-location" components={[<em key="share-location" />]} />
                        </IonText>
                      </IonItem>
                    )}

                    {/*If type of post is offer and category type is service or
                    if post creator is managed user (e.g. not current user) and post type is request (need) it needs to show this addition field*/}
                    {((form.values.categoryType === 'service' && form.values.type === 'offer') ||
                      (form.values.categoryType === 'service' && authorIsNgoManagedUser && form.values.type === 'need')) && (
                      <>
                        <IonItem
                          mode="md"
                          className={`ion-item-with-margin ${form.values.requestsLimit && !form.errors.requestsLimit ? 'chosen-item' : ''} ${
                            PostForm.isFieldInvalid(form, 'requestsLimit') ? 'ion-invalid' : ''
                          }`}
                        >
                          <IonLabel color={PostForm.isFieldInvalid(form, 'requestsLimit') ? 'danger' : 'dark'}>
                            <Trans i18nKey={!authorIsNgoManagedUser ? 'post.participants' : 'post.how-many-people-your-need'} />
                          </IonLabel>

                          <IonInput
                            value={form.values.requestsLimit}
                            className="requestsLimit"
                            name="requestsLimit"
                            onIonChange={e => this.inputChanged(e, form)}
                            type="number"
                            inputmode="decimal"
                            onIonBlur={e => PostForm.handleBlur(e, form, 'requestsLimit')}
                            onIonFocus={PostForm.selectAll}
                          />
                          <div className="number digit-number-with-margin mt-10">{translateDigits(++fieldNumber)}</div>
                        </IonItem>
                        {PostForm.isFieldInvalid(form, 'requestsLimit') && this.getErrorMessage(form, 'requestsLimit')}
                      </>
                    )}

                    {this.props.editionMode && canEstimatePost && (
                      <>
                        <div className="container-co2-estimation">
                          <div className="mascot-edit-post ">
                            <img src="assets/mascot/search.svg" alt="Indigo" />
                          </div>
                          <div className="container-co2-estimation-text">
                            <div className="green-title">
                              <Trans i18nKey="co2-estimation.evaluate-emission-avoid" />
                            </div>
                            <div>
                              <Trans i18nKey="co2-estimation.calculate-environmental-impact" />
                            </div>
                          </div>
                        </div>

                        {isCategoryWithMinAndMaxWeight && (
                          <>
                            <IonItem
                              data-cy="toggle-quantity-modal-btn"
                              className={`ion-item-with-margin textarea-item selection-item ${form.values.quantity ? 'chosen-item' : ''}`}
                              onClick={() => this.openEstimationModal(['weight'])}
                              mode="md"
                            >
                              <IonLabel className={`label-with-number ${form.values.quantity ? 'item-has-value' : ''}`} color="dark" position="floating">
                                <Trans i18nKey="post.number-of-objects" />
                              </IonLabel>
                              <span className="value-label">{form.values.quantity}</span>
                              <IonButton className="arrow-forward-button" fill="clear" color="blue" slot="end">
                                <IonIcon icon="/assets/navigation/ellipse_outline.svg" />
                              </IonButton>
                              <div className="number digit-number-with-margin">{translateDigits(++fieldNumber)}</div>
                            </IonItem>
                            {PostForm.isFieldInvalid(form, 'quantity') && this.getErrorMessage(form, 'quantity')}

                            <IonItem
                              data-cy="toggle-weight-modal-btn"
                              className={`ion-item-with-margin textarea-item selection-item ${form.values.estimatedWeight || categoryAverageWeight ? 'chosen-item' : ''}`}
                              onClick={() => this.openEstimationModal(['weight'])}
                              mode="md"
                            >
                              <IonLabel className={`label-with-number ${form.values.estimatedWeight || categoryAverageWeight ? 'item-has-value' : ''}`} color="dark" position="floating">
                                <Trans i18nKey="post.object-weight" />
                              </IonLabel>
                              <span className="value-label">
                                {form.values.estimatedWeight || categoryAverageWeight ? getWeightWithAppropriateUnit(form.values.estimatedWeight ?? categoryAverageWeight) : ''}
                              </span>
                              <IonButton className="arrow-forward-button" fill="clear" color="blue" slot="end">
                                <IonIcon icon="/assets/navigation/ellipse_outline.svg" />
                              </IonButton>
                              <div className="number digit-number-with-margin">{translateDigits(++fieldNumber)}</div>
                            </IonItem>
                            {PostForm.isFieldInvalid(form, 'estimatedWeight') && this.getErrorMessage(form, 'estimatedWeight')}
                          </>
                        )}
                        {!!categoryDiscriminants.length && (
                          <>
                            <IonItem
                              data-cy="toggle-discriminant-modal-btn"
                              className={`ion-item-with-margin textarea-item chip-selection-item cursor-pointer ${form.values.discriminants?.length ? 'chosen-item' : ''}`}
                              onClick={() => this.openEstimationModal(['discriminants'])}
                              mode="md"
                              lines={form.values.discriminants?.length ? 'none' : 'full'}
                            >
                              <IonLabel className="label-with-number not-floating-label" color="dark">
                                <Trans i18nKey="post.object-discriminants" />
                              </IonLabel>
                              <IonButton className="arrow-forward-button" fill="clear" color="blue" slot="end">
                                <IonIcon icon="/assets/navigation/ellipse_outline.svg" />
                              </IonButton>
                              <div className="number digit-number-with-margin">{translateDigits(++fieldNumber)}</div>
                            </IonItem>
                            {!!form.values.discriminants?.length && (
                              <div className="discriminant-list cursor-pointer" onClick={() => this.openEstimationModal(['discriminants'])}>
                                {form.values.discriminants.map((discId: EntityReference) => {
                                  const discriminant = categoryDiscriminants.find(disc => isSameHydraEntity(disc, discId));
                                  if (!discriminant) {
                                    return;
                                  }
                                  return <IonChip key={discriminant.name}>{getItemTranslation(discriminant, discriminant.name)}</IonChip>;
                                })}
                              </div>
                            )}
                            {PostForm.isFieldInvalid(form, 'discriminants') && this.getErrorMessage(form, 'discriminants')}
                          </>
                        )}
                      </>
                    )}
                  </div>

                  <div className="submit-button">
                    {/* We change the type of the button to avoid the creation of multiple posts by multi-click */}
                    <IonButton type={form.isSubmitting || form.isValidating ? 'button' : 'submit'} shape="round" disabled={form.isSubmitting || form.isValidating}>
                      {form.isSubmitting || form.isValidating ? t('common.loading') : t(submitButtonTextKey)}
                    </IonButton>
                  </div>
                  <AddressModal
                    isOpen={addressModalIsOpen}
                    onClose={() => {
                      this.toggleAddressModal(false);
                      form.setFieldTouched('location');
                    }}
                    onAddressChange={value => this.onAddressChange(value, form)}
                  />
                  <CategoriesModal
                    categoryClicked={categoryClicked}
                    setNewCategory={(category: Category) => this.setCategory(category, form)}
                    categories={categories.filter(category => !form.values.categoryType || category.type === form.values.categoryType)}
                    isCategoryModalOpen={isCategoryModalOpen}
                    closeCategoriesModal={() => {
                      this.setState({ isCategoryModalOpen: false });
                      form.setFieldTouched('category');
                    }}
                  />
                  <SizesModal
                    onSelectionSubmit={(newSize?: SizeData) => {
                      form.setFieldValue('size', newSize?.size || null);
                      form.setFieldValue('universe', newSize?.universe || null);
                    }}
                    isOpen={isSizeModalOpen}
                    onCloseModal={() => {
                      this.setState({ isSizeModalOpen: false });
                      form.setFieldTouched('size');
                      form.setFieldTouched('universe');
                    }}
                    categoryItemsType={getCategoryItemsType(categories, category)}
                    defaultUniverse={form.values.universe}
                  />
                  <PostCo2EstimationModal
                    estimatedWeightProps={form.values.estimatedWeight}
                    quantityProps={form.values.quantity}
                    isOpen={this.state.isEstimationCo2ModalOpen}
                    updatePost={(quantity?: number | null, weight?: number | null, discriminants?: Discriminant[]) => this.updatePost(form, quantity, weight, discriminants)}
                    category={categoryWithMinAndMaxWeight}
                    onCloseModal={() => {
                      this.setState({ isEstimationCo2ModalOpen: false });
                    }}
                    discriminants={categoryDiscriminants}
                    discriminantsProps={form.values.discriminants}
                    steps={this.state.estimationSteps}
                  />

                  <ActionConfirmationAlert isAlertActive={isConfirmationAlertOpen} closeAlert={() => this.keepPostForm()} discardChanges={() => this.closePostForm()} />
                </Form>
              </div>
            </IonContent>
          );
        }}
      </Formik>
    );
  }

  private updatePost = (form: FormikProps<FormValues>, quantity?: number | null, estimatedWeight?: number | null, discriminants?: Discriminant[]): void => {
    if (quantity !== undefined) {
      form.setFieldValue('quantity', parseInt((quantity || 0).toString()));
    }

    if (estimatedWeight !== undefined) {
      form.setFieldValue('estimatedWeight', estimatedWeight);
    }

    if (discriminants !== undefined) {
      form.setFieldValue('discriminants', discriminants);
    }

    this.setState({ isEstimationCo2ModalOpen: false });
  };

  private openEstimationModal = (estimationSteps: Co2EstimationStep[]): void => {
    this.setState({ isEstimationCo2ModalOpen: true, estimationSteps });
  };

  private categorySelection = (form: FormikProps<FormValues>, fieldNumber: number): ReactNode => {
    if (!form.values.categoryType) {
      return <></>;
    }

    return (
      <IonItem
        className={`ion-item-with-margin item-category ${form.values.category ? 'chosen-item' : ''} ${PostForm.isFieldInvalid(form, 'category') ? 'ion-invalid' : 'common-margin'}`}
        onClick={() => this.setState({ isCategoryModalOpen: true })}
        data-cy="toggle-category-modal-btn"
        mode="md"
      >
        <IonLabel className="label-with-number" color={PostForm.isFieldInvalid(form, 'category') ? 'danger' : 'dark'}>
          <span className="category-label">
            <Trans i18nKey="post.category" />
          </span>
        </IonLabel>
        <IonInput
          hidden
          name="category"
          onIonChange={form.handleChange}
          onIonBlur={e => PostForm.handleBlur(e, form, 'category')}
          value={form.values.category}
          spellCheck={true}
          autoCorrect="on"
          autocomplete="on"
        />
        <IonButton className="arrow-forward-button" fill="clear">
          <IonIcon icon="/assets/navigation/chevron-forward.svg" />
        </IonButton>
        <div className="number digit-number-with-margin mt-10">{translateDigits(fieldNumber)}</div>
      </IonItem>
    );
  };

  private categorySelected = (category: Category, fieldNumber: number): ReactNode => {
    const categories = getCategoryParents(this.props.categories, category).reverse();
    return (
      <IonList className="list-category-selected" data-cy="selected-categories-list">
        <IonItem className="selected-title" mode="md" lines="none">
          <div className="selected-title-text">
            <Trans i18nKey="post.category" />
          </div>
        </IonItem>
        {categories.map((category: Category, index) => (
          <IonItem
            onClick={() => this.setState({ isCategoryModalOpen: true, categoryClicked: this.props.categories.find(cat => isSameHydraEntity(cat, category.parent)) })}
            mode="md"
            key={index}
            className="selected"
            data-cy="selected-category-item"
          >
            <IonLabel color="dark">
              <div className="selected-icon">{category.icon ? <IconComponent icon={category.icon} /> : <img className="arrow-selected" alt="arrow" src="/assets/icon/arrow-selected.svg" />}</div>
              <div className="selected-text">{getItemTranslation(category, category.name)}</div>
            </IonLabel>
            <IonButton className="arrow-forward-button arrow-forward-button-selected" fill="clear">
              <IonIcon icon="/assets/navigation/chevron-forward.svg" />
            </IonButton>
            {index === 0 && <div className="number digit-number-selected">{translateDigits(fieldNumber)}</div>}
          </IonItem>
        ))}
      </IonList>
    );
  };

  private setCategory(category: Category | null, form: FormikProps<FormValues>): void {
    if (isSameHydraEntity(category, form.values.category)) {
      return;
    }

    if (getCategoryItemsType(this.props.categories, category) !== getCategoryItemsType(this.props.categories, this.state.category)) {
      form.setFieldValue('universe', null);
      form.setFieldValue('size', null);
    }

    form.setFieldValue('discriminants', []);
    form.setFieldValue('category', category ? extractId(category) : null);
    this.setState({ category, categoryClicked: undefined });
  }

  private exitPostForm = (): void => {
    if (this.props.history.location.pathname.indexOf('/edit') >= 0) {
      const path = this.props.history.location.pathname.replace('/edit', '');
      this.props.history.push(path, { tabDirection: 'back', direction: 'back' });
      return;
    }

    const activeTabPreviousPathname = this.props.activeTabPreviousPathname();
    if (activeTabPreviousPathname) {
      this.props.history.push(activeTabPreviousPathname, { tabDirection: 'back', direction: 'back' });
      return;
    }

    this.props.history.push('/posts', { tabDirection: 'back', direction: 'back' });
  };

  private handleCloseLinkClick(e: React.MouseEvent<HTMLAnchorElement>, form: FormikProps<FormValues>): void {
    if (typeof this === 'undefined') {
      return; // TODO Never call a function if the component does not exist anymore
    }

    e.preventDefault();
    if (form.dirty && Object.keys(form.touched).length !== 0) {
      this.setState({ isConfirmationAlertOpen: true });
      return;
    }

    this.exitPostForm();
  }

  private keepPostForm(): void {
    this.setState({ isConfirmationAlertOpen: false });
  }

  private closePostForm(): void {
    this.setState({ isConfirmationAlertOpen: false });

    this.exitPostForm();
  }

  private onAddressChange = (value: FormValue, form: FormikProps<FormValues>): void => {
    if (!value) {
      form.setFieldValue('address', initialPostValues.address);
      form.setFieldValue('location', initialPostValues.location);
      form.setStatus({ 'address.formatted': i18next.t('post.must-select-address') });
      return;
    }

    getKeysAndValuesFromObject(value).forEach(keyValue => {
      form.setFieldValue(keyValue.key, keyValue.value);
    });
    form.setStatus({ 'address.formatted': undefined });
  };

  private toggleAddressModal(value: boolean): void {
    this.setState({ addressModalIsOpen: value });
  }

  private static setUploadedFiles(values: FormValues, form: FormikProps<FormValues>): void {
    form.setFieldValue('images', values);
  }

  private static handleBlur(e: CustomEvent, form: FormikProps<FormValues>, name: string): void {
    form.setFieldTouched(name, e.returnValue);
  }

  private static handleFocus(e?: CustomEvent): void {
    if (e && e.target) {
      const ionInputElement = e.target as HTMLIonInputElement;
      if (ionInputElement.tagName !== 'ION-INPUT') {
        return;
      }

      ionInputElement
        .getInputElement()
        .then((input: HTMLInputElement) => {
          input.selectionStart = input.selectionEnd = input.value.length;
        })
        .catch();
    }
  }

  private static async selectAll(e?: CustomEvent): Promise<void> {
    if (e && e.target) {
      const ionInput = e.target as HTMLIonInputElement;
      const inputEl = await getPromisedValueWithRetry<HTMLInputElement>(ionInput.getInputElement.bind(ionInput));
      inputEl.select();
    }
  }

  private inputChanged(e: CustomEvent, form: FormikProps<FormValues>): void {
    form.handleChange(e);
    const name = get(e, 'target.name', '') as string;
    const id = get(e, 'target.id', '') as string;

    if (name === 'categoryType' || id === 'categoryType') {
      if (this.state.category && get(e, 'target.value') !== this.state.category.type) {
        this.setCategory(null, form);
      }

      if (get(e, 'target.value') === 'service' && !form.values.service) {
        form.setFieldValue('requestsLimit', 1);
        form.setFieldValue('discriminants', []);
        form.setFieldValue('quantity', null);
        form.setFieldValue('estimatedWeight', null);
      }
    }
    if (form.status && form.status[name]) {
      delete form.status[name];
    }
  }

  private static isFieldInvalid(form: FormikProps<FormValues>, name: string): boolean {
    return (form.status && form.status[name]) || (form.errors[name] && form.touched[name]);
  }

  private scrollToTop = (): void => {
    if (!this.ionContentRef.current) {
      return;
    }

    this.ionContentRef.current.scrollToTop(200);
  };

  private static handlePostFormErrors(actions: FormikHelpers<Partial<EditablePost>>, errors: Record<string, string>): void {
    if (!errors) {
      return;
    }
    if (errors['location.latitude'] || errors['location.longitude']) {
      actions.setStatus({ 'address.formatted': i18next.t('post.must-select-address') });
    }
    if (errors['address.locality']) {
      actions.setStatus({ 'address.formatted': i18next.t('post.locality-required') });
    }
    actions.setStatus(errors);
  }

  private submitForm = async (post: FullPost, actions: FormikHelpers<Partial<EditablePost>>): Promise<void> => {
    if (post.categoryType === 'service') {
      post.images = [];
    }
    if (post.categoryType === 'object' || (post.type === 'need' && !this.props.authorIsNgoManagedUser)) {
      post.requestsLimit = null;
    }

    // TODO Do the same for all empty number input
    if (typeof (post.requestsLimit as never) === 'string' && (post.requestsLimit as never) === '') {
      post.requestsLimit = null;
    }
    if (typeof (post.quantity as never) === 'string' && (post.quantity as never) === '') {
      post.quantity = null;
    }
    if (typeof (post.estimatedWeight as never) === 'string' && (post.estimatedWeight as never) === '') {
      post.estimatedWeight = null;
    }

    try {
      await uploadAndValidateFiles(post, actions, 'images', this.props.t);
    } catch (e) {
      this.scrollToTop();
      actions.setSubmitting(false);
      return;
    }
    try {
      const id = await this.props.submit(post);
      actions.resetForm();
      this.setState({ category: null });
      if (locationIsACreationPage()) {
        this.props.setLastCreatedPostIdAction(id as string);
        this.props.history.push(id || '/posts', { prevPath: this.props.location.pathname });
      } else {
        this.exitPostForm();
      }
    } catch (e) {
      this.scrollToTop();
      PostForm.handlePostFormErrors(actions, e.errors);
    }
    actions.setSubmitting(false);
  };

  private getErrorMessage(form: FormikProps<FormValues>, name: string): ReactNode {
    const formStatus: string = form.status?.[name];
    const originalError: string | string[] = (form.errors?.[name] || []) as string | string[];
    let errors: string[] = Array.isArray(originalError) ? originalError : [originalError];

    if (!errors.length && !formStatus) {
      return;
    }

    if (name === 'location') {
      errors = [this.props.t('post.location-required')];
    }

    // TODO make shared component for text errors
    return (
      <>
        {errors.map(error => (
          <IonText key={error} className="error" color="danger">
            {error}
          </IonText>
        ))}
        {formStatus && (
          <IonText className="error" color="danger">
            {this.props.t(formStatus)}
          </IonText>
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(PostForm)));
