import { registerPlugin } from '@capacitor/core';
import type { PushNotificationsPlugin } from '@capacitor/push-notifications';

export * from '@capacitor/push-notifications/dist/esm/definitions';

// This file replaces the @capacitor/push-notifications import, and adds the PWA / WEB push notifications support
export const PushNotifications = registerPlugin<PushNotificationsPlugin>('PushNotifications', {
  web: () => import('./PushNotificationsPluginWeb').then(m => new m.PushNotificationsPluginWeb()),
});

PushNotifications.addListener('registration', () => {
  // This listener must be here in order to allow other listeners to be registered after
  console.debug('Push notifications registration done');
});
