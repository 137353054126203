import React from 'react';
import { IonButton, IonModal } from '@ionic/react';

import './IndigoDownModal.scss';
import { IndigoFermetureData } from './store/app/types';
import AppStore from './AppStore';
import AndroidStore from './AndroidStore';

interface IndigoDownModalProps {
  data: IndigoFermetureData;
}

const IndigoDownModal: React.FC<IndigoDownModalProps> = ({ data }) => {
  return (
    <IonModal isOpen backdropDismiss={false}>
      <div className="indigo-down-modal-container">
        <div className="indigo-down-modal-content">
          <img src={data.data.image} alt="Indigo Devient Donnons" />
          <h1>{data.data.title}</h1>
          <p>{data.data.text}</p>
          <div>
            {data.data.buttons.ios && (
              <a href={data.data.buttons.ios}>
                <AppStore />
              </a>
            )}
            {data.data.buttons.android && (
              <a href={data.data.buttons.android}>
                <AndroidStore />
              </a>
            )}
          </div>
        </div>
        <div>
          <a href={data.data.buttons.cta.href}>
            <IonButton>{data.data.buttons.cta.text}</IonButton>
          </a>
        </div>
      </div>
    </IonModal>
  );
};

export default IndigoDownModal;
