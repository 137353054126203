import React, { ReactNode } from 'react';
import { IonButton, IonContent, IonModal, IonHeader, IonToolbar, IonButtons, IonThumbnail, IonImg, IonText, IonIcon } from '@ionic/react';
import { GlobalNotificationData, UserNotification } from '../store/notifications/types';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import { getTargetBlankLink } from '../utils/markdownHelper';
import ImageFadeIn from './ImageFadeIn';
import { messageNotificationModal } from '../utils/helpers';
import { useTranslation, withTranslation } from 'react-i18next';

import './NotificationModal.scss';

interface Props {
  isOpen: boolean;
  userNotification: UserNotification | null;
  onClose: () => void;
}

interface ModalContentProps {
  userNotification: UserNotification | null;
  onClose: () => void;
}

interface notificationModalProps {
  userNotification: UserNotification;
}

export const NotificationContent: React.FunctionComponent<notificationModalProps> = ({ userNotification }: notificationModalProps) => {
  const { t } = useTranslation();
  return (
    <IonContent className="notification-content">
      <div className="content-wrap">
        {(userNotification.notification.data as GlobalNotificationData).imageUrl && (
          <ImageFadeIn className="modal-content-image" src={(userNotification.notification.data as GlobalNotificationData).imageUrl} alt="notification" data-cy="notification-image" />
        )}

        <div className="notification-description" data-cy="notification-description">
          <ReactMarkdown components={{ a: getTargetBlankLink }}>{messageNotificationModal(userNotification, t)}</ReactMarkdown>
        </div>
      </div>
    </IonContent>
  );
};

const ModalContent: React.FunctionComponent<ModalContentProps> = ({ userNotification, onClose }: ModalContentProps) => {
  if (null == userNotification) {
    return <></>;
  }
  return (
    <>
      <IonHeader translucent no-border>
        <IonToolbar className="notification-toolbar">
          <div className="notification-toolbar-date-wrapper">
            <IonThumbnail className="date-logo">
              <IonImg src="/assets/logo-indigo-gradient.svg" alt="Indigo" />
            </IonThumbnail>
            <IonText color="dark" className="notification-date-title">
              Indigo World
            </IonText>
            <IonText color="dark" className="notification-date-title">
              &bull;
            </IonText>
            <IonText color="medium" className="date">
              {moment(new Date(userNotification.notification.updatedAt)).format('DD MMMM YYYY')}
            </IonText>
          </div>
          <IonButtons slot="end">
            <IonButton data-cy="notification-toolbar-close-button" onClick={onClose}>
              <IonIcon className="close-modal-button" icon="/assets/navigation/close.svg" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <NotificationContent userNotification={userNotification} />
    </>
  );
};

class NotificationModal extends React.Component<Props> {
  public render(): ReactNode {
    return (
      <IonModal isOpen={this.props.isOpen} onDidDismiss={this.props.onClose} data-cy="notification-modal" className="safe-area-ios--large-screen global-notification-modal">
        <ModalContent userNotification={this.props.userNotification} onClose={this.props.onClose} />
      </IonModal>
    );
  }
}

export default withTranslation()(NotificationModal);
