import React, { useState } from 'react';
import { IonButton, IonIcon, IonImg, IonPopover } from '@ionic/react';
import { Trans, useTranslation } from 'react-i18next';
import { CurrentUser, CurrentUserEditFields, defaultPictures, Preferences } from '../store/users/types';
import { actions, RootState } from '../store';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import UserForm, { addProfilePicturePlaceholder, profilePicturePlaceholder, UserFormValues } from './UserForm';
import { FormikHelpers, FormikProps } from 'formik';
import { FormValues } from './DynamicForm';
import i18next from 'i18next';
import { defaultEmptyEmailDomain, extractId, uploadAndValidateFiles, userTosAreAccepted, userTosUpToDate } from '../utils/helpers';
import InputMediaUploader from './inputs/InputMediaUploader';
import { AppVersions } from '../store/app/types';
import has from 'lodash/has';

import './UpdateUserMissingDataModal.scss';

type modalType =
  | 'EditPasswordModal'
  | 'AddLastTosAcceptedDateModal'
  | 'EditLastTosAcceptedDateModal'
  | 'EditAddressModal'
  | 'EditAvatarModal'
  | 'EditNewsletterSubscribedModal'
  | 'EditUserNameModal'
  | 'EditEmailModal';

interface StateProps {
  currentUser: CurrentUser;
  minAppVersions?: AppVersions;
}

export interface UpdateUserMissingDataModalProps {
  modalType?: modalType;
  toastMessage?: string;
}

interface UpdateUserMissingDataOptions {
  minAppVersions?: AppVersions;
}

export interface WelcomeEditModalProps {
  isOpen: boolean;
  closeWelcomeEditModal(): void;
}

const mapStateToProps = (state: RootState): StateProps => ({
  currentUser: state.app.currentUser,
  minAppVersions: state.app.minAppVersions,
});

interface DispatchProps {
  updateUser(user: Partial<CurrentUserEditFields>): Promise<CurrentUser>;
  addUserPreferences(preferences: Preferences): Promise<CurrentUser>;
  setToastMessage(message: string | null): void;
}

const propsToDispatch = {
  updateUser: actions.app.updateUser,
  addUserPreferences: actions.users.addUserPreferences,
  setToastMessage: actions.layout.setToastMessageAction,
};

interface ModalProps {
  isOpen: boolean;
  currentUser: CurrentUser;
  updateUser(user: Partial<CurrentUserEditFields>): Promise<CurrentUser>;
  onSubmitErrors(errors: Record<string, string>): Promise<void>;
  addUserPreferences(preferences: Preferences): Promise<CurrentUser>;
}

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type PropsUpdateUserMissingDataModal = StateProps & DispatchProps & UpdateUserMissingDataModalProps;

const submitButton: React.FunctionComponent<FormikProps<FormValues>> = ({ isValid, isSubmitting, isValidating }: FormValues) => (
  <IonButton type="submit" shape="round" className="full-width-button" disabled={!isValid || isValidating || isSubmitting} data-cy="submit-button">
    {i18next.t('common.save')}
  </IonButton>
);

const submitTosButton: React.FunctionComponent<FormikProps<FormValues>> = ({ isValid, isSubmitting, isValidating, values }: FormValues) => (
  <IonButton
    type="submit"
    shape="round"
    className="full-width-button"
    disabled={!isValid || isValidating || isSubmitting || (values.lastTosAcceptedDate !== 'undefined' && !values.lastTosAcceptedDate)}
    data-cy="submit-button"
  >
    {i18next.t('common.save')}
  </IonButton>
);

const submitNewsletterButton: React.FunctionComponent<FormikProps<FormValues>> = ({ isValid, isSubmitting, isValidating, values }: FormValues) => (
  <IonButton type="submit" shape="round" className="full-width-button" disabled={!isValid || isValidating || isSubmitting} data-cy="submit-button">
    {values.newsletterSubscribed !== 'undefined' && !values.newsletterSubscribed ? i18next.t('common.refuse') : i18next.t('common.save')}
  </IonButton>
);

const submitAvatarButton: React.FunctionComponent<FormikProps<FormValues>> = ({ isValid, isSubmitting, isValidating, values }: FormValues) => (
  <IonButton type="submit" shape="round" className="full-width-button" disabled={!isValid || isValidating || isSubmitting || values.avatar.length === 0} data-cy="submit-button">
    {i18next.t('common.save')}
  </IonButton>
);

export const getUserMissingDataModalProps = (currentUser: CurrentUser, updateUserMissingDataOptions: UpdateUserMissingDataOptions): UpdateUserMissingDataModalProps => {
  if (!extractId(currentUser) || currentUser.managed) {
    return {};
  }

  if (currentUser.passwordMustBeChanged) {
    return { modalType: 'EditPasswordModal', toastMessage: 'user.update-password-success' };
  }

  if (!userTosAreAccepted(currentUser)) {
    return { modalType: 'AddLastTosAcceptedDateModal', toastMessage: 'add-last-tos-accepted-date-modal.update-success' };
  }

  if (currentUser.email.endsWith(defaultEmptyEmailDomain)) {
    return { modalType: 'EditEmailModal', toastMessage: 'edit-email-modal.update-success' };
  }

  if (updateUserMissingDataOptions.minAppVersions?.tosMinDate !== undefined && !userTosUpToDate(currentUser, updateUserMissingDataOptions.minAppVersions.tosMinDate)) {
    return { modalType: 'EditLastTosAcceptedDateModal', toastMessage: 'edit-last-tos-accepted-date-modal.update-success' };
  }

  if (!has(currentUser, 'preferences.userDeclinedNewsletterSubscription') && !currentUser.newsletterSubscribed) {
    return { modalType: 'EditNewsletterSubscribedModal', toastMessage: 'edit-newsletter-subscribed-modal.update-success' };
  }

  if (!currentUser.address) {
    return { modalType: 'EditAddressModal', toastMessage: 'edit-address-modal.update-success' };
  }

  if (!has(currentUser, 'preferences.userDeclinedAvatarUpload') && !currentUser.avatar) {
    return { modalType: 'EditAvatarModal', toastMessage: 'edit-avatar-modal.update-success' };
  }

  //if current user firstname is empty or current user has default firstname and lastname for an apple account
  if (!currentUser.firstname || (currentUser.firstname.toLowerCase() === 'apple' && currentUser.lastname.toLowerCase() === 'account')) {
    return { modalType: 'EditUserNameModal', toastMessage: 'edit-username-modal.update-success' };
  }

  return {};
};

const EditPasswordModal: React.FunctionComponent<ModalProps> = ({ isOpen, currentUser, updateUser, onSubmitErrors }: ModalProps) => {
  const { t } = useTranslation();
  const initialValue: Partial<UserFormValues> = {
    '@id': extractId(currentUser),
    plainPassword: '',
    confirmPassword: '',
  };
  const passwordFields: (keyof UserFormValues)[] = ['plainPassword', 'confirmPassword'];

  return (
    <IonPopover isOpen={isOpen} backdropDismiss={false} className="update-user-missing-data-modal" data-cy="edit-password-modal">
      <div className="header">
        <IonImg src="/assets/update-user-missing-data-modal/password.svg" />
        <h6>
          <Trans i18nKey="edit-password-modal.update-password" />
        </h6>
        <p className="text">
          <Trans i18nKey="edit-password-modal.ensure-safety-account" />
        </p>
      </div>
      <UserForm
        initialValues={initialValue}
        fields={passwordFields}
        postUserAction={updateUser}
        submitFormButton={submitButton}
        submitButtonText={t('common.save')}
        onSubmitErrors={(action, errors) => onSubmitErrors(errors)}
      />
    </IonPopover>
  );
};

const AddLastTosAcceptedDateModal: React.FunctionComponent<ModalProps> = ({ isOpen, currentUser, updateUser, onSubmitErrors }: ModalProps) => {
  const { t } = useTranslation();
  const initialValue: Partial<UserFormValues> = {
    '@id': currentUser['@id'],
    lastTosAcceptedDate: undefined,
  };
  const fields: (keyof UserFormValues)[] = ['lastTosAcceptedDate'];

  const addLastTosAcceptedDate = async (currentUser: Partial<CurrentUserEditFields>): Promise<CurrentUser> => {
    currentUser.lastTosAcceptedDate = new Date();

    return updateUser(currentUser);
  };

  return (
    <IonPopover isOpen={isOpen} backdropDismiss={false} data-cy="add-last-tos-accepted-date-modal" className="update-user-missing-data-modal">
      <div className="header">
        <IonImg src="/assets/update-user-missing-data-modal/privacy.svg" />
        <h6>
          <Trans i18nKey="add-last-tos-accepted-date-modal.accept-tos" />
        </h6>
        <p className="text">
          <Trans i18nKey="add-last-tos-accepted-date-modal.message" />
        </p>
      </div>
      <UserForm
        initialValues={initialValue}
        fields={fields}
        postUserAction={addLastTosAcceptedDate}
        submitFormButton={submitTosButton}
        submitButtonText={t('common.save')}
        onSubmitErrors={(action, errors) => onSubmitErrors(errors)}
      />
    </IonPopover>
  );
};

const EditLastTosAcceptedDateModal: React.FunctionComponent<ModalProps> = ({ isOpen, currentUser, updateUser, onSubmitErrors }: ModalProps) => {
  const { t } = useTranslation();
  const initialValue: Partial<UserFormValues> = {
    '@id': currentUser['@id'],
    lastTosAcceptedDate: undefined,
  };
  const fields: (keyof UserFormValues)[] = ['lastTosAcceptedDate'];
  const editLastTosAcceptedDate = async (currentUser: Partial<CurrentUserEditFields>): Promise<CurrentUser> => {
    if (currentUser.lastTosAcceptedDate) {
      currentUser.lastTosAcceptedDate = new Date();
    }

    return updateUser(currentUser);
  };
  return (
    <IonPopover isOpen={isOpen} backdropDismiss={false} data-cy="edit-last-tos-accepted-date-modal" className="update-user-missing-data-modal">
      <div className="header">
        <IonImg src="/assets/update-user-missing-data-modal/privacy.svg" />
        <h6>
          <Trans i18nKey="edit-last-tos-accepted-date-modal.accept-tos" />
        </h6>
        <p className="text">
          <Trans i18nKey="edit-last-tos-accepted-date-modal.message" />
        </p>
      </div>
      <UserForm
        initialValues={initialValue}
        fields={fields}
        postUserAction={editLastTosAcceptedDate}
        submitFormButton={submitTosButton}
        submitButtonText={t('common.save')}
        onSubmitErrors={(action, errors) => onSubmitErrors(errors)}
      />
    </IonPopover>
  );
};

const EditAddressModal: React.FunctionComponent<ModalProps> = ({ isOpen, currentUser, updateUser, onSubmitErrors }: ModalProps) => {
  const { t } = useTranslation();
  const initialValue: Partial<UserFormValues> = {
    '@id': extractId(currentUser),
    address: undefined,
    addressLocation: undefined,
  };
  const fields: (keyof UserFormValues)[] = ['address', 'addressLocation'];
  return (
    <IonPopover isOpen={isOpen} backdropDismiss={false} data-cy="edit-address-modal" className="update-user-missing-data-modal">
      <div className="header">
        <IonImg src="/assets/update-user-missing-data-modal/address.svg" />
        <h6>
          <Trans i18nKey="edit-address-modal.enter-address" />
        </h6>
        <p className="text">
          <Trans i18nKey="edit-address-modal.set-your-location" />
        </p>
      </div>
      <UserForm
        initialValues={initialValue}
        fields={fields}
        postUserAction={updateUser}
        submitFormButton={submitButton}
        submitButtonText={t('common.save')}
        onSubmitErrors={(action, errors) => onSubmitErrors(errors)}
      />
    </IonPopover>
  );
};

const EditEmailModal: React.FunctionComponent<ModalProps> = ({ isOpen, currentUser, updateUser, onSubmitErrors }: ModalProps) => {
  const { t } = useTranslation();
  const initialValue: Partial<UserFormValues> = {
    '@id': extractId(currentUser),
    email: undefined,
  };
  const fields: (keyof UserFormValues)[] = ['email'];
  return (
    <IonPopover isOpen={isOpen} backdropDismiss={false} data-cy="edit-email-modal" className="update-user-missing-data-modal">
      <div className="header">
        <IonImg src="/assets/update-user-missing-data-modal/email.svg" />
        <h6>
          <Trans i18nKey="edit-email-modal.enter-email" />
        </h6>
        <p className="text">
          <Trans i18nKey="edit-email-modal.not-miss-offer" />
        </p>
      </div>
      <UserForm
        initialValues={initialValue}
        fields={fields}
        postUserAction={updateUser}
        submitFormButton={submitButton}
        submitButtonText={t('common.save')}
        onSubmitErrors={(action, errors) => onSubmitErrors(errors)}
      />
    </IonPopover>
  );
};

const EditNewsletterSubscribedModal: React.FunctionComponent<ModalProps> = ({ isOpen, currentUser, updateUser, onSubmitErrors, addUserPreferences }: ModalProps) => {
  const [submittedForm, setSubmittedForm] = useState(false);
  const { t } = useTranslation();
  const initialValue: Partial<UserFormValues> = {
    '@id': extractId(currentUser),
    newsletterSubscribed: false,
  };
  const fields: (keyof UserFormValues)[] = ['newsletterSubscribed'];

  const afterSubmitFormSuccess = async (values: UserFormValues): Promise<void> => {
    if (!values.newsletterSubscribed) {
      updateUserPreferences();
    }
  };

  const updateNewsletterSubscribe = async (currentUser: Partial<CurrentUserEditFields>): Promise<CurrentUser> => {
    setSubmittedForm(true);
    return updateUser(currentUser);
  };

  const updateUserPreferences = async (): Promise<void> => {
    if (currentUser.newsletterSubscribed || submittedForm) {
      return;
    }
    await addUserPreferences({ userDeclinedNewsletterSubscription: true });
  };

  return (
    <IonPopover isOpen={isOpen} backdropDismiss={false} data-cy="edit-newsletter-subscribed-modal" className="update-user-missing-data-modal text-center">
      <div className="header">
        <IonButton className="close-icon-button" onClick={updateUserPreferences} fill="clear" size="small" data-cy="close-icon-button">
          <IonIcon icon="/assets/navigation/close.svg" className="close-icon" slot="icon-only" />
        </IonButton>
        <IonImg src="/assets/update-user-missing-data-modal/newsletter.svg" />
        <h6>
          <Trans i18nKey="edit-newsletter-subscribed-modal.follow" />
        </h6>
        <p className="text">
          <Trans i18nKey="edit-newsletter-subscribed-modal.offer-email" />
        </p>
      </div>
      <UserForm
        initialValues={initialValue}
        fields={fields}
        onAfterSubmitFormSuccess={afterSubmitFormSuccess}
        postUserAction={updateNewsletterSubscribe}
        submitFormButton={submitNewsletterButton}
        submitButtonText={t('common.save')}
        onSubmitErrors={(action, errors) => onSubmitErrors(errors)}
      />
    </IonPopover>
  );
};

const EditAvatarModal: React.FunctionComponent<ModalProps> = ({ isOpen, currentUser, updateUser, onSubmitErrors, addUserPreferences }: ModalProps) => {
  const { t } = useTranslation();
  const [submittedForm, setSubmittedForm] = useState(false);
  const initialValue: Partial<UserFormValues> = {
    '@id': extractId(currentUser),
    avatar: [],
  };

  const uploadAvatar = async (values: UserFormValues, actions: FormikHelpers<FormValues>): Promise<void> => {
    try {
      await uploadAndValidateFiles(values, actions, 'avatar', t);
    } catch (e) {
      console.error(e);
    }
  };

  const afterSubmitFormSuccess = async (values: UserFormValues, actions: FormikHelpers<FormValues>): Promise<void> => {
    await actions.setSubmitting(false);
    uploadAvatar(values, actions);
  };

  const updateUserPreferences = async (): Promise<void> => {
    if (currentUser.avatar || submittedForm) {
      return;
    }
    await addUserPreferences({ userDeclinedAvatarUpload: true });
  };

  const updateUserAvatar = async (currentUser: Partial<CurrentUserEditFields>): Promise<CurrentUser> => {
    setSubmittedForm(true);
    return updateUser(currentUser);
  };

  const fields: (keyof UserFormValues)[] = ['avatar'];
  return (
    <IonPopover isOpen={isOpen} backdropDismiss={false} data-cy="edit-avatar-modal" className="update-user-missing-data-modal">
      <div className="header">
        <IonButton className="close-icon-button" onClick={updateUserPreferences} fill="clear" size="small" data-cy="close-icon-button">
          <IonIcon icon="/assets/navigation/close.svg" className="close-icon" slot="icon-only" />
        </IonButton>
        <IonImg src="/assets/update-user-missing-data-modal/media.svg" />
        <h6>
          <Trans i18nKey="edit-avatar-modal.profile-picture" />
        </h6>
        <p className="text">
          <Trans i18nKey="edit-avatar-modal.set-profile-picture" />
        </p>
      </div>
      <UserForm
        initialValues={initialValue}
        fields={fields}
        onAfterSubmitFormSuccess={afterSubmitFormSuccess}
        postUserAction={updateUserAvatar}
        submitFormButton={submitAvatarButton}
        submitButtonText={t('common.save')}
        onSubmitErrors={(action, errors) => onSubmitErrors(errors)}
        overriddenFields={[
          {
            name: 'avatar',
            formComponent: InputMediaUploader,
            options: {
              maxFiles: 1,
              multiple: false,
              isMediaUploaderField: true,
              picturePlaceholder: profilePicturePlaceholder,
              addPicturePlaceholder: addProfilePicturePlaceholder,
              defaultPictures: defaultPictures,
            },
            itemLines: 'none',
          },
        ]}
      />
    </IonPopover>
  );
};

const EditUserNameModal: React.FunctionComponent<ModalProps> = ({ isOpen, currentUser, updateUser, onSubmitErrors }: ModalProps) => {
  const { t } = useTranslation();
  const initialValue: Partial<UserFormValues> = {
    '@id': extractId(currentUser),
    firstname: '',
    lastname: '',
  };
  const passwordFields: (keyof UserFormValues)[] = ['firstname', 'lastname'];

  return (
    <IonPopover isOpen={isOpen} backdropDismiss={false} className="update-user-missing-data-modal" data-cy="edit-username-modal">
      <div className="header">
        <IonImg src="/assets/update-user-missing-data-modal/username.svg" />
        <h6>
          <Trans i18nKey="edit-username-modal.indicate-lastname" />
        </h6>
        <p className="text">
          <Trans i18nKey="edit-username-modal.identify-you" />
        </p>
      </div>
      <UserForm
        initialValues={initialValue}
        fields={passwordFields}
        postUserAction={updateUser}
        submitFormButton={submitButton}
        submitButtonText={t('common.save')}
        onSubmitErrors={(action, errors) => onSubmitErrors(errors)}
      />
    </IonPopover>
  );
};

const WelcomeUserEditModal: React.FunctionComponent<WelcomeEditModalProps> = ({ isOpen, closeWelcomeEditModal }: WelcomeEditModalProps) => {
  return (
    <IonPopover isOpen={isOpen} backdropDismiss={false} data-cy="edit-welcome-modal" className="update-user-missing-data-modal" onDidDismiss={closeWelcomeEditModal}>
      <div className="header">
        <IonButton className="close-icon-button" onClick={closeWelcomeEditModal} fill="clear" size="small" data-cy="close-icon-button">
          <IonIcon icon="/assets/navigation/close.svg" className="close-icon" slot="icon-only" />
        </IonButton>
        <IonImg src="/assets/update-user-missing-data-modal/cat.svg" />
        <h6>
          <Trans i18nKey="welcome-edit-modal.something-missing" />
        </h6>
        <p className="text">
          <Trans i18nKey="welcome-edit-modal.need-to-fill" />
        </p>
        <IonButton className="button-large" data-cy="button-continue" color="primary" type="submit" shape="round" expand="block" size="large" onClick={closeWelcomeEditModal}>
          <Trans i18nKey="user.continue" />
        </IonButton>
      </div>
    </IonPopover>
  );
};

const UpdateUserMissingDataModal: React.FunctionComponent<PropsUpdateUserMissingDataModal> = (props: PropsUpdateUserMissingDataModal) => {
  const [welcomeEditModalAlreadyDisplayed, setWelcomeEditModalAlreadyDisplayed] = useState(false);

  const updateUser = async (currentUser: Partial<CurrentUserEditFields>): Promise<CurrentUser> => {
    const userUpdated = await props.updateUser(currentUser);
    if (props.toastMessage) {
      props.setToastMessage(props.toastMessage);
    }
    return userUpdated;
  };

  const onSubmitErrors = async (errors: Record<string, string>): Promise<void> => {
    props.setToastMessage(errors['_error'] ?? errors ?? 'user.update-error');
  };

  const modalProps: ModalProps = { currentUser: props.currentUser, updateUser, onSubmitErrors, addUserPreferences: props.addUserPreferences, isOpen: false };

  return (
    <>
      <WelcomeUserEditModal isOpen={!welcomeEditModalAlreadyDisplayed && !!props.modalType} closeWelcomeEditModal={() => setWelcomeEditModalAlreadyDisplayed(true)} />
      <EditPasswordModal {...modalProps} isOpen={welcomeEditModalAlreadyDisplayed && props.modalType === 'EditPasswordModal'} />
      <AddLastTosAcceptedDateModal {...modalProps} isOpen={welcomeEditModalAlreadyDisplayed && props.modalType === 'AddLastTosAcceptedDateModal'} />
      <EditEmailModal {...modalProps} isOpen={welcomeEditModalAlreadyDisplayed && props.modalType === 'EditEmailModal'} />
      <EditLastTosAcceptedDateModal {...modalProps} isOpen={welcomeEditModalAlreadyDisplayed && props.modalType === 'EditLastTosAcceptedDateModal'} />
      <EditAddressModal {...modalProps} isOpen={welcomeEditModalAlreadyDisplayed && props.modalType === 'EditAddressModal'} />
      <EditAvatarModal {...modalProps} isOpen={welcomeEditModalAlreadyDisplayed && props.modalType === 'EditAvatarModal'} />
      <EditNewsletterSubscribedModal {...modalProps} isOpen={welcomeEditModalAlreadyDisplayed && props.modalType === 'EditNewsletterSubscribedModal'} />
      <EditUserNameModal {...modalProps} isOpen={welcomeEditModalAlreadyDisplayed && props.modalType === 'EditUserNameModal'} />
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUserMissingDataModal);
