import React, { Component, ReactNode } from 'react';
import { IonModal, IonIcon, IonText, IonThumbnail } from '@ionic/react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { actions } from '../store';
import * as Yup from 'yup';
import DynamicForm, { FormFields, FormValues, InputWithValidationFieldComponent } from './DynamicForm';
import { FormikHelpers } from 'formik';

import './ForgotPasswordModal.scss';

interface DispatchProps {
  requestNewPasswordAction(email: string): void;
}

interface ModalProps {
  closeModal: () => void;
  isOpen: boolean;
}

interface State {
  didErrorOnSubmit: boolean;
}

const propsToDispatch = {
  requestNewPasswordAction: actions.app.requestNewPasswordAction,
};

interface ForgotPasswordFormValues extends FormValues {
  email?: string;
}

type Props = WithTranslation & ModalProps & DispatchProps;

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

class ForgotPasswordModal extends Component<Props, State> {
  allFields: FormFields = [
    {
      label: 'login.forgot-password-label',
      name: 'email',
      formComponent: InputWithValidationFieldComponent,
      options: { autocapitalize: 'off', type: 'email', required: true, clearOnEdit: false, clearInput: true },
    },
  ];

  validationSchema = Yup.object().shape({
    email: Yup.string().email('user.email-invalid').required('user.email-required'),
  });

  state = {
    didErrorOnSubmit: false,
  };

  initialFormValues: ForgotPasswordFormValues = {
    email: '',
  };

  public render(): ReactNode {
    const { didErrorOnSubmit } = this.state;
    const { closeModal, isOpen, t } = this.props;

    return (
      <IonModal isOpen={isOpen} onDidDismiss={closeModal} className="safe-area-ios">
        <div className="forgot-password">
          <IonIcon icon="/assets/navigation/close.svg" className="close-icon" onClick={closeModal} />
          <header>
            <IonThumbnail>
              <img src="/assets/logo-indigo-gradient.svg" alt="Indigo" />
            </IonThumbnail>

            <h1>
              <Trans i18nKey="login.forgot-password-title" />
            </h1>

            <hr />
          </header>

          <IonText className="info-msg" color="ion-color-dark">
            <div>
              <Trans i18nKey="login.forgot-password-text" />
            </div>
          </IonText>

          <div data-cy="error-msg" style={{ visibility: didErrorOnSubmit ? 'visible' : 'hidden' }} className="error-msg">
            <IonText color="light">
              <Trans i18nKey="login.error-email" />
            </IonText>
          </div>
          <DynamicForm
            onAfterSubmitFormSuccess={this.onAfterSubmitFormSuccess}
            onSubmitForm={this.submitForm}
            onFieldChange={this.handleInputChange}
            formFields={this.allFields}
            validationSchema={this.validationSchema}
            initialValues={this.initialFormValues}
            submitButtonText={t('login.forgot-password-submit')}
          />
        </div>
      </IonModal>
    );
  }

  private handleInputChange = (e: string, value: string): void => {
    if (this.state.didErrorOnSubmit && value.length > 0) {
      this.setState({ didErrorOnSubmit: false });
    }
  };

  private onAfterSubmitFormSuccess = async (values: Readonly<ForgotPasswordFormValues>, actions: FormikHelpers<FormValues>): Promise<void> => {
    actions.resetForm({ values: { ...values, email: '' } });
    this.props.closeModal();
  };

  private submitForm = async (values: Readonly<ForgotPasswordFormValues>): Promise<void> => {
    if (!values.email) {
      return;
    }

    return this.props.requestNewPasswordAction(values.email);
  };
}

export default connect(null, mapDispatchToProps)(withTranslation()(ForgotPasswordModal));
