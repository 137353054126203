import { createAsyncAction } from 'typesafe-actions';
import { ThunkResult } from '../types';
import { Thematic } from './types';
import { EntityReference } from '../../utils/hydra';
import { extractId, fetchAllItems } from '../../utils/helpers';

export const fetchThematicsAction = createAsyncAction('thematics/FETCH_THEMATICS_REQUEST', 'thematics/FETCH_THEMATICS_SUCCESS', 'thematics/FETCH_THEMATICS_FAILURE')<void, Thematic[], Error>();

interface ThematicApiValues extends Omit<Thematic, 'parent'> {
  parent: Thematic | EntityReference;
}

const apiValuesToThematic = (values: ThematicApiValues): Thematic => {
  return {
    ...values,
    parent: values.parent !== null ? extractId(values.parent) : null,
  };
};

function handleThematicResponse(data: ThematicApiValues[]): Thematic[] {
  return data.map(item => apiValuesToThematic(item)) as Thematic[];
}

export function fetchThematics(): ThunkResult<Promise<void>> {
  return async dispatch => {
    dispatch(fetchThematicsAction.request());

    return fetchAllItems<ThematicApiValues>('/category_thematics?itemsPerPage=100&order[order]=ASC')
      .then(data => {
        const result = handleThematicResponse(data);
        dispatch(fetchThematicsAction.success(result));
      })
      .catch(e => {
        dispatch(fetchThematicsAction.failure(e));
      });
  };
}
