import { EntityReference, HydraEntity, ReferencedCollection } from '../../utils/hydra';
import { Post } from '../posts/types';
import { User } from '../users/types';

export interface PostRequest extends HydraEntity {
  state: State;
  post: Post;
  user: User;
  chat: EntityReference;
  createdAt: string;
  stateHistory: PostRequestStateHistory[];
  userReviews: ReferencedCollection; // UserReview[]
}

export interface PostRequestStateHistory {
  from: State;
  to: State;
  reason?: Reason;
  date: string;
}

type State = 'waiting' | 'accepted' | 'rejected' | 'canceled' | 'completed';

type Reason = 'post_deleted' | 'post_finished' | 'user_blocked';

export interface PostRequestState {
  currentUserPostRequests: ReferencedCollection; // PostRequest[]
  itemIsLoading: boolean;
}

export type PostRequestTransition = 'accept' | 'accept_and_complete' | 'cancel' | 'reject' | 'complete';

export const cancelableStates = ['waiting', 'accepted'];

export const statesDisplayInChatsList = ['rejected', 'canceled', 'completed', 'accepted'];
