import React, { Component, ReactNode } from 'react';
import { IonButton, IonIcon, IonInput, IonItem, IonLabel } from '@ionic/react';
import { FormikProps } from 'formik';
import i18next from 'i18next';
import { FormValues } from '../DynamicForm';
import { ignoreResetButtonOnTab } from '../../utils/helpers';

interface State {
  isPasswordMasked?: boolean;
  isBlurred?: boolean;
}

export interface InputPasswordToggleProps {
  value?: string;
  form: FormikProps<FormValues>;
  name: string;
  label: string;
  required: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLIonInputElement>;
}

class InputPasswordToggle extends Component<InputPasswordToggleProps, State> {
  state = {
    isPasswordMasked: false,
    isBlurred: false,
    password: undefined,
  };

  public render(): ReactNode {
    const { form, name, label, required } = this.props;
    const fieldHasError = (form.status && form.status[name]) || (form.errors[name] && form.touched[name]);

    return (
      <>
        <IonItem className={`${fieldHasError ? 'ion-invalid' : 'no-error-field'}`} mode="md">
          <img alt="validation" className="icon-svg-validation" src="/assets/form/icon-validation.svg" />
          {label && (
            <IonLabel position="floating" color={fieldHasError ? 'danger' : 'dark'}>
              {i18next.t(label)} {required && ' *'}
            </IonLabel>
          )}
          <IonInput
            {...this.props}
            label={undefined}
            type={this.state.isPasswordMasked ? 'text' : 'password'}
            onFocus={() => {
              this.setState({ isBlurred: false });
            }}
            onBlur={() => {
              this.setState({ isBlurred: true });
            }}
            onKeyDown={e => {
              ignoreResetButtonOnTab(e);
              if (!e.isPropagationStopped() && this.props.onKeyDown) {
                this.props.onKeyDown(e);
              }
            }}
          />
          {this.state.isBlurred && this.props.value?.length !== 0 && (
            <div className="button-right-input-wrapper">
              <IonButton
                fill="clear"
                className="button-icon"
                buttonType="button icon-only"
                tabIndex={-1}
                onClick={e => {
                  this.setState({ isPasswordMasked: !this.state.isPasswordMasked });
                  e.preventDefault();
                }}
              >
                <IonIcon className="icon icon-only" icon={this.state.isPasswordMasked ? '/assets/form/eye-off.svg' : '/assets/form/eye.svg'} />
              </IonButton>
            </div>
          )}
        </IonItem>
      </>
    );
  }
}

export default InputPasswordToggle;
