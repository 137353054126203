import { RootState } from '../index';
import { isSameHydraEntity, parseJwt, userDisallowsNotifications } from '../../utils/helpers';
import { Area, CurrentUser, User } from '../users/types';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { EntityReference } from '../../utils/hydra';
import { EntitiesState } from '../entities/types';
import isEqual from 'lodash/isEqual';
import { RoleName } from './types';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getEntities = (state: RootState): EntitiesState => state.entities; // TODO Find a better way

const getCurrentUserFromState = (state: RootState): CurrentUser => state.app.currentUser;
const getIsFullyLoggedFromState = (state: RootState): boolean => state.app.isFullyLogged;
const getIsUserFirstLoginFromState = (state: RootState): boolean => state.app.isUserFirstLogin;
const getUserToken = (state: RootState): string | null => state.app.token;
const getUserAreaFromState = (state: RootState): Area | undefined => state.app.userArea;

export const getCurrentUser = createDeepEqualSelector(getCurrentUserFromState, (currentUser: CurrentUser) => currentUser);
export const getCurrentUserId = createDeepEqualSelector(getCurrentUserFromState, (currentUser: CurrentUser) => currentUser['@id']);
export const getIsFullyLogged = createSelector(getIsFullyLoggedFromState, (isFullyLogged: boolean) => isFullyLogged);
export const getIsUserFirstLogin = createSelector(getIsUserFirstLoginFromState, (isUserFirstLogin: boolean) => isUserFirstLogin);
export const getUserArea = createDeepEqualSelector(getUserAreaFromState, (userArea: Area | undefined) => userArea);

export type isCurrentUserFunc = (user: Partial<User> | EntityReference) => boolean;
export const createIsCurrentUserFunc = createSelector(getCurrentUserId, (currentUser: EntityReference): isCurrentUserFunc => {
  return (user: Partial<User> | EntityReference) => isSameHydraEntity(user, currentUser);
});
export const isPushNotificationsAllowed = createDeepEqualSelector(getCurrentUser, (currentUser: CurrentUser): boolean => !userDisallowsNotifications(currentUser, 'direct_chat_new_message'));

export const getUserIsManaged = createSelector(getCurrentUser, (currentUser: CurrentUser) => !!currentUser.managed);
export const getUserIsManagedAndNgo = createSelector(getCurrentUser, (currentUser: CurrentUser) => !!currentUser.managed && currentUser.managedData?.type === 'ngo');

const getUserRoles = createSelector(getUserToken, (token: string | null) => (parseJwt(token)?.roles || []) as RoleName[]);
export const getUserIsAdmin = createSelector(getUserRoles, (userRoles: RoleName[]) => userRoles.includes('ROLE_ADMIN'));
export const getUserCanCreateUsers = createSelector(getUserRoles, (userRoles: RoleName[]) => userRoles.includes('ROLE_ADMIN') || userRoles.includes('ROLE_CREATE_USER'));
export const getUserIsAnalyzer = createSelector(getUserRoles, (userRoles: RoleName[]) => userRoles.includes('ROLE_ADMIN') || userRoles.includes('ROLE_ANALYZER'));
export const getUserIsModerator = createSelector(getUserRoles, (userRoles: RoleName[]) => userRoles.includes('ROLE_ADMIN') || userRoles.includes('ROLE_MODERATOR'));
export const getUserIsGlobalNotifier = createSelector(getUserRoles, (userRoles: RoleName[]) => userRoles.includes('ROLE_ADMIN') || userRoles.includes('ROLE_GLOBAL_NOTIFIER'));
