import { IonChip, IonIcon, IonLabel } from '@ionic/react';
import React from 'react';
import { Trans, withTranslation } from 'react-i18next';

import './ShareChip.scss';

interface ShareChipProps {
  onShareClick: () => void;
}

const ShareChip: React.FunctionComponent<ShareChipProps> = (props: ShareChipProps) => {
  const shareSearch = (): void => {
    props.onShareClick();
  };

  return (
    <IonChip data-cy="share-search-chip" className="share-chip warning-ion-chip-outline" color="warning" outline={true} onClick={shareSearch}>
      <IonIcon src="/assets/icon/share.svg" color="dark" />
      <IonLabel color="dark">
        <Trans i18nKey="search.share" />
      </IonLabel>
    </IonChip>
  );
};

export default withTranslation()(ShareChip);
