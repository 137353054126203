import { IonPage } from '@ionic/react';
import React, { PureComponent, ReactNode } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import PostForm from '../components/PostForm';
import ErrorComponent from '../ErrorComponent';
import { actions, RootState } from '../store';
import { EditablePost, Post } from '../store/posts/types';
import { ExtendableError } from '../utils/dataAccessError';
import { EntityReference } from '../utils/hydra';
import { getPostByPostId } from '../store/posts/selectors';
import { PostPageProps } from './PostPage';
import { createIsCurrentUserFunc, isCurrentUserFunc } from '../store/app/selectors';

interface EditPostState {
  postFetchError?: ExtendableError;
}

interface StateProps {
  post: Post | undefined;
  isCurrentUser: isCurrentUserFunc;
}

const mapStateToProps = (state: RootState, props: PostPageProps): StateProps => ({
  post: getPostByPostId(state, props),
  isCurrentUser: createIsCurrentUserFunc(state),
});

interface DispatchProps {
  editPost(post: Post): Promise<EntityReference>;
  fetchPost: (post: EntityReference) => Promise<void>;
}

const propsToDispatch = {
  editPost: actions.posts.editUserPost,
  fetchPost: actions.posts.fetchPost,
};

interface PageProps {
  postId: EntityReference | null;
}

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type EditPostProps = PageProps & ReturnType<typeof mapStateToProps> & DispatchProps;

class EditPostPage extends PureComponent<EditPostProps, EditPostState> {
  constructor(props: EditPostProps) {
    super(props);
    this.state = {};
  }

  public componentDidMount(): void {
    this.refreshPost();
  }

  public render(): ReactNode {
    if (!this.props.post && this.state.postFetchError !== undefined) {
      return <ErrorComponent error={this.state.postFetchError} doRefresh={() => this.refreshPost()} />;
    }

    if (this.props.post?.createdBy && !this.props.isCurrentUser(this.props.post?.createdBy)) {
      return <ErrorComponent error={404} doRefresh={() => this.refreshPost()} />;
    }

    return (
      <IonPage data-cy="edit-post-page">
        {this.props.post && (
          <PostForm submit={this.props.editPost} editionMode={true} initialValues={this.props.post as Partial<EditablePost>} headerTitleKey="post.edit-post" submitButtonTextKey="common.save" />
        )}
      </IonPage>
    );
  }

  private refreshPost(): void {
    const { fetchPost, postId } = this.props;
    if (!postId) {
      return;
    }

    fetchPost(postId)
      .then(() => {
        this.setState({ postFetchError: undefined });
      })
      .catch((e: ExtendableError) => {
        this.setState({ postFetchError: e });
      });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPostPage);
