import { createAction, createAsyncAction } from 'typesafe-actions';
import { itemIsAMedia } from '../../components/inputs/InputMediaUploader';
import { fetchWithAuth, fetchWithAuthIfPossible } from '../../utils/authDataAccess';
import { addParametersToUrl, QueryParameters } from '../../utils/dataAccess';
import { extractId, fetchAllItems, parseHydraCollection, uuidReg } from '../../utils/helpers';
import { CollectionResponse, EntityReference, HydraCollectionResponse, ReferencedCollectionResponse } from '../../utils/hydra';
import { getDisplayedGeohashes, getGeohashPrecision } from '../../utils/mapPostsLoader';
import { denormalizeEntity, normalizeAndDispatchCollectionResponse, normalizeAndDispatchEntity } from '../entities/selectors';
import { ThunkResult } from '../types';
import { UserCollectionFetchFailure, UserCollectionFetchRequest, UserCollectionFetchSuccess } from '../users/actions';
import { User, UserCollectionName } from '../users/types';
import { isFullPost } from './selectors';
import {
  FullPost,
  PartialPost,
  Post,
  PostCluster,
  PostCollectionName,
  PostType,
  currentUserDisplayedStates,
  SearchQueryParameters,
  AnalyzerPrediction,
  PostsPageFetchParams,
  PostEstimateData,
  SavedSearch,
  SearchFilter,
} from './types';
import { Category } from '../categories/types';
import { roundWithPrecision } from '../../utils/geolocationHelpers';
import { Location } from '../posts/types';
import { itemsPerPage, postListRadius } from './variables';

export interface PostCollectionFetchRequest {
  postId?: EntityReference;
  loadNextPage?: boolean;
  collectionName: PostCollectionName;
  extendCollection?: boolean;
}

export interface PostCollectionFetchSuccess {
  postId?: EntityReference;
  collectionName: PostCollectionName;
  collection: CollectionResponse<FullPost>;
  extendCollection?: boolean;
}

export interface PostCollectionFetchFailure {
  postId?: EntityReference;
  collectionName: PostCollectionName;
  error: Error;
}

export const fetchPostsCollectionAction = createAsyncAction('posts/FETCH_POSTS_COLL_REQUEST', 'posts/FETCH_POSTS_COLL_SUCCESS', 'posts/FETCH_POSTS_COLL_FAILURE')<
  PostCollectionFetchRequest,
  PostCollectionFetchSuccess,
  PostCollectionFetchFailure
>();

export const fetchMapCollectionAction = createAsyncAction('posts/FETCH_MAP_COLL_REQUEST', 'posts/FETCH_MAP_COLL_SUCCESS', 'posts/FETCH_MAP_COLL_FAILURE')<void, void, Error>();

export const resetPostCollection = createAction('posts/RESET_COLLECTION', (collectionName: PostCollectionName) => collectionName)();

export const fetchPostAction = createAsyncAction('posts/FETCH_POST_REQUEST', 'posts/FETCH_POST_SUCCESS', 'posts/FETCH_POST_FAILURE')<void, EntityReference, Error>();

export const fetchUserPostsAction = createAsyncAction('posts/FETCH_USER_POSTS_REQUEST', 'posts/FETCH_USER_POSTS_SUCCESS', 'posts/FETCH_USER_POSTS_FAILURE')<
  UserCollectionFetchRequest,
  UserCollectionFetchSuccess,
  UserCollectionFetchFailure
>();

export const createPostAction = createAsyncAction('posts/CREATE_REQUEST', 'posts/CREATE_SUCCESS', 'posts/CREATE_FAILURE')<void, FullPost, Error>();

export const editPostAction = createAsyncAction('posts/EDIT_REQUEST', 'posts/EDIT_SUCCESS', 'posts/EDIT_FAILURE')<void, EntityReference, Error>();

export const updatePostStateAction = createAsyncAction('posts/UPDATE_POST_STATE_RESQUEST', 'posts/UPDATE_POST_STATE_SUCCESS', 'posts/UPDATE_POST_STATE_FAILURE')<
  void,
  string,
  { error: Error; transition: string }
>();

export const setSearchParamsAction = createAction('search/SET_SEARCH_PARAMS', (searchParams: SearchQueryParameters) => searchParams)();

export const resetSearchParamsAction = createAction('search/RESET_SEARCH_PARAMS')();

export const fetchSavedSearchesAction = createAsyncAction('search/FETCH_SAVED_SEARCH_REQUEST', 'search/FETCH_SAVED_SEARCH_SUCCESS', 'search/FETCH_SAVED_SEARCH_FAILURE')<void, SavedSearch[], Error>();

export const deleteSavedSearchAction = createAsyncAction('search/DELETE_SAVED_SEARCH_REQUEST', 'search/DELETE_SAVED_SEARCH_SUCCESS', 'search/DELETE_SAVED_SEARCH_FAILURE')<
  void,
  EntityReference,
  Error
>();

export const createSavedSearchAction = createAsyncAction('search/CREATE_SAVED_SEARCH_REQUEST', 'search/CREATE_SAVED_SEARCH_SUCCESS', 'search/CREATE_SAVED_SEARCH_FAILURE')<void, SavedSearch, Error>();

interface PostApiValues extends Omit<Post, 'images'> {
  images: EntityReference[];
  discriminants?: EntityReference[];
}

const postToApiValues = (post: Post): PostApiValues => {
  if (!isFullPost(post)) {
    throw new Error("Post isn't fully loaded");
  }

  const values: PostApiValues = Object.assign({}, post, { images: undefined, discriminants: undefined });

  if (Object.prototype.hasOwnProperty.call(values, 'images')) {
    values.images = (post as FullPost).images.filter(item => itemIsAMedia(item)).map(media => media['@id']);
  }

  if (Object.prototype.hasOwnProperty.call(values, 'discriminants')) {
    values.discriminants = post.discriminants?.map(item => extractId(item));
  }

  return values;
};

const postsBaseUrl = '/posts';
const postsSearchBaseUrl = '/posts/search';

export function fetchPosts(page: string, collectionName: PostCollectionName, loadNextPage = false, extendCollection = false): ThunkResult<Promise<CollectionResponse<FullPost>>> {
  return async (dispatch, getState) => {
    dispatch(fetchPostsCollectionAction.request({ loadNextPage, collectionName, extendCollection }));

    if (loadNextPage) {
      page = getState().posts.collections?.[collectionName]?.nextPage || page;
    }

    return fetchWithAuthIfPossible(page)
      .then(response => response.json() as Promise<HydraCollectionResponse<FullPost>>)
      .then(data => parseHydraCollection(data))
      .then(collection => {
        dispatch(fetchPostsCollectionAction.success({ collectionName, collection, extendCollection }));
        return collection;
      })
      .catch(error => {
        dispatch(fetchPostsCollectionAction.failure({ collectionName, error }));
        return Promise.reject(error);
      });
  };
}

export function fetchPostsByType(type: PostType, loadNextPage = false): ReturnType<typeof fetchPosts> {
  const url = addParametersToUrl(postsSearchBaseUrl, { type, itemsPerPage });
  return fetchPosts(url, type, loadNextPage);
}

const getFakeLocationCenter = (location: Location): string => {
  const lat = roundWithPrecision(location.latitude);
  const lng = roundWithPrecision(location.longitude);

  return `${lat},${lng}`;
};

export function fetchPostsForPostsPageLists(options: PostsPageFetchParams): ThunkResult<Promise<CollectionResponse<FullPost>>> {
  const { type, radius, location, loadNextPage, extendCollection, excludePrevRadius } = options;
  let url: string = addParametersToUrl(postsSearchBaseUrl, { type });

  // If there is a radius OR the location is undefined, then we order by updatedDate
  if (radius || location?.latitude === undefined || location?.longitude === undefined) {
    url = addParametersToUrl(url, {
      'order[updatedAt]': 'desc',
    });
  }

  if (location?.latitude !== undefined && location?.longitude !== undefined) {
    url = addParametersToUrl(url, {
      'fakeLocation[center]': getFakeLocationCenter(location),
    });

    if (radius) {
      url = addParametersToUrl(url, {
        'fakeLocation[radius]': radius ?? postListRadius[0],
      });

      if (excludePrevRadius) {
        const radiusIndex: number = postListRadius.indexOf(radius);
        const prevRadius = postListRadius[radiusIndex - 1];

        // If the radius is given, we exclude the prevRadius
        if (prevRadius) {
          url = addParametersToUrl(url, {
            'fakeLocation[minRadius]': prevRadius,
          });
        }
      }
    } else {
      // If the radius is undefined, we search outside the max radius, ordered by distance
      url = addParametersToUrl(url, {
        'fakeLocation[minRadius]': postListRadius[postListRadius.length - 1],
        'fakeLocation[order]': 'asc',
      });
    }
  }

  return fetchPosts(addParametersToUrl(url, { itemsPerPage }), type, loadNextPage, extendCollection);
}

export function fetchUserPostsFiltered(
  userId: EntityReference,
  baseUrl: string = postsBaseUrl,
  collectionName: UserCollectionName,
  loadNextPage = false,
): ThunkResult<Promise<ReferencedCollectionResponse>> {
  return async (dispatch, getState) => {
    dispatch(fetchUserPostsAction.request({ userId, collectionName }));

    const reg = new RegExp(`^(?:/users/)?${uuidReg}$`);
    if (!userId.match(reg)) {
      throw new Error('userId is not valid');
    }

    let url = addParametersToUrl(baseUrl, { itemsPerPage, createdBy: userId });

    if (collectionName === 'servicesOffers') {
      url = addParametersToUrl(url, { type: 'offer', categoryType: 'service' });
    } else if (collectionName === 'objectsOffers') {
      url = addParametersToUrl(url, { type: 'offer', categoryType: 'object' });
    } else if (collectionName === 'need') {
      url = addParametersToUrl(url, { type: 'need' });
    } else if (collectionName === 'offer') {
      url = addParametersToUrl(url, { offer: 'offer' });
    }

    if (loadNextPage) {
      url = denormalizeEntity<User>(getState().entities, userId)?.collections?.[collectionName]?.nextPage || url;
    }

    return fetchWithAuthIfPossible(url)
      .then(response => response.json() as Promise<HydraCollectionResponse<Post>>)
      .then(data => parseHydraCollection(data))
      .then(data => normalizeAndDispatchCollectionResponse(data, dispatch))
      .then(collection => {
        dispatch(fetchUserPostsAction.success({ userId, collectionName, collection }));
        return collection;
      })
      .catch(error => {
        dispatch(fetchUserPostsAction.failure({ userId, collectionName, error }));
        return Promise.reject(error);
      });
  };
}

export function fetchAllCurrentUserPosts(): ThunkResult<Promise<void>> {
  return async (dispatch, getState) => {
    const userId: EntityReference = extractId(getState().app.currentUser);
    const url = addParametersToUrl(postsBaseUrl, { 'state[]': currentUserDisplayedStates });
    // We don't want to use postsSearchBaseUrl here as we also want unpublished posts

    // user.objectsOffers
    dispatch(fetchUserPostsFiltered(userId, url, 'objectsOffers'));
    // user.servicesOffers
    dispatch(fetchUserPostsFiltered(userId, url, 'servicesOffers'));
    // user.need
    dispatch(fetchUserPostsFiltered(userId, url, 'need'));
  };
}

export function fetchUserPosts(userId: EntityReference, collectionName: UserCollectionName, loadNextPage = false): ReturnType<typeof fetchUserPostsFiltered> {
  return fetchUserPostsFiltered(userId, postsSearchBaseUrl, collectionName, loadNextPage);
}

export function filterQueryParamToSearchParam(params: SearchFilter, categories: Category[]): SearchQueryParameters {
  const searchQueryParameters: SearchQueryParameters = {
    // Object is Selected if params['categoryType'] === object or undefined
    // Service is Selected if params['categoryType'] === service or undefined
    categorySelection: { object: { isSelected: params['categoryType'] !== 'service' }, service: { isSelected: params['categoryType'] !== 'object' } },
    dateOrder: 'desc',
    locationAddress: null,
    postType: params['type'] || 'offer',
    searchText: params['search'] || '',
    size: params['size'],
    universe: params['universe'],
  };

  const location: string[] = (params['fakeLocation[center]'] || '').split(',');
  if (location.length === 2) {
    searchQueryParameters['locationCenter'] = { latitude: +location[0], longitude: +location[1] };
  }

  if (params['fakeLocation[radius]'] !== undefined) {
    searchQueryParameters['locationRadius'] = params['fakeLocation[radius]'];
  }

  if (params['order[updatedAt]']) {
    searchQueryParameters['dateOrder'] = params['order[updatedAt]'];
  }

  if (params['locationAddress']) {
    searchQueryParameters['locationAddress'] = { formatted: params['locationAddress'] };
  }

  const paramsCategories: string[] = params['category[]'] || [];

  paramsCategories.forEach(categoryName => {
    const category = categories.find(item => item.name === categoryName);

    if (category?.type === 'object' || category?.type === 'service') {
      if (category.parent && paramsCategories.includes(category.parent.name)) {
        if (!searchQueryParameters.childCategories) {
          searchQueryParameters.childCategories = [];
        }
        searchQueryParameters.childCategories?.push(category);
      } else {
        searchQueryParameters['categorySelection'][category?.type].category = category;
      }
    }
  });

  return searchQueryParameters;
}

export function filterSearchParamToQueryParam(params: SearchQueryParameters): QueryParameters {
  const queryParams: QueryParameters = { type: params.postType };

  if (params.searchText) {
    queryParams['search'] = params.searchText;
  }

  if (params.createdByManagedData && params.createdByManagedData.value !== 'particular' && params.createdByManagedData.value !== 'all') {
    queryParams['createdBy.managedData.type'] = params.createdByManagedData.value;
    if (params.createdByManagedData.type === 'certified') {
      queryParams['createdBy.managedData.certified'] = 'true';
    }
  }

  if (params.createdByManagedData && params.createdByManagedData.value === 'particular') {
    queryParams['createdBy.managed'] = 'false';
  }

  if (params.locationCenter && params.locationRadius) {
    queryParams['fakeLocation[center]'] = getFakeLocationCenter(params.locationCenter);
    queryParams['fakeLocation[radius]'] = params.locationRadius;
  }

  if (params.dateOrder) {
    queryParams['order[updatedAt]'] = params.dateOrder;
  }

  const { object, service } = params.categorySelection;

  if (object.isSelected || service.isSelected) {
    if (!object.isSelected || !service.isSelected) {
      queryParams['categoryType'] = object.isSelected ? 'object' : 'service';
    } else {
      // If object and services are selected but one of them doesn't have categories, we select only the one with a category
      if (object.category && !service.category) {
        queryParams['categoryType'] = 'object';
      }
      if (service.category && !object.category) {
        queryParams['categoryType'] = 'service';
      }
    }

    if ((object.isSelected || service.isSelected) && ((object.isSelected && object.category) || (service.isSelected && service.category) || params.childCategories?.length)) {
      queryParams['category[]'] = [];
      if (object.isSelected && object.category) {
        queryParams['category[]'].push(object.category.name);
      }
      if (service.isSelected && service.category) {
        queryParams['category[]'].push(service.category.name);
      }
      if ((object.category || service.category) && params.childCategories?.length) {
        queryParams['category[]'] = queryParams['category[]'].concat(params.childCategories?.map(category => category.name));
      }
    }

    if (object.isSelected && object.category && params.size !== undefined) {
      queryParams['size'] = params.size;
    }
    if (object.isSelected && object.category && params.universe !== undefined) {
      queryParams['universe'] = params.universe;
    }
  }

  if (params.itemsPerPage !== undefined) {
    queryParams['itemsPerPage'] = params.itemsPerPage;
  }

  if (params.locationAddress) {
    queryParams['locationAddress'] = params.locationAddress.formatted;
  }

  return queryParams;
}

export function fetchSearchPosts(params?: SearchQueryParameters, loadNextPage = false): ThunkResult<Promise<void>> {
  return async (dispatch, getState) => {
    dispatch(fetchPostsCollectionAction.request({ loadNextPage, collectionName: 'search' }));

    let url = postsSearchBaseUrl;

    if (params) {
      url = addParametersToUrl(url, filterSearchParamToQueryParam(params));
    }

    if (!url.includes('itemsPerPage')) {
      url = addParametersToUrl(url, { itemsPerPage });
    }

    if (loadNextPage) {
      url = getState().posts.collections?.search?.nextPage || url;
    }

    return fetchWithAuthIfPossible(url)
      .then(response => response.json() as Promise<HydraCollectionResponse<FullPost>>)
      .then(data => parseHydraCollection(data))
      .then(collection => {
        dispatch(fetchPostsCollectionAction.success({ collectionName: 'search', collection }));
      })
      .catch(e => {
        dispatch(fetchPostsCollectionAction.failure({ collectionName: 'search', error: e }));
      });
  };
}

export function fetchSearchPostsTotalItem(params?: SearchQueryParameters): ThunkResult<Promise<number>> {
  return async () => {
    let url = addParametersToUrl(postsSearchBaseUrl, { itemsPerPage: 0 });
    if (params) {
      url = addParametersToUrl(url, filterSearchParamToQueryParam(params));
    }

    return fetchWithAuthIfPossible(url)
      .then(response => response.json() as Promise<HydraCollectionResponse<FullPost>>)
      .then(data => parseHydraCollection(data))
      .then(collection => {
        return collection.totalItems;
      })
      .catch(e => {
        return Promise.reject(e);
      });
  };
}

export function fetchPostsByCategories(
  collectionName: PostCollectionName,
  categories: Category[],
  loadNextPage = false,
  sortByCreatedDate?: boolean,
  postTypes = ['offer', 'need'],
): ThunkResult<Promise<void>> {
  return async (dispatch, getState) => {
    dispatch(fetchPostsCollectionAction.request({ collectionName }));
    const thematicNames = categories.map(cat => cat.name);
    let url = addParametersToUrl(postsSearchBaseUrl, { itemsPerPage: thematicNames.length ? itemsPerPage : 0, 'category[]': thematicNames, 'type[]': postTypes });

    if (loadNextPage) {
      url = getState().posts.collections?.[collectionName]?.nextPage || url;
    }

    if (sortByCreatedDate) {
      url = addParametersToUrl(url, { 'order[createdAt]': 'desc' });
    }

    return fetchWithAuthIfPossible(url)
      .then(response => response.json() as Promise<HydraCollectionResponse<FullPost>>)
      .then(data => parseHydraCollection(data))
      .then(data => {
        dispatch(fetchPostsCollectionAction.success({ collectionName, collection: data }));
      })
      .catch(e => {
        dispatch(fetchPostsCollectionAction.failure({ collectionName, error: e }));
      });
  };
}

export function fetchPostFromPostsStore(id: EntityReference): ThunkResult<Promise<void>> {
  return async (dispatch, getState) => {
    const collections = getState().posts.collections;
    for (const key of Object.keys(collections)) {
      if (collections[key].items?.[id] !== undefined) {
        normalizeAndDispatchEntity(collections[key].items?.[id], dispatch);
        return;
      }
    }
  };
}

export function fetchPost(id: EntityReference): ThunkResult<Promise<void>> {
  return async dispatch => {
    dispatch(fetchPostAction.request());

    return fetchWithAuthIfPossible(id)
      .then(response => response.json() as Promise<Post>)
      .then(data => normalizeAndDispatchEntity(data, dispatch))
      .then(data => {
        dispatch(fetchPostAction.success(data));
      })
      .catch(e => {
        dispatch(fetchPostAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function createUserPost(post: FullPost): ThunkResult<Promise<EntityReference>> {
  return async dispatch => {
    dispatch(createPostAction.request());

    return fetchWithAuth('/posts', {
      method: 'POST',
      data: postToApiValues(post),
    })
      .then(response => response.json() as Promise<FullPost>)
      .then(data => {
        dispatch(createPostAction.success(data));
        return normalizeAndDispatchEntity(data, dispatch);
      })
      .catch(e => {
        dispatch(createPostAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function editUserPost(post: Post): ThunkResult<Promise<EntityReference>> {
  return async dispatch => {
    dispatch(editPostAction.request());

    return fetchWithAuth(extractId(post), {
      method: 'PUT',
      data: postToApiValues(post),
    })
      .then(response => response.json() as Promise<Post>)
      .then(data => normalizeAndDispatchEntity(data, dispatch))
      .then(post => {
        dispatch(editPostAction.success(post));
        return post;
      })
      .catch(e => {
        dispatch(editPostAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function refreshPostUpdatedAt(postId: string): ThunkResult<Promise<EntityReference>> {
  return async dispatch => {
    dispatch(editPostAction.request());

    return fetchWithAuth(postId, {
      method: 'PUT',
      data: {},
    })
      .then(response => response.json() as Promise<Post>)
      .then(data => normalizeAndDispatchEntity(data, dispatch))
      .then(post => {
        dispatch(editPostAction.success(post));
        return post;
      })
      .catch(e => {
        dispatch(editPostAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function updatePostState(post: PartialPost | FullPost | undefined, transition: string, reason: EntityReference | undefined | null): ThunkResult<Promise<void>> {
  return async dispatch => {
    dispatch(updatePostStateAction.request());

    return fetchWithAuth(extractId(post) + '/state', {
      method: 'PUT',
      data: { transition, reason },
    })
      .then(response => response.json() as Promise<Post>)
      .then(data => normalizeAndDispatchEntity(data, dispatch))
      .then(() => {
        dispatch(updatePostStateAction.success(transition));
      })
      .catch(error => {
        dispatch(updatePostStateAction.failure({ error, transition }));
        return Promise.reject(error);
      });
  };
}

export function resetCollection(collectionName: PostCollectionName): ThunkResult<Promise<void>> {
  return async dispatch => {
    dispatch(resetPostCollection(collectionName));
  };
}

export function setSearchParams(params: SearchQueryParameters): ThunkResult<Promise<void>> {
  return async dispatch => {
    dispatch(setSearchParamsAction(params));
  };
}

export function resetSearchParams(): ThunkResult<void> {
  return async dispatch => {
    dispatch(resetSearchParamsAction());
  };
}

export interface MapSearchParams {
  lat: number;
  lng: number;
  radius: number;
  zoom: number;
}

export type FetchMapPosts = (params: MapSearchParams, nextPageUrl?: string) => Promise<CollectionResponse<FullPost>>;

export function fetchMapPosts(params: MapSearchParams, nextPageUrl?: string): ThunkResult<Promise<CollectionResponse<FullPost>>> {
  return async dispatch => {
    const url = addParametersToUrl(postsSearchBaseUrl, {
      'fakeLocation[center]': `${params.lat},${params.lng}`,
      'fakeLocation[radius]': params.radius,
      itemsPerPage: 100,
    });

    dispatch(fetchMapCollectionAction.request());

    return fetchWithAuthIfPossible(nextPageUrl || url)
      .then(response => response.json() as Promise<HydraCollectionResponse<FullPost>>)
      .then(data => parseHydraCollection(data))
      .then(collection => {
        dispatch(fetchMapCollectionAction.success());
        return collection;
      })
      .catch(error => {
        dispatch(fetchMapCollectionAction.failure(error));
        return Promise.reject(error);
      });
  };
}

export type FetchMapClusters = (params: MapSearchParams, nextPageUrl?: string) => Promise<CollectionResponse<PostCluster>>;

export function fetchMapClusters(params: MapSearchParams, nextPageUrl?: string): ThunkResult<Promise<CollectionResponse<PostCluster>>> {
  return async dispatch => {
    const url = addParametersToUrl('/map_clusters', {
      'geohashes[]': getDisplayedGeohashes(params),
      precision: getGeohashPrecision(params.zoom),
      itemsPerPage: 500,
    });

    dispatch(fetchMapCollectionAction.request());

    return fetchWithAuthIfPossible(nextPageUrl || url)
      .then(response => response.json() as Promise<HydraCollectionResponse<PostCluster>>)
      .then(data => parseHydraCollection(data))
      .then(collection => {
        dispatch(fetchMapCollectionAction.success());
        return collection;
      })
      .catch(error => {
        dispatch(fetchMapCollectionAction.failure(error));
        return Promise.reject(error);
      });
  };
}

export function fetchPostAnalyzedData(id: EntityReference, force = false): Promise<AnalyzerPrediction> {
  let url = id + '/analyze';

  if (force) {
    url = addParametersToUrl(url, { force: 'true' });
  }

  return fetchWithAuth(url).then(response => response.json());
}

export function fetchPostEstimateData(id: EntityReference, force = false): Promise<PostEstimateData> {
  let url = id + '/estimate';

  if (force) {
    url = addParametersToUrl(url, { force: 'true' });
  }

  return fetchWithAuth(url).then(response => response.json());
}

export function fetchSavedSearches(): ThunkResult<Promise<void>> {
  return async dispatch => {
    dispatch(fetchSavedSearchesAction.request());

    return fetchAllItems<SavedSearch>('/users/me/saved_searches')
      .then(items => {
        dispatch(fetchSavedSearchesAction.success(items));
        return;
      })
      .catch(error => {
        dispatch(fetchSavedSearchesAction.failure(error));
        return Promise.reject(error);
      });
  };
}

export function deleteSavedSearch(savedSearchId: EntityReference): ThunkResult<Promise<void>> {
  return async dispatch => {
    dispatch(deleteSavedSearchAction.request());

    return fetchWithAuth(savedSearchId, {
      method: 'DELETE',
    })
      .then(() => {
        dispatch(deleteSavedSearchAction.success(savedSearchId));
      })
      .catch(e => {
        dispatch(deleteSavedSearchAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function createSavedSearch(searchQueryParameters: SearchQueryParameters): ThunkResult<Promise<void>> {
  return async dispatch => {
    dispatch(createSavedSearchAction.request());

    return fetchWithAuth('/saved_searches', {
      method: 'POST',
      data: { searchFilters: { ...filterSearchParamToQueryParam(searchQueryParameters) }, lastFetchDate: new Date() },
    })
      .then(response => response.json() as Promise<SavedSearch>)
      .then(data => {
        dispatch(createSavedSearchAction.success(data));
        return;
      })
      .catch(e => {
        dispatch(createSavedSearchAction.failure(e));
        return Promise.reject(e);
      });
  };
}
