import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { AnyAction, Dispatch } from 'redux';
import { ActionType } from 'typesafe-actions';
import { createDeviceTokenResource } from './store/app/actions';
import { notificationReceivedAction } from './store/notifications/actions';
import { assignWindowLocation } from './utils/windowLocationHelper';
import { PushNotificationSchema, PushNotifications, Token, ActionPerformed } from './capacitor/PushNotifications';
import { isProduction } from './environment';

const pushNotificationsListeners: PluginListenerHandle[] = [];

// A simple cache avoiding receiving twice the same notification (this case can happen on web, when the current tab is the app)
const receivedNotificationsIds: string[] = [];

let notificationListenersRegistered = false;

export const registerNotificationListeners = async (dispatch: Dispatch<ActionType<AnyAction>>): Promise<void> => {
  console.info('Initializing notifications listeners');

  if (notificationListenersRegistered) {
    // This check is made because a user's app sent 906 requests to /users/me/device without reason
    if (isProduction) {
      console.error("Notification listeners are already registered. This should never happen, please check how it's possible");
    }
    return;
  }

  notificationListenersRegistered = true;

  // On success, we should be able to receive notifications
  pushNotificationsListeners.push(
    PushNotifications.addListener('registration', (token: Token) => {
      console.info('Push registration success, token: ' + token.value);
      dispatch(createDeviceTokenResource(token.value, Capacitor.getPlatform() || 'web'));
    }),
  );

  // Some issue with our setup and push will not work
  pushNotificationsListeners.push(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    PushNotifications.addListener('registrationError', (error: any) => {
      console.info('Error on registration: ' + JSON.stringify(error));
    }),
  );

  // Shows the notification payload if the app is open on our device
  pushNotificationsListeners.push(
    PushNotifications.addListener('pushNotificationReceived', (notification: PushNotificationSchema) => {
      if (notification.id) {
        if (receivedNotificationsIds.includes(notification.id)) {
          console.warn('The notification has already been received, aborting');
          return;
        }

        // memory leak protection
        if (receivedNotificationsIds.length > 1000) {
          receivedNotificationsIds.length = 0;
        }

        receivedNotificationsIds.push(notification.id);
      }

      let notificationData = notification.data;

      if (typeof notificationData.json !== 'undefined') {
        notificationData = JSON.parse(notificationData.json);
      }

      dispatch(notificationReceivedAction({ ...notification, data: notificationData }));
    }),
  );

  // Method called when tapping on a notification
  pushNotificationsListeners.push(
    PushNotifications.addListener('pushNotificationActionPerformed', (notification: ActionPerformed) => {
      let data = notification.notification.data;

      if (typeof notification.notification.data.json !== 'undefined') {
        data = JSON.parse(notification.notification.data.json);
      }

      // Open to the notification redirection page
      assignWindowLocation('/notification_redirection/' + data.id);
    }),
  );

  // Register with Apple / Google to receive push via APNS/FCM
  try {
    const res = await PushNotifications.checkPermissions();

    if (res.receive === 'granted') {
      await PushNotifications.register();
    } else {
      console.error('Notification permission denied');
    }
  } catch (e) {
    console.error(e);
    return;
  }
};

export const unregisterNotificationListeners = async (): Promise<void> => {
  notificationListenersRegistered = false;
  for (const listener of pushNotificationsListeners) {
    await listener.remove();
  }

  pushNotificationsListeners.length = 0;
};
