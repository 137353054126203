import { IonButtons, IonHeader, IonToolbar, IonPage, IonTitle } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { actions, RootState, selectors } from '../store';
import { Chat, UserChat } from '../store/chats/types';
import { denormalizeEntity } from '../store/entities/selectors';
import { extractId } from '../utils/helpers';
import { CollectionResponse, EntityReference, HydratedCollection } from '../utils/hydra';
import BackButton from '../components/BackButton';
import { GroupChatParticipantsList } from '../components/ChatsList';
import { User } from '../store/users/types';
import { PostRequest } from '../store/postRequests/types';
import { Post } from '../store/posts/types';

import './ManageChatParticipantsPage.scss';

interface StateProps {
  getChat: (chatId: EntityReference) => Chat | undefined;
  getGroupChatParticipants: (chat: Chat) => HydratedCollection<UserChat>;
}

const mapStateToProps = (state: RootState): StateProps => ({
  getChat: (chatId: EntityReference) => denormalizeEntity<Chat>(state.entities, chatId),
  getGroupChatParticipants: (chat: Chat) => selectors.chats.getGroupChatParticipants(state, chat),
});

interface DispatchProps {
  fetchUserChatsByChat: (chat: Chat) => Promise<void>;
  fetchCurrentUserChats: () => Promise<void>;
  fetchPostRequestsWithUser: (post: Post, user: EntityReference) => Promise<CollectionResponse<PostRequest>>;
}

const propsToDispatch = {
  fetchUserChatsByChat: actions.chats.fetchUserChatsByChat,
  fetchCurrentUserChats: actions.chats.fetchCurrentUserChats,
  fetchPostRequestsWithUser: actions.postRequests.fetchPostRequestsWithUser,
};

interface PageProps {
  chatId: EntityReference | null;
}

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type ChatProps = RouteComponentProps & PageProps & DispatchProps & StateProps & WithTranslation;

class ManageChatParticipantsPage extends Component<ChatProps> {
  private readonly ionRefresherRef: React.RefObject<HTMLIonRefresherElement>;
  private readonly ionInputRef: React.RefObject<HTMLIonInputElement>;

  public constructor(props: ChatProps) {
    super(props);
    this.ionRefresherRef = React.createRef<HTMLIonRefresherElement>();
    this.ionInputRef = React.createRef<HTMLIonInputElement>();
  }

  public componentDidMount(): void {
    this.updateComponent();
  }

  public componentDidUpdate(prevProps: Readonly<ChatProps>): void {
    if (prevProps.chatId === this.props.chatId) {
      return;
    }
    this.updateComponent();
  }

  private updateComponent(): void {
    const chatId = this.props.chatId;

    if (!chatId) {
      return;
    }

    const chat = this.props.getChat(chatId);

    if (chat?.post) {
      this.props.fetchCurrentUserChats().then(() => this.props.fetchUserChatsByChat(chat));
    }
  }

  public render(): ReactNode {
    const { t, chatId, getChat, getGroupChatParticipants } = this.props;

    if (!chatId) {
      return <Redirect to="/chats" />;
    }

    const chat = getChat(chatId);
    if (!chat) {
      return <Redirect to="/chats" />;
    }

    const post = chat.post;
    if (chat.type !== 'post_group_private' || !post) {
      return <Redirect to={`/chats/${chatId}`} />;
    }

    const userChats = getGroupChatParticipants(chat);

    return (
      <IonPage data-cy="manage-chat-participant-page">
        <IonHeader className="manage-chat-participant-header">
          <IonToolbar>
            <IonButtons slot="start" className="back-button-container">
              <BackButton dataCy="common-header-back-button" />
            </IonButtons>
            <IonTitle>{t('chat.manage-participants')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <GroupChatParticipantsList userChats={userChats} toggleChatItem={(user: User | undefined) => this.toggleChatItem(user, post)} />
      </IonPage>
    );
  }

  private toggleChatItem(user: User | undefined, post: Post): void {
    if (!user) return;
    const userId = extractId(user);
    if (!userId) return;

    this.props.fetchPostRequestsWithUser(post, userId).then((postRequest: CollectionResponse<PostRequest>) => {
      if (postRequest.totalItems) {
        this.props.history.push(extractId(postRequest.items[0].chat));
      }
    });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(ManageChatParticipantsPage)));
