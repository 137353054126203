import {
  IonLoading,
  IonButton,
  IonButtons,
  IonFooter,
  IonHeader,
  IonIcon,
  IonItem,
  IonToolbar,
  IonThumbnail,
  IonLabel,
  IonText,
  IonAlert,
  IonActionSheet,
  IonPage,
  IonTitle,
  withIonLifeCycle,
  IonImg,
} from '@ionic/react';
import sortBy from 'lodash/sortBy';
import clone from 'lodash/clone';
import React, { ReactNode, Component } from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import MessageInput from '../components/inputs/MessageInput';
import MessagesList from '../components/MessagesList';
import { actions, RootState } from '../store';
import { Chat, Message, UserChat } from '../store/chats/types';
import { Area, CurrentUser, CurrentUserEditFields, CurrentUserStatus, User, UserReview } from '../store/users/types';
import { extractId, setStatePromise, isCurrentUser, isUserBlocked as checkIsUserBlocked, routeTos, getPostTitle, getDisabledUserName } from '../utils/helpers';
import { EntityReference, HydratedCollection } from '../utils/hydra';
import BackButton from '../components/BackButton';
import { userChatHasUnreadMessages } from '../components/ChatsList';
import UserItem from '../components/UserItem';
import { Media } from '../store/app/types';
import { PostCardFooter } from '../components/PostCard';
import { FullPost, Location, Post } from '../store/posts/types';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { CHATS_TAB } from '../utils/TabNames';
import { PostRequest, PostRequestTransition } from '../store/postRequests/types';
import ReportModal from '../components/ReportModal';
import { getChatByChatId, getUserChatByChatId, getHydratedChatMessages, getHydratedPostReviews } from '../store/chats/selectors';
import { getCurrentUser, getUserArea, isPushNotificationsAllowed } from '../store/app/selectors';
import { getHydratedCurrentUserBlockedAndCurrentUserIsBlockedCollections, HydratedCurrentUserBlockedAndCurrentUserIsBlockedCollections } from '../store/blockedUsers/selectors';
import TurnOnNotificationBanner from '../components/TurnOnNotificationBanner';
import { BlockedUsersCollectionName } from '../store/blockedUsers/types';
import { locationPathnameIncludes } from '../utils/windowLocationHelper';
import { getAppLanguage } from '../utils/translation';
import ImageFadeIn from '../components/ImageFadeIn';
import { PushNotifications } from '../capacitor/PushNotifications';
import { uploadMediaListAndGetErrors } from '../store/app/actions';
import { itemIsAMedia } from '../components/inputs/InputMediaUploader';

import './ChatPage.scss';

interface StateProps {
  chat: Chat | undefined;
  userChat: UserChat | undefined;
  chatMessages: HydratedCollection<Message>;
  postRequestReviews: HydratedCollection<UserReview>;
  currentUser: CurrentUser;
  blockedUsersCollections: HydratedCurrentUserBlockedAndCurrentUserIsBlockedCollections;
  isUserBlockRequestLoading: boolean;
  isPushNotificationsAllowed: boolean;
  userArea?: Area;
}

const mapStateToProps = (state: RootState, props: ChatPageProps): StateProps => ({
  chat: getChatByChatId(state, props),
  userChat: getUserChatByChatId(state, props),
  chatMessages: getHydratedChatMessages(state, props),
  postRequestReviews: getHydratedPostReviews(state, props),
  currentUser: getCurrentUser(state),
  blockedUsersCollections: getHydratedCurrentUserBlockedAndCurrentUserIsBlockedCollections(state),
  isUserBlockRequestLoading: state.blockedUsers.itemIsLoading,
  isPushNotificationsAllowed: isPushNotificationsAllowed(state),
  userArea: getUserArea(state),
});

interface DispatchProps {
  fetchChatMessages: (chatId: EntityReference, page?: string) => Promise<void>;
  sendChatMessage: (chatId: EntityReference, message: string) => Promise<EntityReference>;
  updateCurrentUserStatus(currentUserStatus: Partial<CurrentUserStatus>): void;
  fetchUserChat: (chatId: EntityReference) => Promise<void>;
  fetchPost: (post: EntityReference) => Promise<void>;
  fetchPostRequestReviews: (postRequestId: EntityReference) => Promise<void>;
  updatePostRequestState: (postRequest: PostRequest, transition: PostRequestTransition) => Promise<void>;
  setToastMessage(message: string | null): void;
  blockUser(postId: EntityReference): Promise<void>;
  unblockUser(postId: EntityReference): Promise<void>;
  fetchBlockedUsers(collectionName: BlockedUsersCollectionName): Promise<void>;
  updateUser(user: Partial<CurrentUserEditFields>): Promise<CurrentUser>;
  fetchLoggedUser: () => Promise<void>;
  updateUserChat(chatId: EntityReference, userChat: Partial<UserChat>): Promise<void>;
  fetchUserPositiveReviewsNumber(userId: EntityReference): Promise<void>;
}

const propsToDispatch = {
  fetchChatMessages: actions.chats.fetchChatMessages,
  sendChatMessage: actions.chats.sendChatMessage,
  updateCurrentUserStatus: actions.app.updateCurrentUserStatus,
  fetchUserChat: actions.chats.fetchUserChat,
  fetchPost: actions.posts.fetchPost,
  fetchPostRequestReviews: actions.postRequests.fetchPostRequestReviews,
  updatePostRequestState: actions.postRequests.updatePostRequestState,
  setToastMessage: actions.layout.setToastMessageAction,
  blockUser: actions.blockedUsersActions.blockUser,
  unblockUser: actions.blockedUsersActions.unblockUser,
  fetchBlockedUsers: actions.blockedUsersActions.fetchBlockedUsers,
  updateUser: actions.app.updateUser,
  fetchLoggedUser: actions.app.fetchLoggedUser,
  updateUserChat: actions.chats.updateUserChat,
  fetchUserPositiveReviewsNumber: actions.users.fetchPositiveUserPostRequestReviewsNumber,
};

interface State {
  newMessage: string;
  showActionConfirmationAlert: boolean;
  actionButtonIsLoading: boolean;
  showActionSheet: boolean;
  showReportModal: boolean;
  isReportLoading: boolean;
  showBlockConfirmationAlert: boolean;
  isNotificationBannerOpen: boolean;
  noChatsFound: boolean;
  messageIsBeingSent: boolean;
  mediaIsBeingSent: boolean;
}

export interface ChatPageProps {
  chatId: EntityReference | null;
}

interface PostRequestButtonProps {
  outline?: boolean;
  transKey: string;
  firstButton?: boolean;
  onClickAction: () => void;
  isLoading: boolean;
  imageSrc?: string;
}

const PostRequestButton: React.FunctionComponent<PostRequestButtonProps> = ({ outline = false, transKey, firstButton = false, onClickAction, isLoading, imageSrc }: PostRequestButtonProps) => {
  return (
    <IonButton
      disabled={isLoading}
      shape="round"
      fill={outline ? 'outline' : 'solid'}
      color="primary"
      size="small"
      className={`header-action-button ${transKey === 'common.cancel' ? 'large-width' : ''} ${firstButton ? 'margin-left-auto' : ''}`}
      onClick={onClickAction}
    >
      {imageSrc && <IonImg src={imageSrc} alt="indigo-icon" />}
      <Trans i18nKey={transKey} />
    </IonButton>
  );
};

interface PostInfosProps {
  routerLink: string;
  post: Post;
  location?: Location;
  hideBookedChip?: boolean;
}

const PostInfos: React.FunctionComponent<PostInfosProps> = ({ routerLink, post, location, hideBookedChip }: PostInfosProps) => {
  const { t } = useTranslation();
  return (
    <IonItem className="post-infos" lines="none" detail={false} routerLink={routerLink}>
      {post.images?.[0] && post.state !== 'moderated' && (
        <IonThumbnail slot="start" className="post-picture">
          <ImageFadeIn thumbnail src={(post?.images?.[0] as Media).contentUrl} alt="" />
        </IonThumbnail>
      )}
      <IonLabel>
        <IonText data-cy="post-title">{getPostTitle(post, t)}</IonText>
        <PostCardFooter post={post as FullPost} locationData={location} hideBookedChip={hideBookedChip} hideCompletedChip={true} />
      </IonLabel>
    </IonItem>
  );
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type ChatProps = ChatPageProps & DispatchProps & StateProps & WithTranslation;

class ChatPage extends Component<ChatProps, State> {
  private readonly ionRefresherRef: React.RefObject<HTMLIonRefresherElement>;

  public constructor(props: ChatProps) {
    super(props);
    this.ionRefresherRef = React.createRef<HTMLIonRefresherElement>();
    this.state = {
      newMessage: '',
      showActionConfirmationAlert: false,
      actionButtonIsLoading: false,
      showActionSheet: false,
      showReportModal: false,
      isReportLoading: false,
      showBlockConfirmationAlert: false,
      isNotificationBannerOpen: false,
      noChatsFound: false,
      messageIsBeingSent: false,
      mediaIsBeingSent: false,
    };

    PushNotifications.requestPermissions().then(status => {
      this.setState({
        isNotificationBannerOpen: status.receive !== 'granted' || !props.isPushNotificationsAllowed,
      });
    });
  }

  public ionViewDidEnter(): void {
    this.updateComponent();
    this.props.fetchBlockedUsers('currentUserIsBlockedCollection');
  }

  public componentDidUpdate(prevProps: Readonly<ChatProps>): void {
    if (prevProps.chatId === this.props.chatId) {
      return;
    }

    this.setState({ noChatsFound: false });
    this.updateComponent();
  }

  /* This ShouldUpdate must not verify state et props diff since some props are using reselect */
  public shouldComponentUpdate(): boolean {
    return !!this.props.chatId && locationPathnameIncludes(this.props.chatId);
  }

  private async updateComponent(): Promise<void> {
    const { chat, chatId, userChat } = this.props;

    if (!chatId) {
      return;
    }

    if (this.state.noChatsFound) {
      return;
    }

    try {
      // TODO: find a cleaner way to do that
      if (chat?.type !== 'direct') {
        await this.props.fetchUserChat(chatId);
      }
      await this.props.fetchChatMessages(chatId);
    } catch (e) {
      this.setState({ noChatsFound: true });
    }

    if (chat?.type === 'post_request') {
      if (chat.postRequest?.post) {
        this.props.fetchPost(extractId(chat.postRequest.post));
      }

      if (chat.postRequest?.state === 'completed') {
        this.props.fetchPostRequestReviews(extractId(chat.postRequest));
      }

      if (chat?.properties?.firstUsers?.[0]) {
        this.props.fetchUserPositiveReviewsNumber(extractId(chat.properties.firstUsers[0]));
      }
    }

    this.handleUserChatStatus(userChat);
  }

  public render(): ReactNode {
    const { t, chatId, chat, fetchChatMessages, currentUser, updateUser, blockedUsersCollections, userArea } = this.props;
    const { showActionSheet, isReportLoading, newMessage, showBlockConfirmationAlert, isNotificationBannerOpen, noChatsFound } = this.state;
    const currentLocation = userArea?.location as Location;

    if (!chatId || noChatsFound) {
      return <Redirect to="/chats" />;
    }

    if (!chat) {
      return (
        <IonPage data-cy="chat-page">
          <IonLoading spinner="bubbles" isOpen={true} />
        </IonPage>
      );
    }

    const postRequest: PostRequest | null = chat.postRequest;
    const post: Post | null = chat.post;
    const userId = extractId(chat.properties?.firstUsers?.[0], true);
    const isUserBlocked = checkIsUserBlocked(blockedUsersCollections, userId, 'currentUserBlockedCollection');
    const isCurrentUserBlocked = checkIsUserBlocked(blockedUsersCollections, userId, 'currentUserIsBlockedCollection');
    const otherUser = chat.properties?.firstUsers?.[0];
    const isPostModerated = chat.postRequest?.post.state === 'moderated';
    return (
      <IonPage data-cy="chat-page">
        <IonHeader className="chat-header">
          <IonToolbar>
            <IonButtons slot="start" className="back-button-container">
              <BackButton dataCy="chat-page-back-button" />
            </IonButtons>

            {chat.type === 'direct' && <UserItem user={otherUser} isCurrentUser={false} />}
            {chat.type === 'post_request' && (
              <IonButton className="chat-user" color="dark" fill="clear" routerLink={`/chats${userId}`}>
                <span>{!otherUser?.disabled ? otherUser?.name : getDisabledUserName(otherUser as User, t)}</span>
              </IonButton>
            )}
            {chat.type === 'post_group_private' && <IonTitle color="dark">{t('chat.group-title')}</IonTitle>}
            {chat.type !== 'post_group_private' && (
              <IonButtons slot="end">
                <IonButton color="dark" onClick={this.toggleActionSheet} data-cy="chat-options">
                  <IonIcon slot="icon-only" icon="/assets/navigation/more.svg" />
                </IonButton>
              </IonButtons>
            )}
          </IonToolbar>
          {!!postRequest && chat.type !== 'post_group_private' && (
            /* If there is a post request linked to the chat, we show the post, then the action linked to the post request state */
            <>
              <PostInfos
                routerLink={`/${CHATS_TAB}${extractId(chat.postRequest?.post)}`}
                post={postRequest.post}
                location={currentLocation}
                hideBookedChip={isCurrentUser(this.props.currentUser, postRequest.user) || (postRequest?.state !== 'accepted' && postRequest?.state !== 'completed')}
              />
              {!isPostModerated && (
                <>
                  {postRequest?.state === 'waiting' || postRequest?.state === 'accepted' ? (
                    /* States waiting and accepted will have different interfaces depending on side of the postRequest the user is (creator or not) */
                    this.renderHeaderButtonsForWaitingAndAcceptedPostRequestStates(postRequest)
                  ) : (
                    /* States canceled, completed and rejected will show the same interface, whatever side of the postRequest the user is (creator or not) */
                    <IonItem lines="none">
                      <IonText color="primary" className="state-label margin-left-auto">
                        <Trans i18nKey={`chat.${postRequest?.state}`} />
                      </IonText>
                    </IonItem>
                  )}
                </>
              )}
            </>
          )}
          {!!post && chat.type === 'post_group_private' && chat.properties?.firstUsers.length > 1 && (
            <>
              <PostInfos routerLink={extractId(post)} post={post} location={currentLocation} />
              {isCurrentUser(this.props.currentUser, post.createdBy) && (
                <IonItem lines="none">
                  <IonButton shape="round" size="small" color="primary" className="header-action-button margin-left-auto" fill="solid" routerLink={`${extractId(chatId)}/participants`}>
                    <Trans i18nKey="chat.manage-participants" />
                  </IonButton>
                </IonItem>
              )}
            </>
          )}
        </IonHeader>

        {isNotificationBannerOpen && (
          <div className="fixed-banner">
            <TurnOnNotificationBanner
              refreshPageData={() => this.updateComponent()}
              currentUser={currentUser}
              updateUser={updateUser}
              isChatPage
              disabledNotifications={currentUser.disabledPushNotifications}
              showNotificationBanner={isNotificationShouldBeOpen => this.updateNotificationBannerState(isNotificationShouldBeOpen)}
            />
          </div>
        )}

        <MessagesList
          activeTab={CHATS_TAB}
          fetchNextMessagesPage={() => fetchChatMessages(extractId(chat), chat?.messages?.nextPage || '/messages')}
          messages={this.getChatMessages(chat)}
          currentUser={currentUser}
          chat={chat}
          reviews={this.props.postRequestReviews.items}
        />

        <IonLoading spinner="bubbles" isOpen={this.state.mediaIsBeingSent} />

        {isPostModerated && !otherUser?.disabled && (
          <IonLabel className="ion-text-center disabled-info" data-cy="disabled-post-info">
            <Trans i18nKey="chat.advert-deleted">
              <a key="terms" href={routeTos(getAppLanguage())} target="new">
                <Trans i18nKey="user.tos" />
              </a>
            </Trans>
          </IonLabel>
        )}

        {otherUser?.disabled && (
          <IonLabel className="ion-text-center disabled-info" data-cy="disabled-user-info">
            <Trans i18nKey="chat.user-disabled">
              <a key="terms" href={routeTos(getAppLanguage())} target="new">
                <Trans i18nKey="user.tos" />
              </a>
            </Trans>
          </IonLabel>
        )}

        {!isUserBlocked && !isCurrentUserBlocked && !otherUser?.disabled && !isPostModerated && (
          <IonFooter className="chat-footer">
            <MessageInput
              message={newMessage}
              placeholder={i18next.t('chat.input-message-placeholder')}
              disableSendButton={this.state.messageIsBeingSent}
              sendMessage={this.handleSendMessage}
              sendMedia={this.handleSendMedia}
              onChange={e => {
                const message = e.detail.value || '';
                if (message.slice(-1) === '\n' && this.state.newMessage.slice(-1) !== '\n') {
                  // We must scroll to bottom manually because of a bug on mobile devices when typing "X",
                  // then "Enter" when the textarea is already at its max-height
                  this.scrollToBottom(e.target);
                }
                this.setState({ newMessage: message });
              }}
            />
          </IonFooter>
        )}

        <IonActionSheet
          data-cy="chat-user-option-action-sheet"
          isOpen={showActionSheet}
          onDidDismiss={this.toggleActionSheet}
          buttons={[
            {
              text: this.getBlockUserActionSheetButtonText(isUserBlocked),
              role: 'destructive',
              handler: () => this.setState({ showBlockConfirmationAlert: true }),
            },
            {
              text: isReportLoading ? t('common.loading') : t('user.report'),
              role: 'destructive',
              handler: () => this.setState({ showReportModal: true }),
            },
            {
              text: t('common.cancel'),
              role: 'cancel',
            },
          ]}
        />

        {!!otherUser && (
          <IonAlert
            cssClass="confirmation-alert"
            isOpen={showBlockConfirmationAlert}
            onDidDismiss={() => this.setState({ showBlockConfirmationAlert: false })}
            message={isUserBlocked ? t('user.unblock-description', { username: otherUser?.name }) : t('user.block-description', { username: otherUser?.name })}
            header={isUserBlocked ? t('user.unblock-title', { username: otherUser?.name }) : t('user.block-title', { username: otherUser?.name })}
            buttons={[
              {
                text: t('common.cancel'),
                role: 'cancel',
                cssClass: 'secondary',
              },
              {
                text: isUserBlocked ? t('user.unblock') : t('user.block'),
                handler: () => this.toggleBlockUser(isUserBlocked, userId),
              },
            ]}
          />
        )}

        {chat.properties && (
          <ReportModal
            closeModal={this.closeModal}
            isOpen={this.state.showReportModal}
            userId={extractId(chat.properties.firstUsers?.[0])}
            setReportLoadingState={isReportLoading => this.setState({ isReportLoading })}
          />
        )}
      </IonPage>
    );
  }

  private updateNotificationBannerState = async (isNotificationShouldBeOpen: boolean): Promise<void> => {
    this.setState({
      isNotificationBannerOpen: isNotificationShouldBeOpen,
    });
    this.props.fetchLoggedUser();
  };

  private getBlockUserActionSheetButtonText = (isUserBlocked: boolean): string => {
    const { isUserBlockRequestLoading, t } = this.props;

    if (isUserBlockRequestLoading) return t('common.loading');
    if (isUserBlocked) return t('user.unblock');
    return t('user.block');
  };

  private toggleBlockUser = (isUserBlocked: boolean, userId: EntityReference): void => {
    if (isUserBlocked) {
      this.props.unblockUser(userId);
      return;
    }

    this.props.blockUser(userId);
  };

  private closeModal = (): void => {
    this.setState({ showReportModal: false });
  };

  private toggleActionSheet = (): void => {
    this.setState({ showActionSheet: !this.state.showActionSheet });
  };

  private renderHeaderButtonsForWaitingAndAcceptedPostRequestStates = (postRequest: PostRequest): ReactNode => {
    return !isCurrentUser(this.props.currentUser, postRequest.user) ? (
      /* The current user is the one who created the post and is receiving a respond/request on his post */
      this.renderCurrentUserIsPostCreator(postRequest)
    ) : (
      /* Current user is the one who respond/request the post. He hasn't created the post */
      /* Here states waiting and accepted won't change a lot of the design */
      <IonItem lines="none">
        {postRequest?.state === 'accepted' && (
          <IonItem lines="none" className="margin-left-auto booked-item">
            <IonImg src="/assets/icon/icon-lock-closed.svg" alt="lock-closed-icon" />
            <IonText>
              <Trans i18nKey="chat.booked" />
            </IonText>
          </IonItem>
        )}
        <PostRequestButton
          isLoading={this.state.actionButtonIsLoading}
          outline={postRequest?.state === 'waiting'}
          firstButton={postRequest?.state === 'waiting'}
          transKey="common.cancel"
          onClickAction={() => this.updatePostRequestState(postRequest, 'cancel')}
        />
      </IonItem>
    );
  };

  private renderCurrentUserIsPostCreator = (postRequest: PostRequest): ReactNode => {
    return (
      <>
        {postRequest.state === 'waiting' && (
          <>
            {postRequest?.post.type === 'need' && (
              <IonItem lines="none">
                <PostRequestButton isLoading={this.state.actionButtonIsLoading} outline firstButton transKey="common.cancel" onClickAction={() => this.updatePostRequestState(postRequest, 'reject')} />
                <PostRequestButton
                  isLoading={this.state.actionButtonIsLoading}
                  transKey={`chat.accept-and-complete-${postRequest.post.categoryType}`}
                  onClickAction={() => this.setState({ showActionConfirmationAlert: true })}
                />
                {/* When someone respond to a post need, the creator will accept and complete the the post request once he received the object/service */}
              </IonItem>
            )}
            {postRequest?.post.type === 'offer' && postRequest?.post.state === 'fully_requested' && (
              <IonItem lines="none" className="booked-item">
                <IonImg src="/assets/icon/icon-lock-closed.svg" alt="closed-lock-icon" />
                <IonText>
                  <Trans i18nKey="chat.booked" />
                </IonText>
              </IonItem>
            )}
            {postRequest?.post.type === 'offer' && postRequest?.post.state === 'published' && (
              <IonItem lines="none">
                <PostRequestButton
                  isLoading={this.state.actionButtonIsLoading}
                  firstButton
                  transKey={`chat.book-${postRequest.post.categoryType}`}
                  onClickAction={() => this.updatePostRequestState(postRequest, 'accept')}
                  imageSrc="/assets/icon/icon-ring-white.svg"
                />
              </IonItem>
            )}
          </>
        )}
        {postRequest.state === 'accepted' && (
          <IonItem lines="none">
            <PostRequestButton isLoading={this.state.actionButtonIsLoading} outline firstButton transKey="common.cancel" onClickAction={() => this.updatePostRequestState(postRequest, 'reject')} />
            <PostRequestButton
              isLoading={this.state.actionButtonIsLoading}
              transKey={`chat.gave-${postRequest.post.categoryType}`}
              onClickAction={() => this.setState({ showActionConfirmationAlert: true })}
            />
          </IonItem>
        )}
        <IonAlert
          cssClass="confirmation-alert"
          isOpen={this.state.showActionConfirmationAlert}
          onDidDismiss={() => this.setState({ showActionConfirmationAlert: false })}
          message={i18next.t(`chat.did-you-${postRequest?.post.type}-${postRequest?.post.categoryType}`, { userName: postRequest?.user.name })}
          buttons={[
            {
              text: i18next.t('chat.not-yet'),
              role: 'cancel',
              cssClass: 'secondary',
            },
            {
              text: i18next.t('common.yes'),
              handler: () => this.updatePostRequestState(postRequest, postRequest?.post.type === 'need' ? 'accept_and_complete' : 'complete'),
            },
          ]}
        />
      </>
    );
  };

  private updatePostRequestState = async (postRequest: PostRequest, transition: PostRequestTransition): Promise<void> => {
    if (this.state.actionButtonIsLoading) {
      return;
    }

    const key = `chat.toast-transition-${transition}-${postRequest.post.categoryType}-${postRequest.post.type}`;
    await setStatePromise(this, { actionButtonIsLoading: true });
    this.props
      .updatePostRequestState(postRequest, transition)
      .then(() => this.props.setToastMessage(i18next.t(key, { userName: postRequest.user.name })))
      .finally(() => this.setState({ actionButtonIsLoading: false }));
  };

  private getChatMessages(chat: Chat): HydratedCollection<Message> {
    const messages = clone(this.props.chatMessages);

    messages.totalItems += (chat.newMessages || []).length;
    messages.items = sortBy(messages.items.concat(chat.newMessages || []), [
      (message: Message) => {
        return message.createdAt;
      },
    ]);

    return messages;
  }

  private handleSendMedia = (file?: File[]): void => {
    if (!this.props.chatId || !file || file?.length === 0) {
      return;
    }
    this.sendMedia(file);
  };

  private async sendMedia(file: File[]): Promise<void> {
    if (!this.props.chatId || !file || file?.length === 0) {
      return;
    }
    this.setState({ mediaIsBeingSent: true });
    const mediaUploadResults = await uploadMediaListAndGetErrors(file);
    const errors: string[] = [];

    for (let i = 0; i < mediaUploadResults.length; i++) {
      const mediaOrError = mediaUploadResults[i];
      if (itemIsAMedia(mediaOrError)) {
        // If the upload result for the current file is a Media, we send message with url
        this.sendMessage(mediaOrError.contentUrl);
        continue;
      }

      errors.push(this.props.t('media.item-error', { number: i + 1, error: mediaOrError.message }));
    }

    if (errors.length) {
      this.props.setToastMessage(errors.join(', '));
    }

    this.setState({ mediaIsBeingSent: false });

    return;
  }

  private handleSendMessage = (): void => {
    if (!this.props.chatId || !this.state.newMessage.trim() || this.state.messageIsBeingSent) {
      return;
    }

    this.sendMessage(this.state.newMessage.trim());
  };

  private sendMessage = (text: string): void => {
    if (!this.props.chatId || this.state.messageIsBeingSent) {
      return;
    }

    this.setState({ messageIsBeingSent: true });
    this.props
      .sendChatMessage(this.props.chatId, text)
      .then(() => {
        // Restore the input to its original state
        this.setState({ newMessage: '', messageIsBeingSent: false });
      })
      .catch(() => {
        this.setState({ messageIsBeingSent: false });
      });
  };

  private scrollToBottom = async (target: EventTarget | null): Promise<void> => {
    if (!target) {
      return;
    }

    const el = await (target as HTMLIonTextareaElement).getInputElement();
    el.scrollTop = el.scrollHeight;
  };

  private handleUserChatStatus = (userChat: UserChat | undefined): void => {
    // If userChat isn't in the store, then the user status unreadChats won't be decremented
    if (!userChat) {
      return;
    }

    const chatHadUnreadMessages = userChatHasUnreadMessages(userChat);
    if (!chatHadUnreadMessages) {
      return;
    }

    this.props.updateUserChat(extractId(userChat), { lastReadMessageDate: new Date() });
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withIonLifeCycle(ChatPage)));
