import { useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { extractId } from '../utils/helpers';
import './SearchSuggestions.scss';
import { Thematic } from '../store/thematics/types';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import RecommendationList from './RecommendationList';
import SuggestionList from './SuggestionList';
import { SearchPageContentPlaceholder } from './placeholders/SearchPagePlaceholder';
import SuggestionListPlaceholder from './placeholders/SuggestionListPlaceholder';

interface SearchSuggestionsProps {
  userThematics: Thematic[];
}

const firstSuggestionsToShow = 2;

const SearchSuggestions: React.FunctionComponent<SearchSuggestionsProps & WithTranslation> = (props: SearchSuggestionsProps & WithTranslation) => {
  const { userThematics } = props;
  const [contentRendering, setContentRendering] = useState(false);
  const [suggestionsToShow, setSuggestionsToShow] = useState(firstSuggestionsToShow);
  const _isMounted = useRef(false);
  const _suggestionsTimeout = useRef<null | NodeJS.Timeout>(null);

  useEffect(() => {
    _isMounted.current = true;
    setTimeout(() => setContentRendering(true));
    return () => {
      _isMounted.current = false;
      if (_suggestionsTimeout.current) {
        clearTimeout(_suggestionsTimeout.current);
      }
    };
  }, []);
  useIonViewDidEnter(() => setTimeout(() => setContentRendering(true)));
  useIonViewWillLeave(() => {
    if (_isMounted.current) {
      setContentRendering(false);
      setSuggestionsToShow(firstSuggestionsToShow);
    }
  });

  if (!suggestionsToShow && !contentRendering) {
    return <SearchPageContentPlaceholder />;
  }

  let listIndex = 1;

  return (
    <div className="suggestion-component">
      <RecommendationList title={props.t('search.advisable')} userThematics={userThematics} />
      <h3 hidden={userThematics.length === 0} className="title">
        <Trans i18nKey="search.we-suggest-you" />
      </h3>
      {contentRendering &&
        userThematics.map((thematic: Thematic) => {
          const suggestionsComp = <SuggestionList key={extractId(thematic)} thematic={thematic} thematicNumber={listIndex} currentPage="search" />;

          if (suggestionsComp) {
            listIndex++;
          }

          if (listIndex > suggestionsToShow) {
            // Display one new line at the time in order to avoid too many requests at the same time
            if (!_suggestionsTimeout.current) {
              _suggestionsTimeout.current = setTimeout(() => {
                _suggestionsTimeout.current = null;
                setSuggestionsToShow(suggestionsToShow + 1);
              }, 600);
            }
            return <SuggestionListPlaceholder key={listIndex} />;
          }

          return suggestionsComp;
        })}
    </div>
  );
};

export default React.memo(withTranslation()(SearchSuggestions));
