import { SignInWithApple, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import React, { Component, ReactNode } from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { isAppleConnectEnabled } from '../utils/appleConnectAccess';
import { logoApple } from 'ionicons/icons';

interface AppleLoginProps {
  onLogin: (result: SignInWithAppleResponse) => Promise<void>;
  onLogout?: () => void;
  onError: (message: string | null) => void;
  disabled?: boolean;
}

type Props = AppleLoginProps & WithTranslation;

class AppleLogin extends Component<Props> {
  public render(): ReactNode {
    if (!isAppleConnectEnabled()) {
      return null;
    }

    return (
      <IonButton className="button-large" shape="round" size="large" expand="block" disabled={!!this.props.disabled} onClick={this.handleAppleLogin} color="dark">
        <IonIcon size="small" slot="start" icon={logoApple} className="signin-icon" />
        <Trans i18nKey={'user.apple-login'} />
      </IonButton>
    );
  }

  handleAppleLogin = (): void => {
    SignInWithApple.authorize()
      .then(data => {
        if (typeof data === 'undefined') {
          throw new Error('Unsupported platform for apple login');
        }
        if (typeof data.response?.identityToken === 'undefined') {
          throw new Error('Bad Apple sign in response');
        }
        return data;
      })
      .then(this.props.onLogin)
      .catch(this.handleError);
  };

  handleError = (e: string | Error): void => {
    if (!this.props.onError || !e) {
      return;
    }

    if (typeof e === 'string') {
      return this.props.onError(e);
    }

    if (e.message?.includes('1001')) {
      return;
    }

    return this.props.onError(e.message);
  };
}

export default withTranslation()(AppleLogin);
