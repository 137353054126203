import { IonChip, IonIcon, IonLabel } from '@ionic/react';
import React from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { RootState } from '../store';
import { ChatQueryFilters } from '../store/chats/types';
import { getPostByPostId } from '../store/posts/selectors';
import { Post } from '../store/posts/types';

interface StateProps {
  selectedPost?: Post;
}

const mapStateToProps = (state: RootState, props: ChatSelectedFiltersListProps): StateProps => ({
  selectedPost: getPostByPostId(state, { postId: props.filters.postId || null }),
});

interface ChatSelectedFiltersListProps {
  filters: ChatQueryFilters;
  onSelectedPost: (filterName: keyof ChatQueryFilters) => void;
  setFilters: (filters: string[]) => void;
}

type Props = StateProps & ChatSelectedFiltersListProps;

const ChatSelectedFiltersList: React.FunctionComponent<Props> = ({ onSelectedPost, selectedPost, filters, setFilters }: Props) => {
  return (
    <div className="selected-chat-filters-list" data-cy="selected-chat-filters-list">
      {selectedPost && (
        <IonChip className="ion-no-margin text-overflow-chip post-name-ion-chip">
          <div className="text-overflow-chip-content">
            <IonLabel>{selectedPost.title}</IonLabel>
            <IonIcon icon="/assets/icon/icon-close-modal.svg" onClick={() => onSelectedPost('postId')} />
          </div>
        </IonChip>
      )}
      {filters.selectedFilters.map(selectedFilter => {
        return (
          <IonChip className="ion-no-margin text-overflow-chip selected-filter" key={selectedFilter}>
            <div className="text-overflow-chip-content">
              <IonLabel>
                <Trans i18nKey={`chat-filters.chip.${selectedFilter}`} />
              </IonLabel>
              <IonIcon
                icon="/assets/navigation/close-white.svg"
                data-cy={`close-chip-${selectedFilter}`}
                onClick={() =>
                  setFilters(
                    filters.selectedFilters.filter(filter => {
                      return filter !== selectedFilter;
                    }),
                  )
                }
              />
            </div>
          </IonChip>
        );
      })}
    </div>
  );
};

export default connect(mapStateToProps, null)(ChatSelectedFiltersList);
