import { Capacitor } from '@capacitor/core';
import { Clipboard } from '@capacitor/clipboard';
import { ellipsisHorizontal, link } from 'ionicons/icons';
import React, { useState, useEffect } from 'react';
import { IonButton, IonButtons, IonIcon, IonText } from '@ionic/react';
import { Trans } from 'react-i18next';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookMessengerIcon, FacebookShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import { SocialSharing } from '@ionic-native/social-sharing';
import { browserShare, deviceCanShare, shareViaFacebook, shareViaMail, shareViaMessenger, shareViaSms, shareViaWhatsapp, socialShare } from '../utils/shareHelper';
import './ShareButtons.scss';
import { actions } from '../store';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

interface ShareButtonsProps {
  shareUrl: string;
  shareTitle: string;
  shareMessage: string;
  shareComponentTitle: string;
}

interface DispatchProps {
  setToastMessage: (message: string) => void;
}

const propsToDispatch = {
  setToastMessage: actions.layout.setToastMessageAction,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type Props = ShareButtonsProps & DispatchProps;

const ShareButtons: React.FunctionComponent<Props> = ({ shareComponentTitle, setToastMessage, shareUrl, shareTitle, shareMessage }: Props) => {
  const isNative = Capacitor.isNativePlatform();
  const iconSize = 40;
  const [canShareViaWhatsapp, setShareViaWhatsapp] = useState(false);
  const [canShareViaFacebook, setShareViaFacebook] = useState(false);
  const [canShareViaMessenger, setShareViaMessenger] = useState(false);

  useEffect(() => {
    if (!isNative) {
      return;
    }

    const facebookBundleName = Capacitor.getPlatform() === 'ios' ? 'com.apple.social.facebook' : 'com.facebook';
    const whatsappBundleName = Capacitor.getPlatform() === 'ios' ? 'whatsapp' : 'com.whatsapp';
    // Todo Find Messenger bundle name on ios
    const messengerBundleName = 'com.facebook.orca';

    SocialSharing.canShareVia(whatsappBundleName, 'msg').then(() => {
      setShareViaWhatsapp(true);
    });

    SocialSharing.canShareVia(facebookBundleName, 'msg').then(() => {
      setShareViaFacebook(true);
    });

    SocialSharing.canShareVia(messengerBundleName, 'msg').then(() => {
      setShareViaMessenger(true);
    });
  }, []);

  const copyToClipboard = async (): Promise<void> => {
    // This function will fail on HTTP
    try {
      await Clipboard.write({ url: shareUrl });
      setToastMessage('user.copy-to-clipboard-success');
    } catch (e) {
      setToastMessage(e);
    }
  };

  return (
    <div className="share-buttons-wrapper">
      <IonText className="share-buttons-title">
        <Trans i18nKey={shareComponentTitle} />
      </IonText>
      {isNative ? (
        <IonButtons className="share-buttons">
          <IonButton className="sms-share-button" onClick={() => shareViaSms(shareMessage, shareUrl).catch(e => setToastMessage(e))}>
            <IonIcon slot="icon-only" icon="/assets/icon/sms-share-icon.svg" />
          </IonButton>
          <IonButton onClick={() => shareViaMail(shareTitle, `${shareMessage} : `, shareUrl).catch(e => setToastMessage(e))}>
            <EmailIcon size={iconSize} round />
          </IonButton>
          {canShareViaFacebook && (
            <IonButton onClick={() => shareViaFacebook(shareMessage, shareUrl).catch(e => setToastMessage(e))}>
              <FacebookIcon size={iconSize} round />
            </IonButton>
          )}
          {canShareViaWhatsapp && (
            <IonButton onClick={() => shareViaWhatsapp(shareMessage, shareUrl).catch(e => setToastMessage(e))}>
              <WhatsappIcon size={iconSize} round />
            </IonButton>
          )}
          {canShareViaMessenger && (
            <IonButton onClick={() => shareViaMessenger(shareMessage, shareUrl).catch(e => setToastMessage(e))}>
              <FacebookMessengerIcon size={iconSize} round />
            </IonButton>
          )}
          <IonButton className="copy-link-button" onClick={copyToClipboard}>
            <IonIcon slot="icon-only" icon={link} />
          </IonButton>
          <IonButton className="open-share-modal-button" onClick={() => socialShare(shareTitle, shareMessage, shareUrl).catch(e => setToastMessage(e))}>
            <IonIcon slot="icon-only" icon={ellipsisHorizontal} />
          </IonButton>
        </IonButtons>
      ) : (
        <IonButtons className="share-buttons">
          <EmailShareButton url={shareUrl} subject={shareTitle} body={`${shareMessage.replace('/n', '<br/>')} : `}>
            <EmailIcon size={iconSize} round />
          </EmailShareButton>

          <FacebookShareButton url={shareUrl} quote={shareMessage}>
            <FacebookIcon size={iconSize} round />
          </FacebookShareButton>

          <WhatsappShareButton url={shareUrl} title={shareMessage}>
            <WhatsappIcon size={iconSize} round />
          </WhatsappShareButton>

          <IonButton className="copy-link-button" onClick={copyToClipboard}>
            <IonIcon slot="icon-only" icon={link} />
          </IonButton>

          {deviceCanShare && (
            <IonButton className="open-share-modal-button" onClick={() => browserShare(shareTitle, shareMessage, shareUrl).catch(e => setToastMessage(e))}>
              <IonIcon slot="icon-only" icon={ellipsisHorizontal} />
            </IonButton>
          )}
        </IonButtons>
      )}
    </div>
  );
};

export default connect(null, mapDispatchToProps)(ShareButtons);
