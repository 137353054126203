import omit from 'lodash/omit';
import * as layout from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { LayoutState } from './types';

// Caution: this state is not persisted in local storage
const defaultState: LayoutState = {
  toastMessages: {},
  geolocationData: {
    canAskForPermissionsInHomepage: true,
  },
  lastActionUpdatingChatsListDate: new Date(),
};

export type LayoutAction = ActionType<typeof layout>;

export default (state = defaultState, action: LayoutAction): LayoutState => {
  switch (action.type) {
    case getType(layout.setToastMessageAction): {
      const newKey = Object.keys(state.toastMessages).length > 0 ? Math.max(...Object.keys(state.toastMessages).map(val => parseFloat(val))) + 1 : 0;
      return { ...state, toastMessages: { ...state.toastMessages, ['' + newKey]: action.payload } };
    }
    case getType(layout.unsetToastMessageAction):
      return { ...state, toastMessages: omit(state.toastMessages, [action.payload]) };
    case getType(layout.setGeolocationDataAction):
      return {
        ...state,
        geolocationData: {
          ...state.geolocationData,
          ...action.payload,
          forceRefreshPosition: undefined,
          askPermissions: undefined,
          verifyPermissionsBeforeRefresh: undefined,
          canOpenNativeSettings: undefined,
          canAskForPermissionsInHomepage: false,
        },
      };
    case getType(layout.askForGeolocationDataAction):
      return {
        ...state,
        geolocationData: {
          ...state.geolocationData,
          lastAskForPositionDate: new Date(),
          forceRefreshPosition: action.payload?.forceRefreshPosition,
          askPermissions: action.payload?.askPermissions,
          verifyPermissionsBeforeRefresh: action.payload?.verifyPermissionsBeforeRefresh,
          canOpenNativeSettings: action.payload?.canOpenNativeSettings,
        },
      };
    case getType(layout.fetchManagedUserInfosAction.success):
      return { ...state, managedUserInfos: action.payload };
    case getType(layout.setUpgradeLayoutStatusAction):
      return { ...state, upgradeLayoutStatus: action.payload };
    case getType(layout.setLastActionUpdatingChatsListDate):
      return { ...state, lastActionUpdatingChatsListDate: action.payload };
    default:
      return state;
  }
};
