import React from 'react';
import { IonItem, IonLabel, IonList, IonToggle } from '@ionic/react';
import { Trans } from 'react-i18next';
import './SettingsToggleComponent.scss';

type NotificationsProps = {
  enableDisableNotification?: (e: CustomEvent) => void;
  disabledOptions: string[];
  notificationsData: NotificationBlock[];
  isSubmitting: boolean;
};

interface NotificationBlock {
  titleOfNotificationBlock: string;
  notificationItems: NotificationItem[];
}

interface NotificationItem {
  title: string;
  optionValue: string;
}

const SettingsToggleComponent: React.FunctionComponent<NotificationsProps> = ({ notificationsData, disabledOptions, enableDisableNotification, isSubmitting }: NotificationsProps) => {
  return (
    <>
      {notificationsData.map((itemBlock, index) => {
        return (
          <div key={index} className="notification-settings-block">
            <IonLabel className="label-name">
              <Trans i18nKey={`notification-settings.${itemBlock.titleOfNotificationBlock}`} />
            </IonLabel>
            <IonList className="list-of-toggles" data-cy="notification-settings-list-of-toggles">
              {itemBlock.notificationItems.map((item, index: number) => {
                return (
                  <IonItem key={index} lines="none">
                    <IonLabel>{<Trans i18nKey={`notification-settings.${item.title}`} />}</IonLabel>
                    <IonToggle
                      color="medium"
                      data-cy="settings-option"
                      value={item.optionValue}
                      checked={!disabledOptions.includes(item.optionValue)}
                      disabled={isSubmitting}
                      onIonChange={enableDisableNotification}
                    />
                  </IonItem>
                );
              })}
            </IonList>
          </div>
        );
      })}
    </>
  );
};

export default SettingsToggleComponent;
