import React from 'react';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';
import { clientRegEx } from '../environment';

const mdLinkClientRegEx = RegExp(']\\(' + clientRegEx.source, clientRegEx.flags);
const mdLinkClientRegExReplace = '](/';

// This is a named function in order to avoid react/prop-type errors
export function getTargetBlankLink({ ...props }: ReactMarkdownProps & React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>): JSX.Element {
  if (props.href?.substr(0, 4) !== 'http') {
    return <a href={props.href}>{props.children}</a>;
  }
  return (
    <a href={props.href} rel="nofollow noreferrer noopener" target="_blank">
      {props.children}
    </a>
  );
}

export function getMarkdownReadyMessage(message: string): string {
  return message.replace(mdLinkClientRegEx, mdLinkClientRegExReplace);
}
