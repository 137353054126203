import { IonPage } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RootState, actions } from '../store';
import { Post, PostType } from '../store/posts/types';
import ErrorComponent from '../ErrorComponent';
import PostForm from '../components/PostForm';
import { EntityReference } from '../utils/hydra';
import { FF } from '../store/app/types';
import { apiEntrypoint } from '../environment';
import withRouterAndRef from '../utils/withRouterAndRef';
import { RouteComponentProps } from 'react-router';

import './CreatePostPage.scss';
import IndigoDevientDonnonsModal from '../IndigoDevientDonnonsModal';

interface DispatchProps {
  createPost(post: Post): Promise<EntityReference>;
  setFFIndigoDevientDonnonsLastDate: (type: 'homepage' | 'give', date: Date) => void;
}

const propsToDispatch = {
  createPost: actions.posts.createUserPost,
  setFFIndigoDevientDonnonsLastDate: actions.app.setFFIndigoDevientDonnonsLastDate,
};

interface StateProps {
  ff: FF;
}

const mapStateToProps = (state: RootState): StateProps => ({
  ff: state.app.ff,
});

interface State {
  indigoDevientDonnons: boolean;
}

interface Props {
  type: PostType;
}

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type CreatePostProps = Props & DispatchProps & StateProps & RouteComponentProps<Record<string, never>>;

class CreatePostPage extends Component<CreatePostProps, State> {
  constructor(props: CreatePostProps) {
    super(props);

    this.state = {
      indigoDevientDonnons: false,
    };

    this.onIndigoDevientDonnonsClose = this.onIndigoDevientDonnonsClose.bind(this);
  }

  private readonly onIndigoDevientDonnonsClose = (): void => {
    this.setState({ indigoDevientDonnons: false });
    this.props.setFFIndigoDevientDonnonsLastDate('give', new Date());
  };

  public componentDidMount(): void {
    if (
      (this.props.ff.indigo_devient_donnons.envs.includes('preprod') && apiEntrypoint.includes('preprod')) ||
      (this.props.ff.indigo_devient_donnons.envs.includes('production') && !apiEntrypoint.includes('preprod'))
    ) {
      const hoursDiff = Math.abs(new Date().getTime() - (this.props.ff.indigo_devient_donnons.lastDate.homepage ?? new Date().getTime())) / 36e5;

      if (hoursDiff >= 24 || !this.props.ff.indigo_devient_donnons.lastDate.give) {
        this.setState({ indigoDevientDonnons: true });
      }
    }
  }

  public render(): ReactNode {
    const { type } = this.props;

    if (!['offer', 'need'].includes(type)) {
      return <ErrorComponent error={404} />;
    }

    return (
      <IonPage data-cy="create-post-page">
        {this.state.indigoDevientDonnons && this.props.ff.indigo_devient_donnons.data && (
          <IndigoDevientDonnonsModal type="give" onClose={this.onIndigoDevientDonnonsClose} data={this.props.ff.indigo_devient_donnons.data} />
        )}
        <PostForm type={type} submit={post => this.props.createPost(post)} submitButtonTextKey="post.submit" />
      </IonPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouterAndRef(CreatePostPage));
