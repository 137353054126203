import * as categoriesActions from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { CategoryState } from './types';

const defaultState: CategoryState = {
  categories: [],
};

export default (state = defaultState, action: ActionType<typeof categoriesActions>): CategoryState => {
  switch (action.type) {
    case getType(categoriesActions.fetchCategoriesAction.success):
      return { ...state, categories: action.payload.categories };
    default:
      return state;
  }
};
