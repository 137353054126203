import { ActionType, getType } from 'typesafe-actions';
import { createReferencedCollection, initReferencedCollection, mergeReferencedCollections } from '../../utils/redux';
import * as blockedUsersActions from './actions';
import { BlockedUsersState } from './types';

const defaultState: BlockedUsersState = {
  collections: {
    currentUserBlockedCollection: createReferencedCollection(),
    currentUserIsBlockedCollection: createReferencedCollection(),
  },
  itemIsLoading: false,
};

export default (state = defaultState, action: ActionType<typeof blockedUsersActions>): BlockedUsersState => {
  switch (action.type) {
    case getType(blockedUsersActions.fetchBlockedUsersAction.request):
      return { ...state, collections: { ...state.collections, [action.payload]: initReferencedCollection(state.collections[action.payload], true) } };
    case getType(blockedUsersActions.fetchBlockedUsersAction.success):
      return {
        ...state,
        collections: { ...state.collections, [action.payload.collectionName]: mergeReferencedCollections(action.payload.collection, state.collections[action.payload.collectionName]) },
      };
    case getType(blockedUsersActions.fetchBlockedUsersAction.failure):
      return { ...state, collections: { ...state.collections, [action.payload.collectionName]: initReferencedCollection(state.collections[action.payload.collectionName], false) } };

    default:
      return state;
  }
};
