import { briefcase, globeOutline } from 'ionicons/icons';
import React from 'react';
import { IonRow, IonCol, IonText, IonRouterLink, IonIcon } from '@ionic/react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { extractId, isSameHydraEntity } from '../../utils/helpers';
import { RootState } from '../../store';
import { CurrentUser, ManagedData, User } from '../../store/users/types';
import i18next from 'i18next';
import './UserComponents.scss';

interface Props {
  user: User;
  currentUser?: CurrentUser;
}

const getManagedUserTypeTranslation = (managedData: ManagedData | undefined): string => {
  switch (managedData?.type) {
    case 'ngo':
      return 'user.ngo';
    case 'public_sector':
      return 'user.public-sector';
    case 'public_structure':
      return 'user.public-structure';
    case 'reuse_organization':
      return 'user.reuse-organization';
    default:
      return 'user.ngo';
  }
};

type UserOverviewProps = Props & WithTranslation;

const UserOverview: React.FunctionComponent<UserOverviewProps> = ({ user, currentUser }: UserOverviewProps) => {
  const activeTab = useSelector((state: RootState) => state.navigation.activeTab);
  const userLink = activeTab ? `/${activeTab}${extractId(user)}` : extractId(user);

  return (
    <IonRow responsive-sm className="overview">
      <IonCol size="12">
        <div className="title">
          <IonText color="dark">
            <Trans i18nKey="user.overview" />
          </IonText>
        </div>
      </IonCol>
      <IonCol size="12">
        {user?.managed && (
          <div className="overview-item">
            <IonIcon icon={briefcase} />
            <Trans i18nKey={getManagedUserTypeTranslation(user.managedData)} />
          </div>
        )}

        {isSameHydraEntity(currentUser, user) && currentUser?.email && (
          <>
            <div className="overview-item mb-0">
              <img src="/assets/icon/icon-at.svg" alt="Your address" />
              {currentUser?.email}
            </div>
            <IonText className="overview-comment">{i18next.t('user.your-email-is-not-viewable')}</IonText>
          </>
        )}

        {user?.createdAt && (
          <div className="overview-item">
            <img src="/assets/icon/member-since.svg" alt="Indigo" />
            <Trans i18nKey="user.member-since">
              {{
                date: moment(user.createdAt).format('MMM YYYY').toLowerCase(),
              }}
            </Trans>
          </div>
        )}

        <IonRouterLink routerLink={userLink + '/favorite_users'} className="overview-item">
          <img src="/assets/icon/icon-followers.svg" alt="Your followers" />
          <IonText color="primary">{i18next.t('user.user-followers', { count: user?.collections?.favoritedByUsers?.totalItems || 0 })}</IonText>
        </IonRouterLink>

        {user.managedData?.website && (
          <div className="overview-item">
            <IonIcon icon={globeOutline} />
            <a color="primary" href={user.managedData.website} target="_blank" rel="noopener noreferrer">
              {user.managedData.website}
            </a>
          </div>
        )}
      </IonCol>
    </IonRow>
  );
};

export default React.memo(
  withTranslation()(UserOverview),
  (prevProps, nextProps) => prevProps.user.collections?.favoritedByUsers?.totalItems === nextProps.user.collections?.favoritedByUsers?.totalItems,
);
