import { IonButton, IonButtons, IonHeader, IonIcon, IonImg, IonLoading, IonPage, IonText, IonThumbnail, IonToolbar } from '@ionic/react';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { NotificationContent } from '../components/NotificationModal';
import { RootState, selectors } from '../store';
import { UserNotification } from '../store/notifications/types';
import { EntityReference } from '../utils/hydra';
import { useTranslation, withTranslation } from 'react-i18next';
import { titleNotificationModal } from '../utils/helpers';

import '../components/NotificationModal.scss';

interface StateProps {
  userNotification: UserNotification | undefined;
}

const mapStateToProps = (state: RootState, props: { notificationId: EntityReference | null }): StateProps => ({
  userNotification: selectors.notifications.getUserNotificationByNotificationId(state, props),
});

interface State {
  notificationId: EntityReference | null;
}

type NotificationsProps = StateProps & State;

const NotificationPage: React.FunctionComponent<NotificationsProps> = ({ userNotification }: NotificationsProps) => {
  const { t } = useTranslation();
  if (!userNotification) {
    return (
      <IonPage data-cy="global-notification-page">
        <IonLoading isOpen={true} spinner="bubbles" showBackdrop />
      </IonPage>
    );
  }

  return (
    <IonPage data-cy="notification-page">
      <IonHeader translucent no-border>
        <IonToolbar className="notification-toolbar">
          <div className="notification-toolbar-date-wrapper">
            <IonThumbnail className="date-logo">
              <IonImg src="/assets/logo-indigo-gradient.svg" alt="Indigo" />
            </IonThumbnail>
            <IonText color="dark" className="glob-notification-date-title">
              {titleNotificationModal(userNotification, t)}
            </IonText>
            <IonText color="dark" className="notification-date-title">
              &bull;
            </IonText>
            <IonText color="medium" className="date">
              {moment(new Date(userNotification.notification.updatedAt)).format('DD MMMM YYYY')}
            </IonText>
          </div>
          <IonButtons slot="end">
            <IonButton data-cy="notification-toolbar-close-button" routerLink="/notifications">
              <IonIcon className="close-modal-button" icon="/assets/navigation/close.svg" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <NotificationContent userNotification={userNotification} />
    </IonPage>
  );
};

export default connect(mapStateToProps)(withTranslation()(NotificationPage));
