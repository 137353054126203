import { WebPlugin } from '@capacitor/core';
import { FacebookCurrentAccessTokenResponse, FacebookLoginPlugin, FacebookLoginResponse } from '@capacitor-community/facebook-login';
import { FacebookUserFullInfos, FacebookUserInfos, FacebookUserPictureData } from '../utils/facebookAccess';
import { jsonFetch, throwErrorIfAny } from '../utils/dataAccess';

let currentFacebookId: string | null = null;

export interface FacebookDataPlugin {
  getFacebookUserIdAndFullname(token: string): Promise<FacebookUserInfos>;
  getFacebookUserInfos(facebookId: string, token: string): Promise<FacebookUserFullInfos>;
  getFacebookUserPicture(facebookId: string): Promise<FacebookUserPictureData>;
}

export class FacebookLoginMock extends WebPlugin implements FacebookLoginPlugin, FacebookDataPlugin {
  initialize(): Promise<void> {
    return Promise.resolve();
  }

  login(): Promise<FacebookLoginResponse> {
    return new Promise((resolve, reject) => {
      const fbId = prompt('You are using HTTP, this is the FB mock plugin. Please type the wanted FacebookID (any integer), or nothing for an error');

      if (!fbId) {
        reject({ accessToken: { token: null } });
        return;
      }

      currentFacebookId = fbId;

      resolve({
        accessToken: {
          token: 'access-' + currentFacebookId,
        },
      });
    });
  }

  logout(): Promise<void> {
    return new Promise(resolve => {
      currentFacebookId = null;
      resolve();
    });
  }

  getCurrentAccessToken(): Promise<FacebookCurrentAccessTokenResponse> {
    return new Promise((resolve, reject) => {
      if (!currentFacebookId) {
        reject({ accessToken: { token: null } });
        return;
      }

      const result = {
        accessToken: {
          token: 'access-' + currentFacebookId,
          userId: currentFacebookId,
        },
      };

      resolve(result);
    });
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  async getProfile<T extends object>(options: { fields: readonly string[] }): Promise<T> {
    const fields = options.fields.join(',');
    const token = await this.getCurrentAccessToken();
    if (!token.accessToken?.userId) {
      throw new Error('Unable to find the user token');
    }
    const data = await this.getFacebookUserInfos(token.accessToken?.userId, token.accessToken.token, fields);

    return data as T;
  }

  getFacebookUserIdAndFullname(token: string): Promise<FacebookUserInfos> {
    return jsonFetch(`/_test/facebook/me?access_token=${token}`)
      .then(throwErrorIfAny)
      .then(response => response.json() as Promise<FacebookUserInfos>);
  }

  getFacebookUserInfos(facebookId: string, token: string, fields = 'first_name,last_name,email'): Promise<FacebookUserFullInfos> {
    return jsonFetch(`/_test/facebook/graph/${facebookId}?fields=${fields}&access_token=${token}`)
      .then(throwErrorIfAny)
      .then(response => response.json() as Promise<FacebookUserFullInfos>);
  }

  getFacebookUserPicture(facebookId: string): Promise<FacebookUserPictureData> {
    return jsonFetch(`/_test/facebook/graph/${facebookId}/picture?redirect=false&type=large`)
      .then(throwErrorIfAny)
      .then(response => response.json())
      .then(data => data.data as FacebookUserPictureData);
  }

  async reauthorize(): Promise<FacebookLoginResponse> {
    return {
      accessToken: null,
    };
  }
}
