import React from 'react';
import { addViewBoxInSvgString } from '../../utils/helpers';
import './Icon.scss';

interface ComponentProps {
  icon: string;
  color?: string;
}

export const IconComponent: React.FunctionComponent<ComponentProps> = ({ icon, color }: ComponentProps) => {
  const iconWithViewBox = addViewBoxInSvgString(icon);
  return <div className="icon" style={{ fill: color ?? 'var(--ion-color-primary)' }} dangerouslySetInnerHTML={{ __html: iconWithViewBox }} />;
};
