import { IonLoading, IonPage } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { actions, RootState, selectors } from '../store';
import { UserNotification } from '../store/notifications/types';
import { extractRawId } from '../utils/helpers';
import { EntityReference } from '../utils/hydra';
import { getLocationPathname, locationIsNotificationRedirectionPage } from '../utils/windowLocationHelper';

interface StateProps {
  userNotification: UserNotification | undefined;
  managedUsersTotalItems: number;
}

const mapStateToProps = (state: RootState, props: { notificationId: EntityReference | null }): StateProps => ({
  userNotification: selectors.notifications.getUserNotificationByNotificationId(state, props),
  managedUsersTotalItems: state.whoAmI.managedUsers?.totalItems || 0,
});

interface DispatchProps {
  fetchUserNotificationByNotificationId: (notificationId: EntityReference) => Promise<void>;
  markNotificationAsRead: (userNotification: UserNotification, read: boolean) => Promise<void>;
}

const propsToDispatch = {
  fetchUserNotificationByNotificationId: actions.notifications.fetchUserNotificationByNotificationId,
  markNotificationAsRead: actions.notifications.updateNotificationStatus,
};

interface Props {
  notificationId: EntityReference | null;
}

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type NotificationsProps = StateProps & DispatchProps & Props;

class NotificationRedirectionPage extends Component<NotificationsProps> {
  public componentDidMount(): void {
    this.getNotification();
  }

  private getNotification = async (): Promise<void> => {
    if (!this.props.notificationId) {
      return;
    }

    await this.props.fetchUserNotificationByNotificationId(this.props.notificationId);
  };

  private getNotificationRedirectionPath = (): string => {
    const data = this.props.userNotification;
    const redirectIriByUserNotificationData: { [reason: string]: string | undefined } = {
      new_follower: data?.notification.data?.followerIri,
      followed_new_post: data?.notification.data?.postIri,
      global_message: '/global_notification/' + extractRawId(this.props.notificationId as EntityReference),
      moderated_post: '/display_notification/' + extractRawId(this.props.notificationId as EntityReference),
      moderated_report_to_reporter: '/display_notification/' + extractRawId(this.props.notificationId as EntityReference),
      user_review: '/me' + data?.notification.thread.subjectIri + '/reviews?type=personalReviews',
      direct_chat_new_message: data?.notification.thread.subjectIri,
      post_request_state_change: data?.notification.data?.chatIri,
      post_new_request: data?.notification.data?.chatIri,
      post_request_review: data?.notification.data?.chatIri,
    };
    const redirectionPath = redirectIriByUserNotificationData[data?.notification?.reason as string];

    if (!data?.notification?.reason || !redirectionPath) {
      return '/select_profiles';
    }

    this.props.markNotificationAsRead(data, true);
    return redirectionPath;
  };

  public render(): ReactNode {
    if (this.props.managedUsersTotalItems) {
      return <Redirect to="/select_profiles" />;
    }

    if (!this.props.userNotification) {
      return (
        <IonPage data-cy="notification-redirection-page">
          <IonLoading isOpen={true} spinner="bubbles" showBackdrop />
        </IonPage>
      );
    }

    if (!locationIsNotificationRedirectionPage() && this.props.userNotification) {
      return <></>;
    }

    return <Redirect to={this.getNotificationRedirectionPath()} from={getLocationPathname()} />;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationRedirectionPage);
