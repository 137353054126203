import { IonButton, IonThumbnail, IonText, IonPage } from '@ionic/react';
import React, { useState } from 'react';
import { ApiSwitcherAlert, useLongPress } from '../components/ApiSwitcherAlert';
import { useTranslation } from 'react-i18next';
import { apiEntrypoint, defaultApiEntrypoint, appVersion, isProduction, appBuildVersion } from '../environment';
import './HomePage.scss';

const HomePage: React.FunctionComponent = () => {
  const [apiSwitcherIsOpen, setOpenApiSwitcher] = useState(false);
  const backspaceLongPress = useLongPress(() => setOpenApiSwitcher(true), 3000);
  const { t, i18n } = useTranslation();
  const language = i18n.language.substring(0, 2);

  return (
    <IonPage className="light-page" data-cy="home-page">
      <div className="homepage-content">
        <header>
          <IonThumbnail>
            <img src="/assets/logo-indigo-gradient.svg" alt="Indigo" {...backspaceLongPress} />
          </IonThumbnail>

          <h1>
            {t('home.title')}
            <div className="subtitle">{t('home.subtitle')}</div>
            <div className="subtitle">{t('home.subtitle-2')}</div>
            <div className="light-subtitle">{t('home.light-subtitle')}</div>
          </h1>
        </header>

        <div className="homepage-middle-section">
          <div className="ion-text-center welcome-media">
            <img src="/assets/welcome-group.svg" alt="Welcome" />
          </div>

          <IonButton color="primary" routerLink="/register" shape="round" expand="block" size="large">
            {t('home.register')}
          </IonButton>

          <IonButton fill="outline" color="primary" routerLink="/login" shape="round" expand="block" size="large">
            {t('home.login')}
          </IonButton>

          {((isProduction && appVersion !== 'dev') || !isProduction) && (
            <IonText className="app-infos" color="medium">
              v {appBuildVersion}
            </IonText>
          )}
          {apiEntrypoint !== defaultApiEntrypoint && (
            <IonText className="app-infos" color="secondary">
              {apiEntrypoint}
            </IonText>
          )}
        </div>

        <footer className="bottom-section">{(language === 'fr' || language === 'en') && <img src={`/assets/view-posts-${language}.svg`} className="view-posts" alt={t('home.view-posts')} />}</footer>

        <ApiSwitcherAlert closeAlert={() => setOpenApiSwitcher(false)} showAlert={apiSwitcherIsOpen} />
      </div>
    </IonPage>
  );
};

export default HomePage;
