import React from 'react';
import { IonRow, IonCol, IonText } from '@ionic/react';
import { ThematicComponent } from '../ThematicsList';
import { Thematic } from '../../store/thematics/types';
import './UserComponents.scss';

interface Props {
  thematics: Thematic[];
  title: string;
}

const UserThematics: React.FunctionComponent<Props> = ({ thematics, title }: Props) => {
  return (
    <IonRow responsive-sm className="tags">
      <IonCol size="12">
        <div className="title">
          <IonText color="dark">{title}</IonText>
        </div>
      </IonCol>
      <IonCol size="12">
        <div className="tags-list">{thematics.map(thematic => ThematicComponent({ thematic: thematic, isSelected: false }))}</div>
      </IonCol>
    </IonRow>
  );
};

export default React.memo(UserThematics);
