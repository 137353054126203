import React, { Component, ReactNode } from 'react';
import { IonRow, IonCol } from '@ionic/react';
import { FullPost, Location, PostCollectionName } from '../store/posts/types';
import { isEqualIgnoringFunctions } from '../utils/helpers';
import { EntityReference, HydratedCollection, ItemsCollection } from '../utils/hydra';
import PostsSlider from './PostsSlider';
import '../components/placeholders/ProfilePagePlaceholder.scss';
import './UserPostsSlider.scss';
import { isCurrentUserFunc } from '../store/app/selectors';
import { UserCollectionName } from '../store/users/types';

interface SliderProps {
  postsCollection: HydratedCollection<FullPost> | ItemsCollection<FullPost>;
  title: string;
  currentLocation?: Location;
  isCurrentUser: isCurrentUserFunc;
  collectionName: UserCollectionName | PostCollectionName; // The recommandations slider is using this UserPostSlider with userId=undefined
  userId?: EntityReference;
  dataCy?: string;
}

class UserPostsSlider extends Component<SliderProps> {
  public shouldComponentUpdate(nextProps: Readonly<SliderProps>): boolean {
    return !isEqualIgnoringFunctions(nextProps, this.props);
  }

  public render(): ReactNode {
    const { postsCollection, title, currentLocation, isCurrentUser, collectionName, userId, dataCy } = this.props;
    if (!Object.keys(postsCollection.items).length) return null;

    return (
      <IonRow className="user-posts-slider" data-cy={dataCy}>
        <IonCol size="12" className="posts-title">
          {title}
        </IonCol>
        <IonCol size="12" className="posts-wrapper">
          <PostsSlider userId={userId} postsCollection={postsCollection} isCurrentUser={isCurrentUser} postsSize="large" currentLocation={currentLocation} collectionName={collectionName} />
        </IonCol>
      </IonRow>
    );
  }
}

export default UserPostsSlider;
