import { ActionType, getType } from 'typesafe-actions';
import { createReferencedCollection, initReferencedCollection, mergeReferencedCollections, updateItemValue, updateItemWithCollectionLoading } from '../../utils/redux';
import { EntitiesState } from '../entities/types';
import { RootActions } from '../index';
import { Post } from '../posts/types';
import * as postRequestsActions from './actions';
import * as chatActions from '../chats/actions';
import { PostRequest, PostRequestState } from './types';

const defaultState: PostRequestState = {
  currentUserPostRequests: createReferencedCollection(),
  itemIsLoading: false,
};

export const postRequestsEntitiesReducer = (state: EntitiesState, action: ActionType<RootActions>): EntitiesState => {
  // This reducer is called by the entities one
  switch (action.type) {
    case getType(postRequestsActions.fetchPostRequestReviewsAction.request):
      return { ...state, postRequests: updateItemWithCollectionLoading<PostRequest>(state.postRequests, action.payload, 'userReviews', true) };
    case getType(postRequestsActions.fetchPostRequestReviewsAction.success): {
      const postRequestItem = {
        ...state.postRequests[action.payload.postRequestId],
        userReviews: mergeReferencedCollections(action.payload.collection, state.postRequests[action.payload.postRequestId]?.userReviews),
      };
      return { ...state, postRequests: { ...state.postRequests, [action.payload.postRequestId]: postRequestItem } };
    }
    case getType(postRequestsActions.fetchPostRequestReviewsAction.failure):
      return { ...state, postRequests: updateItemWithCollectionLoading<PostRequest>(state.postRequests, action.payload.postRequestId, 'userReviews', false) };
    case getType(postRequestsActions.fetchPostRequestsAction.request):
      return { ...state, posts: updateItemWithCollectionLoading<Post>(state.posts, action.payload.postId, 'requests', true) };
    case getType(postRequestsActions.fetchPostRequestsAction.success):
      return {
        ...state,
        posts: updateItemValue<Post>(state.posts, action.payload.postId, 'requests', mergeReferencedCollections(action.payload.collection, state.posts[action.payload.postId].requests)),
      };
    case getType(postRequestsActions.fetchPostRequestsAction.failure):
      return { ...state, posts: updateItemWithCollectionLoading<Post>(state.posts, action.payload.postId, 'requests', false) };
    case getType(postRequestsActions.fetchUserOpenedPostRequestAction.success):
    case getType(postRequestsActions.createPostRequestAction.success):
      return { ...state, posts: updateItemValue<Post>(state.posts, action.payload.postId, 'currentUserOpenRequest', action.payload.postRequestId) };
    default:
      return state;
  }
};

export default (state = defaultState, action: ActionType<typeof postRequestsActions | typeof chatActions>): PostRequestState => {
  switch (action.type) {
    case getType(postRequestsActions.fetchUserPostRequestsAction.request):
      return { ...state, currentUserPostRequests: initReferencedCollection(state.currentUserPostRequests, true) };
    case getType(postRequestsActions.fetchUserPostRequestsAction.success):
      return { ...state, currentUserPostRequests: mergeReferencedCollections(action.payload, state.currentUserPostRequests) };
    case getType(postRequestsActions.fetchUserPostRequestsAction.failure):
      return { ...state, currentUserPostRequests: initReferencedCollection(state.currentUserPostRequests, false) };

    case getType(postRequestsActions.fetchUserOpenedPostRequestAction.request):
    case getType(postRequestsActions.createPostRequestAction.request):
    case getType(postRequestsActions.cancelPostRequestAction.request):
      return { ...state, itemIsLoading: true };
    case getType(postRequestsActions.fetchUserOpenedPostRequestAction.failure):
    case getType(postRequestsActions.fetchUserOpenedPostRequestAction.success):
    case getType(postRequestsActions.createPostRequestAction.failure):
    case getType(postRequestsActions.createPostRequestAction.success):
    case getType(postRequestsActions.cancelPostRequestAction.failure):
    case getType(postRequestsActions.cancelPostRequestAction.success):
      return { ...state, itemIsLoading: false };
    default:
      return state;
  }
};
