import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonPage, IonText, IonToggle } from '@ionic/react';
import React, { PureComponent, ReactNode, useState } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { actions, RootState } from '../store';
import { DisabledPrivacySettings, CurrentUser, CurrentUserEditFields } from '../store/users/types';
import CommonHeader from '../components/CommonHeader';
import { getCurrentUser } from '../store/app/selectors';
import { bindActionCreators, Dispatch } from 'redux';
import { extractId } from '../utils/helpers';

import './PrivacySettingsPage.scss';
import { routePrivacy } from '../utils/helpers';

interface PrivacySettingsItem {
  title: string;
  optionValue: DisabledPrivacySettings;
  description: string;
}

const privacySettingsList: PrivacySettingsItem[] = [
  {
    title: 'privacy-settings.personalisation',
    description: 'privacy-settings.personalisation-description',
    optionValue: 'privacy_settings_personalisation',
  },
  {
    title: 'privacy-settings.measurement',
    description: 'privacy-settings.measurement-description',
    optionValue: 'privacy_settings_measurement',
  },
  {
    title: 'privacy-settings.information-storage-and-access',
    description: 'privacy-settings.information-description',
    optionValue: 'privacy_settings_storage',
  },
  {
    title: 'privacy-settings.transmission',
    description: 'privacy-settings.transmission-description',
    optionValue: 'privacy_settings_transmission',
  },
];

interface PrivacySettingsToggleComponentProps {
  settingsItem: PrivacySettingsItem;
  disabledPrivacySettings: DisabledPrivacySettings[];
  changeSetting: (e: CustomEvent) => void;
  isSubmitting: boolean;
}

const PrivacySettingsToggleComponent: React.FunctionComponent<PrivacySettingsToggleComponentProps> = ({
  settingsItem,
  disabledPrivacySettings,
  changeSetting,
  isSubmitting,
}: PrivacySettingsToggleComponentProps) => {
  const [isDescriptionOpen, toggleDescription] = useState(false);

  return (
    <div className={`private-settings-toggle-item ${isDescriptionOpen ? 'private-settings-toggle-item-open' : ''}`}>
      <IonItem lines="none">
        <IonLabel className="private-settings-toggle-title">{<Trans i18nKey={settingsItem.title} />}</IonLabel>
        <IonIcon
          className="private-settings-toggle-chevron"
          icon={isDescriptionOpen ? '/assets/navigation/chevron-up.svg' : '/assets/navigation/chevron-down.svg'}
          color="medium"
          onClick={() => toggleDescription(!isDescriptionOpen)}
        />
        <IonToggle
          color="medium"
          data-cy="privacy-settings-option"
          value={settingsItem.optionValue}
          checked={!disabledPrivacySettings.includes(settingsItem.optionValue)}
          onIonChange={changeSetting}
          disabled={isSubmitting}
        />
      </IonItem>

      {isDescriptionOpen && (
        <IonText className="private-settings-toggle-description">
          <Trans i18nKey={settingsItem.description} />
        </IonText>
      )}
    </div>
  );
};

interface StateProps {
  currentUser: CurrentUser;
}

const mapStateToProps = (state: RootState): StateProps => ({
  currentUser: getCurrentUser(state),
});

const propsToDispatch = {
  updateUser: actions.app.updateUser,
};

interface DispatchProps {
  updateUser(user: Partial<CurrentUserEditFields>): Promise<CurrentUser>;
}

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type PrivacySettingsProps = StateProps & WithTranslation & DispatchProps;

interface State {
  disabledPrivacySettings: DisabledPrivacySettings[];
  isSubmitting: boolean;
}

class PrivacySettingsPage extends PureComponent<PrivacySettingsProps, State> {
  constructor(props: PrivacySettingsProps) {
    super(props);

    this.state = {
      disabledPrivacySettings: this.props.currentUser.disabledPrivacySettings,
      isSubmitting: false,
    };
  }

  private toggleSettings(optionValue: DisabledPrivacySettings): void {
    const disabledPrivacySettings: DisabledPrivacySettings[] = this.state.disabledPrivacySettings.includes(optionValue)
      ? this.state.disabledPrivacySettings.filter((el: DisabledPrivacySettings) => el !== optionValue)
      : [...this.state.disabledPrivacySettings, optionValue];

    this.setState({ disabledPrivacySettings, isSubmitting: true }, () => {
      const newUserPrivacySettingsValues: Partial<CurrentUser> = {
        '@id': extractId(this.props.currentUser),
        disabledPrivacySettings,
      };
      this.updateUserPrivacySettings(newUserPrivacySettingsValues);
    });
  }

  updateUserPrivacySettings = async (userNotifications: Partial<CurrentUser>): Promise<void> => {
    //TODO rework updateUser function
    await this.props.updateUser(userNotifications).finally(() => this.setState({ isSubmitting: false }));
  };

  public render(): ReactNode {
    const { isSubmitting } = this.state;
    return (
      <IonPage data-cy="privacy-settings-page">
        <CommonHeader addIonHeader={true} title={<Trans i18nKey="profile-menu.privacy-settings" />} className="private-settings-header" />

        <IonContent className="ion-padding private-settings">
          <IonText className="private-settings-description" color="dark">
            <Trans i18nKey="privacy-settings.description">
              <a key="privacy" href={routePrivacy()} target="new">
                <Trans i18nKey="privacy-settings.privacy-policy" />
              </a>
            </Trans>
          </IonText>

          <IonText className="private-settings-title" color="dark">
            <Trans i18nKey="privacy-settings.title" />
          </IonText>

          <IonList className="list-of-toggles" data-cy="private-settings-toggle-list">
            {privacySettingsList.map((item: PrivacySettingsItem) => (
              <PrivacySettingsToggleComponent
                key={item.optionValue}
                settingsItem={item}
                disabledPrivacySettings={this.state.disabledPrivacySettings}
                changeSetting={(e: CustomEvent) => this.toggleSettings(e.detail.value)}
                isSubmitting={isSubmitting}
              />
            ))}
          </IonList>
        </IonContent>
      </IonPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PrivacySettingsPage));
