// TODO rename file to 'selectors' and fix dependencies when all selectors will be modified

import { RootState } from '../index';
import { createSelector } from 'reselect';
import { EntityReference } from '../../utils/hydra';
import { Thematic } from './types';
import { filterThematicsByIds } from './selectors';

const getThematics = (state: RootState): Thematic[] => state.thematics?.thematics || [];
const getThematicsIdFromParams = (state: RootState, ids: EntityReference[]): EntityReference[] => ids || [];

export const getThematicsByIds = createSelector(getThematics, getThematicsIdFromParams, (thematics: Thematic[], ids: EntityReference[]) => {
  return filterThematicsByIds(thematics, ids);
});
