import { clientUrl } from '../environment';
import { EntityReference } from './hydra';
import { SocialSharing } from '@ionic-native/social-sharing';
import { Capacitor } from '@capacitor/core';

const browserCanShare = typeof window.navigator.share !== 'undefined';
export const deviceCanShare = Capacitor.isNativePlatform() || browserCanShare;

export const getSharePostUrl = (postId: EntityReference): string => {
  return clientUrl + postId;
};

const rejectShareOnCatch = (func: () => Promise<void>): Promise<void> => {
  return func().catch(e => {
    if (e?.message?.includes('Share canceled')) {
      return;
    }

    if (e === 'cordova_not_available') {
      return Promise.reject('This feature is not available on this device as cordova does not exist.');
    }

    return Promise.reject(e);
  });
};

export const browserShare = (title: string, text: string, url: string): Promise<void> => {
  if (Capacitor.isNativePlatform()) {
    return rejectShareOnCatch(() => SocialSharing.share(text, title, undefined, url));
  }

  if (!browserCanShare) {
    return Promise.reject('This feature is not available on this device');
  }

  return rejectShareOnCatch(() =>
    window.navigator.share({
      text,
      title,
      url,
    }),
  );
};

export const socialShare = (title: string, message: string, url: string): Promise<void> => {
  return rejectShareOnCatch(() => SocialSharing.share(message, title, undefined, url));
};

export const shareViaSms = (message: string, url: string): Promise<void> => {
  return rejectShareOnCatch(() => SocialSharing.shareViaSMS(message + url, ''));
};

export const shareViaFacebook = (message: string, url: string): Promise<void> => {
  return rejectShareOnCatch(() => SocialSharing.shareViaFacebook(message, undefined, url));
};

export const shareViaWhatsapp = (message: string, url: string): Promise<void> => {
  return rejectShareOnCatch(() => SocialSharing.shareViaWhatsApp(message, undefined, url));
};

export const shareViaMessenger = (message: string, url: string): Promise<void> => {
  return rejectShareOnCatch(() => SocialSharing.shareVia('com.facebook.orca', message + url));
};

export const shareViaMail = (title: string, message: string, url: string): Promise<void> => {
  return rejectShareOnCatch(() => SocialSharing.shareViaEmail(message + url, title, []));
};
