import { CHATS_TAB, POSTS_TAB, SEARCH_TAB } from './TabNames';

export function getWindowLocation(): Location {
  return window.location;
}

export function getLocationPathname(): string {
  return getWindowLocation().pathname;
}

export function locationPathnameIncludes(searchString: string): boolean {
  return getLocationPathname().includes(searchString);
}

export function locationIsChatTab(): boolean {
  return getLocationPathname() === `/${CHATS_TAB}`;
}

export function locationIsSearchTab(): boolean {
  return getLocationPathname() === `/${SEARCH_TAB}`;
}

export function locationIsPostTab(): boolean {
  return getLocationPathname() === `/${POSTS_TAB}`;
}

function locationPathnameIncludesOneOrMore(searchStrings: string[]): boolean {
  return searchStrings.filter(locationPathnameIncludes).length !== 0;
}

export function locationIsResetPasswordPage(): boolean {
  return locationPathnameIncludes('/reset-password');
}

export function locationIsPostCreatePage(): boolean {
  return locationPathnameIncludes('/posts/create/');
}

export function locationIsLoginPage(): boolean {
  return locationPathnameIncludes('/login');
}

export function locationIsHomepagePage(): boolean {
  return locationPathnameIncludes('/homepage');
}

export function locationIsNotificationRedirectionPage(): boolean {
  return locationPathnameIncludes('notification_redirection/');
}

export function locationIsProfileSelectionPage(): boolean {
  return locationPathnameIncludes('/select_profiles');
}

export function locationIsRegisterOrganizationPage(): boolean {
  return locationPathnameIncludes('/register_organization');
}

export function locationIsACreationPage(): boolean {
  return locationPathnameIncludes('/create/');
}

export function locationIsAPageWithUserForm(): boolean {
  return locationPathnameIncludesOneOrMore(['/me/edit', '/register', '/me/edit/password', '/register_organization']);
}

function testLocationPathname(regexp: RegExp): boolean {
  return regexp.test(getLocationPathname());
}

export function locationIsAChatPage(): boolean {
  return testLocationPathname(/\/chats\/[\w-]+$/);
}

export function locationIsAPostPage(): boolean {
  return testLocationPathname(/\/posts\/[\w-]+$/);
}

export function reloadWindowLocation(): void {
  getWindowLocation().reload();
}

export function assignWindowLocation(newPath: string): void {
  getWindowLocation().assign(newPath);
}
