import { AnyAction, Middleware } from 'redux';
import { actions, RootState } from '../index';
import * as postsActions from './actions';
import * as layoutActions from '../layout/actions';
import { ActionType, getType } from 'typesafe-actions';
import { ThunkResultDispatch } from '../types';
import { extractId } from '../../utils/helpers';

export const postMiddleware: Middleware<unknown, RootState> = store => next => (action: ActionType<typeof actions.app | AnyAction>) => {
  typeof action === 'function' ? action(next) : next(action);

  const dispatch = store.dispatch as ThunkResultDispatch;

  if (action.type === getType(postsActions.updatePostStateAction.success)) {
    dispatch(layoutActions.setToastMessageAction(`post.update-state.${action.payload}-success`));
    // Update the user's posts list
    dispatch(postsActions.fetchAllCurrentUserPosts());
  }

  if (action.type === getType(postsActions.fetchPostsCollectionAction.failure)) {
    dispatch(layoutActions.setToastMessageAction(action.payload.error.message));
  }

  if (action.type === getType(postsActions.updatePostStateAction.failure)) {
    dispatch(layoutActions.setToastMessageAction(`post.update-state.${action.payload.transition}-error`));
  }

  const toastMessagesActions = [getType(postsActions.fetchMapCollectionAction.failure), getType(postsActions.editPostAction.failure)];

  if (toastMessagesActions.indexOf(action.type) !== -1) {
    dispatch(layoutActions.setToastMessageAction(action.payload.message));
  }

  if (action.type === getType(postsActions.fetchPostAction.failure)) {
    dispatch(layoutActions.setToastMessageAction(action.payload));
  }

  if (action.type === getType(postsActions.createPostAction.failure)) {
    dispatch(layoutActions.setToastMessageAction('post.submit-validation-failed'));
  }

  if (
    action.type === getType(postsActions.fetchSavedSearchesAction.failure) ||
    action.type === getType(postsActions.deleteSavedSearchAction.failure) ||
    action.type === getType(postsActions.createSavedSearchAction.failure)
  ) {
    dispatch(layoutActions.setToastMessageAction(action.payload));
  }

  // Refresh the user posts list after a post is created, updated
  // When creating or editing a post, wait for the response from the API for automatic moderation validation
  if (action.type === getType(postsActions.createPostAction.success) || action.type === getType(postsActions.editPostAction.success)) {
    setTimeout(() => {
      if (action?.payload?.['@id']) {
        // Fetch the new state: the post can have been automatically approved
        // TODO Send a push notification instead of waiting for some seconds
        dispatch(postsActions.fetchPost(extractId(action.payload['@id'])));
      }
      // Update the user's posts list
      dispatch(postsActions.fetchAllCurrentUserPosts());
    }, 5000);
  }
};
