import { ActionType, getType } from 'typesafe-actions';
import { reduceAndMergeItems } from '../../utils/redux';
import { chatsEntitiesReducer } from '../chats/reducer';
import { postRequestsEntitiesReducer } from '../postRequests/reducer';
import { usersEntitiesReducer } from '../users/reducer';
import * as entitiesActions from './actions';
import { EntitiesState } from './types';

const defaultState: EntitiesState = {
  posts: {},
  users: {},
  postRequests: {},
  userReviews: {},
  chats: {},
  userChats: {},
  chatMessages: {},
  userNotifications: {},
  notifications: {},
};

export default (state: EntitiesState = defaultState, action: ActionType<typeof entitiesActions>): EntitiesState => {
  switch (action.type) {
    case getType(entitiesActions.addEntities):
      return {
        posts: Object.entries(action.payload.posts || []).reduce(reduceAndMergeItems, state.posts),
        users: Object.entries(action.payload.users || []).reduce(reduceAndMergeItems, state.users),
        postRequests: Object.entries(action.payload.postRequests || []).reduce(reduceAndMergeItems, state.postRequests),
        userReviews: Object.entries(action.payload.userReviews || []).reduce(reduceAndMergeItems, state.userReviews),
        chats: Object.entries(action.payload.chats || []).reduce(reduceAndMergeItems, state.chats),
        userChats: Object.entries(action.payload.userChats || []).reduce(reduceAndMergeItems, state.userChats),
        chatMessages: Object.entries(action.payload.chatMessages || []).reduce(reduceAndMergeItems, state.chatMessages),
        userNotifications: Object.entries(action.payload.userNotifications || []).reduce(reduceAndMergeItems, state.userNotifications),
        notifications: Object.entries(action.payload.notifications || []).reduce(reduceAndMergeItems, state.notifications),
      };
  }

  state = usersEntitiesReducer(state, action);
  state = postRequestsEntitiesReducer(state, action);
  state = chatsEntitiesReducer(state, action);

  return state;
};
