import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { actions, RootState, selectors } from '../store';
import { FollowUserType, User, UserFavoriteCollectionName } from '../store/users/types';
import { bindActionCreators, Dispatch } from 'redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { EntityReference, HydratedCollection, ReferencedCollection } from '../utils/hydra';
import { IonHeader, IonLabel, IonPage, IonSearchbar, IonSegment, IonSegmentButton, IonContent, withIonLifeCycle, SearchbarInputEventDetail } from '@ionic/react';
import './UserFollowersPage.scss';
import UsersFavoriteList from '../components/UsersFavoriteList';
import CommonHeader from '../components/CommonHeader';
import { addStyleToShadowRoot, extractId } from '../utils/helpers';
import { denormalizeEntityCollection } from '../store/entities/selectors';
import { createIsCurrentUserFunc, isCurrentUserFunc } from '../store/app/selectors';
import { getUser } from '../store/users/selectors';
import { IonSearchbarCustomEvent } from '@ionic/core';

interface StateProps {
  findUserById: (id: EntityReference) => User | undefined;
  hydrateUsersCollection: (collection: ReferencedCollection | undefined) => HydratedCollection<User>;
  currentUserAsUser: User | undefined;
  isCurrentUser: isCurrentUserFunc;
  isFavoriteLoading: boolean;
}

const mapStateToProps = (state: RootState): StateProps => ({
  findUserById: (id: EntityReference) => selectors.entities.denormalizeEntity(state.entities, id),
  hydrateUsersCollection: (collection: ReferencedCollection | undefined) => denormalizeEntityCollection<User>(state.entities, collection),
  currentUserAsUser: getUser(state, extractId(state.app.currentUser)),
  isCurrentUser: createIsCurrentUserFunc(state),
  isFavoriteLoading: state.users.isFavoriteLoading,
});

interface DispatchProps {
  fetchUserFavorites: (userId: EntityReference, collectionName: UserFavoriteCollectionName, search?: string, nextPage?: boolean) => void;
  addFavoriteUser: (user: Partial<User> | EntityReference) => Promise<void>;
}

const propsToDispatch = {
  fetchUserFavorites: actions.users.fetchUserFavorites,
  addFavoriteUser: actions.users.addFavoriteUser,
};

interface PageProps {
  userId: EntityReference | null;
}

interface State {
  followUserType: FollowUserType;
  searchText?: string;
}

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);
type Props = PageProps & DispatchProps & StateProps & WithTranslation;

class UserFollowsPage extends Component<Props, State> {
  following: FollowUserType = 'following';
  followers: FollowUserType = 'followers';

  public constructor(props: Props) {
    super(props);
    this.state = {
      followUserType: this.followers,
    };
  }

  public ionViewWillEnter(): void {
    this.loadFollows();
    addStyleToShadowRoot(document.querySelectorAll('ion-segment-button'), '<style>ion-ripple-effect { color: var(--ion-color-milkygray)!important;opacity: 0.5 }</style>');
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.userId !== this.props.userId) {
      this.loadFollows();
      this.setState({ followUserType: this.followers });
    }
  }

  private doRefresh(followUserType: FollowUserType | undefined = undefined, nextPage = false): void {
    if (!this.props.userId) {
      return;
    }

    this.props.fetchUserFavorites(this.props.userId, this.getCollectionName(followUserType), this.state.searchText, nextPage);
  }

  private loadFollows(): void {
    this.doRefresh(this.followers);
    this.doRefresh(this.following);
  }

  private getCollectionName(followUserType: FollowUserType | undefined = undefined): UserFavoriteCollectionName {
    return (followUserType ?? this.state.followUserType) === 'followers' ? 'favoritedByUsers' : 'favoriteUsers';
  }

  public render(): ReactNode {
    if (this.props.userId === null) {
      return <Redirect to="/me" />;
    }

    const user = this.props.findUserById(this.props.userId);
    if (!user) return <IonPage />;

    const users: HydratedCollection<User> = this.props.hydrateUsersCollection(user?.collections?.[this.getCollectionName()]);

    return (
      <IonPage className="user-follows-page" data-cy="user-follows-page">
        <CommonHeader addIonHeader={true} title={user?.name} />

        <IonHeader>
          <IonSegment onIonChange={this.onTabWillChange} mode="md" color="dark" className="tab-bar" value={this.state.followUserType}>
            <IonSegmentButton className="tab-button" value={this.followers} mode="md">
              <IonLabel>{this.props.t('follow.followers', { count: user?.collections?.favoritedByUsers?.totalItems || 0 })}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton className="tab-button" value={this.following} mode="md">
              <IonLabel>{this.props.t('follow.following', { count: user?.collections?.favoriteUsers?.totalItems || 0 })}</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          <IonSearchbar searchIcon="/assets/form/search.svg" className="search-bar" placeholder={this.props.t('common.search')} value={this.state.searchText} onIonInput={this.updateSearchTerm} />
        </IonHeader>

        <IonContent>
          <UsersFavoriteList usersList={users} isCurrentUser={this.props.isCurrentUser} currentUser={this.props.currentUserAsUser} fetchNextUsersPage={() => this.doRefresh(undefined, true)} />
        </IonContent>
      </IonPage>
    );
  }

  private updateSearchTerm = (e: IonSearchbarCustomEvent<SearchbarInputEventDetail>): void => {
    this.setState({ searchText: e.detail.value ?? '' });
    this.doRefresh();
  };

  private onTabWillChange = (e: CustomEvent): void => {
    if (this.state.followUserType !== e.detail.value) {
      this.setState({ followUserType: e.detail.value === this.following ? this.following : this.followers });
      this.doRefresh();
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withIonLifeCycle(UserFollowsPage)));
