import React, { Component, ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { actions, RootState } from '../store';
import { IonButton, IonImg, IonText, IonThumbnail, IonContent, IonPage } from '@ionic/react';
import { GlobalNotificationData } from '../store/notifications/types';
import CommonHeader from '../components/CommonHeader';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
import { getMarkdownReadyMessage, getTargetBlankLink } from '../utils/markdownHelper';
import { getUserIsGlobalNotifier } from '../store/app/selectors';
import './GlobalNotificationPreviewPage.scss';

interface DispatchProps {
  setGlobalNotification(notification: GlobalNotificationData): void;
  sendGlobalNotification(): void;
}

const propsToDispatch = {
  fetchCategories: actions.categoriesActions.fetchPostCategories,
  setGlobalNotification: actions.notifications.setGlobalNotificationAction,
  sendGlobalNotification: actions.notifications.sendGlobalNotification,
};

interface StateProps {
  userCanCreateGlobalNotification: boolean;
  globalNotification: GlobalNotificationData;
  globalNotificationIsLoading: boolean;
}

const mapStateToProps = (state: RootState): StateProps => ({
  userCanCreateGlobalNotification: getUserIsGlobalNotifier(state),
  globalNotification: state.notifications.globalNotification,
  globalNotificationIsLoading: state.notifications.globalNotificationIsLoading,
});

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type GlobalNotificationPreviewProps = RouteComponentProps<Record<string, never>> & DispatchProps & WithTranslation & StateProps;

class GlobalNotificationPreviewPage extends Component<GlobalNotificationPreviewProps> {
  public render(): ReactNode {
    const { userCanCreateGlobalNotification, globalNotification, t, globalNotificationIsLoading } = this.props;

    if (!userCanCreateGlobalNotification) {
      return <Redirect to="/" />;
    }

    if (!globalNotification.message) {
      return <></>;
    }

    const message = getMarkdownReadyMessage(globalNotification.message);

    return (
      <IonPage data-cy="global-notification-preview-page">
        <CommonHeader addIonHeader={true} title="" />
        <IonContent className="global-notification-preview-page">
          <div className="content-wrap">
            <div className="date-wrap common-margin">
              <IonThumbnail className="date-logo">
                <IonImg src="/assets/logo-indigo-gradient.svg" alt="Indigo" />
              </IonThumbnail>
              <IonText color="dark" className="date-title">
                Indigo world
              </IonText>
              <IonText color="medium" className="date">
                {moment(new Date()).format('DD MMMM YYYY')}
              </IonText>
            </div>

            {globalNotification.imageUrl && <img className="notification-image" src={globalNotification.imageUrl} alt="notification" data-cy="notification-image" />}

            {message && (
              <div className="common-margin">
                <ReactMarkdown components={{ a: getTargetBlankLink }}>{message}</ReactMarkdown>
              </div>
            )}

            <IonButton className="send-button" fill="solid" color="primary" onClick={this.sendNotification} disabled={globalNotificationIsLoading}>
              {globalNotificationIsLoading ? t('common.loading') : t('notification.send-to-all-users')}
            </IonButton>
          </div>
        </IonContent>
      </IonPage>
    );
  }

  private sendNotification = async (): Promise<void> => {
    try {
      await this.props.sendGlobalNotification();
      this.props.history.push('/');
    } catch (e) {
      // The store already contains the error
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(GlobalNotificationPreviewPage)));
