import React, { Component, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import CommonHeader from '../components/CommonHeader';
import { IonContent, IonImg, IonItem, IonLabel, IonPage, IonToggle, withIonLifeCycle } from '@ionic/react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { actions, RootState } from '../store';
import { bindActionCreators, Dispatch } from 'redux';
import { CurrentUser, CurrentUserEditFields, Preferences } from '../store/users/types';
import './NewsletterSettingsPage.scss';
import { extractId } from '../utils/helpers';

interface State {
  newsletterSubscription: boolean;
  isSubmitting: boolean;
}

interface StateProps {
  currentUser: CurrentUser;
}

const mapStateToProps = (state: RootState): StateProps => ({
  currentUser: state.app.currentUser,
});

const propsToDispatch = {
  updateUser: actions.app.updateUser,
  addUserPreferences: actions.users.addUserPreferences,
  removeUserPreference: actions.users.removeUserPreference,
};

interface DispatchProps {
  updateUser(user: Partial<CurrentUserEditFields>): Promise<CurrentUser>;
  addUserPreferences(preferences: Preferences): Promise<CurrentUser>;
  removeUserPreference(preference: string): Promise<void>;
}

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type Props = StateProps & RouteComponentProps<Record<string, never>> & WithTranslation & DispatchProps;

class NewsletterSettingsPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      newsletterSubscription: this.props.currentUser.newsletterSubscribed,
      isSubmitting: false,
    };
  }

  enableDisableNewsletterSubscription = async (e: CustomEvent): Promise<void> => {
    const { checked } = e.detail;
    this.setState({
      newsletterSubscription: checked,
      isSubmitting: true,
    });

    await this.props.updateUser({
      '@id': extractId(this.props.currentUser),
      newsletterSubscribed: checked,
    });

    // if user declined newsletter subscription then add it into preferences else remove it
    !checked ? this.props.addUserPreferences({ userDeclinedNewsletterSubscription: !checked }) : this.props.removeUserPreference('userDeclinedNewsletterSubscription');
    this.setState({ isSubmitting: false });
  };

  render(): ReactNode {
    const { newsletterSubscription, isSubmitting } = this.state;
    return (
      <IonPage className="newsletter-settings-page" data-cy="newsletter-settings-page">
        <CommonHeader addIonHeader={true} title={this.props.t(`profile-menu.newsletter`)} />
        <IonContent>
          <div className="description">
            <IonImg src="/assets/update-user-missing-data-modal/newsletter.svg" />
            <h6>
              <Trans i18nKey="edit-newsletter-subscribed-modal.follow" />
            </h6>
            <p className="text">
              <Trans i18nKey="edit-newsletter-subscribed-modal.offer-email" />
            </p>
          </div>
          <IonItem lines="none">
            <IonLabel>
              <Trans i18nKey="profile-menu.agree-to-receive-newsletter" />
            </IonLabel>
            <IonToggle color="medium" disabled={isSubmitting} data-cy="newsletter-settings-option" checked={newsletterSubscription} onIonChange={e => this.enableDisableNewsletterSubscription(e)} />
          </IonItem>
        </IonContent>
      </IonPage>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(withIonLifeCycle(NewsletterSettingsPage))));
