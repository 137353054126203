import { IonButton } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { actions, RootState } from '../store';
import { EntityReference } from '../utils/hydra';
import { bindActionCreators, Dispatch } from 'redux';
import { Trans } from 'react-i18next';
import { User, UserFavoriteCollectionName } from '../store/users/types';
import { extractId, isDataFresh } from '../utils/helpers';

import './FollowButton.scss';

interface ButtonProps {
  currentUser: User;
  userId: EntityReference;
  color: string;
  unfollowColor?: string;
  fill?: 'outline' | 'solid';
  refreshUserFavorites?: boolean;
}

interface DispatchProps {
  addFavoriteUser: (userId: EntityReference) => Promise<void>;
  deleteFavoriteUser: (userId: EntityReference) => Promise<void>;
  fetchUserFavorites: (currentUserId: EntityReference) => Promise<void>;
  fetchUserFavoritesById: (currentUserId: EntityReference, userFavoriteId: EntityReference) => Promise<void>;
  fetchAnotherUserFavorites: (userId: EntityReference, collectionName: UserFavoriteCollectionName, search?: string) => Promise<void>;
}

const propsToDispatch = {
  addFavoriteUser: actions.users.addFavoriteUser,
  deleteFavoriteUser: actions.users.deleteFavoriteUser,
  fetchUserFavorites: (currentUserId: EntityReference) => actions.users.fetchUserFavorites(currentUserId, 'favoriteUsers'),
  fetchUserFavoritesById: (currentUserId: EntityReference, userFavoriteId: EntityReference) => actions.users.fetchUserFavoritesById(currentUserId, 'favoriteUsers', userFavoriteId),
  fetchAnotherUserFavorites: actions.users.fetchUserFavorites,
};

interface StateProps {
  isFavoriteLoading: boolean;
}

const mapStateToProps = (state: RootState): StateProps => ({
  isFavoriteLoading: state.users.isFavoriteLoading,
});

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type ViewPostProps = ButtonProps & DispatchProps & StateProps;

class FollowButton extends Component<ViewPostProps> {
  public componentDidMount(): void {
    if (!isDataFresh(this.props.currentUser.collections?.favoriteUsers)) {
      this.props.fetchUserFavorites(extractId(this.props.currentUser));
    }
  }

  private followUser = async (): Promise<void> => {
    try {
      await this.props.addFavoriteUser(this.props.userId);
    } catch {
      return;
    }

    this.refreshFavorites();
  };

  private unfollowUser = async (): Promise<void> => {
    try {
      await this.props.deleteFavoriteUser(this.props.userId);
    } catch {
      return;
    }

    this.refreshFavorites();
  };

  private refreshFavorites = (): void => {
    this.props.fetchUserFavoritesById(extractId(this.props.currentUser), this.props.userId);

    if (this.props.refreshUserFavorites) {
      this.props.fetchAnotherUserFavorites(this.props.userId, 'favoritedByUsers');
    }
  };

  public render(): ReactNode {
    if (!this.props.userId) {
      return <></>;
    }

    const userIsAlreadyFollowed = this.props.currentUser.collections?.favoriteUsers?.ids.includes(this.props.userId);
    const isLoading = this.props.currentUser.collections?.favoriteUsers?.isLoading || this.props.isFavoriteLoading;

    const color = userIsAlreadyFollowed ? this.props.unfollowColor ?? 'dark' : this.props.color ?? 'primary';
    const fill = this.props.fill ?? (userIsAlreadyFollowed ? 'outline' : 'solid');

    return (
      <IonButton slot="end" className="follow-button" onClick={userIsAlreadyFollowed ? this.unfollowUser : this.followUser} disabled={isLoading} color={color} fill={fill}>
        <Trans i18nKey={userIsAlreadyFollowed ? 'follow.unfollow' : 'follow.follow'} />
      </IonButton>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FollowButton);
