import { Capacitor } from '@capacitor/core';
import { isTest } from '../environment';
import { CurrentUserStatus, User } from '../store/users/types';
import { CollectionResponse } from '../utils/hydra';
import { Badge } from '@ionic-native/badge';

export const setBadgeCount = async (currentUserStatus: CurrentUserStatus | undefined, managedUsers: CollectionResponse<User> | undefined): Promise<void> => {
  if ('web' === Capacitor.getPlatform() || isTest) {
    return;
  }

  if (typeof currentUserStatus?.unreadChats === 'undefined' || typeof currentUserStatus?.unreadNotifications === 'undefined') {
    return;
  }

  const managedUsersNotifications = (managedUsers?.items || []).reduce((prev, user) => {
    return prev + (user.status?.unreadChats || 0) + (user.status?.unreadNotifications || 0);
  }, 0);

  const unreadStatus = currentUserStatus.unreadChats + currentUserStatus.unreadNotifications + managedUsersNotifications;
  // We cannot use https://github.com/katzer/cordova-plugin-badge for web as cordova plugin cannot work on PWA

  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    await Badge.set(unreadStatus);
  } catch (e) {
    // catch error
  }
};

export const resetBadgeCount = async (): Promise<void> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    await Badge.set(0);
  } catch (e) {
    // catch error
  }
};
