import { IonIcon, IonItem, IonLabel, IonList, IonSpinner } from '@ionic/react';
import { TFunction } from 'i18next';
import React, { useState } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { actions, RootState } from '../../store';
import { getCurrentUser } from '../../store/app/selectors';
import { getCategories } from '../../store/categories/selectors';
import { Category } from '../../store/categories/types';
import { filterQueryParamToSearchParam } from '../../store/posts/actions';
import { getSavedSearchList, getSavedSearchListIsLoading } from '../../store/posts/selectors';
import { CategorySelection, PostCategoryType, SavedSearch, SearchQueryParameters } from '../../store/posts/types';
import { CurrentUser } from '../../store/users/types';
import { getCategoryItemsType } from '../../utils/categoriesHelpers';
import { extractId, isSameHydraEntity } from '../../utils/helpers';
import { EntityReference } from '../../utils/hydra';
import { translateSize, translateUniverse } from '../../utils/sizesHelper';
import { getItemTranslation } from '../../utils/translation';
import SavedSearchOptionActionSheet from './SavedSearchOptionActionSheet';
import { refresh } from 'ionicons/icons';

import './SavedSearchList.scss';

interface StateProps {
  savedSearchList: SavedSearch[] | undefined;
  savedSearchListIsLoading: boolean;
  currentUser: CurrentUser;
  categories: Category[];
}

const mapStateToProps = (state: RootState): StateProps => ({
  savedSearchList: getSavedSearchList(state),
  savedSearchListIsLoading: getSavedSearchListIsLoading(state),
  currentUser: getCurrentUser(state),
  categories: getCategories(state),
});

interface DispatchProps {
  fetchSavedSearches: () => Promise<void>;
  setSearchParams: (param: SearchQueryParameters) => Promise<void>;
}

const propsToDispatch = {
  fetchSavedSearches: actions.posts.fetchSavedSearches,
  setSearchParams: actions.posts.setSearchParams,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

interface SavedSearchListProps {
  onSavedSearchClick: () => void;
  onShareClick: () => void;
}

type Props = SavedSearchListProps & StateProps & DispatchProps & WithTranslation;

const SavedSearchList: React.FunctionComponent<Props> = (props: Props) => {
  const [selectedSavedSearchId, setSelectedSavedSearchId] = useState<EntityReference | undefined>(undefined);
  const [selectedSearchFilters, setSelectedSearchFilters] = useState<SearchQueryParameters | undefined>(undefined);

  const fetchSavedSearches = (): void => {
    if (props.savedSearchListIsLoading) {
      return;
    }

    props.fetchSavedSearches().catch(() => {
      // Prevent fetch error
    });
  };

  const applySavedSearch = (savedSearchId: EntityReference | undefined): void => {
    if (!savedSearchId) {
      return;
    }

    const savedSearch = (props.savedSearchList || []).find(item => isSameHydraEntity(savedSearchId, item));
    if (!savedSearch?.searchFilters) {
      return;
    }

    props.setSearchParams(filterQueryParamToSearchParam(savedSearch.searchFilters, props.categories)).then(() => {
      props.onSavedSearchClick();
    });
  };

  const getCategoryText = (categoryType: PostCategoryType, selection: CategorySelection, t: TFunction): string | undefined => {
    const otherCategoryType: PostCategoryType = categoryType === 'object' ? 'service' : 'object';

    if (!selection[categoryType].isSelected || (selection[otherCategoryType].isSelected && selection[otherCategoryType].category && !selection[categoryType].category)) {
      return undefined;
    }

    const categoryLabel = selection[categoryType]?.category !== undefined ? getItemTranslation(selection[categoryType].category as Category) : undefined;
    return t(`search.${categoryType}s`) + (categoryLabel ? ' - ' + categoryLabel : '');
  };

  const savedSearches = props.savedSearchList;

  // We don't show the list when there is no saves searches
  if (!savedSearches || savedSearches?.length === 0) {
    return null;
  }

  return (
    <div className="saved-search">
      <h2 className="saved-search-title">
        <Trans i18nKey="search.saved-searches" />
        {!props.savedSearchListIsLoading && <IonIcon className="refresh-button cursor-pointer" src={refresh} onClick={() => fetchSavedSearches()} color="dark" />}
      </h2>

      <IonList data-cy="saved-search-list" className="saved-search-list" lines="none">
        {props.savedSearchListIsLoading && (
          <IonItem>
            <IonSpinner className="margin-auto" name="bubbles" color="dark" data-cy="saved-searches-loader" />
          </IonItem>
        )}
        {props.savedSearchList?.map(savedSearch => {
          const savedSearchId = extractId(savedSearch);
          const saveSearchFilters: SearchQueryParameters = filterQueryParamToSearchParam(savedSearch.searchFilters, props.categories);

          const itemSubtitle = [
            props.t(`search.${saveSearchFilters.postType === 'offer' ? 'offers' : 'requests'}`),
            getCategoryText('object', saveSearchFilters.categorySelection, props.t),
            saveSearchFilters?.universe ? translateUniverse(saveSearchFilters.universe) : undefined,
            saveSearchFilters?.size
              ? translateSize(getCategoryItemsType(props.categories, saveSearchFilters.categorySelection.object.category ?? null), saveSearchFilters?.universe ?? null, saveSearchFilters.size)
              : undefined,
            getCategoryText('service', saveSearchFilters.categorySelection, props.t),
            saveSearchFilters.locationAddress?.formatted,
            saveSearchFilters.locationRadius ? saveSearchFilters.locationRadius / 1000 + 'km' : undefined,
          ]
            .filter(item => !!item)
            .join(' - ');

          const categoryTitle = [
            saveSearchFilters.categorySelection.object.isSelected && saveSearchFilters.categorySelection.object.category
              ? getItemTranslation(saveSearchFilters.categorySelection.object.category)
              : undefined,
            saveSearchFilters.categorySelection.service.isSelected && saveSearchFilters.categorySelection.service.category
              ? getItemTranslation(saveSearchFilters.categorySelection.service.category)
              : undefined,
          ]
            .filter(item => !!item)
            .join(' - ');

          const alternativeTitle = categoryTitle ? categoryTitle : itemSubtitle;

          return (
            <IonItem className="saved-search-item" key={extractId(savedSearch)}>
              <IonIcon className="cursor-pointer" slot="start" src="/assets/icon/icon-last-search.svg" onClick={() => applySavedSearch(savedSearchId)} color="dark" />
              <div className="saved-search-item-content cursor-pointer" onClick={() => applySavedSearch(savedSearchId)}>
                <IonLabel className="item-title">{saveSearchFilters.searchText ? saveSearchFilters.searchText : alternativeTitle}</IonLabel>
                <p className="item-subtitle">{itemSubtitle}</p>
              </div>
              <IonIcon
                className="cursor-pointer saved-search-more-icon"
                slot="end"
                onClick={() => {
                  setSelectedSavedSearchId(savedSearchId);
                  setSelectedSearchFilters(saveSearchFilters);
                }}
                src="/assets/navigation/more.svg"
                color="dark"
              />
            </IonItem>
          );
        })}
      </IonList>

      <SavedSearchOptionActionSheet
        searchFilters={selectedSearchFilters}
        onShareClick={props.onShareClick}
        savedSearchId={extractId(selectedSavedSearchId, true)}
        isOpen={!!selectedSavedSearchId}
        closeActionSheet={() => setSelectedSavedSearchId(undefined)}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SavedSearchList));
