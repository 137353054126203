import React from 'react';
import { IonChip, IonImg, IonLabel } from '@ionic/react';
import { Trans } from 'react-i18next';
import { currentUserStatesWithBanner, PostState } from '../../store/posts/types';
import './PostStateChip.scss';

type PostStateChipProps = {
  state: PostState;
  hiddenStates?: Partial<Record<PostState, boolean | undefined>>;
};

const PostStateChip: React.FunctionComponent<PostStateChipProps> = ({ state, hiddenStates }) => {
  if (!currentUserStatesWithBanner.includes(state) || hiddenStates?.[state] === true) {
    return null;
  }
  return (
    <IonChip className={`ion-chip-status ion-chip-${state}`}>
      {['fully_requested', 'moderated'].includes(state) && <IonImg src={`/assets/icon/post-state/${state}.svg`} />}
      <IonLabel>
        <Trans i18nKey={`post.state.${state}`} />
      </IonLabel>
    </IonChip>
  );
};

export default React.memo(PostStateChip);
