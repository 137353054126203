import * as postsActions from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { FullPost, PostStoreState, SearchQueryParameters } from './types';
import { extendItemsCollections, initItemsCollection, mergeItemsCollections } from '../../utils/redux';
import { isSameHydraEntity } from '../../utils/helpers';

export const defaultSearchParams: SearchQueryParameters = {
  postType: 'offer',
  categorySelection: { object: { isSelected: true }, service: { isSelected: true } },
  dateOrder: 'desc',
  searchText: '',
  locationAddress: null,
};

export const defaultPostsState: PostStoreState = {
  itemIsLoading: false,
  mapIsLoading: false,
  collections: {
    need: initItemsCollection<FullPost>(),
    offer: initItemsCollection<FullPost>(),
    search: initItemsCollection<FullPost>(),
    recommendation: initItemsCollection<FullPost>(),
  },
  searchParams: defaultSearchParams,
  savedSearchListIsLoading: false,
};

export default (state = defaultPostsState, action: ActionType<typeof postsActions>): PostStoreState => {
  switch (action.type) {
    case getType(postsActions.fetchPostsCollectionAction.request):
      if (action.payload.loadNextPage || action.payload.extendCollection) {
        return { ...state };
      }
      return { ...state, collections: { ...state.collections, [action.payload.collectionName]: initItemsCollection(state.collections[action.payload.collectionName], true) } };
    case getType(postsActions.fetchPostsCollectionAction.success):
      return {
        ...state,
        collections: {
          ...state.collections,
          [action.payload.collectionName]: action.payload.extendCollection
            ? extendItemsCollections(action.payload.collection, state.collections[action.payload.collectionName])
            : mergeItemsCollections(action.payload.collection, state.collections[action.payload.collectionName]),
        },
      };
    case getType(postsActions.resetPostCollection):
      return { ...state, collections: { ...state.collections, [action.payload]: initItemsCollection<FullPost>() } };
    case getType(postsActions.fetchPostsCollectionAction.failure):
      return { ...state, collections: { ...state.collections, [action.payload.collectionName]: initItemsCollection(state.collections[action.payload.collectionName], false) } };
    case getType(postsActions.fetchPostAction.request):
    case getType(postsActions.createPostAction.request):
    case getType(postsActions.editPostAction.request):
    case getType(postsActions.updatePostStateAction.request):
      return { ...state, itemIsLoading: true };
    case getType(postsActions.fetchPostAction.failure):
    case getType(postsActions.fetchPostAction.success):
    case getType(postsActions.createPostAction.failure):
    case getType(postsActions.createPostAction.success):
    case getType(postsActions.editPostAction.failure):
    case getType(postsActions.editPostAction.success):
    case getType(postsActions.updatePostStateAction.failure):
    case getType(postsActions.updatePostStateAction.success):
      return { ...state, itemIsLoading: false };
    case getType(postsActions.fetchMapCollectionAction.request):
      return { ...state, mapIsLoading: true };
    case getType(postsActions.fetchMapCollectionAction.failure):
    case getType(postsActions.fetchMapCollectionAction.success):
      return { ...state, mapIsLoading: false };
    case getType(postsActions.setSearchParamsAction):
      return { ...state, searchParams: action.payload };
    case getType(postsActions.resetSearchParamsAction):
      return { ...state, searchParams: defaultSearchParams };
    case getType(postsActions.createSavedSearchAction.request):
    case getType(postsActions.deleteSavedSearchAction.request):
    case getType(postsActions.fetchSavedSearchesAction.request):
      return { ...state, savedSearchListIsLoading: true };
    case getType(postsActions.fetchSavedSearchesAction.success):
      return { ...state, savedSearchList: action.payload, savedSearchListIsLoading: false };
    case getType(postsActions.createSavedSearchAction.failure):
    case getType(postsActions.deleteSavedSearchAction.failure):
    case getType(postsActions.fetchSavedSearchesAction.failure):
      return { ...state, savedSearchListIsLoading: false };
    case getType(postsActions.deleteSavedSearchAction.success):
      return { ...state, savedSearchList: (state.savedSearchList || []).filter(item => !isSameHydraEntity(action.payload, item)), savedSearchListIsLoading: false };
    case getType(postsActions.createSavedSearchAction.success):
      return { ...state, savedSearchList: [action.payload].concat(state.savedSearchList || []), savedSearchListIsLoading: false };
    default:
      return state;
  }
};
