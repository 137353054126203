import React from 'react';
import { User, CurrentUser } from '../store/users/types';
import { extractId, getItemSrc, getUserAvatar, isSameHydraEntity } from '../utils/helpers';
import { Capacitor } from '@capacitor/core';
import ImageFadeIn from '../components/ImageFadeIn';
import { isMobileNavigator } from '../environment';
import { Media } from '../store/app/types';
import { useHistory } from 'react-router';
import { itemIsAMedia } from './inputs/InputMediaUploader';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import './UserMediaSlider.scss';

type Props = {
  user?: Partial<User> | CurrentUser;
  activeTab?: string;
  isCurrentUser: boolean;
};

const UserMediaSlider: React.FunctionComponent<Props> = ({ user, activeTab, isCurrentUser }: Props) => {
  if (!user?.images?.length) {
    return (
      <>
        <ImageFadeIn src={getUserAvatar(user)} alt="avatar" data-cy="user-avatar" />
        <div className="profile-image-gradient cursor-pointer" data-cy="profile-picture" />
      </>
    );
  }

  const history = useHistory();

  const redirectToUserPictures = (user: Partial<User> | CurrentUser): void => {
    if (user.images && 0 !== user.images.length) {
      const redirectionUrl = isCurrentUser ? '/me/pictures' : activeTab ? `/${activeTab}${extractId(user)}/pictures` : `${extractId(user)}/pictures`;
      history.push(redirectionUrl);
    }
  };

  let images: Media[] = user.images as Media[];
  if (itemIsAMedia(user.avatar)) {
    // Let's put the user avatar first
    images = [user.avatar].concat(images.filter(image => !isSameHydraEntity(user.avatar as Media, image)));
  }

  const showNavigationButtons = Capacitor.getPlatform() === 'web' && !isMobileNavigator && user.images?.length > 1;
  return (
    // without key IonSlider can`t unmount slides and it causes application crash when we change page
    <Swiper
      modules={[Navigation, Pagination]}
      watchOverflow={true}
      autoHeight={false}
      pagination={true}
      navigation={showNavigationButtons}
      initialSlide={0}
      cssMode={false}
      slidesPerView={1}
      speed={400}
      grabCursor={true}
      zoom={true}
      key={images.map((item: Media | File, key: number) => key).join('_')}
      className="user-images-slider"
      data-cy="profile-picture"
    >
      {images.map((image: Media | File, key: number) => (
        <SwiperSlide key={itemIsAMedia(image) ? extractId(image) : key} data-cy="images-slide">
          <ImageFadeIn thumbnail alt="preview" className="cursor-pointer" src={getItemSrc(image)} />
          <div className="profile-image-gradient cursor-pointer" onClick={() => redirectToUserPictures(user)} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default UserMediaSlider;
