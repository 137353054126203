import { TextareaChangeEventDetail } from '@ionic/core';
import { IonButton, IonButtons, IonIcon, IonItem, IonTextarea } from '@ionic/react';
import { send } from 'ionicons/icons';
import React, { useState } from 'react';
import { onTextareaCtrlEnter } from '../../utils/helpers';
import InputMediaUploader from './InputMediaUploader';

import './MessageInput.scss';

interface Props {
  message: string;
  placeholder: string;
  disableSendButton: boolean;
  sendMessage: () => void;
  sendMedia?: (file: File[]) => void;
  onChange?: (event: CustomEvent<TextareaChangeEventDetail>) => void;
  onFocus?: (event: CustomEvent<FocusEvent>) => void;
  onBlur?: (event: CustomEvent<FocusEvent>) => void;
}

const getAddPicturePlaceholder = (
  <IonButtons>
    <IonButton slot="start" data-cy="upload-chat-media">
      <IonIcon icon="/assets/tab-bar-icons/unselected-create.svg" mode="md" />
    </IonButton>
  </IonButtons>
);

const MessageInput: React.FunctionComponent<Props> = ({ message, placeholder, disableSendButton, sendMessage, sendMedia, onChange, onFocus, onBlur }: Props) => {
  const [mediaUpload, setMediaUpload] = useState([]);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  return (
    <IonItem className="message-input" lines="none">
      {sendMedia && (
        <InputMediaUploader
          value={mediaUpload}
          onValueChange={values => {
            sendMedia(values);
            setMediaUpload(values);
          }}
          onShowActionSheetChange={value => {
            setIsInputDisabled(value);
          }}
          isMediaUploaderField
          instantUpload={true}
          addPicturePlaceholder={getAddPicturePlaceholder}
        />
      )}
      <IonTextarea
        className="message-input-textarea"
        placeholder={placeholder}
        value={message}
        enterkeyhint="enter"
        autocapitalize="on"
        autoGrow
        disabled={isInputDisabled}
        autoCorrect="on"
        inputmode="text"
        rows={1}
        onKeyUp={e => onTextareaCtrlEnter(e, sendMessage)}
        onIonChange={e => {
          if (onChange) {
            onChange(e);
          }
        }}
        onIonFocus={e => {
          if (onFocus) {
            onFocus(e);
          }
        }}
        onIonBlur={e => {
          if (onBlur) {
            onBlur(e);
          }
        }}
      />
      <IonButton className="message-input-send-button" onClick={sendMessage} disabled={disableSendButton} fill="clear" size="default">
        <IonIcon icon={send} mode="md" />
      </IonButton>
    </IonItem>
  );
};

export default MessageInput;
