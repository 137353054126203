import { IonButton } from '@ionic/react';
import React, { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { fetchPostRequests } from '../../store/postRequests/actions';
import { Post } from '../../store/posts/types';
import { extractId } from '../../utils/helpers';
import { EntityReference, ReferencedCollectionResponse } from '../../utils/hydra';

interface DispatchProps {
  fetchPostRequests: (postId: EntityReference, withoutResult: boolean) => Promise<ReferencedCollectionResponse>;
}

const propsToDispatch = {
  fetchPostRequests: fetchPostRequests,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

interface PostRequestChatButtonProps {
  post: Post;
}

type Props = DispatchProps & PostRequestChatButtonProps;

const ChatRedirectionButton: React.FunctionComponent<Props> = (props: Props) => {
  useEffect(() => {
    props.fetchPostRequests(extractId(props.post), true).catch(() => {
      // Prevent fetch error
    });
  }, [props.post?.['@id']]);

  if (!props?.post?.requests?.lastFetchDate) {
    return (
      <IonButton data-cy="chat-redirection-button" className="main-button" color="primary" fill="solid" disabled>
        <Trans i18nKey="common.loading" />
      </IonButton>
    );
  }

  return (
    <>
      {/* TODO: Redirect user to the chat page of the post request instead of the chats page when there is only 1 item */}
      {props.post.requests.totalItems >= 1 && (
        <IonButton
          data-cy="chat-redirection-button"
          className="main-button"
          color="primary"
          fill="solid"
          routerLink={`/chats?postId=${extractId(props.post)}&selectedFilters=[]`}
          disabled={props.post?.requests.isLoading}
        >
          <Trans i18nKey={`post-request.see-${props.post.type}-post-request-user-chats`} values={{ count: props.post.requests.totalItems }} />
        </IonButton>
      )}

      {props.post.requests.totalItems === 0 && (
        <IonButton data-cy="chat-redirection-button" className="main-button" color="primary" fill="solid" disabled>
          <Trans i18nKey={`post-request.no-${props.post.type}-post-request-user-chat`} />
        </IonButton>
      )}
    </>
  );
};

export default connect(null, mapDispatchToProps)(ChatRedirectionButton);
