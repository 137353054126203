import React, { useEffect, useState } from 'react';
import { IonModal, IonItem, IonButton, IonIcon, IonLabel, IonContent } from '@ionic/react';
import { ellipseOutline } from 'ionicons/icons';
import i18next from 'i18next';
import { ObjectsUniverse } from '../store/posts/types';
import CommonHeader from './CommonHeader';
import { CategoryItemType } from '../store/categories/types';
import { categoryItemsTypeHasUniverse, getSizesByUniverse, getSizesWithoutUniverse, translateSize, translateUniverse } from '../utils/sizesHelper';

import './SizesModal.scss';

interface ModalProps {
  isOpen: boolean;
  onSelectionSubmit: (selection?: SizeData) => void;
  onCloseModal: () => void;
  categoryItemsType: CategoryItemType | null;
  defaultUniverse: ObjectsUniverse | null;
}

type Props = ModalProps;

interface ItemProps {
  label: string;
  onClick: () => void;
  icon: string;
  iconColor?: string;
}

export interface SizeData {
  universe?: ObjectsUniverse;
  size?: string;
}

const SelectableListItem: React.FunctionComponent<ItemProps> = ({ label, onClick, icon, iconColor = '' }: ItemProps) => {
  return (
    <IonItem onClick={onClick}>
      <IonLabel className="selectable-item">
        <span className="selectable-item-label">{label}</span>
        <IonButton className="arrow-forward-button" fill="clear" slot="end" color={iconColor}>
          <IonIcon icon={icon} />
        </IonButton>
      </IonLabel>
    </IonItem>
  );
};

const SizesModal: React.FunctionComponent<Props> = (props: Props) => {
  const { isOpen, categoryItemsType } = props;

  if (!categoryItemsType) {
    return null;
  }

  const [selectedUniverse, setSelectedUniverse] = useState<ObjectsUniverse | null>(props.defaultUniverse);
  const [selectedSize, setSelectedSize] = useState<string | undefined>(undefined);
  const [universes, setUniverses] = useState<ObjectsUniverse[]>([]);
  const [sizes, setSizes] = useState<string[]>([]);

  useEffect(() => {
    if (categoryItemsTypeHasUniverse(categoryItemsType)) {
      setUniverses(Object.keys(getSizesByUniverse(categoryItemsType)) as ObjectsUniverse[]);
      return;
    }

    // The type does not have any universes
    setUniverses([]);
    setSizes(getSizesWithoutUniverse(categoryItemsType));
  }, [categoryItemsType]);

  useEffect(() => {
    if (categoryItemsTypeHasUniverse(categoryItemsType)) {
      if (!selectedUniverse) {
        setSizes([]);
        return;
      }

      setSizes(getSizesByUniverse(categoryItemsType)?.[selectedUniverse] || []);
      return;
    }

    // The type does not have any universes
    setSizes(getSizesWithoutUniverse(categoryItemsType));
  }, [selectedUniverse]);

  useEffect(() => {
    if (!selectedSize) {
      return;
    }

    props.onSelectionSubmit({ universe: selectedUniverse || undefined, size: selectedSize });
    props.onCloseModal();
  }, [selectedSize]);

  const sizeBackButtonClick = (): void => {
    if (!categoryItemsTypeHasUniverse(categoryItemsType)) {
      props.onCloseModal();
    } else {
      setSelectedUniverse(null);
    }
  };

  const mustChooseUniverse = !selectedUniverse && categoryItemsTypeHasUniverse(categoryItemsType);

  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => props.onCloseModal()} className="sizes-modal safe-area-ios" data-cy="sizes-modal">
      {mustChooseUniverse && (
        <>
          <CommonHeader title={i18next.t('sizes.select-universe-title')} stopPropagation onBackButtonClick={() => props.onCloseModal()} />

          <IonContent>
            {universes.map(universe => (
              <SelectableListItem key={universe} label={translateUniverse(universe)} onClick={() => setSelectedUniverse(universe)} icon="/assets/navigation/ellipse_outline.svg" />
            ))}
          </IonContent>
        </>
      )}
      {!mustChooseUniverse && (
        <>
          <CommonHeader title={i18next.t('sizes.select-size-title', { universe: translateUniverse(selectedUniverse) })} stopPropagation onBackButtonClick={sizeBackButtonClick} />
          <IonContent>
            {sizes.map(size => (
              <SelectableListItem key={size} label={translateSize(categoryItemsType, selectedUniverse, size)} onClick={() => setSelectedSize(size)} icon={ellipseOutline} iconColor="primary" />
            ))}
          </IonContent>
        </>
      )}
    </IonModal>
  );
};

export default SizesModal;
