import { Thematic } from './types';
import { extractId, isSameHydraEntity } from '../../utils/helpers';
import { EntityReference } from '../../utils/hydra';
import uniq from 'lodash/uniq';

export function getParentThematics(thematics: Thematic[]): Thematic[] {
  return thematics.filter(thematic => !thematic.parent);
}

export function getChildrenOfThematic(thematics: Thematic[], parent: Thematic): Thematic[] {
  return thematics.filter(thematic => thematic.parent && extractId(thematic.parent) === extractId(parent));
}

export function filterThematicsByIds(thematics: Thematic[], thematicsIds: EntityReference[]): Thematic[] {
  if (!thematics || !thematicsIds) {
    return [];
  }
  return thematics.filter(thematic => thematicsIds.includes(extractId(thematic)));
}

export function getSuggestionsThematics(thematics: Thematic[], userThematics: Thematic[]): Thematic[] {
  return uniq(
    getParentThematics(userThematics).concat(
      // Add parents of thematics not included in the list
      userThematics
        .map(thematic => {
          if (!userThematics.find(t => isSameHydraEntity(t, thematic.parent))) {
            return thematics.find(t => isSameHydraEntity(t, thematic.parent));
          }
          return undefined;
        })
        .filter(thematic => !!thematic) as Thematic[],
    ),
  );
}
