import { get, has, keyBy, mapValues, range } from 'lodash';
import { CategoryItemType, categoryItemTypesWithoutUniverse } from '../store/categories/types';
import { ObjectsUniverse } from '../store/posts/types';
import i18next from 'i18next';

const generateShoesSizes = (from: number, to: number): Record<string, string> => {
  return mapValues(
    keyBy(
      range(from, to).map(size => ({ key: '' + size, value: '' + size })),
      'key',
    ),
    'value',
  );
};

const sizesWithUniverse = {
  clothes: {
    woman: {
      xs: 'XS - 34 - 6',
      s: 'S - 36 - 8',
      m: 'M - 38 - 10',
      l: 'L - 40 - 12',
      xl: 'XL - 42 - 14',
      xxl: 'XXL - 44 - 16',
      xxxl: 'XXXL - 46 - 18',
      unique: 'sizes.unique',
      other: 'sizes.other',
    },
    man: {
      xs: 'XS - 34 - 6',
      s: 'S - 36 - 8',
      m: 'M - 38 - 10',
      l: 'L - 40 - 12',
      xl: 'XL - 42 - 14',
      xxl: 'XXL - 44 - 16',
      xxxl: 'XXXL - 46 - 18',
      unique: 'sizes.unique',
      other: 'sizes.other',
    },
    child: {
      newborn: 'sizes.clothes.newborn',
      '1-month': 'sizes.clothes.1-month',
      '3-months': 'sizes.clothes.3-months',
      '6-months': 'sizes.clothes.6-months',
      '9-months': 'sizes.clothes.9-months',
      '12-months': 'sizes.clothes.12-months',
      '18-months': 'sizes.clothes.18-months',
      '24-months': 'sizes.clothes.24-months',
      '36-months': 'sizes.clothes.36-months',
      '4-years': 'sizes.clothes.4-years',
      '5-years': 'sizes.clothes.5-years',
      '6-years': 'sizes.clothes.6-years',
      '7-years': 'sizes.clothes.7-years',
      '8-years': 'sizes.clothes.8-years',
      '9-years': 'sizes.clothes.9-years',
      '10-years': 'sizes.clothes.10-years',
      '11-years': 'sizes.clothes.11-years',
      '12-years': 'sizes.clothes.12-years',
      '13-years': 'sizes.clothes.13-years',
      '14-years': 'sizes.clothes.14-years',
      '15-years': 'sizes.clothes.15-years',
      '16-years': 'sizes.clothes.16-years',
      unique: 'sizes.unique',
      other: 'sizes.other',
    },
  },
  shoes: {
    child: generateShoesSizes(12, 40),
    man: generateShoesSizes(38, 49),
    woman: generateShoesSizes(35, 43),
  },
};

const sizesWithoutUniverse = {
  bra: {
    '80a': '80A',
    '80b': '80B',
    '80c': '80C',
    '80d': '80D',
    '80e': '80E',
    '80f': '80F',
    '85a': '85A',
    '85b': '85B',
    '85c': '85C',
    '85d': '85D',
    '85e': '85E',
    '85f': '85F',
    '90a': '90A',
    '90b': '90B',
    '90c': '90C',
    '90d': '90D',
    '90e': '90E',
    '90F': '90F',
    '95a': '95A',
    '95b': '95B',
    '95c': '95C',
    '95d': '95D',
    '95e': '95E',
    '95f': '95F',
    '95g': '95G',
    '95h': '95H',
    '95j': '95J',
    '100a': '100A',
    '100b': '100B',
    '100c': '100C',
    '100d': '100D',
    '100e': '100E',
    '100f': '100F',
    '100g': '100G',
    '100h': '100H',
    '100j': '100J',
    '105a': '105A',
    '105b': '105B',
    '105c': '105C',
    '105d': '105D',
    '105e': '105E',
    '105f': '105F',
    '105g': '105G',
    '105h': '105H',
    '105j': '105J',
    '110a': '110A',
    '110b': '110B',
    '110d': '110D',
    '110c': '110C',
    '110e': '110E',
    '110f': '110F',
    '110g': '110G',
    '110h': '110H',
    '110j': '110J',
    '115a': '115A',
    '115b': '115B',
    '115c': '115C',
    '115d': '115D',
    '115e': '115E',
    '115f': '115F',
    '115g': '115G',
    '115h': '115H',
    '115j': '115J',
    unique: 'sizes.unique',
    other: 'sizes.other',
  },
  fashion_accessory: { unique: 'sizes.unique', s: 'S', m: 'M', l: 'L', xl: 'XL' },
};

export const categoryItemsTypeHasUniverse = (categoryItemType: CategoryItemType): boolean => {
  return !categoryItemTypesWithoutUniverse.includes(categoryItemType);
};

export const getSizesWithoutUniverse = (categoryItemType: CategoryItemType): string[] => {
  if (!has(sizesWithoutUniverse, categoryItemType)) {
    throw new Error(`The '${categoryItemType}' category has not been found in categories without universe.`);
  }

  return Object.keys(get(sizesWithoutUniverse, categoryItemType) as object);
};

export const getSizesByUniverse = (categoryItemType: CategoryItemType): Partial<Record<ObjectsUniverse, string[]>> => {
  if (!has(sizesWithUniverse, categoryItemType)) {
    throw new Error(`The '${categoryItemType}' category has not been found in categories with universe.`);
  }

  return mapValues(get(sizesWithUniverse, categoryItemType), sizes => Object.keys(sizes));
};

export const translateUniverse = (universe: ObjectsUniverse | string | null): string => {
  if (!universe) {
    return '';
  }
  return i18next.t(`universe.${universe}`);
};

export const translateSize = (categoryItemType: CategoryItemType | null, universe: ObjectsUniverse | null | undefined, size: string): string => {
  if (!categoryItemType) {
    return size;
  }

  let sizeLabel: string;

  if (universe) {
    sizeLabel = get(sizesWithUniverse, [categoryItemType, universe, size]);
  } else {
    sizeLabel = get(sizesWithoutUniverse, [categoryItemType, size]);
  }

  return sizeLabel && sizeLabel.includes('.') ? i18next.t(sizeLabel) : sizeLabel;
};
