import { AnyAction, Middleware } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { getLocationPathname, locationPathnameIncludes } from '../../utils/windowLocationHelper';
import * as chatsActions from '../chats/actions';
import * as postRequestsActions from '../postRequests/actions';
import * as appActions from '../app/actions';
import { actions, RootState } from '../index';
import { ThunkResultDispatch } from '../types';
import * as notificationsActions from './actions';
import * as layoutActions from '../layout/actions';
import { defaultState } from './reducer';
import { fetchLoggedUser } from '../app/actions';
import { setLocalNotification } from '../../utils/notificationsHelper';

export const notificationMiddleware: Middleware<unknown, RootState> = store => next => (action: ActionType<typeof actions.app | AnyAction>) => {
  typeof action === 'function' ? action(next) : next(action);

  const dispatch = store.dispatch as ThunkResultDispatch;

  if (action.type === getType(notificationsActions.notificationReceivedAction)) {
    if (!action.payload) {
      return;
    }

    const notificationData = action.payload.data;

    if (notificationData.reason === 'direct_chat_new_message') {
      if (getLocationPathname() === notificationData.thread.subjectIri) {
        dispatch(chatsActions.fetchChatMessages(notificationData.thread.subjectIri));
        return;
      }
    }
    if (notificationData.reason === 'post_request_state_change') {
      if (getLocationPathname() === notificationData.data?.chatIri) {
        dispatch(chatsActions.fetchUserChat(notificationData.data?.chatIri));
        return;
      }
    }
    if (notificationData.reason === 'post_request_review') {
      if (getLocationPathname() === notificationData.data?.chatIri) {
        dispatch(postRequestsActions.fetchPostRequestReviews(notificationData.data?.postRequestIri));
        return;
      }
    }
    if (notificationData.reason === 'post_new_request') {
      // Chat page
      if (getLocationPathname() === notificationData.data?.chatIri) {
        dispatch(postRequestsActions.fetchPostRequestReviews(notificationData.data?.postRequestIri));
        return;
      }
      // Post page
      if (locationPathnameIncludes(notificationData.thread.subjectIri)) {
        dispatch(postRequestsActions.fetchPostRequests(notificationData.thread.subjectIri));
        return;
      }
    }

    // We fetch the current user in order to update the notifications badges numbers
    // If the user is in the chat related to the notification, this action won't be dispatched (avoid an unnecessary incrementation of the chat badge number)
    dispatch(appActions.fetchLoggedUser());

    // Local notification won't be shown if we are in the chat concerned by the notification.
    setLocalNotification(action.payload);
  }

  if (action.type === getType(notificationsActions.sendGlobalNotificationAction.success)) {
    dispatch(layoutActions.setToastMessageAction('notification.notification-for-all-users-has-been-sent'));
    dispatch(notificationsActions.setGlobalNotificationAction(defaultState.globalNotification));
  }

  if (action.type === getType(notificationsActions.sendGlobalNotificationAction.failure)) {
    dispatch(layoutActions.setToastMessageAction('notification.notification-for-all-users-has-not-been-sent'));
  }

  if (action.type === getType(notificationsActions.updateAllNotificationsStatusAction)) {
    dispatch(layoutActions.setToastMessageAction('notification.read-all-success'));
  }

  if (
    action.type === getType(notificationsActions.updateAllNotificationsStatusAction) ||
    action.type === getType(notificationsActions.updateNotificationStatusAction) ||
    action.type === getType(notificationsActions.sendGlobalNotificationAction.success)
  ) {
    dispatch(fetchLoggedUser());
  }
};
