import React, { ReactNode } from 'react';
import { IonIcon, IonItem, IonLabel, IonThumbnail } from '@ionic/react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { getDisabledUserName, getUserAvatar, getUserUrlFromActiveTab, organizationTypeNeedsValidation } from '../utils/helpers';
import { User } from '../store/users/types';
import './UserItem.scss';
import ImageFadeIn from './ImageFadeIn';
import { useTranslation } from 'react-i18next';

interface ComponentProps {
  user: Partial<User> | undefined;
  isCurrentUser?: boolean;
  chipComponent?: ReactNode;
  size?: 'small' | 'large';
  routingComponent?: boolean;
}

type Props = ComponentProps;

const getOrganizationIcon = (type: string): ReactNode => <IonIcon slot="end" src={'/assets/organization-icons/icon-' + type + '.svg'} className="organization-icon" />;

const organizationCertifiedIcon = (): ReactNode => <IonIcon slot="start" size="small" src={'/assets/icon/certified-account.svg'} className="organization-certified-icon" />;

const UserItem: React.FunctionComponent<Props> = ({ user, isCurrentUser, chipComponent, size, routingComponent = true }: Props) => {
  const activeTab = useSelector((state: RootState) => state.navigation.activeTab);
  const { t } = useTranslation();
  if (!user) {
    return <></>;
  }

  const routerLink = routingComponent ? { routerLink: getUserUrlFromActiveTab(!!isCurrentUser, activeTab, user) } : undefined;

  return (
    <IonItem {...routerLink} lines="none" detail={false} button={!!routingComponent} className={`user-item ${size || ''}`}>
      <IonThumbnail slot="start" className="user-picture">
        <ImageFadeIn thumbnail src={getUserAvatar(user as User)} alt="avatar" />
      </IonThumbnail>
      {user?.managed && user?.managedData?.certified && organizationCertifiedIcon()}
      <div className={`user-description ${chipComponent ? 'with-bottom-information' : ''}`}>
        <IonLabel className="user-name" color="dark">
          {!user?.disabled || organizationTypeNeedsValidation(user as User) ? user?.name : getDisabledUserName(user as User, t)}
        </IonLabel>
        <div className="user-chips">{chipComponent}</div>
      </div>
      {user.managed && user.managedData?.type && getOrganizationIcon(user.managedData.type)}
    </IonItem>
  );
};

export default React.memo(UserItem);
