import { AuthenticationError } from './dataAccessError';
import store from '../store';
import { authenticateAsWhoAmIWithRefreshToken, authenticateWithRefreshToken, logout } from '../store/app/actions';
import { ThunkResultDispatch } from '../store/types';
import { FetchOptions, hydraFetch, initFetchOptions, StrictFetchOptions } from './dataAccess';
import { JWTToken } from '../store/app/types';

let refreshTokenRequest: null | Promise<JWTToken> = null;

function getUserToken(): string | null {
  return store.getState().app.token;
}

function getUserRefreshToken(): string | null {
  return store.getState().app.refreshToken;
}

function getWhoAmIToken(): string | undefined {
  return store.getState().whoAmI.whoAmIToken?.token;
}

function getWhoAmIRefreshToken(): string | undefined {
  return store.getState().whoAmI.whoAmIToken?.refreshToken;
}

function getStoreDispatch(): ThunkResultDispatch {
  return store.dispatch;
}

export function refreshToken(refreshToken?: string | null, authenticateAsWhoAmI?: boolean): Promise<JWTToken> {
  if (null !== refreshTokenRequest) {
    return refreshTokenRequest;
  }

  if (!refreshToken) {
    refreshToken = getUserRefreshToken();
  }

  if (null === refreshToken) {
    return Promise.reject(new Error('Refresh token not found'));
  }

  if (authenticateAsWhoAmI) {
    return (refreshTokenRequest = getStoreDispatch()(authenticateAsWhoAmIWithRefreshToken(refreshToken)).finally(() => {
      refreshTokenRequest = null;
    }));
  }

  return (refreshTokenRequest = getStoreDispatch()(authenticateWithRefreshToken(refreshToken)).finally(() => {
    refreshTokenRequest = null;
  }));
}

export function addAuthorizationHeader(options: StrictFetchOptions, authenticateAsWhoAmI?: boolean): void {
  options.headers.delete('Authorization');

  const token = authenticateAsWhoAmI ? getWhoAmIToken() : getUserToken();
  if (null !== token) {
    options.headers.set('Authorization', 'Bearer ' + token);
  }
}

export interface ParsedJwt {
  iat: number;
  exp: number;
  roles?: string[];
  username?: string;
}

function parseJwt(token: string): ParsedJwt {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

function mustRefreshToken(token?: string | null, refreshToken?: string | null): boolean {
  if (!token) {
    token = getUserToken();
  }
  if (!refreshToken) {
    refreshToken = getUserRefreshToken();
  }

  if (null === token && null !== refreshToken) {
    return true;
  }

  if (null !== token) {
    const jwtParsedToken = parseJwt(token);

    if (jwtParsedToken.exp <= Math.floor(Date.now() / 1000)) {
      return true;
    }
  }

  return false;
}

const refreshCatch =
  (reject?: (reason?: Error) => void): ((error: Error) => void) =>
  (error: Error): void => {
    getStoreDispatch()(logout());

    if (reject) {
      reject(error);
    }
  };

export function runFetchWithAuth(page: string, options: StrictFetchOptions, authenticateAsWhoAmI?: boolean): Promise<Response> {
  return new Promise((resolve, reject): void => {
    addAuthorizationHeader(options, authenticateAsWhoAmI);
    hydraFetch(page, options)
      .then(response => resolve(response))
      .catch(error => {
        if (!(error instanceof AuthenticationError)) {
          reject(error);
          return;
        }

        refreshToken()
          .then(() => {
            addAuthorizationHeader(options, authenticateAsWhoAmI);
            hydraFetch(page, options)
              .then(response => resolve(response))
              .catch(refreshCatch(reject));
          })
          .catch(refreshCatch());
      });
  });
}

export function fetchWithAuth(page: string, fetchOptions?: FetchOptions): Promise<Response> {
  const options = initFetchOptions(fetchOptions);

  if (null === getUserToken()) {
    return Promise.reject(new Error('User token not found'));
  }

  if (mustRefreshToken()) {
    return refreshToken()
      .catch(refreshCatch())
      .then(() => runFetchWithAuth(page, options));
  }

  return runFetchWithAuth(page, options);
}

export function fetchWithAuthIfPossible(page: string, fetchOptions?: FetchOptions): Promise<Response> {
  if (null === getUserToken()) {
    return hydraFetch(page, fetchOptions);
  }

  return fetchWithAuth(page, fetchOptions);
}

export function fetchWithAuthAsWhoAmI(page: string, fetchOptions?: FetchOptions): Promise<Response> {
  const options = initFetchOptions(fetchOptions);

  if (null === getWhoAmIToken()) {
    return Promise.reject(new Error('User token not found'));
  }

  if (mustRefreshToken(getWhoAmIToken(), getWhoAmIRefreshToken())) {
    return refreshToken(getWhoAmIRefreshToken(), true)
      .catch(refreshCatch())
      .then(() => runFetchWithAuth(page, options, true));
  }

  return runFetchWithAuth(page, options, true);
}
