import { Components } from '@ionic/core';
import { IonButton, IonContent, IonInput, IonItem, IonLabel, IonList, IonModal, IonSpinner } from '@ionic/react';
import { FormikProps } from 'formik';
import i18next from 'i18next';
import React, { ChangeEvent, ReactNode } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { actions, RootState } from '../../store';
import { AskForGeolocationParameters, GeolocationData } from '../../store/layout/types';
import ActivateLocation from '../ActivateLocation';
import { FormValue } from '../DynamicForm';
import { GeocoderResult, getAddressValuesFromGoogleResults, getGeocoderResultFromDevicePosition, PlaceResult, waitForGoogleMaps } from '../../utils/geolocationHelpers';
import { getPromisedValueWithRetry, setStatePromise } from '../../utils/helpers';

export interface GetPositionButtonProps {
  getPosition: () => void;
  loading: boolean;
}

interface InputPlaceAutocompleteProps {
  form: FormikProps<FormValue>;
  onValueChange: (value: FormValue) => void;
  showLabel: boolean;
  placeholder?: string;
  geoPositionButton?: React.FunctionComponent<GetPositionButtonProps>;
  citiesOnly?: boolean;
  value?: string;
}

interface InputPlaceAutocompleteState {
  googleMapsLoaded: boolean;
  geolocationInProgress: boolean;
  geolocationWasRequested: boolean;
  modalOpened: boolean;
  addressesNearUser: google.maps.GeocoderResult[];
  inputValue: string;
}

interface StateProps {
  geolocationData: GeolocationData;
}

const mapStateToProps = (state: RootState): StateProps => ({
  geolocationData: state.layout.geolocationData,
});

interface DispatchProps {
  askForGeolocationDataAction: (askForGeolocationParameters?: AskForGeolocationParameters) => void;
  setToastMessage(message: string | null): void;
}

const propsToDispatch = {
  askForGeolocationDataAction: actions.layout.askForGeolocationDataAction,
  setToastMessage: actions.layout.setToastMessageAction,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type Props = React.HTMLAttributes<unknown> & Partial<Components.IonInput> & InputPlaceAutocompleteProps & StateProps & DispatchProps;

class InputPlaceAutocomplete extends React.Component<Props, InputPlaceAutocompleteState> {
  private autocomplete?: google.maps.places.Autocomplete;
  private autocompleteListener?: google.maps.MapsEventListener;
  private autocompleteInputRefItem: HTMLIonInputElement | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      googleMapsLoaded: false,
      geolocationInProgress: false,
      geolocationWasRequested: false,
      modalOpened: false,
      addressesNearUser: [],
      inputValue: this.props.value?.toString() ?? '',
    };
  }

  public async componentDidMount(): Promise<void> {
    try {
      await waitForGoogleMaps();
      this.setState({ googleMapsLoaded: true });
    } catch (e) {
      this.props.setToastMessage('Unable to load the autocomplete script: ' + e.message);
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.value !== this.props.value) {
      this.setState({ inputValue: this.props.value || '' });
    }

    const { lastFetchPositionDate, geolocationErrorMessage, devicePosition } = { ...this.props.geolocationData };

    const positionWasFetch = prevProps.geolocationData?.lastFetchPositionDate !== lastFetchPositionDate;
    const positionIsNowAvailable = prevProps.geolocationData.geolocationErrorMessage?.includes('location unavailable') && !geolocationErrorMessage?.includes('location unavailable');

    if ((!prevProps.geolocationData?.devicePosition && devicePosition && this.state.geolocationInProgress) || positionIsNowAvailable) {
      this.getAddressesFromDevicePosition();
      return;
    }

    if (positionWasFetch && geolocationErrorMessage?.includes('location unavailable') && this.state.geolocationInProgress) {
      this.setState({ geolocationInProgress: false });
    }
  }

  public componentWillUnmount(): void {
    this.autocompleteListener?.remove();
    delete this.autocompleteListener;
    this.autocomplete?.unbindAll();
  }

  setAutocompleteInputRef = async (element: HTMLIonInputElement | null): Promise<void> => {
    if (element === null || element === this.autocompleteInputRefItem) {
      return;
    }

    await waitForGoogleMaps();

    this.autocompleteInputRefItem = element;

    if (this.autocompleteListener) {
      this.autocompleteListener.remove();
      delete this.autocompleteListener;
    }

    delete this.autocomplete;

    let options: google.maps.places.AutocompleteOptions = {};
    if (this.props.citiesOnly) {
      options = { types: ['(cities)'] };
    }

    try {
      const inputEl = await getPromisedValueWithRetry<HTMLInputElement>(element.getInputElement.bind(element));

      this.autocomplete = new google.maps.places.Autocomplete(inputEl, options);
      this.autocompleteListener = await this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
    } catch (e) {
      console.error('Unable to get the autocomplete input element: ' + (e.message || e));
      return;
    }
  };

  public render(): ReactNode {
    const { geolocationPermission, geolocationErrorMessage, devicePosition } = { ...this.props.geolocationData };
    const fieldHasError = (this.props.form.status && this.props.form.status['address.formatted']) || (this.props.form.errors['city'] && this.props.form.touched['city']);

    let suggestions = this.state.addressesNearUser || [];
    let modalTitle = i18next.t('post.choose-address');

    if (this.props.citiesOnly) {
      const citiesTypes = ['locality', 'postal_code'];
      suggestions = suggestions.filter((result: GeocoderResult) => {
        return result.types.some(type => citiesTypes.includes(type));
      });
      modalTitle = i18next.t('user.choose-city');
    }

    return (
      <div className="full-width display-grid">
        <IonItem className={fieldHasError ? 'ion-invalid' : ''}>
          {this.props.showLabel && (
            <IonLabel position="floating" color={fieldHasError ? 'danger' : 'primary'}>
              {i18next.t('post.location')}
            </IonLabel>
          )}
          <IonInput
            ref={this.setAutocompleteInputRef}
            enterkeyhint="search"
            clearInput
            autofocus
            disabled={!this.state.googleMapsLoaded}
            name={this.props.name}
            value={this.state.inputValue}
            onIonChange={e => this.setState({ inputValue: e.detail.value || '' })}
            placeholder={this.props.placeholder || ''}
          />
          <input hidden name={this.props.name + 'Hidden'} onChange={this.handleHiddenInputChanged} placeholder={this.props.placeholder || ''} />
        </IonItem>
        {this.props.geoPositionButton ? (
          this.props.geoPositionButton({
            getPosition: () => this.tryToGetAddressesFromDevicePosition(),
            loading: this.state.geolocationInProgress && geolocationPermission !== 'denied',
          })
        ) : (
          <IonButton className="ion-margin-vertical margin-horizontal-auto" onClick={() => this.tryToGetAddressesFromDevicePosition()} disabled={!this.state.googleMapsLoaded}>
            {i18next.t('post.geolocate-me')}
            {this.state.geolocationInProgress && geolocationPermission === 'granted' && !geolocationErrorMessage?.includes('location unavailable') && <IonSpinner name="lines-small" />}
          </IonButton>
        )}

        {this.state.geolocationWasRequested && ((geolocationPermission !== 'granted' && !devicePosition) || !!geolocationErrorMessage) && (
          <ActivateLocation refreshAction={this.getAddressesFromDevicePosition} />
        )}

        <IonModal backdropDismiss animated={false} isOpen={this.state.modalOpened} onDidDismiss={this.ensureModalClosed} className="safe-area-ios" data-cy="address-suggestions-modal">
          <IonContent>
            <h3 className="ion-text-center">{modalTitle}</h3>

            <IonList>
              {suggestions.map((value: GeocoderResult) => (
                <IonItem button key={value.place_id} lines="inset" onClick={() => this.handleAddressSelection(value)}>
                  {value.formatted_address}
                </IonItem>
              ))}
            </IonList>
          </IonContent>
        </IonModal>
      </div>
    );
  }

  private tryToGetAddressesFromDevicePosition = async (): Promise<void> => {
    this.props.askForGeolocationDataAction({ forceRefreshPosition: true, askPermissions: true });
    this.getAddressesFromDevicePosition();
  };

  private getAddressesFromDevicePosition = async (): Promise<void> => {
    this.setState({ geolocationInProgress: true, geolocationWasRequested: true });

    if (!this.props.geolocationData?.devicePosition) {
      return;
    }

    try {
      const results = await getGeocoderResultFromDevicePosition(this.props.geolocationData?.devicePosition);
      this.setState({ modalOpened: true, geolocationInProgress: false, addressesNearUser: results });
    } catch (e) {
      this.setState({ geolocationInProgress: false });
    }
  };

  private handleHiddenInputChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    // This method is only called by tests: $input[0].dispatchEvent(new Event('change', { value: address, bubbles: true }));
    if (!event) {
      return;
    }
    const value = JSON.parse(event.target.value);

    this.ensureModalClosed();
    this.props.onValueChange(value);
  };

  private handlePlaceChanged = (): void => {
    if (!this.autocomplete) {
      return;
    }
    const place: PlaceResult = this.autocomplete.getPlace();
    this.handleAddressSelection(place);
  };

  private ensureModalClosed: () => Promise<void> = async () => {
    if (this.state.modalOpened) {
      return setStatePromise(this, { modalOpened: false });
    }
  };

  private async handleAddressSelection(addressObject: PlaceResult | GeocoderResult): Promise<void> {
    await this.ensureModalClosed();

    if (!addressObject?.formatted_address) {
      // Empty field
      this.props.onValueChange(null);
      return;
    }

    const value = await getAddressValuesFromGoogleResults(addressObject);
    this.props.onValueChange(value);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InputPlaceAutocomplete);
