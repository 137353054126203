import { Category, CategoryItemType, categoryItemTypesWithSize, Discriminant } from '../store/categories/types';
import { isSameHydraEntity } from './helpers';
import { ObjectsUniverse } from '../store/posts/types';
import { translateSize, translateUniverse } from './sizesHelper';

export const getCategoryParents = (categories: Category[], category: Category, parents: Category[] = []): Category[] => {
  parents.push(category);

  if (!category.parent) {
    return parents;
  }
  const parentCategory = categories.find(cat => isSameHydraEntity(cat, category.parent));
  if (!parentCategory) {
    return parents;
  }

  return getCategoryParents(categories, parentCategory, parents);
};

export const getChildCategories = (categories: Category[] | undefined, category: Category): Category[] => {
  if (!categories) {
    return [];
  }
  return categories.filter(cat => isSameHydraEntity(cat.parent, category));
};

export const getCategoryAndParentsTypes = (categories: Category[], category: Category): CategoryItemType[] => {
  return getCategoryParents(categories, category)
    .map(cat => cat.itemsType)
    .filter(type => !!type) as CategoryItemType[];
};

export const getCategoryItemsType = (categories: Category[], category: Category | null): CategoryItemType | null => {
  if (!category) {
    return null;
  }
  const itemTypes = getCategoryAndParentsTypes(categories, category);

  if (!itemTypes.length) {
    return null;
  }

  // Returning the first type found. Further parents are last.
  return itemTypes[0];
};

export const categoryWithSize = (categories: Category[], category: Category | null): boolean => {
  if (!category) {
    return false;
  }

  const categoryAndParentsTypes = getCategoryAndParentsTypes(categories, category);

  return !!categoryAndParentsTypes.find(type => categoryItemTypesWithSize.includes(type));
};

export const getCategoryWithIcon = (categories: Category[], category: Category): Category | undefined => {
  return getCategoryParents(categories, category)?.find(category => category.icon);
};

export const getSizeLabel = (sizedPost: { size?: string | null; universe?: ObjectsUniverse | null }, categories: Category[], category: Category): string => {
  const labelParts = [];

  if (sizedPost?.universe) {
    labelParts.push(translateUniverse(sizedPost.universe));
  }
  if (sizedPost?.size) {
    labelParts.push(translateSize(getCategoryItemsType(categories, category), sizedPost.universe, sizedPost.size));
  }

  return labelParts.join(' - ');
};

export const getCategoryWithMinAndMaxWeight = (categories: Category[], category: Category): Category | undefined => {
  if (category.itemMinWeight) {
    return category;
  }
  const parentCategory = categories.find(cat => isSameHydraEntity(cat, category.parent));
  if (!parentCategory) {
    return undefined;
  }

  return getCategoryWithMinAndMaxWeight(categories, parentCategory);
};

export const getCategoryDiscriminants = (categories: Category[], category: Category): Discriminant[] => {
  if (!category.parent) {
    return category.discriminants || [];
  }

  const parentCategory = categories.find(cat => isSameHydraEntity(cat, category.parent));
  if (category.overrideParentDiscriminants || !parentCategory) {
    return category.discriminants || [];
  }

  return [...(category.discriminants || []), ...getCategoryDiscriminants(categories, parentCategory)];
};

export const getCategoryAverageWeight = (category: Category): number => {
  return category.itemDefaultWeight ?? ((category.itemMinWeight ?? 0) + (category.itemMaxWeight ?? 0)) / 2;
};
