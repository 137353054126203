import React from 'react';
import { IonButton, IonIcon, IonModal } from '@ionic/react';

import './IndigoDevientDonnonsModal.scss';
import { IndigoDevientDonnonsData } from './store/app/types';
import AppStore from './AppStore';
import AndroidStore from './AndroidStore';

interface IndigoDevientDonnonsModalProps {
  onClose: () => void;
  type: 'homepage' | 'give';
  data: IndigoDevientDonnonsData;
}

const IndigoDevientDonnonsModal: React.FC<IndigoDevientDonnonsModalProps> = ({ onClose, type, data }) => {
  const res = data.data.variants[type];

  return (
    <IonModal isOpen>
      <div className="indigo-devient-donnons-modal-container">
        <div className="indigo-devient-donnons-modal-close">
          <IonIcon className="close-modal-button" icon={'/assets/icon/icon-close-modal.svg'} onClick={onClose} />
        </div>
        <div className="indigo-devient-donnons-modal-content">
          <img src={res.image} alt="Indigo Devient Donnons" />
          <h1>{res.title}</h1>
          <p>{res.text}</p>
          <div>
            {res.buttons.ios && (
              <a href={res.buttons.ios}>
                <AppStore />
              </a>
            )}
            {res.buttons.android && (
              <a href={res.buttons.android}>
                <AndroidStore />
              </a>
            )}
          </div>
        </div>
        <div>
          <a href={res.buttons.cta.href}>
            <IonButton>{res.buttons.cta.text}</IonButton>
          </a>
        </div>
      </div>
    </IonModal>
  );
};

export default IndigoDevientDonnonsModal;
