import React from 'react';
import { IonCol, IonGrid, IonRow, IonSkeletonText } from '@ionic/react';

import './SuggestionListPlaceholder.scss';

const SmallServicePlaceholder: React.FunctionComponent = () => {
  return (
    <div className="small-post-placeholder _service">
      <p>
        <IonSkeletonText style={{ width: '89%' }} />
      </p>
      <p>
        <IonSkeletonText style={{ width: '89%' }} />
      </p>
      <p>
        <IonSkeletonText style={{ width: '79%' }} />
      </p>
      <p>
        <IonSkeletonText style={{ width: '32%' }} />
      </p>
      <p>
        <IonSkeletonText style={{ width: '50%' }} />
      </p>
    </div>
  );
};

const SmallObjectPlaceholder: React.FunctionComponent = () => {
  return (
    <div className="small-post-placeholder _object">
      <p>
        <IonSkeletonText />
      </p>
    </div>
  );
};

export const SuggestionListCollectionPlaceholder: React.FunctionComponent = () => {
  return (
    <IonRow className="suggestion-list-placeholder">
      <IonCol size="3.5" className="suggestions-placeholder-col">
        <SmallServicePlaceholder />
      </IonCol>
      <IonCol size="3.5" className="suggestions-placeholder-col">
        <SmallObjectPlaceholder />
      </IonCol>
      <IonCol size="3.5" className="suggestions-placeholder-col">
        <SmallServicePlaceholder />
      </IonCol>
      <IonCol size="1.5" className="suggestions-placeholder-col">
        <SmallObjectPlaceholder />
      </IonCol>
    </IonRow>
  );
};

const SuggestionListPlaceholder: React.FunctionComponent = () => {
  return (
    <IonGrid className="suggestion-grid">
      <div className="suggestion-thematic-title-placeholder" />
      <SuggestionListCollectionPlaceholder />
    </IonGrid>
  );
};

export default SuggestionListPlaceholder;
