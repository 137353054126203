import {
  IonContent,
  IonPage,
  IonHeader,
  IonText,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresherContent,
  IonRefresher,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonIcon,
  IonItem,
  SegmentCustomEvent,
} from '@ionic/react';
import React, { useEffect, useState, useRef } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import CallToPostModal from '../components/CallToPostModal';
import ChangeLocationModal from '../components/ChangeLocationModal';
import PostsPageTopbar from '../components/connected/PostsPageTopbar';
import PromotionalWelcomeModal from '../components/connected/PromotionalWelcomeModal';
import { PostsRowPlaceholder } from '../components/placeholders/PostsPlaceholder';
import PostsDisplay from '../components/PostsDisplay';
import { actions, RootState } from '../store';
import { getCurrentUser, getIsFullyLogged, getIsUserFirstLogin } from '../store/app/selectors';
import { getUserArea } from '../store/app/selectors';
import { GeolocationData } from '../store/layout/types';
import { getOfferAndNeedCollections, OfferAndNeedCollections } from '../store/posts/selectors';
import { Post, PostType, Location, Address, PostsPageFetchParams } from '../store/posts/types';
import { Area, CurrentUser } from '../store/users/types';
import { setStatusBarStyle } from '../utils/statusBarHelper';
import UpdateUserMissingDataModal, { getUserMissingDataModalProps, UpdateUserMissingDataModalProps } from '../components/UpdateUserMissingDataModal';
import { CollectionResponse } from '../utils/hydra';
import { locationIsPostTab } from '../utils/windowLocationHelper';
import { postListRadius } from '../store/posts/variables';
import { AppVersions, FF } from '../store/app/types';
import { apiEntrypoint } from '../environment';

import './PostsPage.scss';
import IndigoFermeBanner from '../IndigoFermeBanner';

export const partialPostsPageFetchParams = {
  location: undefined,
  loadNextPage: false,
  radius: undefined,
  extendCollection: false,
  excludePrevRadius: false,
};

interface PostTypesNumber {
  offer: number;
  need: number;
}

interface StateProps {
  currentUser: CurrentUser;
  postCollections: OfferAndNeedCollections;
  isFullyLogged: boolean;
  isUserFirstLogin: boolean;
  userArea?: Area;
  geolocationData: GeolocationData;
  numberOfTimeHomepageWasVisited: number;
  minAppVersions?: AppVersions;
  ff: FF;
}

const mapStateToProps = (state: RootState): StateProps => ({
  currentUser: getCurrentUser(state),
  postCollections: getOfferAndNeedCollections(state),
  isFullyLogged: getIsFullyLogged(state),
  isUserFirstLogin: getIsUserFirstLogin(state),
  userArea: getUserArea(state),
  geolocationData: state.layout.geolocationData,
  numberOfTimeHomepageWasVisited: state.app.numberOfTimeHomepageWasVisited,
  minAppVersions: state.app.minAppVersions,
  ff: state.app.ff,
});

interface DispatchProps {
  fetchCurrentUser: () => Promise<void>;
  fetchPosts: (params: PostsPageFetchParams) => Promise<CollectionResponse<Post>>;
  setIsUserFirstLoginAction: (isUserFirstLogin: boolean) => void;
  setUserArea: (area: Area | undefined) => void;
  deleteOneUserAreaHistory: (deleteOneUserAreaHistory: Address | undefined) => void;
  homepageWasVisited(): void;
  setToastMessage: (message: string) => void;
  setFFIndigoFermeBannerLastDate: (date: Date) => void;
}

const propsToDispatch = {
  fetchCurrentUser: actions.app.fetchLoggedUser,
  fetchPosts: actions.posts.fetchPostsForPostsPageLists,
  setIsUserFirstLoginAction: actions.app.setIsUserFirstLoginAction,
  setUserArea: actions.app.setUserAreaAction,
  deleteOneUserAreaHistory: actions.app.deleteOneUserAreaHistoryAction,
  homepageWasVisited: actions.app.homepageWasVisited,
  setToastMessage: actions.layout.setToastMessageAction,
  setFFIndigoFermeBannerLastDate: actions.app.setFFIndigoFermeBannerLastDate,
};

interface PostsPageProps {
  lastRefreshDate: Date | undefined; // Last time the user clicked on the home menu
}

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type Props = StateProps & DispatchProps & PostsPageProps & WithTranslation;

const PostsPage: React.FunctionComponent<Props> = (props: Props) => {
  const [indigoFermeBanner, setIndigoFermeBanner] = useState(false);
  const [updateUserMissingDataModal, setUpdateUserMissingDataModal] = useState<UpdateUserMissingDataModalProps>({});
  const [showCallToPostModal, setShowCallToPostModal] = useState<boolean>(false);
  const [selectedPostType, setSelectedPostType] = useState<PostType>('offer');
  const [pagesScrollPosition, setPagesScrollPosition] = useState<PostTypesNumber>({ offer: 0, need: 0 });
  const [fetchRadius, setFetchRadius] = useState<{ offer: number | undefined; need: number | undefined }>({ offer: 0, need: 0 });
  const [showChangeLocationModal, setShowChangeLocationModal] = useState<boolean>(false);
  const [itemsWithinMaxRadius, setItemsWithinMaxRadius] = useState<{ offer: number | undefined; need: number | undefined }>({ offer: undefined, need: undefined });
  const [collectionIsLoading, setCollectionIsLoading] = useState<boolean>(false); // This const is here to avoid having the 'no posts found' message with an empty post list between two fetch

  const ionInfiniteScrollRef = useRef<HTMLIonInfiniteScrollElement>(null);
  const ionRefresherRef = useRef<HTMLIonRefresherElement>(null);
  const ionContentRef = useRef<HTMLIonContentElement>(null);

  const postsCollection = props.postCollections[selectedPostType];

  const indigoFermeBannerFF = props.ff.indigo_ferme_banner;

  const onIndigoFermeBannerClose = (): void => {
    setIndigoFermeBanner(false);
    props.setFFIndigoFermeBannerLastDate(new Date());
  };

  useEffect(() => {
    if ((indigoFermeBannerFF.envs.includes('preprod') && apiEntrypoint.includes('preprod')) || (indigoFermeBannerFF.envs.includes('production') && !apiEntrypoint.includes('preprod'))) {
      const hoursDiff = Math.abs(new Date().getTime() - (indigoFermeBannerFF.lastDate ?? new Date().getTime())) / 36e5;

      if (hoursDiff >= 24 || !indigoFermeBannerFF.lastDate) {
        setIndigoFermeBanner(true);
      }
    }
  }, [indigoFermeBannerFF]);

  const handleScroll = (): void => {
    //if the max radius has been reached, and there is no nextPage, in this case we no longer fetch the posts
    if (fetchRadius[selectedPostType] === undefined && !postsCollection.nextPage) {
      return;
    }

    ionContentRef.current?.getScrollElement().then(value => {
      // If we are on the bottom of the list, sometimes the infiniteScroll event is not triggered
      // Using >= instead of === as on android, we can have 5900.33349609375 instead of 5900 for scrollTop when using `position: sticky`
      if (value.scrollTop + value.clientHeight >= value.scrollHeight && !postsCollection.isLoading) {
        // Avoid being stuck on the bottom of the list
        value.scrollTop -= 2;

        fetchPosts(true, false, !postsCollection.nextPage);
      }
    });
  };

  const completeInfiniteScroll = (): void => {
    // Complete infinite scroll and ion refresher when collection ended its loading
    ionInfiniteScrollRef.current?.complete();
    ionRefresherRef.current?.complete();
    setCollectionIsLoading(false);
  };

  const fetchPosts = async (loadNextPage = false, force = false, extendRadius = false): Promise<void> => {
    if (!force && postsCollection.isLoading) {
      return;
    }

    setCollectionIsLoading(true);
    const minPostNumber = 20; // Min posts number wanted in a radius to select it
    const currentUserLocation: Location | undefined = props.userArea?.location;

    const fetchPostsWithIncreasingRadius = (index: number): Promise<CollectionResponse<Post>> => {
      return props.fetchPosts({ ...partialPostsPageFetchParams, type: selectedPostType, location: currentUserLocation, radius: postListRadius[index] }).then((collection: CollectionResponse<Post>) => {
        // If less than the intended number of items to display, we increase the radius
        if (collection.totalItems < minPostNumber) {
          // If less than the intended number of items to display, and the max radius is reached, we fetch posts outside the max radius, ordered by distance
          if (index + 1 >= postListRadius.length) {
            setFetchRadius({ ...fetchRadius, [selectedPostType]: undefined });
            setItemsWithinMaxRadius({ ...itemsWithinMaxRadius, [selectedPostType]: collection.totalItems });
            return props.fetchPosts({ ...partialPostsPageFetchParams, type: selectedPostType, location: currentUserLocation, extendCollection: true });
          }

          return fetchPostsWithIncreasingRadius(index + 1);
        }
        setFetchRadius({ ...fetchRadius, [selectedPostType]: postListRadius[index] });
        return collection;
      });
    };

    let radius: number | undefined = fetchRadius[selectedPostType];

    try {
      // If the radius is 0, we fetch some posts while increasing the radius until we get a minimum amount of posts (currently 20)
      if (radius === 0 || force) {
        await fetchPostsWithIncreasingRadius(0).finally(() => completeInfiniteScroll());
        return;
      }

      // If we reach the last page of the search with a certain radius, we try to increase it
      if (extendRadius && radius !== undefined) {
        const radiusIndex: number = postListRadius.indexOf(radius || 0);
        radius = postListRadius[radiusIndex + 1];

        if (radius === undefined) {
          setItemsWithinMaxRadius({ ...itemsWithinMaxRadius, [selectedPostType]: Object.keys(postsCollection.items).length });
        }

        setFetchRadius({ ...fetchRadius, [selectedPostType]: radius });
      }

      await props.fetchPosts({ ...partialPostsPageFetchParams, type: selectedPostType, location: currentUserLocation, loadNextPage, radius, extendCollection: extendRadius, excludePrevRadius: true });
    } catch (e) {
      console.error(e);
    }

    completeInfiniteScroll();
  };

  const onTabWillChange = async (e: SegmentCustomEvent): Promise<void> => {
    if (!e.detail.value || e.detail.value === selectedPostType) {
      return;
    }

    const newPagesScrollPosition = pagesScrollPosition;

    try {
      const scrollEl = await ionContentRef.current?.getScrollElement();
      if (scrollEl) {
        newPagesScrollPosition[selectedPostType] = scrollEl.scrollTop;
        scrollEl.scrollTop = 0;
      }
    } catch (e) {
      // nothing
    }

    setSelectedPostType(e.detail.value as PostType);
    setPagesScrollPosition(newPagesScrollPosition);
  };

  const scrollToPreviousPosition = (): void => {
    if (!pagesScrollPosition[selectedPostType]) {
      return;
    }

    ionContentRef.current?.getScrollElement().then(scrollEl => {
      setTimeout(() => {
        // The DOM can be still loading, and the position can be wrong
        // TODO Find why if is there is no SuggestionList, the scrollTop works well, even without setTimeout
        scrollEl.scrollTop = pagesScrollPosition[selectedPostType];
      });
    });
  };

  const setUserArea = (address: Address | undefined, location: Location | undefined): void => {
    let userArea: Area | undefined = undefined;

    if (address && location && location?.latitude !== undefined && location?.longitude !== undefined) {
      userArea = { address, location };
    }
    props.setUserArea(userArea);
  };

  const changeUserAreaLocation = (address: Address | null, location: Location | undefined): void => {
    if (!address || !location) {
      props.setToastMessage('post.no-valid-address-selected');
      return;
    }
    setShowChangeLocationModal(false);

    setUserArea(address ?? undefined, location);
  };

  // We want this effect to be effectuated only once, so we pass [] as deps
  useEffect(() => {
    setStatusBarStyle();
    if (props.isFullyLogged) {
      props.fetchCurrentUser().catch(() => {
        // Prevent from disable account error
      });
    }
  }, []);

  // Reload post when user connect or disconnect
  useEffect(() => {
    const { currentUser, userArea, isFullyLogged } = props;

    if (!isFullyLogged) {
      return;
    }

    // Init the user address and location for posts page. If the user Area is undefined, it means it wasn't set yet
    if (!userArea) {
      const currentUserLocation: Location | undefined =
        currentUser?.addressLocation?.longitude !== null && currentUser?.addressLocation?.latitude !== null ? (currentUser.addressLocation as Location) : undefined;
      setUserArea(currentUser.address, currentUserLocation);
      return;
    }
  }, [props.isFullyLogged, selectedPostType, props.currentUser.email]);

  // Refresh posts when userArea changed
  useEffect(() => {
    setItemsWithinMaxRadius({ offer: undefined, need: undefined });
    fetchPosts(false, true);
  }, [props.userArea?.address, props.isFullyLogged, selectedPostType, props.currentUser.email]);

  // Scroll to previous position when post type changed
  useEffect(() => {
    scrollToPreviousPosition();
  }, [selectedPostType]);

  // If we click twice on the home button, we scroll to top
  useEffect(() => {
    if (!locationIsPostTab()) {
      return;
    }

    if (ionContentRef.current) {
      ionContentRef.current.scrollToTop(1000);
    }
  }, [props.lastRefreshDate]);

  // Display the CallToPost modal
  useEffect(() => {
    if (!props.isFullyLogged) {
      return;
    }

    // Do not show the CallToPostModal if the PrivacyModal/EditPasswordModal is displayed
    // isUserFirstLogin is set to true when the user finished to register
    // numberOfTimeHomepageWasVisited is incremented only if isUserFirstLogin is true
    // Once numberOfTimeHomepageWasVisited is equal to 2 we show the modal and this variable won't be incremented anymore since it doesn't have other purpose
    if (props.isUserFirstLogin && props.numberOfTimeHomepageWasVisited >= 1) {
      props.setIsUserFirstLoginAction(false);
      setShowCallToPostModal(true);
      return;
    }
    if (!props.isUserFirstLogin || props.numberOfTimeHomepageWasVisited >= 2) {
      return;
    }
    props.homepageWasVisited();
  }, [props.lastRefreshDate, props.isFullyLogged]);

  // Display the updateUserMissingDataModal modal
  useEffect(() => {
    if (!props.isFullyLogged || window.location.pathname !== '/posts') {
      return;
    }

    setUpdateUserMissingDataModal(getUserMissingDataModalProps(props.currentUser, { minAppVersions: props.minAppVersions }));
  }, [props.isFullyLogged, props.currentUser]);

  const itemsInMaxRadius = itemsWithinMaxRadius[selectedPostType];
  const maxRadiusCardIndex = itemsInMaxRadius !== undefined ? (itemsInMaxRadius > 0 ? itemsInMaxRadius - 1 : 0) : undefined;

  return (
    <IonPage className="posts-page" data-cy="posts-page">
      <IonHeader className="posts-page-header">
        <PostsPageTopbar />

        {indigoFermeBanner && indigoFermeBannerFF.data && <IndigoFermeBanner data={indigoFermeBannerFF.data} onClose={onIndigoFermeBannerClose} />}

        <IonSegment onIonChange={onTabWillChange} mode="md" color="dark" className="post-type-tab-bar" value={selectedPostType}>
          <IonSegmentButton className="post-type-tab-button" value="offer" mode="md">
            <IonLabel>
              <Trans i18nKey="post.type-offers" />
            </IonLabel>
          </IonSegmentButton>
          <IonSegmentButton className="post-type-tab-button" value="need" mode="md">
            <IonLabel>
              <Trans i18nKey="post.type-needs" />
            </IonLabel>
          </IonSegmentButton>
        </IonSegment>
      </IonHeader>

      <IonContent className="posts-page-content" ref={ionContentRef} onIonScrollEnd={handleScroll} scrollEvents={true}>
        <IonRefresher ref={ionRefresherRef} onIonRefresh={() => fetchPosts(false, true)} slot="fixed">
          <IonRefresherContent />
        </IonRefresher>

        {!props.userArea && (
          <div className="posts-page-content-posts-list">
            <div className="posts-list-header">
              <h2 className="posts-list-title">
                <Trans i18nKey="post.in-your-area" />
              </h2>
              <div className="user-area-banner-wrapper">
                <IonItem data-cy="user-area-edit-banner" className="user-area-banner cursor-pointer" lines="none" onClick={() => setShowChangeLocationModal(true)} color="primary">
                  <IonIcon slot="start" icon="/assets/icon/icon-pin.svg" />
                  <IonText data-cy="common-header-title">
                    <Trans i18nKey="post.complete-your-address" />
                  </IonText>
                  <IonIcon slot="end" icon="/assets/icon/icon-arrow-forward.svg" />
                </IonItem>
              </div>
            </div>
          </div>
        )}

        <div className="posts-page-content-posts-list" data-cy="recent-posts-list">
          <div className="posts-list-header">
            {!props.userArea && (
              <>
                <h2 className="posts-list-title">
                  <Trans i18nKey={`post.recent-${selectedPostType}`} />
                </h2>
                <IonText className="posts-list-subtitle">
                  <Trans i18nKey={`post.all-fresh-posts`} />
                </IonText>
              </>
            )}

            {props.userArea && (
              <>
                <h2 className="posts-list-title">
                  <Trans i18nKey="post.in-your-area" />
                </h2>
                <div className="posts-list-subtitle link-subtitle" onClick={() => setShowChangeLocationModal(true)}>
                  <IonText color="primary">{props.userArea.address?.formatted}</IonText>
                  <IonIcon icon="/assets/navigation/chevron-forward.svg" />
                </div>
              </>
            )}
          </div>
          <PostsDisplay
            posts={postsCollection}
            userPosition={props.userArea?.location}
            showSocialCards={true}
            maxRadiusCardIndex={props.userArea ? maxRadiusCardIndex : undefined}
            showSuggestions={selectedPostType === 'offer'}
            isLoading={collectionIsLoading || postsCollection.isLoading}
          />
        </div>

        <ChangeLocationModal
          isOpen={showChangeLocationModal}
          closeModal={() => setShowChangeLocationModal(false)}
          onLocationValidation={changeUserAreaLocation}
          initialLocation={{
            address: props.userArea?.address ?? null,
            location: props.userArea?.location,
            locationHistory: props.userArea?.locationHistory,
          }}
          deleteLocationHistory={props.deleteOneUserAreaHistory}
          changeRadius={false}
        />

        {/* Post cards have a fixed height of 335px. With 600px as threshold loading of the next posts will start before reaching the last posts row */}
        <IonInfiniteScroll ref={ionInfiniteScrollRef} threshold="600px" onIonInfinite={() => fetchPosts(true)} disabled={!postsCollection?.nextPage}>
          <IonInfiniteScrollContent loading-spinner="circle" loading-text={props.t('common.loading')}>
            <PostsRowPlaceholder />
          </IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>

      <UpdateUserMissingDataModal {...updateUserMissingDataModal} />
      {!updateUserMissingDataModal?.modalType && props.userArea && <PromotionalWelcomeModal />}
      {!updateUserMissingDataModal?.modalType && <CallToPostModal isOpen={showCallToPostModal} closeModal={() => setShowCallToPostModal(false)} />}
    </IonPage>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(PostsPage));
