import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';

export function setStatusBarStyle(isLightMode = true): void {
  if (Capacitor.getPlatform() !== 'ios') {
    return;
  }

  StatusBar.getInfo().then(infos => {
    if ((infos.style === Style.Light && isLightMode) || (infos.style === Style.Dark && !isLightMode)) {
      return;
    }

    StatusBar.setStyle({
      style: isLightMode ? Style.Light : Style.Dark,
    }).catch(() => {
      // Do nothing
    });
  });
}

export function setStatusBarOverlaysWebView(overlay = true): void {
  if (Capacitor.getPlatform() !== 'ios') {
    return;
  }
  StatusBar.getInfo().then(infos => {
    if (infos.overlays === overlay) {
      return;
    }

    StatusBar.setOverlaysWebView({
      overlay,
    }).catch(() => {
      // Do nothing
    });
  });
}
