import React, { Component, ReactNode } from 'react';
import { IonAvatar, IonImg } from '@ionic/react';
import { FacebookUserPictureData, getFacebookUserPicture } from '../utils/facebookAccess';

interface ListProps {
  facebookId: string | null;
}

interface State {
  imgUrl: string;
}

type Props = ListProps;

class FacebookUserPicture extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      imgUrl: 'https://graph.facebook.com/empty/picture?redirect=false&type=large',
    };
  }

  componentDidMount(): void {
    this.loadFacebookUserPicture(this.props.facebookId);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.facebookId !== this.props.facebookId) {
      this.loadFacebookUserPicture(this.props.facebookId);
    }
  }

  public render(): ReactNode {
    return (
      <IonAvatar class="facebookUserPicture">
        <IonImg src={this.state.imgUrl} />
      </IonAvatar>
    );
  }

  loadFacebookUserPicture = async (facebookId: string | null): Promise<void> => {
    try {
      const pictureData: FacebookUserPictureData = await getFacebookUserPicture(facebookId || 'empty');
      this.setState({ imgUrl: pictureData.url });
    } catch (e) {
      return e;
    }
  };
}

export default FacebookUserPicture;
