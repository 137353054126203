import React from 'react';
import { IonList, IonListHeader, IonText } from '@ionic/react';
import { Trans } from 'react-i18next';
import { FullPost, Location } from '../store/posts/types';
import { ItemsCollection } from '../utils/hydra';
import PostsGrid from './PostsGrid';
import HomePagePlaceholder from './placeholders/HomePagePlaceholder';

interface ListProps {
  posts: ItemsCollection<FullPost>;
  showSuggestions: boolean;
  showSocialCards: boolean;
  maxRadiusCardIndex?: number;
  userPosition?: Location;
  isLoading: boolean;
}

type Props = ListProps;

const minPostsToDisplay = 12;

const PostsDisplay: React.FunctionComponent<Props> = ({ posts, userPosition, showSuggestions, showSocialCards, maxRadiusCardIndex, isLoading }: Props) => {
  // If the collection is loading and there is not enough posts to display, we show the placeholder
  if ((isLoading && Object.keys(posts.items).length < minPostsToDisplay) || !posts.lastFetchDate) {
    return <HomePagePlaceholder />;
  }

  if (Object.keys(posts.items).length === 0) {
    return (
      <IonList>
        <IonListHeader className="no-padding">
          <IonText class="full-width text-center">
            <Trans i18nKey="post.no-posts-found" />
          </IonText>
        </IonListHeader>
      </IonList>
    );
  }

  return <PostsGrid posts={posts} isLoading={isLoading} showSuggestions={showSuggestions} showSocialCards={showSocialCards} maxRadiusCardIndex={maxRadiusCardIndex} userPosition={userPosition} />;
};

export default React.memo(PostsDisplay);
