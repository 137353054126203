import React, { Component, ReactNode } from 'react';
import { IonButton, IonCol, IonIcon, IonRow } from '@ionic/react';
import { facebookLogout, FacebookUserInfos, getCurrentFacebookToken, getFacebookToken, getFacebookUserIdAndFullname, isFacebookEnabled } from '../utils/facebookAccess';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import FacebookUserPicture from './FacebookUserPicture';
import { logoFacebook } from 'ionicons/icons';

interface State {
  fbToken: string | null;
  fbData: FacebookUserInfos | null;
}

interface FacebookLoginProps {
  onLogin: (accessToken: string, facebookData: FacebookUserInfos) => Promise<void>;
  onLogout?: () => void;
  onError: (message: string | null) => void;
  loginButtonLabel?: string;
  hideContinueAsButton?: boolean;
  autoLoginAction?: boolean;
  disabled?: boolean;
}

type Props = FacebookLoginProps & WithTranslation;

class FacebookLogin extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      fbToken: null,
      fbData: null,
    };
  }

  public componentDidMount(): void {
    if (!isFacebookEnabled()) {
      return;
    }

    if (this.props.autoLoginAction) {
      return;
    }
    getCurrentFacebookToken()
      .then(token => this.setState({ fbToken: token }))
      .catch(() => {
        // Nothing
      });
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (null === this.state.fbToken) {
      return;
    }

    if (this.state.fbToken !== prevState.fbToken || null === this.state.fbData) {
      this.doGetUsername();
    }
  }

  public render(): ReactNode {
    if (null === this.state.fbToken || this.props.autoLoginAction) {
      return (
        <IonButton disabled={!!this.props.disabled} shape="round" onClick={this.handleFacebookLogin} color="facebook" size="large" expand="block">
          <IonIcon size="small" slot="start" icon={logoFacebook} className="signin-icon" />
          {this.getLoginButtonLabel()}
        </IonButton>
      );
    }

    return this.getFacebookButtons();
  }

  handleFacebookLogin = (): void => {
    getFacebookToken()
      .then(token => this.setState({ fbToken: token }))
      .catch(this.handleError);
  };

  handleFacebookLogout = (): void => {
    facebookLogout().then(() => {
      this.resetState();
      if (undefined !== this.props.onLogout) this.props.onLogout();
    });
  };

  handleError = (e: string | Error): void => {
    if (!this.props.onError || !e) {
      return;
    }

    if (typeof e === 'string') {
      return this.props.onError(e);
    }

    this.resetState();
    return this.props.onError(e.message);
  };

  private resetState(): void {
    this.setState({ fbToken: null, fbData: null });
  }

  private getFacebookButtons(): ReactNode {
    if (this.state.fbData === null || this.state.fbToken === null) {
      return <></>;
    }
    return (
      <>
        <IonRow responsive-sm align-items="center">
          <IonCol size="auto">
            <FacebookUserPicture facebookId={this.state.fbData.id} />
          </IonCol>
          {!this.props.hideContinueAsButton && (
            <IonCol size="auto">
              <IonButton
                className="button-large"
                disabled={!!this.props.disabled}
                onClick={() => this.props.onLogin(this.state.fbToken as string, this.state.fbData as FacebookUserInfos)}
                color="primary"
              >
                <IonIcon size="small" slot="start" icon={logoFacebook} className="signin-icon" />
                <Trans i18nKey="user.facebook-button-continue-as" values={{ name: this.state.fbData.name }} />
              </IonButton>
            </IonCol>
          )}
          <IonCol size="auto">
            <IonButton className="button-large" onClick={this.handleFacebookLogout} shape="round" color="secondary">
              <IonIcon size="small" slot="start" icon={logoFacebook} className="signin-icon" />
              <Trans i18nKey="user.facebook-button-logout" />
            </IonButton>
          </IonCol>
        </IonRow>
      </>
    );
  }

  private getLoginButtonLabel(): string {
    return this.props.loginButtonLabel || this.props.t('user.facebook-button-login');
  }

  private async doGetUsername(): Promise<void> {
    if (!this.state.fbToken) {
      return;
    }
    let fbData;

    try {
      fbData = await getFacebookUserIdAndFullname(this.state.fbToken);
    } catch (e) {
      this.handleError(e);
    }

    if (!fbData) {
      return;
    }
    this.setState({ fbData });

    if (this.props.autoLoginAction && fbData) {
      this.props.onLogin(this.state.fbToken as string, fbData);
    }
  }
}

export default withTranslation()(FacebookLogin);
