import { TabHistory } from './types';

export function getPreviousTabHistory(tabHistory: TabHistory[]): TabHistory[] {
  if (tabHistory.length < 2) {
    return [];
  }
  let history = tabHistory[0];
  let previousHistory = tabHistory[1];
  let previousTabHistory = tabHistory.slice(1);
  // Prevent same history to be stored, when coming from another tab.
  while (history.pathname === previousHistory.pathname) {
    if (previousTabHistory.length < 2) {
      return previousTabHistory;
    }
    history = previousTabHistory[0];
    previousHistory = previousTabHistory[1];
    previousTabHistory = previousTabHistory.slice(1);
  }

  return previousTabHistory;
}
