// TODO rename file to 'selectors' and fix dependencies when all selectors will be modified

import { RootState } from '../index';
import { CurrentUser, User } from '../users/types';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';
import { CollectionResponse } from '../../utils/hydra';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const getWhoAmIUserFromState = (state: RootState): CurrentUser | null => state.whoAmI.whoAmIUser ?? null;
const getManagedUsersFromState = (state: RootState): CollectionResponse<User> | undefined => state.whoAmI.managedUsers;

export const getWhoAmIUser = createDeepEqualSelector(getWhoAmIUserFromState, (currentUser: CurrentUser | null) => currentUser);
export const getManagedUsers = createDeepEqualSelector(getManagedUsersFromState, (users: CollectionResponse<User> | undefined) => users);
