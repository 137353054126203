import isEqual from 'lodash/isEqual';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import { RootState } from '../index';
import { ManagedUserInfos } from './types';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const getManagedUserInfosFromState = (state: RootState): ManagedUserInfos | undefined => state.layout.managedUserInfos;

export const getManagedUserInfos = createDeepEqualSelector(getManagedUserInfosFromState, (managedUserInfos: ManagedUserInfos | undefined) => managedUserInfos);
