import { IonAlert } from '@ionic/react';
import localforage from 'localforage';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { apiEntrypoint, defaultApiEntrypoint, overrideApiEntrypoint } from '../environment';

interface ApiSwitcherAlertProps {
  showAlert: boolean;
  closeAlert: () => void;
}

type Props = ApiSwitcherAlertProps;

export const useLongPress = (callback: () => void, ms = 500): Record<string, unknown> => {
  const [startLongPress, setStartLongPressFoo] = useState(false);
  const itemRef = useRef();

  const setStartLongPress = (e: Event, value: boolean): void => {
    e.preventDefault();
    e.stopPropagation();
    setStartLongPressFoo(value);
  };

  useLayoutEffect(() => {
    if (itemRef.current === undefined) {
      return;
    }

    const attachEvent = (ref: HTMLElement, eventName: keyof HTMLElementEventMap, value: boolean): void => {
      // We cannot use onTouchStart on the react element because of https://github.com/facebook/react/issues/9809
      ref.addEventListener(eventName, e => setStartLongPress(e, value), { passive: false });
    };

    const item: HTMLElement = itemRef.current as unknown as HTMLElement;
    attachEvent(item, 'mousedown', true);
    attachEvent(item, 'mouseup', false);
    attachEvent(item, 'mouseleave', false);
    attachEvent(item, 'touchstart', true);
    attachEvent(item, 'touchend', false);
  }, [itemRef]);

  useEffect(() => {
    let timerId: NodeJS.Timeout;

    if (startLongPress) {
      timerId = setTimeout(() => {
        setStartLongPressFoo(false);
        callback();
      }, ms);
    }

    return () => {
      if (timerId !== undefined) {
        clearTimeout(timerId);
      }
    };
  }, [callback, ms, startLongPress]);

  return {
    ref: itemRef,
  };
};

export const ApiSwitcherAlert: React.FunctionComponent<Props> = ({ showAlert, closeAlert }: Props) => {
  const defaultValue = apiEntrypoint === defaultApiEntrypoint ? '' : apiEntrypoint;

  return (
    <IonAlert
      isOpen={showAlert}
      onDidDismiss={closeAlert}
      backdropDismiss={false}
      header="API URL switcher"
      message="Type the server name, e.g.: 123, api-123, preprod, prod, or any URL"
      inputs={[
        {
          name: 'url',
          type: 'url',
          value: defaultValue,
          placeholder: '123, api-123, preprod, prod',
        },
      ]}
      buttons={[
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Empty field',
          cssClass: 'secondary',
          handler: () => {
            overrideApiEntrypoint(null);
            localforage.clear().then(() => {
              window.location.replace('/homepage');
            });
          },
        },
        {
          text: 'Ok',
          handler: data => {
            overrideApiEntrypoint(data.url);
            localforage.clear().then(() => {
              window.location.replace('/homepage');
            });
          },
        },
      ]}
    />
  );
};
