import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { analyticsEnabled, firebaseConfig } from '../environment';
import { Capacitor } from '@capacitor/core';
import { CurrentUser } from '../store/users/types';
import { extractId } from '../utils/helpers';

export const initFirebase = async (): Promise<void> => {
  if (!analyticsEnabled || 'web' !== Capacitor.getPlatform()) {
    return;
  }
  return FirebaseAnalytics.initializeFirebase(firebaseConfig);
};

export const setFirebaseUserId = async (userId: string): Promise<void> => {
  if (!analyticsEnabled) {
    return;
  }
  return FirebaseAnalytics.setUserId({ userId });
};

export const setFirebaseUserProperty = async (name: string, value: string): Promise<void> => {
  if (!analyticsEnabled) {
    return;
  }
  return FirebaseAnalytics.setUserProperty({ name, value });
};

export const logFirebaseEvent = async (name: string, params: Record<string, unknown> = {}): Promise<void> => {
  if (!analyticsEnabled) {
    return;
  }
  return FirebaseAnalytics.logEvent({ name, params });
};

export async function setAnalyticsUser(user: CurrentUser): Promise<void> {
  if (!analyticsEnabled) {
    return;
  }
  await Promise.allSettled([
    setFirebaseUserId(extractId(user)),
    setFirebaseUserProperty('gender', user?.gender ?? 'unknown'),
    setFirebaseUserProperty('user_type', user?.facebookId ? 'facebook' : user?.appleId ? 'apple' : 'standard'),
    setFirebaseUserProperty('created_at', user?.createdAt ?? 'unknown'),
    setFirebaseUserProperty('city', user?.address?.locality ?? 'unknown'),
    setFirebaseUserProperty('country', user?.address?.country ?? 'unknown'),
    setFirebaseUserProperty('managed', user.managed ? 'true' : 'false'),
  ]);
}
