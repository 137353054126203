import { IonButton, IonIcon, IonContent, IonModal, IonHeader, IonSearchbar, IonLabel, IonRange, IonChip, IonSpinner } from '@ionic/react';
import { isEqual } from 'lodash';
import React, { ChangeEvent, Component, ReactNode } from 'react';
import { Trans } from 'react-i18next';
import i18next from 'i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { actions, RootState } from '../store';
import { AskForGeolocationParameters, GeolocationData } from '../store/layout/types';
import { GeocoderResult, getAddressValuesFromGoogleResults, getGeocoderResultFromDevicePosition, PlaceResult, waitForGoogleMaps } from '../utils/geolocationHelpers';
import { Address, Location } from '../store/posts/types';
import ActivateLocation from './ActivateLocation';
import { LocationHistory } from '../store/users/types';
import './ChangeLocationModal.scss';
import { getPromisedValueWithRetry } from '../utils/helpers';

interface StateProps {
  geolocationData: GeolocationData;
}

const mapStateToProps = (state: RootState): StateProps => ({
  geolocationData: state.layout.geolocationData,
});

interface DispatchProps {
  askForGeolocationDataAction: (askForGeolocationParameters?: AskForGeolocationParameters) => void;
  setToastMessage(message: string | null): void;
}

const propsToDispatch = {
  askForGeolocationDataAction: actions.layout.askForGeolocationDataAction,
  setToastMessage: actions.layout.setToastMessageAction,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

interface LocationProps {
  address: Address | null;
  location?: Location;
  locationHistory?: LocationHistory[];
  radius?: number;
}

interface ModalProps {
  closeModal: () => void;
  onLocationValidation: (address: Address | null, location: Location | undefined, radius?: number) => void;
  deleteLocationHistory?: (address: Address | undefined) => void;
  isOpen: boolean;
  initialLocation?: LocationProps;
  changeRadius: boolean;
}

export interface State extends LocationProps {
  geolocationInProgress: boolean;
  geolocationWasRequested: boolean;
  googleMapsLoaded: boolean;
  hideActivateLocationComponent: boolean;
}

type Props = ModalProps & StateProps & DispatchProps;

class ChangeLocationModal extends Component<Props, State> {
  private autocomplete?: google.maps.places.Autocomplete;
  private autocompleteListener?: google.maps.MapsEventListener;
  private autocompleteInputRefItem: HTMLIonSearchbarElement | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      googleMapsLoaded: false,
      geolocationInProgress: false,
      geolocationWasRequested: false,
      hideActivateLocationComponent: false,
      ...this.getLocationParams(),
    };
  }

  private getLocationParams(): LocationProps {
    const { initialLocation } = this.props;

    return {
      location: initialLocation?.location,
      locationHistory: initialLocation?.locationHistory,
      // Radius in meters for API
      radius: initialLocation?.radius ? initialLocation?.radius / 1000 : 5,
      address: initialLocation?.address || null,
    };
  }

  public async componentDidMount(): Promise<void> {
    try {
      await waitForGoogleMaps();
      this.setState({ googleMapsLoaded: true });
    } catch (e) {
      this.props.setToastMessage('Unable to load the autocomplete script: ' + (e?.message || e));
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    const { isOpen } = this.props;
    if (!prevProps.geolocationData?.devicePosition && this.props.geolocationData.devicePosition && this.state.geolocationInProgress) {
      this.getAddressesFromDevicePosition();
    }

    // Update the current location values with the locationParams when the modal is re-opened
    if (!prevProps.isOpen && isOpen) {
      this.setState(this.getLocationParams());
    }

    if (!isEqual(prevState.location, this.state.location)) {
      this.setState({ hideActivateLocationComponent: true });
    }
  }

  public componentWillUnmount(): void {
    this.autocompleteListener?.remove();
    delete this.autocompleteListener;
    this.autocomplete?.unbindAll();
  }

  setAutocompleteInputRef = async (element: HTMLIonSearchbarElement | null): Promise<void> => {
    if (element === null || element === this.autocompleteInputRefItem) {
      return;
    }

    await waitForGoogleMaps();

    this.autocompleteInputRefItem = element;

    if (this.autocompleteListener) {
      this.autocompleteListener.remove();
      delete this.autocompleteListener;
    }

    delete this.autocomplete;

    // Using getPromisedValueWithRetry as the input element is not always available right now
    try {
      const inputEl = await getPromisedValueWithRetry<HTMLInputElement>(element.getInputElement.bind(element));

      this.autocomplete = new google.maps.places.Autocomplete(inputEl);
      this.autocompleteListener = await this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
    } catch (e) {
      console.error('Unable to get the autocomplete input element: ' + (e.message || e));
      return;
    }
  };

  private handlePlaceChanged = (): void => {
    if (!this.autocomplete) {
      return;
    }
    const place: PlaceResult = this.autocomplete.getPlace();
    this.handleAddressSelection(place);
  };

  private async handleAddressSelection(addressObject: PlaceResult | GeocoderResult): Promise<void> {
    const value = await getAddressValuesFromGoogleResults(addressObject);

    this.setState({ location: value.location, address: value.address });
  }

  private handleAddressHistorySelection(locationHistory: LocationHistory): void {
    if (!locationHistory.address || !locationHistory.location) {
      return;
    }
    this.setState({ location: locationHistory.location, address: locationHistory.address }, () => this.validateLocation());
  }

  private deleteLocationHistory(locationHistory: LocationHistory): void {
    if (!locationHistory.address || !locationHistory.location || !this.props.deleteLocationHistory) {
      return;
    }
    this.props.deleteLocationHistory(locationHistory.address);
  }

  private tryToGetAddressesFromDevicePosition = async (): Promise<void> => {
    this.setState({ hideActivateLocationComponent: false });
    this.props.askForGeolocationDataAction({ forceRefreshPosition: true, askPermissions: true });
    this.getAddressesFromDevicePosition();
  };

  private getAddressesFromDevicePosition = async (): Promise<void> => {
    this.setState({ geolocationInProgress: true, geolocationWasRequested: true });

    if (!this.props.geolocationData?.devicePosition) {
      return;
    }

    try {
      const geocoderResult = await getGeocoderResultFromDevicePosition(this.props.geolocationData?.devicePosition);
      const firstResult = await getAddressValuesFromGoogleResults(geocoderResult[0]);
      this.setState({ geolocationInProgress: false, location: firstResult.location, address: firstResult.address });
    } catch (e) {
      this.setState({ geolocationInProgress: false });
    }
  };

  public render(): ReactNode {
    const { initialLocation } = this.props;
    return (
      <IonModal data-cy="change-location-modal" isOpen={this.props.isOpen} onDidDismiss={this.closeModal} className="safe-area-ios">
        <IonHeader>
          <IonButton onClick={this.closeModal} fill="clear" className="header-back-button">
            <IonIcon icon="/assets/navigation/close.svg" color="dark" />
          </IonButton>
          <h1 className="header-title">
            <Trans i18nKey="search.where-are-you-looking" />
          </h1>
        </IonHeader>
        <IonContent className="location-modal">
          <IonSearchbar
            searchIcon="/assets/form/search.svg"
            ref={this.setAutocompleteInputRef}
            disabled={!this.state.googleMapsLoaded}
            value={this.state.address?.formatted}
            onIonClear={this.resetLocation}
            className="search-bar"
            placeholder={i18next.t('search.search-location')}
          />
          <input hidden name={'changeLocationInputHidden'} onChange={this.handleHiddenInputChanged} />
          {this.state.location && this.props.isOpen && (
            <div className="location-setup">
              <IonChip outline className="ion-no-margin text-overflow-chip">
                <div className="text-overflow-chip-content">
                  <IonIcon className="ion-no-margin" size="small" icon="/assets/form/close-circle-milkygray.svg" onClick={this.resetLocation} />
                  <IonLabel>{this.state.address?.formatted || i18next.t('search.location')}</IonLabel>
                </div>
              </IonChip>

              {this.props.changeRadius && (
                <>
                  <div className="radius-text">
                    <IonLabel>
                      <Trans i18nKey="search.radius-around" />
                    </IonLabel>
                    <IonLabel className="radius-text-value" color="primary">
                      {this.state.radius} km
                    </IonLabel>
                  </div>
                  <IonRange className="radius-range-value" min={1} max={100} step={1} onIonChange={this.radiusChanged} value={this.state.radius} />
                  <div className="radius-indications">
                    <IonLabel>1 km</IonLabel>
                    <IonLabel className="margin-left-auto">100 km</IonLabel>
                  </div>
                </>
              )}
              <hr />
            </div>
          )}

          {/* TODO If the user refused to share his position, ask position permission again when user click on button Around me */}
          <IonButton fill="clear" color="black" onClick={() => this.tryToGetAddressesFromDevicePosition()} disabled={!this.state.googleMapsLoaded}>
            <IonIcon size="small" icon="/assets/form/locate.svg" color="black" mode="md" />
            <IonLabel color="black" className="around-me-text">
              <Trans i18nKey="search.around-me" />
              {this.state.geolocationInProgress &&
                this.props.geolocationData.geolocationPermission === 'granted' &&
                !this.props.geolocationData.geolocationErrorMessage?.includes('location unavailable') && <IonSpinner name="lines-small" />}
            </IonLabel>
          </IonButton>

          {this.state.geolocationWasRequested &&
            !this.state.hideActivateLocationComponent &&
            ((this.props.geolocationData.geolocationPermission !== 'granted' && !this.props.geolocationData.devicePosition) || !!this.props.geolocationData.geolocationErrorMessage) && (
              <ActivateLocation refreshAction={this.getAddressesFromDevicePosition} />
            )}

          {initialLocation?.locationHistory && initialLocation.locationHistory.length !== 0 && (
            <div className="location-history-container">
              <h3 className="title">
                <Trans i18nKey="search.previous-addresses" />
              </h3>
              {initialLocation?.locationHistory?.map(locationHistory => (
                <div className="location-history" key={locationHistory.address?.formatted}>
                  <IonIcon icon="/assets/icon/circle-arrow.svg" className="circle-arrow" />
                  <div className="text" data-cy="location-history" onClick={() => this.handleAddressHistorySelection(locationHistory)}>
                    <span>{locationHistory.address?.formatted}</span>
                  </div>
                  <IonButton data-cy="delete-location-history" onClick={() => this.deleteLocationHistory(locationHistory)} fill="clear" size="small" className="close-button">
                    <IonIcon icon="/assets/navigation/close.svg" color="dark" />
                  </IonButton>
                </div>
              ))}
            </div>
          )}

          <IonButton className="button-large validation-button" color="primary" shape="round" onClick={this.validateLocation} disabled={!this.state.address}>
            <Trans i18nKey="search.validate-location" />
          </IonButton>
        </IonContent>
      </IonModal>
    );
  }

  private handleHiddenInputChanged = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!event) {
      return;
    }
    const values = JSON.parse(event.target.value);
    this.setState({ location: values.location, address: values.address });
  };

  private validateLocation = (): void => {
    // Radius in meters for API
    const radius = (this.state.radius || 0) * 1000;
    this.props.onLocationValidation(this.state.address, this.state.location ?? undefined, radius);
    this.closeModal();
  };

  private closeModal = (): void => {
    this.props.closeModal();
    this.autocomplete = undefined;
  };

  private radiusChanged = (event: CustomEvent): void => {
    this.setState({ radius: event.detail.value });
  };

  private resetLocation = (): void => {
    this.setState({ radius: 5, address: null, location: undefined });
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangeLocationModal);
