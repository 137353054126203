import { bboxes, encode, GeographicPoint, neighbors } from 'ngeohash';
import { MapSearchParams } from '../store/posts/actions';
import { roundWithPrecision, roundWithGeohash } from './geolocationHelpers';

export const getGeohashPrecision = (zoom: number): number => {
  if (zoom >= 14) {
    return 6;
  } else if (zoom >= 12) {
    return 5;
  } else if (zoom >= 9) {
    return 4;
  } else if (zoom >= 6) {
    return 3;
  } else if (zoom >= 4) {
    return 2;
  }

  return 1;
};

// We round the search params in order to mutualize the varnish cache for all users
export const roundSearch = (params: MapSearchParams): MapSearchParams => {
  const coords = {
    latitude: params.lat,
    longitude: params.lng,
  };
  const roundedCoords = roundWithGeohash(coords as GeographicPoint, getGeohashPrecision(params.zoom));

  return {
    lat: roundedCoords.latitude,
    lng: roundedCoords.longitude,
    radius: roundWithPrecision(params.radius, Math.pow(10, params.radius.toFixed().length - 1)), // 100, 1000, 10000
    zoom: params.zoom,
  };
};

export const gpsDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  const p = 0.017453292519943295; // Math.PI / 180
  const c = Math.cos;
  const a = 0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;

  return 12742 * Math.asin(Math.sqrt(a)) * 1000; // 2 * R; R = 6371 km
};

export const gpsMapsDistance = (pos1: google.maps.LatLng, pos2: google.maps.LatLng): number => {
  return gpsDistance(pos1.lat(), pos1.lng(), pos2.lat(), pos2.lng());
};

export const mapCollectionName = (params: MapSearchParams): string => {
  params = roundSearch(params);

  return `${params.lat},${params.lng},${params.radius}`;
};

export const getDisplayedGeohashes = (search: MapSearchParams): string[] => {
  const center = new google.maps.LatLng(search.lat, search.lng);
  const { radius, zoom } = search;

  const north = google.maps.geometry.spherical.computeOffset(center, radius, 0).lat();
  const east = google.maps.geometry.spherical.computeOffset(center, radius, 90).lng();
  const south = google.maps.geometry.spherical.computeOffset(center, radius, 180).lat();
  const west = google.maps.geometry.spherical.computeOffset(center, radius, -90).lng();

  /*  bboxes not work with too large radius, use neighbors */
  const geohashes = bboxes(south, west, north, east, getGeohashPrecision(zoom));
  if (geohashes.length) {
    return geohashes;
  }

  const centerGeoHash = encode(search.lat, search.lng);
  return neighbors(centerGeoHash).concat([centerGeoHash]);
};
