import React from 'react';
import { IonContent, IonModal, IonText, IonButton, IonImg } from '@ionic/react';
import { Trans } from 'react-i18next';
import './CallToPostModal.scss';

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const CallToPostModal: React.FunctionComponent<ModalProps> = (props: ModalProps) => {
  return (
    <IonModal isOpen={props.isOpen} onDidDismiss={props.closeModal} className="call-to-post-modal safe-area-ios" data-cy="call-to-post-modal">
      <IonContent>
        <IonImg src="/assets/illustrations/call-to-offer.svg" className="illustration-image" />
        <h1>
          <Trans i18nKey="home.call-to-post-title" />
        </h1>
        <IonButton className="close-button" data-cy="call-to-post-close-button" onClick={props.closeModal} fill="clear">
          <IonImg src="/assets/icon/icon-close-modal.svg" />
        </IonButton>
        <IonText className="text-bold display-block">
          <Trans i18nKey="home.call-to-post-subtitle" />
          <div className="call-to-post-description">
            <Trans i18nKey="home.call-to-post-description" />
          </div>
        </IonText>
      </IonContent>
    </IonModal>
  );
};

export default CallToPostModal;
