export function modalsExistInDOM(): boolean {
  return document.getElementsByTagName('ion-modal').length > 0;
}

export function closeModals(): Promise<boolean[]> {
  const modals = Array.from(document.getElementsByTagName('ion-modal'));
  const promises: Promise<boolean>[] = [];

  modals.forEach((el: HTMLIonModalElement) => {
    promises.push(el.dismiss());
  });

  return Promise.all(promises);
}
