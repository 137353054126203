import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { IonCard, IonCardContent, IonCardHeader, IonFooter, IonLabel } from '@ionic/react';
import { RootState } from '../store';
import { FullPost, Location } from '../store/posts/types';
import { Media } from '../store/app/types';
import { extractId, formattedDistanceBetweenCoords, titleTagForPostDate } from '../utils/helpers';
import { formatDateFromNow, getBoroughIfExist } from '../utils/helpers';
import RoutingItem from './common/RoutingItem';
import UserItem from './UserItem';
import ResponsiveTitle from './common/ResponsiveTitle';
import ImageFadeIn from './ImageFadeIn';
import { isCurrentUserFunc } from '../store/app/selectors';
import PostStateChip from './chip/PostStateChip';
import './PostCard.scss';

interface PostCardProps {
  post: FullPost;
  isCurrentUser?: isCurrentUserFunc;
  locationData?: Location;
  hideBookedChip?: boolean;
  hideCompletedChip?: boolean;
}

interface PostCardFooterProps {
  post: FullPost;
  locationData?: Location;
  hideBookedChip?: boolean;
  hideCompletedChip?: boolean;
}

type PostCardFooterInternalProps = PostCardFooterProps & WithTranslation;

export const getChipComponent = (post: FullPost): ReactNode | undefined => {
  const color = post.type === 'need' ? 'danger' : 'primary';
  const translationKey = post.type === 'need' ? 'post.requests' : 'post.offers-for-free';

  return (
    <IonLabel className="offer-label" color={color}>
      <Trans i18nKey={translationKey} />
    </IonLabel>
  );
};

const PostCardFooterInternal: React.FunctionComponent<PostCardFooterInternalProps> = ({ i18n, post, locationData, hideBookedChip, hideCompletedChip }: PostCardFooterInternalProps) => {
  return (
    <IonFooter className="footer-post-card font-color-medium">
      <div className="date-distance-infos">
        <h3 className="no-margin-vertical capitalize-first-letter ellipsis-overflow">
          {post.publicLocation && (
            <>
              {post.publicLocation.locality}
              {post.publicLocation.country && post.publicLocation.postalCode && ` ${getBoroughIfExist(post.publicLocation.country, post.publicLocation.postalCode)}`}
            </>
          )}
        </h3>
        <h3 className="no-margin-vertical">
          {locationData && post.publicLocation ? formattedDistanceBetweenCoords(locationData as GeolocationCoordinates, post.publicLocation) : ''}
          {locationData && post.publicLocation && post.updatedAt && <> &bull; </>}
          {post.createdAt && post.updatedAt && <span title={titleTagForPostDate(post, i18n)}>{formatDateFromNow(post.updatedAt, true)}</span>}
        </h3>
      </div>

      {['fully_requested', 'finished'].includes(post.state) && <PostStateChip state={post.state} hiddenStates={{ fully_requested: hideBookedChip, finished: hideCompletedChip }} />}
    </IonFooter>
  );
};

export const PostCardFooter = withTranslation()(PostCardFooterInternal);

const PostCard: React.FunctionComponent<PostCardProps> = ({ post, isCurrentUser = () => false, locationData, hideBookedChip, hideCompletedChip }: PostCardProps) => {
  const postHasImage = (post.images as Media[]).length !== 0;

  const activeTab = useSelector((state: RootState) => state.navigation.activeTab);
  const postLink = activeTab && 'posts' !== activeTab ? `/${activeTab}${extractId(post)}` : extractId(post);

  return (
    <IonCard className="post-card" button data-cy="post-card">
      <IonCardHeader className="card-header">
        <UserItem user={post?.createdBy} chipComponent={getChipComponent(post)} size="small" isCurrentUser={isCurrentUser(post?.createdBy)} />
      </IonCardHeader>
      <RoutingItem route={postLink}>
        <>
          <IonCardContent className="ion-no-padding post-data">
            {postHasImage && <ImageFadeIn thumbnail className="post-picture" src={post.images[0].contentUrl} />}

            <PostStateChip state={post.state} hiddenStates={{ fully_requested: hideBookedChip, finished: hideCompletedChip }} />

            <div className="post-title-description-container">
              {postHasImage && (
                <div className="font-color-black-fat">
                  <h1 className="font-size-middle title-wrapper ellipsis-overflow">{post.title}</h1>
                </div>
              )}
              {!postHasImage && (
                <>
                  <div className="font-color-black-fat title-container">
                    <h1 className="title-size-small" style={{ width: '100%' }}>
                      <ResponsiveTitle text={post.title} />
                    </h1>
                  </div>
                  <h2 className="description-container">{post.description}</h2>
                </>
              )}
            </div>
          </IonCardContent>
          <PostCardFooter post={post} locationData={locationData} hideBookedChip={true} hideCompletedChip={true} />
        </>
      </RoutingItem>
    </IonCard>
  );
};

export default PostCard;
