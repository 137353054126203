import React from 'react';
import { IonContent, IonItem, IonAvatar, IonLabel, IonSkeletonText, IonSpinner } from '@ionic/react';

import './CommonPlaceholderStyles.scss';
import './ChatsPlaceholder.scss';

const ChatsPlaceholder: React.FunctionComponent = () => {
  return (
    <IonContent className="chats-placeholder common-placeholder " data-cy="chats-placeholder">
      <IonSpinner className="common-placeholder-spinner" data-cy="chats-loader" />
      {Array.from({ length: 50 }).map((_, index) => (
        <IonItem lines="none" className="chats-placeholder-item" key={`placeholder-item-${index}`}>
          <IonAvatar slot="start">
            <IonSkeletonText />
          </IonAvatar>
          <IonLabel>
            <p>
              <IonSkeletonText style={{ width: '40%' }} />
            </p>
            <p>
              <IonSkeletonText style={{ width: '30%' }} />
            </p>
            <p>
              <IonSkeletonText style={{ width: '70%' }} />
            </p>
          </IonLabel>
        </IonItem>
      ))}
    </IonContent>
  );
};

export default ChatsPlaceholder;
