import { Capacitor, PermissionState } from '@capacitor/core';
import { Geolocation, Position } from '@capacitor/geolocation';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isHttps, isTest } from './environment';
import { actions, RootState } from './store';
import { DevicePosition, GeolocationData } from './store/layout/types';
import { getPositionByIP } from './utils/geolocationHelpers';
import { openNativeSettings } from './utils/helpers';
import { LocationAccuracy } from '@ionic-native/location-accuracy';
import moment from 'moment';

interface ComponentProps {
  geolocationData?: GeolocationData;
}

const mapStateToProps = (state: RootState): ComponentProps => ({
  geolocationData: state.layout.geolocationData,
});

const mapDispatchToProps = {
  setGeolocationData: (geolocationData: GeolocationData) => actions.layout.setGeolocationDataAction(geolocationData),
};

type Props = ComponentProps & typeof mapDispatchToProps;

// We won't accept the IP position if the user position accuracy is beyond a 20km diameter
export const accuracyMaxRadiusTolerated = 10000;
const geolocationTimeout = 10 * 1000;
const positionMaximumAge = 60 * 1000;

const geoOptions: PositionOptions = {
  // Without high accuracy, load faster on ios and stay accurate enough to remove it. (default is false)
  enableHighAccuracy: false,
  timeout: geolocationTimeout,
  // maximumAge default is 0 ms. It represent the maximum accepted position age from the device.
  maximumAge: positionMaximumAge,
};

export const clientIsWebHttp = !Capacitor.isNativePlatform() && !isHttps;

const getGeolocationPermissionPromise = async (): Promise<PermissionState | undefined> => {
  // This will trigger the device permission modal only on IOS.
  // TODO Check if it's still working everywhere
  const permissions = await Geolocation.checkPermissions();
  return permissions.location;
};

const getGeolocationPositionPromise = async (): Promise<Position> => {
  return await Geolocation.getCurrentPosition(geoOptions);
};

// Requests accurate location
// Returns a promise that resolves on success and rejects if an error occurred
// On android, trigger the native geolocation activation modal.
// On Ios, trigger the geolocation permission modal if needed.
const deviceRequestLocation = async (): Promise<void> => {
  return await LocationAccuracy.request(LocationAccuracy.REQUEST_PRIORITY_LOW_POWER);
};

const devicePlatform = Capacitor.getPlatform();

const GeolocationComponent: React.FunctionComponent<Props> = ({ geolocationData, setGeolocationData }: Props) => {
  const { lastAskForPositionDate, lastFetchPositionDate, devicePosition, forceRefreshPosition, geolocationPermission, askPermissions, verifyPermissionsBeforeRefresh, canOpenNativeSettings } = {
    ...geolocationData,
  };
  const [isRefreshingPosition, setIsRefreshingPosition] = useState(false);

  const setGeolocationDataAction = (locationPermission?: PermissionState, location?: Position, locationErrorMessage?: string): void => {
    const devicePosition: DevicePosition | undefined = location
      ? {
          latitude: location?.coords.latitude,
          longitude: location?.coords.longitude,
          accuracy: location?.coords.accuracy,
        }
      : undefined;
    const geolocationErrorMessage = locationErrorMessage;
    const geolocationPermission = locationPermission;

    setGeolocationData({
      devicePosition,
      geolocationErrorMessage,
      geolocationPermission,
      lastFetchPositionDate: new Date(),
    });

    setIsRefreshingPosition(false);
  };

  const refreshDevicePosition = async (): Promise<void> => {
    if (isRefreshingPosition) {
      return;
    }

    setIsRefreshingPosition(true);

    const oneMinuteAgo = moment(new Date()).subtract(1, 'minutes').toDate();

    const devicePositionExists = devicePosition?.latitude !== undefined;
    const deviceAccuracyIsPrecise = devicePosition && devicePosition.accuracy < accuracyMaxRadiusTolerated;
    const lastFetchPositionWasMadeLessThanOneMinuteAgo = lastFetchPositionDate && lastFetchPositionDate > oneMinuteAgo;

    if (!forceRefreshPosition && devicePositionExists && deviceAccuracyIsPrecise && lastFetchPositionWasMadeLessThanOneMinuteAgo) {
      // We won't refresh the position if we fetched it less than a minute ago and the result accuracy was good enough.
      return;
    }

    let location;
    let locationErrorMessage;
    let locationPermission;

    // In some cases we will need to verify the permissions before asking for them. For example when the user exited the app and then returned, we must verify if he changed his settings
    if (verifyPermissionsBeforeRefresh) {
      locationPermission = await getGeolocationPermissionPromise();

      if (locationPermission === 'denied' && !clientIsWebHttp && !isTest) {
        setGeolocationDataAction(locationPermission);
        return;
      }
    }

    // Prevent permissions modal to appear on pages that need geolocation without user action
    if (!askPermissions && (locationPermission ?? geolocationPermission) === 'denied') {
      setGeolocationDataAction(geolocationPermission);
      return;
    }

    // This function allows ios to launch the permission modal & android to launch the activate gps modal.
    // We won't ask for it the first time this function is run. On android we first want to ask the location permission before activating the gps if needed.
    if (devicePlatform !== 'web' && (locationPermission ?? geolocationPermission) === 'granted' && askPermissions) {
      try {
        await deviceRequestLocation();
      } catch (e) {
        // do nothing
      }
    }

    // This timer is used to open the native settings on android if the user refused to share his position and then click on the 'activate geolocation button'
    let timer;
    const timeToWait = 500; // ms
    if (canOpenNativeSettings && geolocationPermission !== 'granted' && Capacitor.getPlatform() === 'android') {
      timer = new Date();
    }

    // Every time the user will ask to get his position updated, we will ask for the position even if he denied to share his position, in case he updated his permissions.
    // Here we ask the position. It will trigger the device permission modal if needed.
    try {
      location = await getGeolocationPositionPromise();
      setGeolocationDataAction('granted', location);
      return;
    } catch (e) {
      // If an error occurred while fetching the position in less than 500ms and the timer was set, we open the native settings.
      if (!!timer && new Date().valueOf() - timer.valueOf() < timeToWait) {
        setGeolocationDataAction(locationPermission || geolocationPermission);
        await openNativeSettings();
      }
      locationErrorMessage = e.message || 'An error occurred while getting the user position';
    }

    // Once we know the user responded to the permission modal, we get the geolocationPermissions. If we already retrieved a position, this code won't be reached.
    // We don't do it first because it would prevent the permission modal to appear.
    // In most cases, the value will be 'denied' or 'prompt'
    locationPermission = await getGeolocationPermissionPromise();

    if (locationPermission === 'denied' && !clientIsWebHttp && !isTest) {
      setGeolocationDataAction(locationPermission);
      return;
    }

    // If client is on web and is using http, we'll get the geolocation by Ip. Exception for tests or if a position was retrieve via gps.
    if (clientIsWebHttp && !isTest && !location) {
      try {
        location = await getPositionByIP();
        setGeolocationDataAction(locationPermission, location);
        return;
      } catch (e) {
        locationErrorMessage = e.message || 'An error occurred while getting the user position';
      }
    }

    setGeolocationDataAction(locationPermission, location, locationErrorMessage);
  };

  useEffect(() => {
    if (!lastAskForPositionDate) {
      return;
    }

    refreshDevicePosition().finally(() => setIsRefreshingPosition(false));
  }, [lastAskForPositionDate]);

  return <></>;
};

export default connect(mapStateToProps, mapDispatchToProps)(GeolocationComponent);
