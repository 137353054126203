import React from 'react';
import { IonCol, IonRow, IonSkeletonText, IonThumbnail } from '@ionic/react';
import { UserItemPlaceholder } from './UsersPlaceholder';
import './PostsPlaceholder.scss';

export const ObjectPlaceholder: React.FunctionComponent = () => {
  return (
    <div className="posts-list-item-placeholder _object">
      <UserItemPlaceholder />
      <IonThumbnail className="object-image-placeholder">
        <IonSkeletonText />
      </IonThumbnail>
      <p>
        <IonSkeletonText style={{ width: '32%' }} />
      </p>
      <p>
        <IonSkeletonText style={{ width: '50%' }} />
      </p>
    </div>
  );
};

export const ServicePlaceholder: React.FunctionComponent = () => {
  return (
    <div className="posts-list-item-placeholder _service">
      <UserItemPlaceholder />
      <div className="title">
        <p>
          <IonSkeletonText style={{ width: '100%' }} />
        </p>
        <p>
          <IonSkeletonText style={{ width: '60%' }} />
        </p>
      </div>
      <div className="description">
        <p>
          <IonSkeletonText style={{ width: '100%' }} />
        </p>
        <p>
          <IonSkeletonText style={{ width: '100%' }} />
        </p>
        <p>
          <IonSkeletonText style={{ width: '100%' }} />
        </p>
        <p>
          <IonSkeletonText style={{ width: '20%' }} />
        </p>
      </div>
      <p>
        <IonSkeletonText style={{ width: '32%' }} />
      </p>
      <p>
        <IonSkeletonText style={{ width: '50%' }} />
      </p>
    </div>
  );
};

type Props = {
  reverse?: boolean;
};
export const PostsRowPlaceholder: React.FunctionComponent<Props> = ({ reverse = false }: Props) => {
  return (
    <IonRow className="posts-list-placeholder">
      <IonCol size="6" size-lg="3" className="posts-placeholder-col">
        {reverse ? <ServicePlaceholder /> : <ObjectPlaceholder />}
      </IonCol>
      <IonCol size="6" size-lg="3" className="posts-placeholder-col">
        {reverse ? <ObjectPlaceholder /> : <ServicePlaceholder />}
      </IonCol>
      <IonCol offset="0" size="0" size-lg="3" className="posts-placeholder-col">
        {reverse ? <ServicePlaceholder /> : <ObjectPlaceholder />}
      </IonCol>
      <IonCol offset="0" offsetLg="0" size="0" size-lg="3" className="posts-placeholder-col">
        {reverse ? <ObjectPlaceholder /> : <ServicePlaceholder />}
      </IonCol>
    </IonRow>
  );
};

export const PostsPlaceholder: React.FunctionComponent = () => {
  return (
    <>
      <PostsRowPlaceholder />
      <PostsRowPlaceholder reverse />
    </>
  );
};

export default PostsPlaceholder;
