import { Dispatch } from 'redux';
import { createAsyncAction } from 'typesafe-actions';
import { ThunkResult } from '../types';
import { fetchWithAuth } from '../../utils/authDataAccess';
import { EntityReference } from '../../utils/hydra';
import { additionalReportFormValuesType } from './types';

export const createUserReportAction = createAsyncAction('reports/CREATE_USER_REPORT', 'reports/CREATE_USER_REPORT_SUCCESS', 'reports/CREATE_USER_REPORT_FAILURE')<void, void, Error>();
export const createPostReportAction = createAsyncAction('reports/CREATE_POST_REPORT', 'reports/CREATE_POST_REPORT_SUCCESS', 'reports/CREATE_POST_REPORT_FAILURE')<void, void, Error>();

export function createUserReport(userId: EntityReference, creatorComment: string, reason: string): ThunkResult<Promise<void>> {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(createUserReportAction.request());

    return fetchWithAuth('/reports', {
      method: 'POST',
      data: {
        reportedUser: userId,
        creatorComment,
        reason,
      },
    })
      .then(() => {
        dispatch(createUserReportAction.success());
      })
      .catch((e: Error) => {
        dispatch(createUserReportAction.failure(e));
      });
  };
}

export function createPostReport(postId: EntityReference, creatorComment: string, reason: string, reportedPostTargetTransition?: additionalReportFormValuesType): ThunkResult<Promise<void>> {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(createPostReportAction.request());
    return fetchWithAuth('/reports', {
      method: 'POST',
      data: {
        creatorComment,
        reportedPost: postId,
        reason,
        reportedPostTargetTransition,
      },
    })
      .then(() => {
        dispatch(createPostReportAction.success());
      })
      .catch((e: Error) => {
        dispatch(createPostReportAction.failure(e));
      });
  };
}
