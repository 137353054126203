import { createAsyncAction } from 'typesafe-actions';
import { fetchWithAuth, fetchWithAuthIfPossible } from '../../utils/authDataAccess';
import { addParametersToUrl } from '../../utils/dataAccess';
import { extractId, parseHydraCollection } from '../../utils/helpers';
import { EntityReference, HydraCollectionResponse, ReferencedCollectionResponse } from '../../utils/hydra';
import { denormalizeEntity, normalizeAndDispatchCollectionResponse, normalizeAndDispatchEntity } from '../entities/selectors';
import { ThunkResult } from '../types';
import { CurrentUser, CurrentUserApiFields, Preferences, ReviewType, User, UserApiFields, UserCollectionName, UserFavoriteCollectionName, UserReview, UserReviewsCollectionName } from './types';
import { editUserAction, flattenCurrentUser, logout } from '../app/actions';
import { fetchPostRequestReviews } from '../postRequests/actions';
import { createCurrentUserByDefaultKeys, createUserByDefaultKeys } from './reducer';

export interface UserCollectionFetchRequest {
  userId: EntityReference;
  collectionName: UserCollectionName;
}

export interface UserCollectionFetchSuccess {
  userId: EntityReference;
  collectionName: UserCollectionName;
  collection: ReferencedCollectionResponse;
}

export interface UserCollectionFetchFailure {
  userId: EntityReference;
  collectionName: UserCollectionName;
  error: Error;
}

export interface SingleFavoriteFetchSuccess {
  userId: EntityReference;
  collectionName: UserCollectionName;
  collection: ReferencedCollectionResponse;
  favoriteUserId: EntityReference;
}

export const fetchUserAction = createAsyncAction('users/FETCH_REQUEST', 'users/FETCH_SUCCESS', 'users/FETCH_FAILURE')<void, void, Error>();

export const fetchFavoriteUsersAction = createAsyncAction('users/FETCH_FAVORITE_USERS_REQUEST', 'users/FETCH_FAVORITE_USERS_SUCCESS', 'users/FETCH_FAVORITE_USERS_FAILURE')<
  UserCollectionFetchRequest,
  UserCollectionFetchSuccess,
  UserCollectionFetchFailure
>();

export const fetchFavoritesByIdAction = createAsyncAction('users/FETCH_FAVORITE_BY_IDS_REQUEST', 'users/FETCH_FAVORITE_BY_IDS_SUCCESS', 'users/FETCH_FAVORITE_BY_IDS_FAILURE')<
  void,
  SingleFavoriteFetchSuccess,
  void
>();

export const addFavoriteUserAction = createAsyncAction('users/ADD_FAVORITE_USER', 'users/ADD_FAVORITE_USER_SUCCESS', 'users/ADD_FAVORITE_USER_FAILURE')<void, EntityReference, Error>();

export const deleteFavoriteUserAction = createAsyncAction('users/DELETE_FAVORITE_USER', 'users/DELETE_FAVORITE_USER_SUCCESS', 'users/DELETE_FAVORITE_USER_FAILURE')<void, EntityReference, Error>();

export const addUserReviewAction = createAsyncAction('users/ADD_USER_REVIEW', 'users/ADD_USER_REVIEW_SUCCESS', 'users/ADD_USER_REVIEW_FAILURE')<void, ReviewType, Error>();

export const fetchUserReviewsAction = createAsyncAction('users/FETCH_USER_REVIEWS', 'users/FETCH_USER_REVIEWS_SUCCESS', 'users/FETCH_USER_REVIEWS_FAILURE')<
  UserCollectionFetchRequest,
  UserCollectionFetchSuccess,
  UserCollectionFetchFailure
>();

export const deleteCurrentUserAction = createAsyncAction('users/DELETE_CURRENT_USER_REQUEST', 'users/DELETE_CURRENT_USER_SUCCESS', 'users/DELETE_CURRENT_USER_FAILURE')<void, void, Error>();

export const flattenUser = (data: UserApiFields): User => {
  const personalData = data.personalData;
  delete data.personalData;

  return { ...data, ...personalData };
};

export function fetchUser(userId: EntityReference): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(fetchUserAction.request());

    return fetchWithAuthIfPossible(userId)
      .then(response => response.json() as Promise<UserApiFields>)
      .then(data => flattenUser(data))
      .then(data => normalizeAndDispatchEntity(data, dispatch))
      .then(() => {
        dispatch(fetchUserAction.success());
      })
      .catch(e => {
        dispatch(fetchUserAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function fetchUserFavorites(userId: EntityReference, collectionName: UserFavoriteCollectionName, search?: string, loadNextPage = false): ThunkResult<Promise<void>> {
  return async (dispatch, getState): Promise<void> => {
    let url = `${userId}/favorite_users`;

    if (collectionName === 'favoritedByUsers') {
      url = `${userId}/favorited_by_users`;
    }

    if (search) {
      url = addParametersToUrl(url, { search });
    }

    if (loadNextPage) {
      url = denormalizeEntity<User>(getState().entities, userId)?.collections?.[collectionName]?.nextPage ?? url;
    }

    dispatch(fetchFavoriteUsersAction.request({ userId, collectionName }));
    return fetchWithAuth(url)
      .then(response => response.json() as Promise<HydraCollectionResponse<User>>)
      .then(data => parseHydraCollection(data))
      .then(data => normalizeAndDispatchCollectionResponse(data, dispatch))
      .then(collection => {
        dispatch(fetchFavoriteUsersAction.success({ userId, collectionName, collection }));
      })
      .catch(error => {
        dispatch(fetchFavoriteUsersAction.failure({ userId, collectionName, error }));
        return Promise.reject(error);
      });
  };
}

export function fetchUserFavoritesById(userId: EntityReference, collectionName: UserFavoriteCollectionName, favoriteUserId: EntityReference): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    let url = `${userId}/favorite_users`;

    if (collectionName === 'favoritedByUsers') {
      url = `${userId}/favorited_by_users`;
    }

    if (favoriteUserId) {
      url = addParametersToUrl(url, { 'id[]': favoriteUserId });
    }

    dispatch(fetchFavoritesByIdAction.request());
    return fetchWithAuth(url)
      .then(response => response.json() as Promise<HydraCollectionResponse<User>>)
      .then(data => parseHydraCollection(data))
      .then(data => normalizeAndDispatchCollectionResponse(data, dispatch))
      .then(collection => {
        dispatch(fetchFavoritesByIdAction.success({ userId, collectionName, collection, favoriteUserId }));
      })
      .catch(error => {
        dispatch(fetchFavoritesByIdAction.failure());
        return Promise.reject(error);
      });
  };
}

export function addFavoriteUser(user: Partial<User> | EntityReference): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(addFavoriteUserAction.request());

    return fetchWithAuth('/users/me/favorite_users', {
      method: 'POST',
      data: { user: extractId(user) },
    })
      .then(response => response.json() as Promise<CurrentUser>)
      .then(() => {
        dispatch(addFavoriteUserAction.success(extractId(user)));
      })
      .catch(e => {
        dispatch(addFavoriteUserAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function deleteFavoriteUser(user: Partial<User> | EntityReference): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(deleteFavoriteUserAction.request());

    return fetchWithAuth('/users/me/favorite_users', {
      method: 'DELETE',
      data: { user: extractId(user) },
    })
      .then(() => {
        dispatch(deleteFavoriteUserAction.success(extractId(user)));
      })
      .catch(e => {
        dispatch(deleteFavoriteUserAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function fetchUserReviews(userId: EntityReference, collectionName: UserReviewsCollectionName, url?: string): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(fetchUserReviewsAction.request({ userId, collectionName }));

    let page = url;

    if (!page) {
      page = userId + '/received_reviews';

      if (collectionName === 'postRequestReviews') {
        page += '?exists[reviewedPostRequest]=true';
      } else {
        page += '?exists[reviewedPostRequest]=false';
      }
    }

    return fetchWithAuthIfPossible(page)
      .then(response => response.json() as Promise<HydraCollectionResponse<UserReview>>)
      .then(data => parseHydraCollection(data))
      .then(data => normalizeAndDispatchCollectionResponse(data, dispatch))
      .then(collection => {
        dispatch(fetchUserReviewsAction.success({ userId, collectionName, collection }));
      })
      .catch(error => {
        dispatch(fetchUserReviewsAction.failure({ userId, collectionName, error }));
        return Promise.reject(error);
      });
  };
}

export function fetchUserReviewsResponsesByPostRequest(userId: EntityReference): ThunkResult<Promise<void>> {
  const collectionName = 'postRequestReviewsResponses';
  const url = userId + '/created_reviews';

  return fetchUserReviews(userId, collectionName, url);
}

export function fetchPositiveUserPostRequestReviewsNumber(userId: EntityReference): ThunkResult<Promise<void>> {
  const collectionName = 'positivePostRequestReviews';
  const url = userId + '/received_reviews?type=positive&itemsPerPage=0&exists[reviewedPostRequest]=true';

  return fetchUserReviews(userId, collectionName, url);
}

export function addUserReview(reviewedUser: EntityReference, type: ReviewType, comment?: string, reviewedPostRequest?: EntityReference): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(addUserReviewAction.request());

    return fetchWithAuth('/user_reviews', {
      method: 'POST',
      data: { reviewedUser, reviewedPostRequest, type, comment },
    })
      .then(response => response.json() as Promise<UserReview>)
      .then(data => normalizeAndDispatchEntity(data, dispatch))
      .then(() => {
        dispatch(addUserReviewAction.success(type));
        if (reviewedPostRequest) {
          dispatch(fetchPostRequestReviews(reviewedPostRequest));
        }
      })
      .catch(e => {
        dispatch(addUserReviewAction.failure(e));
      });
  };
}

function sendDeletingReason(deletionReason: string): Promise<User> {
  return fetchWithAuth('/users/me', {
    method: 'PUT',
    data: { deletionReason },
  })
    .then(response => response.json() as Promise<User>)
    .catch(e => Promise.reject(e));
}

export function deleteCurrentUser(deletionReason: string): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(deleteCurrentUserAction.request());

    try {
      await sendDeletingReason(deletionReason);
    } catch (e) {
      dispatch(deleteCurrentUserAction.failure(e));
      return Promise.reject(e);
    }

    return fetchWithAuth('/users/me', {
      method: 'DELETE',
    })
      .then(() => {
        dispatch(deleteCurrentUserAction.success());
        dispatch(logout());
      })
      .catch(e => {
        dispatch(deleteCurrentUserAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function addUserPreferences(preferences: Preferences): ThunkResult<Promise<CurrentUser>> {
  return async (dispatch): Promise<CurrentUser> => {
    dispatch(editUserAction.request());
    return fetchWithAuth('/users/me/preferences', {
      method: 'PUT',
      data: preferences,
    })
      .then(response => response.json() as Promise<CurrentUserApiFields>)
      .then(data => flattenCurrentUser(data))
      .then(data => {
        dispatch(editUserAction.success(createCurrentUserByDefaultKeys(data, !!data.managed)));
        normalizeAndDispatchEntity(createUserByDefaultKeys(data), dispatch);
        return data;
      })
      .catch(e => {
        dispatch(editUserAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function removeUserPreference(preference: string): ThunkResult<Promise<void>> {
  return async (): Promise<void> => {
    return fetchWithAuth(`/users/me/preferences/${preference}`, {
      method: 'DELETE',
    })
      .then(() => {
        return;
      })
      .catch(e => {
        return Promise.reject(e);
      });
  };
}
