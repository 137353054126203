import React, { Component, ReactNode } from 'react';
import { IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonList, IonLoading } from '@ionic/react';
import { Redirect } from 'react-router';
import { extractId } from '../utils/helpers';
import { HydratedCollection } from '../utils/hydra';
import { User } from '../store/users/types';
import i18next from 'i18next';
import FollowButton from './FollowButton';
import UserItem from './UserItem';
import './UsersFavoriteList.scss';
import { isCurrentUserFunc } from '../store/app/selectors';

interface ListProps {
  usersList: HydratedCollection<User>;
  fetchNextUsersPage: () => void;
  isCurrentUser: isCurrentUserFunc;
  currentUser: User | undefined;
}

type Props = ListProps;

class UsersFavoriteList extends Component<Props> {
  private readonly ionInfiniteScrollRef = React.createRef<HTMLIonInfiniteScrollElement>();

  public constructor(props: Props) {
    super(props);

    this.infiniteScrolling = this.infiniteScrolling.bind(this);
  }

  public render(): ReactNode {
    const { usersList, isCurrentUser, currentUser } = this.props;

    if (usersList.isLoading && !usersList.totalItems) {
      return <IonLoading spinner="bubbles" isOpen={true} showBackdrop={false} />;
    }

    if (!usersList.totalItems) {
      return (
        <IonItem lines="none" className="favorite-user-not-found-item">
          <IonLabel className="ion-text-center">{i18next.t('user.no-users-found')}</IonLabel>
        </IonItem>
      );
    }

    if (!currentUser) {
      return <Redirect to="/" />;
    }

    return (
      <>
        <IonList mode="md" className="users-list" lines="none">
          {usersList.items.map((user: User) => {
            const isUserCurrentUser: boolean = isCurrentUser(user);
            return (
              /* Mode ios is necessary here because of the parent (IonList) that is in mode="md" */
              <IonItem data-cy="user-item-with-favorite" mode="ios" detail={false} key={extractId(user)}>
                <UserItem user={user} />
                {!isUserCurrentUser && <FollowButton currentUser={currentUser} userId={extractId(user)} color="primary" />}
              </IonItem>
            );
          })}
        </IonList>
        <IonInfiniteScroll ref={this.ionInfiniteScrollRef} onIonInfinite={this.infiniteScrolling} disabled={!usersList?.nextPage}>
          <IonInfiniteScrollContent loading-spinner="circles" />
        </IonInfiniteScroll>
      </>
    );
  }

  private infiniteScrolling(): void {
    this.props.fetchNextUsersPage();
    this.ionInfiniteScrollRef.current?.complete();
  }
}

export default UsersFavoriteList;
