import React from 'react';
import { IonContent, IonItem, IonAvatar, IonLabel, IonSkeletonText, IonSpinner } from '@ionic/react';

import './CommonPlaceholderStyles.scss';
import './NotificationsPlaceholder.scss';

const NotificationsPlaceholder: React.FunctionComponent = () => (
  <IonContent className="notifications-placeholder common-placeholder " data-cy="notifications-placeholder">
    <IonSpinner className="common-placeholder-spinner" data-cy="notifications-loader" />
    {Array.from({ length: 50 }).map((_, index) => (
      <IonItem lines="none" className="notifications-placeholder-item" key={`placeholder-item-${index}`}>
        <IonAvatar slot="start">
          <IonSkeletonText />
        </IonAvatar>
        <IonAvatar slot="end" className="square-avatar">
          <IonSkeletonText />
        </IonAvatar>
        <IonLabel>
          <p>
            <IonSkeletonText style={{ width: '90%' }} />
          </p>
          <p>
            <IonSkeletonText style={{ width: '80%' }} />
          </p>
          <p>
            <IonSkeletonText style={{ width: '15%' }} />
          </p>
        </IonLabel>
      </IonItem>
    ))}
  </IonContent>
);

export default NotificationsPlaceholder;
