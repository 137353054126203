import { Dispatch } from 'redux';
import { createAsyncAction } from 'typesafe-actions';
import { ThunkResult } from '../types';
import { fetchWithAuth } from '../../utils/authDataAccess';
import { EntityReference, HydraCollectionResponse, ReferencedCollectionResponse } from '../../utils/hydra';
import { User } from '../users/types';
import { BlockedUsersCollectionName } from './types';
import { parseHydraCollection } from '../../utils/helpers';
import { normalizeAndDispatchCollectionResponse } from '../entities/selectors';

export const fetchBlockedUsersAction = createAsyncAction('blockedUsers/FETCH_BLOCKED_USERS_REQUEST', 'blockedUsers/FETCH_BLOCKED_USERS_SUCCESS', 'blockedUsers/FETCH_BLOCKED_USERS_FAILURE')<
  BlockedUsersCollectionName,
  { collectionName: BlockedUsersCollectionName; collection: ReferencedCollectionResponse },
  { collectionName: BlockedUsersCollectionName; error: Error }
>();

export const blockUserAction = createAsyncAction('blockedUsers/BLOCK_USER', 'blockedUsers/BLOCK_USER_SUCCESS', 'blockedUsers/BLOCK_USER_FAILURE')<void, void, Error>();
export const unblockUserAction = createAsyncAction('blockedUsers/UNBLOCK_USER', 'blockedUsers/UNBLOCK_USER_SUCCESS', 'blockedUsers/UNBLOCK_USER_FAILURE')<void, void, Error>();

export function fetchBlockedUsers(collectionName: BlockedUsersCollectionName): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    let url = `/users/me/blocked_users`;

    if (collectionName === 'currentUserIsBlockedCollection') {
      url = '/users/me/blocked_by_users';
    }

    dispatch(fetchBlockedUsersAction.request(collectionName));

    return fetchWithAuth(url)
      .then(response => response.json() as Promise<HydraCollectionResponse<User>>)
      .then(data => parseHydraCollection(data))
      .then(data => normalizeAndDispatchCollectionResponse(data, dispatch))
      .then(collection => {
        dispatch(fetchBlockedUsersAction.success({ collectionName, collection }));
      })
      .catch((error: Error) => {
        dispatch(fetchBlockedUsersAction.failure({ collectionName, error }));
      });
  };
}

export function blockUser(userId: EntityReference): ThunkResult<Promise<void>> {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(blockUserAction.request());

    return fetchWithAuth('/users/me/blocked_users', {
      method: 'POST',
      data: {
        user: userId,
      },
    })
      .then(() => {
        dispatch(blockUserAction.success());
      })
      .catch((e: Error) => {
        dispatch(blockUserAction.failure(e));
      });
  };
}

export function unblockUser(userId: EntityReference): ThunkResult<Promise<void>> {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(unblockUserAction.request());

    return fetchWithAuth('/users/me/blocked_users', {
      method: 'DELETE',
      data: {
        user: userId,
      },
    })
      .then(() => {
        dispatch(unblockUserAction.success());
      })
      .catch((e: Error) => {
        dispatch(unblockUserAction.failure(e));
      });
  };
}
