import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCol, IonImg, IonRow } from '@ionic/react';
import i18next from 'i18next';
import React from 'react';
import { Trans } from 'react-i18next';
import RoutingGuard from './common/RoutingGuard';
import { useMediaQuery } from 'react-responsive';
import { widthTabletMin } from '../utils/helpers';
import { sendSocialBannerClickedLog } from '../utils/analytics/analyticsHelper';

import './SocialCard.scss';

interface ImageSocialCardProps {
  backgroundImageName: string;
  sizeLg: string;
  size: string;
  dataCy: string;
  routerLink: string;
  className?: string;
  onClick?: () => void;
}

interface SocialCardProps {
  oneSlotCard?: boolean;
}

export const SocialCardTree: React.FunctionComponent = () => {
  const isSmallScreen = useMediaQuery({ query: `(max-width: ${widthTabletMin}px)` });
  const type = 'eco';
  if (isSmallScreen) {
    return (
      <IonCol size="12">
        <IonCard className="social-card tree-card-sm small-card" button data-cy="social-card">
          <IonCardHeader className="title">
            <Trans i18nKey="social-card.tree.title" components={[<span className="title-size-medium" key="social-card.tree.title" />]} />
          </IonCardHeader>
          <IonCardContent className="ion-no-padding post-data">
            <IonRow>
              <IonCol size="12" className="media">
                <IonImg src="/assets/social-media/world.svg" />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="12" className="content">
                <Trans i18nKey="social-card.tree.content" components={[<strong key="social-card.tree.content" />]} />
                <div>
                  <RoutingGuard route="/posts/create/offer">
                    <IonButton className="button-social" routerLink="/posts/create/offer" onClick={() => sendSocialBannerClickedLog(type)}>
                      <Trans i18nKey="social-card.button" />
                    </IonButton>
                  </RoutingGuard>
                </div>
                <div className="footer">
                  <Trans i18nKey="social-card.tree.footer" />
                </div>
              </IonCol>
            </IonRow>
          </IonCardContent>
        </IonCard>
      </IonCol>
    );
  }

  return (
    <IonCol size="6">
      <IonCard className="social-card tree-card-lg" button data-cy="social-card">
        <IonCardHeader className="title">
          <Trans i18nKey="social-card.tree.title" components={[<span className="title-size-medium" key="social-card.tree.title" />]} />
        </IonCardHeader>
        <IonCardContent className="ion-no-padding post-data">
          <IonRow>
            <IonCol size="6" className="media">
              <IonImg src="/assets/social-media/world.svg" />
            </IonCol>
            <IonCol size="6" className="content">
              <Trans i18nKey="social-card.tree.content" components={[<strong key="social-card.tree.content" />]} />
              <div>
                <RoutingGuard route="/posts/create/offer">
                  <IonButton className="button-social" routerLink="/posts/create/offer" onClick={() => sendSocialBannerClickedLog(type)}>
                    <Trans i18nKey="social-card.button" />
                  </IonButton>
                </RoutingGuard>
              </div>
              <div className="footer">
                <Trans i18nKey="social-card.tree.footer" />
              </div>
            </IonCol>
          </IonRow>
        </IonCardContent>
      </IonCard>
    </IonCol>
  );
};

const ImageSocialCard: React.FunctionComponent<ImageSocialCardProps> = ({ backgroundImageName, sizeLg, size, dataCy, routerLink, className, onClick }: ImageSocialCardProps) => {
  let locale = i18next.language.substring(0, 2);

  if (!['fr', 'en'].includes(locale)) {
    locale = 'en';
  }

  return (
    <RoutingGuard route={routerLink}>
      <IonCol size={size} size-lg={sizeLg}>
        <IonCard
          onClick={onClick}
          className={`social-card image-social-card ${className}`}
          button
          data-cy={dataCy}
          style={{ backgroundImage: `url(${backgroundImageName}-${locale}.png)` }}
          routerLink={routerLink}
        />
      </IonCol>
    </RoutingGuard>
  );
};

export const SocialCardMaxRadius: React.FunctionComponent<SocialCardProps> = ({ oneSlotCard }: SocialCardProps) => {
  const isSmallScreen = useMediaQuery({ query: `(max-width: ${widthTabletMin}px)` });

  return (
    <ImageSocialCard
      backgroundImageName={`/assets/social-media/max-radius-social-card${oneSlotCard || isSmallScreen ? '-small' : '-large'}`}
      sizeLg={oneSlotCard ? '3' : '6'}
      size={!isSmallScreen ? '6' : oneSlotCard ? '6' : '12'}
      dataCy={'max-radius-social-card'}
      routerLink={'/posts/create/offer'}
      className={!oneSlotCard && isSmallScreen ? 'small-card' : ''}
      onClick={() => sendSocialBannerClickedLog('not-enough')}
    />
  );
};
