import {
  IonButtons,
  IonButton,
  IonActionSheet,
  IonAlert,
  IonContent,
  IonHeader,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonToolbar,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonPage,
  withIonLifeCycle,
  IonTitle,
  IonLoading,
  SearchbarInputEventDetail,
} from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import ChatsList from '../components/ChatsList';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import { actions, RootState } from '../store';
import { ChatCollectionName, ChatQueryFilters, chatsQueryFiltersKeys, UserChat, UserChatState } from '../store/chats/types';
import { extractId, isEqualIgnoringFunctions, userDisallowsNotifications } from '../utils/helpers';
import { EntityReference, HydratedCollection } from '../utils/hydra';
import { IonSearchbarCustomEvent, RefresherEventDetail } from '@ionic/core';
import { CurrentUser, CurrentUserEditFields } from '../store/users/types';
import TurnOnNotificationBanner from '../components/TurnOnNotificationBanner';
import { getHydratedCurrentUserChats } from '../store/chats/selectors';
import { locationIsChatTab } from '../utils/windowLocationHelper';
import { PushNotifications } from '../capacitor/PushNotifications';
import moment from 'moment';
import ChatSelectedFiltersList from '../components/ChatSelectedFiltersList';
import ChatFiltersButton from '../components/ChatFiltersButton';

import './ChatsPage.scss';

interface StateProps {
  userChats: HydratedCollection<UserChat>;
  currentUser: CurrentUser;
  pushNotificationsAllowed: boolean;
  lastActionUpdatingChatsListDate?: Date;
}

const mapStateToProps = (state: RootState): StateProps => ({
  userChats: getHydratedCurrentUserChats(state),
  currentUser: state.app.currentUser,
  lastActionUpdatingChatsListDate: state.layout.lastActionUpdatingChatsListDate,
  pushNotificationsAllowed: !userDisallowsNotifications(state.app.currentUser, 'direct_chat_new_message'),
});

interface DispatchProps {
  fetchUserChats: (page?: string, collectionName?: ChatCollectionName, loadNextPage?: boolean, searchParams?: ChatQueryFilters) => void;
  fetchLoggedUser: () => Promise<void>;
  updateUserChatState: (chatId: EntityReference, state: UserChatState, lastReadMessageDate: Date) => Promise<void>;
  setToastMessage(message: string | null): void;
  updateUser(user: Partial<CurrentUserEditFields>): Promise<CurrentUser>;
  resetUserChatCollection: (collectionName: 'currentUserChats') => void;
}

const propsToDispatch = {
  fetchUserChats: actions.chats.fetchCurrentUserChats,
  fetchLoggedUser: actions.app.fetchLoggedUser,
  updateUserChatState: actions.chats.updateUserChatState,
  setToastMessage: actions.layout.setToastMessageAction,
  updateUser: actions.app.updateUser,
  resetUserChatCollection: actions.chats.resetCollection,
};

interface State {
  showActionSheet: boolean;
  isEditMode: boolean;
  isNotificationBannerOpen: boolean;
  isArchiving: boolean;
  checkedUserChats: EntityReference[];
  lastForcedRenderDate: Date;
  lastRefreshDate: Date;
  chatQueryFilters: ChatQueryFilters;
  newChatState?: UserChatState;
}

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

const defaultChatQueryFilters: ChatQueryFilters = {
  search: '',
  postId: undefined,
  selectedFilters: ['active'],
};

type ChatsProps = StateProps & DispatchProps & WithTranslation & RouteComponentProps<Record<string, never>>;

class ChatsPage extends Component<ChatsProps, State> {
  private readonly debouncedDoRefresh: () => void;
  private readonly ionInfiniteScrollRef: React.RefObject<HTMLIonInfiniteScrollElement>;

  public constructor(props: ChatsProps) {
    super(props);

    this.state = {
      showActionSheet: false,
      isEditMode: false,
      isNotificationBannerOpen: false,
      isArchiving: false,
      checkedUserChats: [],
      lastForcedRenderDate: new Date(),
      lastRefreshDate: new Date(),
      chatQueryFilters: defaultChatQueryFilters,
    };

    this.debouncedDoRefresh = debounce(() => this.doRefresh(), 500);
    this.ionInfiniteScrollRef = React.createRef<HTMLIonInfiniteScrollElement>();
  }

  componentDidMount(): void {
    //Useful in case of postId=xxx or any argument in URL
    setTimeout(this.handleUrlParams);
  }

  public ionViewDidEnter(): void {
    this.props.fetchLoggedUser();

    this.setState({ lastForcedRenderDate: new Date() }); // We re render to update the chats list without fetching the chats

    PushNotifications.requestPermissions().then(status => {
      this.setState({
        isNotificationBannerOpen: status.receive !== 'granted' || !this.props.pushNotificationsAllowed,
      });
    });
  }

  public ionViewWillLeave(): void {
    if (this.state.isEditMode) {
      this.setEditMode(false);
    }
  }

  public shouldComponentUpdate(nextProps: Readonly<ChatsProps>, nextState: Readonly<State>): boolean {
    // Update component if currentUser status unreadChats changed, even if we are not in chatsPage
    if (this.props.currentUser.status.unreadChats !== nextProps.currentUser.status.unreadChats) {
      return true;
    }

    if (!locationIsChatTab()) {
      return false;
    }

    return !isEqualIgnoringFunctions(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  private searchTextUpdate(prevState: Readonly<State>): void {
    const searchText: string = this.state.chatQueryFilters.search || '';

    if (prevState.chatQueryFilters.search !== searchText) {
      const trimmedSearchText = searchText.trim();
      if (searchText.length !== 0 && trimmedSearchText.length === 0) {
        return;
      }
      const isSearchValueCleared: boolean = prevState.chatQueryFilters?.search?.length !== 0 && searchText.length === 0;
      const isOneLetterSearchValue: boolean = !!searchText && trimmedSearchText.length === 1;
      isSearchValueCleared || isOneLetterSearchValue ? this.doRefresh() : this.debouncedDoRefresh();
    }
  }

  public componentDidUpdate(prevProps: Readonly<ChatsProps>, prevState: Readonly<State>): void {
    const refreshAndUpdateSearchText = (): void => {
      this.doRefresh();
      this.searchTextUpdate(prevState);
    };

    if (this.props.location?.search !== prevProps.location?.search) {
      this.handleUrlParams();
      return;
    }

    // If the number of unread chats didn't change but the search changed, then we refresh
    if (this.props.currentUser.status.unreadChats !== prevProps.currentUser.status.unreadChats && prevState.chatQueryFilters.search === this.state.chatQueryFilters.search) {
      refreshAndUpdateSearchText();
      return;
    }

    // If the user is in filter mode, he can archive chats, so we refresh the chatList when he exits the filter mode
    if (!this.state.newChatState && prevState.newChatState) {
      refreshAndUpdateSearchText();
      return;
    }

    if (this.props.lastActionUpdatingChatsListDate && moment(this.props.lastActionUpdatingChatsListDate).isAfter(moment(this.state.lastRefreshDate))) {
      this.setState({ lastRefreshDate: new Date() }, () => {
        this.doRefresh();
      });
    }

    this.searchTextUpdate(prevState);
  }

  private isFiltered(): boolean {
    return !!this.state.chatQueryFilters.selectedFilters.length || !!this.state.chatQueryFilters.postId || !!this.state.chatQueryFilters.search;
  }

  public render(): ReactNode {
    const { showActionSheet, isEditMode, chatQueryFilters, isNotificationBannerOpen, isArchiving, newChatState } = this.state;
    const { t, currentUser, userChats } = this.props;

    return (
      <IonPage data-cy="chats-page">
        <IonHeader className="chats-list-header">
          <IonToolbar className="toolbar">
            <IonTitle color="dark">
              <Trans i18nKey="common.messages" />
            </IonTitle>

            <IonButtons slot="end">
              <IonButton onClick={this.toggleActionSheet} data-cy="toggle-chat-mode">
                <IonIcon slot="icon-only" icon="/assets/navigation/more.svg" color="dark" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
          <div className="searchbar-wrapper">
            <IonSearchbar
              searchIcon="/assets/form/search.svg"
              data-cy="chats-searchbar"
              value={chatQueryFilters.search}
              placeholder={t('common.search')}
              onIonChange={this.updateSearchTerm}
              className="search-input"
              onIonCancel={() => this.doRefresh()}
              onIonClear={() => this.clearSearch()}
              debounce={0}
              spellcheck={true}
              autocorrect="on"
              autocomplete="on"
              clearIcon="/assets/form/close-circle.svg"
            />
            <ChatFiltersButton setChatFilters={newFilters => this.setState({ chatQueryFilters: newFilters }, () => this.doRefresh())} initialChatsFilters={this.state.chatQueryFilters} />
            <ChatSelectedFiltersList
              filters={this.state.chatQueryFilters}
              onSelectedPost={(filterName: keyof ChatQueryFilters) => {
                this.setState({ chatQueryFilters: { ...this.state.chatQueryFilters, [filterName]: undefined } }, () => this.doRefresh());
              }}
              setFilters={filters => {
                this.setState({ chatQueryFilters: { ...this.state.chatQueryFilters, selectedFilters: filters } }, () => this.doRefresh());
              }}
            />
          </div>
        </IonHeader>

        <IonContent className="chats-list-content">
          <IonRefresher onIonRefresh={e => this.doRefresh(false, e)} slot="fixed">
            <IonRefresherContent pullingText={t('chat.pull-to-refresh')} refreshing-spinner="circles" />
          </IonRefresher>
          {isNotificationBannerOpen && (
            <div className="fixed-banner">
              <TurnOnNotificationBanner
                isChatPage={false}
                currentUser={this.props.currentUser}
                showNotificationBanner={isNotificationShouldBeOpen => this.updateNotificationBannerState(isNotificationShouldBeOpen)}
                updateUser={this.props.updateUser}
                disabledNotifications={this.props.currentUser.disabledPushNotifications}
              />
            </div>
          )}
          <div className={isNotificationBannerOpen ? 'chat-list-wrapper-margin ' : 'chat-list-wrapper'}>
            <ChatsList
              userChats={userChats}
              isEditMode={isEditMode}
              checkedUserChats={this.state.checkedUserChats}
              toggleChatItem={this.toggleChatItem}
              onChatItemClick={(userChatId, newState) => this.toggleChatState([userChatId], newState)}
              currentUser={currentUser}
            />
          </div>
          <IonInfiniteScroll ref={this.ionInfiniteScrollRef} threshold="50px" onIonInfinite={this.infiniteScroll} disabled={!userChats.nextPage}>
            <IonInfiniteScrollContent loading-spinner="circle" loading-text={t('common.loading')} />
          </IonInfiniteScroll>
          <IonActionSheet
            data-cy="toggle-chat-mode-action-sheet"
            isOpen={showActionSheet}
            onDidDismiss={this.toggleActionSheet}
            buttons={[
              {
                text: t('chat.select-messages'),
                handler: () => this.setEditMode(true),
              },
              {
                text: t('common.cancel'),
                role: 'cancel',
              },
            ]}
          />
          <IonAlert
            cssClass="toggle-chat-mode-confirmation-alert" // Data-cy doesn't work on IonAlerts, so we use a css class
            isOpen={!!newChatState}
            onDidDismiss={() => this.setState({ newChatState: undefined })}
            message={t(newChatState === 'active' ? 'chat.these-conversations-will-be-unarchived' : 'chat.these-conversations-will-be-archived')}
            buttons={[
              {
                text: t('common.cancel'),
                role: 'cancel',
                cssClass: 'secondary',
              },
              {
                text: t('chat.continue'),
                handler: () => {
                  if (!newChatState) {
                    throw new Error('You should set a new chat state before updating it');
                  }

                  this.toggleChatState(this.state.checkedUserChats, newChatState);
                },
              },
            ]}
          />
          {isArchiving && <IonLoading spinner="bubbles" isOpen={true} showBackdrop />}
        </IonContent>
        {isEditMode && (
          <div className="chat-controller">
            <IonButtons slot="end">
              <IonButton color="primary" onClick={() => this.setEditMode(false)}>
                <Trans i18nKey="common.cancel" />
              </IonButton>
              <IonButton color="primary" onClick={() => this.handleArchiveButtonClick(false)}>
                <Trans i18nKey="chat.archive" />
              </IonButton>
              <IonButton color="primary" onClick={() => this.handleArchiveButtonClick(true)}>
                <Trans i18nKey="chat.unarchive" />
              </IonButton>
              {/*TODO delete functionality, uncomment when api will be ready*/}
              {/*<IonButton color="primary">*/}
              {/*  <Trans i18nKey="chat.delete" />*/}
              {/*</IonButton>*/}
            </IonButtons>
          </div>
        )}
      </IonPage>
    );
  }

  private setEditMode(state: boolean): void {
    if (this.state.isEditMode === state) {
      return;
    }
    this.setState({ isEditMode: state, checkedUserChats: [] });
  }

  private updateNotificationBannerState = async (isNotificationShouldBeOpen: boolean): Promise<void> => {
    this.setState({
      isNotificationBannerOpen: isNotificationShouldBeOpen,
    });
    this.props.fetchLoggedUser();
  };

  private infiniteScroll = async (): Promise<void> => {
    await this.doRefresh(true);
    this.ionInfiniteScrollRef.current?.complete();
  };

  private handleArchiveButtonClick = (shouldSetPostToActive: boolean): void => {
    if (this.state.checkedUserChats.length === 0) {
      this.props.setToastMessage(this.props.t('chat.select-chat'));
      return;
    }

    this.setState({ newChatState: shouldSetPostToActive ? 'active' : 'archived' });
  };

  private toggleChatState = async (userChatsIds: EntityReference[], newChatState: UserChatState): Promise<void> => {
    this.setState({ isArchiving: true, newChatState: undefined });

    for (const userChatId of userChatsIds) {
      try {
        await this.props.updateUserChatState(userChatId, newChatState, new Date());
      } catch (e) {
        // Prevent error when archiving the chat
      }
    }

    //updateUser for update count number of read messages
    this.props.updateUser({ '@id': extractId(this.props.currentUser) });
    this.setEditMode(false);
    this.setState({ isArchiving: false });
    this.doRefresh();
  };

  private toggleChatItem = (userChatId: EntityReference): void => {
    if (this.state.checkedUserChats.includes(userChatId)) {
      this.setState({ checkedUserChats: this.state.checkedUserChats.filter(id => id != userChatId) });
    } else {
      this.setState({ checkedUserChats: this.state.checkedUserChats.concat([userChatId]) });
    }
  };

  private toggleActionSheet = (): void => {
    this.setState({ showActionSheet: !this.state.showActionSheet });
  };

  private updateSearchTerm = (e: IonSearchbarCustomEvent<SearchbarInputEventDetail>): void => {
    const searchText = e.detail.value ?? '';
    this.setState({ chatQueryFilters: { ...this.state.chatQueryFilters, search: searchText } });
  };

  private clearSearch = (): void => {
    this.setState({ chatQueryFilters: { ...this.state.chatQueryFilters, search: '' } });
    this.doRefresh();
  };

  private doRefresh = async (loadNextPage = false, event?: CustomEvent<RefresherEventDetail>): Promise<void> => {
    let searchParams;
    const collectionName: ChatCollectionName = 'currentUserChats';

    if (this.isFiltered()) {
      searchParams = this.state.chatQueryFilters;
      searchParams.search = this.state.chatQueryFilters.search?.trim();
      if (!loadNextPage) {
        try {
          await this.props.resetUserChatCollection(collectionName);
        } catch (e) {
          // Prevent fetch error
        }
      }
    }

    try {
      await this.props.fetchUserChats('/users/me/chats', collectionName, loadNextPage, searchParams);
    } catch (e) {
      // Prevent fetch error
    }

    if (event) {
      event.detail.complete();
    }
  };

  private handleUrlParams = (): void => {
    const urlParams = new URLSearchParams(this.props.location?.search);

    let newChatQueryFilters: ChatQueryFilters = { ...defaultChatQueryFilters };

    if (urlParams.toString() === '') {
      return;
    }

    urlParams.forEach((value: string, key: string) => {
      if (!chatsQueryFiltersKeys.includes(key)) {
        return;
      }
      newChatQueryFilters = { ...newChatQueryFilters, [key]: value === '[]' ? [] : value };
    });

    this.setState({ chatQueryFilters: newChatQueryFilters }, this.doRefresh);
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(withIonLifeCycle(ChatsPage))));
