import React, { Component, ReactNode } from 'react';
import { IonModal, IonButton, IonThumbnail, IonContent, IonIcon, IonHeader } from '@ionic/react';
import { Trans } from 'react-i18next';
import { createNewFile, getBlobByUrl } from '../utils/helpers';

import './ChooseDefaultPictureModal.scss';

interface ModalProps {
  isOpen: boolean;
  defaultPictures: string[];
  addPicture: (selectorFile: File) => void;
  closeModal: () => void;
}

class ChooseDefaultPictureModal extends Component<ModalProps> {
  constructor(props: ModalProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  public render(): ReactNode {
    const { isOpen, closeModal, defaultPictures } = this.props;

    return (
      <IonModal data-cy="add-default-images-ion-modal" isOpen={isOpen} onDidDismiss={closeModal} className="safe-area-ios default-images-modal">
        <IonHeader>
          <IonButton onClick={closeModal} fill="clear" className="header-back-button">
            <IonIcon src="/assets/navigation/close.svg" color="dark" />
          </IonButton>
          <h1 className="header-title">
            <Trans i18nKey="media.choose-avatar" />
          </h1>
        </IonHeader>
        <IonContent>
          <div className="container-default-images">
            {defaultPictures.map((pictureUrl, i) => (
              <IonThumbnail key={pictureUrl} data-cy={`default-images-${i}`} className="logo-thumbnail">
                <img src={pictureUrl} alt={pictureUrl} onClick={() => this.onDefaultPictureSelect(pictureUrl)} />
              </IonThumbnail>
            ))}
          </div>
        </IonContent>
      </IonModal>
    );
  }

  private async onDefaultPictureSelect(pictureUrl: string): Promise<void> {
    const image = await getBlobByUrl(pictureUrl);
    const imageFile = createNewFile([image], pictureUrl.split('/').pop() ?? '', { type: 'image/jpeg' });
    this.props.addPicture(imageFile);
    this.props.closeModal();
  }

  public handleClick(): void {
    this.props.closeModal();
  }
}

export default ChooseDefaultPictureModal;
