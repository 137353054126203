import { IonButton, IonButtons, IonCard, IonCol, IonContent, IonHeader, IonIcon, IonModal, IonToolbar } from '@ionic/react';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useMediaQuery } from 'react-responsive';
import { ManagedUserInfos } from '../store/layout/types';
import { widthTabletMin } from '../utils/helpers';
import { getMarkdownReadyMessage, getTargetBlankLink } from '../utils/markdownHelper';
import ImageFadeIn from './ImageFadeIn';

import './PromotionalCard.scss';

interface PromotionalCardProps {
  managedUserInfos: ManagedUserInfos;
}

const PromotionalCard: React.FunctionComponent<PromotionalCardProps> = ({ managedUserInfos }: PromotionalCardProps) => {
  const { promotionalBgImageLargeScreen, promotionalBgImage, promotionalModalText, promotionalModalImage } = managedUserInfos;
  const isSmallScreen = useMediaQuery({ query: `(max-width: ${widthTabletMin}px)` });
  const [isPromotionalCardModalOpen, setIsPromotionalCardModalOpen] = useState(false);

  const size = isSmallScreen ? '12' : '6';
  const className = `promotional-card ${isSmallScreen ? 'small' : 'large'}-card`;

  // If there is no bg image for large screen, we use the small screen bg image, and vice-versa
  const backgroundImageUrl = !isSmallScreen ? promotionalBgImageLargeScreen ?? promotionalBgImage : promotionalBgImage ?? promotionalBgImageLargeScreen;

  const onPromotionalCardClick = (): void => {
    if (!promotionalModalText && !promotionalModalImage) {
      return;
    }
    setIsPromotionalCardModalOpen(true);
  };

  return (
    <>
      <IonCol size={size}>
        <IonCard className={className} button data-cy="promotional-card" style={{ backgroundImage: `url(${backgroundImageUrl})` }} onClick={onPromotionalCardClick} />
      </IonCol>
      <IonModal isOpen={isPromotionalCardModalOpen} onDidDismiss={() => setIsPromotionalCardModalOpen(false)} className="promotional-modal safe-area-ios">
        <IonHeader translucent no-border>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton data-cy="promotional-modal-close-button" onClick={() => setIsPromotionalCardModalOpen(false)}>
                <IonIcon className="close-modal-button" icon="/assets/navigation/close.svg" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          {!!promotionalModalImage && <ImageFadeIn src={promotionalModalImage} alt="Promotional image" className="modal-content-image" />}
          {!!promotionalModalText && (
            <div className="promotional-card-modal-content">
              <ReactMarkdown components={{ a: getTargetBlankLink }}>{getMarkdownReadyMessage(promotionalModalText)}</ReactMarkdown>
            </div>
          )}
        </IonContent>
      </IonModal>
    </>
  );
};

export default PromotionalCard;
