import { IonContent, IonImg, IonItem, IonLabel, IonList, IonLoading, IonPage, IonThumbnail } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RootState, selectors } from '../store';
import { User } from '../store/users/types';
import CommonHeader from '../components/CommonHeader';
import { EntityReference } from '../utils/hydra';
import { extractId, getUserAvatar } from '../utils/helpers';
import { getHydratedCurrentUserBlockedAndCurrentUserIsBlockedCollections, HydratedCurrentUserBlockedAndCurrentUserIsBlockedCollections } from '../store/blockedUsers/selectors';
import './BlockedAccountsPage.scss';

interface StateProps {
  getUser: (id: EntityReference) => User | undefined;
  collection: HydratedCurrentUserBlockedAndCurrentUserIsBlockedCollections;
}

const mapStateToProps = (state: RootState): StateProps => ({
  getUser: (id: EntityReference) => selectors.entities.denormalizeEntity(state.entities, id),
  collection: getHydratedCurrentUserBlockedAndCurrentUserIsBlockedCollections(state),
});

type BlockedAccountsProps = StateProps & WithTranslation;

class BlockedAccountsPage extends Component<BlockedAccountsProps> {
  public render(): ReactNode {
    const { collection, getUser, t } = this.props;

    const blockedAccounts = collection['currentUserBlockedCollection'];

    if (blockedAccounts.isLoading) {
      return (
        <IonPage data-cy="blocked-account-page">
          <CommonHeader addIonHeader={true} title={<Trans i18nKey="profile-menu.blocked-accounts" />} />
          <IonLoading spinner="bubbles" isOpen={true} showBackdrop={false} />
        </IonPage>
      );
    }

    return (
      <IonPage data-cy="blocked-account-page">
        <CommonHeader addIonHeader={true} title={<Trans i18nKey="profile-menu.blocked-accounts" />} />
        <IonContent className="ion-padding">
          {!blockedAccounts.totalItems && (
            <IonItem lines="none" className="no-users-found-text">
              <IonLabel className="ion-text-center">{t('user.no-users-found')}</IonLabel>
            </IonItem>
          )}

          {
            <IonList mode="md" className="users-list" lines="none">
              {blockedAccounts.ids.map((id: EntityReference) => {
                const user = getUser(id);

                if (!user) {
                  return <></>;
                }

                return (
                  /* Mode ios is necessary here because of the parent (IonList) that is in mode="md" */
                  <IonItem mode="ios" className="user-item" key={extractId(user)} routerLink={extractId(user)} detail={false}>
                    <IonThumbnail className="picture-thumbnail">
                      <IonImg className="user-picture" src={getUserAvatar(user)} />
                    </IonThumbnail>
                    <IonLabel className="user-display-name">{user.name}</IonLabel>
                  </IonItem>
                );
              })}
            </IonList>
          }
        </IonContent>
      </IonPage>
    );
  }
}

export default connect(mapStateToProps)(withTranslation()(BlockedAccountsPage));
