import { EntityReference, HydratedCollection, ReferencedCollection } from '../../utils/hydra';
import { denormalizeEntity, denormalizeEntityCollection } from '../entities/selectors';
import { RootState } from '../index';
import { Chat, Message, UserChat } from './types';
import { extractId } from '../../utils/helpers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import { EntitiesState } from '../entities/types';
import { PostRequest } from '../postRequests/types';
import { UserReview } from '../users/types';
import { getEntities } from '../app/selectors';
import { ChatPageProps } from '../../pages/ChatPage';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const getCurrentUserChatsCollection = (state: RootState): ReferencedCollection => state.chats.collections.currentUserChats;
const getChatId = (state: RootState, props: ChatPageProps): EntityReference | null => props.chatId;

export const getHydratedCurrentUserChats = createDeepEqualSelector([getCurrentUserChatsCollection, getEntities], (coll: ReferencedCollection, entities: EntitiesState) =>
  denormalizeEntityCollection<UserChat>(entities, coll),
);

export const getChatByChatId = createDeepEqualSelector([getEntities, getChatId], (entities: EntitiesState, chatId: EntityReference | null): Chat | undefined => {
  if (!chatId) {
    return undefined;
  }

  return denormalizeEntity<Chat>(entities, chatId);
});

export const getUserChatByChatId = createDeepEqualSelector([getHydratedCurrentUserChats, getChatId], (coll: HydratedCollection<UserChat>, chatId): UserChat | undefined =>
  coll.items.find(userChat => extractId(userChat.chat) === chatId),
);

const getChatMessages = createDeepEqualSelector([getChatByChatId], (chat: Chat | undefined) => {
  if (!chat) {
    return undefined;
  }

  return chat.messages;
});

export const getHydratedChatMessages = createDeepEqualSelector(
  [getEntities, getChatMessages],
  (entities: EntitiesState, messages: ReferencedCollection | undefined): HydratedCollection<Message> => denormalizeEntityCollection<Message>(entities, messages),
);

export const getHydratedChatMessagesSortedByDate = createDeepEqualSelector(
  [getEntities, getChatMessages],
  (entities: EntitiesState, messages: ReferencedCollection | undefined): HydratedCollection<Message> => {
    const chatMessages = denormalizeEntityCollection<Message>(entities, messages);

    return {
      ...chatMessages,
      items: sortBy(chatMessages.items, [
        (message: Message) => {
          return message.createdAt;
        },
      ]),
    };
  },
);

const getPostRequest = createDeepEqualSelector([getChatByChatId], (chat: Chat | undefined): PostRequest | null => {
  if (!chat) {
    return null;
  }

  return chat.postRequest;
});

const getPostReviews = createDeepEqualSelector([getPostRequest], (postRequest: PostRequest | null): ReferencedCollection | undefined => {
  if (!postRequest) {
    return undefined;
  }

  return postRequest.userReviews;
});

export const getHydratedPostReviews = createDeepEqualSelector(
  [getEntities, getPostReviews],
  (entities: EntitiesState, userReviews: ReferencedCollection | undefined): HydratedCollection<UserReview> => denormalizeEntityCollection<UserReview>(entities, userReviews),
);

export function getGroupChatParticipants(state: RootState, chat: Chat): HydratedCollection<UserChat> {
  return denormalizeEntityCollection<UserChat>(state.entities, state.chats?.userChatCollections?.[extractId(chat)]);
}
