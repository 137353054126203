import { IonButton, IonLabel } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { EntityReference } from '../utils/hydra';

interface Props {
  favoriteCategoryThematics: EntityReference[];
  thematicSelectNumber: number;
  onButtonClick: () => void;
  title: string;
}

class ThematicsSubmitButton extends Component<Props> {
  public render(): ReactNode {
    const { favoriteCategoryThematics, onButtonClick, thematicSelectNumber, title } = this.props;

    const remainingThematics = thematicSelectNumber - favoriteCategoryThematics.length;

    return (
      <>
        {remainingThematics <= 0 && (
          <IonButton shape="round" className={'button-small continue-button right-corner-elem'} onClick={onButtonClick}>
            <Trans i18nKey={title} />
          </IonButton>
        )}

        {remainingThematics > 0 && (
          <IonLabel className={'remaining-thematics right-corner-elem'}>
            <Trans i18nKey={remainingThematics === thematicSelectNumber ? 'thematics.choose' : 'thematics.remaining'} values={{ remainingThematics }} />
          </IonLabel>
        )}
      </>
    );
  }
}

export default ThematicsSubmitButton;
