import { Capacitor } from '@capacitor/core';
import { AnyAction, Middleware } from 'redux';
import { setBadgeCount, resetBadgeCount } from '../../capacitor/BadgeSetter';
import { setFirebaseCrashlyticsUserId } from '../../capacitor/FirebaseCrashlytics';
import { getWindowLocation } from '../../utils/windowLocationHelper';
import { actions, RootState } from '../index';
import * as appActions from './actions';
import { fetchLoggedUser, fetchAppVersion, fetchFF } from './actions';
import * as layoutActions from '../layout/actions';
import * as thematicActions from '../thematics/actions';
import * as currentUserActions from '../whoAmI/actions';
import * as blockedUserActions from '../blockedUsers/actions';
import * as chatsActions from '../chats/actions';
import { ActionType, getType } from 'typesafe-actions';
import { registerNotificationListeners } from '../../pushNotifications';
import { registerLocalNotificationListeners } from '../../utils/notificationsHelper';
import { isTest } from '../../environment';
import { ThunkResultDispatch } from '../types';
import { REHYDRATE } from 'redux-persist';
import { extractId, isDataFresh } from '../../utils/helpers';
import { Area, CurrentUserEditFields } from '../users/types';
import { getCurrentUser } from './selectors';
import { Address } from '../posts/types';

function refreshUserData(dispatch: ThunkResultDispatch): void {
  dispatch(fetchLoggedUser());
  dispatch(blockedUserActions.fetchBlockedUsers('currentUserIsBlockedCollection'));
  dispatch(blockedUserActions.fetchBlockedUsers('currentUserBlockedCollection'));
  dispatch(chatsActions.fetchCurrentUserChats(undefined, undefined, false));
}

export const appMiddleware: Middleware<unknown, RootState> = store => next => (action: ActionType<typeof actions.app | AnyAction>) => {
  typeof action === 'function' ? action(next) : next(action);

  const dispatch = store.dispatch as ThunkResultDispatch;

  if (action.type === getType(appActions.userLoginAction.success)) {
    if (null === action.payload) {
      return;
    }

    dispatch(currentUserActions.updateWhoAmITokenAction(action.payload));
    dispatch(currentUserActions.fetchManagedUsers());
    refreshUserData(dispatch);
  }

  if (action.type === getType(appActions.resetAppForNewUserAction)) {
    if (null === action.payload) {
      return;
    }

    dispatch(appActions.setUserAreaAction(undefined));
    refreshUserData(dispatch);
  }

  if (action.type === getType(appActions.userManagedRegisterAction.success)) {
    if (null === action.payload) {
      return;
    }
    dispatch(currentUserActions.addOrUpdateManagedUserAction(action.payload));
  }

  if (action.type === getType(appActions.editUserAction.success)) {
    if (null === action.payload || !action.payload.managed) {
      return;
    }
    dispatch(currentUserActions.addOrUpdateManagedUserAction(action.payload));
  }

  if (action.type === getType(appActions.registerNotificationListenersAction)) {
    if (isTest) {
      return;
    }
    registerNotificationListeners(dispatch);
    registerLocalNotificationListeners();
  }

  if (action.type === getType(appActions.fetchLoggedUserAction.success)) {
    dispatch(layoutActions.setAppLanguageAction(action.payload.locale));

    setBadgeCount(store.getState().whoAmI.whoAmIUser?.status, store.getState().whoAmI.managedUsers);
    if ('web' !== Capacitor.getPlatform() && !isTest) {
      setFirebaseCrashlyticsUserId(extractId(action.payload));
    }
  }

  if (action.type === getType(appActions.updateCurrentUserStatus)) {
    setBadgeCount(store.getState().whoAmI.whoAmIUser?.status, store.getState().whoAmI.managedUsers);

    if (!store.getState().whoAmI) return;

    const user: Partial<CurrentUserEditFields> = {
      '@id': extractId(getCurrentUser(store.getState())),
      status: action.payload,
    };

    dispatch(appActions.updateUser(user as CurrentUserEditFields));
  }

  const toastMessagesActions = [getType(appActions.userRegisterAction.failure), getType(thematicActions.fetchThematicsAction.failure)];

  if (toastMessagesActions.indexOf(action.type) !== -1) {
    dispatch(layoutActions.setToastMessageAction(action.payload.message));
    return;
  }

  if (action.type === getType(appActions.userLoginAction.failure)) {
    if (action.payload.message === 'User not found') {
      return;
    }
    dispatch(layoutActions.setToastMessageAction(action.payload.message));
  }

  if (action.type === getType(appActions.userPasswordRecoveryAction.success)) {
    dispatch(layoutActions.setToastMessageAction('login.forgot-password-reset-msg'));
  }
  if (action.type === getType(appActions.userPasswordRecoveryAction.failure)) {
    dispatch(layoutActions.setToastMessageAction('login.error-email'));
  }

  if (action.type === getType(appActions.userPasswordResetAction.success)) {
    dispatch(layoutActions.setToastMessageAction('login.reset-password-success'));
  }
  if (action.type === getType(appActions.userPasswordResetAction.failure)) {
    if (action.payload.message === 'Expired JWT Token') {
      dispatch(layoutActions.setToastMessageAction('login.reset-password-expired-token'));
      return;
    }
    dispatch(layoutActions.setToastMessageAction(action.payload.message));
  }

  if (action.type === getType(appActions.updateUserThematicsAction.failure)) {
    dispatch(layoutActions.setToastMessageAction('thematics.update-thematics-error'));
  }

  if (action.type === getType(appActions.linkUserToThirdPartyAction.success)) {
    dispatch(layoutActions.setToastMessageAction('user.account-linked-success'));
  }
  if (action.type === getType(appActions.linkUserToThirdPartyAction.failure)) {
    dispatch(layoutActions.setToastMessageAction('user.account-linked-failed'));
  }

  if (action.type === getType(appActions.unlinkUserToFacebookAction.success)) {
    dispatch(layoutActions.setToastMessageAction('user.account-unlinked-success'));
  }
  if (action.type === getType(appActions.unlinkUserToFacebookAction.failure)) {
    dispatch(layoutActions.setToastMessageAction('user.account-unlinked-failed'));
  }

  if (action.type === getType(appActions.userHelpRequestAction.success)) {
    dispatch(layoutActions.setToastMessageAction('help.sent-help-message'));
  }
  if (action.type === getType(appActions.userHelpRequestAction.failure)) {
    dispatch(layoutActions.setToastMessageAction('help.sent-help-message-error'));
  }

  if (action.type === getType(appActions.setUserAreaAction)) {
    // The current user position changes, we want to display the promotional modal.
    localStorage.removeItem('promotionalWelcomeModalLastDisplayDate');
  }

  if (action.type === REHYDRATE || action.type === getType(appActions.setRouterDirectionAction)) {
    const { pathname } = getWindowLocation();
    const state = store.getState();
    const { currentUserBlockedCollection, currentUserIsBlockedCollection } = state.blockedUsers.collections;
    const isNecessaryRoute = ['/posts', '/search', '/me/privacy/blocked_accounts'].includes(pathname) || /\/users|chats\/[\w-]+$/.test(pathname);

    if (!currentUserIsBlockedCollection.isLoading && isNecessaryRoute) {
      dispatch(blockedUserActions.fetchBlockedUsers('currentUserIsBlockedCollection'));
    }

    if (!currentUserBlockedCollection.isLoading && !isDataFresh(currentUserBlockedCollection) && isNecessaryRoute) {
      dispatch(blockedUserActions.fetchBlockedUsers('currentUserBlockedCollection'));
    }
  }

  if (action.type === REHYDRATE) {
    dispatch(fetchAppVersion());
    dispatch(fetchFF());

    if (store.getState().app.isFullyLogged && store.getState().app.token) {
      refreshUserData(dispatch);
    }
  }

  if (action.type === getType(appActions.resetAppAction)) {
    resetBadgeCount();
  }

  const getManagedUserInfos = (): void => {
    const userAddress: Address | undefined = store.getState().app.currentUser.address;
    const userArea: Area | undefined = store.getState().app.userArea;
    const localAddress: Address | undefined = userArea?.address || userAddress;

    // FetchManagedUserInfos if user has user address or define userArea
    if (localAddress?.postalCode) {
      dispatch(layoutActions.fetchManagedUserInfos(localAddress));
    }
  };

  // When a user set a new user area or when he already has one and he refreshes the app, we verify if he is located inside a registered public sector
  if ((action.type === REHYDRATE && store.getState().app.userArea) || action.type === getType(appActions.setUserAreaAction)) {
    getManagedUserInfos();
  }
};
