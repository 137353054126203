import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { IonButton, IonContent, IonIcon, IonThumbnail, IonAlert, IonPage, IonRow, IonGrid, IonCol } from '@ionic/react';
import { FormikHelpers, FormikProps } from 'formik';
import i18next from 'i18next';
import React, { Component, MouseEventHandler, ReactNode } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import AppleLogin from '../components/AppleLogin';
import { FormValues } from '../components/DynamicForm';
import FacebookLogin from '../components/FacebookLogin';
import UserForm, { addProfilePicturePlaceholder, profilePicturePlaceholder, UserFormValues } from '../components/UserForm';
import { actions, RootState } from '../store';
import { getCurrentUser, getUserArea } from '../store/app/selectors';
import { JWTToken, Media } from '../store/app/types';
import { FacebookUserInfos, getFacebookUserInfos, getFacebookUserPicture } from '../utils/facebookAccess';
import { createNewFile, defaultEmptyEmailDomain, extractId, getBlobByUrl, setStatePromise, uploadAndValidateFiles } from '../utils/helpers';
import { CurrentUser, Area, defaultPictures, Preferences } from '../store/users/types';
import CommonHeader from '../components/CommonHeader';
import BackButton from '../components/BackButton';
import InputMediaUploader, { itemIsAMedia } from '../components/inputs/InputMediaUploader';
import { sendRegisterCompletedLog } from '../utils/analytics/analyticsHelper';
import { NOT_UNIQUE_ERROR } from '../utils/dataAccess';
import { errorIsSubmissionError } from '../utils/dataAccessError';
import './RegisterPage.scss';

export const getLocationHeader: React.FunctionComponent<MouseEventHandler> = onClose => {
  return (
    <CommonHeader
      addIonHeader={true}
      backButton={
        <IonButton fill="clear" color="dark" className="back-button" onClick={onClose}>
          <IonIcon icon="/assets/navigation/chevron-back.svg" />
        </IonButton>
      }
      title={
        <IonThumbnail className="logo-thumbnail">
          <img src="/assets/logo-indigo-gradient.svg" alt="Indigo" />
        </IonThumbnail>
      }
      className="register-location-header"
    />
  );
};

interface DispatchProps {
  register(user: UserFormValues): Promise<CurrentUser>;
  updateUser(user: UserFormValues): Promise<CurrentUser>;
  authenticateWithCredentials(username: string, password: string): Promise<JWTToken>;
  authenticateWithFacebook(accessToken: string): Promise<JWTToken>;
  authenticateWithApple(token: string): Promise<JWTToken>;
  setToastMessage(message: string | null): void;
  setIsFullyLoggedIn(isFullyLoggedIn: boolean): void;
  setIsUserFirstLoginAction(isUserFirstLogin: boolean): void;
  addUserPreferences(preferences: Preferences): Promise<CurrentUser>;
}

const propsToDispatch = {
  register: actions.app.registerUser,
  updateUser: actions.app.updateUser,
  authenticateWithCredentials: actions.app.authenticateWithCredentials,
  authenticateWithFacebook: actions.app.authenticateWithFacebook,
  authenticateWithApple: actions.app.authenticateWithApple,
  setToastMessage: actions.layout.setToastMessageAction,
  setIsFullyLoggedIn: actions.app.setIsFullyLoggedInAction,
  setIsUserFirstLoginAction: actions.app.setIsUserFirstLoginAction,
  addUserPreferences: actions.users.addUserPreferences,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

interface StateAppProps {
  currentUser: CurrentUser;
  userArea?: Area;
}

const mapStateToProps = (state: RootState): StateAppProps => ({
  currentUser: getCurrentUser(state),
  userArea: getUserArea(state),
});

export type RegisterProps = DispatchProps & StateAppProps & WithTranslation & RouteComponentProps<Record<string, never>>;

interface State {
  initialValues: UserFormValues;
  userValues: Partial<UserFormValues>;
  newsletterSubscribed: boolean;
  cguIsAccepted: boolean;
  facebookToken: string | null;
  appleToken: string | null;
  disableCguButtons: boolean;
  requestIsPending: boolean;
  emailAlreadyExists: boolean;
  registerFormSelected: boolean;
  fieldsCompleted: number;
}

function getInitialUserValues(): UserFormValues {
  return {
    email: '',
    firstname: '',
    lastname: '',
    locale: i18next.language.substring(0, 2) || 'en',
    plainPassword: '',
    confirmPassword: '',
    gender: undefined,
    newsletterSubscribed: false,
    lastTosAcceptedDate: undefined,
    avatar: null,
    address: undefined,
  };
}

const fields: Record<string, Partial<keyof UserFormValues>[]> = {
  fullForm: ['avatar', 'email', 'plainPassword', 'confirmPassword', 'gender', 'firstname', 'lastname', 'address', 'addressLocation', 'newsletterSubscribed', 'lastTosAcceptedDate'],
  formWithoutCredential: ['avatar', 'email', 'gender', 'firstname', 'lastname', 'address', 'addressLocation', 'newsletterSubscribed', 'lastTosAcceptedDate'],
};

class RegisterPage extends Component<RegisterProps, State> {
  public constructor(props: RegisterProps) {
    super(props);

    const initialValues = { ...getInitialUserValues(), address: props?.userArea?.address, addressLocation: props?.userArea?.location };

    if (extractId(this.props.currentUser, true)) {
      Object.keys(initialValues).forEach(key => {
        if (this.props.currentUser[key as keyof CurrentUser] !== undefined) {
          initialValues[key as keyof UserFormValues] = this.props.currentUser[key as keyof CurrentUser] as never;
        }
      });
    }

    this.state = {
      initialValues: initialValues,
      userValues: initialValues,
      newsletterSubscribed: true,
      cguIsAccepted: false,
      facebookToken: null,
      appleToken: null,
      disableCguButtons: false,
      requestIsPending: false,
      emailAlreadyExists: false,
      registerFormSelected: false,
      fieldsCompleted: 0,
    };
  }

  public render(): ReactNode {
    const { requestIsPending } = this.state;

    return (
      <IonPage className={`${!this.state.registerFormSelected ? 'light-page light-page-display' : ''}`} data-cy="register-page">
        <CommonHeader
          addIonHeader={true}
          className={this.state.registerFormSelected ? 'register-page-wrapper' : ''}
          backButton={<BackButton />}
          isBackButtonDisabled={requestIsPending}
          stopPropagation
          title={
            <IonThumbnail className="logo-thumbnail">
              <img src="/assets/logo-indigo-gradient.svg" alt="Indigo" />
            </IonThumbnail>
          }
        />
        {this.state.registerFormSelected ? this.registerForm() : this.selectRegister()}
      </IonPage>
    );
  }

  private selectRegister(): ReactNode {
    return (
      <IonContent className="register-page">
        <header>
          <h1>
            <Trans i18nKey="register.title" />
          </h1>
          <p>
            <Trans i18nKey="register.presentation" />
          </p>
          <p>
            <Trans i18nKey="register.presentation-2" />
          </p>
        </header>
        <div className="cgu-step bottom-section">
          <IonButton expand="block" size="large" className="create-account ion-color-facebook" shape="round" onClick={() => this.setState({ registerFormSelected: true })}>
            {this.props.t('user.register')}
          </IonButton>

          <div className="line-with-text">
            <span>
              <Trans i18nKey="register.or" />
            </span>
          </div>

          <AppleLogin onLogin={this.appleLogin} onError={this.appleError} disabled={this.state.requestIsPending} />

          <FacebookLogin
            autoLoginAction={true}
            disabled={this.state.requestIsPending}
            onLogin={this.onFBLogin}
            onLogout={this.onFBLogout}
            onError={this.props.setToastMessage}
            hideContinueAsButton={this.state.facebookToken !== null}
            loginButtonLabel={this.props.t('user.register-facebook')}
          />
          <p className="register-footer">
            <Trans i18nKey="register.already-member">
              <a key="connection" onClick={() => this.props.history.push('/login')}>
                <Trans i18nKey="register.connection" />
              </a>
            </Trans>
          </p>
        </div>
      </IonContent>
    );
  }

  private registerForm(): ReactNode {
    return (
      <>
        <div className="register-page register-page-form-wrapper">
          <IonGrid className="step">
            <IonRow>
              <IonCol size="3">
                <div className={`register-step ${this.state.fieldsCompleted >= 1 ? `validated-step` : ''}`} />
              </IonCol>
              <IonCol size="3">
                <div className={`register-step ${this.state.fieldsCompleted >= 3 ? `validated-step` : ''}`} />
              </IonCol>
              <IonCol size="3">
                <div className={`register-step ${this.state.fieldsCompleted >= 5 ? `validated-step` : ''}`} />
              </IonCol>
              <IonCol size="3">
                <div className={`register-step ${this.state.fieldsCompleted >= 7 ? `validated-step` : ''}`} />
              </IonCol>
            </IonRow>
          </IonGrid>
          <hr />
        </div>
        <IonContent className="register-page">
          <h1 className="register-page-header-title">
            <Trans i18nKey="user.register-title" />
          </h1>
          <div className="register-form">
            <UserForm
              setValidationStep={(formValues: FormikProps<FormValues>) => this.setValidationStep(formValues)}
              submitFormButton={this.submitRegisterButton}
              onAfterSubmitFormSuccess={this.afterSubmitFormSuccess}
              onSubmitErrors={this.onSubmitErrors}
              initialValues={this.state.initialValues}
              fields={this.getFields()}
              postUserAction={this.submitForm}
              locationModalHeader={getLocationHeader}
              fieldsToTouchOnLoad={this.state?.initialValues?.address ? ['address'] : undefined} // Since the address can be autocomplete, we want it to be validated at the form initialization
              overriddenFields={[
                {
                  name: 'avatar',
                  label: this.props.t('register.choose-profile-picture'),
                  formComponent: InputMediaUploader,
                  options: {
                    maxFiles: 1,
                    multiple: false,
                    isMediaUploaderField: false,
                    picturePlaceholder: profilePicturePlaceholder,
                    addPicturePlaceholder: addProfilePicturePlaceholder,
                    defaultPictures: defaultPictures,
                    displayValidationIcon: true,
                  },
                  itemLines: 'none',
                },
              ]}
            />
            <IonAlert
              isOpen={this.state.emailAlreadyExists}
              onDidDismiss={() => this.setState({ emailAlreadyExists: false, requestIsPending: false })}
              header={this.props.t('register.your-account-already-exists')}
              message={this.props.t('register.stay-on-register-or-go-login')}
              buttons={[
                {
                  text: this.props.t('register.go-to-login'),
                  handler: () => {
                    this.props.history.push('/login');
                  },
                },
              ]}
            />
          </div>
        </IonContent>
      </>
    );
  }

  private submitRegisterButton: React.FunctionComponent<FormikProps<FormValues>> = ({ isValid, isSubmitting, isValidating, values }: FormikProps<FormValues>) => {
    const disabled = this.state.requestIsPending || !isValid || isValidating || isSubmitting || !values.lastTosAcceptedDate;
    return (
      <IonButton type="submit" shape="round" className="create-account ion-color-facebook" fill="solid" disabled={disabled}>
        {!this.state.requestIsPending ? i18next.t('register.register') : i18next.t('common.loading')}
      </IonButton>
    );
  };

  private getFields(): Partial<keyof UserFormValues>[] {
    if (this.state.facebookToken) {
      return fields.formWithoutCredential;
    }
    return fields.fullForm;
  }

  private setValidationStep(formValues: FormikProps<FormValues>): void {
    const total = (formValues.values.lastTosAcceptedDate ? 1 : 0) + (formValues.values.facebookAccessToken?.length ? 2 : 0);
    this.setState({ fieldsCompleted: 6 - (Object.keys(formValues.errors).length - total) });
  }

  private confirmRegistrationAndLogUser = (): void => {
    this.props.setIsUserFirstLoginAction(true);
    this.props.setIsFullyLoggedIn(true);
  };

  private successToastAndEventLog = (): void => {
    sendRegisterCompletedLog();
    this.props.setToastMessage('user.account-created');
  };

  private onFBLogin = async (accessToken: string, fbData: FacebookUserInfos): Promise<void> => {
    this.setState({ disableCguButtons: true, requestIsPending: true });
    try {
      await this.props.authenticateWithFacebook(accessToken);
      this.confirmRegistrationAndLogUser();
      return;
    } catch (e) {
      // Auth error: the account does not already exist
    }

    try {
      const userPicture = await getFacebookUserPicture(fbData.id);
      await this.addFacebookPicture(userPicture.url);
    } catch (e) {
      // error when loading image
    }

    const userFullInfos = await getFacebookUserInfos(fbData.id, accessToken);
    const newFormValues = {
      email: userFullInfos.email,
      firstname: userFullInfos.first_name,
      lastname: userFullInfos.last_name,
      facebookAccessToken: accessToken,
    };

    await setStatePromise(this, {
      facebookToken: accessToken,
      initialValues: Object.assign({}, this.state.initialValues, newFormValues),
      disableCguButtons: false,
      requestIsPending: false,
      registerFormSelected: true,
    });
  };

  private appleLogin = async (connectData: SignInWithAppleResponse): Promise<void> => {
    this.setState({ disableCguButtons: true, requestIsPending: true });
    try {
      await this.props.authenticateWithApple(connectData.response.identityToken);
      this.confirmRegistrationAndLogUser();
    } catch (e) {
      // register new user
      // Caution: this is an ugly hack for the apple test account
      // TODO Remove it
      const newFormValues = {
        email: connectData.response.email ?? Math.random().toString(36).substr(2, 9) + defaultEmptyEmailDomain,
        firstname: connectData.response.givenName ?? 'Apple',
        lastname: connectData.response.familyName ?? 'account',
        appleAccessToken: connectData.response.identityToken,
        gender: 'other',
        lastTosAcceptedDate: null,
        avatar: null,
        registerFormSelected: true,
      };
      this.setState(
        {
          initialValues: Object.assign({}, this.state.initialValues, newFormValues),
        },
        () => {
          this.registerApple(this.state.initialValues);
        },
      );
    }
  };

  private registerApple = async (user: UserFormValues): Promise<void> => {
    await this.props.register(user);

    if (user.appleAccessToken) {
      await this.props.authenticateWithApple(user.appleAccessToken);
    }

    this.successToastAndEventLog();
    this.confirmRegistrationAndLogUser();
  };

  private appleError = (error: string | null): void => {
    if (error === 'The operation couldn’t be completed. (com.apple.AuthenticationServices.AuthorizationError error 1000.)') {
      this.props.setToastMessage('login.apple-simulator-error');
      return;
    }
    this.props.setToastMessage(error);
  };

  private addFacebookPicture = async (imageUrl: string): Promise<void> => {
    const blob: Blob = await getBlobByUrl(imageUrl);
    const imageFile = createNewFile([blob], 'fbImage', { type: 'image/jpeg' });
    return setStatePromise(this, { initialValues: { ...this.state.initialValues, avatar: [imageFile] } });
  };

  private onFBLogout = (): void => {
    this.setState({ facebookToken: null });
  };

  private submitForm = async (values: UserFormValues): Promise<CurrentUser> => {
    if (this.state.requestIsPending) {
      return this.state.userValues as CurrentUser;
    }

    this.setState({ requestIsPending: true });
    this.updateUserValues(values);
    return await this.register(this.state.userValues);
  };

  private tryLogin = async (user: UserFormValues): Promise<void> => {
    if (user.email && user.plainPassword) {
      await this.props.authenticateWithCredentials(user.email, user.plainPassword);
    } else {
      this.props.setToastMessage(i18next.t('register.account-exists-unable-to-log'));
      throw 'unable_to_connect';
    }

    this.props.setToastMessage(i18next.t('register.account-exists-logged-in'));
    this.confirmRegistrationAndLogUser();
  };

  private register = async (user: UserFormValues): Promise<CurrentUser> => {
    let currentUser: CurrentUser = user as CurrentUser;
    try {
      currentUser = await this.props.register(user);
    } catch (e) {
      this.setState({ requestIsPending: false });

      // If the error is "The email already exists in our DB, we try to log in the user
      if (!errorIsSubmissionError(e) || !e.pathHasViolation('email', NOT_UNIQUE_ERROR)) {
        throw e;
      }

      try {
        await this.tryLogin(user);
      } catch (e) {
        this.setState({ emailAlreadyExists: true });
        throw e;
      }

      return currentUser;
    }

    if (this.state.facebookToken) {
      await this.props.authenticateWithFacebook(this.state.facebookToken);
      return currentUser;
    }

    if (this.state.appleToken) {
      await this.props.authenticateWithApple(this.state.appleToken);
      return currentUser;
    }

    if (user.email && user.plainPassword) {
      await this.props.authenticateWithCredentials(user.email, user.plainPassword);
    }

    return currentUser;
  };

  private updateUserValues(values: UserFormValues): void {
    const setUserValue = <T extends UserFormValues, K extends keyof T>(user: T, key: K, value: T[K]): void => {
      user[key] = value;
    };

    let user: UserFormValues = { '@id': values['@id'] as string };

    if (this.state.userValues !== null) {
      user = this.state.userValues;
    }

    if (this.state.facebookToken !== null) {
      setUserValue(user, 'facebookAccessToken', this.state.facebookToken || undefined);
    }

    if (this.state.appleToken !== null) {
      setUserValue(user, 'appleAccessToken', this.state.appleToken || undefined);
    }

    this.getFields().forEach((field: keyof UserFormValues) => {
      if (field !== 'avatar') {
        //If field lastTosAcceptedDate and value is true, update lastTosAcceptedDate boolean to date
        if (field === 'lastTosAcceptedDate' && values[field]) {
          setUserValue(user, field, new Date());
        } else {
          setUserValue(user, field, values[field]);
        }
      }
    });

    this.setState({ userValues: user, initialValues: Object.assign({}, this.state.initialValues, values) });
  }

  private uploadAvatar = async (values: UserFormValues, actions: FormikHelpers<FormValues>): Promise<void> => {
    try {
      await uploadAndValidateFiles(values, actions, 'avatar', this.props.t);
    } catch (e) {
      console.error(e);
    }

    try {
      const filteredImageValues = (values.avatar as (File | Media)[])?.filter((image: File | Media) => itemIsAMedia(image));
      if (!filteredImageValues?.[0]) {
        await this.props.addUserPreferences({ userDeclinedAvatarUpload: true });
        return;
      }

      const currentUser = await this.props.updateUser({ '@id': this.props.currentUser['@id'], avatar: filteredImageValues[0] as Media, images: filteredImageValues as Media[] });
      this.updateUserValues({ images: currentUser.images, avatar: currentUser.avatar });
    } catch (e) {
      console.error(e);
    }
  };

  private afterSubmitFormSuccess = async (values: UserFormValues, actions: FormikHelpers<FormValues>): Promise<void> => {
    await actions.setSubmitting(false);

    await this.uploadAvatar(values, actions);

    if (!values.newsletterSubscribed) {
      await this.props.addUserPreferences({ userDeclinedNewsletterSubscription: true });
    }
    if (!this.state.requestIsPending) {
      return;
    }
    this.setState({ requestIsPending: false });
    this.successToastAndEventLog();
    this.confirmRegistrationAndLogUser();
  };

  private onSubmitErrors = async (actions: FormikHelpers<FormValues>, errors: Record<string, string>): Promise<void> => {
    this.setState({ requestIsPending: false });

    const editedErrors: Record<string, string> = {};
    if (!errors || !errors['_error']) {
      return;
    }

    // Some errors do not have the same name as the form fields, so we edit them
    Object.keys(errors).forEach((key: string) => {
      const dotIndex = key.indexOf('.');
      if (dotIndex < 0) {
        return;
      }

      const croppedKey = key.substring(0, dotIndex);
      // If the key is a field name, we concat all the errors starting with this name
      if ((this.getFields() as string[]).includes(croppedKey)) {
        editedErrors[croppedKey] = `${editedErrors[croppedKey] ? editedErrors[croppedKey] + '\n' : ''} ${key}: ${errors[key]} `;
      }

      // If the key starts by personalData, we crop it
      if (croppedKey === 'personalData') {
        const subName = key.substring(dotIndex + 1);
        editedErrors[subName] = errors[key];
      }
    });

    actions.setStatus({ ...errors, ...editedErrors });
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(RegisterPage)));
