import { EntityReference, HydraEntity, ReferencedCollection } from '../../utils/hydra';
import { User } from '../users/types';
import { PostRequest } from '../postRequests/types';
import { Post } from '../posts/types';

export interface ChatState {
  collections: {
    currentUserChats: ReferencedCollection; // UserChat[]
    currentUserCompletedChats: ReferencedCollection; // UserChat[]
  };
  userChatCollections: {
    [chatId: string]: ReferencedCollection; // UserChat[] - The participants of the chat
  };
  itemIsLoading: boolean;
}

export type ChatCollectionName = keyof NonNullable<ChatState['collections']>;
export type ChatType = 'post_request' | 'direct' | 'post_group_private' | 'post_group_public';
export type UserChatState = 'archived' | 'active';

export interface Chat extends HydraEntity {
  type: ChatType;
  createdAt: string;
  lastActionDate: string;
  properties: {
    firstUsers: User[];
    lastMessage: Message | null;
  };
  postRequest: PostRequest | null;
  post: Post | null;

  // Client added data
  messages?: ReferencedCollection; // Message[] from the server
  newMessages?: Message[]; // From the client, when the user sends a message or receive a new one
}

export interface Message extends HydraEntity {
  text: string;
  createdBy: Partial<User>;
  createdAt: string;
  chatId: EntityReference;
}

export interface UserChat extends HydraEntity {
  chat: Chat;
  lastReadMessageDate: Date;
  user?: User;
  state?: UserChatState;
}

export const chatFilters = [
  {
    title: 'state',
    filters: ['active', 'archived'],
  },
  {
    title: 'status',
    filters: ['read', 'unread'],
  },
  {
    title: 'exchange-status',
    filters: ['fully_requested', 'deleted', 'finished', 'pending'],
  },
  {
    title: 'type-of-exchange',
    filters: ['object', 'service', 'direct-chat'],
  },
  {
    title: 'my-adverts',
    filters: ['offer', 'need'],
  },
  {
    title: 'respond-to-ad',
    filters: ['ask', 'help'],
  },
];

export const chatsQueryFiltersKeys = ['postId', 'search', 'selectedFilters'];

export interface ChatQueryFilters {
  postId?: EntityReference;
  search?: string;
  selectedFilters: string[];
}
