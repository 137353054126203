/* eslint-disable */

const AppStore = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="115" height="37" viewBox="0 0 115 37" fill="none">
    <g clipPath="url(#clip0_11246_229032)">
      <path
        d="M106.213 0.318537H9.51886C9.18884 0.318537 8.86279 0.318537 8.53365 0.320337C8.25812 0.322137 7.9848 0.327366 7.70663 0.331767C7.10232 0.338878 6.49943 0.392043 5.9032 0.490798C5.30781 0.591704 4.73108 0.78194 4.19249 1.05507C3.65457 1.33051 3.16305 1.68842 2.73578 2.11582C2.30626 2.54201 1.94822 3.03462 1.67543 3.57473C1.40189 4.11376 1.21221 4.69138 1.11296 5.28763C1.01276 5.88314 0.958837 6.48551 0.951684 7.08935C0.943332 7.36528 0.94245 7.64212 0.938049 7.91808V28.7199C0.94245 28.9994 0.943332 29.2701 0.951684 29.5496C0.958839 30.1534 1.01276 30.7558 1.11296 31.3512C1.21194 31.9478 1.40162 32.5258 1.67543 33.065C1.94809 33.6034 2.30618 34.094 2.73578 34.5178C3.16143 34.9471 3.65327 35.3053 4.19249 35.5786C4.73107 35.8525 5.30775 36.0438 5.9032 36.1463C6.49953 36.2443 7.10236 36.2974 7.70663 36.3054C7.9848 36.3115 8.25812 36.315 8.53365 36.315C8.86278 36.3168 9.18886 36.3168 9.51886 36.3168H106.213C106.537 36.3168 106.866 36.3168 107.189 36.315C107.463 36.315 107.744 36.3115 108.019 36.3054C108.622 36.2979 109.224 36.2447 109.819 36.1463C110.416 36.0431 110.995 35.8518 111.536 35.5786C112.075 35.3051 112.566 34.947 112.991 34.5178C113.42 34.0923 113.779 33.6021 114.055 33.065C114.326 32.5254 114.514 31.9475 114.612 31.3512C114.712 30.7557 114.768 30.1535 114.779 29.5496C114.782 29.2701 114.782 28.9994 114.782 28.7199C114.789 28.393 114.789 28.0678 114.789 27.7356V8.90065C114.789 8.57108 114.789 8.24414 114.782 7.91808C114.782 7.64212 114.782 7.36528 114.779 7.08931C114.768 6.48543 114.712 5.88319 114.612 5.2876C114.514 4.69168 114.326 4.11411 114.055 3.57469C113.499 2.49211 112.618 1.6109 111.536 1.05499C110.995 0.782526 110.416 0.592341 109.819 0.490717C109.224 0.391526 108.622 0.338343 108.019 0.331641C107.744 0.327249 107.463 0.321975 107.189 0.32022C106.866 0.31842 106.537 0.318537 106.213 0.318537Z"
        fill="#A6A6A6"
      />
      <path
        d="M8.53751 35.527C8.26332 35.527 7.99574 35.5234 7.7237 35.5173C7.16013 35.51 6.59788 35.4609 6.04155 35.3705C5.52281 35.2812 5.02028 35.115 4.55056 34.8775C4.08514 34.6419 3.66064 34.333 3.29332 33.9626C2.92068 33.5965 2.61044 33.172 2.3749 32.7058C2.13676 32.2365 1.97196 31.7335 1.88622 31.2144C1.79364 30.6565 1.74355 30.0924 1.73638 29.5269C1.73068 29.3371 1.72321 28.7052 1.72321 28.7052V7.91566C1.72321 7.91566 1.73116 7.29342 1.73643 7.11061C1.74329 6.54605 1.79309 5.98283 1.88539 5.42583C1.97128 4.90522 2.13622 4.4008 2.37449 3.93001C2.60916 3.46412 2.91767 3.03926 3.28806 2.67191C3.65804 2.30108 4.0839 1.99058 4.55012 1.75174C5.01876 1.51494 5.52034 1.34992 6.03803 1.26221C6.59619 1.17093 7.16039 1.12157 7.72591 1.11457L8.53796 1.10358H107.184L108.006 1.11501C108.566 1.12167 109.125 1.17058 109.678 1.26133C110.201 1.35014 110.708 1.51631 111.182 1.75437C112.116 2.23571 112.876 2.99723 113.355 3.93221C113.59 4.39974 113.752 4.90005 113.837 5.41616C113.931 5.97774 113.983 6.54538 113.994 7.11457C113.996 7.36944 113.996 7.64321 113.996 7.91566C114.003 8.25315 114.003 8.57437 114.003 8.89823V27.7331C114.003 28.0601 114.003 28.3791 113.996 28.7008C113.996 28.9934 113.996 29.2615 113.993 29.5375C113.982 30.0965 113.931 30.654 113.839 31.2055C113.755 31.7285 113.591 32.2354 113.353 32.7085C113.116 33.1696 112.807 33.5905 112.439 33.9555C112.071 34.3279 111.646 34.6386 111.179 34.8757C110.707 35.1151 110.201 35.2819 109.678 35.3705C109.122 35.4614 108.56 35.5105 107.996 35.5173C107.733 35.5234 107.457 35.527 107.189 35.527L106.213 35.5288L8.53751 35.527Z"
        fill="white"
      />
      <path
        d="M23.2303 18.5859C23.24 17.8347 23.4395 17.0982 23.8103 16.4449C24.1811 15.7916 24.7111 15.2426 25.3511 14.8492C24.9445 14.2686 24.4082 13.7908 23.7847 13.4537C23.1612 13.1166 22.4677 12.9295 21.7592 12.9074C20.248 12.7487 18.7829 13.8117 18.0128 13.8117C17.2278 13.8117 16.0421 12.9231 14.7653 12.9494C13.9394 12.9761 13.1345 13.2162 12.4291 13.6465C11.7236 14.0767 11.1416 14.6823 10.7398 15.4044C8.99933 18.4178 10.2976 22.8465 11.9649 25.2824C12.799 26.4751 13.7739 27.8075 15.0496 27.7602C16.2979 27.7084 16.7641 26.9642 18.2709 26.9642C19.7637 26.9642 20.2011 27.7602 21.5026 27.7302C22.8421 27.7084 23.6861 26.5321 24.491 25.3281C25.0903 24.4782 25.5515 23.5389 25.8574 22.5449C25.0792 22.2158 24.4152 21.6649 23.948 20.9608C23.4808 20.2568 23.2312 19.4308 23.2303 18.5859Z"
        fill="#100F0D"
      />
      <path
        d="M20.7711 11.308C21.5015 10.4313 21.8613 9.30438 21.7741 8.16663C20.6584 8.28382 19.6277 8.8171 18.8875 9.6602C18.5256 10.0721 18.2484 10.5513 18.0718 11.0703C17.8951 11.5894 17.8226 12.1382 17.8582 12.6853C18.4163 12.6911 18.9684 12.5701 19.473 12.3315C19.9775 12.093 20.4214 11.743 20.7711 11.308Z"
        fill="#100F0D"
      />
      <path d="M33.0256 13.5467V8.93579H31.3364V8.17688H35.5453V8.93578H33.8597V13.5467H33.0256Z" fill="#100F0D" />
      <path
        d="M39.424 12.4541C39.3154 12.8245 39.08 13.1449 38.759 13.3592C38.4379 13.5735 38.0518 13.6682 37.6681 13.6265C37.4011 13.6336 37.1358 13.5824 36.8905 13.4767C36.6453 13.3709 36.426 13.213 36.2479 13.014C36.0698 12.815 35.9371 12.5796 35.8591 12.3242C35.7811 12.0688 35.7596 11.7994 35.7961 11.5348C35.7605 11.2695 35.7824 10.9995 35.8603 10.7433C35.9381 10.4871 36.0701 10.2507 36.2472 10.0499C36.4244 9.84915 36.6426 9.6888 36.8872 9.57972C37.1317 9.47064 37.3968 9.41537 37.6646 9.41765C38.7921 9.41765 39.4724 10.188 39.4724 11.4606V11.7396H36.6108V11.7844C36.5983 11.9331 36.617 12.0828 36.6657 12.2239C36.7144 12.365 36.792 12.4943 36.8936 12.6036C36.9953 12.7129 37.1186 12.7998 37.2557 12.8586C37.3929 12.9175 37.5408 12.947 37.69 12.9454C37.8813 12.9683 38.0752 12.9339 38.2468 12.8464C38.4185 12.7589 38.5603 12.6224 38.6542 12.4541L39.424 12.4541ZM36.6108 11.1481H38.6577C38.6678 11.0121 38.6493 10.8755 38.6034 10.7471C38.5576 10.6186 38.4853 10.5012 38.3914 10.4023C38.2975 10.3035 38.1839 10.2254 38.0579 10.173C37.932 10.1206 37.7965 10.0952 37.6602 10.0983C37.5218 10.0966 37.3846 10.1226 37.2564 10.1747C37.1283 10.2269 37.0119 10.3041 36.9141 10.402C36.8163 10.4998 36.7391 10.6162 36.687 10.7444C36.6349 10.8725 36.609 11.0098 36.6108 11.1481ZM37.1838 8.81827L38.1181 7.53821H39.0558L38.01 8.81827H37.1838Z"
        fill="#100F0D"
      />
      <path d="M40.583 7.91321H41.3828V13.5476H40.583V7.91321Z" fill="#100F0D" />
      <path
        d="M46.1228 12.4541C46.0141 12.8245 45.7787 13.1449 45.4577 13.3592C45.1367 13.5735 44.7505 13.6682 44.3668 13.6265C44.0998 13.6336 43.8345 13.5824 43.5893 13.4767C43.344 13.3709 43.1247 13.213 42.9466 13.014C42.7685 12.815 42.6358 12.5796 42.5578 12.3242C42.4798 12.0688 42.4583 11.7994 42.4948 11.5348C42.4593 11.2695 42.4812 10.9995 42.559 10.7433C42.6368 10.4871 42.7688 10.2507 42.946 10.0499C43.1231 9.84915 43.3414 9.6888 43.5859 9.57972C43.8304 9.47064 44.0955 9.41537 44.3633 9.41765C45.4909 9.41765 46.1711 10.188 46.1711 11.4606V11.7396H43.3095V11.7844C43.297 11.9331 43.3157 12.0828 43.3644 12.2239C43.4131 12.365 43.4907 12.4943 43.5924 12.6036C43.694 12.7129 43.8173 12.7998 43.9544 12.8586C44.0916 12.9175 44.2395 12.947 44.3888 12.9454C44.5801 12.9683 44.7739 12.9339 44.9456 12.8464C45.1172 12.7589 45.259 12.6224 45.3529 12.4541L46.1228 12.4541ZM43.3095 11.1481H45.3564C45.3665 11.0121 45.348 10.8755 45.3021 10.7471C45.2563 10.6186 45.184 10.5012 45.0901 10.4023C44.9962 10.3035 44.8826 10.2254 44.7566 10.173C44.6307 10.1206 44.4952 10.0952 44.3589 10.0983C44.2205 10.0966 44.0833 10.1226 43.9551 10.1747C43.827 10.2269 43.7106 10.3041 43.6128 10.402C43.515 10.4998 43.4378 10.6162 43.3857 10.7444C43.3336 10.8725 43.3077 11.0098 43.3095 11.1481ZM43.8825 8.81827L44.8168 7.53821H45.7545L44.7087 8.81827H43.8825Z"
        fill="#100F0D"
      />
      <path
        d="M49.8983 10.8187C49.8473 10.6085 49.7228 10.4236 49.5472 10.2974C49.3716 10.1711 49.1567 10.112 48.9412 10.1305C48.2715 10.1305 47.862 10.6438 47.862 11.5073C47.862 12.3857 48.2751 12.9104 48.9412 12.9104C49.1546 12.9351 49.3696 12.881 49.5458 12.7584C49.7221 12.6357 49.8474 12.4528 49.8983 12.2442H50.6761C50.6247 12.6546 50.415 13.0286 50.0917 13.2865C49.7684 13.5444 49.3572 13.6659 48.9456 13.6249C48.6747 13.6335 48.4051 13.5827 48.1558 13.4762C47.9065 13.3697 47.6835 13.21 47.5023 13.0083C47.3212 12.8066 47.1863 12.5678 47.1071 12.3085C47.0279 12.0492 47.0063 11.7758 47.0438 11.5073C47.0082 11.2408 47.0311 10.9697 47.1111 10.713C47.1911 10.4563 47.3261 10.2201 47.5068 10.021C47.6875 9.82184 47.9094 9.66454 48.1572 9.56006C48.405 9.45558 48.6725 9.40643 48.9412 9.41605C49.3562 9.37783 49.7696 9.50302 50.0936 9.76499C50.4176 10.027 50.6266 10.405 50.6761 10.8187L49.8983 10.8187Z"
        fill="#100F0D"
      />
      <path
        d="M51.7378 7.91321H52.5306V10.1464H52.5938C52.7002 9.91303 52.8759 9.71811 53.097 9.5882C53.3181 9.45829 53.5739 9.39972 53.8295 9.42047C54.0256 9.40979 54.2217 9.44254 54.4038 9.51637C54.5858 9.59021 54.7493 9.70331 54.8826 9.84761C55.0159 9.9919 55.1157 10.1638 55.1749 10.3512C55.2342 10.5385 55.2513 10.7365 55.2251 10.9312V13.5476H54.4245V11.1285C54.4245 10.4812 54.123 10.1534 53.5579 10.1534C53.4205 10.1422 53.2822 10.1611 53.1528 10.2088C53.0234 10.2565 52.906 10.332 52.8088 10.4298C52.7116 10.5277 52.637 10.6456 52.5901 10.7753C52.5433 10.9051 52.5253 11.0434 52.5376 11.1808V13.5476H51.7378L51.7378 7.91321Z"
        fill="#100F0D"
      />
      <path
        d="M56.2305 12.4017C56.2305 11.6723 56.7736 11.2517 57.7377 11.192L58.8354 11.1287V10.7789C58.8354 10.3509 58.5524 10.1092 58.0058 10.1092C57.5593 10.1092 57.2499 10.2731 57.1612 10.5596H56.3869C56.4686 9.86357 57.1234 9.41711 58.0427 9.41711C59.0586 9.41711 59.6312 9.92289 59.6312 10.7789V13.5478H58.8618V12.9783H58.7985C58.6701 13.1826 58.4897 13.3491 58.2759 13.4609C58.062 13.5727 57.8224 13.6257 57.5813 13.6145C57.4112 13.6322 57.2392 13.6141 57.0766 13.5613C56.9139 13.5084 56.7641 13.4221 56.6368 13.3078C56.5095 13.1936 56.4076 13.0539 56.3376 12.8978C56.2676 12.7418 56.2311 12.5728 56.2305 12.4017ZM58.8354 12.0555V11.7167L57.8458 11.7799C57.2877 11.8173 57.0346 12.0071 57.0346 12.3644C57.0346 12.7291 57.351 12.9414 57.7861 12.9414C57.9136 12.9543 58.0423 12.9414 58.1647 12.9035C58.2871 12.8656 58.4007 12.8035 58.4986 12.7209C58.5965 12.6382 58.6767 12.5367 58.7346 12.4224C58.7925 12.3081 58.8268 12.1833 58.8354 12.0555Z"
        fill="#100F0D"
      />
      <path
        d="M60.8833 9.4966H61.6532V10.118H61.713C61.7991 9.90226 61.9515 9.71942 62.1481 9.59571C62.3446 9.47199 62.5754 9.41375 62.8071 9.42937C62.9269 9.42775 63.0465 9.43895 63.164 9.46276V10.252C63.0172 10.2207 62.8676 10.2045 62.7175 10.2037C62.5856 10.1918 62.4527 10.2077 62.3273 10.2503C62.2018 10.2929 62.0867 10.3612 61.9893 10.451C61.8919 10.5407 61.8143 10.6498 61.7616 10.7713C61.7088 10.8928 61.6821 11.0239 61.6831 11.1564V13.5491H60.8833L60.8833 9.4966Z"
        fill="#100F0D"
      />
      <path
        d="M63.8997 13.955H64.7179C64.7856 14.2489 65.124 14.4388 65.6636 14.4388C66.3298 14.4388 66.7244 14.1224 66.7244 13.5867V12.8089H66.6646C66.5392 13.0266 66.356 13.2054 66.1353 13.3256C65.9146 13.4457 65.6649 13.5025 65.414 13.4896C64.3796 13.4896 63.7397 12.6898 63.7397 11.4766C63.7397 10.2409 64.3866 9.42968 65.4219 9.42968C65.6791 9.42073 65.9339 9.48263 66.1583 9.60865C66.3827 9.73466 66.5682 9.91992 66.6945 10.1442H66.7578V9.49692H67.5241V13.5827C67.5241 14.5095 66.7982 15.0975 65.6521 15.0975C64.6397 15.0975 63.985 14.6436 63.8997 13.955ZM66.7393 11.4726C66.7393 10.6653 66.3219 10.1482 65.6407 10.1482C64.9526 10.1482 64.5659 10.6654 64.5659 11.4726C64.5659 12.2803 64.9526 12.7975 65.6407 12.7975C66.3263 12.7975 66.7393 12.2843 66.7393 11.4726Z"
        fill="#100F0D"
      />
      <path
        d="M72.2253 12.4537C72.1167 12.824 71.8812 13.1444 71.5602 13.3587C71.2392 13.5731 70.8531 13.6677 70.4693 13.6261C70.2024 13.6331 69.937 13.582 69.6918 13.4762C69.4466 13.3704 69.2273 13.2126 69.0492 13.0135C68.871 12.8145 68.7384 12.5791 68.6604 12.3237C68.5823 12.0683 68.5608 11.7989 68.5974 11.5344C68.5618 11.269 68.5837 10.9991 68.6615 10.7429C68.7394 10.4867 68.8713 10.2502 69.0485 10.0494C69.2257 9.84868 69.4439 9.68833 69.6884 9.57925C69.933 9.47017 70.1981 9.4149 70.4658 9.41718C71.5934 9.41718 72.2737 10.1875 72.2737 11.4601V11.7391H69.4121V11.7839C69.3996 11.9327 69.4182 12.0823 69.4669 12.2234C69.5157 12.3645 69.5933 12.4938 69.6949 12.6031C69.7965 12.7124 69.9199 12.7993 70.057 12.8581C70.1942 12.917 70.3421 12.9465 70.4913 12.9449C70.6826 12.9679 70.8764 12.9334 71.0481 12.8459C71.2198 12.7584 71.3616 12.6219 71.4554 12.4536L72.2253 12.4537ZM69.412 11.1477H71.4589C71.469 11.0117 71.4505 10.8751 71.4047 10.7466C71.3588 10.6182 71.2866 10.5007 71.1926 10.4019C71.0987 10.303 70.9851 10.2249 70.8592 10.1725C70.7332 10.1202 70.5978 10.0947 70.4614 10.0979C70.3231 10.0961 70.1858 10.1221 70.0577 10.1743C69.9296 10.2264 69.8132 10.3037 69.7154 10.4015C69.6176 10.4994 69.5404 10.6158 69.4883 10.7439C69.4362 10.8721 69.4102 11.0094 69.412 11.1477Z"
        fill="#100F0D"
      />
      <path
        d="M73.3477 9.4966H74.1176V10.118H74.1774C74.2635 9.90226 74.4159 9.71942 74.6125 9.59571C74.809 9.47199 75.0398 9.41375 75.2715 9.42937C75.3913 9.42775 75.5109 9.43895 75.6284 9.46276V10.252C75.4816 10.2207 75.332 10.2045 75.1819 10.2037C75.05 10.1918 74.917 10.2077 74.7916 10.2503C74.6662 10.2929 74.5511 10.3612 74.4537 10.4509C74.3563 10.5407 74.2787 10.6498 74.2259 10.7713C74.1732 10.8927 74.1465 11.0239 74.1474 11.1564V13.5491H73.3477V9.4966Z"
        fill="#100F0D"
      />
      <path
        d="M78.5098 11.5196C78.5098 10.2391 79.168 9.42793 80.1919 9.42793C80.4452 9.41626 80.6965 9.47692 80.9166 9.60282C81.1367 9.72872 81.3163 9.91465 81.4346 10.1389H81.4944V7.91321H82.2942V13.5476H81.5278V12.9074H81.4645C81.3371 13.1301 81.1511 13.3137 80.9268 13.4383C80.7025 13.563 80.4483 13.6238 80.1919 13.6144C79.161 13.6144 78.5098 12.8032 78.5098 11.5196ZM79.3359 11.5196C79.3359 12.3792 79.7411 12.8964 80.4187 12.8964C81.0928 12.8964 81.5094 12.3717 81.5094 11.5231C81.5094 10.6786 81.0884 10.1464 80.4187 10.1464C79.7455 10.1464 79.3359 10.6672 79.3359 11.5196Z"
        fill="#100F0D"
      />
      <path
        d="M83.375 12.4017C83.375 11.6723 83.9181 11.2517 84.8823 11.192L85.98 11.1287V10.7789C85.98 10.3509 85.697 10.1092 85.1503 10.1092C84.7039 10.1092 84.3945 10.2731 84.3057 10.5596H83.5314C83.6132 9.86357 84.2679 9.41711 85.1872 9.41711C86.2032 9.41711 86.7762 9.92289 86.7762 10.7789V13.5478H86.0063V12.9783H85.943C85.8146 13.1826 85.6343 13.3491 85.4204 13.4609C85.2066 13.5727 84.9669 13.6257 84.7258 13.6145C84.5557 13.6322 84.3838 13.6141 84.2211 13.5613C84.0584 13.5084 83.9086 13.4221 83.7813 13.3078C83.6541 13.1936 83.5521 13.0539 83.4822 12.8978C83.4122 12.7418 83.3757 12.5728 83.375 12.4017ZM85.98 12.0555V11.7167L84.9904 11.7799C84.4323 11.8173 84.1792 12.0071 84.1792 12.3644C84.1792 12.7291 84.4956 12.9413 84.9306 12.9413C85.0581 12.9542 85.1869 12.9414 85.3093 12.9035C85.4317 12.8656 85.5452 12.8035 85.6431 12.7209C85.741 12.6382 85.8213 12.5367 85.8791 12.4224C85.937 12.3081 85.9713 12.1833 85.98 12.0555Z"
        fill="#100F0D"
      />
      <path
        d="M88.0293 9.49542H88.7992V10.1392H88.8589C88.9603 9.90797 89.1313 9.71414 89.3481 9.58473C89.5649 9.45531 89.8166 9.39674 90.0683 9.4172C90.2654 9.40237 90.4634 9.4321 90.6476 9.50419C90.8317 9.57627 90.9972 9.68886 91.132 9.83361C91.2667 9.97836 91.3671 10.1516 91.4258 10.3404C91.4844 10.5292 91.4999 10.7288 91.4709 10.9244V13.5478H90.6712V11.1253C90.6712 10.474 90.3882 10.1502 89.7967 10.1502C89.6628 10.1439 89.5292 10.1667 89.4049 10.217C89.2807 10.2672 89.1688 10.3437 89.0769 10.4413C88.985 10.5388 88.9152 10.6551 88.8725 10.7821C88.8297 10.9091 88.8149 11.0439 88.8291 11.1771V13.5479H88.0293V9.49542Z"
        fill="#100F0D"
      />
      <path
        d="M94.1894 9.41711C95.1008 9.41711 95.6966 9.84116 95.7748 10.5557H95.0076C94.9338 10.2582 94.6429 10.0684 94.1894 10.0684C93.7429 10.0684 93.4037 10.2802 93.4037 10.5966C93.4037 10.8386 93.6085 10.9912 94.0479 11.0918L94.7211 11.2478C95.4919 11.4266 95.8531 11.758 95.8531 12.3534C95.8531 13.1163 95.1421 13.626 94.1744 13.626C93.2103 13.626 92.5819 13.1905 92.5107 12.4725H93.3114C93.3804 12.6434 93.5048 12.7862 93.6646 12.8779C93.8245 12.9696 94.0105 13.0049 94.1929 12.9783C94.6912 12.9783 95.0454 12.7551 95.0454 12.4312C95.0454 12.1895 94.8556 12.033 94.4495 11.9364L93.7429 11.7725C92.9721 11.5901 92.6153 11.2443 92.6153 10.6414C92.6153 9.9229 93.2736 9.41711 94.1894 9.41711Z"
        fill="#100F0D"
      />
      <path d="M32.6145 16.58H34.2879V27.7561H32.6145V16.58Z" fill="#100F0D" />
      <path d="M36.3041 20.6694L37.2182 16.58H38.8441L37.7367 20.6694H36.3041Z" fill="#100F0D" />
      <path
        d="M45.1671 24.7354H40.9072L39.8842 27.7561H38.0799L42.1148 16.58H43.9894L48.0242 27.7561H46.1892L45.1671 24.7354ZM41.3484 23.3416H44.725L43.0605 18.4388H43.0139L41.3484 23.3416Z"
        fill="#100F0D"
      />
      <path
        d="M56.7376 23.6812C56.7376 26.2132 55.3824 27.84 53.3373 27.84C52.8192 27.8671 52.304 27.7477 51.8506 27.4956C51.3972 27.2435 51.024 26.8688 50.7736 26.4144H50.7349V30.4502H49.0624V19.6063H50.6813V20.9615H50.7121C50.9739 20.5093 51.3535 20.1365 51.8103 19.8829C52.2671 19.6292 52.7842 19.5042 53.3065 19.521C55.3745 19.5211 56.7376 21.1557 56.7376 23.6812ZM55.0185 23.6812C55.0185 22.0311 54.166 20.9466 52.8653 20.9466C51.5874 20.9466 50.7279 22.054 50.7279 23.6812C50.7279 25.3229 51.5874 26.4223 52.8653 26.4223C54.166 26.4223 55.0185 25.3457 55.0185 23.6812Z"
        fill="#100F0D"
      />
      <path
        d="M65.7049 23.6812C65.7049 26.2132 64.3497 27.84 62.3046 27.84C61.7865 27.8671 61.2713 27.7477 60.8179 27.4956C60.3645 27.2435 59.9913 26.8688 59.7409 26.4144H59.7022V30.4502H58.0302V19.6063H59.6486V20.9615H59.6794C59.9413 20.5093 60.3208 20.1365 60.7777 19.8829C61.2345 19.6292 61.7516 19.5042 62.2738 19.521C64.3418 19.5211 65.7049 21.1557 65.7049 23.6812ZM63.9858 23.6812C63.9858 22.0311 63.1333 20.9466 61.8326 20.9466C60.5548 20.9466 59.6952 22.054 59.6952 23.6812C59.6952 25.3229 60.5548 26.4223 61.8326 26.4223C63.1333 26.4223 63.9858 25.3457 63.9858 23.6812Z"
        fill="#100F0D"
      />
      <path
        d="M71.6319 24.6412C71.7558 25.7494 72.8324 26.4771 74.3037 26.4771C75.7134 26.4771 76.7276 25.7494 76.7276 24.7501C76.7276 23.8827 76.1159 23.3633 74.6675 23.0073L73.2191 22.658C71.167 22.1623 70.2143 21.2026 70.2143 19.6453C70.2143 17.717 71.8947 16.3926 74.2808 16.3926C76.6423 16.3926 78.2611 17.717 78.3156 19.6453H76.6274C76.5263 18.53 75.6044 17.8568 74.2571 17.8568C72.9098 17.8568 71.9878 18.5379 71.9878 19.5293C71.9878 20.3194 72.5767 20.7843 74.0171 21.1402L75.2484 21.4425C77.5414 21.9848 78.4941 22.9063 78.4941 24.5409C78.4941 26.6318 76.8286 27.9413 74.1797 27.9413C71.7013 27.9413 70.028 26.6625 69.9199 24.6411L71.6319 24.6412Z"
        fill="#100F0D"
      />
      <path
        d="M82.1043 17.6797V19.6079H83.6538V20.9324H82.1043V25.4247C82.1043 26.1225 82.4146 26.4477 83.0957 26.4477C83.2796 26.4445 83.4633 26.4316 83.6459 26.409V27.7255C83.3396 27.7827 83.0284 27.8087 82.7169 27.8029C81.0673 27.8029 80.4239 27.1833 80.4239 25.6031V20.9324H79.2392V19.6079H80.4239V17.6797H82.1043Z"
        fill="#100F0D"
      />
      <path
        d="M84.5502 23.6829C84.5502 21.1188 86.0601 19.5078 88.4146 19.5078C90.777 19.5078 92.2798 21.1187 92.2798 23.6829C92.2798 26.2535 90.7849 27.8575 88.4146 27.8575C86.0452 27.8575 84.5502 26.2535 84.5502 23.6829ZM90.5757 23.6829C90.5757 21.9238 89.7698 20.8859 88.4146 20.8859C87.0594 20.8859 86.2543 21.9317 86.2543 23.6829C86.2543 25.4485 87.0594 26.4785 88.4146 26.4785C89.7698 26.4785 90.5757 25.4485 90.5757 23.6829Z"
        fill="#100F0D"
      />
      <path
        d="M93.6591 19.6062H95.2543V20.993H95.293C95.4009 20.5599 95.6546 20.1771 96.0115 19.909C96.3685 19.641 96.8068 19.5039 97.2528 19.5209C97.4456 19.5203 97.6378 19.5412 97.8259 19.5834V21.1477C97.5825 21.0733 97.3287 21.0392 97.0744 21.0466C96.8314 21.0368 96.5891 21.0797 96.3643 21.1723C96.1394 21.2649 95.9372 21.4051 95.7716 21.5832C95.6061 21.7614 95.481 21.9732 95.4051 22.2043C95.3291 22.4353 95.304 22.6801 95.3316 22.9217V27.7546H93.6591L93.6591 19.6062Z"
        fill="#100F0D"
      />
      <path
        d="M105.536 25.3633C105.311 26.8424 103.871 27.8575 102.028 27.8575C99.6577 27.8575 98.1865 26.2694 98.1865 23.7215C98.1865 21.1654 99.6656 19.5078 101.958 19.5078C104.212 19.5078 105.63 21.0564 105.63 23.5273V24.1003H99.8748V24.2014C99.8482 24.5013 99.8857 24.8033 99.9849 25.0876C100.084 25.3718 100.243 25.6317 100.45 25.85C100.657 26.0683 100.909 26.24 101.187 26.3536C101.466 26.4673 101.766 26.5204 102.067 26.5093C102.462 26.5463 102.859 26.4548 103.198 26.2483C103.537 26.0418 103.8 25.7314 103.948 25.3632L105.536 25.3633ZM99.8827 22.9314H103.956C103.971 22.6618 103.93 22.3919 103.836 22.1388C103.742 21.8857 103.597 21.6547 103.409 21.4605C103.221 21.2663 102.995 21.113 102.746 21.0101C102.496 20.9073 102.228 20.8572 101.958 20.863C101.685 20.8614 101.415 20.9137 101.163 21.017C100.911 21.1203 100.682 21.2725 100.489 21.4648C100.296 21.6571 100.143 21.8857 100.039 22.1375C99.9351 22.3892 99.8819 22.659 99.8827 22.9314Z"
        fill="#100F0D"
      />
    </g>
    <defs>
      <clipPath id="clip0_11246_229032">
        <rect width="113.851" height="35.9983" fill="white" transform="translate(0.93689 0.32959)" />
      </clipPath>
    </defs>
  </svg>
);

export default AppStore;
