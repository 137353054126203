import { IonActionSheet, IonItem, IonText } from '@ionic/react';
import React, { useState } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { actions } from '../../store';
import { Menu } from './ProfileMenuModal';

interface DispatchProps {
  logout: () => void;
}

const propsToDispatch = {
  logout: actions.app.logout,
};

interface LogOutMenuItemProps {
  item: Menu;
}

type Props = LogOutMenuItemProps & DispatchProps & WithTranslation;

const LogOutMenuItem: React.FunctionComponent<Props> = ({ item, logout, t }: Props) => {
  const [showActionSheet, setShowActionSheet] = useState(false);

  return (
    <IonItem detailIcon="/assets/navigation/chevron-forward.svg" className="menu-item" button detail onClick={() => setShowActionSheet(true)}>
      <img src={item.icon} alt="" className="icon" />
      <IonText className="menu-text">
        <Trans i18nKey={`profile-menu.${item.title}`} />
      </IonText>
      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        header={t('profile-menu.want-to-logout')}
        cssClass="logout-modal"
        buttons={[
          {
            text: t('profile-menu.log-out'),
            role: 'destructive',
            handler: () => {
              setShowActionSheet(false);
              logout();
            },
          },
          {
            text: t('profile-menu.cancel'),
            role: 'cancel',
          },
        ]}
      />
    </IonItem>
  );
};

export default connect(null, propsToDispatch)(withTranslation()(LogOutMenuItem));
