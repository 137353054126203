import { IonButton, IonImg, IonText } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { actions, RootState } from '../store';
import { AskForGeolocationParameters, GeolocationData } from '../store/layout/types';
import { openNativeSettings } from '../utils/helpers';
import { Capacitor } from '@capacitor/core';
import { App, AppState } from '@capacitor/app';

import './ActivateLocation.scss';

type Props = {
  refreshAction?: () => void;
};

interface StateProps {
  geolocationData: GeolocationData;
}

const mapStateToProps = (state: RootState): StateProps => ({
  geolocationData: state.layout.geolocationData,
});

interface DispatchProps {
  askForGeolocationDataAction: (askForGeolocationParameters?: AskForGeolocationParameters) => void;
}

const propsToDispatch = {
  askForGeolocationDataAction: actions.layout.askForGeolocationDataAction,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type ActivateLocationProps = Props & DispatchProps & StateProps;

const ActivateLocation: React.FunctionComponent<ActivateLocationProps> = ({ refreshAction, askForGeolocationDataAction, geolocationData }: ActivateLocationProps) => {
  const locationUnavailableError = geolocationData.geolocationErrorMessage;
  const [isRefreshingPosition, setIsRefreshingPosition] = useState(false);

  // We add an appStateChange listener in case the user leave the app to change his geolocation settings. Once the component is unmounted, the listener will also be removed.
  useEffect(() => {
    const appStateEventListener = App.addListener('appStateChange', async (state: AppState) => {
      // state.isActive contains the active state
      if (state.isActive) {
        // Depending on the parent component, this action will trigger the geolocation main function and fetch posts if geolocation was provided.
        setIsRefreshingPosition(true);
        askForGeolocationDataAction({ forceRefreshPosition: true, verifyPermissionsBeforeRefresh: true });
      }
    });
    // We remove the appStateChange listener when the component unmount
    return () => {
      setTimeout(async () => await appStateEventListener.remove());
    };
  }, []);

  // If the lastFetchPositionDate changed, it means we retrieved a position so there is no need to show a loading state.
  useEffect(() => {
    if (!geolocationData.lastFetchPositionDate) {
      return;
    }
    setIsRefreshingPosition(false);

    if (geolocationData.geolocationErrorMessage) {
      return;
    }
    if (refreshAction) {
      refreshAction();
    }
  }, [geolocationData.lastFetchPositionDate]);

  const shouldRefreshPosition = (!Capacitor.isNativePlatform() && geolocationData.geolocationPermission !== 'denied') || Capacitor.getPlatform() === 'android';

  const refreshButtonAction = (): void => {
    if (isRefreshingPosition) {
      return;
    }

    if (shouldRefreshPosition) {
      setIsRefreshingPosition(true);
      askForGeolocationDataAction({ forceRefreshPosition: true, askPermissions: true, canOpenNativeSettings: geolocationData.geolocationPermission === 'denied' });
    } else {
      openNativeSettings();
    }
  };

  // If it's web, and the permission has not been given nor denied, we display a text asking for it (and we ask the browser for the authorization at the same time)
  if (!Capacitor.isNativePlatform() && geolocationData.askPermissions) {
    return (
      <div className="activate-location-container">
        <div className="activate-position-header">
          <IonImg src="/assets/form/locate.svg" className="activate-location-position-image" />

          <IonText className="bold-text">
            <Trans i18nKey="geolocation.must-allow" />
          </IonText>
        </div>
      </div>
    );
  }

  // Everything is fine, returning
  if (!isRefreshingPosition && (geolocationData.forceRefreshPosition || geolocationData.askPermissions)) {
    return null;
  }

  // Something is wrong, displaying info + button
  let locationErrorMessage = '';
  let refreshButtonMessage: string | null = null;

  if (!Capacitor.isNativePlatform()) {
    // Web
    locationErrorMessage = 'geolocation.must-allow';
  } else {
    // iOS & android
    locationErrorMessage = 'geolocation.location-unavailable';
    refreshButtonMessage = 'geolocation.open-settings';

    if (locationUnavailableError) {
      locationErrorMessage = 'geolocation.location-unavailable';
    }

    if (geolocationData.geolocationPermission === 'prompt' || (geolocationData.geolocationPermission !== 'granted' && Capacitor.getPlatform() === 'android')) {
      refreshButtonMessage = 'geolocation.allow-geolocation';
    }
  }

  if (locationUnavailableError?.includes('location disabled') && geolocationData.geolocationPermission === 'granted') {
    refreshButtonMessage = 'geolocation.activate-gps';
  }

  if (locationUnavailableError?.includes('position accuracy')) {
    locationErrorMessage = 'geolocation.location-accuracy-error';
    refreshButtonMessage = 'geolocation.reload';
  }

  return (
    <div className="activate-location-container">
      <div className="activate-position-header">
        <IonImg src="/assets/form/locate.svg" className="activate-location-position-image" />

        <IonText className="bold-text">
          <Trans i18nKey={locationErrorMessage} />
        </IonText>
      </div>

      {refreshButtonMessage && (
        <IonButton type="submit" shape="round" onClick={refreshButtonAction} className="activate-location-button" disabled={isRefreshingPosition}>
          <Trans i18nKey={!isRefreshingPosition ? refreshButtonMessage : 'common.loading'} />
        </IonButton>
      )}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateLocation);
