import React from 'react';
import { IonText, IonImg } from '@ionic/react';

import './RegisterOrganizationSlider.scss';
import i18next from 'i18next';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

interface FieldsItem {
  title: string;
  image: string | null;
  descriptionTitle: string | null;
  description: string;
}

const fields: FieldsItem[] = [
  {
    title: i18next.t('organization.slide1-title'),
    image: null,
    descriptionTitle: null,
    description: i18next.t('organization.slide1-description'),
  },
  {
    title: i18next.t('organization.slide2-title'),
    image: '/assets/organization-icons/slide2.svg',
    descriptionTitle: i18next.t('organization.slide2-description-title'),
    description: i18next.t('organization.slide2-description'),
  },
  {
    title: i18next.t('organization.slide3-title'),
    image: '/assets/organization-icons/slide3.svg',
    descriptionTitle: i18next.t('organization.slide3-description-title'),
    description: i18next.t('organization.slide3-description'),
  },
  {
    title: i18next.t('organization.slide4-title'),
    image: '/assets/organization-icons/slide4.svg',
    descriptionTitle: i18next.t('organization.slide4-description-title'),
    description: i18next.t('organization.slide4-description'),
  },
  {
    title: i18next.t('organization.slide5-title'),
    image: '/assets/organization-icons/slide5.svg',
    descriptionTitle: i18next.t('organization.slide5-description-title'),
    description: i18next.t('organization.slide5-description'),
  },
];

const RegisterOrganizationSlider: React.FunctionComponent = () => {
  return (
    <Swiper
      modules={[Navigation, Pagination]}
      pagination={true}
      initialSlide={0}
      cssMode={false}
      autoHeight={false}
      slidesPerView={1}
      speed={400}
      grabCursor={true}
      key={fields.map((item: FieldsItem) => item.title).join('_')}
      className="register-slider"
    >
      {fields.map((item: FieldsItem) => {
        return (
          <SwiperSlide key={item.title} data-cy="register-slide">
            {item.image && <IonImg src={item.image} className="register-slider-image" />}
            <div className="register-slider-content">
              <h1 className={`register-slider-title${!item.image ? ' register-slider-title-top' : ''}`}>{item.title}</h1>
              <IonText color="dark" className="register-slider-description-title">
                {item.descriptionTitle}
              </IonText>
              <IonText color="medium" className="register-slider-description">
                {item.description}
              </IonText>
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default RegisterOrganizationSlider;
