import React, { Suspense, useState } from 'react';
import { IonApp, IonLoading, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import LayoutToast from './components/LayoutToast';
import PopoverConnection from './components/common/PopoverConnection';
import NetworkAlert from './components/NetworkAlert';
import ErrorBoundary from './ErrorBoundary';
import GeolocationComponent from './GeolocationHandler';
import RoutingMenu from './RoutingMenu';
import AppUrlListener from './AppUrlListener';
import { UnsupportedBrowserPage } from './pages/UnsupportedBrowserPage';
import { appIsDisabled, isTest } from './environment';
import { setDeviceVersion } from './utils/osVersionHelper';
import { setStatusBarOverlaysWebView, setStatusBarStyle } from './utils/statusBarHelper';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { Network } from '@capacitor/network';
import supportedBrowsers from './supportedBrowsers';

import './globals.scss';

setupIonicReact({
  rippleEffect: true,
  mode: 'ios',
  swipeBackEnabled: false,
  animated: false,
});

const currentBrowserNotSupported = !supportedBrowsers.test(navigator.userAgent);

const App: React.FunctionComponent = () => {
  setStatusBarStyle();
  setStatusBarOverlaysWebView();
  setDeviceVersion();

  const [showKeyboard, setShowKeyboard] = useState(false);
  const [showNetworkStatusAlert, setShowNetworkStatusAlert] = useState(false);

  if (Capacitor.isNativePlatform()) {
    Keyboard.addListener('keyboardWillShow', () => {
      setShowKeyboard(true);
    });

    Keyboard.addListener('keyboardWillHide', () => {
      setShowKeyboard(false);
    });
  }

  Network.getStatus().then(status => {
    setShowNetworkStatusAlert(!status.connected);
  });

  Network.addListener('networkStatusChange', status => {
    setShowNetworkStatusAlert(!status.connected);
  });

  if (!isTest && !Capacitor.isNativePlatform() && (currentBrowserNotSupported || appIsDisabled)) {
    return (
      <IonApp>
        <UnsupportedBrowserPage />
      </IonApp>
    );
  }
  return (
    <IonApp className={`ion-app ${showKeyboard ? 'keyboard-open' : ''}`}>
      <ErrorBoundary>
        <IonReactRouter>
          <AppUrlListener />
          <GeolocationComponent />
          <Suspense fallback={<IonLoading spinner="bubbles" isOpen showBackdrop />}>
            <RoutingMenu />
            <LayoutToast />
            <PopoverConnection />
            {showNetworkStatusAlert && <NetworkAlert />}
          </Suspense>
        </IonReactRouter>
      </ErrorBoundary>
    </IonApp>
  );
};

export default App;
