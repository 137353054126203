/* eslint-disable max-len */

import { IonContent, IonPage, IonThumbnail } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';

import CommonHeader from '../components/CommonHeader';
import ReactMarkdown from 'react-markdown';
import './LoginPage.scss';
import remarkGfm from 'remark-gfm';

type Props = WithTranslation & RouteComponentProps<Record<string, never>>;

class LoginPage extends Component<Props> {
  public constructor(props: Props) {
    super(props);
  }

  public render(): ReactNode {
    return (
      <IonPage className="privacy-page light-page light-page-display" data-cy="privacy-page">
        <CommonHeader
          addIonHeader={true}
          title={
            <IonThumbnail className="logo-thumbnail">
              <img src="/assets/logo-indigo-gradient.svg" alt="Indigo" />
            </IonThumbnail>
          }
        />

        <IonContent>
          <div>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {`# Indigo – Politique de confidentialité

**Version : 2.0**

**Date de la dernière mise à jour : 22 juillet 2020**

**Tout changement qu’Indigo apportera à la présente Politique de confidentialité sera affiché
sur cette page à l’avenir. Veuillez consulter fréquemment cette page pour connaître les
mises à jour apportées à cette Politique de confidentialité.**

## INTRODUCTION

INDIGO, société par actions simplifiée, immatriculée au Registre du Commerce et des
Sociétés de Nanterre sous le numéro 843 148 800 et dont le siège social est situé 18, rue de
l’Ancienne Mairie – 92100 Boulogne-Billancourt, vous encourage vivement à lire
attentivement la présente politique de confidentialité.

La présente Politique de confidentialité décrit la manière dont INDIGO, en sa qualité de
responsable du traitement, gère les traitements de vos Données à caractère personnel
collectées dans le cadre de votre utilisation de l’Application Indigo accessible l’adresse
suivante (l’« Application »).

Les questions, commentaires et demandes concernant cette Politique de confidentialité
doivent être adressés à l’adresse email suivante : data@indigo.world.
La Politique de confidentialité peut être modifiée à tout moment, veuillez donc la consulter
régulièrement.

Toute personne physique se connectant ou utilisant l’Application Indigo reconnaît avoir pris
pleinement connaissance de la présent Politique de confidentialité au moment de sa
connexion.

Termes et définitions :
- **Application**: Désigne la plateforme d’entraide et de partage développée par INDIGO
et téléchargeable sur l’Apple Store et Google Play.
- **Indigo**: Indigo est une société par actions simplifiée établie en France et dont le
siège social est situé 18 rue de l’Ancienne Mairie, 92100
Boulogne-Billancourt. Indigo est le Responsable du Traitement des
Données à caractère personnel pour les Traitements exposés dans la
présente Politique de Confidentialité.
- **Destinataire**: Personne physique ou morale, l’autorité publique, le service ou tout autre
organisme qui reçoit communication de données à caractère personnel.
- **Données à caractère personnel**: Toute information relative à une personne physique qui permet de
l’identifier, de manière directe ou indirecte, notamment par référence à
un identifiant, tel qu’un nom, un numéro d’identification, des données de
localisation, un identifiant en ligne, ou un ou plusieurs éléments
spécifiques propres à son identité physique, physiologique, génétique,
psychique, économique, culturelle ou sociale.
- **Finalité**: But poursuivi par Indigo lors du Traitement de vos Données à caractère
personnel.
- **Fondement du Traitement**: Fondement sur lequel le Traitement est mis en œuvre conformément aux
articles 6 à 9 du RGPD.
- **Règlementation sur la Protection des Données**: Désigne le règlement européen 2016/679 du Parlement Européen et du Conseil
du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du
traitement des données à caractère personnel et à la libre circulation de ces
(« RGPD »), et de la loi française n° 78-17 du 6 janvier 1978 relative à
l'informatique, aux fichiers et aux libertés telle que modifiée à tout moment.
- **Responsable du Traitement**: Désigne la personne physique ou morale, l’autorité publique, le service ou tout
autre organisme qui, seul ou conjointement avec d’autres, détermine les finalités
et moyens du Traitement, soit ici INDIGO.
- **RGPD**: Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27
avril 2016 relatif à la protection des personnes physiques à l’égard du
traitement des données à caractère personnel et à la libre circulation de
ces données, abrogeant la directive 95/46/CE (Règlement Général sur la
Protection des Données).
- **Traitement**: Toute opération ou tout ensemble d’opérations effectuées ou non à l’aide
de procédés automatisés et appliquées à des données ou des
ensembles de données à caractère personnel, telles que la collecte,
l’enregistrement, l’organisation, la structuration, la conservation,
l’adaptation ou la modification, l’extraction, la consultation, l’utilisation, la
communication par transmission, la diffusion ou toute autre forme de
mise à disposition, le rapprochement ou l’interconnexion, la limitation,
l’effacement ou la destruction.
- **Transfert hors de l’EEE**: Tout transfert de Données à caractère personnel d’un pays de l’Union
Européenne vers un pays hors de l’EEE, i.e. l’Espace Economique
Européen, à savoir l’Autriche, la Belgique, la Bulgarie, la Croatie, la
République de Chypre, la République tchèque, le Danemark, l’Estonie, la
Finlande, la France, l’Allemagne, la Grèce, la Hongrie, l’Irlande, l’Italie, la
Lettonie, la Lituanie, le Luxembourg, Malte, les Pays-Bas, la Pologne, le
Portugal, la Roumanie, la Slovaquie, la Slovénie, l’Espagne, la Suède, le
Royaume-Uni, l’Islande, le Liechtenstein et la Norvège.
- **Utilisateur**: Désigne toute personne qui télécharge l’Application et/ou s’y inscrit et/ou s’y
connecte.

| Finalité du Traitement | Informations collectées | Fondement du Traitement | Destinataires | Transfert hors de l’EEE | Durée de conservation |
|---|---|---|---|---|---|
| Création, sécurisation et personnalisation du compte utilisateur | Nom, prénom, sexe, photos de profil, date de naissance, pays, adresse postale, ville, adresse email, description de profil Facebook, photos Facebook, mot de passe, centres d’intérêt, description “ à propos de moi”, texte “pourquoi je suis sur Indigo” | Exécution du contrat d’utilisation de l’Application entre vous et Indigo | Service technique / Développement informatique Service Marketing et Communication | Non | Jusqu’à la suppression de votre compte utilisateur par vos soins. Si vous n’utilisez pas votre compte pendant un délai de deux ans, Indigo vous avertira de sa suppression prochaine et vous laissera l’opportunité de le réactiver. |
| Création, sécurisation et personnalisation d’un compte utilisateur - Organisation/Page | Catégorie d’organisation , secteur d’activité, nom de la Page, photos de profil, ville, code postal, pays, site internet de l'organisation, email, nom, prénom et numéro de téléphone du responsable de la page, centres d’intérêt, photos de profil, description “ à propos de moi”, texte “pourquoi je suis sur Indigo” | Exécution du contrat d’utilisation de l’Application entre vous et Indigo | Service technique / Développe ment informatique Service Marketing et Communication | Non | Jusqu’à la suppression de votre compte utilisateur par vos soins. Si vous n’utilisez pas votre compte pendant un délai de deux ans, Indigo vous avertira de sa suppression prochaine et vous laissera l’opportunité de le réactiver |
| Mise en place et gestion d’un espace de communication entre les utilisateurs sur le site | Historique des discussions en ligne entre utilisateurs de l’Application | Exécution du contrat d’utilisation de l’Application entre vous et Indigo et exécution du contrat d’échange | Service technique / Développe ment informatique | Non | Jusqu’à la suppression de votre compte utilisateur par vos soins. Si vous n’utilisez pas votre compte pendant un délai de deux ans, Indigo vous avertira de sa suppression prochaine et vous laissera l’opportunité de le réactiver. |
| Fourniture des services de l’Application | Données liées à votre compte (transactions réalisées) | Exécution du contrat d’utilisation de l’Application entre vous et Indigo | Service technique / Développe ment informatique | Non | 5 ans à compter de la suppression de votre compte utilisateur. |
| Création d’annonce | Caractéristiques de l’annonce : adresse, photo, description, titre, catégories, poids de l’objet, type de matériaux, estimation du prix | Exécution du contrat d’utilisation del’Application entre vous et Indigo | Service technique / Développe ment informatique Service Marketing et Communication | Non | Jusqu’à la suppression de votre compte utilisateur par vos soins. Si vous n’utilisez pas votre compte pendant un délai de deux ans, Indigo vous avertira de sa suppression prochaine et vous laissera l’opportunité de le réactiver. |
| Réalisation et utilisation de la carte géographique de l’application afin de vous informer des annonces de biens ou services situés à proximité de votre position | Données de géolocalisation | Consentement | Service technique / Développement informatique | Non | Durant le temps de la validité de l’annonce puis 6 mois au maximum après la suppression de l’annonce. |
| Notation des utilisateurs | Recommandation, non recommandation et avis écrits des utilisateurs | Exécution du contrat d’utilisation de l’Application entre vous et Indigo | Service technique / Développement informatique | Non | Jusqu’à la suppression de votre compte utilisateur par vos soins. Si vous n’utilisez pas votre compte pendant un délai de deux ans, Indigo vous avertira de sa suppression prochaine et vous laissera l’opportunité de le réactiver. |
| Gestion de la newsletter | Adresse email | Consentement | Service technique / Développe ment informatique Service Marketing et Communication | Oui, vers les Etats-Uni s où les données de Mail Chimp sont hébergée s. Mail Chimp a certifié sa conformit é avec le mécanis me de certificati on approuvé Privacy Shield | Jusqu’au retrait ce votre consentement ou 3 ans à compter du dernier contact reçu. |
| Gestion des notifications par email (news, newsletter et tendances, nouveau message, nouvelle demande, réponse aux demandes, avis, nouveaux abonnés, nouvelles annonces des comptes suivis, publications d’Indigo, publications recommandées, profils recommandés) | Adresse email | Consentement | Service technique / Développe ment informatique Service Marketing et Communication | None | Jusqu’à la suppression de votre compte utilisateur par vos soins. Si vous n’utilisez pas votre compte pendant un délai de deux ans, Indigo vous avertira de sa suppression prochaine et vous laissera l’opportunité de le réactiver. |
| Gestion des notifications push (nouveau message, nouvelle demande, réponses aux demandes de l'utilisateur, avis, nouveaux abonnés, nouvelles annonces des profils suivis, publications d’Indigo, publications recommandées, profils recommandés) |  | Consentement | Service technique / Développe ment informatique Service Marketing et Communication | None | Jusqu’à la suppression de votre compte utilisateur par vos soins. Si vous n’utilisez pas votre compte pendant un délai de deux ans, Indigo vous avertira de sa suppression prochaine et vous laissera l’opportunité de le réactiver. |
| Gestion de la liste d’opposition | Adresse email | Intérêt légitime d’Indigo de s’assurer de l’effectivité du droit d’opposition des utilisateurs. | Service  Marketing et Communication | Non | 3 ans à compter de l’exercice du droit d’opposition |
| Formulaire de contact | Adresse email et corps du message | Intérêt légitime d’Indigo de répondre aux sollicitations de ses utilisateurs | Service technique / Développe ment informatique Service Marketing et Communication | Non | 3 ans après la collecte des données ou après le dernier contact reçu |
| Stockage et sauvegarde des Données à caractère personnel que vous fournissez à Indigo via l’Application | Données à caractère personnel collectées via l’utilisation de l’Application | Exécution du contrat d’utilisation de l’Application entre vous et Indigo | Amazon Web Services | Non | Jusqu’à la suppression de votre compte utilisateur par vos soins. Si vous n’utilisez pas votre compte pendant un délai de deux ans, Indigo vous avertira de sa suppression prochaine et vous laissera l’opportunité de le réactiver. |
| Analyse de vos données de navigation pour la proposition de contenus et services correspondant à vos centres d’intérêts | Cookies (date, heure et contenu accédé) Adresse IP Pixel Facebook, Pixel LinkedIn, balises Google, Google Firebase, Facebook Analytics, Kibana, Google Analytics | Consentement | Service technique / Développe ment informatique Service Marketing et Communication | Non | 13 mois (pour les cookies eux-mêmes) et 25 mois dans le cadre des données collectées via ces cookies |
| Mesure de l’audience et de la performance de l’Application | Cookies Informations relatives à la version et au type de navigateur Internet utilisé (e.g. Firefox, Chrome ou Internet Explorer) et au système d’exploitation utilisé (e.g. Windows ou Mac OS) Pixel Facebook, Pixel LinkedIn, balises Google, Google Firebase, Facebook Analytics, Kibana, Google Analytics | Consentement | Service technique / Développe ment informatique Service Marketing et Communication | Non | 13 mois (pour les cookies eux-mêmes) et 25 mois dans le cadre des données collectées via ces cookies |
| Amélioration du fonctionnement de l’Application Détection, prévention et résolution des fraudes, spams, abus, incidents de sécurité et autres activités préjudiciables Identification de l’utilisateur (en cas de signalement de l’utilisateur et pour la modération des commentaires signalés) | Cookies Adresse IP Informations relatives à votre terminal (type, version) Informations relatives à la version et au type de navigateur Internet utilisé (e.g. Firefox, Chrome ou Internet Explorer) et au système d’exploitation utilisé (e.g. Windows ou Mac OS) | Intérêt légitime d’Indigo d’améliorer le fonctionnement de l’Application Intérêt légitime d’Indigo de détecter, prévenir et résoudre les fraudes, spams, abus, incidents de sécurité et autres activités préjudiciables  Intérêt légitime d’Indigo d’identifier l’utilisateur signalé et de modérer ses commentaires signalés | Service technique / Développe ment informatique Service Marketing et Communication | Non | 13 mois (pour les cookies eux-mêmes) et 25 mois dans le cadre des données collectées via ces cookies |
| Amélioration de l’expérience de l’utilisateur | Cookies Pixel Facebook, Pixel LinkedIn, balises Google, Google Firebase, Facebook Analytics, Kibana, Google Analytics | Intérêt légitime d’Indigo d’améliorer l’expérience de l’utilisateur | Service technique / Développe ment informatique Service Marketing et Communication | Non | 13 mois (pour les cookies eux-mêmes) et 25 mois dans le cadre des données collectées via ces cookies |
| Permettre et faciliter la communication par des moyens électroniques sur l’Application | Cookies Pixel Facebook, Pixel LinkedIn, balises Google, Google Firebase, Facebook Analytics, Kibana, Google Analytics | Intérêt légitime d’Indigo de communiqu er avec ses utilisateurs | Service technique / Développe ment informatique Service Marketing et Communication | Non | 13 mois (pour les cookies eux-mêmes) et 25 mois dans le cadre des données collectées via ces cookies |
| Elaboration d’études analytiques et statistiques commerciales | Données agrégées liées au profil des Utilisateurs et aux transactions ou Échanges réalisés via l’Application (secteur géographique, nombre de transactions, économies de CO2 et Euros réalisées…) | Intérêt légitime d’Indigo de promouvoir son service et évaluer la performance de l'Application | Service technique / Développe ment informatique Service Marketing et Communication | Non | 5 ans à compter de la suppression de votre compte utilisateur |

## 1. POURQUOI COLLECTONS-NOUS VOS DONNÉES À CARACTÈRE PERSONNEL ?

Vos données à caractère personnel :
- Sont obtenues et traitées loyalement et licitement ;
- Sont enregistrées pour des finalités déterminées et légitimes ;
  - sont employées conformément à ces finalités ;
  - sont adéquates, pertinentes et non excessives par rapport à ces finalités ;
- Font l’objet de précautions de nature à assurer la sécurité et la confidentialité des données en
vue d’empêcher qu’elles puissent être endommagées, modifiées, détruites ou communiquées à
des tiers non autorisés.

## 2. APERÇU DES TRAITEMENTS MIS EN ŒUVRE PAR INDIGO

Le tableau ci-dessous synthétise les Traitements de Données à caractère personnel mis en œuvre par
Indigo. Ce tableau est complété par les précisions qui figurent aux sections suivantes.

## 3. QUELLES SONT LES DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES PAR INDIGO ?

Vous fournissez des informations à Indigo lors de la création de votre compte d’utilisateur ou
Page, de la mise en ligne d’une annonce, de la rédaction de commentaires, en vous
inscrivant aux newsletters ou en contactant Indigo par le biais de l’Application (par email,
téléphone, réseaux sociaux, etc.).

Des informations techniques (par exemples, adresse IP, information sur votre navigateur,
etc.) sont également transmises à Indigo par votre terminal lors de votre utilisation de
l’Application.

Les informations collectées par Indigo sont indiquées dans le tableau de synthèse ci-dessus.

## 4. SUR QUELS FONDEMENTS INDIGO TRAITE-T-IL VOS DONNÉES À CARACTÈRE PERSONNEL ?

Comme décrit dans le tableau de synthèse ci-dessus, les Traitements mis en œuvre par
Indigo sont fondés sur (i) l’exécution du contrat d’utilisation de l’Application entre vous et
Indigo, (ii) les intérêts légitimes d’Indigo, ou (iii) votre consentement.

Vous ne pouvez pas vous opposer à ces Traitement lorsqu’ils sont nécessaires à l’exécution
du contrat d’échange ou du contrat d’utilisation de l’Application entre vous et Indigo.

Lorsque le Traitement est fondé sur l’intérêt légitime d’Indigo, vous pouvez vous y opposer.
Pour ce faire, veuillez-vous référer à la Section 9 concernant vos droits.

Lorsque le Traitement repose sur votre consentement, vous pouvez le retirer à tout moment
en envoyant un email à l’adresse suivante : data@indigo.world, ou dans les paramètres de
confidentialité dans l’Application.

## 5. COOKIES

### 5.1. A quoi servent les Cookies et pourquoi sont-ils collectés

Les cookies sont des fichiers texte contenant de petites quantités d’informations qui sont
téléchargées sur votre terminal lorsque vous visitez une page Web ou utilisez une
application. Les cookies sont ensuite renvoyés à la page Web ou l’application d’origine lors
de chaque visite ultérieure, ou à une autre page Web ou application qui reconnaît ce cookie.

### 5.2. INFORMATION IMPORTANTE RELATIVE AU CONSENTEMENT

Le dépôt ou la lecture de certains cookies ne nécessitent pas le recueil préalable de votre
consentement, soit parce qu’ils ne traitent aucune Donnée à Caractère Personnel vous
concernant, soit parce qu’ils sont strictement nécessaires à la fourniture du service que vous
sollicitez.

La durée de validité de ce consentement est de 13 mois maximum.

Le dépôt ou la lecture d’autres cookies que ceux évoqués dans le paragraphe précédent doit
faire l’objet d’un consentement de votre part au préalable et sont détaillés dans le tableau
ci-avant (article 2).


Vous pouvez, à tout moment, vous opposer au dépôt ou à la lecture des cookies que nous
utilisons, soit en les supprimant de vos appareils, soit en modifiant les paramètres de votre
navigateur.

### 5.3. QUELS COOKIES UTILISONS-NOUS

Nous utilisons différents types de cookies dont les finalités sont décrites ci-après.

#### Cookies techniques

Il s’agit de cookies nécessaires au fonctionnement de l’Application qui ne nécessitent pas
votre consentement. Ils vous permettent d’utiliser les principales fonctionnalités l’Application
(par exemple l’accès à votre compte). Sans ces cookies, vous ne pourrez pas utiliser
correctement notre Application. Il s’agit de cookies déposés par INDIGO ou par des tiers qui
concernent le fonctionnement ou la sécurité de notre Application.

Indigo utilise des cookies, pour son intérêt légitime pour :
- améliorer le fonctionnement de l’Application ;
- détecter, prévenir et résoudre les fraudes, spams, abus, incidents de sécurité et autres
activités préjudiciables ;
- identifier l’utilisateur (en cas de signalement de l’utilisateur et pour la modération des
commentaires signalés) ;
- améliorer l’expérience de l’utilisateur ;
- permettre et faciliter la communication par des moyens électroniques sur l’Application.

Vous pouvez vous y opposer et les supprimer en utilisant les paramètres de votre
navigateur, cependant votre expérience utilisateur risque d’être dégradée voire impossible
pour des raisons de sécurité. Ces cookies sont détaillés dans le tableau ci-avant (article 2).

#### Cookies analytiques / mesures de fréquentation

Il s’agit de cookies tiers qui nous permettent de connaître l’utilisation et les performances de
notre site, d’établir des statistiques, des volumes de fréquentation et d’utilisation des divers
éléments de notre site/notre application (contenus visités, parcours) nous permettant
d’améliorer l’intérêt et l’ergonomie de nos services (les pages ou les rubriques les plus
souvent consultés, les articles les plus lus, etc.).

Si vous donnez votre accord (consentement), des cookies seront déposés permettant de
collecter les informations strictement nécessaires aux finalités décrites dans le tableau
ci-dessous. Indigo utilise Google Analytics, un outil d’analyse Web qui aide Indigo à
comprendre comment les utilisateurs agissent sur l’Application. Google Analytics utilise des
cookies permanents pour suivre les interactions des utilisateurs. Ces informations sont
utilisées pour générer des rapports et pour aider Indigo à améliorer l’Application. Ces
rapports révèlent les tendances de l’Application sans identifier les visiteurs individuellement.
Vous pouvez désactiver Google Analytics sans affecter la façon dont vous visitez l’Application – pour plus d’informations sur la possibilité de désactiver Google Analytics sur
tous les sites Internet que vous utilisez, visitez cette [page Google](https://tools.google.com/dlpage/gaoptout)

Vous trouverez plus d’informations sur les cookies et sur la façon de les administrer à
l’adresse suivante : [http://www.allaboutcookies.org/fr/](http://www.allaboutcookies.org/fr/).

Certaines données sont également collectées via Firebase, Balises Google, Facebook
analytics, Pixel Facebook, Pixel LinkedIn, Kibana.

L’ensemble de ces cookies est détaillé dans le tableau ci-avant (article 2).

Via ces cookies, ces tiers collecteront et utiliseront vos données de navigation pour des
finalités qui leur sont propres, conformément à leur politique de confidentialité (lien
ci-dessous).

[https://www.facebook.com/policies/cookies/](https://www.facebook.com/policies/cookies/)
[https://policies.google.com/privacy?gl=FR&hl=fr](https://policies.google.com/privacy?gl=FR&hl=fr)
[https://www.linkedin.com/legal/privacy-policy](https://www.linkedin.com/legal/privacy-policy)
[https://logz.io/about-us/privacy-policy/](https://logz.io/about-us/privacy-policy/)

#### Réseaux sociaux

Les cookies qui sont déposés via les boutons réseaux sociaux ont pour finalité de permettre
aux utilisateurs de l’Application de faciliter le partage de contenu et d’améliorer la convivialité
(indiquer les réseaux concernés, si applicable).

Si vous donnez votre accord (consentement), ces tiers déposeront des cookies qui vous
permettront d’améliorer la convivialité de l’Application (à confirmer/compléter/adapter.

Via ces cookies, ces tiers collecteront et utiliseront vos données de navigation pour des
finalités qui leur sont propres, conformément à leur politique de confidentialité (lien
ci-dessous).

[https://www.facebook.com/policies/cookies/](https://www.facebook.com/policies/cookies/)
[https://www.facebook.com/policies/cookies/](https://www.facebook.com/policies/cookies/)
[https://policies.google.com/privacy?gl=FR&hl=fr](https://policies.google.com/privacy?gl=FR&hl=fr)
[https://www.linkedin.com/legal/privacy-policy](https://www.linkedin.com/legal/privacy-policy)

### 5.4. COMMENT PARAMÉTRER VOTRE NAVIGATEUR, SMARTPHONE OU COMPOSANT LOGICIELS

Tout paramétrage que vous pourrez entreprendre sera susceptible de modifier votre
utilisation de l’Application ainsi que vos conditions d’accès à certains services/fonctionnalités
nécessitant l’utilisation de cookies. Vous pouvez autoriser ou refuser l’enregistrement de
cookies dans votre terminal et modifier à tout moment le paramétrage de ce dernier.

Si vous avez accepté dans votre logiciel de navigation l’enregistrement de Cookies, ils sont
stockés au sein d’un espace dédié de votre terminal.

Si vous refusez l’enregistrement de Cookies dans votre terminal ou si vous supprimez ceux
qui y sont enregistrés, vous ne pourrez plus bénéficier d’un certain nombre de
fonctionnalités pourtant nécessaires à la navigation dans certains espaces de site internet.
Le cas échéant, nous déclinons toute responsabilité pour les conséquences liées au
fonctionnement dégradé de notre site internet résultant de l’impossibilité pour nous
d’enregistrer ou de consulter les Cookies nécessaires à leur fonctionnement et que vous
auriez supprimés ou refusés.

-Comment paramétrer votre navigateur ?-

La plupart des navigateurs vous permettent de modifier les paramètres des cookies. Ces
paramètres se trouvent dans le menu « options » ou « préférences » de votre navigateur.
Pour mieux comprendre ces paramètres, les liens suivants peuvent vous être utiles, sinon
veuillez utiliser l’option « Aide » de votre navigateur pour plus de détails :
- [Paramétrer les cookies dans Internet Explorer](https://support.microsoft.com/fr-fr/windows/supprimer-et-g%C3%A9rer-les-cookies-168dab11-0753-043d-7c16-ede5947fc64d)
- [Paramétrer les cookies dans Firefox](https://support.mozilla.org/fr/kb/protection-renforcee-contre-pistage-firefox-ordinateur?redirectslug=activer-desactiver-cookies-preferences&redirectlocale=fr)
- [Paramétrer les cookies dans Chrome](https://support.google.com/chrome/answer/95647?hl=fr)
- [Paramétrer les cookies dans Safari web et iOS.](https://support.apple.com/fr-fr/guide/safari/sfri11471/mac)

-Comment paramétrer vos réglages de confidentialité dans votre smartphone/tablette ?-

Vous pouvez décider de modifier le paramétrage de confidentialité de votre
smartphone/tablette.

Pour paramétrer vos réglages de confidentialité :
- Système Androïd : [https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&
hl=fr](https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&
hl=fr)
- Système Apple : [https://support.apple.com/fr-fr/HT201265](https://support.apple.com/fr-fr/HT201265)

## 6. A QUI VOS DONNÉES À CARACTÈRE PERSONNEL SONT-ELLES TRANSMISES ?

Comme détaillé dans le tableau de synthèse ci-dessus, l’accès à vos données à caractère
personnel est limité aux :
- employés et préposés d’Indigo, habilités en raison de leurs fonctions et tenus à une
obligation de confidentialité ;
- sous-traitants d’Indigo chargés contractuellement de l’exécution des tâches nécessaires
au bon fonctionnement de l’application et de ses services, à savoir le stockage cloud de
vos Données à caractère personnel (Amazon Web Services qui stocke vos Données à
caractère personnel en Irlande).

Dans le cadre de l’exécution de leurs prestations, les sous-traitants d’Indigo respectent les
dispositions du RGPD.

Indigo communiquera également vos Données à caractère personnel à des tiers si Indigo
procède à une cession de l’une de ses entités et/ou actifs et que vos Données à caractère
personnel sont concernées par à cette cession.

Indigo partagera vos Données à caractère personnel avec les autorités judiciaires, les
autorités administratives indépendantes ou toute autre organisation si la loi le requiert.

Indigo produit des statistiques, non identifiantes, sous format agrégé, à partir des données
concernant les Utilisateurs et/ou les Echanges, qu’elle est susceptible de communiquer à
ses Clients. Vous pouvez vous opposer à ce traitement statistique de vos données
personnelles en suivant la procédure indiquée à l’article 9 ci-après.

## 7. VOS DONNÉES À CARACTÈRE PERSONNEL SONT-ELLES TRANSMISES HORS DE L’EEE ?

La plupart des Destinataires des Données à caractère personnel sont situés en France ou
dans un Etat membre de l’EEE.

Dans le cadre de la mise en place de la newsletter Indigo, votre adresse e-mail est
transférée vers les Etats-Unis où les données du prestataire d’Indigo, Mail Chimp, sont
hébergées. Mail Chimp a certifié sa conformité avec le mécanisme de certification approuvé
Privacy Shield.

## 8. PENDANT COMBIEN DE TEMPS VOS DONNÉES À CARACTÈRE PERSONNEL SONT-ELLES CONSERVÉES ?

Indigo conserve vos Données à caractère personnel selon les durées décrites dans le
tableau de synthèse ci-dessus.

Après que vous avez mis fin à l’utilisation de l’Application, Indigo conservera les données
liées à votre compte pendant une durée de 5 ans puis stockera vos Données à caractère
personnel dans un format agrégé et anonyme.

## 9. QUELS SONT VOS DROITS ?

Conformément à la Règlementation sur la Protection des Données, vous disposez de droits
concernant les Données à caractère personnel qu’Indigo détient à votre sujet.

Le tableau ci-dessous présente un aperçu de ces droits et de la façon dont vous pouvez les
exercer. Ces droits peuvent être exercés sur simple demande écrite, impérativement
accompagnées d’un justificatif d’identité en cours de validité comportant la signature du
titulaire. Pour exercer l’un de vos droits, vous pouvez :
- Soit adresser un courrier à l’adresse postale suivante : [Indigo SAS, 4 place de
l'Opéra 75016 Paris FRANCE] ;
- Soit adresser un email à l’adresse suivante : data@indigo.world.

Veuillez noter que pour chacun de ces droits Indigo peut avoir des motifs légaux valables
pour refuser votre demande, dans ce cas Indigo vous en informera.
Toute demande doit être claire, précise et justifiée.

Une réponse vous sera apportée dans le mois qui suit la réception de votre demande ; ce
délai pourra toutefois être prolongé jusqu’à deux mois en cas de complexité de la demande
et/ou de demande trop nombreuses.

INDIGO ne facture pas de frais pour le traitement des demandes de droits d’accès, mais se
réserve le droit d’en facturer lorsque cela concerne des demandes de copies
supplémentaires d'informations qui vous ont déjà été fournies, et pour des demandes
manifestement infondées ou excessives, en particulier lorsque ces demandes sont
répétitives.

L’utilisateur est informé qu’en cas d’opposition au traitement ou s’il transmet des données
erronées ou fantaisistes, les services liés à la collecte des données ne pourront pas être
rendus, INDIGO ne pouvant en aucun cas engager sa responsabilité à ce titre.

## 10. SÉCURITÉ
Les données personnelles sont tr
aitées de façon à garantir une sécurité appropriée à l'aide
de mesures physiques, techniques ou organisationnelles pertinente au regard de l’état de
l’art en la matière, y compris la protection contre le traitement non autorisé ou illicite et
contre la perte, la destruction ou les dégâts d'origine accidentelle.

Selon les besoins, les risques, les coûts et la finalité de traitement ces mesures peuvent
inclure la pseudonymisation et le chiffrement des données.
INDIGO met en place une procédure visant à tester, à analyser et à évaluer régulièrement
l'efficacité des mesures techniques et organisationnelles pour assurer la sécurité du
traitement.

Toutefois, il est à signaler qu’internet n’est pas un environnement complètement sécurisé et
INDIGO ne peut pas garantir la sécurité de la transmission ou du stockage des informations
sur internet.

11. COMMENT POUVEZ-VOUS FAIRE UNE RÉCLAMATION ?

Si vous souhaitez déposer une plainte sur la manière dont Indigo traite vos Données à
caractère personnel, vous pouvez :
- Soit adresser un courrier à l’adresse postale suivante :[Indigo SAS, 4 place de
l'Opéra 75016 Paris FRANCE];
- Soit adresser un email à l’adresse suivante : data@indigo.world.

Indigo s’efforcera de traiter votre demande dans les plus brefs délais.

Vous conservez le droit de faire une réclamation auprès de l’autorité de contrôle de la
protection des données du pays de l’Union Européenne dans lequel vous résidez ou
travaillez où vous pensez qu’Indigo a enfreint la réglementation sur la protection des
Données à caractère personnel. Par exemple, en France, la CNIL : 3 Place de Fontenoy
TSA 80715 75334 PARIS CEDEX 07.

## 12. MODIFICATIONS

INDIGO se réserve le droit de modifier la Politique de confidentialité à tout moment et sans
préavis. Les modifications apportées à la Politique de confidentialité entreront en vigueur
une fois publiées sur l’Application.
`}
            </ReactMarkdown>
          </div>
        </IonContent>
      </IonPage>
    );
  }
}

export default withTranslation()(withRouter(LoginPage));
