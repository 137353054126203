import { createAsyncAction } from 'typesafe-actions';
import { ThunkResult } from '../types';
import { Category } from './types';
import { fetchAllItems } from '../../utils/helpers';

export const fetchCategoriesAction = createAsyncAction('posts/FETCH_CATEGORIES', 'posts/FETCH_CATEGORIES_SUCCESS', 'posts/FETCH_CATEGORIES_FAILURE')<void, { categories: Category[] }, Error>();

export function fetchPostCategories(): ThunkResult<Promise<void>> {
  return async dispatch => {
    dispatch(fetchCategoriesAction.request());

    return fetchAllItems<Category>('/categories?itemsPerPage=100&order[order]=ASC', { method: 'GET' })
      .then(data => {
        dispatch(fetchCategoriesAction.success({ categories: data }));
      })
      .catch(e => {
        dispatch(fetchCategoriesAction.failure(e));
        return Promise.reject(e);
      });
  };
}
