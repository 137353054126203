import { fetchWithAuthIfPossible } from '../authDataAccess';
import { appInfo, appVersion, deviceId, deviceInfo, isTest } from '../../environment';
import { Capacitor } from '@capacitor/core';
import { isFirstAppOpen } from '../analytics/analyticsHelper';
import { hydraFetch } from '../dataAccess';
import { resolveIfTrue } from '../helpers';
import { Area } from '../../store/users/types';

const waitForDeviceInfo = (): Promise<void> => {
  return new Promise(function (resolve, reject) {
    resolveIfTrue(() => typeof deviceId?.identifier !== 'undefined' && typeof deviceInfo !== 'undefined', resolve, reject, 20);
  });
};

async function getDeviceInfo(): Promise<Record<string, unknown>> {
  await waitForDeviceInfo();

  return {
    platform: Capacitor.getPlatform(),
    appVersion,
    userAgent: navigator.userAgent,
    deviceId: deviceId?.identifier,
    appInfo,
    deviceInfo,
  };
}

function logUserAction(action: string, data?: Record<string, unknown>, withAuth = true): Promise<void> {
  if (isTest) {
    return Promise.resolve();
  }

  const run = (): Promise<unknown> => {
    const fetchUrl = '/user_logs';
    const fetchOptions = {
      method: 'POST',
      data: {
        action,
        data,
      },
    };

    return withAuth ? fetchWithAuthIfPossible(fetchUrl, fetchOptions) : hydraFetch(fetchUrl, fetchOptions);
  };

  return run()
    .then(() => {
      // Ignore
    })
    .catch(() => {
      // Ignore
    });
}

export async function logUserRegister(): Promise<void> {
  return logUserAction('register', await getDeviceInfo());
}

export async function logUserConnect(): Promise<void> {
  return logUserAction('connect', await getDeviceInfo());
}

export async function logUserAreaChange(area: Area): Promise<void> {
  if (!area?.address?.locality) {
    return;
  }

  const loggedAddress = {
    locality: area.address.locality,
    country: area.address.country,
    postalCode: area.address.postalCode,
  };

  return logUserAction('area_change', loggedAddress);
}

export async function logAppInstall(): Promise<void> {
  return logUserAction('install', await getDeviceInfo(), false);
}

export async function logError(errorData: string | Error | Record<string, unknown>): Promise<void> {
  let data: Record<string, unknown>;
  try {
    data = await getDeviceInfo();
  } catch (e) {
    data = { deviceInfoError: e };
  }

  data.error = errorData;

  return logUserAction('error', data, false);
}

export function init(): void {
  if (Capacitor.isNativePlatform() && isFirstAppOpen()) {
    logAppInstall();
  }
}
