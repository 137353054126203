import { IonButton, IonIcon, IonImg, IonItem, IonLabel, IonText, IonTextarea, IonThumbnail, IonCheckbox } from '@ionic/react';
import { Components } from '@ionic/core';
import { FormikHelpers, FormikProps } from 'formik';
import i18next from 'i18next';
import { addCircle as addCircleIcon } from 'ionicons/icons';
import React, { Component, MouseEventHandler, ReactNode, useState } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import * as Yup from 'yup';
import { actions } from '../store';
import { locationIsAPageWithUserForm } from '../utils/windowLocationHelper';
import DynamicForm, { FormFields, FormValue, FormValues, InputWithValidationFieldComponent } from './DynamicForm';
import InputMediaUploader, { PicturePlaceholderProps } from './inputs/InputMediaUploader';
import InputPasswordToggle from './inputs/InputPasswordToggle';
import { CurrentUser, CurrentUserEditFields } from '../store/users/types';
import { Thematic } from '../store/thematics/types';
import SelectField from './inputs/SelectField';
import ThematicTagItems from './ThematicTagItems';
import { ReactComponentType } from './types';
import { getUserAvatar, isEqualIgnoringFunctions, routePrivacy, routeTos, rotateIconForRtlShadowRoot } from '../utils/helpers';
import get from 'lodash/get';
import { localesLabels } from '../i18n';
import UserAddressModal from './UserAddressModal';
import { CurrentUserFields } from '../store/users/types';
import PhoneInput from 'react-phone-input-2';
import ActionConfirmationAlert from './ActionConfirmationAlert';
import AsyncImg from './AsyncImg';
import { getAppLanguage } from '../utils/translation';
import { ObjectShape } from 'yup/lib/object';

import 'react-phone-input-2/lib/style.css';
import './UserForm.scss';
import { Media } from '../store/app/types';

interface LocationFieldComponentProps {
  form: FormikProps<CurrentUserFields>;
  locationModalHeader?: React.FunctionComponent<MouseEventHandler>;
  onValueChange?: (newValue: FormValue, resetStatus: boolean) => void;
  submitFormIfValid?: () => void;
}

interface DispatchProps {
  setAppLanguage: (lng: string) => void;
}

interface AvatarMediaUploaderProps {
  form: FormikProps<FormValues>;
}

const propsToDispatch = {
  setAppLanguage: actions.layout.setAppLanguageAction,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

export const addProfilePicturePlaceholder: ReactNode = (
  <>
    <img className="profile-picture-add-button" src="/assets/avatar.svg" alt="avatar" />
    <IonButton fill="clear" className="add-icon" size="default">
      <IonIcon icon={addCircleIcon} color="primary" className="icon-medium-size" size="" />
    </IonButton>
  </>
);

export const profilePicturePlaceholder: React.FunctionComponent<PicturePlaceholderProps> = ({ imageSrc, deleteItem }: PicturePlaceholderProps) => (
  <>
    <AsyncImg alt="" src={imageSrc} className="round-radius" />
    <IonButton fill="clear" className="add-icon" size="default" onClick={() => deleteItem()}>
      <IonIcon icon="/assets/form/close-circle.svg" className="icon-medium-size" />
    </IonButton>
  </>
);

export const AvatarMediaUploaderComponent: ReactComponentType = ({ ...props }: React.HTMLAttributes<unknown> & Components.IonItem & AvatarMediaUploaderProps) => {
  const [isConfirmationModalOpen, setConfirmationModalState] = useState(false);
  const history = useHistory();

  const conditionalLink = props.form.dirty ? { onClick: () => setConfirmationModalState(true) } : { routerLink: '/me/edit/pictures' };
  rotateIconForRtlShadowRoot('.user-avatar', 'ion-icon');
  return (
    <div className="avatar-content">
      <IonItem detailIcon="/assets/navigation/chevron-forward.svg" className="user-avatar" detail lines="none" {...conditionalLink}>
        <IonThumbnail className="avatar">
          <IonImg src={getUserAvatar(get(props, 'form.initialValues'))} alt="avatar" />
        </IonThumbnail>
        <Trans i18nKey="user.edit-my-profile-picture" />
      </IonItem>

      <ActionConfirmationAlert
        closeAlert={() => setConfirmationModalState(false)}
        discardChanges={() => {
          setConfirmationModalState(false);
          history.push('/me/edit/pictures');
        }}
        isAlertActive={isConfirmationModalOpen}
      />
    </div>
  );
};

export const TextAreaFieldComponent: ReactComponentType = ({ ...props }: React.HTMLAttributes<unknown> & Components.IonTextarea) => {
  return (
    <div className="textarea">
      <IonItem>
        <IonLabel position="stacked">
          <Trans i18nKey={`user.${props.name}`} />
        </IonLabel>
        <IonTextarea {...props} />
      </IonItem>
      <IonText className="example" color="medium">
        <Trans i18nKey={`user.${props.name}-example`} />
      </IonText>
    </div>
  );
};

const CheckboxCguFieldComponent: ReactComponentType = (props: Components.IonCheckbox) => {
  return (
    <IonItem className="checkbox-container" detail={false} lines="none">
      <IonCheckbox data-cy="cgu-checkbox" {...props} checked={!!props?.value} color="medium" slot="start" />
      <IonLabel color="medium" className="cursor-pointer checkbox-label fw-normal">
        <Trans i18nKey="user.cgu-label">
          <a key="terms" href={routeTos(getAppLanguage())} target="new">
            <Trans i18nKey="user.cgu-terms" />
          </a>
          <a key="privacy" href={routePrivacy()} target="new">
            <Trans i18nKey="user.cgu-privacy" />
          </a>
        </Trans>
      </IonLabel>
    </IonItem>
  );
};

const CheckboxNewsLetterFieldComponent: ReactComponentType = (props: Components.IonCheckbox) => {
  return (
    <IonItem data-cy="newsletter-checkbox" lines="none" className="checkbox-container newsletter-container" detail={false}>
      <IonCheckbox color="medium" {...props} checked={!!props?.value} slot="start" />
      <IonLabel color="medium" className="checkbox-label fw-normal">
        <Trans i18nKey="user.newsletter-label" />
      </IonLabel>
    </IonItem>
  );
};

export const LocationFieldComponent: ReactComponentType = ({ ...props }: React.HTMLAttributes<unknown> & LocationFieldComponentProps) => {
  const { form, locationModalHeader, onValueChange } = props;
  const [isLocationModalOpen, setLocationModalState] = useState(false);
  const fieldHasError = (form.status && form.status['address']) || (form.errors['address'] && form.touched['address']);
  const fieldHasValue = !!form.values.address?.formatted;
  const fieldIsValid = fieldHasValue && !fieldHasError;

  return (
    <>
      <IonItem
        button
        mode="md"
        className={`location-item ${fieldHasError ? '' : 'ion-invalid'} ${fieldHasValue ? 'item-has-value' : ''}`}
        onClick={() => setLocationModalState(true)}
        data-cy="toggle-address-modal-btn"
        lines="none"
        detailIcon="/assets/navigation/chevron-forward.svg"
      >
        <img alt="validation" className={`icon-svg-validation ${fieldIsValid ? 'field-valid' : ''}`} src="/assets/form/icon-validation.svg" />
        <IonLabel className="location-label" color={fieldHasError ? 'danger' : 'dark'} position="floating">
          <Trans i18nKey="user.address" /> *
        </IonLabel>
        <span className="address-value">{form.values.address?.formatted || ''}</span>
      </IonItem>
      <IonButton className="arrow-forward-button location-item-button" fill="clear" color="blue" slot="end" onClick={() => setLocationModalState(true)}>
        <IonIcon icon="/assets/navigation/chevron-forward.svg" />
      </IonButton>
      <UserAddressModal
        isOpen={isLocationModalOpen}
        onClose={() => {
          setLocationModalState(false);
          form.setFieldTouched('address');
          form.setFieldTouched('addressLocation');
        }}
        onAddressChange={value => {
          if (!onValueChange) {
            return;
          }

          if (!value) {
            onValueChange({ address: null, addressLocation: null }, true);
            return;
          }

          onValueChange(
            {
              address: { formatted: value.address.formatted, locality: value.address.locality, country: value.address.country, postalCode: value.address.postalCode } || '',
              addressLocation: value.location || '',
            },
            value.address.locality.length > 0 || value.address.country.length > 0,
          );
        }}
        locationModalHeader={locationModalHeader}
      />
    </>
  );
};

export const PhoneFieldComponent: ReactComponentType = ({ ...props }: React.HTMLAttributes<unknown> & Components.IonInput & LocationFieldComponentProps) => {
  const preferredCountries = ['fr', 'it', 'pl', 'gr', 'fi', 'cy', 'be', 'pt', 'gb'];
  const { form } = props;
  const [phoneNumber, setPhoneNumber] = useState('');
  return (
    <PhoneInput
      {...props}
      country="fr"
      preferredCountries={preferredCountries}
      value={phoneNumber}
      autoFormat
      countryCodeEditable={false}
      onChange={phone => {
        setPhoneNumber(phone);
        form.setFieldValue('phone', '+' + phone);
      }}
    />
  );
};

function getTypeFields(): { value: string; label: string }[] {
  return [
    { value: 'ngo', label: i18next.t('organization.categories.ngo') },
    { value: 'public_structure', label: `${i18next.t('organization.categories.public-structure')} (${i18next.t('organization.pending-validation-page')})` },
    { value: 'reuse_organization', label: `${i18next.t('organization.categories.reuse-organization')} (${i18next.t('organization.pending-validation-page')})` },
    { value: 'public_sector', label: `${i18next.t('organization.categories.public-sector')} (${i18next.t('organization.pending-validation-page')})` },
  ];
}

function getGenderSelect(): { value: string; label: string }[] {
  return [
    { value: 'female', label: i18next.t('user.genders.woman') },
    { value: 'male', label: i18next.t('user.genders.man') },
    { value: 'other', label: i18next.t('user.genders.other') },
  ];
}

export type UserFormValues = Partial<CurrentUserEditFields>;
export type UserPicturesFormValues = { images: File[] | Media[]; avatar: Media | File[] | null };

export interface UserFormProps {
  fields: (keyof UserFormValues)[];
  initialValues: UserFormValues;
  postUserAction: (user: Partial<UserFormValues>) => Promise<CurrentUser>;
  redirectionRoute?: string;
  submitButtonText?: string;
  onSubmitErrors?: (actions: FormikHelpers<FormValues>, errors: Record<string, string>) => Promise<void>;
  onAfterSubmitFormSuccess?: (values: Readonly<FormValues>, actions: FormikHelpers<FormValues>) => Promise<void>;
  submitFormButton?: React.FunctionComponent<FormikProps<FormValues>>;
  setValidationStep?: (formValues: FormikProps<FormValues>) => void;
  touchOnLoad?: boolean;
  fieldsToTouchOnLoad?: (keyof UserFormValues)[];
  setDirty?: (dirty: boolean) => void;
  currentUserThematics?: Thematic[];
  shouldAvatarComponentChange?: boolean;
  overriddenFields?: FormFields;
  isOrganizationRegister?: boolean;
  locationModalHeader?: React.FunctionComponent<MouseEventHandler>;
  submitOnAddressChange?: boolean;
}

type Props = UserFormProps & DispatchProps & WithTranslation & RouteComponentProps<Record<string, never>>;

class UserForm extends Component<Props> {
  static constraints = {
    passwordMinLength: 6,
    aboutInfoMinLength: 2,
    aboutInfoMaxLength: 2000,
  };

  userValidationFields = {
    email: Yup.string()
      .email('user.email-invalid')
      .required(this.props.isOrganizationRegister ? 'organization.contact-email-required' : 'user.email-required'),
    firstname: Yup.string()
      .required(this.props.isOrganizationRegister ? 'organization.contact-firstname-required' : 'user.firstname-required')
      .when('lastname', {
        is: (val: string) => val?.toLowerCase() == 'account',
        then: Yup.string().test('existsCheck', 'user.name-invalid', value => value?.toLowerCase() !== 'apple'),
      }),
    lastname: this.props.isOrganizationRegister ? Yup.string().required('organization.contact-lastname-required') : Yup.string().nullable().ensure(),
    phone: Yup.string()
      .nullable()
      .matches(/^([+])(\d)+$/, 'user.phone-invalid')
      .required('organization.contact-phone-required'),
    gender: Yup.string()
      .nullable()
      .oneOf(getGenderSelect().map(gender => gender.value))
      .required('user.gender-required'),
    plainPassword: Yup.string()
      .required('user.password-required')
      .matches(/^(?=.*?[A-Z])(?=(.*[a-z])+)(?=(.*[\d])+)(?=(.*[\W])+)(?!.*\s).{8,}$/, 'user.password-format-required'),
    confirmPassword: Yup.string()
      .required('user.confirm-password-required')
      .oneOf([Yup.ref('plainPassword'), ''], 'user.password-must-match'),
    avatar: Yup.mixed().nullable(),
    aboutMe: Yup.string()
      .min(UserForm.constraints.aboutInfoMinLength, i18next.t('post.error-min', { min: UserForm.constraints.aboutInfoMinLength }))
      .max(UserForm.constraints.aboutInfoMaxLength, i18next.t('post.error-max', { max: UserForm.constraints.aboutInfoMaxLength })),
    aboutMeOnIndigo: Yup.string()
      .min(UserForm.constraints.aboutInfoMinLength, i18next.t('post.error-min', { min: UserForm.constraints.aboutInfoMinLength }))
      .max(UserForm.constraints.aboutInfoMaxLength, i18next.t('post.error-max', { max: UserForm.constraints.aboutInfoMaxLength })),
    name: Yup.string().required('organization.name-required'),
    type: Yup.string().required('organization.type-required'),
    lastTosAcceptedDate: Yup.mixed().required(),
    activity: Yup.string().required('organization.activity-required'),
    address: Yup.mixed()
      .test('checkIfAddressIsComplete', 'user.address-is-incomplete', value => {
        return value === null || !!value?.formatted;
      })
      .required('user.address-required')
      .nullable(),
    website: Yup.string().url('organization.url-invalid').nullable(),
  };

  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (!locationIsAPageWithUserForm()) {
      return false;
    }
    return !isEqualIgnoringFunctions(nextProps, this.props);
  }

  public render(): ReactNode {
    if (this.props.fields.length === 0) {
      return <></>;
    }

    return (
      <DynamicForm
        onSubmitForm={this.submitForm}
        onSubmitErrors={this.props.onSubmitErrors}
        onAfterSubmitFormSuccess={this.props.onAfterSubmitFormSuccess || this.onAfterSubmitFormSuccess}
        onFieldChange={this.changeProfileFieldsState}
        formFields={this.getFormFields()}
        validationSchema={this.getFormSchema()}
        initialValues={this.props.initialValues as FormValues}
        constraints={UserForm.constraints}
        submitButtonText={this.props.submitButtonText}
        dynamicFormSubmitButton={this.props.submitFormButton}
        touchOnLoad={this.props.touchOnLoad}
        fieldsToTouchOnLoad={this.props.fieldsToTouchOnLoad}
      />
    );
  }

  private changeProfileFieldsState = (fieldName: string, value: string | null | undefined, form: FormikProps<FormValues>): void => {
    if (this.props.setDirty) {
      this.props.setDirty(form?.dirty);
    }

    if (fieldName === 'locale' && value) {
      this.props.setAppLanguage(value);
    }

    if (this.props.setValidationStep) {
      this.props.setValidationStep(form);
    }
  };

  private getFormFields(): FormFields {
    const allFields: FormFields = [
      {
        name: 'avatar',
        formComponent: InputMediaUploader,
        options: { maxFiles: 1, multiple: false, isMediaUploaderField: true, picturePlaceholder: profilePicturePlaceholder, addPicturePlaceholder: addProfilePicturePlaceholder },
        itemLines: 'none',
      },
      {
        label: 'user.choose-lang',
        name: 'locale',
        formComponent: SelectField,
        withoutIonItem: true,
        options: { autocapitalize: 'on', type: 'text', required: true, clearOnEdit: false, selectOptions: localesLabels, multiple: false, interface: 'alert', displayValidationIcon: true },
      },
      {
        label: 'user.email-address',
        name: 'email',
        formComponent: InputWithValidationFieldComponent,
        options: { autocapitalize: 'off', type: 'email', required: true, clearOnEdit: false, clearInput: false, autocomplete: 'email', spellcheck: true, autocorrect: 'on' },
      },
      {
        label: 'user.password',
        name: 'plainPassword',
        formComponent: InputPasswordToggle,
        options: { autocapitalize: 'off', type: 'password', required: true, clearOnEdit: false, autocomplete: 'new-password', clearInput: true },
        withoutIonItem: true,
      },
      {
        label: 'user.confirm-password',
        name: 'confirmPassword',
        formComponent: InputPasswordToggle,
        options: { autocapitalize: 'off', type: 'password', required: true, clearOnEdit: false, clearInput: true },
        withoutIonItem: true,
      },
      {
        label: 'organization.page-name',
        name: 'name',
        formComponent: InputWithValidationFieldComponent,
        options: { autocapitalize: 'on', type: 'text', required: true, clearOnEdit: false, clearInput: false, autocomplete: 'on', spellcheck: true, autocorrect: 'on' },
      },
      {
        label: 'user.firstname',
        name: 'firstname',
        formComponent: InputWithValidationFieldComponent,
        options: { autocapitalize: 'on', type: 'text', required: true, clearOnEdit: false, clearInput: false, autocomplete: 'given-name', spellcheck: true, autocorrect: 'on' },
      },
      {
        label: 'user.lastname',
        name: 'lastname',
        formComponent: InputWithValidationFieldComponent,
        options: {
          autocapitalize: 'on',
          type: 'text',
          required: this.props.isOrganizationRegister,
          clearOnEdit: false,
          clearInput: false,
          autocomplete: 'family-name',
          spellcheck: true,
          autocorrect: 'on',
        },
      },
      {
        name: 'address',
        formComponent: (props: LocationFieldComponentProps) => <LocationFieldComponent {...props} locationModalHeader={this.props.locationModalHeader} />,
        options: { type: 'text', required: true, clearOnEdit: false, clearInput: false },
      },
      {
        name: 'favoriteCategoryThematics',
        formComponent: ThematicTagItems,
        options: { autocapitalize: 'on', type: 'text', required: true, clearOnEdit: false, clearInput: false, thematics: this.props.currentUserThematics, ...this.props },
        itemLines: 'none',
      },
      {
        name: 'phone',
        formComponent: PhoneFieldComponent,
        options: { placeholder: i18next.t('user.phone'), autocapitalize: 'on', type: 'tel', clearOnEdit: false, clearInput: false, ...this.props },
        withoutIonItem: true,
      },
      {
        label: 'user.gender',
        name: 'gender',
        formComponent: SelectField,
        withoutIonItem: true,
        options: {
          autocapitalize: 'on',
          type: 'text',
          required: true,
          clearOnEdit: false,
          useIonSegment: true,
          selectOptions: getGenderSelect(),
          multiple: false,
          displayValidationIcon: true,
        },
      },
      {
        name: 'lastTosAcceptedDate',
        formComponent: CheckboxCguFieldComponent,
        itemLines: 'none',
      },
      {
        name: 'newsletterSubscribed',
        formComponent: CheckboxNewsLetterFieldComponent,
        itemLines: 'none',
      },
      {
        label: 'organization.website',
        name: 'website',
        formComponent: InputWithValidationFieldComponent,
        options: { autocapitalize: 'off', type: 'url', required: false, clearOnEdit: false, clearInput: false },
      },
      {
        name: 'aboutMe',
        formComponent: TextAreaFieldComponent,
        options: {
          autocapitalize: 'on',
          type: 'text',
          required: true,
          clearOnEdit: false,
          clearInput: false,
          rows: 4,
          minlength: 2,
          maxlength: 2000,
          autocomplete: 'on',
          spellcheck: true,
          autocorrect: 'on',
        },
        itemLines: 'none',
      },
      {
        name: 'aboutMeOnIndigo',
        formComponent: TextAreaFieldComponent,
        options: {
          autocapitalize: 'on',
          type: 'text',
          required: true,
          clearOnEdit: false,
          clearInput: false,
          rows: 4,
          minlength: 2,
          maxlength: 2000,
          autocomplete: 'on',
          spellcheck: true,
          autocorrect: 'on',
        },
        itemLines: 'none',
      },
      {
        label: 'organization.type',
        name: 'type',
        formComponent: SelectField,
        withoutIonItem: true,
        options: { autocapitalize: 'on', type: 'text', required: true, clearOnEdit: false, selectOptions: getTypeFields(), multiple: false, interface: 'alert', displayValidationIcon: true },
      },
      {
        // TODO add activity component once ready
        label: 'organization.activity',
        name: 'activity',
        formComponent: InputWithValidationFieldComponent,
        options: {
          placeholder: i18next.t('organization.activity-placeholder'),
          autocapitalize: 'on',
          type: 'text',
          required: true,
          clearOnEdit: false,
          clearInput: true,
          spellcheck: true,
          autocorrect: 'on',
        },
        disableAutomaticSubmission: true,
      },
    ];
    return (
      // TODO Check this filter
      allFields
        .filter(value => this.props.fields.indexOf(value.name as keyof UserFormValues) !== -1)
        .map(fieldData => (this.props.overriddenFields || []).find(item => item.name === fieldData.name) || fieldData)
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getFormSchema(): Yup.ObjectSchema<any> {
    const schema: ObjectShape = {};
    this.props.fields.forEach(value => {
      if (Object.prototype.hasOwnProperty.call(this.userValidationFields, value)) {
        schema[value] = this.userValidationFields[value as never];
      }
    });

    return Yup.object().shape(schema);
  }

  private submitForm = async (values: Readonly<UserFormValues>): Promise<void> => {
    const setUserValue = <T extends UserFormValues, K extends keyof T>(user: T, key: K, value: T[K]): void => {
      user[key] = value;
    };

    const user: UserFormValues = { '@id': values['@id'] as string };
    this.props.fields.forEach((field: keyof UserFormValues) => {
      setUserValue(user, field, values[field]);
    });

    await this.props.postUserAction(user);
  };

  private onAfterSubmitFormSuccess = async (values: Readonly<FormValues>, actions: FormikHelpers<FormValues>): Promise<void> => {
    actions.resetForm({ values: { ...values, plainPassword: '', confirmPassword: '' } });
    if (this.props.redirectionRoute) {
      this.props.history.push(this.props.redirectionRoute);
    }
  };
}

export default connect(null, mapDispatchToProps)(withTranslation()(withRouter(UserForm)));
