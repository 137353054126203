import { AnyAction, Middleware } from 'redux';
import { actions, RootState } from '../index';
import * as userActions from './actions';
import * as layoutActions from '../layout/actions';
import { ActionType, getType } from 'typesafe-actions';
import { ThunkResultDispatch } from '../types';

export const userMiddleware: Middleware<unknown, RootState> = store => next => (action: ActionType<typeof actions.app | AnyAction>) => {
  typeof action === 'function' ? action(next) : next(action);

  const dispatch = store.dispatch as ThunkResultDispatch;

  if (action.type === getType(userActions.addFavoriteUserAction.success)) {
    dispatch(layoutActions.setToastMessageAction('post.added-to-favorites-list'));
  }

  if (action.type === getType(userActions.deleteFavoriteUserAction.success)) {
    dispatch(layoutActions.setToastMessageAction('post.deleted-from-favorites-list'));
  }

  if (action.type === getType(userActions.addUserReviewAction.failure)) {
    dispatch(layoutActions.setToastMessageAction('review.already-reviewed'));
  }

  if (action.type === getType(userActions.addUserReviewAction.success)) {
    dispatch(layoutActions.setToastMessageAction('review.review-saved'));
  }

  if (action.type === getType(userActions.deleteCurrentUserAction.success)) {
    dispatch(layoutActions.setToastMessageAction('user.delete-current-user-account-success'));
  }

  if (action.type === getType(userActions.fetchUserAction.failure)) {
    dispatch(layoutActions.setToastMessageAction(action.payload));
  }

  const toastMessagesActions = [getType(userActions.addFavoriteUserAction.failure), getType(userActions.deleteCurrentUserAction.failure), getType(userActions.deleteFavoriteUserAction.failure)];

  if (toastMessagesActions.indexOf(action.type) !== -1) {
    dispatch(layoutActions.setToastMessageAction(action.payload.message));
  }
};
