import React from 'react';
import { IonChip, IonIcon, IonLabel } from '@ionic/react';
import { Trans } from 'react-i18next';
import { checkmark } from 'ionicons/icons';
import './CertifiedChip.scss';

const CertifiedChip: React.FunctionComponent = () => {
  return (
    <IonChip slot="end" className="ion-chip-certified">
      <IonLabel>
        <Trans i18nKey={'organization.certified'} />
      </IonLabel>
      <IonIcon size="small" icon={checkmark} className="ion-icon-certified" />
    </IonChip>
  );
};

export default CertifiedChip;
