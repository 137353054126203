import React, { Component, ReactNode } from 'react';
import { IonModal, IonItem, IonButton, IonIcon, IonLabel, IonContent, IonSearchbar, SearchbarInputEventDetail } from '@ionic/react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { Category } from '../store/categories/types';
import get from 'lodash/get';
import { extractId, isSameHydraEntity, unfocusInput, searchNormalize } from '../utils/helpers';
import { getCategoryParents } from '../utils/categoriesHelpers';
import { IconComponent } from './common/Icon';
import './CategoriesModal.scss';
import i18next from 'i18next';
import CommonHeader from './CommonHeader';
import { getItemTranslation } from '../utils/translation';
import { boldString } from '../utils/stringsHelper';
import { IonSearchbarCustomEvent } from '@ionic/core';

interface ModalProps {
  isCategoryModalOpen: boolean;
  closeCategoriesModal: () => void;
  categories: Category[];
  setNewCategory: (category: Category) => void;
  categoryClicked?: Category | undefined;
}

interface State {
  selectedCategory: Category | null;
  subCategories: Category[] | null;
  searchText: string;
  searchCategoriesResult: Category[];
}

type Props = RouteComponentProps & ModalProps & WithTranslation;

class CategoriesModal extends Component<Props, State> {
  private readonly ionSearchBarRef: React.RefObject<HTMLIonSearchbarElement>;
  constructor(props: Props) {
    super(props);
    this.ionSearchBarRef = React.createRef<HTMLIonSearchbarElement>();
    this.state = {
      selectedCategory: null,
      subCategories: null,
      searchText: '',
      searchCategoriesResult: [],
    };
  }

  public componentDidMount(): void {
    if (this.props.categoryClicked && this.props.categories.includes(this.props.categoryClicked)) {
      this.onCategoryClick(this.props.categoryClicked);
    }
  }

  public render(): ReactNode {
    const { isCategoryModalOpen } = this.props;
    const { searchText } = this.state;

    return (
      <IonModal isOpen={isCategoryModalOpen} onDidDismiss={this.props.closeCategoriesModal} className="category-modal safe-area-ios" data-cy="categories-modal">
        <CommonHeader title={i18next.t('post.choose-category')} stopPropagation onBackButtonClick={this.onBackButtonClick} />
        <IonSearchbar
          searchIcon="/assets/form/search.svg"
          ref={this.ionSearchBarRef}
          className="search-bar"
          value={searchText}
          placeholder={i18next.t('common.search')}
          onKeyPress={this.handleKeyboardClick}
          onIonInput={this.handleInputEvent}
          onIonClear={this.focusSearchBar}
          spellcheck={true}
          autocorrect="on"
          autocomplete="on"
          enterkeyhint="search"
        />
        <IonContent data-cy="category-modal-selector">{this.categoriesModalContent()}</IonContent>
      </IonModal>
    );
  }

  private categoriesModalContent = (): ReactNode => {
    //return search result if searchCategoriesResult
    if (this.state.searchText && this.state.searchCategoriesResult.length !== 0) {
      return this.searchCategoriesList();
    }

    //return error if searchCategoriesResult is empty
    if (this.state.searchText && this.state.searchCategoriesResult.length === 0) {
      return (
        <div className="no-result-search" data-cy="no-result-search">
          <div className="no-result-text">
            <Trans i18nKey="post.no-search-result" />
          </div>
        </div>
      );
    }

    //return all categories if no selectedCategory
    if (this.state.selectedCategory === null) {
      return this.categoriesList();
    }

    //return selectedCategory
    return (
      <>
        {this.subCategoriesList()}
        <IonItem key={extractId(this.state.selectedCategory)} onClick={() => this.selectCategory(this.state.selectedCategory as Category)}>
          <IonLabel className="category-item">
            <div className="label-text">
              <span className="category-label">
                <Trans
                  i18nKey={'post.other-category'}
                  values={{ categoryTitle: get(this.state.selectedCategory, `translations[${this.props.i18n.language}]`, this.state.selectedCategory.name).toLowerCase() }}
                />
              </span>
            </div>
            <IonButton className="arrow-forward-button" fill="clear">
              <IonIcon icon="/assets/navigation/ellipse_outline.svg" />
            </IonButton>
          </IonLabel>
        </IonItem>
      </>
    );
  };

  private handleInputEvent = (e: IonSearchbarCustomEvent<SearchbarInputEventDetail>): void => {
    const searchText = e.detail.value ? e.detail.value.charAt(0).toUpperCase() + e.detail.value.slice(1) : '';
    this.setState({
      searchText,
      searchCategoriesResult: this.props.categories.filter(cat => searchNormalize(getItemTranslation(cat, cat.name)).toLowerCase().includes(searchNormalize(searchText).toLowerCase())),
    });
  };

  private focusSearchBar = (): void => {
    if (!this.ionSearchBarRef.current) {
      return;
    }

    this.ionSearchBarRef.current.setFocus();
  };

  private handleKeyboardClick = (e: React.KeyboardEvent<HTMLIonSearchbarElement>): void => {
    if (e.type === 'keypress' && (e as React.KeyboardEvent<HTMLIonSearchbarElement>).key === 'Enter') {
      unfocusInput(this.ionSearchBarRef);
    }
  };

  private selectCategory(category: Category): void {
    this.setState({ selectedCategory: null });
    this.props.setNewCategory(category);
    this.props.closeCategoriesModal();
  }

  private onCategoryClick(category: Category): void {
    const subCategories = this.props.categories.filter(cat => isSameHydraEntity(cat.parent, category));

    if (category.parent && !subCategories.length) {
      this.selectCategory(category);
      return;
    }

    if (!subCategories.length) {
      this.selectCategory(category);
      return;
    }

    this.setState({ subCategories, selectedCategory: category });
  }

  private categoryHasChild(category: Category): boolean {
    const subCategories = this.props.categories.filter(cat => isSameHydraEntity(cat.parent, category));
    return !!subCategories.length;
  }

  private onBackButtonClick = (): void => {
    if (this.state.selectedCategory !== null) {
      this.setState({ selectedCategory: null });
      return;
    }

    this.props.closeCategoriesModal();
  };

  private categoriesList = (): ReactNode => this.props.categories.filter(category => !category.parent).map(this.categoryItem);
  private subCategoriesList = (): ReactNode => this.state.subCategories?.map(this.categoryItem);
  private searchCategoriesList = (): ReactNode => this.state.searchCategoriesResult.map(this.categorySearchItem);

  private categoryItem = (category: Category): ReactNode => {
    return (
      <IonItem key={extractId(category)} onClick={() => this.onCategoryClick(category)}>
        {category.icon && <IconComponent icon={category.icon} />}
        <IonLabel className="category-item">
          <div className="label-text">
            <span className="category-label">{getItemTranslation(category, category.name)}</span>
          </div>
          <IonButton className="arrow-forward-button" fill="clear">
            <IonIcon src={this.categoryHasChild(category) ? '/assets/navigation/chevron-forward.svg' : '/assets/navigation/ellipse_outline.svg'} />
          </IonButton>
        </IonLabel>
      </IonItem>
    );
  };

  private onSearchCategoryClick(category: Category): void {
    const subCategories = this.props.categories.filter(cat => isSameHydraEntity(cat.parent, category));
    if (subCategories.length) {
      this.setState({ subCategories, selectedCategory: category, searchText: '', searchCategoriesResult: [] });
      return;
    }
    this.selectCategory(category);
  }

  private categorySearchItem = (category: Category): ReactNode => {
    const categories = getCategoryParents(this.props.categories, category).reverse();
    return (
      <IonItem className="search-item" key={extractId(category)} onClick={() => this.onSearchCategoryClick(category)}>
        {categories.map((category: Category, index) => (
          <div key={index}>
            <IonLabel className="category-item">
              {category.icon && <IconComponent icon={category.icon} />}
              <div className="search-label-text">
                <span className="category-label" dangerouslySetInnerHTML={{ __html: boldString(getItemTranslation(category, category.name), this.state.searchText) }} />
              </div>
              {index !== categories.length - 1 && (
                <IonButton className="arrow-forward-button" fill="clear">
                  <IonIcon icon="/assets/navigation/chevron-forward.svg" />
                </IonButton>
              )}
            </IonLabel>
          </div>
        ))}
      </IonItem>
    );
  };
}

export default withTranslation()(withRouter(CategoriesModal));
