import React from 'react';
import { truncate } from 'lodash';

interface ResponsiveTitleProps {
  text: string;
  sliderMode?: boolean;
}

const TEXTLENGTH_TO_FONTSIZE_MAP: Map<number, number> = new Map([
  [2, 100],
  [3, 67],
  [4, 56],
  [5, 52],
  [6, 43],
  [7, 37],
  [8, 33],
  [9, 29],
  [10, 26],
  [11, 23],
  [12, 22],
  [13, 21],
  [14, 18],
  [15, 17],
]);

const TEXTLENGTH_TO_MULTIPLIER_MAP: Map<number, number> = new Map([
  [80, 1.04],
  [74, 1.1],
  [27, 1.15],
  [23, 1.2],
  [14, 1.22],
  [5, 1.24],
]);

/**
 * Reproduce a behavior close to fitty library (auto scale font-size)
 * https://github.com/rikschennink/fitty
 *
 * 15/07/20 : due to project and code structure, using fitty to auto scale the font size causing UI
 * bugs. We have to setTimeout to let fitty do its job correctly.
 * Nevertheless, this solution does not work everytime and it is weird.
 * So, this "correspondence map" brings us closer to the needs (in addition it's more performant).
 */
const ResponsiveTitle: React.FunctionComponent<ResponsiveTitleProps> = ({ text = '', sliderMode = false }: ResponsiveTitleProps) => {
  text = text.trim();
  const length = text.length;
  const hasSpace = text.indexOf(' ') !== -1;
  let fontSize = 16;

  if (length >= 2 && length <= 15) {
    const calculatedFontSize = TEXTLENGTH_TO_FONTSIZE_MAP.get(length);
    if (calculatedFontSize) {
      fontSize = calculatedFontSize;
    }
  }

  if (hasSpace) {
    if (length > 4) {
      const calculatedFontSize = TEXTLENGTH_TO_MULTIPLIER_MAP.get(length);
      if (calculatedFontSize) {
        fontSize *= calculatedFontSize;
      }
    }
  }

  // in slider mode, we have less space, but we apply
  // the same logic as above.
  // => so, it's all about "proportion"
  if (sliderMode) {
    fontSize *= window.innerWidth > 370 ? 0.55 : 0.45;
  }
  text = truncate(text, { length: 80, omission: '...' });

  return <span style={{ whiteSpace: 'normal', display: 'inline-block', fontSize: `${fontSize}px` }}>{text}</span>;
};

export default ResponsiveTitle;
