import React from 'react';
import { IonBadge, IonIcon, IonLabel, IonRippleEffect, IonTabButton, IonThumbnail } from '@ionic/react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { actions, RootState } from '../store';
import { Media } from '../store/app/types';
import AddPostModal from './AddPostModal';
import { getUserAvatar } from '../utils/helpers';
import { POSTS_TAB, SEARCH_TAB, CREATE_POSTS_TAB, CHATS_TAB, ME_TAB } from '../utils/TabNames';
import { setStatusBarStyle } from '../utils/statusBarHelper';
import ImageFadeIn from './ImageFadeIn';
import { setRedirectAfterLogin } from '../utils/routeHelpers';
import { translateDigits } from '../utils/translation';
import { bindActionCreators, Dispatch } from 'redux';
import './TabBar.scss';

interface StateProps {
  managedUsersTotalItems: number;
  currentUserUnreadChats: number;
  currentUserAvatar: string | undefined;
  activeTab: string | undefined;
  addPostModalIsOpen: boolean;
}

const mapStateToProps = (state: RootState): StateProps => ({
  managedUsersTotalItems: state.whoAmI.managedUsers?.totalItems || 0,
  currentUserUnreadChats: state.app.currentUser.status?.unreadChats || 0,
  currentUserAvatar: (state.app.currentUser.avatar as Media)?.contentUrl,
  activeTab: state.navigation.activeTab,
  addPostModalIsOpen: state.app.addPostModalIsOpen,
});

interface DispatchProps {
  setAddPostModalIsOpenAction(addPostModalIsOpen: boolean): void;
  setPopoverConnectionIsOpenAction(popoverConnectionIsOpen: boolean): void;
}

const propsToDispatch = {
  setAddPostModalIsOpenAction: actions.app.setAddPostModalIsOpenAction,
  setPopoverConnectionIsOpenAction: actions.app.setPopoverConnectionIsOpenAction,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

interface TabBarProps {
  onTabChanged: (tabName: string) => void;
  hasCurrentUser?: boolean;
}

type Props = TabBarProps & DispatchProps & WithTranslation & StateProps;

const ASSET_PATH = '/assets/tab-bar-icons';

const TabBar: React.FunctionComponent<Props> = ({
  activeTab,
  currentUserUnreadChats,
  managedUsersTotalItems,
  currentUserAvatar,
  onTabChanged,
  hasCurrentUser,
  addPostModalIsOpen,
  setAddPostModalIsOpenAction,
  setPopoverConnectionIsOpenAction,
}: Props) => {
  const history = useHistory();
  const getActiveClass = (tabName: string): string => (activeTab === tabName ? 'tab-selected' : '');

  // icons
  const postsIcon = activeTab === POSTS_TAB ? `${ASSET_PATH}/selected-home.svg` : `${ASSET_PATH}/unselected-home.svg`;

  const searchIcon = activeTab === SEARCH_TAB ? `${ASSET_PATH}/selected-search.svg` : `${ASSET_PATH}/unselected-search.svg`;

  const createPostIcon = addPostModalIsOpen ? `${ASSET_PATH}/selected-create.svg` : `${ASSET_PATH}/unselected-create.svg`;

  const chatsIcon = activeTab === CHATS_TAB ? `${ASSET_PATH}/selected-messages.svg` : `${ASSET_PATH}/unselected-messages.svg`;

  const meIcon = activeTab === ME_TAB ? `${ASSET_PATH}/selected-profile.svg` : `${ASSET_PATH}/unselected-profile.svg`;

  const loginIcon = activeTab === ME_TAB ? `${ASSET_PATH}/selected-login.svg` : `${ASSET_PATH}/unselected-login.svg`;

  const changeTabIfConnected = (tabName: string): void => {
    if (!hasCurrentUser) {
      // All tabs except login button
      setRedirectAfterLogin(`/${tabName}`);
      history.push('/homepage');
    }
    onTabChanged(tabName);
  };

  const onCreatePostClick = (addPostModalStatus: boolean): void => {
    if (!hasCurrentUser) {
      setPopoverConnectionIsOpenAction(true);
      return;
    }

    setAddPostModalIsOpenAction(addPostModalStatus);
    setStatusBarStyle();
  };

  return (
    <div className="tab-bar">
      {/* POSTS */}
      <IonTabButton tab={POSTS_TAB} href="/posts" className={getActiveClass(POSTS_TAB)} onClick={() => onTabChanged(POSTS_TAB)}>
        <IonIcon src={postsIcon} className="posts-icon" />
        <IonLabel>
          <Trans i18nKey="common.home" />
        </IonLabel>
        <IonRippleEffect />
      </IonTabButton>

      {/* SEARCH */}
      <IonTabButton tab={SEARCH_TAB} href="/search" className={getActiveClass(SEARCH_TAB)} onClick={() => onTabChanged(SEARCH_TAB)}>
        <IonIcon src={searchIcon} />
        <IonLabel>
          <Trans i18nKey="common.search" />
        </IonLabel>
        <IonRippleEffect />
      </IonTabButton>

      {/* CREATE POST */}
      <IonTabButton tab={CREATE_POSTS_TAB} onClick={() => onCreatePostClick(true)} className={getActiveClass(CREATE_POSTS_TAB)}>
        <IonIcon src={createPostIcon} className="create-post" />
        <IonLabel>
          <Trans i18nKey="common.post" />
        </IonLabel>
        <IonRippleEffect />
        <AddPostModal isOpen={addPostModalIsOpen} closeModal={() => onCreatePostClick(false)} />
      </IonTabButton>
      {/* CHATS */}
      <IonTabButton tab={CHATS_TAB} href="/chats" onClick={() => changeTabIfConnected(CHATS_TAB)} className={getActiveClass(CHATS_TAB)}>
        <IonIcon src={chatsIcon} />
        <IonLabel>
          <Trans i18nKey="common.messages" />
        </IonLabel>
        <IonRippleEffect />
        {currentUserUnreadChats > 0 && (
          <IonBadge color="danger" className="count-badge">
            {translateDigits(currentUserUnreadChats)}
          </IonBadge>
        )}
      </IonTabButton>
      {/* ME */}
      <IonTabButton data-cy="me-tab" tab={ME_TAB} href="/me" onClick={() => changeTabIfConnected(ME_TAB)} className={getActiveClass(ME_TAB)}>
        {hasCurrentUser ? (
          <>
            {!managedUsersTotalItems ? (
              <IonIcon src={meIcon} />
            ) : (
              <IonThumbnail className="managed-user-avatar">
                <ImageFadeIn thumbnail src={getUserAvatar(currentUserAvatar)} />
              </IonThumbnail>
            )}
          </>
        ) : (
          <IonIcon icon={loginIcon} />
        )}
        <IonLabel>
          <Trans i18nKey={hasCurrentUser ? 'common.profile' : 'user.login'} />
        </IonLabel>
        <IonRippleEffect />
      </IonTabButton>

      {/* slide cursor */}
      <div className="slide-cursor" />
    </div>
  );
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TabBar)));
