import React from 'react';
import { IonContent, IonItem, IonModal, IonList, IonTitle, IonButton } from '@ionic/react';
import { Trans } from 'react-i18next';
import './AddPageModal.scss';

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const AddPageModal: React.FunctionComponent<ModalProps> = (props: ModalProps) => {
  return (
    <IonModal isOpen={props.isOpen} onDidDismiss={props.closeModal} className="add-page-modal safe-area-ios">
      <IonContent>
        <IonList>
          <IonItem lines="none" className="title-item">
            <IonTitle>
              <Trans i18nKey="organization.add-page" />
            </IonTitle>
          </IonItem>
          <IonButton shape="round" routerLink="/login_organization" className="button-large modal-button connect-button">
            <Trans i18nKey="organization.connect-to-page" />
          </IonButton>
          <IonButton fill="clear" routerLink="/register_organization" className="modal-button create-button">
            <Trans i18nKey="organization.create-your-page" />
          </IonButton>
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default AddPageModal;
