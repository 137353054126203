import React, { PureComponent, ReactNode } from 'react';
import CommonHeader from '../components/CommonHeader';
import { Message } from '../store/chats/types';
import { EntityReference, HydratedCollection } from '../utils/hydra';
import { actions, RootState } from '../store';
import { getHydratedChatMessagesSortedByDate } from '../store/chats/selectors';
import { ChatPageProps } from './ChatPage';
import { bindActionCreators, Dispatch } from 'redux';
import { Trans, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IonAvatar, IonButton, IonFooter, IonIcon, IonImg, IonInput, IonItem, IonPage, withIonLifeCycle } from '@ionic/react';
import { send } from 'ionicons/icons';
import { Redirect } from 'react-router';
import CommentsList from '../components/CommentsList';
import i18next from 'i18next';
import { getUserAvatar, onKeyboardEnterExecuteFunction } from '../utils/helpers';
import { getCurrentUser } from '../store/app/selectors';
import { CurrentUser } from '../store/users/types';

import './CommentsPage.scss';

interface State {
  newMessage: string;
}

export interface CommentsPageProps {
  chatId: EntityReference | null;
}

interface StateProps {
  chatMessages: HydratedCollection<Message>;
  currentUser: CurrentUser;
  activeTab?: string;
}

const mapStateToProps = (state: RootState, props: ChatPageProps): StateProps => ({
  chatMessages: getHydratedChatMessagesSortedByDate(state, props),
  currentUser: getCurrentUser(state),
  activeTab: state.navigation.activeTab,
});

interface DispatchProps {
  fetchChatMessages: (chatId: EntityReference, page?: string) => Promise<void>;
  sendChatMessage: (chatId: EntityReference, message: string) => Promise<EntityReference>;
  fetchPost: (post: EntityReference) => Promise<void>;
}

const propsToDispatch = {
  fetchChatMessages: actions.chats.fetchChatMessages,
  sendChatMessage: actions.chats.sendChatMessage,
  fetchPost: actions.posts.fetchPost,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type CommentsProps = CommentsPageProps & DispatchProps & StateProps & WithTranslation;

class CommentsPage extends PureComponent<CommentsProps, State> {
  private readonly ionInputRef: React.RefObject<HTMLIonInputElement>;

  public constructor(props: CommentsProps) {
    super(props);
    this.ionInputRef = React.createRef<HTMLIonInputElement>();
    this.state = {
      newMessage: '',
    };
  }

  public componentDidMount(): void {
    this.updateComponent();
  }

  public componentDidUpdate(prevProps: Readonly<CommentsProps>): void {
    if (prevProps.chatMessages.totalItems === this.props.chatMessages.totalItems && this.props.chatId === prevProps.chatId) {
      return;
    }

    this.updateComponent();
  }

  private sendMessage = (): void => {
    if (!this.props.chatId || !this.state.newMessage.trim()) {
      return;
    }

    this.props.sendChatMessage(this.props.chatId, this.state.newMessage.trim()).then(() => this.updateComponent());
    this.setState({ newMessage: '' });
  };

  private async updateComponent(): Promise<void> {
    if (!this.props.chatId) {
      return;
    }

    await this.props.fetchChatMessages(this.props.chatId);
  }

  public render(): ReactNode {
    const { chatMessages, chatId } = this.props;

    if (!chatId) {
      return <Redirect to="/posts/" />;
    }
    return (
      <IonPage data-cy="comments-page">
        <CommonHeader addIonHeader={true} title={<Trans i18nKey="chat.comments-title" />} />
        <CommentsList
          activeTab={this.props.activeTab}
          chatId={chatId}
          messages={this.props.chatMessages}
          fetchNextMessagesPage={() => this.props.fetchChatMessages(chatId, chatMessages?.nextPage || '/messages')}
        />
        <IonFooter className="comments-page-footer">
          <IonItem lines="none">
            <IonAvatar className="current-user-avatar" slot="start">
              <IonImg alt="" src={getUserAvatar(this.props.currentUser)} />
            </IonAvatar>
            <IonInput
              ref={this.ionInputRef}
              className="message-input"
              placeholder={i18next.t('chat.add-comment')}
              value={this.state.newMessage}
              autocapitalize="on"
              onIonChange={e => {
                this.setState({ newMessage: e.detail.value || '' });
              }}
              onKeyDown={e => onKeyboardEnterExecuteFunction(e, this.sendMessage)}
              spellCheck={true}
              autoCorrect={'on'}
              autocomplete={'on'}
            />
            <IonButton onClick={this.sendMessage} fill="clear" size="default" className="send-button">
              <IonIcon icon={send} mode="md" />
            </IonButton>
          </IonItem>
        </IonFooter>
      </IonPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withIonLifeCycle(CommentsPage));
