import { IonButton, IonButtons, IonChip, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonRange, IonRow, IonSelect, IonSelectOption, IonToolbar } from '@ionic/react';
import get from 'lodash/get';
import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { WeightUnit, weightUnits } from '../store/posts/types';
import { Category, Discriminant } from '../store/categories/types';
import { getCategoryAverageWeight } from '../utils/categoriesHelpers';
import { getFixedWeight, getWeightInUnit, getWeightWithAppropriateUnit, isSameHydraEntity } from '../utils/helpers';
import { actions } from '../store';
import { getItemTranslation } from '../utils/translation';

import './PostCo2EstimationSteps.scss';

// Here are the possible steps for the CO2 estimation by a user
// 'weight': the weight and quantity selection
// 'discriminants': the discriminants selection
export type Co2EstimationStep = 'weight' | 'discriminants';

export const maxEstimatedWeight = 100000;
export const minEstimatedWeight = 0.001;
export const maxQuantity = 999999;
export const minQuantity = 1; // Number of object given in the post by default
const maxSelectableDiscriminants = 1;

interface StepSubmissionButtonsProps {
  onSubmit: () => void;
  onBackButtonClick: () => void;
  steps: Co2EstimationStep[];
  currentStep: Co2EstimationStep;
}

const StepSubmissionButtons: React.FunctionComponent<StepSubmissionButtonsProps> = ({ onSubmit, onBackButtonClick, steps, currentStep }: StepSubmissionButtonsProps) => {
  const currentStepId = steps.findIndex(step => {
    if (step === currentStep) {
      return true;
    }
  });

  const hasPrevStep = currentStepId > 0;
  const hasNextStep = currentStepId < steps.length - 1;

  return (
    <IonGrid>
      <IonRow className="step-submission-buttons">
        <IonCol size="6" className="left-box">
          {hasPrevStep && (
            <IonButtons className="back-button cursor-pointer" onClick={onBackButtonClick}>
              <IonIcon className="arrow-icon" icon="/assets/navigation/arrow-left-green.svg" slot="start" />
              <Trans i18nKey="common.back" />
            </IonButtons>
          )}
        </IonCol>

        <IonCol size="6">
          {hasNextStep && (
            <IonButton data-cy="continue-co2-estimation-button" className="main-button right-button" fill="solid" color="success" onClick={onSubmit}>
              <IonIcon className="arrow-icon" icon="/assets/navigation/arrow-right-white.svg" slot="end" />
              <Trans i18nKey="common.continue" />
            </IonButton>
          )}

          {!hasNextStep && (
            <IonButton data-cy="validate-co2-estimation-button" className="main-button right-button" fill="solid" color="success" onClick={onSubmit}>
              <Trans i18nKey="co2-estimation.validate" />
            </IonButton>
          )}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

interface WeightEstimationStepProps {
  onSelection: (quantity?: number | null, weight?: number | null) => void;
  category: Category;
  quantityProps?: number;
  estimatedWeightProps?: number | null;
  onBackButtonClick: () => void;
  steps: Co2EstimationStep[];
  currentStep: Co2EstimationStep;
}

const WeightEstimationStep: React.FunctionComponent<WeightEstimationStepProps> = ({
  onSelection,
  category,
  quantityProps,
  estimatedWeightProps,
  onBackButtonClick,
  steps,
  currentStep,
}: WeightEstimationStepProps) => {
  const getAverageWeight = (): number => {
    const averageWeight = getCategoryAverageWeight(category);
    return parseFloat(averageWeight?.toFixed(3));
  };

  const initialSelectedUnit = (): WeightUnit => {
    const averageWeight = getCategoryAverageWeight(category);
    return parseInt(averageWeight.toString()) > 0 ? 'kg' : 'g';
  };

  const [quantity, setQuantity] = useState<number>(minQuantity);
  const [inputWeight, setInputWeight] = useState<number | null>(null);
  const [selectedWeight, setSelectedWeight] = useState<number | null>(null);
  const [selectedUnit, setSelectedUnit] = useState<WeightUnit>(initialSelectedUnit());
  const [totalPound, setTotalPound] = useState<number>(0);

  // This trick is needed so the changes on the ion range will not update the selected weight without a user action
  const [ignoreWeightUpdate, setIgnoreWeightUpdate] = useState<boolean>(false);

  const [rangeValue] = useState<{
    lower: number;
    upper: number;
  }>({ lower: category.itemMinWeight ?? 0, upper: category.itemMaxWeight ?? 0 });

  const [ionRangeValue, setIonRangeValue] = useState<number>(estimatedWeightProps ?? getAverageWeight() ?? rangeValue.lower);

  const onIonRangeInputChange = (e: CustomEvent): void => {
    if (ignoreWeightUpdate) {
      setIgnoreWeightUpdate(false);
      return;
    }

    const upperValue = parseFloat(rangeValue.upper.toString());
    const lowerValue = parseFloat(rangeValue.lower.toString());
    const weight = parseFloat(e?.detail?.value.toString());

    if (!inputWeight || (inputWeight <= upperValue && inputWeight >= lowerValue)) {
      setSelectedWeight(weight >= 0 ? weight : null);
      return;
    }

    if (weight < upperValue && weight > lowerValue) {
      setSelectedWeight(weight >= 0 ? weight : null);
    }
  };

  const onQuantityInputChange = (inputValue: string | null): void => {
    const value: number = inputValue ? parseInt(inputValue) : 0;

    if (value < minQuantity) {
      setQuantity(minQuantity);
      return;
    }

    if (value > maxQuantity) {
      setQuantity(maxQuantity);
      return;
    }

    setQuantity(value);
  };

  const onWeightInputChange = (inputValue: string | null): void => {
    if (inputValue === null) {
      setInputWeight(null);
      return;
    }

    const value: number = parseFloat(inputValue ?? 0);

    if (!value) {
      setInputWeight(null);
      return;
    }

    const newValue: number = selectedUnit === 'kg' ? value : value / 1000;
    setInputWeight(newValue);
  };

  const onWeightInputBlur = (inputValue: string | null): void => {
    if (inputValue === null) {
      setInputWeight(null);
      setSelectedWeight(null);
      return;
    }

    const value: number = parseFloat(inputValue ?? 0);
    if (value <= 0) {
      setSelectedWeight(0); // We don't want to consider empty result
      return;
    }

    if (value > maxEstimatedWeight) {
      setSelectedWeight(maxEstimatedWeight);
      return;
    }

    if (!value) {
      setSelectedWeight(null);
      return;
    }

    const newValue: number = selectedUnit === 'kg' ? value : value / 1000;
    setSelectedWeight(newValue >= 0 ? newValue : null);
  };

  useEffect(() => {
    if (!quantity) {
      return;
    }

    if (selectedWeight) {
      setTotalPound(getFixedWeight(selectedWeight * quantity));
      return;
    }

    setTotalPound(getFixedWeight(getAverageWeight() * quantity));
  }, [quantity]);

  useEffect(() => {
    if (selectedWeight === null || selectedWeight <= 0) {
      setIgnoreWeightUpdate(true);
      setIonRangeValue(getAverageWeight());
      setInputWeight(null);
      setTotalPound(getFixedWeight(getAverageWeight() * quantity));
      return;
    }

    if (selectedWeight !== ionRangeValue) {
      setIonRangeValue(selectedWeight);
    }
    if (selectedWeight !== inputWeight) {
      setInputWeight(selectedWeight);
    }

    setTotalPound(getFixedWeight(selectedWeight * quantity));
  }, [selectedWeight]);

  useEffect(() => {
    if (quantityProps) {
      setQuantity(quantityProps);
    }

    if (estimatedWeightProps) {
      setSelectedWeight(estimatedWeightProps);
    }
  }, []);

  return (
    <div className="weight-estimation-step">
      <p className="co2-estimation-step-description">
        <Trans i18nKey="co2-estimation.weight-step-description" values={{ averageWeight: getWeightWithAppropriateUnit(getAverageWeight()) }} components={[<strong key="average-weight" />]} />
      </p>
      <div className="co2-estimation-step-content">
        <IonRow>
          <IonCol size="6" size-md="6" className="input-col">
            <IonLabel position="stacked">
              <Trans i18nKey="co2-estimation.object-number-placeholder" />
            </IonLabel>
            <IonInput
              className="input-quantity"
              data-cy="input-quantity"
              value={quantity}
              enterkeyhint="enter"
              autocapitalize="on"
              autoCorrect="on"
              inputmode="numeric"
              onIonChange={e => setQuantity(Number(get(e, 'target.value', 0)))} // We allow empty value while typing to see total pound updates in real time
              onIonBlur={e => onQuantityInputChange(get(e, 'target.value')?.toString() ?? null)}
              type="number"
              min="0"
            />
          </IonCol>

          <IonCol size="6" size-md="6" className="input-col">
            <IonLabel position="stacked">
              <Trans i18nKey="co2-estimation.weight-placeholder" />
            </IonLabel>
            <div className="weight-col">
              <IonInput
                className="weight-input"
                data-cy="input-weight"
                value={inputWeight !== null && inputWeight !== undefined ? getWeightInUnit(inputWeight, selectedUnit) : null}
                enterkeyhint="enter"
                autocapitalize="on"
                autoCorrect="on"
                inputmode="decimal"
                onIonChange={e => onWeightInputChange(get(e, 'target.value', 0).toString())} // We allow empty value while typing to see total pound updates in real time
                onIonBlur={e => onWeightInputBlur(get(e, 'target.value')?.toString() ?? null)}
                type="number"
                placeholder={getWeightInUnit(getAverageWeight(), selectedUnit).toString()}
                min="0"
              />
              <IonSelect
                className="weight-unit"
                data-cy="weight-unit"
                onIonChange={e => setSelectedUnit(get(e, 'target.value'))}
                autoCapitalize="on"
                multiple={false}
                value={selectedUnit}
                interface="popover"
              >
                {weightUnits.map(unit => (
                  <IonSelectOption key={unit} value={unit}>
                    {unit}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </div>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol size="12" size-md="12">
            <IonRange value={ionRangeValue} min={rangeValue.lower} max={rangeValue.upper} step={0.01} snaps={false} pin={false} onIonChange={onIonRangeInputChange}>
              <IonLabel slot="start">{getWeightWithAppropriateUnit(rangeValue.lower)}</IonLabel>
              <IonLabel slot="end">{getWeightWithAppropriateUnit(rangeValue.upper)}</IonLabel>
            </IonRange>
          </IonCol>
        </IonRow>
      </div>

      <IonItem className="pound" lines="none">
        <div className="total" data-cy="total-pound">
          <Trans i18nKey="co2-estimation.total-pound" />
          <div className="number">
            <b>{getWeightWithAppropriateUnit(totalPound)}</b>
          </div>
        </div>
      </IonItem>

      <div className="bottom-section">
        <div className="mascot dumbbells">
          <img src="assets/mascot/dumbbells.svg" alt="Indigo" />
        </div>
        <div className="background hemisphere">
          <img src="assets/background/hemisphere-white.svg" alt="Indigo" />
        </div>
        <StepSubmissionButtons
          onSubmit={() => onSelection(quantity, selectedWeight ? getFixedWeight(selectedWeight) : undefined)}
          onBackButtonClick={onBackButtonClick}
          steps={steps}
          currentStep={currentStep}
        />
      </div>
    </div>
  );
};

interface DiscriminantEstimationStepProps {
  onSelection: (discriminants: Discriminant[]) => void;
  onBackButtonClick: (discriminants: Discriminant[]) => void;
  discriminantsProps?: Discriminant[];
  discriminants: Discriminant[];
  steps: Co2EstimationStep[];
  currentStep: Co2EstimationStep;
}

const DiscriminantEstimationStep: React.FunctionComponent<DiscriminantEstimationStepProps> = ({
  onSelection,
  discriminants,
  discriminantsProps,
  onBackButtonClick,
  steps,
  currentStep,
}: DiscriminantEstimationStepProps) => {
  const [selectedDiscriminant, setSelectedDiscriminant] = useState<Discriminant | undefined>(undefined);

  const onDiscriminantClick = (discriminant: Discriminant): void => {
    if (isSameHydraEntity(discriminant, selectedDiscriminant)) {
      setSelectedDiscriminant(undefined);
      return;
    }

    setSelectedDiscriminant(discriminant);
  };

  useEffect(() => {
    if (discriminantsProps) {
      setSelectedDiscriminant(discriminantsProps[0]); // For now we allow to have only one discriminant
    }
  }, []);

  return (
    <div data-cy="discriminant-estimation-step" className="discriminant-estimation-step">
      <p className="co2-estimation-step-description">
        <Trans i18nKey="co2-estimation.discriminants-step-description" />
      </p>
      <div className="co2-estimation-step-content">
        <div className="discriminant-list">
          {discriminants.map(discriminant => {
            const isSelected = isSameHydraEntity(discriminant, selectedDiscriminant);
            return (
              <IonChip
                className={`text-overflow-chip discriminant-item ${isSelected ? 'is-selected' : ''}`}
                key={discriminant.name}
                onClick={() => onDiscriminantClick(discriminant)}
                outline={!isSelected}
              >
                <div className="text-overflow-chip-content">
                  <IonLabel>{getItemTranslation(discriminant, discriminant.name)}</IonLabel>
                  {isSelected && <IonIcon icon="/assets/icon/icon-close-modal-white.svg" />}
                </div>
              </IonChip>
            );
          })}
        </div>
      </div>

      <div className="bottom-section">
        <div className="mascot search">
          <img src="assets/mascot/search.svg" alt="Indigo" />
        </div>
        <div className="background hemisphere-down">
          <img src="assets/background/hemisphere-down-white.svg" alt="Indigo" />
        </div>
        <StepSubmissionButtons
          onSubmit={() => onSelection(selectedDiscriminant ? [selectedDiscriminant] : [])}
          onBackButtonClick={() => onBackButtonClick(selectedDiscriminant ? [selectedDiscriminant] : [])}
          steps={steps}
          currentStep={currentStep}
        />
      </div>
    </div>
  );
};

interface Co2EstimationStepsProps {
  steps: Co2EstimationStep[];
  updatePost: (quantity?: number | null, weight?: number | null, discriminants?: Discriminant[]) => void;
  category?: Category;
  discriminants: Discriminant[];
  discriminantsProps?: Discriminant[];
  quantityProps?: number;
  estimatedWeightProps?: number;
  onStepsEnd: () => void;
}

interface DispatchProps {
  setToastMessage: (message: string) => void;
}

const propsToDispatch = {
  setToastMessage: actions.layout.setToastMessageAction,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type PostCo2EstimationStepsProps = Co2EstimationStepsProps & DispatchProps;

const PostCo2EstimationSteps: React.FunctionComponent<PostCo2EstimationStepsProps> = (props: PostCo2EstimationStepsProps) => {
  const [selectedStep, setSelectedStep] = useState<Co2EstimationStep | undefined>(undefined);
  const quantity = useRef<number | undefined | null>(undefined);
  const weight = useRef<number | undefined | null>(undefined);
  const discriminants = useRef<Discriminant[] | undefined>(undefined);

  const selectNextStep = (): void => {
    const currentStepId: number = props.steps.findIndex(step => {
      if (step === selectedStep) {
        return true;
      }
    });

    if (!selectedStep || currentStepId < 0) {
      throw new Error('Selected step is undefined');
    }

    if (currentStepId + 1 >= props.steps.length) {
      props.updatePost(quantity.current, weight.current ? parseFloat(weight.current.toString()) : weight.current, discriminants.current);
      return;
    }

    setSelectedStep(props.steps[currentStepId + 1]);
  };

  const selectPrevStep = (): void => {
    const currentStepId: number = props.steps.findIndex(step => {
      if (step === selectedStep) {
        return true;
      }
    });

    if (!selectedStep || currentStepId < 0) {
      throw new Error('Selected step is undefined');
    }

    if (currentStepId - 1 < 0) {
      props.onStepsEnd();
      return;
    }

    setSelectedStep(props.steps[currentStepId - 1]);
  };

  useEffect(() => {
    if (!props.steps.length) {
      throw new Error('No Estimation steps has been assigned');
    }

    setSelectedStep(props.steps[0]);

    if (props.steps.includes('weight')) {
      quantity.current = props.quantityProps ?? minQuantity;
      weight.current = props.estimatedWeightProps ?? null;
    }

    if (props.steps.includes('discriminants')) {
      discriminants.current = props.discriminantsProps?.length ? props.discriminantsProps : [];
    }
  }, []);

  useEffect(() => {
    if (selectedStep === 'weight' && !props.category) {
      selectNextStep();
    }
  }, [selectedStep]);

  return (
    <>
      <IonHeader className="common-header co2-estimation-step-header" data-cy="co2-estimation-step-header">
        <IonToolbar>
          <h2 data-cy="common-header-title" color="dark">
            <Trans i18nKey={`co2-estimation.${selectedStep}-step-title`} />
          </h2>
          <IonButtons slot="end">
            <IonButton fill="clear" className="close-modal-button" onClick={props.onStepsEnd}>
              <IonIcon icon="/assets/icon/icon-close-modal.svg" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className="co2-estimation-step">
          {selectedStep === 'weight' && props.category && (
            <WeightEstimationStep
              estimatedWeightProps={weight.current ?? undefined}
              quantityProps={quantity.current ?? undefined}
              category={props.category}
              onSelection={(chosenQuantity?: number | null, chosenWeight?: number | null) => {
                quantity.current = chosenQuantity;
                weight.current = chosenWeight ?? null;
                selectNextStep();
              }}
              onBackButtonClick={selectPrevStep}
              currentStep={selectedStep}
              steps={props.steps}
            />
          )}

          {selectedStep === 'discriminants' && (
            <DiscriminantEstimationStep
              onSelection={chosenDiscriminants => {
                if (chosenDiscriminants.length > maxSelectableDiscriminants) {
                  props.setToastMessage('co2-estimation.max-selectable-discriminants');
                  return;
                }

                discriminants.current = chosenDiscriminants;
                selectNextStep();
              }}
              discriminantsProps={discriminants.current}
              discriminants={props.discriminants}
              onBackButtonClick={chosenDiscriminants => {
                discriminants.current = chosenDiscriminants;
                selectPrevStep();
              }}
              currentStep={selectedStep}
              steps={props.steps}
            />
          )}
        </div>
      </IonContent>
    </>
  );
};

export default connect(null, mapDispatchToProps)(PostCo2EstimationSteps);
