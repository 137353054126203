import { defaultAppState } from './app/reducer';
import { defaultPostsState } from './posts/reducer';
import { MigrationManifest, PersistedState } from 'redux-persist';

const migrations: MigrationManifest = {
  1: (state: PersistedState | undefined) => {
    if (!state) {
      return undefined;
    }

    // Change all collections from ReferencedCollection to ItemsCollection<FullPost>
    return { ...state, posts: defaultPostsState };
  },
  2: (state: PersistedState | undefined) => {
    if (!state) {
      return undefined;
    }

    return { ...state, app: defaultAppState };
  },
};

export default migrations;
