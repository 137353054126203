import { IonItem, IonText } from '@ionic/react';
import React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import { CurrentUser } from '../../store/users/types';
import { browserShare } from '../../utils/shareHelper';
import { ModalItemProps } from './ProfileMenuModal';

interface StateProps {
  currentUser: CurrentUser;
}

const mapStateToProps = (state: RootState): StateProps => ({
  currentUser: state.app.currentUser,
});

type Props = StateProps & ModalItemProps & WithTranslation;

const ShareAppMenuItem: React.FunctionComponent<Props> = ({ item, currentUser, t }: Props) => {
  const doShareApp = (): void => {
    browserShare(t('user.join-indigo', { username: currentUser.name }), t('user.share-app-text', { username: currentUser.name }), 'https://bit.ly/3g4lqYP');
  };

  return (
    <IonItem className="menu-item" button detail onClick={doShareApp}>
      <img src={item.icon} alt="" className="icon" />
      <IonText className="menu-text">
        <Trans i18nKey={`profile-menu.${item.title}`} />
      </IonText>
    </IonItem>
  );
};

export default connect(mapStateToProps)(withTranslation()(ShareAppMenuItem));
