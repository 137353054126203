import { ActionType, getType } from 'typesafe-actions';
import { createReferencedCollection, initReferencedCollection, mergeReferencedCollections } from '../../utils/redux';
import * as notificationsActions from './actions';
import { NotificationsState } from './types';

export const defaultState: NotificationsState = {
  currentUserNotifications: createReferencedCollection(),
  globalNotification: {
    '@type': 'NotificationData:global',
    message: '',
    imageUrl: '',
    sendByPush: false,
    sendByMail: false,
    targetedLocales: [],
    externalLinkUrl: null,
    image: null,
  },
  globalNotificationIsLoading: false,
};

export default (state = defaultState, action: ActionType<typeof notificationsActions>): NotificationsState => {
  switch (action.type) {
    case getType(notificationsActions.fetchNotificationsAction.request):
      return { ...state, currentUserNotifications: initReferencedCollection(state.currentUserNotifications, true) };
    case getType(notificationsActions.fetchNotificationsAction.success):
      return { ...state, currentUserNotifications: mergeReferencedCollections(action.payload, state.currentUserNotifications) };
    case getType(notificationsActions.fetchNotificationsAction.failure):
      return { ...state, currentUserNotifications: initReferencedCollection(state.currentUserNotifications, false) };
    case getType(notificationsActions.setGlobalNotificationAction):
      return { ...state, globalNotification: { ...defaultState.globalNotification, ...action.payload } };
    case getType(notificationsActions.sendGlobalNotificationAction.request):
      return { ...state, globalNotificationIsLoading: true };
    case getType(notificationsActions.sendGlobalNotificationAction.success):
    case getType(notificationsActions.sendGlobalNotificationAction.failure):
      return { ...state, globalNotificationIsLoading: false };
    default:
      return state;
  }
};
