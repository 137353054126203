import React, { useState } from 'react';
import { size, map } from 'lodash';
import { IonText, IonItem, IonSpinner } from '@ionic/react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { actions, RootState } from '../store';
import { FullPost, Location, PostCollectionName } from '../store/posts/types';
import { extractId } from '../utils/helpers';
import PostCard from '../components/PostCard';
import ResponsiveTitle from './common/ResponsiveTitle';
import { CollectionResponse, EntityReference, HydratedCollection, ItemsCollection, ReferencedCollectionResponse } from '../utils/hydra';
import { Trans } from 'react-i18next';
import { SearchPageContentPlaceholder } from './placeholders/SearchPagePlaceholder';
import { SuggestionListCollectionPlaceholder } from './placeholders/SuggestionListPlaceholder';
import '../components/placeholders/ProfilePagePlaceholder.scss';
import ImageFadeIn from './ImageFadeIn';
import { isCurrentUserFunc } from '../store/app/selectors';
import { SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import './PostsSlider.scss';
import { UserCollectionName } from '../store/users/types';

interface SmallPostTileProps {
  post: FullPost;
}

const SmallPostCard: React.FunctionComponent<SmallPostTileProps> = ({ post }: SmallPostTileProps) => {
  const activeTab = useSelector((state: RootState) => state.navigation.activeTab);
  const postLink = activeTab && 'posts' !== activeTab ? `/${activeTab}${extractId(post)}` : extractId(post);

  return (
    <IonItem className="small-post-card no-padding" routerLink={postLink} detail={false}>
      {post?.images.length !== 0 && <ImageFadeIn thumbnail className="post-picture" src={post.images[0].contentUrl} />}
      {!post?.images?.length && (
        <div className="title-container">
          <ResponsiveTitle text={post.title} sliderMode={true} />
        </div>
      )}
    </IonItem>
  );
};

interface DispatchProps {
  fetchPosts: (page: string, collectionName: PostCollectionName, loadNextPage: boolean) => Promise<CollectionResponse<FullPost>>;
  fetchUserPosts: (userId: EntityReference, collectionName: UserCollectionName, loadNextPage: boolean) => Promise<ReferencedCollectionResponse>;
}

const propsToDispatch = {
  fetchPosts: actions.posts.fetchPosts,
  fetchUserPosts: actions.posts.fetchUserPosts,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

interface PostsSliderProps {
  postsCollection: HydratedCollection<FullPost> | ItemsCollection<FullPost>;
  collectionName: PostCollectionName | UserCollectionName;
  currentLocation?: Location;
  isCurrentUser?: isCurrentUserFunc;
  postsSize: 'small' | 'large';
  // UserId and PostType are needed in Users profile pages in order to use the right collection
  userId?: EntityReference;
}

type Props = PostsSliderProps & DispatchProps;

const PostsSlider: React.FunctionComponent<Props> = (props: Props) => {
  const { postsCollection, currentLocation, isCurrentUser, postsSize, fetchPosts, collectionName, userId, fetchUserPosts } = props;
  const postsAreSmall = postsSize === 'small';
  const [postsAreLoadingMore, setPostsAreLoadingMore] = useState<boolean>(false);

  const getNextPosts = async (): Promise<void> => {
    if (postsAreLoadingMore) {
      return;
    }
    await setPostsAreLoadingMore(true);
    if (userId) {
      fetchUserPosts(userId, collectionName as UserCollectionName, true).finally(() => setPostsAreLoadingMore(false));
      return;
    }

    fetchPosts('/posts', collectionName as PostCollectionName, true).finally(() => setPostsAreLoadingMore(false));
  };

  if (size(postsCollection.items) === 0 && !postsCollection.isLoading) return <></>;

  return (
    <>
      {postsCollection.isLoading && size(postsCollection.items) === 0 ? (
        postsAreSmall ? (
          <SuggestionListCollectionPlaceholder />
        ) : (
          <SearchPageContentPlaceholder />
        )
      ) : (
        <div className="posts-slider">
          {map(postsCollection.items, (post: FullPost) => (
            <SwiperSlide key={extractId(post)} data-cy={`post-slide-${postsSize}`} className={`post-slide-${postsSize}`}>
              {postsAreSmall ? <SmallPostCard post={post} /> : <PostCard post={post} locationData={currentLocation} isCurrentUser={isCurrentUser} />}
            </SwiperSlide>
          ))}
          {!!postsCollection.nextPage && (
            <SwiperSlide key="loading-item" className={`post-slider-load-more post-card post-card-${postsSize}`} onClick={getNextPosts}>
              <div className="load-more-wrapper">
                {postsAreLoadingMore ? (
                  <IonSpinner className="margin-auto" />
                ) : (
                  <IonText>
                    <Trans i18nKey={'common.see-more'} />
                  </IonText>
                )}
              </div>
            </SwiperSlide>
          )}
        </div>
      )}
    </>
  );
};

export default connect(null, mapDispatchToProps)(PostsSlider);
