import { IonContent, IonItem, IonList, IonPage, IonText } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Redirect } from 'react-router';
import './ProfileMenuItemComponentPage.scss';
import CommonHeader from '../components/CommonHeader';
import { ChildMenu, menuData } from '../components/profileMenu/ProfileMenuModal';
import { ReactComponentType } from '../components/types';
import { appVersion, deviceInfo, appBuildVersion } from '../environment';
import { getAppLanguage } from '../utils/translation';
import { getPrivacyAndTosLanguage, rotateIconForRtlShadowRoot } from '../utils/helpers';
import { Capacitor } from '@capacitor/core';

export interface ChildProfileMenuItemProps {
  item: ChildMenu;
  currentUserIsManaged: boolean;
  childrenPageRoute?: string;
}

export interface ProfileMenuItemComponentPageProps {
  currentUserIsManaged: boolean;
}

const getTransKey = (currentUserIsManaged: boolean, key: string): string => {
  return currentUserIsManaged ? `profile-menu.managed-${key}` : `profile-menu.${key}`;
};

const ChildProfileMenuItem: React.FunctionComponent<ChildProfileMenuItemProps> = (props: ChildProfileMenuItemProps) => {
  const { item, currentUserIsManaged } = props;

  if (item.itemComponent) {
    const Comp: ReactComponentType = item.itemComponent;
    return <Comp {...props} />;
  }

  return (
    <IonItem
      className="ion-item"
      detailIcon="/assets/navigation/chevron-forward.svg"
      detail
      {...(item.isExternalLink ? { href: item.route?.replace('%language%', getPrivacyAndTosLanguage(getAppLanguage())) } : { routerLink: item.route ?? '/me' })}
      target="_blank"
    >
      {item.icon && <img src={item.icon} alt="" className="icon" />}
      <Trans i18nKey={getTransKey(currentUserIsManaged, item.title)} />
    </IonItem>
  );
};

type Props = WithTranslation & ProfileMenuItemComponentPageProps;

const ProfileMenuItemComponentPage: React.FunctionComponent<Props> = (props: Props) => {
  const { routeMenu } = useParams() as never;
  const childrenItems = menuData.filter(item => item.title === routeMenu);
  const [deviceInfoFetched, setDeviceInfoFetched] = useState(deviceInfo !== undefined);

  // There should be only one item to display
  const menuItem = childrenItems[0];
  if (!menuItem) {
    return <Redirect to="/me" />;
  }

  const Component: ReactComponentType | undefined = menuItem.childrenPageContent;
  if (Component) {
    return (
      <IonPage data-cy={menuItem.title + '-page'}>
        <Component key={menuItem.title} item={menuItem} />
      </IonPage>
    );
  }

  useEffect(() => {
    rotateIconForRtlShadowRoot('.ion-item', 'ion-icon');
  }, []);

  if (!deviceInfoFetched) {
    // If the user refreshes the page, we don't have the device info at first
    setTimeout(() => {
      setDeviceInfoFetched(deviceInfo !== undefined);
    }, 500);
  }

  return (
    <IonPage data-cy={menuItem.title + '-page'}>
      <CommonHeader addIonHeader={true} title={<Trans i18nKey={getTransKey(props.currentUserIsManaged, menuItem.title)} />} />
      <IonContent className={`menu-item-page ${menuItem.childrenPageRoute === 'about' ? 'menu-item-page-about' : ''}`}>
        <div className="list-content">
          {menuItem.childrenPageTitle && (
            <IonItem lines="none" className="list-content-header-text" button={false}>
              <Trans i18nKey={getTransKey(props.currentUserIsManaged, menuItem.childrenPageTitle)} />
            </IonItem>
          )}
          <IonList lines="none">
            {menuItem.children?.map((item, index) => (
              <ChildProfileMenuItem key={index} item={item} currentUserIsManaged={props.currentUserIsManaged} />
            ))}
          </IonList>
        </div>
        {menuItem.childrenPageRoute === 'about' && appVersion && (
          <IonText className="app-version" color="medium">
            v {appBuildVersion}
            {deviceInfo?.webViewVersion && (
              <>
                <br />
                WebView: {deviceInfo?.webViewVersion}
              </>
            )}
            {Capacitor.isNativePlatform() && (
              <>
                <br />
                {deviceInfo?.operatingSystem} {deviceInfo?.osVersion}
              </>
            )}
          </IonText>
        )}
      </IonContent>
    </IonPage>
  );
};

export default withTranslation()(ProfileMenuItemComponentPage);
