import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { defaultDomain } from './environment';
import { App as CapApp } from '@capacitor/app';

const AppUrlListener: React.FC = () => {
  const history = useHistory();
  useEffect(() => {
    CapApp.addListener('appUrlOpen', data => {
      if (data.url.indexOf(defaultDomain) < 0) {
        return;
      }

      const slug = data.url.split(defaultDomain).pop();
      console.info('Opening deep link', data.url, slug);

      if (slug) {
        history.push(slug);
      }
      // If no match, do nothing - let regular routing logic take over
    });
  }, [history]);

  return null;
};

export default AppUrlListener;
