import React, { useCallback, useState } from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { useHistory } from 'react-router';
import { connect, useSelector } from 'react-redux';
import { RootState, selectors } from '../store';
import { POSTS_TAB, CHATS_TAB, SEARCH_TAB } from '../utils/TabNames';
import { locationIsAChatPage, locationIsHomepagePage } from '../utils/windowLocationHelper';
import { getRedirectPathAfterCloseHomepage, userBurgerMenuRoutes } from '../utils/routeHelpers';
import { Capacitor } from '@capacitor/core';

interface Props {
  dataCy?: string;
  stopPropagation?: boolean;
  isBackButtonDisabled?: boolean;
  icon?: string;
}

interface StateProps {
  activeTab: string | undefined;
}

const mapStateToProps = (state: RootState): StateProps => ({
  activeTab: state.navigation.activeTab,
});

const BackButton: React.FunctionComponent<Props & StateProps> = ({ dataCy, activeTab, stopPropagation, isBackButtonDisabled, icon }: Props & StateProps) => {
  const history = useHistory();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const activeTabPreviousPathname = useSelector((state: RootState) => selectors.navigation.activeTabPreviousPathname(state));
  const handleClick = useCallback(() => {
    if (isButtonDisabled || stopPropagation) {
      return;
    }

    setIsButtonDisabled(true);

    // When the user arrives on a web page after a redirection from outside of the app, the history length is equal to 2
    // The reason is that a blank page is generated by default in the browser when redirecting
    if (history.length <= ('web' === Capacitor.getPlatform() ? 2 : 1) || (activeTab && !activeTabPreviousPathname && history.location.pathname !== `/${activeTab}`)) {
      history.push(activeTab ? `/${activeTab}` : `/${POSTS_TAB}`, { tabDirection: 'back', direction: 'back' });
      setIsButtonDisabled(false);
      return;
    }

    if (activeTabPreviousPathname?.includes('/posts/create') && /\/posts\/[\w-]+$/.test(history.location.pathname)) {
      // business rule : when back on a opened post, I want to be back to the homepage
      const pathname = `/${POSTS_TAB}`;
      history.push(pathname, { tabDirection: 'back', direction: 'back' });
      setIsButtonDisabled(false);
      return;
    }

    if (locationIsAChatPage() && activeTab !== POSTS_TAB && activeTab !== SEARCH_TAB) {
      // business rule: if I open a "one to one" chat, the "back" button redirect me to chat list
      history.push(`/${CHATS_TAB}`, { tabDirection: 'back', direction: 'back' });
      setIsButtonDisabled(false);
      return;
    }

    if (locationIsHomepagePage()) {
      // business rule: if I close homepage modal, i redirect to "redirectPathAfterCloseHomepage" or posts list
      history.push(getRedirectPathAfterCloseHomepage(), { tabDirection: 'back', direction: 'back' });
      return;
    }

    if (activeTabPreviousPathname && userBurgerMenuRoutes.includes(history.location.pathname)) {
      history.push(activeTabPreviousPathname, { tabDirection: 'back', direction: 'back', openMenuModal: true });
      setIsButtonDisabled(false);
      return;
    }

    if (activeTabPreviousPathname && activeTabPreviousPathname !== '/') {
      history.push(activeTabPreviousPathname, { tabDirection: 'back', direction: 'back' });
      setIsButtonDisabled(false);
      return;
    }

    history.goBack();
    setIsButtonDisabled(false);
  }, [history, activeTabPreviousPathname, activeTab, stopPropagation, isButtonDisabled]);

  if (activeTabPreviousPathname?.includes('/notification_redirection')) {
    return <></>;
  }

  /* TODO Fix disabled button issue */
  return (
    <IonButton data-cy={dataCy || 'back-button'} onClick={handleClick} fill="clear" color="dark" disabled={isButtonDisabled || isBackButtonDisabled} className="back-button">
      {icon ? <IonIcon slot="icon-only" icon={icon} /> : <IonIcon slot="icon-only" src="/assets/navigation/chevron-back.svg" />}
    </IonButton>
  );
};

export default connect(mapStateToProps)(BackButton);
