import { IonContent, IonPage } from '@ionic/react';
import React, { PureComponent, ReactNode } from 'react';
import PostsMap from '../components/connected/PostsMap';
import BackButton from '../components/BackButton';

import './MapPage.scss';

class MapPage extends PureComponent {
  public render(): ReactNode {
    return (
      <IonPage className="map-page" data-cy="map-page">
        <IonContent>
          <BackButton icon="/assets/navigation/back.svg" />
          <PostsMap />
        </IonContent>
      </IonPage>
    );
  }
}

export default MapPage;
