import { IonContent, IonButton, IonThumbnail, IonText, IonPage } from '@ionic/react';
import { FormikProps } from 'formik';
import i18next from 'i18next';
import React, { Component, ReactNode } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { FormValues } from '../components/DynamicForm';
import UserForm, { UserFormValues } from '../components/UserForm';
import { actions, RootState } from '../store';
import { CurrentUser, CurrentUserEditFields, User } from '../store/users/types';
import CommonHeader from '../components/CommonHeader';
import { getUserAvatar } from '../utils/helpers';
import './EditPasswordPage.scss';
import { getUserIsManaged } from '../store/app/selectors';

const submitButton: React.FunctionComponent<FormikProps<FormValues>> = (formikBag: FormValues) => (
  <IonButton type="submit" shape="round" className="full-width-button" disabled={!formikBag.isValid || formikBag.isValidating || formikBag.isSubmitting} data-cy="submit-button">
    {i18next.t('common.save')}
  </IonButton>
);

interface StateProps {
  currentUser: CurrentUser;
  currentUserIsManaged: boolean;
}

const mapStateToProps = (state: RootState): StateProps => ({
  currentUser: state.app.currentUser,
  currentUserIsManaged: getUserIsManaged(state),
});

interface DispatchProps {
  updateUser(user: Partial<CurrentUserEditFields>): Promise<CurrentUser>;
  setToastMessage(message: string | null): void;
}

const propsToDispatch = {
  updateUser: actions.app.updateUser,
  setToastMessage: actions.layout.setToastMessageAction,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type EditPasswordProps = StateProps & DispatchProps & WithTranslation;

class EditPasswordPage extends Component<EditPasswordProps> {
  private initialValue: Partial<UserFormValues> = {
    '@id': this.props.currentUser['@id'],
    plainPassword: '',
    confirmPassword: '',
  };

  public render(): ReactNode {
    const currentUserIsManaged = this.props.currentUserIsManaged;
    const passwordFields: (keyof UserFormValues)[] = currentUserIsManaged ? ['plainPassword'] : ['plainPassword', 'confirmPassword'];

    return (
      <IonPage data-cy="edit-password-page">
        <CommonHeader addIonHeader={true} title={this.props.t(currentUserIsManaged ? 'organization.page-credentials' : 'user.edit-password')} dataCy="edit-password-common-header" />

        <IonContent className="edit-password-content">
          {currentUserIsManaged && (
            <div className="page-profile-description">
              <IonThumbnail slot="start" className="page-picture">
                <img src={getUserAvatar(this.props.currentUser as User)} alt="avatar" />
              </IonThumbnail>
              <IonText className="page-name" color="dark">
                {this.props.currentUser?.name}
              </IonText>

              <IonText className="text-description">
                <Trans i18nKey="organization.page-login-id-explanation" />
              </IonText>

              <IonText className="page-id">{this.props.currentUser.managedData?.id}</IonText>

              <IonText className="text-description">
                <Trans i18nKey="organization.page-login-password-explanation" />
              </IonText>
            </div>
          )}

          <UserForm
            initialValues={this.initialValue}
            fields={passwordFields}
            postUserAction={this.updateUser}
            redirectionRoute="/me"
            submitFormButton={submitButton}
            submitButtonText={this.props.t('common.save')}
            onSubmitErrors={(action, errors) => this.onSubmitErrors(errors)}
          />
        </IonContent>
      </IonPage>
    );
  }

  private updateUser = async (currentUser: Partial<CurrentUserEditFields>): Promise<CurrentUser> => {
    const userUpdated = await this.props.updateUser(currentUser);
    this.props.setToastMessage('user.update-password-success');
    return userUpdated;
  };

  private onSubmitErrors = async (errors: Record<string, string>): Promise<void> => {
    this.props.setToastMessage(errors['_error'] ?? errors ?? 'user.update-error');
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(EditPasswordPage));
