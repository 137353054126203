import ReactPixel from 'react-facebook-pixel';
import { Capacitor } from '@capacitor/core';
import { analyticsEnabled, fbAppId, fbPixelId } from '../../environment';

export const FacebookPixel = {
  ...ReactPixel,
  init: (): void => {
    ReactPixel.init(fbPixelId, undefined, {
      autoConfig: true,
      debug: false,
    });

    if (Capacitor.isNativePlatform()) {
      ReactPixel.fbq('set', 'mobileBridge', fbPixelId, fbAppId);
    }
  },
};

// eslint-disable-next-line @typescript-eslint/ban-types
export async function logFacebookEvent(name: string, params: object = {}): Promise<void> {
  if (!analyticsEnabled) {
    return;
  }

  return FacebookPixel.track(name, params);
}

export default FacebookPixel;
