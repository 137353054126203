import { IonAvatar, IonContent, IonItem, IonLabel, IonList, IonIcon, IonFab, IonFabButton, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonItemGroup, IonText } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { Trans } from 'react-i18next';
import { Message } from '../store/chats/types';
import { EntityReference, HydratedCollection } from '../utils/hydra';
import { extractId, getDateLabel, getUserAvatar, getUserUrlFromActiveTab, handleScrollPosition, scrollToBottom } from '../utils/helpers';
import RoutingItem from './common/RoutingItem';
import './CommentsList.scss';

interface Props {
  messages: HydratedCollection<Message>;
  chatId: EntityReference;
  fetchNextMessagesPage: () => void;
  activeTab?: string;
}

interface State {
  showFabButton: boolean;
}

class CommentsList extends Component<Props, State> {
  private readonly ionContentRef = React.createRef<HTMLIonContentElement>();
  private readonly ionInfiniteScrollRef = React.createRef<HTMLIonInfiniteScrollElement>();

  public constructor(props: Props) {
    super(props);
    this.state = {
      showFabButton: false,
    };
  }

  public componentDidMount(): void {
    setTimeout(() => scrollToBottom(0, this.ionContentRef), 500);
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (extractId(prevProps.chatId) !== extractId(this.props.chatId)) {
      scrollToBottom(200, this.ionContentRef);
      return;
    }

    if (prevProps.messages.totalItems === 0 && this.props.messages.totalItems > 0) {
      setTimeout(() => scrollToBottom(200, this.ionContentRef));
      return;
    }

    if (prevProps.messages.nextPage && prevProps.messages.nextPage !== this.props.messages.nextPage) {
      this.ionInfiniteScrollRef.current?.complete();
      return;
    }

    if (prevProps.messages.items.length !== this.props.messages.items.length) {
      setTimeout(() => scrollToBottom(200, this.ionContentRef));
      return;
    }

    if (prevProps.messages.lastFetchDate !== this.props.messages.lastFetchDate) {
      setTimeout(() => scrollToBottom(200, this.ionContentRef), 500);
      return;
    }
  }

  public render(): ReactNode {
    const messagesList = this.props.messages.items;
    const { messages, fetchNextMessagesPage } = this.props;

    return (
      <IonContent ref={this.ionContentRef} scrollEvents onIonScrollEnd={this.handleScrollPosition} className="comments-list-content has-footer">
        {messages.totalItems === 0 ? (
          <IonLabel className="ion-text-center no-messages-found">
            <Trans i18nKey="chat.no-messages-found" />
          </IonLabel>
        ) : (
          <>
            {this.state.showFabButton && (
              <IonFab vertical="bottom" horizontal="center" slot="fixed">
                <IonFabButton className="scroll-down-fab-button" color="white" size="small" onClick={() => scrollToBottom(200, this.ionContentRef)}>
                  <IonIcon icon="/assets/navigation/chevron-down.svg" mode="md" color="primary" />
                </IonFabButton>
              </IonFab>
            )}

            <IonInfiniteScroll ref={this.ionInfiniteScrollRef} position="top" threshold="15%" onIonInfinite={fetchNextMessagesPage} disabled={!messages.nextPage}>
              <IonInfiniteScrollContent loading-spinner="circles" />
            </IonInfiniteScroll>

            <IonList lines="none" className="comments-list">
              {messagesList.map((message: Message, key) => {
                return <React.Fragment key={key}>{this.messageItem(message, message.createdBy.name)}</React.Fragment>;
              })}
            </IonList>
          </>
        )}
      </IonContent>
    );
  }

  private messageItem = (message: Message, userName: string | undefined): ReactNode => {
    return (
      <IonItemGroup className="comment-item">
        <IonItem lines="none" className="comment-content">
          <RoutingItem route={getUserUrlFromActiveTab(false, this.props.activeTab, message.createdBy)}>
            <IonAvatar slot="start" className="comment-avatar">
              <IonImg alt="" src={getUserAvatar(message.createdBy)} />
            </IonAvatar>
          </RoutingItem>
          <IonLabel color="dark" className={`comment-text-label`}>
            <b>{userName} </b> {message.text}
          </IonLabel>
        </IonItem>
        <IonLabel className="comment-info">
          <IonText className="comment-date">{getDateLabel(new Date(message.createdAt))}</IonText>
        </IonLabel>
      </IonItemGroup>
    );
  };

  private handleScrollPosition = (): void => {
    handleScrollPosition(this.ionContentRef, (value: HTMLElement) =>
      this.setState({ showFabButton: value.scrollHeight > (this?.ionContentRef?.current?.scrollHeight || value.scrollWidth) + value.scrollTop + 100 }),
    );
  };
}

export default CommentsList;
