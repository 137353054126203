import { IonImg, IonItem, IonLabel, IonLoading, IonText } from '@ionic/react';
import { Circle, GoogleMap, Marker } from '@react-google-maps/api';
import i18next from 'i18next';
import moment from 'moment';
import React, { PureComponent, ReactNode } from 'react';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { getCategories } from '../../store/categories/selectors';
import { Category } from '../../store/categories/types';
import { AskForGeolocationParameters, DevicePosition, GeolocationData } from '../../store/layout/types';
import { FullPost, PostCluster } from '../../store/posts/types';
import { defaultCenter, getLatLngFromDevicePosition, getPostPosition, googleMapStyle, waitForGoogleMaps } from '../../utils/geolocationHelpers';
import { addColorInSvg, addViewBoxInSvgString, extractId, getWidthAndHeightFromSvgString, insertInString, isSameHydraEntity, setStatePromise } from '../../utils/helpers';
import MapControl, { mapControlButtonStyle } from '../MapControl';
import { PostCardFooter, getChipComponent } from '../PostCard';
import UserItem from '../UserItem';
import { sendOpenMapLog } from '../../utils/analytics/analyticsHelper';
import PostsMapLoader from './PostsMapLoader';
import { createIsCurrentUserFunc, getUserArea, isCurrentUserFunc } from '../../store/app/selectors';
import { translateDigits } from '../../utils/translation';
import './PostsMap.scss';
import { Area } from '../../store/users/types';
import { getCategoryWithIcon } from '../../utils/categoriesHelpers';

const assetsDirectory = '/assets/map-icons/';

const defaultOfferIconUrl = assetsDirectory + 'default-offer.svg';
const defaultNeedIconUrl = assetsDirectory + 'default-need.svg';
const selectedOfferIconUrl = assetsDirectory + 'selected-offer.svg';
const selectedNeedIconUrl = assetsDirectory + 'selected-need.svg';
const clusterIconUrl = assetsDirectory + 'cluster.svg';
const positionIconUrl = assetsDirectory + 'position.svg';

interface State {
  firstMoveDone: boolean;
  googleMapsLoaded: boolean;
  map?: google.maps.Map;
  currentLocation?: DevicePosition;
  center: google.maps.LatLngLiteral;
  selectedMarker?: google.maps.Marker;
  askingForCurrentGeolocation: boolean;
}

interface StateProps {
  categories: Category[];
  isCurrentUser: isCurrentUserFunc;
  geolocationData: GeolocationData;
  userArea?: Area;
}

const mapStateToProps = (state: RootState): StateProps => ({
  categories: getCategories(state),
  isCurrentUser: createIsCurrentUserFunc(state),
  geolocationData: state.layout.geolocationData,
  userArea: getUserArea(state),
});

interface DispatchProps {
  askForGeolocationDataAction: (askForGeolocationParameters?: AskForGeolocationParameters) => void;
  setToastMessage(message: string | null): void;
}

const propsToDispatch = {
  askForGeolocationDataAction: actions.layout.askForGeolocationDataAction,
  setToastMessage: actions.layout.setToastMessageAction,
};

type Props = StateProps & DispatchProps;

const defaultZoom = 17;

const mapOptions = {
  zoomControl: true,
  scaleControl: true,
  disableDefaultUI: true,
  clickableIcons: false,
  minZoom: 4,
  styles: googleMapStyle,
};

const centerCircleOptions = { fillColor: '#00F', fillOpacity: 0.05, clickable: false, strokeColor: '#00F', strokeOpacity: 0.15, strokeWeight: 1 };

const mapContainerStyle = { height: '100%', width: '100%' };

const defaultIconSize = 30;

class PostsMap extends PureComponent<Props, State> {
  private myPositionIcon?: google.maps.Icon;
  private categoriesIcon: { [key: string]: string } = {};

  public constructor(props: Props) {
    super(props);

    this.buildCategoriesIcons();
    this.state = {
      firstMoveDone: false,
      googleMapsLoaded: false,
      map: undefined,
      currentLocation: undefined,
      center: defaultCenter,
      askingForCurrentGeolocation: false,
    };
  }

  public async componentDidMount(): Promise<void> {
    if (!this.props.userArea?.location && this.props.geolocationData.geolocationPermission !== 'denied') {
      this.props.askForGeolocationDataAction();
    }
    await waitForGoogleMaps();

    if (this.props.userArea?.location?.latitude) {
      this.onCurrentPositionFetched(this.props.userArea?.location as DevicePosition);
    }

    // The setTimeout allows to wait for the div to be displayed before rendering the map
    // Fixes the grey map background issue
    setTimeout(() => this.setState({ googleMapsLoaded: true }));
    sendOpenMapLog();
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (this.props.categories && prevProps.categories !== this.props.categories) {
      this.buildCategoriesIcons();
    }

    if (prevState.selectedMarker !== this.state.selectedMarker) {
      if (this.state.selectedMarker) {
        this.state.selectedMarker.setIcon(this.getMarkerIcon(this.state.selectedMarker.get('post')));
      }
      if (prevState.selectedMarker) {
        prevState.selectedMarker.setIcon(this.getMarkerIcon(prevState.selectedMarker.get('post')));
      }
    }

    const geolocationWasFetched = prevProps.geolocationData.lastFetchPositionDate !== this.props.geolocationData.lastFetchPositionDate;

    if (this.props.geolocationData.geolocationPermission === 'denied' && geolocationWasFetched) {
      this.setState({ askingForCurrentGeolocation: false });
      this.props.setToastMessage('geolocation.must-allow');
    }

    const devicePosition = this.props.geolocationData.devicePosition;
    const latitudeChanged = prevProps.geolocationData.devicePosition?.latitude !== devicePosition?.latitude;
    const longitudeChanged = prevProps.geolocationData.devicePosition?.longitude !== devicePosition?.longitude;

    if (devicePosition && (latitudeChanged || longitudeChanged || geolocationWasFetched)) {
      this.onCurrentPositionFetched(devicePosition, true);
    }
  }

  private getMyPositionIcon = (): google.maps.Icon => {
    if (this.myPositionIcon !== undefined) {
      return this.myPositionIcon;
    }

    this.myPositionIcon = {
      url: positionIconUrl,
      scaledSize: new google.maps.Size(30, 30),
    };

    return this.myPositionIcon;
  };

  private onCenterChanged = (): void => {
    if (!this.state.map) {
      return;
    }
    if (this.state.firstMoveDone && !this.state.selectedMarker) {
      return;
    }

    this.setState({ firstMoveDone: true, selectedMarker: undefined });
  };

  private onMapLoad = async (map: google.maps.Map): Promise<void> => {
    await setStatePromise(this, { map });
  };

  private createPostMarker = (post: FullPost): google.maps.Marker => {
    const marker = new google.maps.Marker({
      title: post.title,
      position: getPostPosition(post),
      icon: this.getMarkerIcon(post),
      clickable: true,
    });

    marker.set('post', post);
    marker.addListener('click', () => this.postSelect(marker));

    return marker;
  };

  private createClusterMarker = (cluster: PostCluster): google.maps.Marker => {
    const marker = new google.maps.Marker({
      label: translateDigits(cluster.count).toString(),
      position: cluster.center,
      icon: clusterIconUrl,
      clickable: true,
    });

    marker.set('cluster', cluster);
    marker.addListener('click', () => {
      this.state.map?.setZoom((this.state.map?.getZoom() || 0) + 2);
      this.state.map?.setCenter(marker.getPosition() as google.maps.LatLng);
    });

    return marker;
  };

  private onCurrentPositionFetched = async (currentLocation: DevicePosition, force = false): Promise<void> => {
    await setStatePromise(this, { currentLocation });

    if (!force && this.state.firstMoveDone) {
      return;
    }

    await setStatePromise(this, { center: undefined });
    await setStatePromise(this, { center: getLatLngFromDevicePosition(currentLocation), askingForCurrentGeolocation: false });
  };

  private askForCurrentPosition = (): void => {
    this.setState({ askingForCurrentGeolocation: true });
    const oneMinuteAgo = moment(new Date()).subtract(1, 'minutes').toDate();

    const geolocationData = this.props.geolocationData;
    const lastFetchPositionWasMadeLessThanOneMinuteAgo = geolocationData.lastFetchPositionDate && geolocationData.lastFetchPositionDate > oneMinuteAgo;
    if (lastFetchPositionWasMadeLessThanOneMinuteAgo && geolocationData.devicePosition) {
      this.onCurrentPositionFetched(geolocationData.devicePosition, true);
    } else {
      this.props.askForGeolocationDataAction({ askPermissions: true });
    }
  };

  public render(): ReactNode {
    if (!this.state.googleMapsLoaded) {
      return <div style={mapContainerStyle} />;
    }

    const currentLocationCenter = this.props.geolocationData.devicePosition ? getLatLngFromDevicePosition(this.props.geolocationData.devicePosition) : undefined;

    return (
      <div style={{ height: '100%' }}>
        <PostsMapLoader map={this.state.map} createPostMarker={this.createPostMarker} createClusterMarker={this.createClusterMarker} />
        <IonLoading isOpen={this.state.askingForCurrentGeolocation} showBackdrop={false} cssClass="map-spinner map-spinner_bottom" />

        <GoogleMap zoom={defaultZoom} center={this.state.center} options={mapOptions} mapContainerStyle={mapContainerStyle} onLoad={this.onMapLoad} onCenterChanged={this.onCenterChanged}>
          {currentLocationCenter && this.state.currentLocation && (
            <>
              <Marker position={currentLocationCenter} icon={this.getMyPositionIcon()} />
              <Circle center={currentLocationCenter} radius={this.state.currentLocation?.accuracy} options={centerCircleOptions} />
            </>
          )}

          <MapControl position={window.google.maps.ControlPosition.RIGHT_BOTTOM}>
            <button title={i18next.t('post.geolocate-me')} aria-label={i18next.t('post.geolocate-me')} type="button" style={mapControlButtonStyle} onClick={this.askForCurrentPosition}>
              <img src={positionIconUrl} alt={i18next.t('post.geolocate-me')} title={i18next.t('post.geolocate-me')} />
            </button>
          </MapControl>
        </GoogleMap>
        {this.state.selectedMarker && this.renderPostCard(this.state.selectedMarker.get('post'))}
      </div>
    );
  }

  private renderPostCard = (post: FullPost): ReactNode => {
    const postHasImage = post.images.length !== 0;

    return (
      <IonItem routerLink={extractId(post)} className={`map-post-container ${post && 'expand'}`} lines="none" detail={false} button={false}>
        <div className="post-main-info">
          {postHasImage ? (
            <IonImg className="post-image" src={post.images[0].contentUrl} />
          ) : (
            <IonText slot="start" className="post-title-large">
              {post.title}
            </IonText>
          )}
        </div>
        <IonLabel className="post-content">
          <UserItem user={post?.createdBy} chipComponent={getChipComponent(post)} size="small" isCurrentUser={this.props.isCurrentUser(post?.createdBy)} />
          <div className="post-infos">
            {postHasImage && <IonText className="post-title">{post?.title}</IonText>}
            <IonText className="post-description">{post?.description}</IonText>
          </div>
          <PostCardFooter post={post} locationData={this.props.geolocationData.devicePosition} />
        </IonLabel>
      </IonItem>
    );
  };

  private getMarkerIcon = (post: FullPost): google.maps.Icon => {
    const postIsSelected = isSameHydraEntity(post, this.state.selectedMarker?.get('post'));
    const postTypeNeed = post.type === 'need';

    let url: string;

    if (postIsSelected) {
      url = postTypeNeed ? selectedNeedIconUrl : selectedOfferIconUrl;
    } else {
      const icon = this.categoriesIcon[post.category as string];
      if (icon) {
        // If post is need we change the icon background color to red. It was set to blue on the buildCategoriesIcons
        url = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(postTypeNeed ? icon?.replace(/3b5998/, 'd00a26') : icon);
      } else {
        url = postTypeNeed ? defaultNeedIconUrl : defaultOfferIconUrl;
      }
    }

    return { url: url, scaledSize: postIsSelected ? new google.maps.Size(defaultIconSize, defaultIconSize + 14) : new google.maps.Size(defaultIconSize, defaultIconSize) };
  };

  private buildCategoriesIcons = (): void => {
    this.categoriesIcon = this.props.categories.reduce((obj: { [key: string]: string }, category) => {
      const categoryWithIcon = getCategoryWithIcon(this.props.categories, category);

      if (!categoryWithIcon?.icon) {
        obj[extractId(category)] = '';
        return obj;
      }

      const icon = categoryWithIcon.icon;

      const iconSize = getWidthAndHeightFromSvgString(icon);
      const circleSize = (iconSize.width ?? defaultIconSize) / 2;

      // We add a blue circle on the icon background & set icon color to white
      const iconWhite = addColorInSvg(icon, '#fff');
      const iconWithBackground = insertInString(
        iconWhite,
        iconWhite.indexOf('>') + 1,
        `<circle cx="${circleSize}" cy="${circleSize}" r="${circleSize + 3}" fill="#3b5998" stroke="#fff" stroke-width="1"/>`,
      );

      obj[extractId(category)] = addViewBoxInSvgString(iconWithBackground);
      return obj;
    }, {});
  };

  private postSelect = (selectedMarker: google.maps.Marker): void => {
    this.setState({ selectedMarker });
  };
}

export default connect(mapStateToProps, propsToDispatch)(PostsMap);
