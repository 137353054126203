export const signUpComplete = 'sign_up_complete';
export const login = 'login';
export const postCreated = 'post_created';
export const postUpdate = 'post_update';
export const postRequestCompletedReceiver = 'post_request_completed_receiver';
export const postRequestCompletedGiver = 'post_request_completed_giver';
export const postRequestBooked = 'post_request_booked';
export const postRequestRejected = 'post_request_rejected';
export const postRequestCanceled = 'post_request_canceled';
export const follow = 'follow';
export const reportUser = 'report_user';
export const reportPost = 'report_post';
export const postPageViewed = 'post_page_viewed';
export const signUpPageViewed = 'signup_page_viewed';
export const openMap = 'open_map';
export const sessionStart = 'session_start';
export const notificationOpen = 'notification_open';
export const search = 'search';
export const profilePageViewed = 'profile_page_viewed';
export const loginSignUpPageViewed = 'login_or_sign_up_page_viewed';
export const loginPageViewed = 'login_page_viewed';
export const pushNotificationAuthorization = 'push_notification_authorization';
export const profilePictureUploaded = 'profile_picture_uploaded';
export const writeReview = 'write_review';
export const replyRecommendation = 'reply_recommendation';
export const postRequestCreated = 'post_request_created';
export const firstOpen = 'first_open';
export const socialBannerClicked = 'banner_click';
