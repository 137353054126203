import { IonActionSheet, IonContent, IonInfiniteScroll, IonInfiniteScrollContent, IonPage, IonRefresher, IonRefresherContent, withIonLifeCycle } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import NotificationsList from '../components/NotificationsList';
import { actions, RootState } from '../store';
import { denormalizeEntityCollection } from '../store/entities/selectors';
import { UserNotification } from '../store/notifications/types';
import { UserStatus } from '../store/users/types';
import { isDataFresh, isEqualIgnoringFunctions } from '../utils/helpers';
import { HydratedCollection } from '../utils/hydra';
import CommonHeader from '../components/CommonHeader';
import NotificationsPlaceholder from '../components/placeholders/NotificationsPlaceholder';
import isEqual from 'lodash/isEqual';
import withRouterAndRef from '../utils/withRouterAndRef';

interface StateProps {
  userNotifications: HydratedCollection<UserNotification>;
  userStatus: UserStatus;
}

const mapStateToProps = (state: RootState): StateProps => ({
  userNotifications: denormalizeEntityCollection<UserNotification>(state.entities, state.notifications.currentUserNotifications),
  userStatus: state.app.currentUser.status,
});

interface DispatchProps {
  fetchNotifications: (loadNextPage: boolean) => Promise<void>;
  markNotificationAsRead: (userNotification: UserNotification, read: boolean) => Promise<void>;
  markAllNotificationsAsRead: () => Promise<void>;
}

const propsToDispatch = {
  fetchNotifications: actions.notifications.fetchNotifications,
  markNotificationAsRead: actions.notifications.updateNotificationStatus,
  markAllNotificationsAsRead: actions.notifications.updateAllNotificationStatus,
};

interface State {
  contentRendering: boolean;
  isActionSheetActive: boolean;
}

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type NotificationsProps = StateProps & DispatchProps & WithTranslation & RouteComponentProps<Record<string, never>>;

class NotificationsPage extends Component<NotificationsProps, State> {
  private readonly ionRefresherRef: React.RefObject<HTMLIonRefresherElement>;
  private readonly ionInfiniteScrollRef = React.createRef<HTMLIonInfiniteScrollElement>();
  private _isMounted = true;
  public constructor(props: NotificationsProps) {
    super(props);
    this.ionRefresherRef = React.createRef<HTMLIonRefresherElement>();
    this.state = {
      contentRendering: false,
      isActionSheetActive: false,
    };
  }

  public ionViewWillEnter(): void {
    if (!isDataFresh(this.props.userNotifications) || this.props.userStatus?.unreadNotifications || 0 > this.props.userNotifications.items.length) {
      this.props.fetchNotifications(false);
    }
    setTimeout(() => this.setState({ contentRendering: true }));
  }
  public ionViewWillLeave(): void {
    if (this._isMounted) {
      this.setState({ contentRendering: false });
    }
  }
  public shouldComponentUpdate(nextProps: Readonly<NotificationsProps>, nextState: Readonly<State>): boolean {
    return !isEqualIgnoringFunctions(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public componentDidUpdate(prevProps: Readonly<NotificationsProps>): void {
    if (!this.props.userNotifications.isLoading && prevProps.userNotifications.isLoading && this.ionRefresherRef.current) {
      this.ionRefresherRef.current.complete();
    }
  }

  private toggleActionSheet = (): void => {
    this.setState({ isActionSheetActive: !this.state.isActionSheetActive });
  };

  private markAllNotificationsAsRead = async (): Promise<void> => {
    try {
      await this.props.markAllNotificationsAsRead();
      this.props.fetchNotifications(false);
    } catch (e) {
      console.error(e);
    }
  };

  public render(): ReactNode {
    const actionSheetButtons = [
      {
        text: this.props.i18n.t('notification.read-all'),
        handler: () => this.markAllNotificationsAsRead(),
      },
      {
        text: this.props.i18n.t('notification.settings'),
        handler: () => this.props.history.push('me/notifications'),
      },
      {
        text: this.props.t('common.cancel'),
        role: 'cancel',
      },
    ];

    const { contentRendering } = this.state;
    const { userNotifications: collection } = this.props;
    return (
      <IonPage data-cy="notifications-page">
        <CommonHeader
          addIonHeader={true}
          openMenu={this.toggleActionSheet}
          menuIcon="/assets/navigation/more.svg"
          title={<Trans i18nKey="common.notifications" />}
          className="notification-page-header"
          menuDataCy="open-actions-sheet"
        />

        {contentRendering ? (
          <IonContent>
            <IonRefresher ref={this.ionRefresherRef} onIonRefresh={() => this.props.fetchNotifications(false)} slot="fixed">
              <IonRefresherContent />
            </IonRefresher>

            <NotificationsList userNotifications={collection} onStatusClick={this.props.markNotificationAsRead} />

            <IonInfiniteScroll ref={this.ionInfiniteScrollRef} threshold="148px" onIonInfinite={() => this.loadNotifications(true)} disabled={!collection?.nextPage}>
              <IonInfiniteScrollContent loading-spinner="circle" loading-text={this.props.t('common.loading')} />
            </IonInfiniteScroll>

            <IonActionSheet data-cy="notification-action-sheet" isOpen={this.state.isActionSheetActive} onDidDismiss={this.toggleActionSheet} buttons={actionSheetButtons} />
          </IonContent>
        ) : (
          <NotificationsPlaceholder />
        )}
      </IonPage>
    );
  }

  private loadNotifications = (loadNextPage = false): void => {
    this.props.fetchNotifications(loadNextPage).finally(() => {
      this.ionInfiniteScrollRef.current?.complete();
    });
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withIonLifeCycle(withRouterAndRef(NotificationsPage))));
