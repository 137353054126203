import { IonButton, IonContent, IonGrid, IonRow, IonText } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { getManagedUserInfos } from '../store/layout/selector';
import { DevicePosition, ManagedUserInfos } from '../store/layout/types';
import { FullPost, Location } from '../store/posts/types';
import { minPostsBeforeMaxRadiusCard, postsBeforeTreeOrPromotionalCard, socialCardSlotSize } from '../store/posts/variables';
import { extractId, isEqualIgnoringFunctions } from '../utils/helpers';
import { EntityReference, ItemsCollection } from '../utils/hydra';
import PostCard from './PostCard';
import { Trans } from 'react-i18next';
import { Thematic } from '../store/thematics/types';
import { connect } from 'react-redux';
import { actions, RootState } from '../store';
import { bindActionCreators, Dispatch } from 'redux';
import { getSuggestionsThematics } from '../store/thematics/selectors';
import PromotionalCard from './PromotionalCard';
import SuggestionList from './SuggestionList';
import { SocialCardMaxRadius, SocialCardTree } from './SocialCard';
import IonColInView from './IonColInView';
import { getThematicsByIds } from '../store/thematics/reselectors';
import { createIsCurrentUserFunc, getIsFullyLogged, isCurrentUserFunc } from '../store/app/selectors';
import FirstCellCard from './FirstCellCard';

import './PostsGrid.scss';

interface ListProps {
  posts: ItemsCollection<FullPost>;
  isLoading?: boolean;
  showSuggestions: boolean;
  showSocialCards: boolean;
  maxRadiusCardIndex?: number;
  userPosition: Location | undefined;
}

interface StateProps {
  isCurrentUser: isCurrentUserFunc;
  currentUserThematics: Thematic[];
  thematics: Thematic[];
  isFullyLogged: boolean;
  managedUserInfos?: ManagedUserInfos;
  devicePosition: DevicePosition | undefined;
}

const mapStateToProps = (state: RootState): StateProps => ({
  isCurrentUser: createIsCurrentUserFunc(state),
  currentUserThematics: getThematicsByIds(state, state.app.currentUser.favoriteCategoryThematics),
  thematics: state.thematics.thematics,
  isFullyLogged: getIsFullyLogged(state),
  managedUserInfos: getManagedUserInfos(state),
  devicePosition: state.layout.geolocationData.devicePosition,
});

interface DispatchProps {
  fetchThematics: () => void;
  fetchCategories: () => void;
}

const propsToDispatch = {
  fetchThematics: actions.thematicsActions.fetchThematics,
  fetchCategories: actions.categoriesActions.fetchPostCategories,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type Props = ListProps & StateProps & DispatchProps;

type SocialCardsInfo = {
  socialCardTreeIdx: number;
  socialCardMaxRadiusIdx: number;
  maxRadiusCardSlotSize: number;
};

const getSuggestionsPositionsIndexes = (userThematicsQuantity: number, showSocialCards: boolean, showMaxRadiusCard: boolean, socialCardsInfo: SocialCardsInfo): number[] => {
  /** generate suggestions positions list to show horizontal swipe suggestions posts after the 4th position
   *  of the homepage list offers and then after every 12th position of the posts list
   *  7 - index of last post in 4th row
   *  24 - posts amount between suggestion blocks
   **/
  const positions = Array.from({ length: userThematicsQuantity }).map((_, index) => index * 24 + 7);

  return updateSuggestionsPositionsWithSocialBanners(positions, showMaxRadiusCard, showSocialCards, socialCardsInfo);
};

const updateSuggestionsPositionsWithSocialBanners = (positions: number[], showMaxRadiusCard: boolean, showSocialCards: boolean, socialCardsInfo: SocialCardsInfo): number[] => {
  // Since social cards occupy some slots inside the grid, we step back the suggestion list according to the size of the social cards
  return positions.map(index => {
    let finalIndex = index;

    if (showSocialCards && index >= socialCardsInfo.socialCardTreeIdx) {
      finalIndex -= socialCardSlotSize;
    }

    if (showMaxRadiusCard && index > socialCardsInfo.socialCardMaxRadiusIdx) {
      finalIndex -= socialCardsInfo.maxRadiusCardSlotSize;
    }

    return finalIndex;
  });
};

const getMaxRadiusCardIdx = (maxRadiusCardIdx: number | undefined): number => {
  const minMaxRadiusCardIdx = minPostsBeforeMaxRadiusCard - 1; // To get the index of the card, we remove 1 to the wanted amount of posts

  // If maxRadiusCardIdx is undefined, it means we should not display the maxRadiusCard
  if (maxRadiusCardIdx === undefined) {
    return -1;
  }

  // If maxRadiusCardIdx is too small, we set it to the minMaxRadiusCardIdx
  if (maxRadiusCardIdx < minMaxRadiusCardIdx) {
    return minMaxRadiusCardIdx;
  }

  return maxRadiusCardIdx;
};

class PostsGrid extends Component<Props> {
  private _isMounted = true;

  public componentDidMount(): void {
    this.fetchData();
  }

  public componentWillUnmount(): void {
    this._isMounted = false;
  }

  private fetchData(): void {
    this.props.fetchCategories();
    this.props.fetchThematics();
  }

  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return !isEqualIgnoringFunctions(nextProps, this.props);
  }

  public render(): ReactNode {
    const { posts, isCurrentUser, thematics, currentUserThematics, showSuggestions, userPosition, showSocialCards, devicePosition, maxRadiusCardIndex, isLoading } = this.props;

    const socialCardMaxRadiusIdx: number = getMaxRadiusCardIdx(maxRadiusCardIndex);
    const showMaxRadiusCard: boolean = socialCardMaxRadiusIdx > 0;
    const maxRadiusCardSlotSize: number = socialCardMaxRadiusIdx % 2 !== 0 ? 2 : 1; // The maxRadiusCard is displayed on 1 slot if his index is odd

    const socialCardsInfo: SocialCardsInfo = {
      socialCardTreeIdx: postsBeforeTreeOrPromotionalCard - 1, // To get the index of the card, we remove 1 to the wanted amount of posts
      socialCardMaxRadiusIdx: socialCardMaxRadiusIdx,
      maxRadiusCardSlotSize: maxRadiusCardSlotSize,
    };
    const userThematics = getSuggestionsThematics(thematics, currentUserThematics);
    const showSuggestionPositionsList = getSuggestionsPositionsIndexes(userThematics.length, showSocialCards, showMaxRadiusCard, socialCardsInfo);

    // To show the Promotional card, the user should be connected, and there should be at least the promotional BG image
    const showPromotionalCard = !!(this.props?.managedUserInfos?.promotionalBgImage || this.props?.managedUserInfos?.promotionalBgImageLargeScreen);
    const showFirstCellImage = !!(showSocialCards && this.props.managedUserInfos?.firstCellImage);

    if (Object.keys(posts.items).length === 0) {
      return (
        <IonContent className="no-posts-found">
          <div className="ion-flex full-height ion-justify-content-center ion-align-items-center">
            <div className="ion-text-center">
              <h3>
                <Trans i18nKey="post.no-posts-found" />
              </h3>
              <IonButton className="icon-btn" fill="clear" routerLink="/posts/create/need">
                <div className="btn-container">
                  <img src="/assets/bt-post-request.svg" alt="Post a new need" />
                  <div className="ion-margin-top">
                    <IonText className="header-title">
                      <Trans i18nKey="common.post-a-request" />
                    </IonText>
                  </div>
                </div>
              </IonButton>
            </div>
          </div>
        </IonContent>
      );
    }

    let currentIndex = -1 + Number(showFirstCellImage);

    return (
      <IonGrid className={`post-grid-container ${isLoading ? 'loading' : ''}`}>
        <IonRow>
          {showFirstCellImage && <FirstCellCard managedUserInfos={this.props.managedUserInfos} />}
          {Object.keys(posts.items).map((id: EntityReference) => {
            ++currentIndex;
            return (
              <React.Fragment key={id}>
                <IonColInView size="6" size-lg="3" height={335} data-cy="post-holder" className="post-holder">
                  <PostCard post={posts.items[id]} locationData={userPosition ?? (devicePosition as Location)} isCurrentUser={isCurrentUser} />
                </IonColInView>
                {showSocialCards && !showPromotionalCard && currentIndex === socialCardsInfo.socialCardTreeIdx && <SocialCardTree />}
                {showSocialCards && showPromotionalCard && currentIndex === socialCardsInfo.socialCardTreeIdx && this.props.managedUserInfos && (
                  <PromotionalCard managedUserInfos={this.props.managedUserInfos} />
                )}
                {showMaxRadiusCard && currentIndex === socialCardsInfo.socialCardMaxRadiusIdx && <SocialCardMaxRadius oneSlotCard={socialCardsInfo.maxRadiusCardSlotSize === 1} />}
                {showSuggestions && showSuggestionPositionsList.indexOf(currentIndex) !== -1 && (
                  <SuggestionList
                    key={extractId(userThematics[showSuggestionPositionsList.indexOf(currentIndex)])}
                    thematic={userThematics[showSuggestionPositionsList.indexOf(currentIndex)]}
                    locationData={userPosition ?? (devicePosition as Location)}
                    currentPage="posts"
                    postsSize="large"
                  />
                )}
              </React.Fragment>
            );
          })}
        </IonRow>
      </IonGrid>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostsGrid);
