import { AnyAction, Middleware } from 'redux';
import { actions, RootState } from '../index';
import * as chatActions from './actions';
import * as appActions from '../app/actions';
import * as layoutActions from '../layout/actions';
import { ActionType, getType } from 'typesafe-actions';
import { ThunkResultDispatch } from '../types';
import { extractId } from '../../utils/helpers';

export const chatMiddleware: Middleware<unknown, RootState> = store => next => (action: ActionType<typeof actions.chats | AnyAction>) => {
  typeof action === 'function' ? action(next) : next(action);

  const dispatch = store.dispatch as ThunkResultDispatch;

  if (action.payload && (action.type === getType(chatActions.createDirectChatAction.failure) || action.type === getType(chatActions.fetchCurrentUserChatAction.failure))) {
    next(layoutActions.setToastMessageAction(action.payload.message));
  }

  if (action.type === getType(chatActions.updateUserChatStateAction.success)) {
    next(layoutActions.setToastMessageAction(action.payload === 'archived' ? 'chat.archive-success' : 'chat.unarchive-success'));
  }

  if (action.type === getType(chatActions.updateUserChatStateAction.failure)) {
    next(layoutActions.setToastMessageAction(action.payload.state === 'archived' ? 'chat.archive-fail' : 'chat.unarchive-fail'));
  }

  if (action.type === getType(chatActions.createChatMessageAction.failure)) {
    next(layoutActions.setToastMessageAction('chat.unable-to-send-message'));
  }

  if (action.type === getType(chatActions.updateUserChatAction.success)) {
    // Update current user chats list
    dispatch(chatActions.fetchUserChat(extractId(action.payload.chat)));
    // Update current user status
    dispatch(appActions.fetchLoggedUser());
  }
};
