import React from 'react';
import { IonContent, IonItem, IonModal, IonText, IonButton } from '@ionic/react';
import { Trans } from 'react-i18next';
import './CompletePageModal.scss';

interface ModalProps {
  directRegistration?: boolean;
  isOpen: boolean;
  closeModal: () => void;
}

const CompletePageModal: React.FunctionComponent<ModalProps> = (props: ModalProps) => {
  const directRegistration = props.directRegistration;
  const directRegistrationTranslation = directRegistration ? 'direct' : 'pending';

  return (
    <IonModal isOpen={props.isOpen} onDidDismiss={props.closeModal} className="complete-page-modal safe-area-ios">
      <IonContent>
        <IonItem lines="none">
          <IonText className="organization-complete-page-title">
            <Trans i18nKey={`organization.registration-${directRegistrationTranslation}.next-important-steps`} />
          </IonText>
        </IonItem>
        <IonText>
          <Trans i18nKey={`organization.registration-${directRegistrationTranslation}.next-important-steps-details`} components={[<span key="organisation.premium" />]} />
        </IonText>
        {directRegistration && (
          <IonButton routerLink="/me/edit">
            <Trans i18nKey="organization.complete-page" />
          </IonButton>
        )}
      </IonContent>
    </IonModal>
  );
};

export default CompletePageModal;
