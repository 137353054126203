import React from 'react';
import { IonSkeletonText, IonThumbnail, IonSpinner, IonItem, IonAvatar, IonLabel } from '@ionic/react';
import { PostsRowPlaceholder } from './PostsPlaceholder';
import './HomePagePlaceholder.scss';
import './CommonPlaceholderStyles.scss';
import './PostPagePlaceholder.scss';

export const PostTitlePlaceholder: React.FunctionComponent = () => {
  return (
    <IonItem lines="none" className="post-placeholder-no-gaps common-placeholder">
      <IonThumbnail className="post-picture-placeholder">
        <IonSkeletonText />
      </IonThumbnail>
    </IonItem>
  );
};

export const PostUserPlaceholder: React.FunctionComponent = () => {
  return (
    <>
      <IonItem lines="none" className="post-placeholder-no-gaps common-placeholder">
        <IonAvatar slot="start" className="user-avatar">
          <IonSkeletonText />
        </IonAvatar>
        <IonLabel className="user-text-placeholder">
          <p>
            <IonSkeletonText style={{ width: '115px' }} />
          </p>
          <p>
            <IonSkeletonText style={{ width: '64px' }} />
          </p>
        </IonLabel>
      </IonItem>
      <IonItem lines="none" className="post-placeholder-no-gaps">
        <IonLabel className="post-request-placeholder common-placeholder">
          <p>
            <IonSkeletonText style={{ width: '33%' }} />
          </p>
          <p>
            <IonSkeletonText style={{ width: '20%' }} />
          </p>
          <p>
            <IonSkeletonText style={{ width: '20%' }} />
          </p>
          <p className="request-button-placeholder">
            <IonSkeletonText />
          </p>
        </IonLabel>
      </IonItem>
    </>
  );
};

export const PostDescriptionPlaceholder: React.FunctionComponent = () => {
  return (
    <IonItem lines="none" className="post-placeholder-no-gaps common-placeholder post-description-placeholder">
      <IonLabel>
        <div className="post-description-text">
          <p className="title-placeholder">
            <IonSkeletonText style={{ width: '21%' }} />
          </p>
          <p>
            <IonSkeletonText style={{ width: '100%' }} />
          </p>
          <p>
            <IonSkeletonText style={{ width: '100%' }} />
          </p>
          <p className="bottom-gap ">
            <IonSkeletonText style={{ width: '100%' }} />
          </p>
        </div>
        <p className="post-map-placeholder">
          <IonSkeletonText />
        </p>
      </IonLabel>
    </IonItem>
  );
};

export const PostsUserListPlaceholder: React.FunctionComponent = () => {
  return (
    <IonLabel className="posts-user-list-placeholder">
      <p className="post-list-title">
        <IonSkeletonText style={{ width: '50%' }} />
      </p>
      <PostsRowPlaceholder />
    </IonLabel>
  );
};

export const PostHelperPlaceholder: React.FunctionComponent = () => {
  return (
    <IonItem lines="none" className="post-placeholder-no-gaps common-placeholder post-help-placeholder">
      <p>
        <IonSkeletonText />
      </p>
    </IonItem>
  );
};

export const PostPagePlaceholder: React.FunctionComponent = () => {
  return (
    <>
      <IonSpinner className="common-placeholder-spinner" data-cy="post-page-loader" />
      <PostTitlePlaceholder />
      <PostUserPlaceholder />
      <PostDescriptionPlaceholder />
      <PostsUserListPlaceholder />
      <PostHelperPlaceholder />
      <PostsUserListPlaceholder />
    </>
  );
};

export default PostPagePlaceholder;
