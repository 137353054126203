import { getType } from 'typesafe-actions';
import { createObjectByDefaultKeys, extractId } from '../../utils/helpers';
import * as entitiesActions from '../entities/actions';
import { RootActions } from '../index';
import { defaultCurrentUser, defaultCurrentUserManaged } from '../users/reducer';
import * as appActions from './actions';
import { AppState } from './types';

// TODO find clean way to init store
export const defaultAppState: AppState = {
  deviceToken: null,
  token: null,
  registerOrganizationStep: 'cguStep',
  isUserFirstLogin: false,
  numberOfTimeHomepageWasVisited: 0,
  refreshToken: null,
  currentUser: {
    ...defaultCurrentUser,
  },
  isFullyLogged: false,
  areThematicsLoading: false,
  isAuthenticationRequestLoading: false,
  isUserLoading: false,
  isUserRequestLoading: false,
  routerDirection: 'none',
  addPostModalIsOpen: false,
  popoverConnectionIsOpen: false,
  lastCreatedPostId: null,
  ff: {
    indigo_devient_donnons: {
      envs: [],
      data: null,
      lastDate: {
        homepage: null,
        give: null,
      },
    },
    indigo_fermeture: {
      envs: [],
      data: null,
    },
    indigo_ferme_banner: {
      envs: [],
      data: null,
      lastDate: null,
    },
  },
};

export default (state = defaultAppState, action: RootActions): AppState => {
  let locationHistory;
  switch (action.type) {
    case getType(entitiesActions.addEntities): {
      // If we update the current user in entities.users, we do the same in app.currentUser
      if (typeof action.payload.users?.[extractId(state.currentUser)] === 'undefined') {
        return state;
      }

      return {
        ...state,
        currentUser: createObjectByDefaultKeys(state.currentUser.managed ? defaultCurrentUserManaged : defaultCurrentUser, {
          ...state.currentUser,
          ...action.payload.users[extractId(state.currentUser)],
        }),
      };
    }
    case getType(appActions.setRegistrationOrganizationStepAction):
      return { ...state, registerOrganizationStep: action.payload };
    case getType(appActions.setAddPostModalIsOpenAction):
      return { ...state, addPostModalIsOpen: action.payload };
    case getType(appActions.setPopoverConnectionIsOpenAction):
      return { ...state, popoverConnectionIsOpen: action.payload };
    case getType(appActions.setLastCreatedPostIdAction):
      return { ...state, lastCreatedPostId: action.payload };
    case getType(appActions.addUserThematicsAction.request):
      return { ...state, areThematicsLoading: true };
    case getType(appActions.createDeviceTokenAction.success):
      return { ...state, deviceToken: action.payload };
    case getType(appActions.setIsFullyLoggedInAction):
      return { ...state, isFullyLogged: action.payload };
    case getType(appActions.setIsUserFirstLoginAction):
      return { ...state, isUserFirstLogin: action.payload };
    case getType(appActions.homepageWasVisited):
      return { ...state, numberOfTimeHomepageWasVisited: state.numberOfTimeHomepageWasVisited + 1 };
    case getType(appActions.userLoginAction.request):
      return { ...defaultAppState, ...state, isAuthenticationRequestLoading: true };
    case getType(appActions.userLoginAction.success):
      if (null === action.payload) {
        return { ...defaultAppState, ...state, isAuthenticationRequestLoading: false };
      }
      return { ...state, token: action.payload.token, refreshToken: action.payload.refreshToken, isFullyLogged: false, isAuthenticationRequestLoading: false };
    case getType(appActions.userLoginAction.failure):
      return { ...defaultAppState, ...state, isAuthenticationRequestLoading: false };
    case getType(appActions.userRefreshTokenAction.success):
      return { ...state, token: action.payload.token, refreshToken: action.payload.refreshToken };
    case getType(appActions.managedUserLoginAction.success):
      return { ...state, token: action.payload.token, refreshToken: action.payload.refreshToken, isAuthenticationRequestLoading: false };
    case getType(appActions.managedUserLoginAction.failure):
    case getType(appActions.userRegisterAction.request):
      return { ...defaultAppState, ...state };
    case getType(appActions.fetchLoggedUserAction.success):
      return { ...state, currentUser: { ...state.currentUser, ...action.payload }, isUserLoading: false };
    case getType(appActions.resetAppForNewUserAction):
    case getType(appActions.fetchLoggedUserAction.request):
      return { ...state, isUserLoading: true };
    case getType(appActions.fetchLoggedUserAction.failure):
      return { ...state, isUserLoading: false };
    case getType(appActions.userRegisterAction.success):
    case getType(appActions.linkUserToThirdPartyAction.success):
    case getType(appActions.unlinkUserToFacebookAction.success):
    case getType(appActions.updateUserThematicsAction.success):
      if (null === action.payload) {
        return state;
      }
      return { ...state, currentUser: { ...state.currentUser, ...action.payload } };
    case getType(appActions.addUserThematicsAction.success):
      return { ...state, currentUser: { ...state.currentUser, favoriteCategoryThematics: action.payload }, areThematicsLoading: false };
    case getType(appActions.addUserThematicsAction.failure):
      return { ...state, areThematicsLoading: false };
    case getType(appActions.editUserAction.success):
      return { ...state, currentUser: { ...state.currentUser, ...action.payload }, isUserRequestLoading: false };
    case getType(appActions.editUserAction.request):
      return { ...state, currentUser: { ...state.currentUser }, isUserRequestLoading: true };
    case getType(appActions.editUserAction.failure):
      return { ...state, isUserRequestLoading: false };
    case getType(appActions.setRouterDirectionAction):
      return { ...state, routerDirection: action.payload };
    case getType(appActions.fetchAppVersionAction.success):
      return { ...state, minAppVersions: action.payload };
    case getType(appActions.fetchFFAction.success):
      return {
        ...state,
        ff: {
          indigo_devient_donnons: {
            ...state.ff.indigo_devient_donnons,
            envs: action.payload.indigo_devient_donnons.envs,
            data: action.payload.indigo_devient_donnons.data,
          },
          indigo_fermeture: {
            ...state.ff.indigo_fermeture,
            envs: action.payload.indigo_fermeture.envs,
            data: action.payload.indigo_fermeture.data,
          },
          indigo_ferme_banner: {
            ...state.ff.indigo_ferme_banner,
            envs: action.payload.indigo_ferme_banner.envs,
            data: action.payload.indigo_ferme_banner.data,
          },
        },
      };
    case getType(appActions.setFFIndigoDevientDonnonsLastDate):
      return {
        ...state,
        ff: {
          ...state.ff,
          indigo_devient_donnons: { ...state.ff.indigo_devient_donnons, lastDate: { ...state.ff.indigo_devient_donnons.lastDate, [action.payload.type]: action.payload.date.getTime() } },
        },
      };
    case getType(appActions.setFFIndigoFermeBannerLastDate):
      return {
        ...state,
        ff: {
          ...state.ff,
          indigo_ferme_banner: { ...state.ff.indigo_ferme_banner, lastDate: action.payload.date.getTime() },
        },
      };
    case getType(appActions.deleteOneUserAreaHistoryAction):
      locationHistory = [...(state.userArea?.locationHistory || []).filter(history => history.address?.formatted !== action.payload?.formatted)];
      return { ...state, userArea: { ...state.userArea, locationHistory } };
    case getType(appActions.setUserAreaAction):
      if (action.payload === undefined) {
        return { ...state, userArea: action.payload };
      }
      locationHistory = [action.payload, ...(state.userArea?.locationHistory || []).filter(history => history.address?.formatted !== action.payload?.address?.formatted)].slice(0, 5);
      return { ...state, userArea: { ...state.userArea, ...action.payload, locationHistory } };
    default:
      return state;
  }
};
