import { IonItem, IonLabel, IonList, IonThumbnail, IonImg, IonText, IonLoading } from '@ionic/react';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  UserNotification,
  GlobalNotificationData,
  NewFollowerNotificationData,
  NewPostFollowedNotificationData,
  UserReviewData,
  PostDeletedNotificationData,
  ModeratedPostData,
  ModeratedReportToReporterData,
} from '../store/notifications/types';
import { extractId, formatDateFromNow, getUserAvatar } from '../utils/helpers';
import { EntityReference, HydratedCollection } from '../utils/hydra';
import NotificationModal from './NotificationModal';
import ReactMarkdown from 'react-markdown';
import { User } from '../store/users/types';
import { RootState } from '../store';
import { useSelector } from 'react-redux';
import { getUser } from '../store/users/selectors';

import './NotificationsList.scss';
import ImageFadeIn from './ImageFadeIn';

interface UserNotificationItemProps {
  userNotification: UserNotification;
  onNotificationClick: (userNotifications: UserNotification) => void;
}

interface UserNotificationLabelItemProps {
  userNotification: UserNotification;
}

interface StateProps {
  getUser: (id: EntityReference | undefined) => User | undefined;
}

const getNotificationItemSelectors = (state: RootState): StateProps => ({
  getUser: (id: EntityReference | undefined) => getUser(state, id),
});

const PostTooOldNotificationLabel: React.FunctionComponent<UserNotificationLabelItemProps> = ({ userNotification }: UserNotificationLabelItemProps) => {
  const postTitle = (userNotification.notification.data as PostDeletedNotificationData).postTitle || '';
  return (
    <IonText color="dark" className="notification-item-title">
      <Trans i18nKey="notification.post-too-old" values={{ postTitle: `<strong>${postTitle}</strong>` }} />
    </IonText>
  );
};

const DeletedPostNotificationLabel: React.FunctionComponent<UserNotificationLabelItemProps> = ({ userNotification }: UserNotificationLabelItemProps) => {
  const deletedPostTranslatedReasons = ['too_old'];
  const deletionReason = (userNotification.notification.data as PostDeletedNotificationData).deletionReason;
  const postTitle = (userNotification.notification.data as PostDeletedNotificationData).postTitle || '';
  const labelKey = deletedPostTranslatedReasons.includes(deletionReason) ? `notification.post-deleted-${deletionReason}` : 'notification.post-deleted';
  return (
    <IonText color="dark" className="notification-item-title">
      <Trans i18nKey={labelKey} values={{ postTitle: `<strong>${postTitle}</strong>` }} />
    </IonText>
  );
};

const UserNotificationItem: React.FunctionComponent<UserNotificationItemProps> = ({ userNotification, onNotificationClick }: UserNotificationItemProps) => {
  const selectors: StateProps = useSelector(getNotificationItemSelectors);
  const imageIsUserAvatarCases = ['new_follower', 'followed_new_post', 'user_review'];
  const arrayUsersIriByUserNotificationData = {
    new_follower: (userNotification.notification.data as NewFollowerNotificationData).followerIri,
    followed_new_post: (userNotification.notification.data as NewPostFollowedNotificationData).followedIri,
    user_review: (userNotification.notification.data as UserReviewData).reviewCreatorIri,
  };
  const user = selectors.getUser(arrayUsersIriByUserNotificationData[userNotification.notification.reason as 'new_follower' | 'followed_new_post' | 'user_review']);
  return (
    <IonItem
      data-cy="notification-item"
      button
      detail={false}
      className={`notification-item ${userNotification.read && 'notification-item-read'}`}
      lines="none"
      onClick={() => onNotificationClick(userNotification)}
    >
      <IonThumbnail className="notification-item-logo">
        {imageIsUserAvatarCases.includes(userNotification.notification.reason) ? (
          <ImageFadeIn thumbnail src={getUserAvatar(user)} alt="avatar" data-cy="user-avatar" />
        ) : (
          <IonImg src="/assets/logo-indigo-gradient.svg" alt="Indigo" className="indigo-logo" />
        )}
      </IonThumbnail>
      <IonLabel className="notification-item-description">
        {userNotification.notification.reason === 'global_message' && (
          <IonText color="dark" className="notification-item-title">
            <ReactMarkdown>{(userNotification.notification.data as GlobalNotificationData).message}</ReactMarkdown>
          </IonText>
        )}
        {userNotification.notification.reason === 'post_too_old' && <PostTooOldNotificationLabel userNotification={userNotification} />}
        {userNotification.notification.reason === 'post_deleted' && <DeletedPostNotificationLabel userNotification={userNotification} />}
        {userNotification.notification.reason === 'followed_new_post' && (
          <IonText color="dark">
            <Trans
              i18nKey={`notification.followed-new-post`}
              values={{
                username: `<strong>${(userNotification.notification.data as NewPostFollowedNotificationData).followedDisplayName + ' ' || ''}</strong>`,
                postTitle: `<strong>${(userNotification.notification.data as NewPostFollowedNotificationData).postTitle + ' ' || ''}</strong>`,
              }}
            />
          </IonText>
        )}
        {userNotification.notification.reason === 'user_review' && (
          <IonText color="dark">
            <Trans
              i18nKey={`notification.user_review`}
              values={{
                username: `<strong>${(userNotification.notification.data as UserReviewData).reviewCreatorDisplayName + ' ' || ''}</strong>`,
              }}
            />
          </IonText>
        )}
        {userNotification.notification.reason === 'new_follower' && (
          <IonText color="dark">
            <Trans
              i18nKey={`notification.started-following-you`}
              values={{ username: `<strong>${(userNotification.notification.data as NewFollowerNotificationData).followerDisplayName + ' ' || ''}</strong>` }}
            />
          </IonText>
        )}
        {userNotification.notification.reason === 'moderated_post' && (
          <IonText color="dark">
            <Trans
              i18nKey={`notification.moderated_post`}
              values={{
                postTitle: `<strong>${(userNotification.notification.data as ModeratedPostData).postTitle + ' ' || ''}</strong>`,
              }}
            />
          </IonText>
        )}
        {userNotification.notification.reason === 'moderated_report_to_reporter' && (
          <IonText color="dark">
            <Trans
              i18nKey={`notification.moderated_report_to_reporter_${(userNotification.notification.data as ModeratedReportToReporterData).type}`}
              values={{
                postTitle: `<strong>${(userNotification.notification.data as ModeratedReportToReporterData).postTitle + ' ' || ''}</strong>`,
                blockedUserDisplayName: `<strong>${(userNotification.notification.data as ModeratedReportToReporterData).blockedUserDisplayName + ' ' || ''}</strong>`,
              }}
            />
          </IonText>
        )}
        <IonText color="medium" className="notification-item-date">
          {formatDateFromNow(userNotification.notification.createdAt)}
        </IonText>
      </IonLabel>
      {
        <IonThumbnail className="notification-item-image">
          {(userNotification.notification.data as GlobalNotificationData).imageUrl && <IonImg src={(userNotification.notification.data as GlobalNotificationData).imageUrl} alt="notification-image" />}
        </IonThumbnail>
      }
    </IonItem>
  );
};

interface ListProps {
  userNotifications: HydratedCollection<UserNotification>;
  onStatusClick: (userNotifications: UserNotification, read: boolean) => Promise<void>;
}

const notificationsDisplayInModal = ['moderated_post', 'moderated_report_to_reporter', 'global_message'];

type NotificationsListType = ListProps;

const NotificationsList: React.FunctionComponent<NotificationsListType> = ({ userNotifications, onStatusClick }: NotificationsListType) => {
  const history = useHistory();
  const [selectedNotification, setSelectedNotification] = useState<UserNotification | null>(null);

  const onNotificationClick = (userNotification: UserNotification): void => {
    if (userNotification.notification.reason === 'new_follower') {
      onStatusClick(userNotification, true);
      history.push('/posts' + (userNotification.notification.data as NewFollowerNotificationData).followerIri);
      return;
    }
    if (userNotification.notification.reason === 'followed_new_post') {
      onStatusClick(userNotification, true);
      history.push((userNotification.notification.data as NewPostFollowedNotificationData).postIri);
      return;
    }
    if (userNotification.notification.reason === 'user_review') {
      onStatusClick(userNotification, true);
      history.push('/posts' + (userNotification.notification.data as UserReviewData).reviewedIri + '/reviews?type=personalReviews');
      return;
    }
    if (userNotification.notification.reason === 'post_too_old') {
      onStatusClick(userNotification, true);
      history.push((userNotification.notification.data as PostDeletedNotificationData).postIri);
      return;
    }
    if (userNotification.notification.reason === 'post_deleted') {
      onStatusClick(userNotification, true);
      history.push((userNotification.notification.data as PostDeletedNotificationData).postIri);
      return;
    }

    if (!notificationsDisplayInModal.includes(userNotification.notification.reason)) {
      return;
    }

    setSelectedNotification(userNotification);
    onStatusClick(userNotification, true);
  };

  if (userNotifications.isLoading && userNotifications.totalItems === 0) {
    return <IonLoading spinner="bubbles" showBackdrop={false} isOpen={true} />;
  }

  if (userNotifications.totalItems === 0 && !userNotifications.isLoading) {
    return (
      <IonItem lines="none" className="notification-not-found">
        <IonLabel className="ion-text-center">
          <Trans i18nKey="notification.no-notifications-found" />
        </IonLabel>
      </IonItem>
    );
  }

  return (
    <>
      <NotificationModal
        isOpen={selectedNotification !== null && notificationsDisplayInModal.includes(selectedNotification.notification.reason)}
        userNotification={selectedNotification}
        onClose={() => setSelectedNotification(null)}
      />
      <IonList>
        {userNotifications.items.map((userNotification: UserNotification) => (
          <UserNotificationItem key={extractId(userNotification)} userNotification={userNotification} onNotificationClick={onNotificationClick} />
        ))}
      </IonList>
    </>
  );
};

export default NotificationsList;
