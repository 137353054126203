import { FacebookCurrentAccessTokenResponse, FacebookLogin, FacebookLoginPlugin, FacebookLoginResponse } from '@capacitor-community/facebook-login';
import { Capacitor } from '@capacitor/core';
import { throwErrorIfAny } from './dataAccess';
import { FacebookDataPlugin, FacebookLoginMock } from '../capacitor/FacebookPluginMock';
import { isHttps, isTest } from '../environment';
import fetch from './fetch';

const FACEBOOK_PERMISSIONS = ['email'];

export interface FacebookUserInfos {
  id: string;
  name: string;
}

export interface FacebookUserFullInfos {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  picture: { data: FacebookUserPictureData };
}

export interface FacebookUserPictureData {
  is_silhouette?: boolean;
  url: string;
  width?: number;
  height?: number;
}

function getFacebookPlugin(): FacebookLoginPlugin | (FacebookLoginPlugin & FacebookDataPlugin) {
  if (isTest || (!isHttps && Capacitor.getPlatform() === 'web')) {
    return new FacebookLoginMock();
  }
  return FacebookLogin;
}

export function isFacebookEnabled(): boolean {
  return isHttps || isTest || Capacitor.getPlatform() !== 'web';
}

interface FacebookError {
  message?: string;
}

export function getFacebookToken(): Promise<string> {
  return new Promise((resolve, reject) => {
    getFacebookPlugin()
      .login({ permissions: FACEBOOK_PERMISSIONS })
      .then((result: FacebookLoginResponse) => {
        console.log('FacebookLoginResponse result', result);
        if (!result.accessToken || !result.accessToken.token) {
          reject();
          return;
        }

        resolve(result.accessToken.token);
      })
      .catch((e: FacebookError) => {
        reject(e.message);
      });
  });
}

export async function facebookLogout(): Promise<void> {
  try {
    const resp = await getFacebookPlugin()
      .getCurrentAccessToken()
      .catch(e => e); // If token === null, getCurrentAccessToken will throw a FacebookCurrentAccessTokenResponse with null token
    if (resp?.accessToken?.token) {
      await getFacebookPlugin().logout();
    }
  } catch (e) {
    console.error(e);
  }
}

export function getCurrentFacebookToken(): Promise<string> {
  return getFacebookPlugin()
    .getCurrentAccessToken()
    .then((resp: FacebookCurrentAccessTokenResponse) => {
      if (!resp.accessToken || !resp.accessToken.token) {
        return Promise.reject({ accessToken: { token: null } });
      }

      return resp.accessToken.token;
    });
}

export function getFacebookUserIdAndFullname(token: string): Promise<FacebookUserInfos> {
  const plugin = getFacebookPlugin();
  if ('getFacebookUserIdAndFullname' in plugin) {
    return plugin.getFacebookUserIdAndFullname(token);
  }

  return fetch(`https://graph.facebook.com/me?access_token=${token}`)
    .then(throwErrorIfAny)
    .then(response => response.json() as Promise<FacebookUserInfos>);
}

export function getFacebookUserInfos(facebookId: string, token: string): Promise<FacebookUserFullInfos> {
  const plugin = getFacebookPlugin();
  if ('getFacebookUserInfos' in plugin) {
    return plugin.getFacebookUserInfos(facebookId, token);
  }

  return fetch(`https://graph.facebook.com/${facebookId}?fields=first_name,last_name,email&access_token=${token}`)
    .then(throwErrorIfAny)
    .then(response => response.json() as Promise<FacebookUserFullInfos>);
}

export async function getFacebookUserPicture(facebookId: string): Promise<FacebookUserPictureData> {
  const plugin = getFacebookPlugin();
  if ('getFacebookUserPicture' in plugin) {
    return plugin.getFacebookUserPicture(facebookId);
  }

  const accessToken = await plugin.getCurrentAccessToken();
  const token = accessToken.accessToken?.token;
  const pictureUrl = `https://graph.facebook.com/${facebookId}/picture?redirect=false&type=large&width=960&height=960&access_token=${token}`;

  return fetch(pictureUrl)
    .then(throwErrorIfAny)
    .then(response => response.json())
    .then(data => data.data as FacebookUserPictureData);
}
