import { Capacitor } from '@capacitor/core';
import React from 'react';
import { IonIcon, IonButton } from '@ionic/react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Market } from '@ionic-native/market';
import { androidPackageName, iosAppId } from '../environment';
import { reloadWindowLocation } from '../utils/windowLocationHelper';

import './UpgradeAppAlert.scss';

interface UpgradeAppAlertProps {
  onAlertClose: () => void;
  setToastMessage: (message: string) => void;
}

export const upgradeApp = (): Promise<void> => {
  if ('web' === Capacitor.getPlatform()) {
    reloadWindowLocation();
    return Promise.resolve();
  }
  return Market.open(Capacitor.getPlatform() === 'ios' ? iosAppId : androidPackageName);
};

type Props = UpgradeAppAlertProps & WithTranslation;

const UpgradeAppAlert: React.FunctionComponent<Props> = ({ onAlertClose, setToastMessage, t }: Props) => {
  return (
    <div data-cy="upgrade-alert" className="upgrade-alert">
      <IonIcon className="upgrade-alert-refresh-icon" icon="/assets/icon/icon-refresh.svg" color="white" />
      <div
        className="upgrade-alert-message-wrapper"
        onClick={e => {
          e.stopPropagation();
          upgradeApp().catch(e => {
            if (e === 'cordova_not_available') {
              return setToastMessage('This feature is not available on this device as cordova does not exist.');
            }
            setToastMessage(e);
          });
        }}
      >
        {/* We use t() and not <Trans /> however the translation is not updated correctly on locale change */}
        <b>{t('app.minor-upgrade-title')}</b>
        <div className="upgrade-alert-download-link"> {t('app.minor-upgrade-content')}</div>
      </div>
      <IonButton className="upgrade-alert-close-button" onClick={onAlertClose}>
        <IonIcon icon="/assets/navigation/close-white.svg" />
      </IonButton>
    </div>
  );
};

export default withTranslation()(UpgradeAppAlert);
