import * as thematicActions from './actions';
import { ActionType, getType } from 'typesafe-actions';
import { ThematicState } from './types';

const defaultState: ThematicState = {
  isLoading: false,
  thematics: [],
};

export default (state = defaultState, action: ActionType<typeof thematicActions>): ThematicState => {
  switch (action.type) {
    case getType(thematicActions.fetchThematicsAction.request):
      return { ...state, isLoading: true };
    case getType(thematicActions.fetchThematicsAction.failure):
      return { ...state, isLoading: false };
    case getType(thematicActions.fetchThematicsAction.success):
      return { ...state, isLoading: false, thematics: action.payload };
    default:
      return state;
  }
};
