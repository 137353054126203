import { IonButtons, IonCol, IonContent, IonRow, IonButton, IonThumbnail, IonActionSheet, IonPage, withIonLifeCycle } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import GalleryModal from '../components/GalleryModal';
import { actions, RootState } from '../store';
import { CurrentUser } from '../store/users/types';
import CommonHeader from '../components/CommonHeader';
import { extractId, getItemSrc, isEqualIgnoringFunctions, uploadAndValidateFiles } from '../utils/helpers';
import { FormValues } from '../components/DynamicForm';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import InputMediaUploader, { itemIsAMedia } from '../components/inputs/InputMediaUploader';
import { Media } from '../store/app/types';
import { UserFormValues, UserPicturesFormValues } from '../components/UserForm';
import { isSameHydraEntity } from '../utils/helpers';
import isEqual from 'lodash/isEqual';
import { sendProfilePictureUploadedLog } from '../utils/analytics/analyticsHelper';
import ImageFadeIn from '../components/ImageFadeIn';
import './CurrentUserPicturesPage.scss';

interface State {
  isOpen: boolean;
  slideIndex: number;
  showActionSheet: boolean;
}

interface StateProps {
  currentUser: CurrentUser;
  isUserUpdateLoading: boolean;
}

const mapStateToProps = (state: RootState): StateProps => ({
  currentUser: state.app.currentUser,
  isUserUpdateLoading: state.app.isUserRequestLoading,
});

interface DispatchProps {
  fetchLoggedUser: () => Promise<void>;
  updateUser(user: Partial<UserFormValues>): Promise<CurrentUser>;
  deleteMediaObject(media: Media): Promise<void>;
  setToastMessage(message: string | null): void;
}

const propsToDispatch = {
  fetchLoggedUser: actions.app.fetchLoggedUser,
  updateUser: actions.app.updateUser,
  deleteMediaObject: actions.app.deleteMediaObject,
  setToastMessage: actions.layout.setToastMessageAction,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type Props = StateProps & DispatchProps & WithTranslation;

class CurrentUserPicturesPage extends Component<Props, State> {
  private readonly ionContentRef: React.RefObject<HTMLIonContentElement>;
  private readonly ionRawRef: React.RefObject<HTMLIonContentElement>;

  private filesIds: WeakMap<File, string>;

  public constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      slideIndex: 0,
      showActionSheet: false,
    };

    this.ionContentRef = React.createRef<HTMLIonContentElement>();
    this.ionRawRef = React.createRef<HTMLIonContentElement>();
    this.filesIds = new WeakMap<File, string>();

    this.closeModal = this.closeModal.bind(this);
  }

  public ionViewDidEnter(): void {
    this.props.fetchLoggedUser();
  }

  public shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>): boolean {
    return !isEqualIgnoringFunctions(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  private static setFormField(values: FormValues, form: FormikProps<FormValues>, field: string): void {
    form.setFieldValue(field, values);
    form.submitForm();
  }

  private setImageAsAvatar = (values: FormValues, form: FormikProps<FormValues>): void => {
    const { setToastMessage, t } = this.props;

    if (isSameHydraEntity(values.avatar, values.images[this.state.slideIndex])) {
      setToastMessage(t('user.avatar-the-same'));
      return;
    }
    CurrentUserPicturesPage.setFormField(values.images[this.state.slideIndex], form, 'avatar');

    setTimeout(() =>
      this.setState({
        isOpen: false,
        slideIndex: 0,
      }),
    );
  };

  private deleteImage = (values: FormValues, form: FormikProps<FormValues>): void => {
    const images = values.images.filter((image: Media | File, index: number) => index !== this.state.slideIndex);

    CurrentUserPicturesPage.setFormField(images, form, 'images');

    if (images.length > 0 && images[1]) {
      return;
    }

    setTimeout(() =>
      this.setState({
        isOpen: images.length !== 0,
        slideIndex: images.length === 0 ? 1 : images.length,
      }),
    );
  };

  private submitForm = async (values: UserPicturesFormValues, actions: FormikHelpers<UserPicturesFormValues>): Promise<void> => {
    const { currentUser, t } = this.props;

    try {
      if (values.images) {
        await uploadAndValidateFiles(values, actions as FormikHelpers<FormValues>, 'images', t);
      }

      if (values.avatar) {
        await uploadAndValidateFiles(values, actions as FormikHelpers<FormValues>, 'avatar', t);
      }
      sendProfilePictureUploadedLog();
    } catch (e) {
      const filteredImageValues = (values.images as (File | Media)[]).filter((image: File | Media) => itemIsAMedia(image));

      actions.setFieldValue('images', filteredImageValues);
      actions.setFieldValue('avatar', currentUser.avatar);

      if (isEqual(currentUser.images, filteredImageValues) && isSameHydraEntity(currentUser.avatar as Media, values.avatar as Media)) {
        this.props.setToastMessage(e.message ? e.message : t('user.images-uploaded-fail'));
        actions.setSubmitting(false);
        return;
      }
    }

    const newUserValues: Partial<CurrentUser> = {
      '@id': extractId(currentUser),
    };

    if (values.images && !isEqual(currentUser.images, values.images)) {
      newUserValues.images = values.images;
    }

    if (values.avatar && !isSameHydraEntity(currentUser.avatar as Media, values.avatar as Media)) {
      newUserValues.avatar = values.avatar;
    }

    if (values.avatar || currentUser.avatar) {
      const isAvatarImageExists = (values.images as Media[]).find(item => isSameHydraEntity(item, values.avatar as Media) || isSameHydraEntity(item, currentUser.avatar as Media));

      if (!isAvatarImageExists) {
        newUserValues.avatar = null;
      }
    }

    // If no currentUser avatar, we set the first picture he add as his avatar
    if (!currentUser.avatar && !newUserValues.avatar && newUserValues.images) {
      newUserValues.avatar = values.images?.[0] as Media;
    }

    try {
      await this.props.updateUser(newUserValues);

      this.showToastMessage(currentUser.avatar as Media, currentUser.images as Media[], values);
      this.checkAndDeleteImages(currentUser.avatar as Media, currentUser.images as Media[]);
    } catch {
      this.showToastMessage(currentUser.avatar as Media, currentUser.images as Media[], values, '-fail');

      actions.setSubmitting(false);
      actions.resetForm({ values: { images: currentUser.images, avatar: currentUser.avatar } });
      return;
    }
  };

  private showToastMessage = (previousAvatar: Media, previousImages: Media[], values: Readonly<UserFormValues>, type = ''): void => {
    const { setToastMessage, t } = this.props;

    if (isSameHydraEntity(previousAvatar as Media, values.avatar as Media)) {
      setToastMessage(t(previousImages.length > (values.images || [])?.length ? `user.image-deleted${type}` : `user.images-uploaded${type}`));
    } else {
      setToastMessage(t(`user.avatar-changed${type}`));
    }
  };

  private checkAndDeleteImages = (previousAvatar: Media, previousImages: Media[]): void => {
    const { currentUser } = this.props;

    const images = (currentUser.images as Media[]).map((image: Media) => extractId(image));
    const deletedMedia = isSameHydraEntity(previousAvatar, currentUser.avatar as Media) ? previousImages.find((item: Media) => !images.includes(extractId(item))) : previousAvatar;
    const isImageExist = (currentUser.images as Media[]).find((media: Media) => isSameHydraEntity(media, deletedMedia));

    if (
      isImageExist ||
      !deletedMedia ||
      isSameHydraEntity(deletedMedia, currentUser.avatar as Media) ||
      (isSameHydraEntity(previousAvatar, currentUser.avatar as Media) && previousImages.length <= currentUser.images.length)
    ) {
      return;
    }

    this.props.deleteMediaObject(deletedMedia);
  };

  private setShowActionSheet = (showActionSheet: boolean): void => {
    if (this.state.showActionSheet !== showActionSheet) {
      this.setState({ showActionSheet });
    }
  };

  private updateSlideIndex = (currentMediaIndex: number): void => {
    this.setState({ slideIndex: currentMediaIndex });
  };

  getAddPicturePlaceholder = (isUserLoading: boolean): ReactNode => {
    return (
      <IonButtons
        onClick={e => {
          if (isUserLoading) {
            e.stopPropagation();
          }
        }}
      >
        <IonButton className="add-photo-button" color="primary" fill="solid" disabled={isUserLoading}>
          <Trans i18nKey="user.add-new-photos" />
        </IonButton>
      </IonButtons>
    );
  };

  private getFileId(file: File): string {
    if (!this.filesIds.has(file)) {
      this.filesIds.set(file, Math.random().toFixed(10));
    }

    return this.filesIds.get(file) || Math.random().toFixed(10);
  }

  public render(): ReactNode {
    const { isOpen, showActionSheet } = this.state;
    const { t, isUserUpdateLoading, setToastMessage } = this.props;
    const isScrollBarAppeared = this.ionContentRef?.current?.clientWidth !== this.ionRawRef?.current?.clientWidth;

    return (
      <IonPage data-cy="current-user-picture-page">
        <CommonHeader addIonHeader={true} title={this.props.t('user.my-photos')} />
        <Formik initialValues={{ images: this.props.currentUser.images || [], avatar: this.props.currentUser.avatar }} onSubmit={this.submitForm} enableReinitialize>
          {(form: FormikProps<FormValues>) => {
            return (
              <IonContent className="user-pictures-page" ref={this.ionContentRef}>
                <IonRow responsive-sm ref={this.ionRawRef}>
                  <IonCol className="preview-wrap">
                    {form.values.images.map((item: Media | File, key: number) => {
                      return (
                        <IonThumbnail
                          key={itemIsAMedia(item) ? extractId(item) : this.getFileId(item)}
                          className={`cursor-pointer preview ${!itemIsAMedia(item) ? 'not-uploaded' : ''} ${isScrollBarAppeared ? 'has-scroll' : ''}`}
                          onClick={() => {
                            this.setState({ isOpen: true, slideIndex: key });
                          }}
                          data-cy="user-images-preview"
                        >
                          <ImageFadeIn thumbnail alt="preview" src={getItemSrc(item)} />
                        </IonThumbnail>
                      );
                    })}
                  </IonCol>
                </IonRow>

                <IonRow responsive-sm data-cy="add-photo-button-wrap" className={`add-photo-button-wrap ${isUserUpdateLoading ? 'add-photo-button-wrap-disabled' : ''}`}>
                  <InputMediaUploader
                    value={form.values.images}
                    maxFiles={12}
                    multiple
                    isMediaUploaderField
                    onValueChange={values => CurrentUserPicturesPage.setFormField(values, form, 'images')}
                    onError={(message: string, options?: Record<string, unknown>) => setToastMessage(t(message, options))}
                    addPicturePlaceholder={this.getAddPicturePlaceholder(isUserUpdateLoading)}
                  />
                </IonRow>

                <IonActionSheet
                  isOpen={showActionSheet}
                  onDidDismiss={() => this.setShowActionSheet(false)}
                  cssClass="pictures-action-sheet"
                  buttons={[
                    {
                      text: t('user.set-profile-picture'),
                      handler: () => this.setImageAsAvatar(form.values, form),
                    },
                    {
                      text: t('post.delete-post'),
                      role: 'destructive',
                      handler: () => this.deleteImage(form.values, form),
                    },
                    {
                      text: t('common.cancel'),
                      role: 'cancel',
                      handler: () => this.setShowActionSheet(false),
                    },
                  ]}
                />

                <GalleryModal
                  isOpen={isOpen}
                  closeModal={this.closeModal}
                  images={form.values.images}
                  initialSlide={this.state.slideIndex}
                  moreButtonAction={() => this.setShowActionSheet(true)}
                  updateSlideIndex={this.updateSlideIndex}
                />
              </IonContent>
            );
          }}
        </Formik>
      </IonPage>
    );
  }

  private closeModal(): void {
    this.setState({ isOpen: false });
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withIonLifeCycle(CurrentUserPicturesPage)));
