import { getPlatforms, IonRouterOutlet, RouterDirection } from '@ionic/react';
import { LocationState } from '@ionic/react-router/dist/types/ReactRouter/IonRouter';
import { UnregisterCallback } from 'history';
import i18next from 'i18next';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import UpgradeAppAlert from './components/UpgradeAppAlert';
import BlockedAccountsPage from './pages/BlockedAccountsPage';
import NotificationPage from './pages/NotificationPage';
import HomePage from './pages/HomePage';
import NotificationRedirectionPage from './pages/NotificationRedirectionPage';
import PrivacySettingsPage from './pages/PrivacySettingsPage';
import ChatPage from './pages/ChatPage';
import ChatsPage from './pages/ChatsPage';
import CreateGlobalNotificationPage from './pages/CreateGlobalNotificationPage';
import CreatePostPage from './pages/CreatePostPage';
import CurrentUserPicturesPage from './pages/CurrentUserPicturesPage';
import CurrentUserProfilePage from './pages/CurrentUserProfilePage';
import DeleteAccountPage from './pages/DeleteAccountPage';
import EditPasswordPage from './pages/EditPasswordPage';
import EditPostPage from './pages/EditPostPage';
import EditProfilePage from './pages/EditProfilePage';
import GlobalNotificationPreviewPage from './pages/GlobalNotificationPreviewPage';
import LinkedAccountsPage from './pages/LinkedAccountsPage';
import LoginOrganization from './pages/LoginOrganizationPage';
import NotificationSettingsPage from './pages/NotificationSettingsPage';
import NotificationsPage from './pages/NotificationsPage';
import PostPage from './pages/PostPage';
import PostRequestPage from './pages/PostRequestsPage';
import PostsPage from './pages/PostsPage';
import ProfileMenuItemComponentPage from './pages/ProfileMenuItemComponentPage';
import RegisterOrganizationPage from './pages/RegisterOrganizationPage';
import SearchPage from './pages/SearchPage';
import SelectProfilePage from './pages/SelectProfilePage';
import UserFollowsPage from './pages/UserFollowsPage';
import UserProfilePage from './pages/UserProfilePage';
import UserReviewsPage from './pages/UserReviewsPage';
import UserPicturesPage from './pages/UserPicturesPage';
import ManageChatParticipantsPage from './pages/ManageChatParticipantsPage';
import { actions, RootState } from './store';
import { getCurrentUser } from './store/app/selectors';
import { logRouteChangedEvent } from './utils/analytics/analyticsHelper';
import { EntityReference } from './utils/hydra';
import { closeModals, modalsExistInDOM } from './utils/modalsHelper';
import CommentsPage from './pages/CommentsPage';
import TabBar from './components/TabBar';
import { clearRedirectAfterLogin, ROUTES_WITHOUT_FOOTER, setRedirectAfterLogin } from './utils/routeHelpers';
import { POSTS_TAB, CREATE_POSTS_TAB, DEFAULT_TAB, MAIN_TABS, SEARCH_TAB, ME_TAB } from './utils/TabNames';
import MapPage from './pages/MapPage';
import LoginPage from './pages/LoginPage';
import PrivacyPage from './pages/PrivacyPage';
import RegisterPage from './pages/RegisterPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import RegisterNewUserPage from './pages/RegisterNewUserPage';
import NewsletterSettingsPage from './pages/NewsletterSettingsPage';
import { setStatusBarStyle } from './utils/statusBarHelper';
import { extractId, uuidReg } from './utils/helpers';
import ErrorComponent from './ErrorComponent';
import { PostType } from './store/posts/types';
import UpgradeAppModal from './components/UpgradeAppModal';
import { UpgradeLayoutStatus } from './store/layout/types';
import { App } from '@capacitor/app';
import { Dialog } from '@capacitor/dialog';
import { getI18n } from 'react-i18next';
import { FF } from './store/app/types';
import { apiEntrypoint } from './environment';
import IndigoDownModal from './IndigoDownModal';
import IndigoDevientDonnonsModal from './IndigoDevientDonnonsModal';

const RedirectToHomepage: React.FC = () => {
  setStatusBarStyle();
  setRedirectAfterLogin(location.pathname);
  return <Redirect to="/homepage" />;
};

type RedirectToVisitorsHomepageProps = {
  route?: string;
};

const RedirectToVisitorsHomepage: React.FC<RedirectToVisitorsHomepageProps> = ({ route }: RedirectToVisitorsHomepageProps) => {
  setStatusBarStyle();
  return <Redirect to={route ?? '/posts'} />;
};

interface IdableRouteParams {
  id: string;
}

interface PostTypableRouteParams {
  type: PostType;
}

interface StateProps {
  managedUsersTotalItems: number;
  currentUserIsManaged: boolean;
  isFullyLogged?: boolean;
  upgradeLayoutStatus?: UpgradeLayoutStatus;
  ff: FF;
}

// Only put simple values here in order to avoid useless render while reselect is not used
// TODO Use reselect or shouldComponentUpdate() and only inject currentUser
const mapStateToProps = (state: RootState): StateProps => ({
  managedUsersTotalItems: state.whoAmI.managedUsers?.totalItems || 0,
  currentUserIsManaged: getCurrentUser(state).managed || false,
  isFullyLogged: state.app.isFullyLogged && !!extractId(getCurrentUser(state)),
  upgradeLayoutStatus: state.layout.upgradeLayoutStatus,
  ff: state.app.ff,
});

interface DispatchProps {
  registerNotificationListeners: () => void;
  setActiveTab: (activeTab: string | undefined) => void;
  setTabHistory: (tab: string, pathname: string, direction: string | undefined) => void;
  setRouterDirection: (routerDirection: RouterDirection) => void;
  setUpgradeLayoutStatus: (upgradeLayoutStatus: UpgradeLayoutStatus) => void;
  setToastMessage: (message: string) => void;
  setFFIndigoDevientDonnonsLastDate: (type: 'homepage' | 'give', date: Date) => void;
}

const propsToDispatch = {
  registerNotificationListeners: actions.app.registerNotificationListenersAction,
  setActiveTab: actions.navigation.setActiveTabAction,
  setTabHistory: actions.navigation.setTabHistoryAction,
  setRouterDirection: actions.app.setRouterDirectionAction,
  setUpgradeLayoutStatus: actions.layout.setUpgradeLayoutStatusAction,
  setToastMessage: actions.layout.setToastMessageAction,
  setFFIndigoDevientDonnonsLastDate: actions.app.setFFIndigoDevientDonnonsLastDate,
};

interface State {
  showExitModal: boolean;
  indigoDown: boolean;
  indigoDevientDonnons: boolean;
  postPageLastRefresh?: Date;
  searchPageLastRefresh?: Date;
}

interface TabLocationState {
  tabDirection: string;
}

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type Props = RouteComponentProps & DispatchProps & StateProps;

// TODO lazy components /!\ It will use suspense on the first time the page is loaded. Can be weird in a mobile app
// const PostsPage = React.lazy(() => import('./pages/PostsPage'));

class RoutingMenu extends Component<Props, State> {
  historyUnsubscribe: UnregisterCallback | undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      showExitModal: false,
      indigoDown: false,
      indigoDevientDonnons: false,
    };

    this.handleTabChanged = this.handleTabChanged.bind(this);
    this.onIndigoDevientDonnonsClose = this.onIndigoDevientDonnonsClose.bind(this);
  }

  private static getTabNameFromPathname(pathname: string): string {
    return pathname.substring(1).split('/')[0];
  }

  private static getActiveTabFromPathname(pathname: string): string | undefined {
    const curPath = RoutingMenu.getTabNameFromPathname(pathname);

    // Root route.
    if ('' === curPath) {
      return DEFAULT_TAB;
    }

    // We return the default tab ('/posts') so that map and notification pages are added in this tab history
    if (pathname === '/map' || pathname === '/notifications') {
      return DEFAULT_TAB;
    }

    if (pathname === '/homepage') {
      return ME_TAB;
    }

    if (!MAIN_TABS.includes(curPath)) {
      return undefined;
    }

    return curPath;
  }

  public componentDidMount(): void {
    this.historyUnsubscribe = this.props.history.listen(location => {
      if (modalsExistInDOM()) {
        closeModals();
      }

      const locationState = location.state as LocationState & TabLocationState;
      const direction = locationState?.direction;
      if (direction) {
        setTimeout(() => this.props.setRouterDirection(direction));
      }

      const activeTab = RoutingMenu.getActiveTabFromPathname(location.pathname);
      this.props.setActiveTab(activeTab);
      if (activeTab) {
        this.props.setTabHistory(activeTab, location.pathname, locationState?.tabDirection);
      }

      logRouteChangedEvent(location.pathname);
    });

    if (this.props.isFullyLogged) {
      this.props.registerNotificationListeners();
    }

    if (getPlatforms().includes('mobile') && this.props.history.location.pathname !== '/select_profiles' && this.props.managedUsersTotalItems) {
      setTimeout(() => {
        setRedirectAfterLogin(location.pathname);
        this.props.history.push('/select_profiles');
      });
    }

    const activeTab = RoutingMenu.getActiveTabFromPathname(this.props.location.pathname);
    if (this.props.location.pathname !== '/' && activeTab) {
      this.props.setActiveTab(activeTab);
      this.props.setTabHistory(activeTab, this.props.location.pathname, undefined);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    // Login
    if (!prevProps.isFullyLogged && this.props.isFullyLogged) {
      this.props.registerNotificationListeners();
    }
    // Logout
    if (prevProps.isFullyLogged && !this.props.isFullyLogged) {
      this.props.history.push('/posts');
      clearRedirectAfterLogin(); // Empty the last page in case of logout
    }

    if (this.state.showExitModal && prevState.showExitModal !== this.state.showExitModal) {
      this.showExitConfirm().then(value => {
        this.setState({ showExitModal: false });
        if (value) {
          App.exitApp();
        }
      });
    }

    if (
      (this.props.ff.indigo_fermeture.envs.includes('preprod') && apiEntrypoint.includes('preprod')) ||
      (this.props.ff.indigo_fermeture.envs.includes('production') && !apiEntrypoint.includes('preprod'))
    ) {
      if (!this.state.indigoDown) {
        this.setState({ indigoDown: true });
      }
    }

    if (
      (this.props.ff.indigo_devient_donnons.envs.includes('preprod') && apiEntrypoint.includes('preprod')) ||
      (this.props.ff.indigo_devient_donnons.envs.includes('production') && !apiEntrypoint.includes('preprod'))
    ) {
      const hoursDiff = Math.abs(new Date().getTime() - (this.props.ff.indigo_devient_donnons.lastDate.homepage ?? new Date().getTime())) / 36e5;

      if (hoursDiff >= 24 || !this.props.ff.indigo_devient_donnons.lastDate.homepage) {
        if (!this.state.indigoDevientDonnons) {
          this.setState({ indigoDevientDonnons: true });
        }
      }
    }
  }

  private showExitConfirm = async (): Promise<boolean> => {
    const history = this.props.history;
    const routerInBackDirection = (history.location.state as { direction: RouterDirection }).direction === 'back';

    if (!(history.location.pathname === '/posts' && routerInBackDirection)) {
      return false;
    }

    const response = await Dialog.confirm({
      title: i18next.t('app.confirm'),
      message: i18next.t('app.confirm-exit-app'),
    });

    if (!response) {
      return false;
    }

    return response.value;
  };

  public componentWillUnmount(): void {
    if (!this.historyUnsubscribe) {
      return;
    }
    this.historyUnsubscribe();
  }

  private readonly onIndigoDevientDonnonsClose = (): void => {
    this.setState({ indigoDevientDonnons: false });
    this.props.setFFIndigoDevientDonnonsLastDate('homepage', new Date());
  };

  private readonly handleTabChanged = (tabName: string): void => {
    if (tabName === CREATE_POSTS_TAB) {
      return;
    }
    this.props.setActiveTab(tabName);
    this.props.history.push(`/${tabName}`);

    if (tabName === POSTS_TAB) {
      this.setState({ postPageLastRefresh: new Date() });
    }

    if (tabName === SEARCH_TAB) {
      this.setState({ searchPageLastRefresh: new Date() });
    }
  };

  public render(): ReactNode {
    const { isFullyLogged, upgradeLayoutStatus } = this.props;
    const i18n = getI18n();

    const extractIdFromRoute = (props: RouteComponentProps, prefix: string): EntityReference | null => {
      const reg = new RegExp(`(${uuidReg})`);
      const routeParams: IdableRouteParams | undefined = props?.match?.params as IdableRouteParams;

      if (routeParams?.id && routeParams.id.match(reg)) {
        return (prefix + routeParams.id) as EntityReference;
      }

      // id can be undefined, it's a bug somewhere in the router
      console.info(`Router error: unable to extract the entity ID. Getting it from the location: ${props.location.pathname}`);
      const regResult = reg.exec(props.location.pathname);
      if (regResult && regResult.length >= 2) {
        return (prefix + regResult[1]) as EntityReference;
      }

      console.info('Unable to get the id from the location');
      return null;
    };

    const isRouteWithoutFooter = ROUTES_WITHOUT_FOOTER.includes(this.props.history.location.pathname);
    const isConnected = isFullyLogged;
    const defaultPage = '/posts';

    /**
     * Caution: we cannot use regex in routes, as there is a bug in the router: if the regex does not match once, all regex will fail after that
     * To reproduce the bug: add `:id(${uuidReg})` to the `/posts/:id` route, open the app on "/posts/foobar", then go to the posts page and open any post.
     */
    return (
      <React.Fragment>
        <div className={isRouteWithoutFooter ? 'flex' : 'content'}>
          <Switch>
            {/* Privacy policy */}
            <Route path="/privacy" exact render={() => <PrivacyPage />} />

            {/* Visitors pages outside IonRouterOutlet */}
            <Route path="/login" exact render={() => (isConnected ? <RedirectToVisitorsHomepage route="/select_profiles" /> : <LoginPage />)} />
            <Route path="/register" exact render={() => (isConnected ? <RedirectToVisitorsHomepage route="/select_profiles" /> : <RegisterPage />)} />
            <Route path="/reset-password" exact component={ResetPasswordPage} />
            <Route path="/homepage" exact render={() => (isConnected ? <RedirectToVisitorsHomepage route="/select_profiles" /> : <HomePage />)} />

            {/* Connected users pages outside IonRouterOutlet */}
            <Route
              path="/notification_redirection/:id"
              exact
              component={isConnected ? (props: RouteComponentProps) => <NotificationRedirectionPage notificationId={extractIdFromRoute(props, '/notifications/')} /> : RedirectToHomepage}
            />
            <Route path="/me/pictures" exact component={isConnected ? CurrentUserPicturesPage : RedirectToHomepage} />
            <Route path="/me/edit/pictures" exact component={isConnected ? CurrentUserPicturesPage : RedirectToHomepage} />
            <Route path="/me/password" exact component={isConnected ? EditPasswordPage : RedirectToHomepage} />
            <Route path="/me/edit" exact render={isConnected ? () => <EditProfilePage /> : () => <RedirectToHomepage />} />
            {/* This route allows to display all posts requests related to the current user. This page cannot be accessed without knowing the route */}
            <Route path="/me/post_requests" exact component={isConnected ? PostRequestPage : RedirectToHomepage} />

            <IonRouterOutlet animated={false} dir={i18n.dir()}>
              <Route path="/search" exact render={() => <SearchPage lastRefreshDate={this.state.searchPageLastRefresh} />} />
              <Route path="/posts" exact render={() => <PostsPage lastRefreshDate={this.state.postPageLastRefresh} />} />
              <Route path="/map" exact component={MapPage} />

              <Route path="/me" exact component={isConnected ? CurrentUserProfilePage : RedirectToHomepage} />
              <Route path="/select_profiles" exact component={isConnected ? SelectProfilePage : RedirectToHomepage} />
              <Route path="/register_organization" exact component={isConnected ? RegisterOrganizationPage : RedirectToHomepage} />
              <Route path="/login_organization" exact component={isConnected ? LoginOrganization : RedirectToHomepage} />

              <Route path="/notifications/global/create" exact component={isConnected ? CreateGlobalNotificationPage : RedirectToHomepage} />
              <Route path="/users/create/new" exact component={isConnected ? RegisterNewUserPage : RedirectToHomepage} />
              <Route path="/notifications/global/preview" exact component={isConnected ? GlobalNotificationPreviewPage : RedirectToHomepage} />
              <Route
                path="/global_notification/:id"
                exact
                component={isConnected ? (props: RouteComponentProps) => <NotificationPage notificationId={extractIdFromRoute(props, '/notifications/')} /> : RedirectToHomepage}
              />
              <Route
                path="/display_notification/:id"
                exact
                component={isConnected ? (props: RouteComponentProps) => <NotificationPage notificationId={extractIdFromRoute(props, '/notifications/')} /> : RedirectToHomepage}
              />
              <Route path="/notifications" exact component={isConnected ? NotificationsPage : RedirectToHomepage} />

              <Route path="/me/account/linked" exact component={isConnected ? LinkedAccountsPage : RedirectToHomepage} />
              <Route path="/me/account/delete" exact component={isConnected ? DeleteAccountPage : RedirectToHomepage} />
              <Route path="/me/notifications/settings/:type" exact render={isConnected ? () => <NotificationSettingsPage /> : () => <RedirectToHomepage />} />
              <Route path="/me/settings/newsletter" exact render={isConnected ? () => <NewsletterSettingsPage /> : () => <RedirectToHomepage />} />
              <Route path="/me/privacy/blocked_accounts" exact component={isConnected ? BlockedAccountsPage : RedirectToHomepage} />
              {/* The privacy page is actually temporarily deactivated and unreachable for users, but we keep the route */}
              <Route path="/me/privacy/settings" exact component={isConnected ? PrivacySettingsPage : RedirectToHomepage} />
              <Route path="/me/:routeMenu" exact render={isConnected ? () => <ProfileMenuItemComponentPage currentUserIsManaged={this.props.currentUserIsManaged} /> : () => <RedirectToHomepage />} />

              <Route path="/chats" exact render={isConnected ? () => <ChatsPage /> : () => <RedirectToHomepage />} />
              <Route path="/chats/:id" exact render={isConnected ? (props: RouteComponentProps) => <ChatPage chatId={extractIdFromRoute(props, '/chats/')} /> : () => <RedirectToHomepage />} />
              <Route
                path="/posts/create/:type"
                exact
                // @ts-expect-error je comprends pas pourquoi
                render={isConnected ? (props: RouteComponentProps) => <CreatePostPage type={(props.match?.params as PostTypableRouteParams)?.type} /> : () => <RedirectToHomepage />}
              />
              <Route
                path="/chats/:id/participants"
                exact
                component={isConnected ? (props: RouteComponentProps) => <ManageChatParticipantsPage chatId={extractIdFromRoute(props, '/chats/')} /> : RedirectToHomepage}
              />

              <Route path="/users/:id" exact component={(props: RouteComponentProps) => <UserProfilePage userId={extractIdFromRoute(props, '/users/')} />} />
              <Route
                path="/users/:id/favorite_users"
                exact
                component={isConnected ? (props: RouteComponentProps) => <UserFollowsPage userId={extractIdFromRoute(props, '/users/')} /> : RedirectToHomepage}
              />
              <Route path="/users/:id/reviews" exact component={isConnected ? (props: RouteComponentProps) => <UserReviewsPage userId={extractIdFromRoute(props, '/users/')} /> : RedirectToHomepage} />
              <Route
                path="/users/:id/pictures"
                exact
                component={isConnected ? (props: RouteComponentProps) => <UserPicturesPage userId={extractIdFromRoute(props, '/users/')} /> : RedirectToHomepage}
              />

              <Route path="/posts/:id" exact render={(props: RouteComponentProps) => <PostPage postId={extractIdFromRoute(props, '/posts/')} />} />
              <Route
                path="/posts/:id/edit"
                exact
                render={isConnected ? (props: RouteComponentProps) => <EditPostPage postId={extractIdFromRoute(props, '/posts/')} /> : () => <RedirectToHomepage />}
              />
              <Route path="/posts/chats/:id" exact component={isConnected ? (props: RouteComponentProps) => <CommentsPage chatId={extractIdFromRoute(props, '/chats/')} /> : RedirectToHomepage} />
              <Route path="/search/posts/:id" exact render={(props: RouteComponentProps) => <PostPage postId={extractIdFromRoute(props, '/posts/')} />} />
              <Route
                path="/search/posts/:id/edit"
                exact
                render={isConnected ? (props: RouteComponentProps) => <EditPostPage postId={extractIdFromRoute(props, '/posts/')} /> : () => <RedirectToHomepage />}
              />
              <Route
                path="/search/posts/chats/:id"
                exact
                render={isConnected ? (props: RouteComponentProps) => <CommentsPage chatId={extractIdFromRoute(props, '/chats/')} /> : () => <RedirectToHomepage />}
              />
              {MAIN_TABS.filter(tab => POSTS_TAB !== tab && SEARCH_TAB !== tab).map(tab => [
                <Route
                  path={`/${tab}/posts/:id`}
                  key={`/${tab}/posts/:id`}
                  exact
                  render={isConnected ? (props: RouteComponentProps) => <PostPage postId={extractIdFromRoute(props, '/posts/')} /> : () => <RedirectToHomepage />}
                />,
                <Route
                  path={`/${tab}/posts/:id/edit`}
                  key={`/${tab}/posts/:id/edit`}
                  exact
                  render={isConnected ? (props: RouteComponentProps) => <EditPostPage postId={extractIdFromRoute(props, '/posts/')} /> : () => <RedirectToHomepage />}
                />,
                <Route
                  path={`/${tab}/posts/chats/:id`}
                  key={`/${tab}/posts/chats/:id`}
                  exact
                  render={isConnected ? (props: RouteComponentProps) => <CommentsPage chatId={extractIdFromRoute(props, '/chats/')} /> : () => <RedirectToHomepage />}
                />,
              ])}
              {MAIN_TABS.map(tab => [
                <Route path={`/${tab}/users/:id`} key={`/${tab}/users/:id`} exact render={(props: RouteComponentProps) => <UserProfilePage userId={extractIdFromRoute(props, '/users/')} />} />,
                <Route
                  path={`/${tab}/users/:id/favorite_users`}
                  key={`/${tab}/users/:id/favorite_users`}
                  exact
                  render={isConnected ? (props: RouteComponentProps) => <UserFollowsPage userId={extractIdFromRoute(props, '/users/')} /> : () => <RedirectToHomepage />}
                />,
                <Route
                  path={`/${tab}/users/:id/reviews`}
                  key={`/${tab}/users/:id/reviews`}
                  exact
                  render={isConnected ? (props: RouteComponentProps) => <UserReviewsPage userId={extractIdFromRoute(props, '/users/')} /> : () => <RedirectToHomepage />}
                />,
                <Route
                  path={`/${tab}/users/:id/pictures`}
                  key={`/${tab}/users/:id/pictures`}
                  exact
                  render={isConnected ? (props: RouteComponentProps) => <UserPicturesPage userId={extractIdFromRoute(props, '/users/')} /> : () => <RedirectToHomepage />}
                />,
              ])}

              <Redirect from="/" exact to={defaultPage} />
              <Route render={() => <ErrorComponent error={404} />} />
            </IonRouterOutlet>
          </Switch>
        </div>

        {!isRouteWithoutFooter && upgradeLayoutStatus?.showAlert && !upgradeLayoutStatus?.showModal && (
          <UpgradeAppAlert setToastMessage={this.props.setToastMessage} onAlertClose={() => this.props.setUpgradeLayoutStatus({ ...upgradeLayoutStatus, showAlert: false })} />
        )}
        <UpgradeAppModal isOpen={!!upgradeLayoutStatus?.showModal} />

        {!isRouteWithoutFooter && <TabBar onTabChanged={this.handleTabChanged} hasCurrentUser={isFullyLogged} />}

        {this.state.indigoDown && this.props.ff.indigo_fermeture.data && <IndigoDownModal data={this.props.ff.indigo_fermeture.data} />}
        {this.state.indigoDevientDonnons && this.props.ff.indigo_devient_donnons.data && (
          <IndigoDevientDonnonsModal type="homepage" onClose={this.onIndigoDevientDonnonsClose} data={this.props.ff.indigo_devient_donnons.data} />
        )}
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RoutingMenu));
