import { Thematic } from '../store/thematics/types';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import React, { Component, ReactNode } from 'react';
import { IonButton, IonIcon, IonList, IonText } from '@ionic/react';
import { ThematicComponent } from './ThematicsList';
import AddUserThematicsModal from './connected/AddUserThematicsModal';

import './ThematicTagItems.scss';

export interface ThematicsTagsProps {
  thematics: Thematic[];
}

interface ThematicState {
  addThematicsModalIsOpen: boolean;
}

type ThematicsProps = ThematicsTagsProps & WithTranslation;

class ThematicTagItems extends Component<ThematicsProps, ThematicState> {
  public constructor(props: ThematicsProps) {
    super(props);

    this.state = {
      addThematicsModalIsOpen: false,
    };
  }

  render(): ReactNode {
    const thematics = this.props.thematics || [];

    return (
      <>
        <div className="thematics-content">
          <IonText className="followed-tags-text">
            <Trans i18nKey="user.tags-you-follow" />
          </IonText>
          <div className="followed-tags-container">
            <IonList mode="md" className={'ion-no-padding followed-tags-list' + (!thematics.length ? ' empty-list' : '')}>
              <IonButton key="add-button" shape="round" fill="clear" className="plus-button" data-cy="plus-button" onClick={() => this.toggleAddThematicsModal(true)}>
                <IonIcon icon="/assets/icon/plus.svg" color="primary" className="plus-icon" />
              </IonButton>
              {thematics.map(thematic => ThematicComponent({ thematic: thematic, isSelected: false, onClick: () => this.onThematicTagClick }))}
            </IonList>
          </div>
        </div>
        <AddUserThematicsModal isOpen={this.state.addThematicsModalIsOpen} closeModal={() => this.toggleAddThematicsModal(false)} isUserProfile={true} />
      </>
    );
  }

  private toggleAddThematicsModal = (value: boolean): void => {
    this.setState({ addThematicsModalIsOpen: value });
  };

  private onThematicTagClick = (): void => {
    // TODO: add the actions resulting of a tag click.
  };
}

export default withTranslation()(ThematicTagItems);
