import { RootState } from '../index';
import { getPreviousTabHistory } from './utils';

export function activeTabPreviousPathname(state: RootState): string | undefined {
  const tabHistory = state.navigation.tabHistory[state.navigation.activeTab ?? ''];

  if (!tabHistory) {
    return undefined;
  }

  const previousTabHistory = getPreviousTabHistory(tabHistory);
  if (previousTabHistory.length < 1) {
    return undefined;
  }

  return previousTabHistory[0].pathname;
}
