import { IonToast } from '@ionic/react';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { isTest } from '../environment';
import { actions, RootState } from '../store';

import './LayoutToast.scss';

interface ComponentProps {
  toastMessages: { [index: string]: string };
}

const mapStateToProps = (state: RootState): ComponentProps => ({
  toastMessages: state.layout.toastMessages,
});

const mapDispatchToProps = {
  unsetToastMessage: (index: string) => actions.layout.unsetToastMessageAction(index),
};

type Props = ComponentProps & typeof mapDispatchToProps & WithTranslation;

const LayoutToast: React.FunctionComponent<Props> = ({ toastMessages, unsetToastMessage, t }: Props) => {
  return (
    <>
      {Object.keys(toastMessages).map((index: string) => {
        return (
          <IonToast
            key={index}
            cssClass="layout-toast"
            isOpen
            onDidDismiss={() => unsetToastMessage(index)}
            message={t(toastMessages[index], { nsSeparator: false })} // We disable the namespace separator to keep the entirety of the sentences with a ":" inside
            duration={isTest ? 2000 : 4000}
            position="top"
            buttons={[
              {
                side: 'end',
                text: t('common.close'),
                role: 'cancel',
              },
            ]}
          />
        );
      })}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LayoutToast));
