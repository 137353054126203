import camelCase from 'lodash/camelCase';
import clone from 'lodash/clone';
import { denormalize as normalizrDenormalize, normalize, Schema } from 'normalizr';
import { extractId, isLoadingFresh } from '../../utils/helpers';
import { CollectionResponse, EntityReference, HydraEntity, HydratedCollection, ReferencedCollection, ReferencedCollectionResponse } from '../../utils/hydra';
import { initReferencedCollection } from '../../utils/redux';
import * as normalizrSchema from '../normalizrSchema';
import { ThunkResultDispatch } from '../types';
import { addEntities } from './actions';
import { EntitiesState, NormalizedEntities } from './types';

function getSchemaFromId(id: EntityReference): Schema {
  const idFirstPart = id.split('/').filter(s => !!s)[0] || '';
  const entitySchema = camelCase(idFirstPart.replace(/s$/, '')) as keyof typeof normalizrSchema;

  if (!entitySchema) {
    throw new Error('Invalid entity id');
  }

  if (typeof normalizrSchema[entitySchema] === 'undefined') {
    throw new Error('Invalid entity schema');
  }

  return normalizrSchema[entitySchema];
}

export function denormalizeEntity<T extends HydraEntity>(state: EntitiesState, id: EntityReference): T | undefined {
  try {
    return normalizrDenormalize(id, getSchemaFromId(id), state);
  } catch (e) {
    console.error(e, 'id: ', id);
    return undefined;
  }
}

export function denormalizeEntities<T extends HydraEntity>(state: EntitiesState, ids: EntityReference[]): T[] {
  return (ids.map(id => denormalizeEntity<T>(state, id)).filter(item => typeof item !== undefined) as T[]) || [];
}

export function denormalizeEntityCollection<T extends HydraEntity>(state: EntitiesState, collection: ReferencedCollection | undefined): HydratedCollection<T> {
  if (typeof collection === 'undefined') {
    collection = initReferencedCollection();
  }

  const hydratedColl = clone(collection) as HydratedCollection<T>;
  hydratedColl.items = denormalizeEntities<T>(state, collection.ids) as T[];

  if (hydratedColl.isLoading && hydratedColl.lastFetchStartDate) {
    // Caution: this value overrides the store's one
    // We need this line in order to change the loading state if the user closed the app before it gets the server response
    hydratedColl.isLoading = isLoadingFresh(hydratedColl);
  }

  return hydratedColl;
}

export const normalizeAndDispatchEntity = <T extends HydraEntity>(item: T, dispatch: ThunkResultDispatch): EntityReference => {
  const normalized = normalize<HydraEntity, NormalizedEntities>(item, getSchemaFromId(extractId(item)));
  dispatch(addEntities(normalized.entities));

  return normalized.result;
};

export const normalizeAndDispatchEntities = <T extends HydraEntity>(items: T[], dispatch: ThunkResultDispatch): EntityReference[] => {
  if (!items.length) {
    return [];
  }

  const normalized = normalize<HydraEntity, NormalizedEntities>(items, [getSchemaFromId(extractId(items[0]))]);
  dispatch(addEntities(normalized.entities));

  return normalized.result;
};

export const normalizeAndDispatchCollectionResponse = <T extends HydraEntity>(data: CollectionResponse<T>, dispatch: ThunkResultDispatch): ReferencedCollectionResponse => {
  const result = normalizeAndDispatchEntities<T>(data.items, dispatch);

  return {
    ids: result,
    totalItems: data.totalItems,
    isFirstPage: typeof data.previousPage === 'undefined',
    nextPage: data.nextPage,
  };
};
