import { ActionType, getType } from 'typesafe-actions';
import { createReferencedCollection, initReferencedCollection, mergeReferencedCollections, updateItemWithCollectionLoading } from '../../utils/redux';
import { EntitiesState } from '../entities/types';
import { RootActions } from '../index';
import * as chatsActions from './actions';
import { Chat, ChatState } from './types';

const defaultState: ChatState = {
  collections: {
    currentUserChats: createReferencedCollection(),
    currentUserCompletedChats: createReferencedCollection(),
  },
  userChatCollections: {},
  itemIsLoading: false,
};

export const chatsEntitiesReducer = (state: EntitiesState, action: ActionType<RootActions>): EntitiesState => {
  // This reducer is called by the entities one
  switch (action.type) {
    case getType(chatsActions.fetchChatsMessagesAction.request):
      return { ...state, chats: updateItemWithCollectionLoading<Chat>(state.chats, action.payload, 'messages', true) };
    case getType(chatsActions.fetchChatsMessagesAction.success): {
      const chatItem = {
        ...state.chats[action.payload.chatId],
        messages: mergeReferencedCollections(action.payload.collection, state.chats[action.payload.chatId]?.messages),
        newMessages: action.payload.collection.isFirstPage ? [] : state.chats[action.payload.chatId]?.newMessages,
      };
      return { ...state, chats: { ...state.chats, [action.payload.chatId]: chatItem } };
    }
    case getType(chatsActions.createChatMessageAction.success): {
      const chatItem = {
        ...state.chats[action.payload.chatId],
        newMessages: (state.chats[action.payload.chatId]?.newMessages || []).concat(action.payload.messageId),
      };
      return { ...state, chats: { ...state.chats, [action.payload.chatId]: chatItem } };
    }
    case getType(chatsActions.fetchChatsMessagesAction.failure):
      return { ...state, chats: updateItemWithCollectionLoading<Chat>(state.chats, action.payload, 'messages', false) };
    default:
      return state;
  }
};

export default (state = defaultState, action: ActionType<typeof chatsActions>): ChatState => {
  switch (action.type) {
    case getType(chatsActions.resetUserChatCollection):
      return { ...state, collections: { ...state.collections, [action.payload]: initReferencedCollection(undefined, true) } };
    case getType(chatsActions.fetchUserChatsByChatAction.request):
      return { ...state, userChatCollections: { ...state?.userChatCollections, [action.payload]: initReferencedCollection(state?.userChatCollections?.[action.payload], true) } };
    case getType(chatsActions.fetchCurrentUserChatsAction.request):
      return { ...state, collections: { ...state.collections, [action.payload]: initReferencedCollection(state.collections[action.payload], true) } };
    case getType(chatsActions.fetchUserChatsByChatAction.success):
      return {
        ...state,
        userChatCollections: {
          ...state.userChatCollections,
          [action.payload.collectionName]: mergeReferencedCollections(action.payload.collection, state.userChatCollections[action.payload.collectionName]),
        },
      };
    case getType(chatsActions.fetchCurrentUserChatsAction.success):
      return {
        ...state,
        collections: { ...state.collections, [action.payload.collectionName]: mergeReferencedCollections(action.payload.collection, state.collections[action.payload.collectionName]) },
      };
    case getType(chatsActions.fetchUserChatsByChatAction.failure):
      return {
        ...state,
        userChatCollections: { ...state.userChatCollections, [action.payload.collectionName]: initReferencedCollection(state.userChatCollections[action.payload.collectionName], false) },
      };
    case getType(chatsActions.fetchCurrentUserChatsAction.failure):
      return { ...state, collections: { ...state.collections, [action.payload.collectionName]: initReferencedCollection(state.collections[action.payload.collectionName], false) } };

    case getType(chatsActions.createDirectChatAction.request):
    case getType(chatsActions.createChatMessageAction.request):
    case getType(chatsActions.fetchCurrentUserChatAction.request):
      return { ...state, itemIsLoading: true };
    case getType(chatsActions.createDirectChatAction.failure):
    case getType(chatsActions.createDirectChatAction.success):
    case getType(chatsActions.createChatMessageAction.failure):
    case getType(chatsActions.createChatMessageAction.success):
    case getType(chatsActions.fetchCurrentUserChatAction.success):
    case getType(chatsActions.fetchCurrentUserChatAction.failure):
      return { ...state, itemIsLoading: false };

    default:
      return state;
  }
};
