import { IonCol, IonContent, IonRow, IonThumbnail, IonPage, withIonLifeCycle } from '@ionic/react';
import React, { PureComponent, ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import GalleryModal from '../components/GalleryModal';
import { RootState, selectors } from '../store';
import { User } from '../store/users/types';
import { EntityReference } from '../utils/hydra';
import { getItemSrc } from '../utils/helpers';
import { Media } from '../store/app/types';
import CommonHeader from '../components/CommonHeader';
import ImageFadeIn from '../components/ImageFadeIn';

type State = {
  user?: User;
  isOpen: boolean;
  slideIndex: number;
};

type StateProps = {
  getUser: (id: EntityReference) => User | undefined;
};

const mapStateToProps = (state: RootState): StateProps => ({
  getUser: (id: EntityReference) => selectors.entities.denormalizeEntity(state.entities, id),
});

interface PageProps {
  userId: EntityReference | null;
}

type UserProfileProps = PageProps & RouteComponentProps<Record<string, never>> & StateProps & WithTranslation;

class UserPicturesPage extends PureComponent<UserProfileProps, State> {
  public constructor(props: UserProfileProps) {
    super(props);
    this.state = {
      isOpen: false,
      slideIndex: 0,
    };

    this.closeModal = this.closeModal.bind(this);
  }

  private updateSlideIndex = (currentMediaIndex: number): void => {
    this.setState({ slideIndex: currentMediaIndex });
  };

  public render(): ReactNode {
    const { userId, getUser } = this.props;
    const { isOpen } = this.state;

    if (!userId) {
      return <Redirect to="/" />;
    }

    const user = getUser(userId);
    if (!user) {
      return <Redirect to="/" />;
    }

    return (
      <IonPage data-cy="user-pictures-page">
        <CommonHeader addIonHeader={true} title={this.props.t('user.profile-photos')} />
        <IonContent className="user-pictures-page">
          <IonRow responsive-sm>
            <IonCol className="preview-wrap">
              {user.images?.map((item: Media | File, key: number) => {
                return (
                  <IonThumbnail
                    className="preview"
                    key={key}
                    onClick={() => {
                      this.setState({ isOpen: true, slideIndex: key });
                    }}
                    data-cy="user-images-preview"
                  >
                    <ImageFadeIn thumbnail alt="preview" className="cursor-pointer" src={getItemSrc(item)} />
                  </IonThumbnail>
                );
              })}
            </IonCol>
          </IonRow>

          <GalleryModal updateSlideIndex={this.updateSlideIndex} isOpen={isOpen} closeModal={this.closeModal} images={user.images} initialSlide={this.state.slideIndex} />
        </IonContent>
      </IonPage>
    );
  }

  private closeModal(): void {
    this.setState({ isOpen: false });
  }
}

export default connect(mapStateToProps)(withTranslation()(withIonLifeCycle(UserPicturesPage)));
