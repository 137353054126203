import { pickBy } from 'lodash';
import isEqual from 'lodash/isEqual';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { extractId, isSameHydraEntity } from '../../utils/helpers';
import { EntityReference, HydraEntity, HydratedCollection, ItemsCollection, ReferencedCollection } from '../../utils/hydra';
import { isUserBlocked } from '../blockedUsers/selectors';
import { denormalizeEntity, denormalizeEntityCollection } from '../entities/selectors';
import { EntitiesState } from '../entities/types';
import { RootState } from '../index';
import { FullPost, Post, SavedSearch, SearchQueryParameters } from './types';
import { getCurrentUserId, getEntities } from '../app/selectors';

export function isAPost(item: HydraEntity): item is FullPost {
  return item['@type'] === 'Post';
}

export function isFullPost(item: Post): item is FullPost {
  return typeof (item as FullPost)?.description !== 'undefined';
}

export function isNotBlockedUserPost(state: RootState, item: Post): boolean {
  if (!item) {
    return false;
  }
  return !isUserBlocked(state, extractId(item.createdBy), 'currentUserBlockedCollection') && !isUserBlocked(state, extractId(item.createdBy), 'currentUserIsBlockedCollection');
}

export function getHydratedFullPostCollection(state: RootState, coll: ReferencedCollection | undefined): HydratedCollection<FullPost> {
  const hydratedColl: HydratedCollection<FullPost> = denormalizeEntityCollection<FullPost>(state.entities, coll);
  hydratedColl.items = hydratedColl.items.filter(item => isNotBlockedUserPost(state, item)).filter(isFullPost) as FullPost[];

  return hydratedColl;
}

export type OfferAndNeedCollections = {
  offer: ItemsCollection<FullPost>;
  need: ItemsCollection<FullPost>;
};

export type UserOfferAndNeedCollections = {
  offer?: ReferencedCollection;
  need?: ReferencedCollection;
};

export type UserOfferAndNeedHydratedCollections = {
  offer: HydratedCollection<FullPost>;
  need: HydratedCollection<FullPost>;
};

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const getBlockedUsersIds = (state: RootState): EntityReference[] => state.blockedUsers.collections.currentUserBlockedCollection.ids;
const getBlockedByUsersIds = (state: RootState): EntityReference[] => state.blockedUsers.collections.currentUserIsBlockedCollection.ids;
export const getAllBlockedUsersIds = createSelector(getBlockedUsersIds, getBlockedByUsersIds, (blockedIds: EntityReference[], blockedByIds: EntityReference[]) => [...blockedIds, ...blockedByIds]);

const filterByNotAuthor =
  (currentUserId: EntityReference) =>
  (post: FullPost): boolean =>
    !isSameHydraEntity(currentUserId, post.createdBy);
const filterByNotBlockedUser =
  (blockedUsersIds: EntityReference[]) =>
  (post: Post): boolean =>
    !blockedUsersIds.find(id => isSameHydraEntity(id, post.createdBy));

const getSearchParamsFromState = (state: RootState): SearchQueryParameters => state.posts.searchParams;
const getOfferCollection = (state: RootState): ItemsCollection<FullPost> => state.posts.collections.offer;
const getNeedCollection = (state: RootState): ItemsCollection<FullPost> => state.posts.collections.need;
const getSearchCollection = (state: RootState): ItemsCollection<FullPost> => state.posts.collections.search;
const getRecommendationCollection = (state: RootState): ItemsCollection<FullPost> => state.posts.collections.recommendation;
const getThematicCollectionPerName = (state: RootState, name: string): ItemsCollection<FullPost> | undefined => state.posts.collections[name];

const getUserNeedAndOfferCollection = (state: RootState, userId: EntityReference | undefined): UserOfferAndNeedCollections => {
  return {
    offer: userId ? state.entities.users?.[userId].collections?.offer : undefined,
    need: userId ? state.entities.users?.[userId].collections?.need : undefined,
  };
};

const filterCollectionByBlockedUsers = (coll: ItemsCollection<FullPost>, blockedUsersIds: EntityReference[]): ItemsCollection<FullPost> => {
  const newColl = Object.assign({}, coll); // We must clone this object in order to avoid returning the same one updated
  newColl.items = pickBy(coll.items, filterByNotBlockedUser(blockedUsersIds));

  const itemsCount = Object.keys(coll.items).length;
  const newItemsCount = Object.keys(newColl.items).length;
  if (itemsCount === newItemsCount) {
    return coll;
  }

  newColl.totalItems -= itemsCount - newItemsCount;
  return newColl;
};

const filterCollectionByCurrentUserAndBlockedUsers = (coll: ItemsCollection<FullPost>, currentUserId: EntityReference, blockedUsersIds: EntityReference[]): ItemsCollection<FullPost> => {
  const newColl = Object.assign({}, coll); // We must clone this object in order to avoid returning the same one updated
  newColl.items = pickBy(coll.items, filterByNotAuthor(currentUserId));
  newColl.items = pickBy(newColl.items, filterByNotBlockedUser(blockedUsersIds));

  const itemsCount = Object.keys(coll.items).length;
  const newItemsCount = Object.keys(newColl.items).length;
  if (itemsCount === newItemsCount) {
    return coll;
  }

  newColl.totalItems -= itemsCount - newItemsCount;
  return newColl;
};

const getFilteredOfferCollection = createSelector([getOfferCollection, getAllBlockedUsersIds], filterCollectionByBlockedUsers);
const getFilteredNeedCollection = createSelector([getNeedCollection, getAllBlockedUsersIds], filterCollectionByBlockedUsers);

export const getOfferAndNeedCollections = createSelector([getFilteredOfferCollection, getFilteredNeedCollection], (offer: ItemsCollection<FullPost>, need: ItemsCollection<FullPost>) => ({
  offer,
  need,
}));

export const getUserHydratedOfferAndNeedCollections = createDeepEqualSelector([getUserNeedAndOfferCollection, getEntities], (colls: UserOfferAndNeedCollections, entities: EntitiesState) => {
  return {
    offer: denormalizeEntityCollection<FullPost>(entities, colls?.offer),
    need: denormalizeEntityCollection<FullPost>(entities, colls?.need),
  };
});

const getPostIdFromProps = (state: RootState, props: { postId: EntityReference | null }): EntityReference | null => props.postId;

export const getSearchParams = createDeepEqualSelector(getSearchParamsFromState, (searchParams: SearchQueryParameters) => searchParams);
export const getPostByPostId = createDeepEqualSelector([getEntities, getPostIdFromProps], (entities: EntitiesState, postId: EntityReference | null): Post | undefined => {
  if (!postId) {
    return undefined;
  }

  return denormalizeEntity<Post>(entities, postId);
});

export const getSearchResultCollection = createSelector([getSearchCollection, getAllBlockedUsersIds], filterCollectionByBlockedUsers);
export const getRecommendationsCollection = createSelector([getRecommendationCollection, getCurrentUserId, getAllBlockedUsersIds], filterCollectionByCurrentUserAndBlockedUsers);

export const getThematicCollection = createSelector(
  [getThematicCollectionPerName, getCurrentUserId, getAllBlockedUsersIds],
  (coll: ItemsCollection<FullPost> | undefined, currentUserId: EntityReference, blockedUsersIds: EntityReference[]) => {
    if (!coll) {
      return undefined;
    }

    return filterCollectionByCurrentUserAndBlockedUsers(coll, currentUserId, blockedUsersIds);
  },
);

const getSavedSearchesFromState = (state: RootState): SavedSearch[] | undefined => state.posts.savedSearchList;
const getSavedSearchListIsLoadingFromState = (state: RootState): boolean => state.posts.savedSearchListIsLoading;

export const getSavedSearchList = createSelector(getSavedSearchesFromState, (SavedSearchList: SavedSearch[] | undefined) => SavedSearchList);
export const getSavedSearchListIsLoading = createSelector(getSavedSearchListIsLoadingFromState, (isLoading: boolean) => isLoading);
