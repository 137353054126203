import React from 'react';
import { IonModal, IonContent } from '@ionic/react';

import './ShareModal.scss';
import ShareButtons from './ShareButtons';

interface shareModalProps {
  isOpen: boolean;
  closeModal: () => void;
  shareUrl: string;
  shareTitle: string;
  shareMessage: string;
}

export const ShareModal: React.FunctionComponent<shareModalProps> = ({ isOpen, closeModal, shareUrl, shareMessage, shareTitle }: shareModalProps) => {
  return (
    <IonModal isOpen={isOpen} onDidDismiss={closeModal} data-cy="share-modal" className="share-modal safe-area-ios--no-padding-top">
      <IonContent>
        <ShareButtons shareUrl={shareUrl} shareTitle={shareTitle} shareMessage={shareMessage} shareComponentTitle="search.share-this-search" />
      </IonContent>
    </IonModal>
  );
};

export default ShareModal;
