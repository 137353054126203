import { IonButton, IonContent, IonText, IonIcon, IonLabel, IonCheckbox, IonItem, IonPage } from '@ionic/react';
import { FormikHelpers, FormikProps } from 'formik';
import i18next from 'i18next';
import isEqual from 'lodash/isEqual';
import React, { Component, MouseEventHandler, ReactNode } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { FormValues } from '../components/DynamicForm';
import UserForm, { UserFormValues } from '../components/UserForm';
import { actions, RootState } from '../store';
import { JWTToken, Media } from '../store/app/types';
import { EntityReference } from '../utils/hydra';
import ThematicsSubmitButton from '../components/ThematicSubmitButton';
import ThematicsList from '../components/ThematicsList';
import { CurrentUser, organizationTypePublicSector, organizationTypePublicStructure, organizationTypeReuseOrganization, User } from '../store/users/types';
import { extractId, isEqualIgnoringFunctions } from '../utils/helpers';
import { CheckboxChangeEventDetail } from '@ionic/core';
import { defaultManagedData } from '../store/users/reducer';
import intersection from 'lodash/intersection';
import RegisterOrganizationSlider from '../components/RegisterOrganizationSlider';
import { routePrivacy, routeTos } from '../utils/helpers';
import { getAppLanguage } from '../utils/translation';
import CommonHeader from '../components/CommonHeader';
import { locationIsRegisterOrganizationPage } from '../utils/windowLocationHelper';

import './RegisterOrganizationPage.scss';

const getLocationHeader: React.FunctionComponent<MouseEventHandler> = onClose => (
  <CommonHeader
    addIonHeader={true}
    backButton={
      <IonButton onClick={onClose} color="dark">
        <IonIcon icon="/assets/navigation/chevron-back.svg" />
      </IonButton>
    }
    title={i18next.t('organization.create-your-page')}
  />
);

interface DispatchProps {
  registerManagedUser(user: UserFormValues): Promise<CurrentUser>;
  setRegistrationOrganizationStepAction(registerOrganizationStep: string): void;
  authenticateAsManagedUser(user: EntityReference): Promise<JWTToken>;
  fetchThematics(): Promise<void>;
  updateUser(user: UserFormValues): Promise<CurrentUser>;
  updateCurrentUserThematics(thematics: EntityReference[]): void;
  fetchManagedUsers(): Promise<User[]>;
}

const propsToDispatch = {
  registerManagedUser: actions.app.registerManagedUser,
  setRegistrationOrganizationStepAction: actions.app.setRegistrationOrganizationStepAction,
  authenticateAsManagedUser: actions.app.authenticateAsManagedUser,
  fetchThematics: actions.thematicsActions.fetchThematics,
  updateUser: actions.app.updateUser,
  updateCurrentUserThematics: actions.app.updateCurrentUserThematics,
  fetchManagedUsers: actions.whoAmI.fetchManagedUsers,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

interface StateAppProps {
  currentUser: CurrentUser;
  thematicSelectNumber: number;
  registerOrganizationStep: string;
}

const mapStateToProps = (state: RootState): StateAppProps => ({
  currentUser: state.app.currentUser,
  thematicSelectNumber: 3,
  registerOrganizationStep: state.app.registerOrganizationStep,
});

export type RegisterOrganizationProps = DispatchProps & StateAppProps & WithTranslation & RouteComponentProps<Record<string, never>>;

type RegisterOrganizationFieldsType = keyof UserFormValues;

const stepsFields: Record<string, { title: string; description: string; fields: Partial<RegisterOrganizationFieldsType>[] }> = {
  cguStep: { title: 'organization.get-started-title', description: 'organization.get-started-description', fields: [] },
  categoryStep: { title: 'organization.category-title', description: 'organization.category-description', fields: ['type', 'activity'] },
  nameStep: { title: 'organization.name-title', description: 'organization.name-description', fields: ['name'] },
  locationStep: { title: 'user.which-is-your-address', description: '', fields: ['address', 'addressLocation'] },
  websiteStep: { title: 'organization.website-title', description: 'organization.website-description', fields: ['website'] },
  contactDataStep: { title: 'organization.contact-title', description: 'organization.contact-description', fields: ['firstname', 'lastname', 'email', 'phone'] },
  thematicStep: { title: 'thematics.what-is-your-interest', description: '', fields: [] },
  avatarStep: { title: 'organization.avatar-title', description: 'organization.avatar-description', fields: ['avatar'] },
};

type PageStep = keyof typeof stepsFields;

interface State {
  initialValues: UserFormValues;
  userValues: Partial<UserFormValues>;
  requestIsPending: boolean;
  favoriteCategoryThematicsIds: EntityReference[];
  isLegalOrganization: boolean;
  pageNoWebsite: boolean;
}

function getInitialUserValues(): UserFormValues {
  return {
    locale: i18next.language.substring(0, 2) || 'en',
    avatar: null,
    address: undefined,
    type: undefined,
    activity: '',
    website: '',
    contact: undefined,
  };
}

const organizationWithPendingRegistrationTypes = [organizationTypePublicSector, organizationTypePublicStructure, organizationTypeReuseOrganization];

const initialState = (): State => {
  return {
    initialValues: getInitialUserValues(),
    userValues: getInitialUserValues(),
    requestIsPending: false,
    favoriteCategoryThematicsIds: [],
    isLegalOrganization: false,
    pageNoWebsite: false,
  };
};

class RegisterOrganizationPage extends Component<RegisterOrganizationProps, State> {
  public constructor(props: RegisterOrganizationProps) {
    super(props);
    this.state = initialState();
  }

  public shouldComponentUpdate(nextProps: Readonly<RegisterOrganizationProps>, nextState: Readonly<State>): boolean {
    if (locationIsRegisterOrganizationPage()) {
      return !isEqualIgnoringFunctions(nextProps, this.props) || !isEqual(nextState, this.state);
    }
    return false;
  }

  public componentDidMount(): void {
    // If the user refreshes the form after the creation of his account, but before the end of the registration, we let him continue
    // It also prevents errors on android when allowing the app to use camera for the first time
    if (!['avatarStep', 'thematicStep'].includes(this.props.registerOrganizationStep)) {
      this.props.setRegistrationOrganizationStepAction('cguStep');
    }
  }

  private static isPendingRegistrationType(type: string): boolean {
    return organizationWithPendingRegistrationTypes.includes(type);
  }

  private hideBackButton(step: PageStep): boolean {
    return step === 'thematicStep' || step === 'avatarStep';
  }

  private static getFields(step: PageStep): RegisterOrganizationFieldsType[] {
    return stepsFields[step].fields;
  }

  public render(): ReactNode {
    return (
      <IonPage className="register-organization-page full-screen-page" data-cy="register-organization-page">
        <CommonHeader
          addIonHeader={true}
          backButton={this.hideBackButton(this.props.registerOrganizationStep)}
          stopPropagation
          onBackButtonClick={() => this.goToPreviousStep(this.props.registerOrganizationStep)}
          title={i18next.t('organization.create-your-page')}
          nextButton={
            this.props.registerOrganizationStep !== 'thematicStep' ? (
              <></>
            ) : (
              <ThematicsSubmitButton
                thematicSelectNumber={this.props.thematicSelectNumber}
                onButtonClick={() => this.afterThematicSelection()}
                favoriteCategoryThematics={this.state.favoriteCategoryThematicsIds}
                title={'common.next'}
              />
            )
          }
        />

        <IonContent className="register-organization-content">
          {this.props.registerOrganizationStep === 'cguStep' ? (
            <RegisterOrganizationSlider />
          ) : (
            <>
              <h1 className="step-title">{this.getPageTitle(this.props.registerOrganizationStep)}</h1>
              <IonText color="medium" className="text-description">
                {this.getPageDescription(this.props.registerOrganizationStep)}
              </IonText>
            </>
          )}
          {this.registerOrganizationForm()}
        </IonContent>
      </IonPage>
    );
  }

  private registerOrganizationForm(): ReactNode {
    if (this.props.registerOrganizationStep === 'thematicStep') {
      return (
        <div className="thematic-step">
          <ThematicsList onThematicSelection={thematic => this.onThematicSelection(thematic)} favoriteCategoryThematicsIds={this.state.favoriteCategoryThematicsIds} />
        </div>
      );
    }

    if (!this.isFirstStep(this.props.registerOrganizationStep)) {
      return (
        <div className="register-form">
          <UserForm
            submitFormButton={this.submitRegisterComponent}
            onSubmitErrors={this.onSubmitErrors}
            onAfterSubmitFormSuccess={this.afterSubmitFormSuccess}
            initialValues={this.state.initialValues}
            fields={RegisterOrganizationPage.getFields(this.props.registerOrganizationStep)}
            postUserAction={this.submitForm}
            submitButtonText={this.props.t('common.next')}
            locationModalHeader={getLocationHeader}
            isOrganizationRegister={true}
          />
        </div>
      );
    }

    return (
      <div className="cgu-step">
        <div className="bottom-section">
          <IonText color="medium" className="cgu-links">
            <Trans i18nKey="user.cgu-label">
              <a key="terms" href={routeTos(getAppLanguage())} target="new">
                <Trans i18nKey="user.cgu-terms" />
              </a>
              <a key="privacy" href={routePrivacy()} target="new">
                <Trans i18nKey="user.cgu-privacy" />
              </a>
            </Trans>
          </IonText>

          <IonButton expand="block" size="large" className="create-account" shape="round" onClick={() => this.props.setRegistrationOrganizationStepAction('categoryStep')}>
            {this.props.t('organization.get-started-button')}
          </IonButton>
        </div>
      </div>
    );
  }

  private submitRegisterComponent: React.FunctionComponent<FormikProps<FormValues>> = ({
    validateForm,
    isValid,
    isSubmitting,
    isValidating,
    getFieldProps,
    setFieldValue,
  }: FormikProps<FormValues>) => {
    const isCategoryStep = this.props.registerOrganizationStep === 'categoryStep';
    const isWebsiteStep = this.props.registerOrganizationStep === 'websiteStep';

    let websiteValue;
    let checkValue = this.state.isLegalOrganization;
    let checkChangeFunction = (e: CustomEvent<CheckboxChangeEventDetail>): void => this.setState({ isLegalOrganization: (e.target as HTMLInputElement).checked }, validateForm);
    let checkboxText = i18next.t('organization.legal-organization-declaration');

    if (isWebsiteStep) {
      checkboxText = i18next.t('organization.no-website-declaration');
      websiteValue = getFieldProps('website').value;
      checkValue = this.state.pageNoWebsite && !websiteValue;
      checkChangeFunction = (e: CustomEvent<CheckboxChangeEventDetail>): void => {
        this.setState({ pageNoWebsite: (e.target as HTMLInputElement).checked }, () => (e.target as HTMLInputElement).checked && setFieldValue('website', undefined, true));
      };
    }

    const isButtonInvalid = (isCategoryStep && !this.state.isLegalOrganization) || (isWebsiteStep && !this.state.pageNoWebsite && !websiteValue);
    return (
      <div className="next-section">
        {(isCategoryStep || isWebsiteStep) && (
          <IonItem lines="none">
            <IonLabel color="dark" className="checkbox-label">
              {checkboxText}
            </IonLabel>
            <IonCheckbox data-cy="legal-ngo-checkbox" color="primary" onIonChange={checkChangeFunction} slot="start" checked={checkValue} />
          </IonItem>
        )}
        <IonButton
          type="submit"
          shape="round"
          className="ion-margin button-small next-button"
          hidden={!isValid || isButtonInvalid}
          disabled={!isValid || isButtonInvalid || isValidating || isSubmitting}
        >
          {i18next.t('common.next')}
        </IonButton>
      </div>
    );
  };

  private getSteps(): PageStep[] {
    return Object.keys(stepsFields);
  }

  private getNextStep(currentStep: PageStep): PageStep | null {
    const steps = this.getSteps();
    const i = steps.indexOf(currentStep);

    if (i >= steps.length - 1) {
      return null;
    }

    return steps[i + 1];
  }

  private getPreviousStep(currentStep: PageStep): PageStep | null {
    const steps = this.getSteps();
    const i = steps.indexOf(currentStep);

    if (i <= 0) {
      return null;
    }

    return steps[i - 1];
  }

  private isFirstStep(step: PageStep): boolean {
    return this.getPreviousStep(step) === null;
  }

  private goToNextStep = (step: PageStep): void => {
    const nextStep = this.getNextStep(step);

    if (!nextStep) {
      const directRegistration = this.state.userValues.type && !RegisterOrganizationPage.isPendingRegistrationType(this.state.userValues.type);
      this.props.history.push('/me', { pageMustBeCompleted: true, directRegistration });
      this.resetSubscription();
      return;
    }
    this.props.setRegistrationOrganizationStepAction(nextStep);
  };

  private resetSubscription = (): void => {
    this.setState(initialState());
    this.props.setRegistrationOrganizationStepAction(Object.keys(stepsFields)[0]);
  };

  private goToPreviousStep = (step: PageStep): void => {
    const previousStep = this.getPreviousStep(step);
    if (!previousStep) {
      this.props.history.push('/me');
      return;
    }
    this.props.setRegistrationOrganizationStepAction(previousStep);
  };

  private getPageTitle(step: PageStep): string {
    return this.props.t(stepsFields[step].title);
  }

  private getPageDescription(step: PageStep): string {
    return this.props.t(stepsFields[step].description);
  }

  private submitForm = async (values: UserFormValues): Promise<CurrentUser> => {
    if (this.state.requestIsPending) {
      return values as CurrentUser;
    }

    this.setState({ requestIsPending: true });
    this.updateUserValues(values);

    if (this.props.registerOrganizationStep === 'avatarStep') {
      return await this.props.updateUser({ ...values, '@id': this.props.currentUser['@id'], images: [values.avatar as Media] });
    }

    if (this.props.registerOrganizationStep === 'contactDataStep') {
      this.props.setRegistrationOrganizationStepAction('contactDataStep');
      const organization = this.state.userValues;
      organization['contact'] = { firstname: organization.firstname, lastname: organization.lastname, email: organization.email, phone: organization.phone };
      delete organization.email;
      delete organization.firstname;
      delete organization.lastname;
      delete organization.phone;
      await this.props.fetchThematics();

      const currentUser = await this.register(organization);
      const directRegistration = organization.type && !RegisterOrganizationPage.isPendingRegistrationType(organization.type);
      if (directRegistration) {
        return currentUser;
      }

      this.resetSubscription();
      this.props.history.push('/me', { pageMustBeCompleted: true, directRegistration });

      return currentUser;
    }

    return this.state.userValues as CurrentUser;
  };

  private onSubmitErrors = async (actions: FormikHelpers<FormValues>, errors: Record<string, string>): Promise<void> => {
    this.setState({ requestIsPending: false });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errorsState: any = {};
    if (!errors || !errors['_error']) {
      return;
    }

    stepsFields.contactDataStep.fields.forEach(value => {
      if (errors['managedData.contact.' + value]) {
        errorsState[value] = errors['managedData.contact.' + value];
        delete errors['managedData.contact.' + value];
      }
    });

    Object.keys(defaultManagedData).forEach(value => {
      if (errors['managedData.' + value]) {
        errorsState[value] = errors['managedData.' + value];
        delete errors['managedData.' + value];
      }
    });

    delete errors['_error'];

    actions.setStatus({ ...errors, ...errorsState });

    const errorKeys: string[] = Object.keys({ ...errors, ...errorsState });
    const pageStepsKeys = Object.keys(stepsFields);

    for (let stepIndex = 0; stepIndex < pageStepsKeys.length; stepIndex++) {
      if (intersection(stepsFields[pageStepsKeys[stepIndex]].fields, errorKeys).length > 0) {
        this.props.setRegistrationOrganizationStepAction(this.getSteps()[stepIndex]);
        return;
      }
    }
  };

  private register = async (user: UserFormValues): Promise<CurrentUser> => {
    const currentUser = await this.props.registerManagedUser(user);
    if (this.state.userValues.type && RegisterOrganizationPage.isPendingRegistrationType(this.state.userValues.type)) {
      return currentUser;
    }
    await this.props.authenticateAsManagedUser(extractId(currentUser));

    return currentUser;
  };

  private updateUserValues(values: UserFormValues): void {
    const setUserValue = <T extends UserFormValues, K extends keyof T>(user: T, key: K, value: T[K]): void => {
      user[key] = value;
    };

    let user: UserFormValues = { '@id': values['@id'] as string };

    if (this.state.userValues != null) {
      user = this.state.userValues;
    }

    RegisterOrganizationPage.getFields(this.props.registerOrganizationStep).forEach((field: keyof UserFormValues) => {
      setUserValue(user, field, values[field] || undefined);
    });

    this.setState({ userValues: user, initialValues: Object.assign({}, this.state.initialValues, values) });
  }

  private afterSubmitFormSuccess = async (values: UserFormValues, actions: FormikHelpers<FormValues>): Promise<void> => {
    const isCategoryStep = this.props.registerOrganizationStep === 'categoryStep';
    const isWebsiteStep = this.props.registerOrganizationStep === 'websiteStep';
    // if registerOrganizationStep is categoryStep and isLegalOrganization is not accepted
    // or registerOrganizationStep is websiteStep and pageNoWebsite is not accepted and website unfilled
    // form is not valid.
    const validForm = (isCategoryStep && !this.state.isLegalOrganization) || (isWebsiteStep && !this.state.pageNoWebsite && !values.website);

    await actions.setSubmitting(false);

    this.setState({ requestIsPending: false }, () => (!validForm ? this.goToNextStep(this.props.registerOrganizationStep) : null));
  };

  private onThematicSelection(thematicsIds: EntityReference[]): void {
    this.setState({ favoriteCategoryThematicsIds: thematicsIds });
  }

  private afterThematicSelection(): void {
    this.props.updateCurrentUserThematics(this.state.favoriteCategoryThematicsIds);
    this.props.setRegistrationOrganizationStepAction(this.props.registerOrganizationStep);
    this.goToNextStep(this.props.registerOrganizationStep);
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(RegisterOrganizationPage)));
