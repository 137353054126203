import get from 'lodash/get';
import { ActionType, getType } from 'typesafe-actions';
import { createObjectByDefaultKeys } from '../../utils/helpers';
import { EntityReference, ReferencedCollection, ReferencedCollectionResponse } from '../../utils/hydra';
import { mergeReferencedCollections, updateItemValue, updateItemWithCollectionLoading } from '../../utils/redux';
import * as appActions from '../app/actions';
import { EntitiesState } from '../entities/types';
import { RootActions } from '../index';
import * as postsActions from '../posts/actions';
import * as usersActions from './actions';
import { CurrentUser, CurrentUserEditFields, CurrentUserFields, CurrentUserPersonalDataFields, ManagedData, User, UserState } from './types';

const defaultUser: User = {
  '@id': '',
  name: '',
  avatar: null,
  managed: false,
  managedData: {},
  images: [],
  aboutMe: '',
  aboutMeOnIndigo: '',
  createdAt: '',
};

export const defaultCurrentUserPersonalData: CurrentUserPersonalDataFields = {
  firstname: '',
  lastname: '',
  phone: '',
  gender: undefined,
  newsletterSubscribed: false,
  lastTosAcceptedDate: undefined,
  facebookId: null,
  appleId: null,
};

export const defaultManagedData: ManagedData = {
  type: undefined,
  activity: '',
  website: '',
  contact: undefined,
};

export const defaultCurrentUserCommonFields: CurrentUserFields = {
  ...defaultCurrentUserPersonalData,
  email: '',
  avatar: null,
  name: '',
  locale: 'en',
  images: [],
  favoriteCategoryThematics: [],
  aboutMe: '',
  aboutMeOnIndigo: '',
  status: {
    createdPosts: 0,
    createdOfferPosts: 0,
    createdNeedPosts: 0,
    offerPostNotFinished: 0,
    needPostNotFinished: 0,
    completedPostRequests: 0,
    favoriteUsers: 0,
    requestPostsByUser: 0,
    completedPostRequestsAsHelper: 0,
    unreadChats: 0,
    unreadNotifications: 0,
  },
  createdAt: '',
  allowPushNotifications: true,
  disabledPushNotifications: [],
  allowMailNotifications: true,
  disabledMailNotifications: [],
  address: {
    formatted: '',
    locality: '',
    country: '',
    postalCode: '',
  },
  addressLocation: {
    latitude: null,
    longitude: null,
  },
  managed: false,
  passwordMustBeChanged: false,
  preferences: {},
  managedData: { id: undefined, ...defaultManagedData },
  disabledPrivacySettings: [],
};

export const defaultCurrentUser: CurrentUser = {
  '@id': '',
  username: '',
  name: '',
  facebookId: null,
  ...defaultCurrentUserCommonFields,
};

export const defaultCurrentUserManaged: CurrentUser & { disabled: boolean } = {
  managed: true,
  managedData: defaultManagedData,
  disabled: true,
  ...defaultCurrentUser,
};

export const defaultEditableUser: CurrentUserEditFields = {
  '@id': '',
  ...defaultCurrentUserCommonFields,
  plainPassword: '',
  facebookAccessToken: '',
};

const defaultUserState: UserState = {
  isItemLoading: false,
  isFavoriteLoading: false,
};

export const createUserByDefaultKeys = (data: unknown): User => {
  return createObjectByDefaultKeys<User>(defaultUser, data);
};

export const createCurrentUserByDefaultKeys = (data: unknown, isManagedUser = false): CurrentUser => {
  const defaultData = isManagedUser ? defaultCurrentUserManaged : defaultCurrentUser;
  return createObjectByDefaultKeys<CurrentUser>(defaultData, data);
};

const updateFavoriteCollections = (fetchResponse: ReferencedCollectionResponse, favoriteCollection: ReferencedCollection | undefined, favoriteUserId: EntityReference): ReferencedCollection => {
  let ids = favoriteCollection?.ids ?? [];

  if (fetchResponse.ids.length <= 0) {
    ids = ids.filter(item => item !== favoriteUserId);
  } else {
    ids = [...ids, favoriteUserId];
  }

  return {
    ids,
    totalItems: ids.length,
    nextPage: favoriteCollection?.nextPage,
    lastFetchDate: new Date(),
    isLoading: false,
  };
};

export const usersEntitiesReducer = (state: EntitiesState, action: ActionType<RootActions>): EntitiesState => {
  // This reducer is called by the entities one
  switch (action.type) {
    case getType(postsActions.fetchUserPostsAction.request):
    case getType(usersActions.fetchFavoriteUsersAction.request):
    case getType(usersActions.fetchUserReviewsAction.request):
      return { ...state, users: updateItemWithCollectionLoading<User>(state.users, action.payload.userId, ['collections', action.payload.collectionName], true) };
    case getType(postsActions.fetchUserPostsAction.success):
    case getType(usersActions.fetchFavoriteUsersAction.success):
    case getType(usersActions.fetchUserReviewsAction.success):
      return {
        ...state,
        users: updateItemValue<User>(
          state.users,
          action.payload.userId,
          ['collections', action.payload.collectionName],
          mergeReferencedCollections(action.payload.collection, get(state.users, [action.payload.userId, 'collections', action.payload.collectionName])),
        ),
      };
    case getType(usersActions.fetchFavoritesByIdAction.success):
      return {
        ...state,
        users: updateItemValue<User>(
          state.users,
          action.payload.userId,
          ['collections', action.payload.collectionName],
          updateFavoriteCollections(action.payload.collection, get(state.users, [action.payload.userId, 'collections', action.payload.collectionName]), action.payload.favoriteUserId),
        ),
      };
    case getType(postsActions.fetchUserPostsAction.failure):
    case getType(usersActions.fetchUserReviewsAction.failure):
    case getType(usersActions.fetchFavoriteUsersAction.failure):
      return { ...state, users: updateItemWithCollectionLoading<User>(state.users, action.payload.userId, ['collections', action.payload.collectionName], false) };
    default:
      return state;
  }
};

export default (state = defaultUserState, action: ActionType<typeof usersActions | typeof appActions | typeof postsActions>): UserState => {
  switch (action.type) {
    case getType(usersActions.fetchUserAction.request):
      return { ...state, isItemLoading: true };
    case getType(usersActions.fetchUserAction.success):
    case getType(usersActions.fetchUserAction.failure):
      return { ...state, isItemLoading: false };
    case getType(usersActions.addFavoriteUserAction.request):
    case getType(usersActions.deleteFavoriteUserAction.request):
      return { ...state, isFavoriteLoading: true };
    case getType(usersActions.addFavoriteUserAction.failure):
    case getType(usersActions.deleteFavoriteUserAction.failure):
    case getType(usersActions.addFavoriteUserAction.success):
    case getType(usersActions.deleteFavoriteUserAction.success):
      return { ...state, isFavoriteLoading: false };
    default:
      return state;
  }
};
