import { loadGoogleMapsScript } from '../../utils/geolocationHelpers';
import * as layoutActions from './actions';
import * as postRequestsActions from '../postRequests/actions';
import * as chatsActions from '../chats/actions';
import { ActionType, getType } from 'typesafe-actions';
import { AnyAction, Middleware } from 'redux';
import { LayoutState } from './types';
import i18next from 'i18next';
import { actions } from '../index';
import { gmapsApiKey } from '../../environment';
import moment from 'moment';
import 'moment/min/locales';
import { ThunkResultDispatch } from '../types';

export const layoutMiddleware: Middleware<unknown, LayoutState> = store => next => (action: ActionType<typeof actions.layout | AnyAction>) => {
  typeof action === 'function' ? action(next) : next(action);

  const dispatch = store.dispatch as ThunkResultDispatch;

  if (action.type === getType(layoutActions.setAppLanguageAction)) {
    if (null === action.payload || i18next.language === action.payload) {
      return;
    }
    moment.locale(action.payload);
    i18next.changeLanguage(action.payload);
    loadGoogleMapsScript(action.payload, gmapsApiKey).catch(() => {
      // nothing
    });
  }

  if (
    action.type === getType(postRequestsActions.createPostRequestAction.success) ||
    action.type === getType(postRequestsActions.updatePostRequestStateAction.success) ||
    action.type === getType(chatsActions.createDirectChatAction.success) ||
    action.type === getType(chatsActions.createChatMessageAction.success)
  ) {
    dispatch(layoutActions.setLastActionUpdatingChatsListDate(new Date()));
  }
};
