import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import { IonContent, IonList, IonPage } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ThirdPartyLink from '../components/profileMenu/ThirdPartyLink';
import { actions, RootState } from '../store';
import { getCurrentUser } from '../store/app/selectors';
import { CurrentUser } from '../store/users/types';
import CommonHeader from '../components/CommonHeader';
import { getFacebookToken } from '../utils/facebookAccess';
import { isAppleConnectEnabled } from '../utils/appleConnectAccess';

interface State {
  facebookAccessToken?: string;
  appleAccessToken?: string;
}

interface StateProps {
  currentUser?: CurrentUser;
}

const mapStateToProps = (state: RootState): StateProps => ({
  currentUser: getCurrentUser(state),
});

interface DispatchProps {
  linkThirdPartyAccount(facebookId: string | null, thirdPartyName: string): Promise<void>;
  unlinkThirdPartyAccount(thirdPartyName: string): Promise<void>;
  setToastMessage(message: string | null): void;
}

const propsToDispatch = {
  linkThirdPartyAccount: actions.app.linkThirdPartyUser,
  unlinkThirdPartyAccount: actions.app.unlinkThirdPartyUser,
  setToastMessage: actions.layout.setToastMessageAction,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type LinkedAccountsProps = StateProps & DispatchProps & WithTranslation;

class LinkedAccountsPage extends Component<LinkedAccountsProps, State> {
  public constructor(props: LinkedAccountsProps) {
    super(props);
    this.state = {
      facebookAccessToken: undefined,
      appleAccessToken: undefined,
    };
  }

  private linkFacebookAccount = (): void => {
    getFacebookToken()
      .then(token => this.props.linkThirdPartyAccount(token, 'facebook'))
      .catch(this.handleError);
  };

  private unlinkFacebookAccount = (): void => {
    this.props.unlinkThirdPartyAccount('facebook');
  };

  private linkAppleAccount = (): void => {
    SignInWithApple.authorize()
      .then(data => {
        if (typeof data === 'undefined') {
          throw new Error('Unsupported platform for apple login');
        }
        if (typeof data.response?.identityToken === 'undefined') {
          throw new Error('Bad Apple sign in response');
        }
        return data;
      })
      .then(connectData => this.props.linkThirdPartyAccount(connectData.response.identityToken, 'apple'))
      .catch(this.handleError);
  };

  private unlinkAppleAccount = (): void => {
    this.props.unlinkThirdPartyAccount('apple');
  };

  private handleError = (err: string | Error): void => {
    if (!err) return;
    return this.props.setToastMessage(typeof err === 'string' ? err : err.message);
  };

  public render(): ReactNode {
    return (
      <IonPage data-cy="linked-account-page">
        <CommonHeader addIonHeader={true} title={<Trans i18nKey="profile-menu.linked-accounts" />} />
        <IonContent className="ion-padding">
          <IonList lines="none">
            <ThirdPartyLink
              currentUser={this.props.currentUser}
              thirdPartyLogo="/assets/profile-menu-icons/facebook.svg"
              thirdPartyName="Facebook"
              thirdPartyToken={this.props.currentUser?.facebookId}
              linkThirdPartyAccount={this.linkFacebookAccount}
              unlinkThirdPartyAccount={this.unlinkFacebookAccount}
            />
            {(isAppleConnectEnabled() || this.props.currentUser?.appleId) && (
              <ThirdPartyLink
                currentUser={this.props.currentUser}
                thirdPartyLogo="/assets/profile-menu-icons/apple.svg"
                thirdPartyName="Apple"
                thirdPartyToken={this.props.currentUser?.appleId}
                linkThirdPartyAccount={this.linkAppleAccount}
                unlinkThirdPartyAccount={this.unlinkAppleAccount}
              />
            )}
          </IonList>
        </IonContent>
      </IonPage>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LinkedAccountsPage));
