import React from 'react';
import { IonCol, IonRow, IonSkeletonText, IonAvatar, IonItem } from '@ionic/react';

import './UsersPlaceholder.scss';

export const UserItemPlaceholder: React.FunctionComponent = () => {
  return (
    <IonItem className="user-item-placeholder" lines="none" detail={false}>
      <IonAvatar className="avatar">
        <IonSkeletonText />
      </IonAvatar>
      <p className="name">
        <IonSkeletonText />
      </p>
    </IonItem>
  );
};

const ObjectPlaceholder: React.FunctionComponent = () => {
  return (
    <div className="posts-list-placeholder_object">
      <IonSkeletonText />
    </div>
  );
};

export const UsersPlaceholder: React.FunctionComponent = () => {
  return (
    <>
      <IonRow className="posts-list-placeholder">
        <IonCol size="6" className="posts-placeholder-col">
          <ObjectPlaceholder />
        </IonCol>
      </IonRow>
    </>
  );
};

export default UsersPlaceholder;
