import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import i18next from 'i18next';
import { IonButton, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonThumbnail } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { Link } from 'react-router-dom';
import AppleLogin from '../components/AppleLogin';
import FacebookLogin from '../components/FacebookLogin';
import ForgotPasswordModal from '../components/ForgotPasswordModal';
import { actions, RootState } from '../store';
import { JWTToken } from '../store/app/types';
import { ErrorMessage, Form, Formik } from 'formik';
import { defaultEmptyEmailDomain, ignoreResetButtonOnTab, isEqualIgnoringFunctions, onKeyboardEnterExecuteFunction } from '../utils/helpers';
import isEqual from 'lodash/isEqual';
import * as Yup from 'yup';
import { isMobileNavigator, isProduction, isTest } from '../environment';
import CommonHeader from '../components/CommonHeader';
import { UserFormValues } from '../components/UserForm';
import { CurrentUser } from '../store/users/types';
import { locationIsLoginPage } from '../utils/windowLocationHelper';
import { Capacitor } from '@capacitor/core';
import './LoginPage.scss';

interface State {
  initialValues: UserFormValues;
  email: string;
  password: string;
  isPasswordMasked: boolean;
  focusedField: string | null;
  isForgotPasswordModalOpen: boolean;
}

interface StateProps {
  token: string | null;
  isAuthenticationRequestLoading: boolean;
  isFullyLogged: boolean;
}

const mapStateToProps = (state: RootState): StateProps => ({
  token: state.app.token,
  isAuthenticationRequestLoading: state.app.isAuthenticationRequestLoading,
  isFullyLogged: state.app.isFullyLogged,
});

interface DispatchProps {
  register(user: UserFormValues): Promise<CurrentUser>;
  authenticate(username: string, password: string): Promise<JWTToken>;
  authenticateWithFacebook(token: string): Promise<JWTToken>;
  authenticateWithApple(token: string): Promise<JWTToken>;
  setIsFullyLoggedIn(isFullyLogged: boolean): void;
  setToastMessage(message: string | null): void;
}

const propsToDispatch = {
  register: actions.app.registerUser,
  authenticate: actions.app.authenticateWithCredentials,
  authenticateWithFacebook: actions.app.authenticateWithFacebook,
  authenticateWithApple: actions.app.authenticateWithApple,
  setIsFullyLoggedIn: actions.app.setIsFullyLoggedInAction,
  setToastMessage: actions.layout.setToastMessageAction,
};

function getInitialUserValues(): UserFormValues {
  return {
    email: '',
    firstname: '',
    lastname: '',
    locale: i18next.language.substring(0, 2) || 'en',
    plainPassword: '',
    confirmPassword: '',
    gender: undefined,
    newsletterSubscribed: true,
    lastTosAcceptedDate: undefined,
    avatar: null,
    appleAccessToken: '',
  };
}

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type Props = WithTranslation & StateProps & DispatchProps & RouteComponentProps<Record<string, never>>;

class LoginPage extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      initialValues: getInitialUserValues(),
      email: !isProduction || isTest ? 'user1@indigo.world' : '',
      password: !isProduction || isTest ? 'ppower' : '',
      isPasswordMasked: true,
      focusedField: null,
      isForgotPasswordModalOpen: false,
    };

    this.onPasswordToggle = this.onPasswordToggle.bind(this);
  }

  public componentDidMount(): void {
    // Quick hack for test env avoiding having to type both username & password in fields
    const testLoginUsername = localStorage.getItem('test-login-username');
    if (isTest && testLoginUsername) {
      console.info('Bypassing the user login page, username: ' + testLoginUsername);
      this.login(testLoginUsername, localStorage.getItem('test-login-password') || '').finally(() => {
        localStorage.removeItem('test-login-username');
        localStorage.removeItem('test-login-password');
      });
    }
  }

  public shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>): boolean {
    if (locationIsLoginPage()) {
      return !isEqualIgnoringFunctions(nextProps, this.props) || !isEqual(nextState, this.state);
    }
    return false;
  }

  public render(): ReactNode {
    // We autofocus the email field only if it's a computer, as the keyboard would cover the Facebook button.
    const autofocus = !Capacitor.isNativePlatform() && !isMobileNavigator;

    return (
      <IonPage className="login-page light-page light-page-display" data-cy="login-page">
        <CommonHeader
          addIonHeader={true}
          isBackButtonDisabled={this.props.isAuthenticationRequestLoading}
          title={
            <IonThumbnail className="logo-thumbnail">
              <img src="/assets/logo-indigo-gradient.svg" alt="Indigo" />
            </IonThumbnail>
          }
        />

        <IonContent>
          <header>
            <h1>
              <Trans i18nKey="login.title" />
            </h1>

            <hr />
          </header>

          <Formik
            initialValues={{ email: this.state.email, password: this.state.password }}
            onSubmit={(values, { setSubmitting }) => {
              // Removed finally because of formik warning -> no update on unmounted components. It was the case on a successful login.
              this.login(values.email, values.password).catch(() => setSubmitting(false));
            }}
            validationSchema={Yup.object({
              email: Yup.string().email(this.props.t('login.error-email')).required('Email is required'),
              password: Yup.string().required('Password is required'),
            })}
          >
            {({ values, errors, touched, handleChange, handleBlur, submitForm, isSubmitting }) => (
              <Form>
                <IonItem className={errors.email && touched.email ? 'with-error' : ''} mode="md">
                  <IonLabel position="floating" color="medium">
                    <Trans i18nKey="user.email-address" /> {' *'}
                  </IonLabel>
                  <IonInput
                    name="email"
                    type="email"
                    autocapitalize="off"
                    autocomplete="on"
                    clearInput
                    autofocus={autofocus}
                    onIonFocus={() => this.setFocus('email')}
                    value={values.email}
                    onIonChange={handleChange}
                    onIonInput={handleChange}
                    onIonBlur={e => {
                      this.setState({ focusedField: null });
                      handleBlur(e);
                    }}
                    onKeyDown={ignoreResetButtonOnTab}
                  />
                  <img alt="validation" className={`icon-svg-validation ${!errors.email && values.email.length !== 0 && 'field-valid'}`} src="/assets/form/icon-validation.svg" />
                </IonItem>

                <p className="form-error-message">{errors.email && touched.email && <ErrorMessage name="email" component="ion-label" />}</p>

                <IonItem className={errors.password && touched.password ? 'with-error' : ''} mode="md">
                  <IonLabel position="floating" color="medium">
                    <Trans i18nKey="user.password" /> {' *'}
                  </IonLabel>
                  <IonInput
                    name="password"
                    type={this.state.isPasswordMasked ? 'password' : 'text'}
                    clearInput
                    clearOnEdit={false}
                    autocapitalize="off"
                    value={values.password}
                    onIonChange={handleChange}
                    onIonInput={handleChange}
                    onIonBlur={e => {
                      this.setState({ focusedField: null });
                      handleBlur(e);
                    }}
                    onIonFocus={() => {
                      this.setFocus('password');
                    }}
                    onKeyDown={ignoreResetButtonOnTab}
                    onKeyUp={e => onKeyboardEnterExecuteFunction(e, submitForm)}
                    enterkeyhint="enter"
                    inputmode="text"
                  />
                  <img alt="validation" className={`icon-svg-validation ${!errors.password && values.password.length !== 0 && 'field-valid'}`} src="/assets/form/icon-validation.svg" />
                  {values.password.length !== 0 && this.state.focusedField !== 'password' && (
                    <div className="button-right-input-wrapper">
                      <IonButton fill="clear" className="button-icon" buttonType="button icon-only" onClick={this.onPasswordToggle} tabIndex={-1}>
                        <IonIcon className="icon-password icon-only" icon={this.state.isPasswordMasked ? '/assets/form/eye.svg' : '/assets/form/eye-off.svg'} />
                      </IonButton>
                    </div>
                  )}
                </IonItem>
                {errors.password && touched.password && (
                  <p className="form-error-message">
                    <ErrorMessage name="password" component="ion-label" />
                  </p>
                )}

                <div className="link-wrap">
                  <Link to="/" className="forgot-password-link" onClick={this.handlePasswordLinkClick}>
                    <Trans i18nKey="login.forgot-password-title" />
                  </Link>
                </div>

                <div className="bottom-section">
                  <IonButton
                    className="button-large"
                    data-cy="button-continue"
                    color="primary"
                    type="submit"
                    shape="round"
                    expand="block"
                    size="large"
                    disabled={this.props.isAuthenticationRequestLoading}
                  >
                    {this.props.isAuthenticationRequestLoading ? <Trans i18nKey="common.loading" /> : <Trans i18nKey="user.continue" />}
                  </IonButton>
                  <div className="line-with-text">
                    <span>
                      <Trans i18nKey="register.or" />
                    </span>
                  </div>
                  <AppleLogin onLogin={this.appleLogin} onError={this.appleError} disabled={this.props.isAuthenticationRequestLoading} />
                  <FacebookLogin autoLoginAction onLogin={this.fbLogin} onError={this.props.setToastMessage} disabled={this.props.isAuthenticationRequestLoading} />
                </div>
                {isSubmitting && <IonLoading spinner="bubbles" isOpen={true} showBackdrop />}
              </Form>
            )}
          </Formik>
        </IonContent>

        <ForgotPasswordModal closeModal={() => this.setState({ isForgotPasswordModalOpen: false })} isOpen={this.state.isForgotPasswordModalOpen} />
      </IonPage>
    );
  }

  fbLogin = async (accessToken: string): Promise<void> => {
    try {
      await this.props.authenticateWithFacebook(accessToken);
      this.props.setIsFullyLoggedIn(true);
    } catch (e) {
      if (e.message === 'User not found') {
        this.props.setToastMessage('login.no-fb-user-found-register');
        this.props.history.push('/register');
      }
    }
  };

  appleLogin = async (connectData: SignInWithAppleResponse): Promise<void> => {
    try {
      await this.props.authenticateWithApple(connectData.response.identityToken);
      this.props.setIsFullyLoggedIn(true);
    } catch (e) {
      // register new user
      // Caution: this is an ugly hack for the apple test account
      // TODO Remove it
      const newFormValues = {
        email: connectData.response.email ?? Math.random().toString(36).substr(2, 9) + defaultEmptyEmailDomain,
        firstname: connectData.response.givenName ?? 'Apple',
        lastname: connectData.response.familyName ?? 'account',
        appleAccessToken: connectData.response.identityToken,
        gender: 'other',
        newsletterSubscribed: false,
        lastTosAcceptedDate: new Date(),
        avatar: null,
      };
      this.setState(
        {
          initialValues: Object.assign({}, this.state.initialValues, newFormValues),
        },
        () => {
          this.registerApple(this.state.initialValues);
        },
      );
    }
  };

  private registerApple = async (user: UserFormValues): Promise<void> => {
    await this.props.register(user);

    if (user.appleAccessToken) {
      await this.props.authenticateWithApple(user.appleAccessToken);
    }

    this.props.setIsFullyLoggedIn(true);
  };

  appleError = (error: string | null): void => {
    if (error === 'The operation couldn’t be completed. (com.apple.AuthenticationServices.AuthorizationError error 1000.)') {
      this.props.setToastMessage('login.apple-simulator-error');
      return;
    }
    this.props.setToastMessage(error);
  };

  private async login(email: string, password: string): Promise<void> {
    await this.props.authenticate(email, password);
    this.props.setIsFullyLoggedIn(true);
  }

  private onPasswordToggle(): void {
    this.setState({ isPasswordMasked: !this.state.isPasswordMasked });
  }

  private setFocus(field: string | null): void {
    this.setState({ focusedField: field });
  }

  private handlePasswordLinkClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault();
    this.setState({ isForgotPasswordModalOpen: true });
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(LoginPage)));
