import { IonImg, IonItem, IonLabel, IonList, IonText, IonThumbnail, IonCheckbox, IonItemSliding, IonItemOptions, IonItemOption } from '@ionic/react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { UserChat, UserChatState } from '../store/chats/types';
import last from 'lodash/last';
import { getDisabledUserName, getPostTitle, extractId, formatDateFromNow, isSameHydraEntity, chatListMessageFormat } from '../utils/helpers';
import { EntityReference, HydratedCollection } from '../utils/hydra';
import ChatsPlaceholder from './placeholders/ChatsPlaceholder';
import { useHistory } from 'react-router';
import { statesDisplayInChatsList, PostRequest } from '../store/postRequests/types';
import { CurrentUser, User } from '../store/users/types';
import './ChatsList.scss';
import ImageFadeIn from './ImageFadeIn';
import { Media } from '../store/app/types';
import { Post } from '../store/posts/types';

interface ChatListItemProps {
  userChat: UserChat;
  isEditMode: boolean;
  isChecked: boolean;
  toggleCheck: (userChatId: EntityReference) => void;
  onChatItemClick: (userChatId: EntityReference, newChatState: UserChatState) => void;
  currentUser: CurrentUser;
}

export const userChatHasUnreadMessages = (userChat: UserChat): boolean => {
  const lastAction = userChat?.chat?.lastActionDate;

  if (!lastAction) {
    return false;
  }

  const lastActionDate: Date = new Date(lastAction);
  const lastReadMessageDate: Date | null = userChat.lastReadMessageDate ? new Date(userChat.lastReadMessageDate) : null;

  // If there is no LastReadMessageDate (chat was never opened), we check if the chat contains messages
  if (!lastReadMessageDate) {
    return !!lastActionDate;
  }

  return lastReadMessageDate.getTime() - lastActionDate.getTime() < 0;
};

const ChatListItem: React.FunctionComponent<ChatListItemProps> = ({ userChat, isEditMode, isChecked, toggleCheck, onChatItemClick, currentUser }: ChatListItemProps) => {
  const postRequest: PostRequest | null = userChat.chat?.postRequest;
  const post: Post | null = userChat.chat?.post;
  const history = useHistory();
  const { t } = useTranslation();
  const userChatId = extractId(userChat);
  const postRequestTypeMedia = postRequest && postRequest.post && postRequest.post.type === 'offer' ? '/assets/bt-post-offer.svg' : '/assets/bt-post-request.svg';

  const handleClick = (): void => {
    if (isEditMode) {
      toggleCheck(userChatId);
      return;
    }

    history.push(extractId(userChat.chat));
  };

  return (
    <IonItemSliding className="chat-item-sliding">
      <IonItem
        button
        onClick={handleClick}
        className={`chat-item ${isChecked ? 'checked' : ''} ${userChatHasUnreadMessages(userChat) && 'unread-item'}`}
        detail-icon="/assets/navigation/chevron-forward.svg"
      >
        {isEditMode && <IonCheckbox slot="start" className="item-check-mark" color="medium" checked={isChecked} />}

        <IonThumbnail slot="start" className="user-avatar">
          {userChat.chat.type === 'post_group_private' && <IonImg src="/assets/icon/chat-group.svg" className="user-image" />}
          {userChat.chat.type === 'direct' && (
            <ImageFadeIn
              thumbnail
              src={`${!userChat.chat.properties?.firstUsers?.[0]?.disabled ? userChat.chat.properties?.firstUsers?.[0]?.avatar?.contentUrl || '/assets/avatar.svg' : '/assets/avatar.svg'}`}
              className="user-image"
            />
          )}
          {userChat.chat.type === 'post_request' && postRequest && postRequest.post && (
            <>
              <ImageFadeIn
                thumbnail
                src={postRequest.post.images && (postRequest.post.images as Media[]).length !== 0 ? (postRequest.post.images[0] as Media).contentUrl : postRequestTypeMedia}
                className="user-image"
              />
              <ImageFadeIn
                thumbnail
                src={`${!userChat.chat.properties?.firstUsers?.[0]?.disabled ? userChat.chat.properties?.firstUsers?.[0]?.avatar?.contentUrl || '/assets/avatar.svg' : '/assets/avatar.svg'}`}
                className="user-image user-miniature"
              />
            </>
          )}
        </IonThumbnail>

        <div className="message-wrapper">
          <div className="message-info-container">
            <IonText className="user-name">
              {userChat.chat.type === 'direct' && <Trans i18nKey="chat.direct-chat" />}
              {userChat.chat.type === 'post_group_private' && post && getPostTitle(post, t)}
              {userChat.chat.type === 'post_request' && postRequest && (
                <>
                  <span>
                    {postRequest.post.type === 'offer' && (
                      <>{isSameHydraEntity(postRequest.post.createdBy, extractId(currentUser)) ? <Trans i18nKey="common.i-offer" /> : <Trans i18nKey="common.i-ask" />}</>
                    )}
                    {postRequest.post.type === 'need' && (
                      <>{isSameHydraEntity(postRequest.post.createdBy, extractId(currentUser)) ? <Trans i18nKey="common.i-need" /> : <Trans i18nKey="common.i-help" />}</>
                    )}
                  </span>
                  <span className="grey-color point"> - </span>
                  {getPostTitle(postRequest.post, t)}
                </>
              )}
            </IonText>
            <IonText className="post-title ellipsis-overflow">
              {userChat.chat.type === 'post_group_private' && <Trans i18nKey="chat.group-title" />}
              {(userChat.chat.type === 'direct' || userChat.chat.type === 'post_request') && (
                <>{!userChat.chat.properties?.firstUsers?.[0]?.disabled ? userChat.chat.properties?.firstUsers?.[0]?.name : getDisabledUserName(userChat.chat.properties.firstUsers[0], t)}</>
              )}
            </IonText>
            <IonText className="post-message">
              {postRequest?.state && statesDisplayInChatsList.includes(postRequest?.state) && (
                <>
                  <IonImg src={`/assets/icon/post-request-state/${postRequest.state}.svg`} />
                  <span className={`post-request-state-${postRequest.state}`}>
                    <Trans i18nKey={`post-request.state.${postRequest.state}`} />
                    <span className="grey-color point">᛫</span>
                  </span>
                </>
              )}
              {userChat.chat.properties.lastMessage ? (
                <>
                  <span className={`ellipsis-overflow ${!userChatHasUnreadMessages(userChat) ? 'grey-color' : ''}`}>{chatListMessageFormat(userChat.chat.properties.lastMessage.text, t)}</span>
                  <span className="grey-color point">᛫</span>
                  <span className="last-message-date grey-color capitalize-first-letter">{formatDateFromNow(new Date(userChat.chat.properties.lastMessage.createdAt))}</span>
                </>
              ) : (
                <>
                  {postRequest?.stateHistory?.length ? (
                    <span className="last-message-date grey-color capitalize-first-letter">{formatDateFromNow(new Date(last(postRequest?.stateHistory)?.date || ''))}</span>
                  ) : (
                    <Trans i18nKey="chat.no-message" />
                  )}
                </>
              )}
            </IonText>
          </div>
        </div>
      </IonItem>
      <IonItemOptions side="end">
        <IonItemOption data-cy="archive-action" expandable onClick={() => onChatItemClick(userChatId, userChat.state === 'active' ? 'archived' : 'active')} color="primary">
          {userChat.state === 'active' ? <Trans i18nKey="chat.archive" /> : <Trans i18nKey="chat.unarchive" />}
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  );
};

interface ChatsListProps {
  userChats: HydratedCollection<UserChat>;
  isEditMode: boolean;
  checkedUserChats: EntityReference[];
  toggleChatItem: (userChatId: EntityReference) => void;
  onChatItemClick: (userChatId: EntityReference, newChatState: UserChatState) => void;
  currentUser: CurrentUser;
}

interface GroupChatParticipantsListProps {
  userChats: HydratedCollection<UserChat>;
  toggleChatItem: (user: User | undefined) => void;
}

export const GroupChatParticipantsList: React.FunctionComponent<GroupChatParticipantsListProps> = ({ userChats, toggleChatItem }: GroupChatParticipantsListProps) => {
  if (userChats.isLoading && userChats.totalItems === 0) {
    return <ChatsPlaceholder />;
  }

  if (userChats.totalItems === 0) {
    return (
      <IonLabel className="ion-text-center">
        <Trans i18nKey="chat.no-participants-found" />
      </IonLabel>
    );
  }

  return (
    <IonList className="chats-list" lines="none">
      {userChats.items.map((userChat: UserChat) => {
        if (userChat?.chat?.type === 'post_group_private') return null;
        return (
          <IonItem key={extractId(userChat)} button detail={false} className="chat-item" onClick={() => toggleChatItem(userChat.user)}>
            <IonThumbnail slot="start" className="user-avatar">
              <ImageFadeIn thumbnail src={`${userChat.user?.avatar?.contentUrl || '/assets/avatar.svg'}`} className="user-image" />
            </IonThumbnail>
            <div className="message-wrapper">
              <div className="message-info-container">
                <IonText className="user-name">{userChat.user?.name}</IonText>
                {userChat.chat?.type === 'post_request' && <IonText className="post-title ellipsis-overflow">{userChat.chat?.postRequest?.post.title}</IonText>}
                <IonText className="post-message">
                  {userChat.chat?.properties.lastMessage && (
                    <>
                      <span className={`last-message ${!userChatHasUnreadMessages(userChat) ? 'grey-color' : ''}`}>{userChat.chat.properties.lastMessage.text}</span>
                      <span className="grey-color point">᛫</span>
                      <span className="last-message-date grey-color capitalize-first-letter">{formatDateFromNow(new Date(userChat.chat.properties.lastMessage.createdAt))}</span>
                    </>
                  )}
                </IonText>
              </div>
              {userChatHasUnreadMessages(userChat) && <div className="unread-dot" />}
            </div>
          </IonItem>
        );
      })}
    </IonList>
  );
};

const ChatsList: React.FunctionComponent<ChatsListProps> = ({ userChats, isEditMode, checkedUserChats, toggleChatItem, onChatItemClick, currentUser }: ChatsListProps) => {
  if ((userChats.isLoading && userChats.totalItems === 0) || !userChats.lastFetchDate) {
    return <ChatsPlaceholder />;
  }

  if (userChats.totalItems === 0) {
    return (
      <IonLabel className="ion-text-center">
        <Trans i18nKey="chat.no-chats-found" />
      </IonLabel>
    );
  }

  return (
    <IonList className={`chats-list ${isEditMode ? 'select-mode' : ''}`} lines="none">
      {userChats.items.map((userChat: UserChat) => (
        <ChatListItem
          key={extractId(userChat)}
          userChat={userChat}
          isEditMode={isEditMode}
          isChecked={checkedUserChats.includes(extractId(userChat))}
          toggleCheck={toggleChatItem}
          onChatItemClick={onChatItemClick}
          currentUser={currentUser}
        />
      ))}
    </IonList>
  );
};

export default React.memo(ChatsList);
