import i18n from 'i18next';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { appLanguages } from './utils/translation';
import en from './translations/en/client.json';
import el from './translations/el/client.json';
import fi from './translations/fi/client.json';
import fr from './translations/fr/client.json';
import it from './translations/it/client.json';
import pl from './translations/pl/client.json';
import pt from './translations/pt/client.json';
import ar from './translations/ar/client.json';
import ur from './translations/ur/client.json';
import fa from './translations/fa/client.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      resources: {
        en: { translation: en },
        el: { translation: el },
        fi: { translation: fi },
        fr: { translation: fr },
        it: { translation: it },
        pl: { translation: pl },
        pt: { translation: pt },
        ar: { translation: ar },
        ur: { translation: ur },
        fa: { translation: fa },
      },
      fallbackLng: 'en',
      debug: false,

      supportedLngs: appLanguages, // Array of allowed languages
      load: 'languageOnly',

      interpolation: {
        escapeValue: false, // react already safes from xss
      },

      detection: {
        order: ['navigator'],
        excludeCacheFor: ['cimode', 'cookie', 'localStorage'], // languages to not persist (cookie, localStorage)
      },
    },
    err => {
      if (err) {
        return console.error(err);
      }
      moment.locale(i18n.language);
    },
  );

export const localesLabels = [
  { value: 'en', label: 'English' },
  { value: 'el', label: 'Ελληνικά' },
  { value: 'fr', label: 'Français' },
  { value: 'it', label: 'Italiano' },
  { value: 'pl', label: 'Polskie' },
  { value: 'pt', label: 'Português' },
  { value: 'fi', label: 'Viedä loppuun' },
  { value: 'ar', label: 'العربية' },
  { value: 'ur', label: 'اردو' },
  { value: 'fa', label: 'فارسی' },
];

export default i18n;
