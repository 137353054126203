import { assignWindowLocation } from './windowLocationHelper';
import { PushNotificationSchema } from '../capacitor/PushNotifications';
import { ActionPerformed, LocalNotifications } from '@capacitor/local-notifications';

export const setLocalNotification = (notification: PushNotificationSchema): void => {
  LocalNotifications.schedule({
    notifications: [
      {
        title: notification.title || notification.data.reason,
        body: notification.body || '',
        smallIcon: 'res://ic_local_notification',
        id: new Date().getTime(),
        extra: {
          redirectUrl: '/notification_redirection/' + notification.data.id,
        },
        threadIdentifier: 'indigo_world',
        group: 'indigo_world',
      },
    ],
  });
};

let localNotificationListenersRegistered = false;
export const registerLocalNotificationListeners = async (): Promise<void> => {
  if (localNotificationListenersRegistered) {
    console.error("Local notification listeners are already registered. This should never happen, please check how it's possible");
    return;
  }

  localNotificationListenersRegistered = true;

  LocalNotifications.addListener('localNotificationActionPerformed', (notificationAction: ActionPerformed) => {
    if (typeof notificationAction.notification.extra?.redirectUrl !== 'undefined') {
      // Open to the notification redirection page
      assignWindowLocation(notificationAction.notification.extra.redirectUrl);
    }
  });
};
