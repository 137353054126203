import React, { Component, ReactNode } from 'react';
import { IonModal, IonIcon, IonToolbar, IonButtons, IonButton, IonHeader, IonText, IonContent } from '@ionic/react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { actions, RootState } from '../store';
import * as Yup from 'yup';
import DynamicForm, { FormFields, FormValue, FormValues, TextareaFieldComponent } from './DynamicForm';
import { FormikHelpers, FormikProps } from 'formik';
import { EntityReference } from '../utils/hydra';
import i18next from 'i18next';
import SelectField from './inputs/SelectField';
import { getUserIsModerator } from '../store/app/selectors';
import './ReportModal.scss';
import 'react-phone-input-2/lib/style.css';
import './UserForm.scss';
import { additionalReportFormValuesType } from '../store/reports/types';

const getSubmitButton: React.FunctionComponent<FormikProps<FormValues>> = (formikBag: FormValues, text: string) => (
  <IonButton
    type="submit"
    shape="round"
    className="full-width-button report-submit-button"
    disabled={!formikBag.isValid || formikBag.isValidating || formikBag.isSubmitting}
    data-cy="report-submit-button"
  >
    {text}
  </IonButton>
);

interface State {
  initialFormValues: ReportFormValues;
}

interface DispatchProps {
  createPostReport(postId: EntityReference, reason: string, type: string, reportedPostTargetTransition?: additionalReportFormValuesType): Promise<void>;
  createUserReport(userId: EntityReference, reason: string, type: string): Promise<void>;
}

interface ModalProps {
  closeModal: () => void;
  isOpen: boolean;
  userId?: EntityReference;
  postId?: EntityReference;
  setReportLoadingState: (isReportLoading: boolean) => void;
}

const propsToDispatch = {
  createUserReport: actions.reportsActions.createUserReport,
  createPostReport: actions.reportsActions.createPostReport,
};

interface StateProps {
  userIsModerator: boolean;
}

const mapStateToProps = (state: RootState): StateProps => ({
  userIsModerator: getUserIsModerator(state),
});

interface ReportFormValues extends FormValues {
  creatorComment: string;
  reason: string;
}

interface AdditionalReportFormValues extends FormValues {
  reportedPostTargetTransition: additionalReportFormValuesType;
}

type Props = WithTranslation & ModalProps & DispatchProps & StateProps;

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

const initialFormValues: ReportFormValues = {
  creatorComment: '',
  reason: '',
};

const additionalReportPostAdminFormValues: AdditionalReportFormValues = {
  reportedPostTargetTransition: '',
};

function getReportedPostTargetTransition(): { value: string; label: string }[] {
  return [
    { value: '', label: i18next.t('report.simple-reporting') },
    { value: 'unpublish', label: i18next.t('report.hide-ad') },
    { value: 'moderate', label: i18next.t('report.moderate-ad') },
  ];
}

class ReportModal extends Component<Props, State> {
  reportPredefinedReasonUserSelectFieldOptions = [
    {
      label: i18next.t('report.scam'),
      value: 'scam',
    },
    {
      label: i18next.t('report.obscene-language'),
      value: 'obscene_language',
    },
    {
      label: i18next.t('report.harassment'),
      value: 'harassment',
    },
    {
      label: i18next.t('report.other'),
      value: 'other',
    },
  ];

  reportPredefinedReasonPostSelectFieldOptions = [
    {
      label: i18next.t('report.scam'),
      value: 'scam',
    },
    {
      label: i18next.t('report.paid-advert'),
      value: 'paid_advert',
    },
    {
      label: i18next.t('report.obscene-language'),
      value: 'obscene_language',
    },
    {
      label: i18next.t('report.inappropriate-advert'),
      value: 'inappropriate_advert',
    },
    {
      label: i18next.t('report.not-cgu-compliant'),
      value: 'not_cgu_compliant',
    },
    {
      label: i18next.t('report.other'),
      value: 'other',
    },
  ];

  allFields: FormFields = [
    {
      label: 'report.reason',
      labelOptions: { position: 'stacked' },
      name: 'reason',
      formComponent: SelectField,
      withoutIonItem: true,
      options: {
        autocapitalize: 'on',
        type: 'text',
        clearOnEdit: false,
        selectOptions: this.props.userId ? this.reportPredefinedReasonUserSelectFieldOptions : this.reportPredefinedReasonPostSelectFieldOptions,
        multiple: false,
        interface: 'alert',
      },
    },
    {
      label: 'report.creatorComment',
      name: 'creatorComment',
      formComponent: TextareaFieldComponent,
      options: { autocapitalize: 'sentences', clearOnEdit: false },
    },
  ];

  additionalReportPostAdminFields: FormFields = [
    {
      label: 'report.as-admin',
      name: 'reportedPostTargetTransition',
      formComponent: SelectField,
      withoutIonItem: true,
      options: {
        textWrap: true,
        autocapitalize: 'on',
        type: 'text',
        clearOnEdit: false,
        selectOptions: getReportedPostTargetTransition(),
        multiple: false,
        interface: 'action-sheet',
      },
    },
  ];

  validationSchema = Yup.object().shape({
    creatorComment: Yup.string().required('common.report-required'),
    reason: Yup.string().required('common.report-required'),
  });

  public render(): ReactNode {
    const { closeModal, isOpen, t, postId, userIsModerator } = this.props;
    return (
      <>
        <IonModal isOpen={isOpen} onDidDismiss={closeModal} className="report-modal safe-area-ios">
          <IonHeader className="report-modal-header">
            <IonToolbar>
              <IonButtons slot="start">
                <IonButton color="dark" onClick={closeModal} data-cy="report-modal-close-button">
                  <IonIcon icon="/assets/navigation/close.svg" className="close-icon" onClick={closeModal} />
                </IonButton>
              </IonButtons>
              <IonText color="dark">
                <Trans i18nKey={postId ? 'post.report-post' : 'user.report'} />
              </IonText>
            </IonToolbar>
          </IonHeader>

          <IonContent className="report-modal-content">
            <div className="report-modal-container">
              <DynamicForm
                onAfterSubmitFormSuccess={this.onAfterSubmitFormSuccess}
                onSubmitErrors={this.onAfterSubmitError}
                onSubmitForm={this.submitForm}
                formFields={this.transformFormValues()}
                validationSchema={this.validationSchema}
                initialValues={userIsModerator && this.props.postId ? { ...initialFormValues, ...additionalReportPostAdminFormValues } : initialFormValues}
                onFieldChange={this.transformText}
                dynamicFormSubmitButton={formikBag => getSubmitButton(formikBag, t('user.report-submit'))}
              />
            </div>
          </IonContent>
        </IonModal>
      </>
    );
  }

  private transformText = (field: string, value: FormValue, form: FormikProps<FormValues>): void => {
    if (value.length > 0 && field === 'creatorComment') {
      form.setFieldValue(field, (value as string).charAt(0).toUpperCase() + (value as string).substr(1));
    }
  };

  private transformFormValues = (): FormFields => {
    if (this.props.userIsModerator && this.props.postId) {
      return this.allFields.concat(this.additionalReportPostAdminFields).map(field => {
        field.options = { ...field.options };
        return field;
      });
    }
    return this.allFields.map(field => {
      field.options = { ...field.options };
      return field;
    });
  };

  private onAfterSubmitError = async (): Promise<void> => {
    this.props.closeModal();
  };

  private onAfterSubmitFormSuccess = async (values: Readonly<FormValues>, actions: FormikHelpers<FormValues>): Promise<void> => {
    actions.resetForm({ values: { ...values, creatorComment: '', reason: '' } });
    this.props.closeModal();
  };

  private submitForm = async (values: Readonly<FormValues>): Promise<void> => {
    if (!values.creatorComment || !values.reason) {
      return;
    }

    this.props.setReportLoadingState(true);

    if (this.props.postId && this.props.userIsModerator) {
      this.props.createPostReport(this.props.postId, values.creatorComment, values.reason, values.reportedPostTargetTransition).finally(() => this.props.setReportLoadingState(false));
      return;
    }

    if (this.props.postId) {
      this.props.createPostReport(this.props.postId, values.creatorComment, values.reason).finally(() => this.props.setReportLoadingState(false));
    }

    if (this.props.userId) {
      this.props.createUserReport(this.props.userId, values.creatorComment, values.reason).finally(() => this.props.setReportLoadingState(false));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ReportModal));
