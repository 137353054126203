import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { actions, RootState } from '../store';
import { getCategories } from '../store/categories/selectors';
import { Category } from '../store/categories/types';
import { FullPost } from '../store/posts/types';
import { Thematic } from '../store/thematics/types';
import { extractId, isEqualIgnoringFunctions } from '../utils/helpers';
import { ItemsCollection } from '../utils/hydra';
import SearchPagePlaceholder from './placeholders/SearchPagePlaceholder';
import UserPostsSlider from './UserPostsSlider';
import isEqual from 'lodash/isEqual';
import { getRecommendationsCollection } from '../store/posts/selectors';
import { createIsCurrentUserFunc, isCurrentUserFunc } from '../store/app/selectors';

interface RecommendationListProps {
  userThematics: Thematic[];
  title: string;
}

interface RecommendationListStateProps {
  isCurrentUser: isCurrentUserFunc;
  collection: ItemsCollection<FullPost>;
  categories: Category[];
}

interface DispatchProps {
  fetchPostsByCategories: (collectionName: string, thematicCategories: Category[], loadNextPage: boolean, sortByCreatedDate?: boolean) => Promise<void>;
}

const mapStateToProps = (state: RootState): RecommendationListStateProps => ({
  isCurrentUser: createIsCurrentUserFunc(state),
  collection: getRecommendationsCollection(state),
  categories: getCategories(state),
});

const propsToDispatch = {
  fetchPostsByCategories: actions.posts.fetchPostsByCategories,
};

type ListProps = RecommendationListProps & RecommendationListStateProps & DispatchProps;

class RecommendationList extends Component<ListProps> {
  public componentDidMount(): void {
    this.fetchPostRecommendations();
  }

  public shouldComponentUpdate(nextProps: Readonly<ListProps>): boolean {
    return !isEqualIgnoringFunctions(nextProps, this.props);
  }

  public componentDidUpdate(prevProps: Readonly<ListProps>): void {
    if ((this.props.categories.length && !prevProps.categories.length) || (!isEqual(this.props.userThematics, prevProps.userThematics) && this.props.categories)) {
      this.fetchPostRecommendations();
    }
  }

  private fetchPostRecommendations(loadNextPage = false): void {
    const categories: Category[] = this.props.categories.filter(category => {
      return this.props.userThematics.find(thematic => thematic.categories.includes(extractId(category)));
    });

    if (!categories.length) {
      return;
    }
    this.props.fetchPostsByCategories('recommendation', categories, loadNextPage);
  }

  public render(): ReactNode {
    const { isCurrentUser, title } = this.props;

    if ((this.props.collection.isLoading && this.props.collection.totalItems === 0) || !this.props.collection.lastFetchDate) {
      return <SearchPagePlaceholder isSearchResult={false} />;
    }

    if (!this.props.collection || this.props.collection.totalItems === 0) {
      return null;
    }
    return (
      <div className="recommendation-slider">
        <UserPostsSlider postsCollection={this.props.collection} isCurrentUser={isCurrentUser} title={title} collectionName="recommendation" />
      </div>
    );
  }
}

export default connect(mapStateToProps, propsToDispatch)(RecommendationList);
