import amplitude from 'amplitude-js';
import { amplitudeId, analyticsEnabled } from '../../environment';
import { isFirstAppOpen } from './analyticsHelper';
import { CurrentUser } from '../../store/users/types';
import { Capacitor } from '@capacitor/core';
import * as eventsName from './eventsName';

// eslint-disable-next-line @typescript-eslint/ban-types
export const logAmplitudeEvent = async (name: string, params: object = {}): Promise<void> => {
  if (!analyticsEnabled || !amplitude.getInstance().getSessionId()) {
    return;
  }
  amplitude.getInstance().logEvent(name, params);
};

export const setAnalyticsUser = async (user: CurrentUser): Promise<void> => {
  if (!analyticsEnabled) {
    return;
  }
  const identify = new amplitude.Identify()
    .set('city', user?.address?.locality || '')
    .set('gender', user?.gender || '')
    .set('managed', !!user?.managed)
    .set('interests', user?.favoriteCategoryThematics)
    .set('totalPosts', user?.status.createdPosts)
    .set('totalCreatedOffers', user?.status.createdOfferPosts)
    .set('totalCreatedRequests', user?.status.createdNeedPosts)
    .set('totalActiveOffers', user?.status.offerPostNotFinished)
    .set('totalActiveRequests', user?.status.needPostNotFinished)
    .set('totalFollowed', user?.status.favoriteUsers)
    .set('totalAskOffers', user?.status.requestPostsByUser);
  amplitude.getInstance().identify(identify);
};

const setPlatform = (): void => {
  const identify = new amplitude.Identify().set('Platform', Capacitor.getPlatform() ? Capacitor.getPlatform() : 'unknown');
  amplitude.getInstance().identify(identify);
};

export const initAmplitude = (): void => {
  if (!analyticsEnabled) {
    return;
  }
  amplitude.getInstance().init(amplitudeId, undefined, { batchEvents: true, eventUploadPeriodMillis: 10000, eventUploadThreshold: 10, platform: Capacitor.getPlatform() || 'unknown' });
  setPlatform();
  logAmplitudeEvent(eventsName.sessionStart);
  if (isFirstAppOpen()) {
    logAmplitudeEvent(eventsName.firstOpen);
  }
};
