import { IonItem, IonIcon, IonLabel, IonText, IonNote, IonActionSheet } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { CurrentUser } from '../../store/users/types';
import './ThirdPartyLink.scss';

interface ListProps {
  currentUser: CurrentUser | undefined;
  thirdPartyName: string;
  thirdPartyLogo?: string;
  thirdPartySrcLogo?: string;
  thirdPartyToken?: string | null;
  unlinkThirdPartyAccount?: () => void;
  linkThirdPartyAccount?: () => void;
}

interface State {
  isActionSheetOpen: boolean;
}

type Props = ListProps & WithTranslation;

class ThirdPartyLink extends Component<Props, State> {
  private thirdPartyId: string;

  public constructor(props: Props) {
    super(props);
    this.thirdPartyId = props.thirdPartyName.toLowerCase();
    this.state = {
      isActionSheetOpen: false,
    };
  }

  public render(): ReactNode {
    const { t } = this.props;
    const actionSheetHeader = this.props.thirdPartyToken ? t('user.dissociate-to', { socialname: this.props.thirdPartyName }) : t('user.connect-to', { socialname: this.props.thirdPartyName });
    const connectActionSheetButtons = [
      {
        text: t('user.login'),
        role: 'destructive',
        handler: this.props.linkThirdPartyAccount,
      },
      {
        text: this.props.t('common.cancel'),
        role: 'cancel',
      },
    ];
    const disconnectActionSheetButtons = [
      {
        text: t('user.dissociate'),
        role: 'destructive',
        handler: this.props.unlinkThirdPartyAccount,
      },
      {
        text: this.props.t('common.cancel'),
        role: 'cancel',
      },
    ];
    console.log(this.props.thirdPartySrcLogo);
    return (
      <React.Fragment>
        <IonItem detail button onClick={this.openActionSheet} className={'third-party-link ' + (!this.props.thirdPartyToken ? '_gaps ' : '')} data-cy={this.thirdPartyId + '-link'}>
          <IonIcon className="third-party-icon" src={this.props.thirdPartySrcLogo} icon={this.props.thirdPartyLogo} />
          <IonLabel>
            <IonText className="third-party-title">{this.props.thirdPartyName}</IonText>
            {!this.props.thirdPartyToken && (
              <IonNote className="third-party-subtitle">
                <Trans i18nKey="user.link-your-account" values={{ thirdPartyName: this.props.thirdPartyName }} />
              </IonNote>
            )}
          </IonLabel>
          {!!this.props.thirdPartyToken && (
            <IonNote slot="end">
              <Trans i18nKey="user.connected" />
            </IonNote>
          )}
        </IonItem>
        <IonActionSheet
          isOpen={this.state.isActionSheetOpen}
          onDidDismiss={() => this.setState({ isActionSheetOpen: false })}
          header={actionSheetHeader}
          buttons={this.props.thirdPartyToken ? disconnectActionSheetButtons : connectActionSheetButtons}
        />
      </React.Fragment>
    );
  }

  private openActionSheet = (): void => {
    this.setState({ isActionSheetOpen: true });
  };
}

export default withTranslation()(ThirdPartyLink);
