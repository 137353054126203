import React from 'react';
import { useHistory } from 'react-router';
import { IonButton, IonIcon, IonImg, IonPopover } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';

import './PopoverConnection.scss';

interface ComponentProps {
  popoverConnectionIsOpen: boolean;
  isFullyLogged: boolean;
}

const mapStateToProps = (state: RootState): ComponentProps => ({
  popoverConnectionIsOpen: state.app.popoverConnectionIsOpen,
  isFullyLogged: state.app.isFullyLogged,
});

const mapDispatchToProps = {
  setPopoverConnectionIsOpenAction: actions.app.setPopoverConnectionIsOpenAction,
};

type Props = ComponentProps & typeof mapDispatchToProps;

const PopoverConnection: React.FunctionComponent<Props> = ({ popoverConnectionIsOpen, setPopoverConnectionIsOpenAction, isFullyLogged }: Props) => {
  if (isFullyLogged) {
    return <></>;
  }

  const { t } = useTranslation();
  const history = useHistory();

  const closePopover = (redirect = false): void => {
    setPopoverConnectionIsOpenAction(false);
    if (redirect) {
      history.push('/homepage');
    }
  };

  return (
    <IonPopover isOpen={popoverConnectionIsOpen} onDidDismiss={() => closePopover()} className="popover-connection safe-area-ios">
      <IonButton className="close-icon-button" onClick={() => closePopover()} fill="clear" size="small" data-cy="close-icon-button">
        <IonIcon icon="/assets/navigation/close.svg" className="close-icon" slot="icon-only" />
      </IonButton>
      <IonImg src="/assets/family.svg" />
      <h6>{t('popover-connection.join-us')}</h6>
      <div className="additional-text">{t('popover-connection.take-full-advantage')}</div>
      <IonButton className="button-small" data-cy="button-redirect" color="primary" shape="round" expand="block" size="large" onClick={() => closePopover(true)}>
        {t('popover-connection.connection')}
      </IonButton>
    </IonPopover>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PopoverConnection);
