import { IonContent, IonItem, IonList, IonModal, IonText } from '@ionic/react';
import React from 'react';
import { Trans } from 'react-i18next';
import { apiEntrypoint, defaultApiEntrypoint } from '../../environment';
import { ReactComponentType } from '../types';
import ChangeLanguageSelectMenuItem from './ChangeLanguageSelectMenuItem';
import LogOutMenuItem from './LogOutMenuItem';
import ShareAppMenuItem from './ShareAppMenuItem';
import HelpMenuItem from './HelpMenuItem';
import { routePrivacy, routeTos } from '../../utils/helpers';
import { deviceCanShare } from '../../utils/shareHelper';
import './ProfileMenuModal.scss';

export type ChildMenu = {
  title: string;
  icon?: string;
  route?: string;
  isExternalLink?: boolean;
  itemComponent?: ReactComponentType;
};

export type Menu = {
  title: string;
  icon?: string;
  hidden?: boolean;
  itemComponent?: ReactComponentType;
  childrenPageTitle?: string;
  childrenPageRoute?: string;
  childrenPageContent?: ReactComponentType;
  children?: ChildMenu[];
  topSeparator?: boolean;
};

export const menuData: Menu[] = [
  {
    title: 'invite-friends',
    icon: 'assets/profile-menu-icons/person.svg',
    itemComponent: ShareAppMenuItem,
    hidden: !deviceCanShare,
  },
  {
    title: 'notifications',
    icon: 'assets/profile-menu-icons/notifications.svg',
    childrenPageRoute: 'notifications',
    children: [
      {
        title: 'push-notifications',
        route: 'notifications/settings/push',
      },
      {
        title: 'mail-notifications',
        route: 'notifications/settings/mail',
      },
      {
        title: 'newsletter',
        route: 'settings/newsletter',
      },
    ],
  },
  {
    title: 'privacy',
    icon: 'assets/profile-menu-icons/privacy.svg',
    childrenPageRoute: 'privacy',
    children: [
      // TODO: Uncomment to reactivate the privacy settings after the temporary deactivation
      // {
      //   title: 'privacy-settings',
      //   route: '/me/privacy/settings',
      // },
      {
        title: 'blocked-accounts',
        route: '/me/privacy/blocked_accounts',
      },
    ],
  },
  {
    title: 'security',
    icon: 'assets/profile-menu-icons/security.svg',
    childrenPageRoute: 'security',
    childrenPageTitle: 'login-security',
    children: [
      {
        title: 'password',
        icon: 'assets/profile-menu-icons/key.svg',
        route: '/me/password',
      },
    ],
  },
  {
    title: 'account',
    icon: 'assets/profile-menu-icons/account.svg',
    childrenPageRoute: 'account',
    children: [
      {
        title: 'language',
        itemComponent: ChangeLanguageSelectMenuItem,
      },
      {
        title: 'linked-accounts',
        route: '/me/account/linked',
      },
      {
        title: 'delete-account',
        route: '/me/account/delete',
      },
    ],
  },
  {
    title: 'help',
    icon: 'assets/profile-menu-icons/help.svg',
    childrenPageRoute: 'help',
    childrenPageContent: HelpMenuItem,
  },
  {
    title: 'about',
    icon: 'assets/profile-menu-icons/information.svg',
    childrenPageRoute: 'about',
    children: [
      {
        title: 'data-policy',
        route: routePrivacy(),
        isExternalLink: true,
      },
      {
        title: 'terms-of-use',
        route: routeTos(),
        isExternalLink: true,
      },
    ],
  },
  {
    title: 'log-out',
    icon: 'assets/profile-menu-icons/logout.svg',
    itemComponent: LogOutMenuItem,
    topSeparator: true,
  },
  {
    title: apiEntrypoint,
    hidden: apiEntrypoint === defaultApiEntrypoint,
    topSeparator: true,
  },
];

interface ModalProps {
  isOpen: boolean;
  closeModal: () => void;
  currentUserIsManagedUser: boolean;
}

export interface ModalItemProps {
  item: Menu;
  currentUserIsManagedUser: boolean;
  closeModal: () => void;
}

const ProfileMenuItem: React.FunctionComponent<ModalItemProps> = (props: ModalItemProps) => {
  const { item } = props;

  if (item.itemComponent) {
    const Comp: ReactComponentType = item.itemComponent;
    return <Comp {...props} />;
  }

  return (
    <IonItem
      detailIcon="/assets/navigation/chevron-forward.svg"
      className="menu-item"
      routerLink={item.childrenPageRoute ? `/me/${item.childrenPageRoute}` : undefined}
      onClick={() => props.closeModal()}
    >
      {item.icon && <img src={item.icon} alt="" className="icon" />}
      <IonText className="menu-text">
        <Trans i18nKey={props.currentUserIsManagedUser ? `profile-menu.managed-${item.title}` : `profile-menu.${item.title}`} defaults={item.title} />
      </IonText>
    </IonItem>
  );
};

const ProfileMenuModal: React.FunctionComponent<ModalProps> = (props: ModalProps) => {
  return (
    <IonModal isOpen={props.isOpen} onDidDismiss={props.closeModal} className="profile-menu-modal safe-area-ios--no-padding-top">
      <IonContent>
        <IonList lines="none" className="menu-list">
          {menuData
            .filter(item => !item.hidden)
            .map(item => (
              <React.Fragment key={item.title}>
                {item.topSeparator && <hr />}
                <ProfileMenuItem item={item} currentUserIsManagedUser={props.currentUserIsManagedUser} closeModal={props.closeModal} />
              </React.Fragment>
            ))}
        </IonList>
      </IonContent>
    </IonModal>
  );
};

export default React.memo(ProfileMenuModal);
