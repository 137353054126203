import isEqual from 'lodash/isEqual';
import { RootState } from '../index';
import { PostCategoryType } from '../posts/types';
import { Category } from './types';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const filterCategoriesByPostCategoryType = (categories: Category[], postCategoryType: PostCategoryType): Category[] => {
  return categories.filter(category => category.type === postCategoryType);
};

const getPostCategoryTypeFromProps = (state: RootState, props: { postCategoryType: PostCategoryType }): PostCategoryType => props.postCategoryType;

const getCategoriesFromState = (state: RootState): Category[] => state.categories.categories;
const getCategoriesFilteredByPostCategoryType = createSelector([getCategoriesFromState, getPostCategoryTypeFromProps], filterCategoriesByPostCategoryType);

export const getCategories = createDeepEqualSelector(getCategoriesFromState, (categories: Category[]) => categories);
export const getCategoriesByPostCategoryType = createDeepEqualSelector([getCategoriesFilteredByPostCategoryType], (categories: Category[]) => categories);
