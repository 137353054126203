import {
  IonButton,
  IonContent,
  IonFab,
  IonHeader,
  IonSearchbar,
  IonList,
  IonIcon,
  IonLabel,
  IonChip,
  IonActionSheet,
  IonInfiniteScrollContent,
  IonInfiniteScroll,
  IonPage,
  withIonLifeCycle,
  IonTitle,
  IonToolbar,
  IonBadge,
  SearchbarInputEventDetail,
} from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import BackButton from '../components/BackButton';
import SavedSearchOptionButtons from '../components/connected/SavedSearchOptionButtons';
import HomePagePlaceholder from '../components/placeholders/HomePagePlaceholder';
import { PostsRowPlaceholder } from '../components/placeholders/PostsPlaceholder';
import PostsGrid from '../components/PostsGrid';
import { actions, RootState, selectors } from '../store';
import { getUserArea } from '../store/app/selectors';
import { getCategoriesByPostCategoryType } from '../store/categories/selectors';
import { Address, FullPost, PostCategoryType, SavedSearch, SearchQueryParameters } from '../store/posts/types';
import { searchQueryKeys } from '../store/posts/variables';
import { countActiveSearchParams, isEqualIgnoringFunctions, replaceEntitiesByIds, replaceIdsByEntities, unfocusInput } from '../utils/helpers';
import { ItemsCollection } from '../utils/hydra';
import { Thematic } from '../store/thematics/types';
import ThematicTagItems from '../components/ThematicTagItems';
import ChangeLocationModal from '../components/ChangeLocationModal';
import SearchFilterModal from '../components/searchFilter/SearchFilterModal';
import CategoriesModal from '../components/CategoriesModal';
import { Category } from '../store/categories/types';
import { Location } from '../store/posts/types';
import { defaultSearchParams } from '../store/posts/reducer';
import SearchSuggestions from '../components/SearchSuggestions';
import { getSuggestionsThematics } from '../store/thematics/selectors';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { locationIsSearchTab } from '../utils/windowLocationHelper';
import { sendSearchLog } from '../utils/analytics/analyticsHelper';
import { getSearchResultCollection, getSearchParams, getSavedSearchList, getSavedSearchListIsLoading } from '../store/posts/selectors';
import { RouteComponentProps, withRouter } from 'react-router';
import set from 'lodash/set';
import { Area } from '../store/users/types';
import SavedSearchList from '../components/connected/SavedSearchList';

import './SearchPage.scss';
import SizesModal, { SizeData } from '../components/SizesModal';
import { getCategoryItemsType, getChildCategories } from '../utils/categoriesHelpers';
import { clientUrl } from '../environment';
import ShareModal from '../components/ShareModal';
import ShareChip from '../components/common/ShareChip';
import { translateDigits } from '../utils/translation';
import { IonSearchbarCustomEvent } from '@ionic/core';

interface StateProps {
  searchCollection: ItemsCollection<FullPost>;
  currentUserThematics: Thematic[];
  searchParams: SearchQueryParameters;
  objectCategories: Category[];
  serviceCategories: Category[];
  thematics: Thematic[];
  hasCurrentUser: boolean;
  userArea?: Area;
  savedSearchList: SavedSearch[] | undefined;
  savedSearchListIsLoading: boolean;
}

const mapStateToProps = (state: RootState): StateProps => ({
  searchCollection: getSearchResultCollection(state),
  currentUserThematics: selectors.thematics.filterThematicsByIds(state.thematics.thematics, state.app.currentUser.favoriteCategoryThematics),
  searchParams: getSearchParams(state),
  objectCategories: getCategoriesByPostCategoryType(state, { postCategoryType: 'object' }),
  serviceCategories: getCategoriesByPostCategoryType(state, { postCategoryType: 'service' }),
  thematics: state.thematics.thematics,
  hasCurrentUser: !!state.app.currentUser['@id'],
  userArea: getUserArea(state),
  savedSearchList: getSavedSearchList(state),
  savedSearchListIsLoading: getSavedSearchListIsLoading(state),
});

interface DispatchProps {
  fetchSearchPosts: (params: SearchQueryParameters, loadNextPage: boolean) => Promise<void>;
  resetPostCollection: (collectionName: 'search') => void;
  fetchThematics: () => void;
  setSearchParams: (param: SearchQueryParameters) => void;
  resetSearchParams: () => void;
  fetchCategories: () => Promise<void>;
  fetchSavedSearches: () => Promise<void>;
}

const propsToDispatch = {
  fetchSearchPosts: actions.posts.fetchSearchPosts,
  resetPostCollection: actions.posts.resetCollection,
  fetchThematics: actions.thematicsActions.fetchThematics,
  setSearchParams: actions.posts.setSearchParams,
  resetSearchParams: actions.posts.resetSearchParams,
  fetchCategories: actions.categoriesActions.fetchPostCategories,
  fetchSavedSearches: actions.posts.fetchSavedSearches,
};

interface SearchPageProps {
  lastRefreshDate: Date | undefined; // Last time the user clicked on the search tab button
}

interface State {
  showSearchButton: boolean;
  showActionSheet: boolean;
  filterModalIsOpen: boolean;
  addThematicsModalIsOpen: boolean;
  changeLocationModalIsOpen: boolean;
  categoryModalIsOpen: boolean;
  sizeModalIsOpen: boolean;
  oldSearchParams: SearchQueryParameters;
  categoryModalType: PostCategoryType;
  infiniteScrollIsActive: boolean;
  showSearchResult: boolean;
  showBackButton: boolean;
  categoryClicked: Category | undefined;
  shareUrl: string;
}

type LocationState = {
  from: Location;
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type PostsProps = StateProps & DispatchProps & SearchPageProps & WithTranslation & RouteComponentProps<Record<string, never>>;

class SearchPage extends Component<PostsProps, State> {
  private readonly ionContentRef = React.createRef<HTMLIonContentElement>();
  private readonly ionSearchBarRef: React.RefObject<HTMLIonSearchbarElement>;
  private readonly ionInfiniteScrollRef: React.RefObject<HTMLIonInfiniteScrollElement>;

  public constructor(props: PostsProps) {
    super(props);
    this.ionSearchBarRef = React.createRef<HTMLIonSearchbarElement>();
    this.ionInfiniteScrollRef = React.createRef<HTMLIonInfiniteScrollElement>();
    this.state = {
      showSearchButton: false,
      showActionSheet: false,
      filterModalIsOpen: false,
      addThematicsModalIsOpen: false,
      changeLocationModalIsOpen: false,
      categoryModalIsOpen: false,
      sizeModalIsOpen: false,
      oldSearchParams: defaultSearchParams,
      categoryModalType: 'object',
      infiniteScrollIsActive: false,
      showSearchResult: false,
      showBackButton: false,
      categoryClicked: undefined,
      shareUrl: '',
    };
  }

  public componentDidMount(): void {
    this.initSearch();

    // Fetch the savedSearchList
    if (this.props.hasCurrentUser) {
      this.props.fetchSavedSearches().catch(() => {
        // Prevent fetch error
      });
    }
  }

  private async initSearch(): Promise<void> {
    this.props.fetchThematics();
    // We need the categories in order to initialize the search with URL params
    await this.props.fetchCategories();

    const urlParams = new URLSearchParams(this.props.location?.search);
    this.handleSearchQueries(urlParams);
  }

  public componentDidUpdate(prevProps: Readonly<PostsProps>, prevState: Readonly<State>): void {
    // If the user quit the page and come back, there is no more search params, so we need to hide the backButton
    if (!this.props.location?.search) {
      this.setState({ showBackButton: false });
    }

    // Fetch the savedSearchList if the collection was reset
    if (this.props.hasCurrentUser && this.props.savedSearchList === undefined && !this.props.savedSearchListIsLoading) {
      this.props.fetchSavedSearches().catch(() => {
        // Prevent fetch error
      });
    }

    // If the user clicked twice on the search tab button, we cancel the current search
    if (this.props.lastRefreshDate !== prevProps.lastRefreshDate && this.state.showSearchResult) {
      this.cancelSearch();
      return;
    }

    // If the search params changed, we update search params
    if (this.props.location?.search && this.props.location?.search !== prevProps.location?.search) {
      const urlParams = new URLSearchParams(this.props.location?.search);
      this.handleSearchQueries(urlParams);
      return;
    }

    if ((!this.state.filterModalIsOpen && prevState.filterModalIsOpen && this.state.showSearchResult) || (!this.state.showActionSheet && prevState.showActionSheet)) {
      this.searchPosts();
    }
  }

  public shouldComponentUpdate(nextProps: Readonly<PostsProps>, nextState: Readonly<State>): boolean {
    if (!locationIsSearchTab()) {
      return false;
    }
    return !isEqualIgnoringFunctions(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  /** handleSearchQueries function
   *
   * It will set the redux searchParams object and send a request with those parameters.
   *
   * 1. If the URL contains a jsonSearch key, we decode it and use it as search params
   *
   * 2. The object searchQueryKeys will be used to be sure only accepted parameters are kept.
   *
   * Query params example:
   *
   * postType=offer&
   * dateOrder=desc&
   * searchText=textToSearch&
   * locationCenter[latitude]=48.826315&
   * locationCenter[longitude]=2.3307238&
   * locationRadius=9000&
   * locationAddress=Autourdemoi&
   * itemsPerPage=10
   ***/
  private handleSearchQueries = (urlParams: URLSearchParams): void => {
    // If no query params we don't search for posts.
    if (urlParams.toString() === '') {
      this.setState({ showBackButton: false });
      return;
    }

    let searchParamsObject: Partial<SearchQueryParameters> = {};

    // Handle ?jsonSearch={...}
    if (urlParams.has('jsonSearch')) {
      searchParamsObject = JSON.parse(urlParams.get('jsonSearch') || '');
      searchParamsObject = replaceIdsByEntities(searchParamsObject, [...this.props.objectCategories, ...this.props.serviceCategories]) as Partial<SearchQueryParameters>;
    }

    // Handle postType=offer&...
    urlParams.forEach((value: string, key: string) => {
      if (!searchQueryKeys.includes(key)) {
        return;
      }
      if (key === 'locationAddress') {
        set(searchParamsObject, key, { formatted: value });
        return;
      }

      set(searchParamsObject, key, value);
    });

    this.searchPosts({ ...this.props.searchParams, ...searchParamsObject });
    this.props.setSearchParams({ ...this.props.searchParams, ...searchParamsObject });
    this.setState({ showBackButton: true });
  };

  private generateSearchUrl = (params: SearchQueryParameters): string => {
    // Replace entities by their IRI
    const cleanParams = replaceEntitiesByIds(params);

    return clientUrl + '/search?jsonSearch=' + encodeURIComponent(JSON.stringify(cleanParams));
  };

  private onShareClick = (searchFilters?: SearchQueryParameters | undefined): void => {
    const shareUrl = this.generateSearchUrl(searchFilters || this.props.searchParams);

    this.setState({ shareUrl });
  };

  public render(): ReactNode {
    const { showSearchButton, sizeModalIsOpen, shareUrl } = this.state;
    const { t, searchParams, currentUserThematics, thematics, hasCurrentUser } = this.props;
    const actionSheetButtons = [
      {
        text: t('search.sort-by-desc'),
        handler: () => this.updateSearchParams({ dateOrder: 'desc' }),
      },
      {
        text: t('search.sort-by-asc'),
        handler: () => this.updateSearchParams({ dateOrder: 'asc' }),
      },
      {
        text: t('common.cancel'),
        role: 'cancel',
      },
    ];

    const searchResult = this.props.searchCollection;
    const showSearchResults = searchResult.lastFetchDate !== undefined && this.state.showSearchResult;
    const stateHistory = this.props.location.state as LocationState;
    const previousPosition = locationIsSearchTab() && stateHistory?.from;

    return (
      <IonPage className="search-page" data-cy="search-page">
        <IonHeader>
          <IonToolbar mode="md" className="header-toolbar">
            {this.state.showBackButton && !!previousPosition && <BackButton dataCy="search-page-back-button" />}
            <IonTitle>
              <Trans i18nKey="search.search-page-title" />
            </IonTitle>
            {showSearchResults && (
              <IonButton className="cancel-button" fill="clear" size="small" onClick={this.cancelSearch}>
                <Trans i18nKey="common.cancel" />
              </IonButton>
            )}
          </IonToolbar>

          <div className="search-bar-wrapper">
            <IonSearchbar
              searchIcon="/assets/form/search.svg"
              ref={this.ionSearchBarRef}
              className="search-bar"
              value={searchParams.searchText}
              placeholder={t('common.search')}
              onIonInput={this.handleInputEvent}
              onIonFocus={this.handleSearchBarFocus}
              onIonClear={this.focusSearchBar}
              onKeyPress={this.handleKeyboardClick}
              spellcheck={true}
              autocorrect="on"
              autocomplete="on"
              enterkeyhint="search"
            />
            {!searchParams.searchText?.length && (
              <IonChip className="filter-button" data-cy="filter-button" onClick={this.triggerFilterModal}>
                <Trans i18nKey="search.filters" />
              </IonChip>
            )}
          </div>
          {showSearchResults && (
            <div className="filters-list-content">
              <IonList mode="md" className="filters-list">
                {hasCurrentUser && !(searchResult.isLoading && !this.state.filterModalIsOpen) && <SavedSearchOptionButtons onShareClick={this.onShareClick} />}
                <ShareChip onShareClick={this.onShareClick} />
                <IonChip outline onClick={this.triggerFilterModal} className="filter-button">
                  <IonLabel>
                    <Trans i18nKey="search.filters" />
                  </IonLabel>
                  <IonBadge className="badge" data-cy="count-filters-badge">
                    {translateDigits(countActiveSearchParams(this.props.searchParams))}
                  </IonBadge>
                </IonChip>
                <IonChip className="text-overflow-chip" outline onClick={() => this.triggerChangeLocationModal(true)}>
                  <div className="text-overflow-chip-content">
                    <IonIcon icon="/assets/form/locate.svg" color="dark" />
                    <IonLabel>{searchParams.locationAddress?.formatted || t('search.location')}</IonLabel>
                  </div>
                </IonChip>
                <IonChip outline onClick={() => this.toggleActionSheet()}>
                  <IonLabel>
                    <Trans i18nKey={'search.sort-by-' + searchParams.dateOrder} />
                  </IonLabel>
                </IonChip>
              </IonList>
            </div>
          )}
        </IonHeader>

        <IonContent ref={this.ionContentRef} data-cy="search-page-content" scrollEvents onTouchMove={this.handleSearchContentTouchMove}>
          {showSearchResults &&
            (searchResult.isLoading && !this.state.filterModalIsOpen ? (
              <HomePagePlaceholder />
            ) : (
              <>
                <PostsGrid
                  posts={searchResult}
                  isLoading={searchResult.isLoading}
                  userPosition={this.props.searchParams.locationCenter ?? this.props.userArea?.location}
                  maxRadiusCardIndex={undefined}
                  showSocialCards={false}
                  showSuggestions={false}
                />
                {this.state.infiniteScrollIsActive && (
                  <IonInfiniteScroll ref={this.ionInfiniteScrollRef} onIonInfinite={() => this.searchPosts(this.props.searchParams, true)} threshold="600px" disabled={!searchResult?.nextPage}>
                    <IonInfiniteScrollContent loading-spinner="circle" loading-text={this.props.t('common.loading')}>
                      <PostsRowPlaceholder />
                    </IonInfiniteScrollContent>
                  </IonInfiniteScroll>
                )}
              </>
            ))}
          {!showSearchResults && hasCurrentUser && (
            <>
              <SavedSearchList onSavedSearchClick={this.searchPosts} onShareClick={this.onShareClick} />
              <ThematicTagItems thematics={currentUserThematics} />
              <SearchSuggestions userThematics={getSuggestionsThematics(thematics, currentUserThematics)} />
            </>
          )}

          {showSearchButton && (
            <IonFab vertical="bottom" horizontal="start" slot="fixed" className="search-fab">
              <IonButton data-cy="search-page-submit-button" disabled={searchResult.isLoading} onClick={() => this.searchPosts(searchParams, false)} expand="full">
                <Trans i18nKey="common.search" />
              </IonButton>
            </IonFab>
          )}
        </IonContent>

        <IonActionSheet cssClass={'date-sort-selection'} isOpen={this.state.showActionSheet} onDidDismiss={() => this.toggleActionSheet()} header={t('search.sort-by')} buttons={actionSheetButtons} />
        <SearchFilterModal
          searchParams={searchParams}
          isOpen={this.state.filterModalIsOpen}
          onClose={() => this.closeSearchFilterModal(false)}
          onSubmit={this.closeSearchFilterModal}
          onLocationClick={() => this.setState({ changeLocationModalIsOpen: true })}
          onCategoryClick={(categoryType, category) => {
            this.setState({ categoryModalIsOpen: true, categoryModalType: categoryType, categoryClicked: category });
          }}
          onSizeClick={() => this.setState({ sizeModalIsOpen: true })}
          categories={{ object: this.props.objectCategories, service: this.props.serviceCategories }}
          setSearchParams={this.updateSearchParams}
        />
        <ChangeLocationModal
          isOpen={this.state.changeLocationModalIsOpen}
          closeModal={() => this.triggerChangeLocationModal(false)}
          onLocationValidation={this.locationChanged}
          initialLocation={{
            address: this.props.searchParams.locationAddress,
            location: this.props.searchParams.locationCenter,
            radius: this.props.searchParams.locationRadius,
          }}
          changeRadius={true}
        />
        <CategoriesModal
          setNewCategory={category => this.closeCategoryModal(category, this.state.categoryModalType)}
          categoryClicked={this.state.categoryClicked}
          categories={this.state.categoryModalType === 'object' ? this.props.objectCategories : this.props.serviceCategories}
          isCategoryModalOpen={this.state.categoryModalIsOpen}
          closeCategoriesModal={() => this.setState({ categoryModalIsOpen: false })}
        />
        <SizesModal
          onSelectionSubmit={(newSize?: SizeData) => {
            this.closeSizeModal(newSize);
          }}
          isOpen={sizeModalIsOpen}
          onCloseModal={() => {
            this.setState({ sizeModalIsOpen: false });
          }}
          categoryItemsType={getCategoryItemsType(
            this.props.objectCategories,
            this.props.searchParams.categorySelection.object.category ? this.props.searchParams.categorySelection.object.category : null,
          )}
          defaultUniverse={null}
        />
        <ShareModal
          closeModal={() => {
            this.setState({ shareUrl: '' });
          }}
          isOpen={shareUrl !== ''}
          shareUrl={shareUrl}
          shareMessage={this.props.t('search.share-message')}
          shareTitle={this.props.t('search.share-title')}
        />
      </IonPage>
    );
  }

  private handleSearchContentTouchMove = (): void => {
    unfocusInput(this.ionSearchBarRef);
  };

  private handleSearchBarFocus = (): void => {
    this.setState({ showSearchButton: true, infiniteScrollIsActive: false });
  };

  private toggleActionSheet = (): void => {
    this.setState({ showActionSheet: !this.state.showActionSheet });
  };

  private locationChanged = (locationAddress: Address | null, locationCenter: Location | undefined, locationRadius?: number): void => {
    this.updateSearchParams({ locationCenter, locationRadius, locationAddress });
  };

  private triggerChangeLocationModal = (value: boolean): void => {
    this.setState({ changeLocationModalIsOpen: value });
    if (!value && !this.state.filterModalIsOpen) {
      this.searchPosts();
    }
  };

  private triggerFilterModal = (): void => {
    this.setState({ filterModalIsOpen: true, oldSearchParams: this.props.searchParams, infiniteScrollIsActive: false });
  };

  private closeCategoryModal = (newCategory: Category | undefined, categoryType: PostCategoryType): void => {
    const categorySelection = cloneDeep(this.props.searchParams.categorySelection);
    // if categoryType is service, set searchParams size else set searchParams size only if categorySelection itemsType equal to newCategory itemsType
    const size = categoryType === 'service' ? this.props.searchParams.size : categorySelection[categoryType].category?.itemsType === newCategory?.itemsType ? this.props.searchParams.size : undefined;
    const universe =
      categoryType === 'service' ? this.props.searchParams.universe : categorySelection[categoryType].category?.itemsType === newCategory?.itemsType ? this.props.searchParams.universe : undefined;
    categorySelection[categoryType].category = newCategory;
    this.updateSearchParams({
      categorySelection,
      size,
      universe,
      childCategories: newCategory ? getChildCategories(this.state.categoryModalType === 'object' ? this.props.objectCategories : this.props.serviceCategories, newCategory) : undefined,
    });
  };

  private closeSizeModal = (newSize: SizeData | undefined): void => {
    this.updateSearchParams({ size: newSize?.size, universe: newSize?.universe });
  };

  private closeSearchFilterModal = (search = true): void => {
    if (!this.state.filterModalIsOpen) {
      return;
    }

    const showSearchResult = search || !isEqual(this.state.oldSearchParams, defaultSearchParams);
    this.setState({ filterModalIsOpen: false, infiniteScrollIsActive: true, showSearchResult });
  };

  private focusSearchBar = (): void => {
    if (!this.ionSearchBarRef.current) {
      return;
    }

    this.ionSearchBarRef.current.setFocus();
  };

  private cancelSearch = (): void => {
    this.props.resetSearchParams();
    unfocusInput(this.ionSearchBarRef);
    this.props.resetPostCollection('search');
    this.setState({ showSearchButton: false, oldSearchParams: defaultSearchParams, showSearchResult: false });
  };

  private handleKeyboardClick = (e: React.KeyboardEvent<HTMLIonSearchbarElement>): void => {
    if (e.type === 'keypress' && (e as React.KeyboardEvent<HTMLIonSearchbarElement>).key !== 'Enter') {
      return;
    }

    unfocusInput(this.ionSearchBarRef);
    this.searchPosts();
  };

  private handleInputEvent = (e: IonSearchbarCustomEvent<SearchbarInputEventDetail>): void => {
    this.updateSearchParams({ searchText: e.detail.value ?? '' });
    this.setState({ infiniteScrollIsActive: false });
  };

  private updateSearchParams = (params: Partial<SearchQueryParameters>): void => {
    this.props.setSearchParams({ ...this.props.searchParams, ...params });
  };

  private searchPosts = (searchParams: SearchQueryParameters = this.props.searchParams, loadNextPage = false, showSearchResults = true): void => {
    this.props.fetchSearchPosts(searchParams, loadNextPage).then(() => this.ionInfiniteScrollRef.current?.complete());
    this.setState({ showSearchButton: false, infiniteScrollIsActive: true, showSearchResult: showSearchResults }, () => {
      sendSearchLog(searchParams);
    });
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(withIonLifeCycle(SearchPage))));
