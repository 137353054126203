import { TextareaChangeEventDetail } from '@ionic/core';
import { IonButton, IonContent, IonFooter, IonIcon, IonLabel, IonModal, IonThumbnail } from '@ionic/react';
import i18next from 'i18next';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { PostType } from '../store/posts/types';
import { User } from '../store/users/types';
import { getUserAvatar } from '../utils/helpers';
import ImageFadeIn from './ImageFadeIn';
import MessageInput from './inputs/MessageInput';

import './PersonalizedMessageModal.scss';

interface PremadeMessageButtonProps {
  label: string;
  onClick: (message: string) => void;
}

const PremadeMessageButton: React.FunctionComponent<PremadeMessageButtonProps> = ({ label, onClick }: PremadeMessageButtonProps) => {
  return (
    <IonButton className="premade-message-button" fill="outline" onClick={() => onClick(label)}>
      {label}
    </IonButton>
  );
};

interface PersonalizedMessageModalProps {
  isOpen: boolean;
  postType?: PostType;
  messageReceiver?: User;
  onCloseModal: () => void;
  onMessageSelection: (message: string) => void;
}

const premadeMessages: { [key: string]: string[] } = {
  offer: ['interesting-post', 'is-post-available', 'is-available-for-exchange'],
  need: ['i-can-help', 'i-am-available', 'when-are-you-available'],
};

const PersonalizedMessageModal: React.FunctionComponent<PersonalizedMessageModalProps> = ({ isOpen, postType, messageReceiver, onCloseModal, onMessageSelection }: PersonalizedMessageModalProps) => {
  const [newMessage, setNewMessage] = useState('');
  const [showPremadeMessages, setShowPremadeMessages] = useState(true);

  const onPremadeButtonClick = (message: string): void => {
    setNewMessage(message);
  };

  return (
    <IonModal
      isOpen={isOpen}
      onDidDismiss={onCloseModal}
      className={`personalized-message-modal safe-area-ios--no-padding-top ${!showPremadeMessages ? 'personalized-message-modal-small' : ''}`}
      data-cy="personalized-message-modal"
    >
      <IonContent>
        <IonIcon className="close-modal-button" icon={'/assets/icon/icon-close-modal.svg'} onClick={onCloseModal} />
        {messageReceiver && (
          <div className="user-info">
            <div className="user-avatar-wrapper">
              <IonThumbnail className="user-avatar">
                <ImageFadeIn thumbnail src={getUserAvatar(messageReceiver)} />
              </IonThumbnail>
              <IonIcon className="message-icon" src="/assets/icon/icon-message.svg" color="primary" />
            </div>
            <IonLabel>
              <Trans i18nKey={'post-request.send-your-first-message-to'} values={{ username: messageReceiver.name }} />
            </IonLabel>
          </div>
        )}
        {postType && showPremadeMessages && (
          <div className="premade-message-list">
            {premadeMessages[postType].map(message => (
              <PremadeMessageButton key={message} label={i18next.t(`post-request.premade-message.${message}`)} onClick={onPremadeButtonClick} />
            ))}
          </div>
        )}
      </IonContent>
      <IonFooter>
        <MessageInput
          message={newMessage}
          placeholder={i18next.t('post-request.personalized-message-placeholder')}
          disableSendButton={!newMessage}
          sendMessage={() => onMessageSelection(newMessage)}
          onChange={(e: CustomEvent<TextareaChangeEventDetail>) => setNewMessage(e.detail.value || '')}
          onFocus={() => setShowPremadeMessages(false)}
          onBlur={() => setShowPremadeMessages(true)}
        />
      </IonFooter>{' '}
    </IonModal>
  );
};

export default PersonalizedMessageModal;
