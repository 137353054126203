import React, { Component, ReactNode, useState } from 'react';
import { IonModal, IonButton, IonToolbar, IonTitle, IonHeader, IonContent, IonList, IonItem, IonLabel, IonButtons, IonFooter, IonIcon, IonPopover, IonImg } from '@ionic/react';
import { Trans } from 'react-i18next';
import { EntityReference } from '../utils/hydra';
import { PostCategoryType, PostState, postStateCanBeFinished, postTransitionDelete, postTransitionFinish, PostType } from '../store/posts/types';

import './DeletePostModal.scss';
import '../theme/utils.scss';

type deleteOptions = {
  [key: string]: string;
};

interface State {
  selectedOption?: string | null;
  deleteOptions: deleteOptions;
  popoverGiveOnIndigoOpen: boolean;
}

interface ModalProps {
  isOpen: boolean;
  postTypeCategory: PostCategoryType;
  postType: PostType;
  closeModal: () => void;
  updatePostState: (transition: string, reason?: string | undefined | null) => void;
  postId: EntityReference;
  postState: PostState;
}

const deleteOfferObjectOptions: deleteOptions = {
  object_given_on_indigo: 'offer.object-given-on-indigo',
  object_given_on_another_platform: 'offer.object-given-on-another-platform',
  no_user_interest: 'no-user-interest',
  changed_mind: 'changed-mind',
  object_sold: 'offer.object-sold',
  other: 'other',
};

const deleteOfferServiceOptions: deleteOptions = {
  service_given_on_indigo: 'offer.service-given-on-indigo',
  service_given_on_another_platform: 'offer.service-given-on-another-platform',
  no_longer_relevant: 'no-longer-relevant',
  no_user_interest: 'no-user-interest',
  changed_mind: 'changed-mind',
  other: 'other',
};

const deleteNeedObjectOptions: deleteOptions = {
  no_user_interest: 'no-user-interest',
  changed_mind: 'changed-mind',
  object_found_on_indigo: 'need.object-found-on-indigo',
  object_found_another_platform: 'need.object-found-another-platform',
  object_bought_another_platform: 'need.object-bought-another-platform',
  other: 'other',
};

const deleteNeedServiceOptions: deleteOptions = {
  no_longer_relevant: 'no-longer-relevant',
  no_user_interest: 'no-user-interest',
  changed_mind: 'changed-mind',
  service_found_on_indigo: 'need.service-found-on-indigo',
  service_found_another_platform: 'need.service-found-another-platform',
  other: 'other',
};

interface popoverGiveOnIndigoProps {
  isOpen: boolean;
  closeModal: () => void;
  setPostStateFinish: () => void;
  postId: EntityReference;
}

export const PopoverGiveOnIndigo: React.FunctionComponent<popoverGiveOnIndigoProps> = ({ isOpen, closeModal, postId, setPostStateFinish }: popoverGiveOnIndigoProps) => {
  const [haveChat, setHaveChat] = useState(false);

  const closeAndResetModal = (): void => {
    setHaveChat(false);
    closeModal();
  };

  return (
    <IonPopover isOpen={isOpen} onDidDismiss={haveChat ? closeAndResetModal : closeModal} className="give-on-indigo safe-area-ios">
      <div className="header">
        {haveChat ? (
          <>
            <IonButton className="close-icon-button" onClick={closeAndResetModal} fill="clear" size="small" data-cy="close-icon-button">
              <IonIcon icon="/assets/navigation/close.svg" className="close-icon" slot="icon-only" />
            </IonButton>
            <IonImg src="/assets/phone-yellow-key.svg" />
            <h6>
              <Trans i18nKey="post.delete-reasons.give-on-indigo.complete-your-exchange" />
            </h6>
            <div className="additional-text">
              <Trans i18nKey="post.delete-reasons.give-on-indigo.find-the-person" />
            </div>
            {/*use an empty button to fix a shadow-root issue*/}
            <IonButton className="display-none" />
            <IonButton className="button-small" data-cy="button-redirect" color="primary" shape="round" expand="block" size="large" routerLink={`/chats?postId=${postId}`} onClick={closeModal}>
              <Trans i18nKey="common.see-conversations" />
            </IonButton>
          </>
        ) : (
          <>
            <IonButton className="close-icon-button" onClick={closeModal} fill="clear" size="small" data-cy="close-icon-button">
              <IonIcon icon="/assets/navigation/close.svg" className="close-icon" slot="icon-only" />
            </IonButton>
            <IonImg src="/assets/phone-blue-key.svg" />
            <h6>
              <Trans i18nKey="post.delete-reasons.give-on-indigo.do-you-have-conversation" />
            </h6>
            <IonButton className="button-small" data-cy="button-yes" color="primary" type="submit" shape="round" expand="block" size="large" onClick={() => setHaveChat(true)}>
              <Trans i18nKey="common.yes" />
            </IonButton>
            <IonButton className="button-small" data-cy="button-no" color="primary" type="submit" shape="round" fill="outline" expand="block" size="large" onClick={setPostStateFinish}>
              <Trans i18nKey="common.no" />
            </IonButton>
          </>
        )}
      </div>
    </IonPopover>
  );
};

interface CheckOptionProps {
  optionKey: string;
  onClick: () => void;
  transKey: string;
  checked: boolean;
}

const CheckOption: React.FC<CheckOptionProps> = ({ optionKey, onClick, transKey, checked }) => (
  <IonItem className="option-wrapper" lines="none" onClick={onClick}>
    <IonLabel text-wrap={false}>
      <Trans i18nKey={`post.delete-reasons.${transKey}`} />
      {(optionKey === 'object_given_on_indigo' || optionKey === 'service_given_on_indigo') && (
        <div className="additional-text">
          <Trans i18nKey="post.delete-reasons.give-on-indigo.environmental-impact" />
        </div>
      )}
    </IonLabel>
    <div className={`option-checkbox ${checked ? 'option-checkbox-checked' : ''}`}>
      <svg className="checkbox-icon" viewBox="0 0 24 24">
        <path d="M5.9,12.5l3.8,3.8l8.8-8.8"></path>
      </svg>
    </div>
  </IonItem>
);

class DeletePostModalContent extends Component<ModalProps, State> {
  constructor(props: ModalProps) {
    super(props);
    this.state = {
      selectedOption: null,
      deleteOptions: {},
      popoverGiveOnIndigoOpen: false,
    };
  }

  public componentDidMount(): void {
    if (this.props.postType === 'need') {
      this.setState({ deleteOptions: this.props.postTypeCategory === 'object' ? deleteNeedObjectOptions : deleteNeedServiceOptions });
    } else {
      this.setState({ deleteOptions: this.props.postTypeCategory === 'object' ? deleteOfferObjectOptions : deleteOfferServiceOptions });
    }
  }

  private onBackButtonClick = (): void => {
    this.props.closeModal();
  };

  public onDeleteClick = (): void => {
    if ((this.state.selectedOption === 'object_given_on_indigo' || this.state.selectedOption === 'service_given_on_indigo') && postStateCanBeFinished.includes(this.props.postState)) {
      this.setState({ popoverGiveOnIndigoOpen: true });
      return;
    }

    this.props.updatePostState(postTransitionDelete, this.state.selectedOption);
  };

  public setPostStateFinish = (): void => {
    this.setState({ popoverGiveOnIndigoOpen: false }, () => {
      this.props.updatePostState(postTransitionFinish);
    });
  };

  public closePopoverGiveOnIndigo = (): void => {
    this.setState({ popoverGiveOnIndigoOpen: false });
  };

  public initDeleteOptionsList = (): ReactNode =>
    Object.keys(this.state.deleteOptions).map((key, i) => {
      return <CheckOption key={key + i} optionKey={key} onClick={() => this.setState({ selectedOption: key })} checked={this.state.selectedOption === key} transKey={this.state.deleteOptions[key]} />;
    });

  public render(): ReactNode {
    return (
      <>
        <IonHeader translucent>
          <IonToolbar className="header">
            <IonTitle className="title">
              <Trans i18nKey="post.delete-post-title" />
            </IonTitle>
            <IonButtons slot="end">
              <IonButton className="cancel-btn" fill="clear" onClick={this.onBackButtonClick}>
                <IonIcon icon="/assets/navigation/close.svg" className="close-icon" slot="icon-only" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonList>{this.initDeleteOptionsList()}</IonList>
        </IonContent>
        <IonFooter className="footer">
          <IonButton onClick={this.onDeleteClick} disabled={!this.state.selectedOption} className="delete-btn">
            <Trans i18nKey="post.confirm-delete" />
          </IonButton>
        </IonFooter>
        {this.state.selectedOption && (this.state.selectedOption === 'object_given_on_indigo' || this.state.selectedOption === 'service_given_on_indigo') && (
          <PopoverGiveOnIndigo isOpen={this.state.popoverGiveOnIndigoOpen} setPostStateFinish={this.setPostStateFinish} closeModal={this.closePopoverGiveOnIndigo} postId={this.props.postId} />
        )}
      </>
    );
  }
}

const DeletePostModal: React.FunctionComponent<ModalProps> = (props: ModalProps) => {
  // Using a dedicated Modal component in order to reset the content each time the modal is closed
  return (
    <IonModal isOpen={props.isOpen} onDidDismiss={props.closeModal} className="delete-post-modal safe-area-ios">
      <DeletePostModalContent {...props} />
    </IonModal>
  );
};

export default DeletePostModal;
