import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import get from 'lodash/get';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { actions, RootState } from '../../store';
import { getCurrentUser } from '../../store/app/selectors';
import { CurrentUser, CurrentUserEditFields } from '../../store/users/types';
import { extractId } from '../../utils/helpers';
import { localesLabels } from '../../i18n';

import './ChangeLanguageSelectMenuItem.scss';

import '../../pages/ProfileMenuItemComponentPage.scss';

interface StateProps {
  currentUser: CurrentUser;
}

const mapStateToProps = (state: RootState): StateProps => ({
  currentUser: getCurrentUser(state),
});

interface DispatchProps {
  setAppLanguage: (lng: string) => void;
  updateUser(user: Partial<CurrentUserEditFields>): Promise<CurrentUser>;
}

const propsToDispatch = {
  setAppLanguage: actions.layout.setAppLanguageAction,
  updateUser: actions.app.updateUser,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type Props = StateProps & DispatchProps & WithTranslation;

const ChangeLanguageSelectMenuItem: React.FunctionComponent<Props> = ({ setAppLanguage, currentUser, updateUser, t }: Props) => {
  const updateUserLocale = async (locale: string): Promise<CurrentUser> => {
    return await updateUser({ '@id': extractId(currentUser), locale });
  };

  const changeAppLanguage = (e: CustomEvent | undefined): void => {
    const value = get(e, 'target.value');
    if (value && value !== currentUser.locale) {
      updateUserLocale(value).then(val => setAppLanguage(val.locale));
    }
  };

  return (
    <IonItem className="language-holder" data-cy="language-item">
      <IonLabel>{t('profile-menu.language')}</IonLabel>
      <IonSelect className="language" onIonChange={changeAppLanguage} autoCapitalize="on" multiple={false} value={currentUser.locale} interface="alert">
        {localesLabels.map((object, i) => (
          <IonSelectOption key={i} value={object.value}>
            {object.label}
          </IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ChangeLanguageSelectMenuItem));
