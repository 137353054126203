import { Dispatch } from 'redux';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { fetchWithAuthIfPossible } from '../../utils/authDataAccess';
import { addParametersToUrl } from '../../utils/dataAccess';
import { HydraCollectionResponse } from '../../utils/hydra';
import { ThunkResult } from '../types';
import { User } from '../users/types';
import { AskForGeolocationParameters, GeolocationData, ManagedUserInfos, UpgradeLayoutStatus } from './types';
import { Address } from '../posts/types';

export const setToastMessageAction = createAction('layout/SET_TOAST_MESSAGE', (message: string) => message)();

export const unsetToastMessageAction = createAction('layout/UNSET_TOAST_MESSAGE', (index: string) => index)();

export const setAppLanguageAction = createAction('layout/SET_APP_LANGUAGE', (lng: string | null) => lng)();

export const setGeolocationDataAction = createAction('layout/SET_GEOLOCATION_DATA_ACTION', (geolocationData: GeolocationData) => geolocationData)();

export const askForGeolocationDataAction = createAction('layout/ASK_FOR_GEOLOCATION_DATA_ACTION', (askForGeolocationParameters?: AskForGeolocationParameters) => askForGeolocationParameters)();

export const setUpgradeLayoutStatusAction = createAction('layout/SET_UPGRADE_LAYOUT_STATUS', (upgradeLayoutStatus: UpgradeLayoutStatus) => upgradeLayoutStatus)();

export const fetchManagedUserInfosAction = createAsyncAction(
  'layout/GET_MANAGED_USER_WELCOMING_INFOS_REQUEST',
  'layout/GET_MANAGED_USER_WELCOMING_INFOS_SUCCESS',
  'layout/GET_MANAGED_USER_WELCOMING_INFOS_FAILURE',
)<void, ManagedUserInfos | undefined, Error>();

export const setLastActionUpdatingChatsListDate = createAction(
  'layout/SET_LAST_ACTION_UPDATING_CHATS_LIST_DATE',
  (lastActionUpdatingChatsListDate: Date | undefined) => lastActionUpdatingChatsListDate,
)();

export function fetchManagedUserInfos(address: Address): ThunkResult<Promise<ManagedUserInfos | undefined>> {
  return async (dispatch: Dispatch): Promise<ManagedUserInfos | undefined> => {
    dispatch(fetchManagedUserInfosAction.request());

    const { postalCode, country, locality } = address;
    const url = addParametersToUrl('/users/public_sector', { postalCode, country, locality });

    return fetchWithAuthIfPossible(url, {
      method: 'GET',
    })
      .then(response => response.json() as Promise<HydraCollectionResponse<User>>)
      .then(data => {
        const publicSectorData = data?.['hydra:member']?.[0]?.managedData?.publicSectorData;
        const managedUserInfos = publicSectorData ? { ...publicSectorData, welcomeFrom: data['hydra:member'][0]?.name, managedDataId: data['hydra:member'][0].managedData?.id } : undefined;
        dispatch(fetchManagedUserInfosAction.success(managedUserInfos));
        return managedUserInfos;
      })
      .catch((e: Error) => {
        dispatch(fetchManagedUserInfosAction.failure(e));
        // Hide error
        return undefined;
      });
  };
}
