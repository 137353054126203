import { FirebaseCrashlytics } from '@capacitor-community/firebase-crashlytics';
import { RecordExceptionOptions } from '@capacitor-community/firebase-crashlytics';

export const setFirebaseCrashlyticsUserId = async (userId: string): Promise<void> => {
  return FirebaseCrashlytics.setUserId({ userId });
};

export const recordFirebaseCrashlyticsException = async (options: RecordExceptionOptions): Promise<void> => {
  return FirebaseCrashlytics.recordException(options);
};

export const setFirebaseCrashlyticsEnabled = async (enabled: boolean): Promise<void> => {
  return FirebaseCrashlytics.setEnabled({ enabled });
};
