import React, { useState } from 'react';
import { IonButton, IonIcon, IonInput, IonItem, IonLabel, IonList, IonSelect, IonSelectOption, SelectChangeEventDetail } from '@ionic/react';
import { IconComponent } from '../common/Icon';
import { isSameHydraEntity } from '../../utils/helpers';
import { Category } from '../../store/categories/types';
import { Trans } from 'react-i18next';
import { getItemTranslation } from '../../utils/translation';
import { CreatedByManagedDataType, PostCategoryType } from '../../store/posts/types';
import { getCategoryParents } from '../../utils/categoriesHelpers';
import { organizationTypeNgo, organizationTypePublicSector, organizationTypePublicStructure, organizationTypeReuseOrganization } from '../../store/users/types';
import { TFunction } from 'i18next';

interface PostTypeComponentProps {
  label: string;
  isSelected: boolean;
  onClick: () => void;
  shape?: 'round';
}

interface SearchFilterItemProps {
  icon?: string;
  title: string;
  value: string | undefined;
  onClick: () => void;
  dataCy?: string;
  onCancel?: (e: React.MouseEvent<HTMLIonIconElement>) => void;
}

interface SearchByManagedItemProps {
  onChange: (value: CreatedByManagedDataType) => void;
  t: TFunction;
  value: CreatedByManagedDataType | undefined;
}

interface SearchCategoryFilterItemProps {
  categories: Category[];
  category: Category;
  categoryType: PostCategoryType;
  onClick: (categoryType: PostCategoryType, category?: Category) => void;
  onCancel: (categoryType: PostCategoryType, category: Category) => void;
  title: string;
}

const manageDataType: CreatedByManagedDataType[] = [
  {
    value: 'all',
    name: 'all',
    type: '',
  },
  {
    name: 'particular',
    value: 'particular',
    type: 'all',
  },
  {
    value: organizationTypeNgo,
    name: 'ngo',
    type: 'all',
  },
  {
    value: organizationTypeNgo,
    name: 'ngo-certified',
    type: 'certified',
  },
  {
    name: 'public-structure-certified',
    value: organizationTypePublicStructure,
    type: 'certified',
  },
  {
    name: 'reuse-organization-certified',
    value: organizationTypeReuseOrganization,
    type: 'certified',
  },
  {
    name: 'public-sector-certified',
    value: organizationTypePublicSector,
    type: 'certified',
  },
];

export const PostTypeComponent: React.FunctionComponent<PostTypeComponentProps> = ({ label, isSelected, onClick, shape }: PostTypeComponentProps) => {
  return (
    <IonButton className={'post-type-component ' + (isSelected ? 'selected-post-type' : '')} onClick={onClick} fill="clear">
      <IonIcon
        icon={
          shape
            ? isSelected
              ? 'assets/form/checkmark-circle-sharp.svg'
              : 'assets/form/ellipse_outline.svg'
            : isSelected
            ? 'assets/form/square_selected_outline.svg'
            : 'assets/form/square_outline.svg'
        }
        mode="md"
        className="post-type-icon"
      />
      <IonLabel>{label}</IonLabel>
    </IonButton>
  );
};

export const SearchFilterItem: React.FunctionComponent<SearchFilterItemProps> = ({ icon, title, value, onClick, onCancel, dataCy }: SearchFilterItemProps) => {
  return (
    <IonItem button detail={false} onClick={onClick} data-cy={dataCy}>
      {value && <IonIcon className="custom-item-detail custom-item-close-circle" icon="/assets/form/close-circle.svg" onClick={onCancel} />}
      {!value && <IonIcon className="custom-item-detail" icon="/assets/navigation/chevron-forward.svg" />}
      <IonLabel position="floating" color="primary">
        {title}
      </IonLabel>
      <div className="search-filter-category-selection">
        {icon && <IconComponent icon={icon} color="var(--ion-color-facebook)" />}
        <IonInput data-cy="input" className={icon ? 'with-icon' : ''} hidden={!value} value={value} readonly />
      </div>
    </IonItem>
  );
};

export const SearchByManagedItem: React.FunctionComponent<SearchByManagedItemProps> = ({ onChange, t, value }: SearchByManagedItemProps) => {
  const [selectedValue, setSelectedValue] = useState(value ? value : manageDataType.find(val => val.name === 'all'));

  const onSelectChange = (e: CustomEvent<SelectChangeEventDetail>): void => {
    const value: string = e.detail.value;
    const objectManageDataType = manageDataType.find(val => val.name === value);
    if (!objectManageDataType) {
      return;
    }

    onChange(objectManageDataType);
    setSelectedValue(objectManageDataType);
  };

  return (
    <IonItem>
      <IonLabel position="floating">{t('search.type-of-account')}</IonLabel>
      <IonSelect value={selectedValue?.name} onIonChange={e => onSelectChange(e)} data-cy="search-by-managed">
        {manageDataType.map(val => (
          <IonSelectOption key={val.name} value={val.name}>
            {t(`user.${val.name}`)}
          </IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  );
};

export const SearchCategorySelectedFilterItem: React.FunctionComponent<SearchCategoryFilterItemProps> = ({
  categoryType,
  categories,
  category,
  onClick,
  onCancel,
  title,
}: SearchCategoryFilterItemProps) => {
  const categoryParents = getCategoryParents(categories, category).reverse();
  return (
    <IonList className="list-category-selected" data-cy="selected-categories-list">
      <IonItem className="selected-title" mode="md" lines="none">
        <div className="selected-title-text">
          <Trans i18nKey={title} />
        </div>
      </IonItem>
      {categoryParents.map((category: Category, index) => (
        <div key={index}>
          <IonItem
            button
            detail={false}
            onClick={() =>
              onClick(
                categoryType,
                categories.find(cat => isSameHydraEntity(cat, category.parent)),
              )
            }
            mode="md"
            key={index}
            className="selected"
            data-cy="selected-category-item"
          >
            <IonIcon
              className="custom-item-detail custom-item-close-circle ios hydrated"
              icon="/assets/form/close-circle.svg"
              onClick={e => {
                e.stopPropagation();
                onCancel(categoryType, category);
              }}
            />
            <IonLabel color="dark">
              <div className="selected-icon">{category.icon ? <IconComponent icon={category.icon} /> : <img className="arrow-selected" alt="arrow" src="/assets/icon/arrow-selected.svg" />}</div>
              <div className="selected-text">{getItemTranslation(category, category.name)}</div>
            </IonLabel>
          </IonItem>
        </div>
      ))}
    </IonList>
  );
};
