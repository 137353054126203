import React, { MouseEventHandler, ReactNode } from 'react';
import { IonContent, IonModal, IonIcon, IonButton, IonSpinner } from '@ionic/react';
import { ErrorMessage, Field, FieldProps, FormikProps } from 'formik';
import { FormValue, FormValues } from './DynamicForm';
import InputPlaceAutocomplete from './inputs/InputPlaceAutocomplete';
import { EditablePost } from '../store/posts/types';
import i18next from 'i18next';
import { locate as locateIcon } from 'ionicons/icons';
import { GetPositionButtonProps } from './inputs/InputPlaceAutocomplete';

import '../pages/EditProfilePage.scss';
import './UserAddressModal.scss';

const geoPositionButton: React.FunctionComponent<GetPositionButtonProps> = ({ getPosition, loading }: GetPositionButtonProps) => (
  <div className="location-button-wrap">
    <IonIcon icon={locateIcon} color="dark" />
    <IonButton className="location-button" onClick={getPosition} fill="clear" color="dark">
      {i18next.t('user.my-current-location')}
      {loading && <IonSpinner name="lines-small" color="dark" />}
    </IonButton>
  </div>
);

interface Props {
  isOpen: boolean;
  onClose: (successValue?: FormValue) => void;
  onAddressChange: (value: FormValue) => void;
  locationModalHeader?: React.FunctionComponent<MouseEventHandler>;
}

class UserAddressModal extends React.Component<Props> {
  public render(): ReactNode {
    return (
      <IonModal isOpen={this.props.isOpen} onDidDismiss={() => this.props.onClose()} className="user-address-modal safe-area-ios">
        {this.props.locationModalHeader && this.props.locationModalHeader(() => this.props.onClose())}
        <IonContent>
          <Field name="address">
            {(fieldProps: FieldProps<FormValues>): ReactNode => {
              const form = fieldProps.form;
              const value: FormValue = fieldProps.form.values.address?.formatted;

              return (
                <>
                  <div className="input-wrap">
                    <IonIcon icon="/assets/icon/search.svg" color="medium" className="search-icon" />

                    <InputPlaceAutocomplete
                      geoPositionButton={geoPositionButton}
                      placeholder={i18next.t('user.enter-address')}
                      showLabel={false}
                      form={form as FormikProps<EditablePost>}
                      name={'address'}
                      value={value}
                      onValueChange={this.onAddressSelect}
                    />
                  </div>

                  {form.errors && (
                    <p className="ion-no-margin form-error-message">
                      <ErrorMessage name="address" render={(errorMessage: string): string => i18next.t(errorMessage)} />
                    </p>
                  )}

                  {form.status && <p className="ion-no-margin form-error-message">{form.status && form.status['address'] ? form.status['address'] : ''}</p>}
                  {/* TODO: Show error when the submit button is disabled */}

                  <IonButton className="validate-location-button" onClick={() => this.validateLocation(form, value)} disabled={!form.values.address}>
                    {i18next.t('user.validate-location')}
                  </IonButton>
                </>
              );
            }}
          </Field>
        </IonContent>
      </IonModal>
    );
  }

  private onAddressSelect = (value: FormValue): void => {
    this.props.onAddressChange(value);
    // The setTimeout allows to let the InputPlaceAutocomplete inner modal enough time to close properly before closing this one
    setTimeout(() => {
      this.props.onClose();
    });
  };

  private validateLocation = (form: FormikProps<FormValues>, value: FormValue): void => {
    const fieldHasError = (form.status && form.status['address']) || (form.errors['address'] && form.touched['address']);

    if (!value && fieldHasError) {
      return;
    }

    if (!value && !fieldHasError) {
      form.setStatus({ address: i18next.t('post.must-select-address') });
      return;
    }

    this.props.onClose(value);
  };
}

export default UserAddressModal;
