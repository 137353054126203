import { Capacitor } from '@capacitor/core';
import compareVersions from 'compare-versions';
import { appVersion, isTest, testAppVersion } from '../environment';
import { AppVersions } from '../store/app/types';

const getAppVersion = (): string => {
  const separatorIndex: number = appVersion.indexOf('-');

  // CompareVersion decides that V1.1 > V1.1-xx, so we remove the -xx if existing
  if (separatorIndex > 0) {
    return appVersion.substring(0, separatorIndex);
  }
  return appVersion;
};

export const minorUpgradeIsAvailable = (minAppVersions: AppVersions): boolean => {
  // For the cypress tests, we compare with the android version
  if (isTest) {
    return compareVersions.compare(testAppVersion, minAppVersions.android, '<');
  }

  const currentAppVersion = getAppVersion();

  // If the app version is not valid
  if (!compareVersions.validate(currentAppVersion)) {
    return false;
  }

  if (Capacitor.getPlatform() === 'ios' && compareVersions.validate(minAppVersions.ios)) {
    return compareVersions.compare(currentAppVersion, minAppVersions.ios, '<');
  }

  if (Capacitor.getPlatform() === 'android' && compareVersions.validate(minAppVersions.android)) {
    return compareVersions.compare(currentAppVersion, minAppVersions.android, '<');
  }

  // If we are neither on ios nor in android, or the min appVersions are not valid, and we are not in test
  return false;
};

export const majorUpgradeIsAvailable = (minAppVersions: AppVersions): boolean => {
  // For the cypress tests, we compare with the android version
  if (isTest) {
    return compareVersions.compare(testAppVersion, minAppVersions.min, '<');
  }

  const currentAppVersion = getAppVersion();

  return compareVersions.validate(currentAppVersion) && compareVersions.validate(minAppVersions.min) && compareVersions.compare(currentAppVersion, minAppVersions.min, '<');
};
