const postBlockPush = {
  titleOfNotificationBlock: 'posts',
  notificationItems: [
    {
      title: 'new-messages',
      optionValue: 'direct_chat_new_message',
    },
    {
      title: 'new-requests',
      optionValue: 'post_new_request',
    },
    {
      title: 'answers-to-requests',
      optionValue: 'post_request_state_change',
    },
    {
      title: 'reviews',
      optionValue: 'post_request_review',
    },
    {
      title: 'post-deleted',
      optionValue: 'post_deleted',
    },
  ],
};

const communityBlockPush = {
  titleOfNotificationBlock: 'community',
  notificationItems: [
    {
      title: 'new-followers',
      optionValue: 'new_follower',
    },
    {
      title: 'new-posts-from-followers',
      optionValue: 'followed_new_post',
    },
  ],
};

const ofIndigoBlockPush = {
  titleOfNotificationBlock: 'of-indigo',
  notificationItems: [
    {
      title: 'indigo-publications',
      optionValue: 'global_message',
    },
    {
      title: 'recommended-publications',
      optionValue: 'recommended_posts',
    },
    {
      title: 'recommended-profiles',
      optionValue: 'recommended_users',
    },
    {
      title: 'reports-moderation',
      optionValue: 'moderated_report_to_reporter',
    },
  ],
};

const postBlockMail = {
  titleOfNotificationBlock: 'posts',
  notificationItems: [
    {
      title: 'new-messages',
      optionValue: 'direct_chat_new_message',
    },
    {
      title: 'new-requests',
      optionValue: 'post_new_request',
    },
    {
      title: 'answers-to-requests',
      optionValue: 'post_request_state_change',
    },
    {
      title: 'reviews',
      optionValue: 'post_request_review',
    },
    {
      title: 'post-deleted',
      optionValue: 'post_deleted',
    },
  ],
};

const communityBlockMail = {
  titleOfNotificationBlock: 'community',
  notificationItems: [
    {
      title: 'new-followers',
      optionValue: 'new_follower',
    },
    {
      title: 'new-posts-from-followers',
      optionValue: 'followed_new_post',
    },
  ],
};

const ofIndigoBlockMail = {
  titleOfNotificationBlock: 'of-indigo',
  notificationItems: [
    {
      title: 'indigo-publications',
      optionValue: 'global_message',
    },
    {
      title: 'recommended-publications',
      optionValue: 'recommended_posts',
    },
    {
      title: 'recommended-profiles',
      optionValue: 'recommended_users',
    },
    {
      title: 'reports-moderation',
      optionValue: 'moderated_report_to_reporter',
    },
  ],
};

export const notificationsChoice = {
  mail: [postBlockMail, communityBlockMail, ofIndigoBlockMail],
  push: [postBlockPush, communityBlockPush, ofIndigoBlockPush],
};
