import { IonButton, IonIcon, IonContent, IonModal, IonSearchbar, IonList, SearchbarInputEventDetail } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { CreatedByManagedDataType, PostCategoryType, PostType, SearchQueryParameters } from '../../store/posts/types';
import { Category } from '../../store/categories/types';
import { isSameHydraEntity, unfocusInput } from '../../utils/helpers';
import { PostTypeComponent, SearchByManagedItem, SearchCategorySelectedFilterItem, SearchFilterItem } from './SearchFilterComponents';
import { categoryWithSize, getChildCategories, getSizeLabel } from '../../utils/categoriesHelpers';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import i18next from 'i18next';
import CommonHeader from '../CommonHeader';
import { actions } from '../../store';

import './SearchFilterModal.scss';
import { IonSearchbarCustomEvent } from '@ionic/core';

interface DispatchProps {
  fetchSearchPostsTotalItem: (params: SearchQueryParameters) => Promise<number>;
  setToastMessage(message: string | null): void;
}

const propsToDispatch = {
  fetchSearchPostsTotalItem: actions.posts.fetchSearchPostsTotalItem,
  setToastMessage: actions.layout.setToastMessageAction,
};

interface ModalProps {
  isOpen: boolean;
  searchParams: SearchQueryParameters;
  onClose: (useOldParams: boolean) => void;
  onSubmit: () => void;
  onCategoryClick: (categoryType: PostCategoryType, category?: Category) => void;
  onSizeClick: () => void;
  onLocationClick: () => void;
  categories: { object: Category[]; service: Category[] };
  setSearchParams: (searchParams: SearchQueryParameters) => void;
}

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type Props = DispatchProps & ModalProps & WithTranslation;

interface State {
  searchBarText: string;
  searchPostsTotalItem: number;
}

const getFilterModalBackButton = (): ReactNode => (
  <IonButton fill="clear" className="common-header-back-button">
    <IonIcon className="close-modal-button" icon="/assets/navigation/close.svg" color="dark" />
  </IonButton>
);

class SearchFilterModal extends Component<Props, State> {
  private readonly ionSearchBarRef: React.RefObject<HTMLIonSearchbarElement>;

  public constructor(props: Props) {
    super(props);

    this.ionSearchBarRef = React.createRef<HTMLIonSearchbarElement>();
    this.state = { searchBarText: this.props?.searchParams?.searchText || '', searchPostsTotalItem: 0 };
  }

  public componentDidMount(): void {
    this.fetchSearchPostsTotalItem();
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
    if (!this.props.isOpen) {
      return;
    }

    if (this.props.isOpen && !prevProps.isOpen) {
      this.fetchSearchPostsTotalItem();
      this.setState({ searchBarText: this.props?.searchParams?.searchText || '' });
      return;
    }

    if (!isEqual(this.props.searchParams, prevProps.searchParams) || !isEqual(this.state.searchBarText, prevState.searchBarText)) {
      this.fetchSearchPostsTotalItem();
    }
  }

  public render(): ReactNode {
    const { categories, searchParams } = this.props;
    const { postType, categorySelection, locationAddress, locationCenter } = searchParams;
    return (
      <IonModal className="search-filter-modal safe-area-ios--large-screen" isOpen={this.props.isOpen} onDidDismiss={e => this.props.onClose(e.detail.role === 'backdrop')}>
        <CommonHeader backButton={getFilterModalBackButton()} title={i18next.t('common.search')} stopPropagation onBackButtonClick={() => this.props.onClose(true)} />

        <div>
          <IonSearchbar
            searchIcon="/assets/form/search.svg"
            ref={this.ionSearchBarRef}
            className="search-bar"
            placeholder={this.props.t('common.search')}
            onIonChange={this.updateSearchText}
            onIonClear={this.focusSearchBar}
            onKeyPress={this.handleKeyboardClick}
            spellcheck={true}
            autocorrect={'on'}
            autocomplete={'on'}
            // do not display his.state.searchBarText on edit input to avoid the truncate text issue bind to debounce
            value={this.props?.searchParams?.searchText === this.state.searchBarText ? this.state.searchBarText : this.ionSearchBarRef.current?.value}
          />
        </div>

        <IonContent className="search-filter-content">
          <h3>
            <Trans i18nKey="search.what-are-you-looking-for" />{' '}
          </h3>
          <h5>
            <Trans i18nKey="search.advert-type" />{' '}
          </h5>

          <div className="post-type-container">
            <div className="post-type-container-category">
              <PostTypeComponent onClick={() => this.setPostTypeState('offer')} isSelected={postType === 'offer'} label={this.props.t('search.offers')} shape={'round'} />
              <PostTypeComponent onClick={() => this.togglePostCategoryTypeState('object')} isSelected={categorySelection?.object.isSelected} label={this.props.t('search.objects')} />
            </div>
            <div className="post-type-container-category">
              <PostTypeComponent onClick={() => this.setPostTypeState('need')} isSelected={postType === 'need'} label={this.props.t('search.requests')} shape={'round'} />
              <PostTypeComponent onClick={() => this.togglePostCategoryTypeState('service')} isSelected={categorySelection?.service.isSelected} label={this.props.t('search.services')} />
            </div>
          </div>

          <IonList className="search-filter-list">
            {categorySelection?.object.isSelected && (
              <>
                {categorySelection.object.category ? (
                  <SearchCategorySelectedFilterItem
                    category={categorySelection.object.category}
                    categories={this.props.categories.object}
                    onClick={this.props.onCategoryClick}
                    categoryType="object"
                    title={this.props.t(categorySelection.service.isSelected ? 'search.object-category' : 'search.category')}
                    onCancel={(categoryType, category) => {
                      this.cancelCategorySelection(categoryType, category);
                    }}
                  />
                ) : (
                  <SearchFilterItem
                    value=""
                    title={this.props.t(categorySelection.service.isSelected ? 'search.object-category' : 'search.category')}
                    onClick={() => this.props.onCategoryClick('object')}
                  />
                )}
              </>
            )}
            {categorySelection.object.category && categorySelection.object.isSelected && categoryWithSize(categories.object, categorySelection.object.category) && (
              <SearchFilterItem
                value={getSizeLabel({ size: searchParams.size, universe: searchParams.universe }, this.props.categories.object, categorySelection.object.category)}
                title={this.props.t('sizes.select-size')}
                onClick={() => this.props.onSizeClick()}
                dataCy="size"
                onCancel={e => {
                  e.stopPropagation();
                  this.cancelPostSizeSelection();
                }}
              />
            )}
            {categorySelection?.service.isSelected && (
              <>
                {categorySelection.service.category ? (
                  <SearchCategorySelectedFilterItem
                    category={categorySelection.service.category}
                    categories={this.props.categories.service}
                    title={this.props.t(categorySelection.object.isSelected ? 'search.service-category' : 'search.category')}
                    onClick={this.props.onCategoryClick}
                    categoryType="service"
                    onCancel={(categoryType, category) => {
                      this.cancelCategorySelection(categoryType, category);
                    }}
                  />
                ) : (
                  <SearchFilterItem
                    value=""
                    title={this.props.t(categorySelection.object.isSelected ? 'search.service-category' : 'search.category')}
                    onClick={() => this.props.onCategoryClick('service')}
                  />
                )}
              </>
            )}
            <div data-cy="location-item">
              <SearchFilterItem
                value={locationCenter ? locationAddress?.formatted ?? '' : ''}
                title={this.props.t('search.location')}
                onClick={this.props.onLocationClick}
                dataCy="location"
                onCancel={e => {
                  e.stopPropagation();
                  this.props.setSearchParams({ ...this.props.searchParams, locationCenter: undefined, locationRadius: 5, locationAddress: null });
                }}
              />
            </div>
            <SearchByManagedItem onChange={this.setManagedUserType} t={this.props.t} value={searchParams.createdByManagedData} />
          </IonList>

          <div className="search-filter-button-container">
            <IonButton
              data-cy="search-filter-button"
              className="search-filter-button button-round"
              onClick={() => {
                this.props.setSearchParams({ ...this.props.searchParams, searchText: this.state.searchBarText });
                this.props.onSubmit();
              }}
            >
              <div>
                {/* TODO: remove div and use an other way to break lines in button */}
                <Trans i18nKey="search.search" />
                <br />
                <span>
                  <Trans i18nKey={'search.search-result'} values={{ count: this.state.searchPostsTotalItem }} />
                </span>
              </div>
            </IonButton>
          </div>
        </IonContent>
      </IonModal>
    );
  }

  private cancelPostSizeSelection(): void {
    this.props.setSearchParams({
      ...this.props.searchParams,
      size: undefined,
      universe: undefined,
    });
  }

  private cancelCategorySelection(value: PostCategoryType, category: Category): void {
    const categorySelection = cloneDeep(this.props.searchParams.categorySelection);

    if (!categorySelection || !categorySelection[value]) {
      return;
    }

    if (category.parent) {
      const size = value === 'service' ? this.props.searchParams.size : categorySelection[value].category?.itemsType === category.itemsType ? this.props.searchParams.size : undefined;
      const universe = value === 'service' ? this.props.searchParams.universe : categorySelection[value].category?.itemsType === category.itemsType ? this.props.searchParams.universe : undefined;
      const parentCategory = this.props.categories[value].find(cat => isSameHydraEntity(cat, category.parent)) as Category;
      categorySelection[value].category = parentCategory;
      this.props.setSearchParams({
        ...this.props.searchParams,
        categorySelection,
        size,
        universe,
        childCategories: getChildCategories(value === 'object' ? this.props.categories.object : this.props.categories.service, parentCategory),
      });
      return;
    }

    categorySelection[value].category = undefined;

    this.props.setSearchParams({ ...this.props.searchParams, categorySelection, childCategories: undefined });
  }

  private togglePostCategoryTypeState = (value: PostCategoryType): void => {
    const categorySelection = cloneDeep(this.props.searchParams.categorySelection);

    if (categorySelection && categorySelection[value]) {
      categorySelection[value].isSelected = !categorySelection[value].isSelected;
    }

    // Be sure to have at least one selected category type
    if (!categorySelection.object.isSelected && !categorySelection.service.isSelected) {
      categorySelection.object.isSelected = true;
    }

    this.props.setSearchParams({ ...this.props.searchParams, categorySelection });
  };

  private setPostTypeState = (postType: PostType): void => {
    this.props.setSearchParams({ ...this.props.searchParams, postType });
  };

  private setManagedUserType = (value: CreatedByManagedDataType): void => {
    const searchParams = {
      ...this.props.searchParams,
      createdByManagedData: value,
    };

    this.props.setSearchParams(searchParams);
  };

  private focusSearchBar = (): void => {
    if (!this.ionSearchBarRef.current) {
      return;
    }

    this.ionSearchBarRef.current.setFocus();
  };

  private updateSearchText = (e: IonSearchbarCustomEvent<SearchbarInputEventDetail>): void => {
    const searchText = e.detail.value ?? '';
    this.setState({ searchBarText: searchText });
  };

  private handleKeyboardClick = (e: React.KeyboardEvent<HTMLIonSearchbarElement>): void => {
    if (e.type === 'keypress' && (e as React.KeyboardEvent<HTMLIonSearchbarElement>).key !== 'Enter') {
      return;
    }

    unfocusInput(this.ionSearchBarRef);
  };

  private fetchSearchPostsTotalItem = async (): Promise<void> => {
    const totalItem = await this.props.fetchSearchPostsTotalItem({ ...this.props.searchParams, searchText: this.state.searchBarText }).catch(e => {
      this.props.setToastMessage(e);
    });

    if (totalItem === undefined) {
      return;
    }

    this.setState({ searchPostsTotalItem: totalItem });
  };
}

export default connect(null, mapDispatchToProps)(withTranslation()(SearchFilterModal));
