import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { AnyAction, Middleware } from 'redux';
import { REHYDRATE } from 'redux-persist';
import { dispatchAnalytics } from '../utils/analytics/analyticsHelper';
import { extractId } from '../utils/helpers';
import { appLanguages } from '../utils/translation';
import { AppState } from './app/types';
import * as layoutActions from './layout/actions';
import { RehydrateAction } from 'redux-persist/es/types';
import { ActionType } from 'typesafe-actions';

export const storeMiddleware: Middleware<unknown, RehydrateAction> = store => next => (action: RehydrateAction | ActionType<AnyAction>) => {
  typeof action === 'function' ? action(next) : next(action);

  const onHydrate = (payload: AppState): void => {
    if (payload.currentUser && extractId(payload.currentUser)) {
      store.dispatch(layoutActions.setAppLanguageAction(payload.currentUser.locale));
      return;
    }

    if (Capacitor.isNativePlatform()) {
      Device.getLanguageCode().then(result => {
        if (!appLanguages.includes(result.value)) {
          return;
        }

        store.dispatch(layoutActions.setAppLanguageAction(result.value));
      });
    }
  };

  if (action.type === REHYDRATE && action.payload) {
    onHydrate(action.payload.app as AppState);
  }

  dispatchAnalytics(action);
};
