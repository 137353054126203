import { getWindowLocation } from './utils/windowLocationHelper';
import { DeviceId, DeviceInfo } from '@capacitor/device/dist/esm/definitions';
import { AppInfo } from '@capacitor/app';

interface Environment {
  production?: boolean;
  test?: boolean;
  disabledClient?: boolean;
  enableAnalytics?: boolean;
  isHttps?: boolean;
  version?: string;
  buildVersion?: string;
  apiEntrypoint?: string;
  cityUrl?: string;
  gmapsApiKey?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const env: Environment = (window as any).__env || {};

export const navigatorLanguage = window?.navigator?.language?.substring(0, 2) || 'en';
export const isHttps = getWindowLocation().protocol === 'https:';
export const isProduction = typeof env.production !== 'undefined' ? env.production : true;
export const isTest = env.test || false;

const disabledClientHosts = ['client.indigo.world'];
export const appIsDisabled = !isTest && (typeof env.disabledClient !== 'undefined' ? env.disabledClient : disabledClientHosts.indexOf(getWindowLocation().host) >= 0);
export const isMobileNavigator = navigator.userAgent.includes('Mobile');

export const isSelfSignedSsl = isHttps && getWindowLocation().hostname.indexOf('localhost.indigo.world') >= 0;
export const defaultDomain = 'indigo.world';
export const prodApiEntrypoint = `https://api.${defaultDomain}`;
export const defaultApiEntrypoint = env.apiEntrypoint || prodApiEntrypoint;
export let appVersion = env.version || 'dev';
export let appBuildVersion = appVersion + (env.buildVersion ? ` - ` + env.buildVersion : '');
export const testAppVersion = '4.3.2'; // Fake app version used for cypress tests
export const gmapsApiKey = env.gmapsApiKey || 'AIzaSyDknJ6DqmqXl_mINx5EwwqSlVLP0vby4nM';
export const clientRegEx = /(https?:\/\/(?:app|client)(?:-\d+)?(?:\.\w+)?\.(?:indigo\.world|localhost.indigo.world)(?::\d+)?\/?)/g;

export let apiEntrypoint = '';

export let appInfo: AppInfo | undefined = undefined;
export let deviceInfo: DeviceInfo | undefined = undefined;
// The UUID (or ID) of the device as available to the app. This identifier may change on modern mobile platforms that only allow per-app install IDs.
// On web, a random identifier is generated and stored on localStorage for subsequent calls.
export let deviceId: DeviceId | undefined = undefined;

export const setDeviceId = (id: DeviceId): void => {
  deviceId = id;
};

export const setAppInfo = (info: AppInfo): void => {
  appInfo = info;

  if (appInfo.version) {
    appVersion = appInfo.version;
    appBuildVersion = appInfo.version + (appInfo.build ? ` - ` + appInfo.build : '');
  }
};

export const setDeviceInfo = (info: DeviceInfo): void => {
  deviceInfo = info;
};

const isProd = (entrypoint?: string): boolean => {
  return (entrypoint || apiEntrypoint) === prodApiEntrypoint;
};

const setApiEntrypoint = (value: string): void => {
  apiEntrypoint = value;
  localStorage.setItem('apiEntrypoint', apiEntrypoint);

  if (!isProd(apiEntrypoint)) {
    document.body.classList.add('dev-mode');
  } else {
    document.body.classList.remove('dev-mode');
  }
};

export const overrideApiEntrypoint = (value: string | null): void => {
  if (!value) {
    setApiEntrypoint(defaultApiEntrypoint);
    return;
  }

  if ('prod' === value) {
    value = `api.${defaultDomain}`;
  } else if ('preprod' === value) {
    value = `api.preprod.${defaultDomain}`;
  } else if (value?.match(/^api-\d+$/)) {
    value = `${value}.dev.${defaultDomain}`;
  } else if (value?.match(/^\d+$/)) {
    value = `api-${value}.dev.${defaultDomain}`;
  }

  if ('http' !== value.substr(0, 4)) {
    value = `https://${value}`;
  }

  setApiEntrypoint(value.replace(/\/$/, ''));
};

overrideApiEntrypoint(localStorage.getItem('apiEntrypoint') || null);
export const cityUrl = env.cityUrl || apiEntrypoint.replace('api', 'city');
export const supervisorUrl = apiEntrypoint.replace('api', 'supervisor');
export const clientUrl = isProd() ? 'https://app.indigo.world' : apiEntrypoint.replace('api', 'client');

const enableAnalytics = env.enableAnalytics !== undefined ? env.enableAnalytics : isProd();

export const analyticsEnabled = !isTest && enableAnalytics;
export const fbAppId = '589230167903091';
export const fbPixelId = '279022623264680';
export const amplitudeId = isProd() ? '591a2afb51f74be3511139271501bfbf' : '162ce75655ca8aa81a89be17bd7dac60';

export const firebaseConfig = {
  apiKey: 'AIzaSyDqm1vHrdy0LOzi2cj0CmWYZqedHNGWl_0',
  authDomain: 'indigo-v2-1589f.firebaseapp.com',
  databaseURL: 'https://indigo-v2-1589f.firebaseio.com',
  projectId: 'indigo-v2-1589f',
  storageBucket: 'indigo-v2-1589f.appspot.com',
  messagingSenderId: '555182525886',
  appId: isProd() ? '1:555182525886:web:463b8627a21cdbba3abdef' : '1:555182525886:web:e506f2c432a7a92d3abdef',
  measurementId: isProd() ? 'G-0CXWJGG8D2' : 'G-ZWFE1JS3G8',
  vapidKey: 'BM2H_m0ewUfnbyBpn9v-DPNKRDQ9EZJ1-0745eb8r18CQ4uUJ7Ksi0q3wp3Vf4PU0pjm1pW56h-cRV1XM5LPRtc',
};

export const iosAppId = 'id987494212';
export const androidPackageName = 'com.cooperativeIndigo.indigo';
