import React from 'react';
import { IonButton, IonModal, IonContent, IonThumbnail } from '@ionic/react';
import { Trans } from 'react-i18next';
import ImageFadeIn from './ImageFadeIn';
import { upgradeApp } from './UpgradeAppAlert';

import './UpgradeAppModal.scss';

export interface Props {
  isOpen?: boolean;
}

const UpgradeAppModal: React.FunctionComponent<Props> = ({ isOpen }: Props) => {
  return (
    <IonModal backdropDismiss={false} isOpen={isOpen} className="upgrade-app-modal safe-area-ios">
      <IonContent>
        <IonThumbnail className="upgrade-app-modal-image modal-content-image">
          <ImageFadeIn src="/assets/upgrade-app-image.svg" />
        </IonThumbnail>
        <div className="upgrade-app-modal-content-wrapper">
          <div>
            <div className="upgrade-app-modal-content">
              <h1>
                <Trans i18nKey="app.major-upgrade-title" />
              </h1>
              <p>
                <Trans i18nKey="app.major-upgrade-content" />
              </p>
            </div>
            <IonButton className="upgrade-app-modal-button button-large" onClick={upgradeApp} shape="round" size="large">
              <Trans i18nKey="app.upgrade" />
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default UpgradeAppModal;
