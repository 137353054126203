import { IonButton, IonContent, IonThumbnail, IonPage, IonText } from '@ionic/react';
import { FormikHelpers, FormikProps } from 'formik';
import i18next from 'i18next';
import React, { Component, ReactNode } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators, Dispatch } from 'redux';
import { FormValues, InputWithValidationFieldComponent } from '../components/DynamicForm';
import UserForm, { UserFormValues } from '../components/UserForm';
import { actions, RootState } from '../store';
import { CurrentUser } from '../store/users/types';
import CommonHeader from '../components/CommonHeader';
import BackButton from '../components/BackButton';
import { sendRegisterCompletedLog } from '../utils/analytics/analyticsHelper';
import { getLocationHeader } from './RegisterPage';
import { errorIsSubmissionError } from '../utils/dataAccessError';
import { getUserCanCreateUsers } from '../store/app/selectors';
import './RegisterPage.scss';

interface DispatchProps {
  registerNewUser(user: UserFormValues): Promise<CurrentUser>;
  setToastMessage(message: string | null): void;
}

const propsToDispatch = {
  registerNewUser: actions.app.registerNewUser,
  setToastMessage: actions.layout.setToastMessageAction,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

interface StateAppProps {
  userCanCreateUsers: boolean;
}

const mapStateToProps = (state: RootState): StateAppProps => ({
  userCanCreateUsers: getUserCanCreateUsers(state),
});

export type RegisterNewUserProps = DispatchProps & StateAppProps & WithTranslation & RouteComponentProps<Record<string, never>>;

interface State {
  initialValues: UserFormValues;
  userValues: Partial<UserFormValues>;
  userSelectedValues: Partial<UserFormValues>;
  requestIsPending: boolean;
}

function getInitialUserValues(): UserFormValues {
  return {
    email: '',
    firstname: '',
    lastname: '',
    locale: i18next.language.substring(0, 2) || 'en',
    gender: undefined,
    address: undefined,
  };
}

const fields: Record<string, Partial<keyof UserFormValues>[]> = {
  fullForm: ['email', 'firstname', 'lastname', 'gender', 'address', 'addressLocation'],
};

class RegisterNewUserPage extends Component<RegisterNewUserProps, State> {
  public constructor(props: RegisterNewUserProps) {
    super(props);

    const initialValues = getInitialUserValues();

    this.state = {
      initialValues: initialValues,
      userValues: initialValues,
      userSelectedValues: initialValues,
      requestIsPending: false,
    };
  }

  public render(): ReactNode {
    const { requestIsPending } = this.state;

    if (!this.props.userCanCreateUsers) {
      return <Redirect to="/" />;
    }

    return (
      <IonPage className={'register-page-wrapper light-page light-page-display'} data-cy="register-new-user-page">
        <CommonHeader
          addIonHeader={true}
          backButton={<BackButton />}
          isBackButtonDisabled={requestIsPending}
          stopPropagation
          title={
            <IonThumbnail className="logo-thumbnail">
              <img src="/assets/logo-indigo-gradient.svg" alt="Indigo" />
            </IonThumbnail>
          }
        />
        <IonContent className="register-page">
          <h1 className="register-page-header-title">
            <Trans i18nKey="register.pre-registration-form" />
          </h1>
          <IonText className="alert-info">
            <Trans i18nKey="register.pre-registration-form-helper" />
          </IonText>
          <div className="register-form">
            <UserForm
              submitFormButton={this.submitRegisterButton}
              onAfterSubmitFormSuccess={this.afterSubmitFormSuccess}
              initialValues={this.state.initialValues}
              fields={fields.fullForm}
              postUserAction={this.submitForm}
              locationModalHeader={getLocationHeader}
              overriddenFields={[
                {
                  label: 'user.email-address',
                  name: 'email',
                  formComponent: InputWithValidationFieldComponent,
                  options: { autocapitalize: 'off', type: 'email', required: true, clearOnEdit: false, clearInput: false, autocomplete: 'new-password', spellcheck: true, autocorrect: 'on' },
                },
                {
                  label: 'user.firstname',
                  name: 'firstname',
                  formComponent: InputWithValidationFieldComponent,
                  options: { autocapitalize: 'on', type: 'text', required: true, clearOnEdit: false, clearInput: false, autocomplete: 'new-password', spellcheck: true, autocorrect: 'on' },
                },
                {
                  label: 'user.lastname',
                  name: 'lastname',
                  formComponent: InputWithValidationFieldComponent,
                  options: { autocapitalize: 'on', type: 'text', required: false, clearOnEdit: false, clearInput: false, autocomplete: 'new-password', spellcheck: true, autocorrect: 'on' },
                },
              ]}
            />
          </div>
        </IonContent>
      </IonPage>
    );
  }

  private submitRegisterButton: React.FunctionComponent<FormikProps<FormValues>> = ({ isValid, isSubmitting, isValidating, values }: FormikProps<FormValues>) => {
    const disabled = this.state.requestIsPending || !isValid || isValidating || isSubmitting;
    this.setState({
      userSelectedValues: Object.assign({}, this.state.userValues, values),
    });

    return (
      <IonButton type="submit" shape="round" className="create-account ion-color-facebook" fill="solid" disabled={disabled}>
        {!this.state.requestIsPending ? i18next.t('register.register') : i18next.t('common.loading')}
      </IonButton>
    );
  };

  private successToastAndEventLog = (): void => {
    sendRegisterCompletedLog();
    this.props.setToastMessage('register.new-account-created');
  };

  private submitForm = async (values: UserFormValues): Promise<CurrentUser> => {
    if (this.state.requestIsPending) {
      return this.state.userValues as CurrentUser;
    }

    this.setState({ requestIsPending: true });
    this.updateUserValues(values);
    return await this.register(this.state.userValues);
  };

  private register = async (user: UserFormValues): Promise<CurrentUser> => {
    let newUser: CurrentUser = user as CurrentUser;
    try {
      newUser = await this.props.registerNewUser(user);
    } catch (e) {
      // Avoid afterSubmitFormSuccess to close the page
      this.setState({ requestIsPending: false });

      if (errorIsSubmissionError(e)) {
        this.props.setToastMessage(e.getErrorsMessage());
      }
    }

    return newUser;
  };

  private updateUserValues(values: UserFormValues): void {
    const setUserValue = <T extends UserFormValues, K extends keyof T>(user: T, key: K, value: T[K]): void => {
      user[key] = value;
    };

    let user: UserFormValues = { '@id': values['@id'] as string };

    if (this.state.userValues != null) {
      user = this.state.userValues;
    }

    fields.fullForm.forEach((field: keyof UserFormValues) => {
      setUserValue(user, field, values[field] || undefined);
    });

    this.setState({ userValues: user, initialValues: Object.assign({}, this.state.initialValues, values) });
  }

  private afterSubmitFormSuccess = async (values: UserFormValues, actions: FormikHelpers<FormValues>): Promise<void> => {
    await actions.setSubmitting(false);

    if (!this.state.requestIsPending) {
      return;
    }
    this.setState({ requestIsPending: false });
    this.successToastAndEventLog();
    this.props.history.goBack();
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(RegisterNewUserPage)));
