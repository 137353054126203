import { IonThumbnail, IonButtons, IonButton, IonIcon, IonBadge, IonToolbar } from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { actions, RootState } from '../../store';
import { getCurrentUser } from '../../store/app/selectors';
import RoutingGuard from '../common/RoutingGuard';
import { translateDigits } from '../../utils/translation';

interface StateProps {
  unreadNotifications: number;
}

const mapStateToProps = (state: RootState): StateProps => ({
  unreadNotifications: getCurrentUser(state).status?.unreadNotifications ?? 0,
});

interface DispatchProps {
  fetchCurrentUser: () => Promise<void>;
}

const propsToDispatch = {
  fetchCurrentUser: actions.app.fetchLoggedUser,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type Props = StateProps & DispatchProps;

class PostsPageTopbar extends Component<Props> {
  public render(): ReactNode {
    const { unreadNotifications } = this.props;

    return (
      <IonToolbar mode="md" className="posts-page-topbar">
        <IonThumbnail className="posts-page-topbar-thumbnail">
          <img src="/assets/logo-indigo-gradient.svg" alt="Indigo" />
        </IonThumbnail>

        <IonButtons className="posts-page-topbar-buttons">
          <IonButton routerLink="/map" fill="clear" color="light" data-cy="map-button">
            <IonIcon icon="/assets/icon/map.svg" />
          </IonButton>

          <RoutingGuard>
            <IonButton
              routerLink="/notifications"
              fill="clear"
              color="light"
              className="notification-button disable-hover"
              style={{ '--padding-end': `${(unreadNotifications.toString().length - 1) * 8}px` }}
            >
              <IonIcon icon="/assets/icon/notifications.svg" />
              {unreadNotifications > 0 && (
                <IonBadge color="danger" className="count-badge" data-cy="notification-counter">
                  {translateDigits(unreadNotifications)}
                </IonBadge>
              )}
            </IonButton>
          </RoutingGuard>
        </IonButtons>
      </IonToolbar>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PostsPageTopbar);
