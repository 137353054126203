import { AnyAction, Middleware } from 'redux';
import { actions, RootState } from '../index';
import * as reportsActions from './actions';
import * as layoutActions from '../layout/actions';
import { ActionType, getType } from 'typesafe-actions';
import { ThunkResultDispatch } from '../types';

export const reportMiddleware: Middleware<unknown, RootState> = store => next => (action: ActionType<typeof actions.app | AnyAction>) => {
  typeof action === 'function' ? action(next) : next(action);

  const dispatch = store.dispatch as ThunkResultDispatch;

  if (action.type === getType(reportsActions.createUserReportAction.success)) {
    dispatch(layoutActions.setToastMessageAction('user.report-success'));
  }

  if (action.type === getType(reportsActions.createUserReportAction.failure)) {
    dispatch(layoutActions.setToastMessageAction(action.payload?.errors?.reportedUser ? action.payload?.errors?.reportedUser : action.payload.message));
  }

  if (action.type === getType(reportsActions.createPostReportAction.success)) {
    dispatch(layoutActions.setToastMessageAction('post.report-success'));
  }

  if (action.type === getType(reportsActions.createPostReportAction.failure)) {
    if (action.payload?.errors?.reportedUser) {
      dispatch(layoutActions.setToastMessageAction(action.payload?.errors?.reportedUser));
      return;
    }
    if (action.payload?.errors?.reportedPost) {
      dispatch(layoutActions.setToastMessageAction(action.payload?.errors?.reportedPost));
      return;
    }

    dispatch(layoutActions.setToastMessageAction(action.payload.message));
  }
};
