import { createSelectorCreator, defaultMemoize } from 'reselect';
import { EntityReference, HydratedCollection, ReferencedCollection } from '../../utils/hydra';
import { denormalizeEntity, denormalizeEntityCollection } from '../entities/selectors';
import { EntitiesState } from '../entities/types';
import { RootState } from '../index';
import { User, UserCollectionName } from './types';
import { getEntities } from '../app/selectors';
import { isFullPost } from '../posts/selectors';
import { FullPost } from '../posts/types';
import isEqual from 'lodash/isEqual';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);
const getUserIdFromParams = (state: RootState, id: EntityReference | undefined): EntityReference | undefined => id;

export const getUser = createDeepEqualSelector(getEntities, getUserIdFromParams, (entities: EntitiesState, id: EntityReference | undefined): User | undefined => {
  if (!id) {
    return undefined;
  }
  return denormalizeEntity<User>(entities, id);
});

const getCollectionNameFromParams = (state: RootState, id: EntityReference | undefined, collectionName: UserCollectionName): UserCollectionName => collectionName;

export const getUserPosts = createDeepEqualSelector(
  getEntities,
  getUser,
  getCollectionNameFromParams,
  (entities: EntitiesState, user: User | undefined, collectionName: UserCollectionName): HydratedCollection<FullPost> => {
    const hydratedColl: HydratedCollection<FullPost> = denormalizeEntityCollection<FullPost>(entities, user?.collections?.[collectionName] as ReferencedCollection);
    hydratedColl.items = hydratedColl.items.filter(isFullPost) as FullPost[];

    return hydratedColl;
  },
);

const getUserIdFromProps = (state: RootState, props: { userId: EntityReference | null }): EntityReference | null => props.userId;

export const getUserById = createDeepEqualSelector(getEntities, getUserIdFromProps, (entities: EntitiesState, userId: EntityReference | null): User | undefined => {
  if (!userId) {
    return undefined;
  }

  return denormalizeEntity<User>(entities, userId);
});
