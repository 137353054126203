import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { actions, RootState, selectors } from '../store';
import { User, UserReview, UserReviewsCollectionName } from '../store/users/types';
import { bindActionCreators, Dispatch } from 'redux';
import { WithTranslation, withTranslation } from 'react-i18next';
import { EntityReference, HydratedCollection, ReferencedCollection } from '../utils/hydra';
import { IonHeader, IonLabel, IonSegment, IonSegmentButton, IonContent, IonPage, IonLoading, withIonLifeCycle } from '@ionic/react';
import CommonHeader from '../components/CommonHeader';
import { addStyleToShadowRoot, extractId } from '../utils/helpers';
import i18next from 'i18next';
import { denormalizeEntityCollection } from '../store/entities/selectors';
import ReviewItem from '../components/ReviewItem';
import { BlockedUsersCollectionName } from '../store/blockedUsers/types';
import { isUserBlocked } from '../store/blockedUsers/selectors';
import { getWindowLocation } from '../utils/windowLocationHelper';
import { createIsCurrentUserFunc, isCurrentUserFunc } from '../store/app/selectors';
import './UserReviewsPage.scss';

interface StateProps {
  findUserById: (id: EntityReference) => User | undefined;
  isCurrentUser: isCurrentUserFunc;
  getReviews: (reviews: ReferencedCollection | undefined) => HydratedCollection<UserReview>;
  isUserBlocked: (userId: EntityReference, collectionName: BlockedUsersCollectionName) => boolean;
}

const mapStateToProps = (state: RootState): StateProps => ({
  findUserById: (id: EntityReference) => selectors.entities.denormalizeEntity(state.entities, id),
  isCurrentUser: createIsCurrentUserFunc(state),
  getReviews: (reviews: ReferencedCollection | undefined) => denormalizeEntityCollection(state.entities, reviews),
  isUserBlocked: (userId: EntityReference, collectionName: BlockedUsersCollectionName) => isUserBlocked(state, userId, collectionName),
});

interface DispatchProps {
  getUserReviews: (userId: EntityReference, collectionName: UserReviewsCollectionName) => void;
  fetchUserReviewsResponses: (userId: EntityReference) => void;
}

const propsToDispatch = {
  getUserReviews: actions.users.fetchUserReviews,
  fetchUserReviewsResponses: actions.users.fetchUserReviewsResponsesByPostRequest,
};

interface PageProps {
  userId: EntityReference | null;
}

interface State {
  reviewsCollections: UserReviewsCollectionName;
}

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);
type Props = PageProps & DispatchProps & StateProps & WithTranslation;

class UserReviewsPage extends Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      reviewsCollections: 'postRequestReviews',
    };
  }

  public ionViewWillEnter(): void {
    const queryString = getWindowLocation().search;
    const urlParams = new URLSearchParams(queryString);
    const type = urlParams.get('type');
    addStyleToShadowRoot(document.querySelectorAll('ion-segment-button'), '<style>ion-ripple-effect { color: var(--ion-color-milkygray)!important;opacity: 0.5 }</style>');

    if (type) {
      this.setState({ reviewsCollections: type as UserReviewsCollectionName }, () => this.doRefresh());
      return;
    }

    this.doRefresh();
  }

  public componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.userId !== this.props.userId) {
      this.doRefresh();
    }
  }

  private async doRefresh(): Promise<void> {
    if (this.props.userId === null) return;
    const user = this.props.findUserById(this.props.userId);
    if (!user) return;

    if (!this.props.userId) {
      return;
    }

    await this.props.getUserReviews(this.props.userId, this.state.reviewsCollections);

    if (this.state.reviewsCollections === 'postRequestReviews') {
      this.props.fetchUserReviewsResponses(this.props.userId);
    }
  }

  public render(): ReactNode {
    if (this.props.userId === null) {
      return <Redirect to="/" />;
    }

    const user = this.props.findUserById(this.props.userId);
    if (!user) return <IonPage />;
    const reviews = this.props.getReviews(user.collections?.[this.state.reviewsCollections]);

    const responses = this.props.getReviews(user.collections?.postRequestReviewsResponses);
    const isPostRequestsReviews = this.state.reviewsCollections === 'postRequestReviews';

    const isUserBlocked = this.props.isUserBlocked(this.props.userId, 'currentUserBlockedCollection');
    const isCurrentUserBlocked = this.props.isUserBlocked(this.props.userId, 'currentUserIsBlockedCollection');

    return (
      <IonPage data-cy="user-reviews-page">
        <IonHeader>
          <CommonHeader addIonHeader={true} title={i18next.t('review.reviews')} />
          <IonSegment onIonChange={this.onTabWillChange} mode="md" color="dark" className="user-reviews-tab-bar" value={this.state.reviewsCollections}>
            <IonSegmentButton className="tab-button" value="postRequestReviews" mode="md">
              <IonLabel>{i18next.t('review.exchanges')}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton className="tab-button" value="personalReviews" mode="md">
              <IonLabel>{i18next.t('review.personal')}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
        </IonHeader>

        <IonContent className="user-reviews-content">
          {reviews.isLoading && !reviews.items.length && <IonLoading spinner="bubbles" isOpen={true} showBackdrop={false} />}
          {!reviews.isLoading && (reviews.items.length === 0 || isUserBlocked || isCurrentUserBlocked) ? (
            <IonLabel className="no-reviews-text">{i18next.t('review.no-reviews')}</IonLabel>
          ) : (
            reviews.items.map(review => {
              let response;
              const reviewedPostRequest = review.reviewedPostRequest;
              if (isPostRequestsReviews && responses.ids.length > 0 && reviewedPostRequest) {
                response = responses.items.find(value => {
                  if (!value.reviewedPostRequest) {
                    return false;
                  }
                  return extractId(value.reviewedPostRequest) === extractId(reviewedPostRequest);
                });
              }
              return (
                <React.Fragment key={extractId(review)}>
                  <ReviewItem review={review} user={user} />
                  {response && response.comment && <ReviewItem user={user} review={response} isResponse />}
                </React.Fragment>
              );
            })
          )}
        </IonContent>
      </IonPage>
    );
  }

  private onTabWillChange = (e: CustomEvent): void => {
    if (this.state.reviewsCollections === e.detail.value) {
      return;
    }
    this.setState({ reviewsCollections: e.detail.value });
    this.doRefresh();
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withIonLifeCycle(UserReviewsPage)));
