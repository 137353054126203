import {
  IonButtons,
  IonButton,
  IonCol,
  IonContent,
  IonRow,
  IonIcon,
  IonCard,
  IonText,
  IonToolbar,
  IonMenuToggle,
  IonMenuButton,
  IonHeader,
  IonPage,
  withIonLifeCycle,
  IonLabel,
  IonCardContent,
} from '@ionic/react';
import React, { Component, ReactNode } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import AddPageModal from '../components/user/AddPageModal';
import { actions, RootState } from '../store';
import { createIsCurrentUserFunc, getCurrentUser, getUserArea, isCurrentUserFunc } from '../store/app/selectors';
import { Area, CurrentUser, organizationTypePublicSector } from '../store/users/types';
import { FullPost, Location } from '../store/posts/types';
import { User } from '../store/users/types';
import { extractId, isEqualIgnoringFunctions } from '../utils/helpers';
import { CollectionResponse, EntityReference, HydratedCollection } from '../utils/hydra';
import { Thematic } from '../store/thematics/types';
import UserPostsSlider from '../components/UserPostsSlider';
import ProfileMenuModal from '../components/profileMenu/ProfileMenuModal';
import AboutInfoComponent from '../components/user/AboutInfoComponent';
import UserSocialImpact from '../components/user/UserSocialImpact';
import UserExperience from '../components/user/UserExperience';
import UserThematics from '../components/user/UserThematics';
import UserOverview from '../components/user/UserOverview';
import { JWTToken } from '../store/app/types';
import { Redirect } from 'react-router';
import CompletePageModal from '../components/user/CompletePageModal';
import SwitchProfileModal from '../components/user/SwitchProfileModal';
import ProfilePagePlaceholder from '../components/placeholders/ProfilePagePlaceholder';
import withRouterAndRef from '../utils/withRouterAndRef';
import { RouteComponentProps } from 'react-router-dom';
import { setStatusBarStyle } from '../utils/statusBarHelper';
import { cityUrl } from '../environment';
import CertifiedChip from '../components/chip/CertifiedChip';
import UserMediaSlider from '../components/UserMediaSlider';
import { getManagedUsers, getWhoAmIUser } from '../store/whoAmI/reselectors';
import { getUser, getUserPosts } from '../store/users/selectors';
import { getThematicsByIds } from '../store/thematics/reselectors';
import { historyState } from '../utils/routeHelpers';
import isEqual from 'lodash/isEqual';

import './CurrentUserProfilePage.scss';

interface State {
  profileMenuModalIsOpen: boolean;
  completePageModalIsOpen: boolean;
  switchProfileModalIsOpen: boolean;
  addPageModalIsOpen: boolean;
  directRegistration?: boolean;
  viewEntered: boolean;
}

interface StateProps {
  currentUserIsLoading: boolean;
  currentUser: CurrentUser;
  currentUserAsUser: User | undefined;
  whoAmI: CurrentUser | null;
  managedUsersCollection: CollectionResponse<User> | undefined;
  currentUserObjectsOffers: HydratedCollection<FullPost>;
  currentUserServicesOffers: HydratedCollection<FullPost>;
  currentUserNeeds: HydratedCollection<FullPost>;
  isCurrentUser: isCurrentUserFunc;
  currentUserThematics: Thematic[];
  userArea?: Area;
}

const mapStateToProps = (state: RootState): StateProps => ({
  currentUser: getCurrentUser(state),
  currentUserAsUser: getUser(state, extractId(state.app.currentUser)),
  currentUserIsLoading: state.app.isUserLoading,
  whoAmI: getWhoAmIUser(state),
  managedUsersCollection: getManagedUsers(state),
  currentUserObjectsOffers: getUserPosts(state, extractId(state.app.currentUser), 'objectsOffers'),
  currentUserServicesOffers: getUserPosts(state, extractId(state.app.currentUser), 'servicesOffers'),
  currentUserNeeds: getUserPosts(state, extractId(state.app.currentUser), 'need'),
  isCurrentUser: createIsCurrentUserFunc(state),
  currentUserThematics: getThematicsByIds(state, state.app.currentUser.favoriteCategoryThematics),
  userArea: getUserArea(state),
});

interface DispatchProps {
  authenticateAsManagedUser(user: EntityReference): Promise<JWTToken>;
  authenticateAsWhoAmI(): void;
  fetchLoggedUser: () => Promise<void>;
  fetchManagedUsers: () => Promise<User[]>;
  fetchAllCurrentUserPosts: () => Promise<void>;
  fetchUserFollowers: (currentUserId: EntityReference) => Promise<void>;
  fetchThematics: () => Promise<void>;
  fetchPositiveUserReviewsNumber: (userId: EntityReference) => Promise<void>;
  setRegistrationOrganizationStep(registerOrganizationStep: string): void;
}

const propsToDispatch = {
  authenticateAsManagedUser: actions.app.authenticateAsManagedUser,
  authenticateAsWhoAmI: actions.whoAmI.authenticateAsWhoAmI,
  fetchLoggedUser: actions.app.fetchLoggedUser,
  fetchManagedUsers: actions.whoAmI.fetchManagedUsers,
  fetchAllCurrentUserPosts: () => actions.posts.fetchAllCurrentUserPosts(),
  fetchUserFollowers: (currentUserId: EntityReference) => actions.users.fetchUserFavorites(currentUserId, 'favoritedByUsers'),
  fetchThematics: actions.thematicsActions.fetchThematics,
  fetchPositiveUserReviewsNumber: actions.users.fetchPositiveUserPostRequestReviewsNumber,
  setRegistrationOrganizationStep: actions.app.setRegistrationOrganizationStepAction,
};

const mapDispatchToProps: (dispatch: Dispatch) => DispatchProps = (dispatch: Dispatch) => bindActionCreators(propsToDispatch, dispatch);

type CurrentUserProfileProps = StateProps & DispatchProps & WithTranslation & RouteComponentProps<Record<string, never>>;

class CurrentUserProfilePage extends Component<CurrentUserProfileProps, State> {
  // isMounted is used to avoid state update after that the component has been unmounted
  // TODO use callbacks to cancel ongoing promises -> https://fr.reactjs.org/blog/2015/12/16/ismounted-antipattern.html
  // In this page, isMounted is used to avoid state update from onClose MenuModal, triggered by a router change
  _isMounted = false;

  constructor(props: CurrentUserProfileProps) {
    super(props);
    this.state = {
      profileMenuModalIsOpen: false,
      completePageModalIsOpen: false,
      switchProfileModalIsOpen: false,
      addPageModalIsOpen: false,
      viewEntered: false,
    };
  }

  public componentDidMount(): void {
    this._isMounted = true;
    // only the fetches that have to be done once
    this.fetchData();

    if (this.props.history.location.state && (this.props.history.location.state as { pageMustBeCompleted: boolean }).pageMustBeCompleted) {
      this.toggleCompletePageModal(true);
      this.setState({ directRegistration: (this.props.history.location.state as { directRegistration: boolean }).directRegistration });
    }
  }

  // TODO Use a PureComponent instead and remove withRouter() in order to avoid useless rerenders
  public shouldComponentUpdate(nextProps: Readonly<CurrentUserProfileProps>, nextState: Readonly<State>): boolean {
    // Don't update the component if it is not displayed
    if (!this.state.viewEntered && !nextState.viewEntered) {
      return false;
    }

    return !isEqualIgnoringFunctions(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  public componentDidUpdate(prevProps: Readonly<CurrentUserProfileProps>): void {
    if (extractId(prevProps.currentUser) !== extractId(this.props.currentUser)) {
      this.refreshComponent();
    }

    if (!this.state.completePageModalIsOpen && this.props.history.location.state && (this.props.history.location.state as { pageMustBeCompleted: boolean }).pageMustBeCompleted) {
      this.toggleCompletePageModal(true);
      this.setState({ directRegistration: (this.props.history.location.state as { directRegistration: boolean }).directRegistration });
      this.props.history.replace('/me');
    }
  }

  // Add or remove from the dom when displaying or leaving page
  // For fix a bug on ionSlider - https://github.com/ionic-team/ionic-framework/issues/19638
  public ionViewWillEnter(): void {
    this.setState({ viewEntered: true });
    //if history state contains "openMenuModal", set profileMenuModalIsOpen to true for open menu
    if (!this.props.history.location?.state || this.props.history.length === 0) {
      return;
    }
    const state = this.props.history.location.state as historyState;
    this.setState({ profileMenuModalIsOpen: !!state?.openMenuModal });
  }

  public ionViewDidLeave(): void {
    if (!this._isMounted) {
      return;
    }
    this.setState({ viewEntered: false });
  }

  public componentWillUnmount(): void {
    this._isMounted = false;
    this.setState({ profileMenuModalIsOpen: false });
  }

  public render(): ReactNode {
    const { currentUser, currentUserIsLoading, currentUserThematics, t, userArea } = this.props;
    const currentLocation = userArea?.location as Location;
    const { viewEntered } = this.state;
    const currentUserIsManagedUser = !!currentUser.managed;

    if (currentUserIsLoading && !extractId(currentUser)) {
      return (
        <IonPage data-cy="current-user-page">
          <ProfilePagePlaceholder isCurrentUser={true} />
        </IonPage>
      );
    }

    if (!currentUser || !extractId(currentUser)) {
      return <Redirect to="/" />;
    }

    const user = this.props.currentUserAsUser;
    return (
      <IonPage data-cy="current-user-page">
        {user ? (
          <>
            <IonHeader className="current-user-profile-header">
              <IonToolbar no-border>
                <IonButton
                  className="user-button-switch"
                  fill="clear"
                  color="dark"
                  onClick={() => {
                    this.toggleSwitchProfileModal(true);
                    setStatusBarStyle(false);
                  }}
                >
                  <span className="user-button-switch_text">{currentUser.name}</span>
                  <IonIcon icon="/assets/navigation/chevron-down.svg" />
                </IonButton>
                <IonMenuToggle autoHide={false} slot="end" data-cy="burger-menu-button" className="burger-menu-button">
                  <IonMenuButton color="dark" autoHide={false} onClick={() => this.toggleProfileMenuModal(true)}>
                    <IonIcon icon="/assets/icon/menu.svg" color="dark" />
                  </IonMenuButton>
                </IonMenuToggle>
              </IonToolbar>
            </IonHeader>
            <IonContent className="current-user-profile">
              <IonCard className="profile-image">
                <IonCardContent className={user?.images?.length === 0 ? 'd-flex' : ''}>
                  {viewEntered && <UserMediaSlider isCurrentUser user={user} activeTab={undefined} />}
                  <div className="profile-image-description">
                    <IonLabel>
                      <IonText className="profile-image-title" color="light">
                        {currentUser.name}
                      </IonText>

                      {currentUser?.managedData?.certified && <CertifiedChip />}
                    </IonLabel>

                    <IonButtons>
                      <IonButton className="profile-image-button" routerLink="/me/pictures" data-cy="upload-user-images">
                        <IonIcon icon="/assets/profile-menu-icons/camera.svg" color="light" />
                      </IonButton>
                    </IonButtons>
                  </div>
                </IonCardContent>
              </IonCard>

              <IonRow responsive-sm className="common-margin">
                <IonCol>
                  <IonButton routerLink="/me/edit" className="main-button" color="primary" fill="outline">
                    <Trans i18nKey={currentUserIsManagedUser ? 'user.edit-managed-profile' : 'user.edit-profile'} />
                  </IonButton>
                </IonCol>
              </IonRow>

              {user?.managedData?.type === organizationTypePublicSector && user.managedData?.publicSectorData?.cityKey && user.managedData?.publicSectorData?.cityCodes?.length !== 0 && (
                <IonRow responsive-sm className="common-margin">
                  <IonCol>
                    <IonButton
                      href={`${cityUrl}${user['@id'].replace('/users', '')}/${user.managedData.publicSectorData?.cityKey}`}
                      target="_blank"
                      className="main-button dashboard-button"
                      color="primary"
                    >
                      <Trans i18nKey={'user.dashboard-summary'} />
                    </IonButton>
                  </IonCol>
                </IonRow>
              )}

              {!!currentUser.status?.completedPostRequestsAsHelper && user?.managedData?.type !== organizationTypePublicSector && (
                <UserSocialImpact isCurrentUser completedPostRequestsAsHelper={currentUser.status?.completedPostRequestsAsHelper} user={user} />
              )}

              <UserExperience user={user} currentUser={currentUser} />

              {!!currentUserThematics?.length && <UserThematics thematics={currentUserThematics} title={this.props.t('user.tags-you-follow')} />}

              <UserOverview user={user} currentUser={currentUser} />

              {currentUser.aboutMe && <AboutInfoComponent title="aboutMe" description={currentUser.aboutMe} />}

              {currentUser.aboutMeOnIndigo && <AboutInfoComponent title="aboutMeOnIndigo" description={currentUser.aboutMeOnIndigo} />}

              <ProfileMenuModal isOpen={this.state.profileMenuModalIsOpen} closeModal={() => this.toggleProfileMenuModal(false)} currentUserIsManagedUser={currentUserIsManagedUser} />
              <CompletePageModal directRegistration={this.state.directRegistration} isOpen={this.state.completePageModalIsOpen} closeModal={() => this.toggleCompletePageModal(false)} />
              <SwitchProfileModal
                isOpen={this.state.switchProfileModalIsOpen}
                closeModal={() => {
                  this.toggleSwitchProfileModal(false);
                  setStatusBarStyle();
                }}
                whoAmI={this.props.whoAmI}
                managedUsers={this.props.managedUsersCollection?.items}
                currentUser={this.props.currentUser}
                authenticateAsWhoAmI={this.props.authenticateAsWhoAmI}
                authenticateAsManagedUser={this.props.authenticateAsManagedUser}
                openAddPageModal={() => {
                  this.toggleAddPageModal(true);
                  this.props.setRegistrationOrganizationStep('cguStep'); // Be sure to have a new page creation form when the user click on this button
                }}
              />
              <AddPageModal isOpen={this.state.addPageModalIsOpen} closeModal={() => this.toggleAddPageModal(false)} />
              <UserPostsSlider
                collectionName="objectsOffers"
                postsCollection={this.props.currentUserObjectsOffers}
                title={t('user.my-objects-offers')}
                currentLocation={currentLocation}
                isCurrentUser={this.props.isCurrentUser}
                userId={extractId(this.props.currentUser)}
                dataCy="objects-offers"
              />
              <UserPostsSlider
                collectionName="servicesOffers"
                postsCollection={this.props.currentUserServicesOffers}
                title={t('user.my-services-offers')}
                currentLocation={currentLocation}
                isCurrentUser={this.props.isCurrentUser}
                userId={extractId(this.props.currentUser)}
                dataCy="services-offers"
              />
              <UserPostsSlider
                collectionName="need"
                postsCollection={this.props.currentUserNeeds}
                title={t('user.my-needs')}
                currentLocation={currentLocation}
                isCurrentUser={this.props.isCurrentUser}
                userId={extractId(this.props.currentUser)}
                dataCy="needs"
              />
            </IonContent>
          </>
        ) : (
          <ProfilePagePlaceholder isCurrentUser={true} />
        )}
      </IonPage>
    );
  }

  private async fetchData(): Promise<void> {
    await this.props.fetchLoggedUser();

    if (!this.props.currentUser || !extractId(this.props.currentUser)) {
      // The current user request is in progress
      return;
    }

    if (this.props.whoAmI && extractId(this.props.currentUser) === extractId(this.props.whoAmI)) {
      this.props.fetchManagedUsers();
    }

    this.props.fetchThematics();
    this.props.fetchAllCurrentUserPosts();
    this.props.fetchUserFollowers(extractId(this.props.currentUser));
    this.props.fetchPositiveUserReviewsNumber(extractId(this.props.currentUser));
  }

  private refreshComponent = (): void => {
    if (!this.props.currentUser || !extractId(this.props.currentUser)) {
      // The current user request is in progress
      return;
    }

    // TODO Check if data is in store : isDataFresh currently not working
    this.props.fetchAllCurrentUserPosts();
    this.props.fetchUserFollowers(extractId(this.props.currentUser));
    this.props.fetchPositiveUserReviewsNumber(extractId(this.props.currentUser));
  };

  private toggleProfileMenuModal = (value: boolean): void => {
    if (this._isMounted) {
      this.setState({ profileMenuModalIsOpen: value });
    }
  };

  private toggleCompletePageModal = (value: boolean): void => {
    if (this._isMounted) {
      this.setState({ completePageModalIsOpen: value });
    }
  };

  private toggleAddPageModal = (value: boolean): void => {
    if (this._isMounted) {
      this.setState({ addPageModalIsOpen: value });
      setStatusBarStyle(!value);
    }
  };

  private toggleSwitchProfileModal = (value: boolean): void => {
    if (this._isMounted) {
      this.setState({ switchProfileModalIsOpen: value });
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withIonLifeCycle(withRouterAndRef(CurrentUserProfilePage))));
