import { EntityReference, HydratedCollection, ReferencedCollection } from '../../utils/hydra';
import { denormalizeEntityCollection } from '../entities/selectors';
import { RootState } from '../index';
import { extractId } from '../../utils/helpers';
import { createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';
import { EntitiesState } from '../entities/types';
import { getEntities } from '../app/selectors';
import { UserNotification } from './types';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

const getUserNotificationCollection = (state: RootState): ReferencedCollection => state.notifications.currentUserNotifications;
const getNotificationId = (state: RootState, props: { notificationId: EntityReference | null }): EntityReference | null => props.notificationId;

export const getHydratedUserNotification = createDeepEqualSelector([getUserNotificationCollection, getEntities], (coll: ReferencedCollection, entities: EntitiesState) =>
  denormalizeEntityCollection<UserNotification>(entities, coll),
);

export const getUserNotificationByNotificationId = createDeepEqualSelector(
  [getHydratedUserNotification, getNotificationId],
  (coll: HydratedCollection<UserNotification>, notificationId): UserNotification | undefined => coll.items.find(userNotification => extractId(userNotification.notification) === notificationId),
);
