import React, { ReactNode } from 'react';
import { IonHeader, IonToolbar, IonButtons, IonTitle, IonButton, IonIcon } from '@ionic/react';
import BackButton from './BackButton';
import RoutingGuard from './common/RoutingGuard';
import './CommonHeader.scss';

interface StateProps {
  title?: string | React.ReactElement;
  className?: string;
  dataCy?: string;
  openMenu?: () => void;
  menuIcon?: string;
  menuDataCy?: string;
  onBackButtonClick?: () => void;
  stopPropagation?: boolean;
  backButton?: ReactNode;
  nextButton?: ReactNode;
  isBackButtonDisabled?: boolean;
  addIonHeader?: boolean;
}

type Props = StateProps;

const CommonHeader: React.FunctionComponent<Props> = ({
  title,
  className,
  dataCy,
  openMenu,
  menuIcon,
  menuDataCy,
  onBackButtonClick,
  stopPropagation,
  backButton,
  nextButton,
  isBackButtonDisabled,
  addIonHeader,
}: Props) => {
  const headerContent = (
    <IonToolbar className={`common-header ${className ? className : ''}`} data-cy={dataCy || 'common-header'}>
      <IonButtons slot="start" onClick={onBackButtonClick}>
        {backButton ? backButton : <BackButton isBackButtonDisabled={isBackButtonDisabled} dataCy="common-header-back-button" stopPropagation={stopPropagation} />}
      </IonButtons>
      <IonTitle data-cy="common-header-title" color="dark">
        {title}
      </IonTitle>
      <IonButtons slot="end">
        {openMenu && menuIcon && (
          <RoutingGuard>
            <IonButton data-cy={menuDataCy} onClick={openMenu} color="dark">
              <IonIcon slot="icon-only" icon={menuIcon} />
            </IonButton>
          </RoutingGuard>
        )}
        {nextButton}
      </IonButtons>
    </IonToolbar>
  );

  if (addIonHeader) {
    return <IonHeader>{headerContent}</IonHeader>;
  }

  return headerContent;
};

export default React.memo(CommonHeader);
