import { RouterDirection } from '@ionic/react';
import { Dispatch } from 'redux';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { UserFormValues } from '../../components/UserForm';
import { unregisterNotificationListeners } from '../../pushNotifications';
import { getLocationFromGoogleMapsLatLngLiteral } from '../../utils/geolocationHelpers';
import { majorUpgradeIsAvailable, minorUpgradeIsAvailable } from '../../utils/upgradeHelper';
import { normalizeAndDispatchEntity } from '../entities/selectors';
import { setUpgradeLayoutStatusAction } from '../layout/actions';
import { flattenUser } from '../users/actions';
import { AppVersions, DeviceToken, FF, FFValue, IndigoDevientDonnonsData, IndigoFermeBannerData, IndigoFermetureData, JWTToken, Media } from './types';
import { ThunkResult } from '../types';
import { hydraFetch, initFetchOptions, jsonFetch, throwErrorIfAny } from '../../utils/dataAccess';
import { fetchWithAuth, fetchWithAuthAsWhoAmI } from '../../utils/authDataAccess';
import { facebookLogout, isFacebookEnabled } from '../../utils/facebookAccess';
import { extractId, isSameHydraEntity, promiseTimeout, resizeImage } from '../../utils/helpers';
import { EntityReference } from '../../utils/hydra';
import { createCurrentUserByDefaultKeys, createUserByDefaultKeys, defaultCurrentUserPersonalData, defaultManagedData } from '../users/reducer';
import { Area, CurrentUser, CurrentUserApiFields, CurrentUserEditFields, CurrentUserFields, CurrentUserPersonalDataFields, CurrentUserStatus, ManagedDataFields, UserApiFields } from '../users/types';
import { Address } from '../posts/types';
import { itemIsAMedia } from '../../components/inputs/InputMediaUploader';
import { errorIsSubmissionError } from '../../utils/dataAccessError';
import * as whoAmIActions from '../whoAmI/actions';
import moment from 'moment';
import isEqual from 'lodash/isEqual';

export const userLoginAction = createAsyncAction('user/LOGIN_REQUEST', 'user/LOGIN_SUCCESS', 'user/LOGIN_FAILURE')<void, JWTToken | null, Error>();

export const userRefreshTokenAction = createAsyncAction('user/REFRESH_TOKEN_REQUEST', 'user/REFRESH_TOKEN_SUCCESS', 'user/REFRESH_TOKEN_FAILURE')<void, JWTToken | null, Error>();

export const managedUserLoginAction = createAsyncAction('user/MANAGED_LOGIN_REQUEST', 'user/MANAGED_LOGIN_SUCCESS', 'user/MANAGED_LOGIN_FAILURE')<void, JWTToken | null, Error>();

export const setIsFullyLoggedInAction = createAction('app/SET_IS_FULLY_LOGGED_IN', (isFullyLoggedIn: boolean) => isFullyLoggedIn)();

export const setIsUserFirstLoginAction = createAction('app/SET_IS_USER_FIRST_LOGIN', (isUserFirstLogin: boolean) => isUserFirstLogin)();

export const homepageWasVisited = createAction('app/HOMEPAGE_WAS_VISITED')();

export const userRegisterAction = createAsyncAction('user/REGISTER_REQUEST', 'user/REGISTER_SUCCESS', 'user/REGISTER_FAILURE')<void, CurrentUser | null, Error>();

export const userManagedRegisterAction = createAsyncAction('user/MANAGED_REGISTER_REQUEST', 'user/MANAGED_REGISTER_SUCCESS', 'user/MANAGED_REGISTER_FAILURE')<void, CurrentUser | null, Error>();

export const editUserAction = createAsyncAction('user/EDIT_REQUEST', 'user/EDIT_SUCCESS', 'user/EDIT_FAILURE')<void, CurrentUser | null, Error>();

export const userPasswordRecoveryAction = createAsyncAction('user/PASSWORD_RECOVERY', 'user/PASSWORD_RECOVERY_SUCCESS', 'user/PASSWORD_RECOVERY_FAILURE')<void, JWTToken | null, Error>();

export const userPasswordResetAction = createAsyncAction('user/PASSWORD_RESET', 'user/PASSWORD_RESET_SUCCESS', 'user/PASSWORD_RESET_FAILURE')<void, JWTToken | null, Error>();

export const fetchLoggedUserAction = createAsyncAction('loggedUser/FETCH_REQUEST', 'loggedUser/FETCH_SUCCESS', 'loggedUser/FETCH_FAILURE')<void, CurrentUser, Error>();

export const createDeviceTokenAction = createAsyncAction('app/CREATE_DEVICE_TOKEN_REQUEST', 'app/CREATE_DEVICE_TOKEN_SUCCESS', 'app/CREATE_DEVICE_TOKEN_FAILURE')<void, DeviceToken, Error>();

export const registerNotificationListenersAction = createAction('app/REGISTER_NOTIFICATION_LISTENERS')();

export const linkUserToThirdPartyAction = createAsyncAction('user/THIRD_PARTY_LINK_REQUEST', 'user/THIRD_PARTY_LINK_SUCCESS', 'user/THIRD_PARTY_LINK_FAILURE')<void, CurrentUser, Error>();

export const unlinkUserToFacebookAction = createAsyncAction('user/UNLINK_REQUEST', 'user/UNLINK_SUCCESS', 'user/UNLINK_FAILURE')<void, CurrentUser, Error>();

export const addUserThematicsAction = createAsyncAction('user/ADD_USER_THEMATICS_REQUEST', 'user/ADD_USER_THEMATICS_SUCCESS', 'user/ADD_USER_THEMATICS_FAILURE')<void, EntityReference[], Error>();

export const updateUserThematicsAction = createAsyncAction('user/UPDATE_THEMATICS_REQUEST', 'user/UPDATE_THEMATICS_SUCCESS', 'user/UPDATE_THEMATICS_FAILURE')<void, CurrentUser, Error>();

export const deleteMediaObjectAction = createAsyncAction('app/DELETE_MEDIA_OBJECT_REQUEST', 'app/DELETE_MEDIA_OBJECT_SUCCESS', 'app/DELETE_MEDIA_OBJECT_FAILURE')<void, void, Error>();

/* This action will dispatch an update user action to save the new status */
export const updateCurrentUserStatus = createAction('user/UPDATE_CURRENT_USER_STATUS', (newCurrentUserStatus: Partial<CurrentUserStatus>) => newCurrentUserStatus)();

export const userHelpRequestAction = createAsyncAction('user/HELP', 'user/HELP_SUCCESS', 'user/HELP_FAILURE')<void, JWTToken | null, Error>();

export const setRouterDirectionAction = createAction('app/ROUTER_DIRECTION', (routerDirection: RouterDirection) => routerDirection)();

export const resetAppForNewUserAction = createAction('RESET_APP_FOR_NEW_USER')();

export const resetAppAction = createAction('RESET_APP')();

export const setRegistrationOrganizationStepAction = createAction('SET_REGISTRATION_ORGANIZATION_STEP', (registerOrganisationStep: string) => registerOrganisationStep)();

export const fetchAppVersionAction = createAsyncAction('app/FETCH_APP_VERSION_REQUEST', 'app/FETCH_APP_VERSION_SUCCESS', 'app/FETCH_APP_VERSION_FAILURE')<void, AppVersions, Error>();

export const fetchFFAction = createAsyncAction('app/FETCH_FF_REQUEST', 'app/FETCH_FF_SUCCESS', 'app/FETCH_FF_FAILURE')<void, FF, Error>();

export const setFFIndigoDevientDonnonsLastDate = createAction('app/SET_FF_INDIGO_DEVIENT_DONNONS_LAST_DATE', (type: 'homepage' | 'give', date: Date) => ({ type, date }))();

export const setFFIndigoFermeBannerLastDate = createAction('app/SET_FF_INDIGO_FERME_LAST_DATE', (date: Date) => ({ date }))();

export const setUserAreaAction = createAction('app/SET_USER_AREA', (userArea: Area | undefined) => userArea)();

export const deleteOneUserAreaHistoryAction = createAction('app/DELETE_ONE_USER_AREA_HISTORY', (deleteOneUserAreaHistory: Address | undefined) => deleteOneUserAreaHistory)();

export const setAddPostModalIsOpenAction = createAction('SET_ADD_POST_MODAL_IS_OPEN', (addPostModalIsOpen: boolean) => addPostModalIsOpen)();

export const setPopoverConnectionIsOpenAction = createAction('SET_POPOVER_CONNECTION_IS_OPEN', (popoverConnectionIsOpen: boolean) => popoverConnectionIsOpen)();

export const setLastCreatedPostIdAction = createAction('SET_LAST_CREATED_POST_ID', (lastCreatedPostId: EntityReference) => lastCreatedPostId)();

export function createDeviceTokenResource(token: string, platform: string): ThunkResult<Promise<DeviceToken>> {
  return async (dispatch: Dispatch): Promise<DeviceToken> => {
    dispatch(createDeviceTokenAction.request());

    return fetchWithAuth('/users/me/devices', {
      method: 'POST',
      data: {
        token: token,
        platform: platform,
      },
    })
      .then(response => response.json() as Promise<DeviceToken>)
      .then(data => {
        dispatch(createDeviceTokenAction.success(data));
        return data;
      })
      .catch((e: Error) => {
        dispatch(createDeviceTokenAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function deleteDeviceTokenResource(tokenId: string): Promise<Response> {
  const options = initFetchOptions({ method: 'DELETE' });
  return hydraFetch(tokenId, options);
}

export function logout(): ThunkResult<Promise<void>> {
  return async (dispatch: Dispatch, getState) => {
    if (isFacebookEnabled()) {
      try {
        await promiseTimeout(3000, facebookLogout());
      } catch (e) {
        console.error(e);
      }
    }

    try {
      await unregisterNotificationListeners();
    } catch (e) {
      console.error(e);
    }

    const deviceToken = getState().app.deviceToken;
    if (deviceToken) {
      await deleteDeviceTokenResource(extractId(deviceToken)).catch(console.error);
    }

    setTimeout(() => {
      dispatch(resetAppAction());
    });

    localStorage.removeItem('promotionalWelcomeModalLastDisplayDate');
  };
}

export function authenticateAsManagedUser(user: EntityReference): ThunkResult<Promise<JWTToken>> {
  return async (dispatch): Promise<JWTToken> => {
    dispatch(managedUserLoginAction.request());

    return fetchWithAuthAsWhoAmI('/auth/managed_token', {
      method: 'POST',
      data: { user },
    })
      .then(throwErrorIfAny)
      .then(response => response.json() as Promise<JWTToken>)
      .then(data => {
        dispatch(managedUserLoginAction.success(data));
        dispatch(resetAppForNewUserAction());
        return data;
      })
      .catch(e => {
        dispatch(managedUserLoginAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function authenticateWithCredentials(username: string, password: string): ThunkResult<Promise<JWTToken>> {
  return async (dispatch: Dispatch): Promise<JWTToken> => {
    dispatch(userLoginAction.request());

    return jsonFetch('/auth/token', {
      method: 'POST',
      data: {
        username,
        password,
      },
    })
      .then(throwErrorIfAny)
      .then(response => response.json() as Promise<JWTToken>)
      .then(data => {
        dispatch(userLoginAction.success(data));
        return data;
      })
      .catch(e => {
        dispatch(userLoginAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function authenticateWithFacebook(accessToken: string): ThunkResult<Promise<JWTToken>> {
  return async (dispatch: Dispatch): Promise<JWTToken> => {
    dispatch(userLoginAction.request());

    return jsonFetch('/auth/facebook', {
      method: 'POST',
      data: { accessToken },
    })
      .then(throwErrorIfAny)
      .then(response => response.json() as Promise<JWTToken>)
      .then(data => {
        dispatch(userLoginAction.success(data));
        return data;
      })
      .catch(e => {
        dispatch(userLoginAction.failure(e));

        return Promise.reject(e);
      });
  };
}

export function authenticateWithApple(accessToken: string): ThunkResult<Promise<JWTToken>> {
  return async (dispatch: Dispatch): Promise<JWTToken> => {
    dispatch(userLoginAction.request());

    return jsonFetch('/auth/apple', {
      method: 'POST',
      data: { accessToken },
    })
      .then(throwErrorIfAny)
      .then(response => response.json() as Promise<JWTToken>)
      .then(data => {
        dispatch(userLoginAction.success(data));
        return data;
      })
      .catch(e => {
        dispatch(userLoginAction.failure(e));

        return Promise.reject(e);
      });
  };
}

export function authenticateWithRefreshToken(refreshToken: string, authenticateAsWhoAmI?: boolean): ThunkResult<Promise<JWTToken>> {
  return async (dispatch: Dispatch, getState): Promise<JWTToken> => {
    dispatch(userRefreshTokenAction.request());

    return jsonFetch('/auth/refresh', {
      method: 'POST',
      data: { refreshToken },
    })
      .then(throwErrorIfAny)
      .then(response => response.json() as Promise<JWTToken>)
      .then(data => {
        dispatch(userRefreshTokenAction.success(data));
        if (!getState().app.currentUser.managed || authenticateAsWhoAmI) {
          dispatch(whoAmIActions.updateWhoAmITokenAction(data));
        }
        return data;
      })
      .catch(e => {
        dispatch(userRefreshTokenAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function authenticateAsWhoAmIWithRefreshToken(refreshToken: string): ThunkResult<Promise<JWTToken>> {
  return authenticateWithRefreshToken(refreshToken, true);
}

interface CurrentUserValues extends Omit<UserFormValues, 'avatar' | 'images'> {
  avatar: EntityReference | null;
  images: EntityReference[];
  personalData: CurrentUserPersonalDataFields;
}

function getUpdatedObjectKeys(
  user: Partial<CurrentUserValues>,
  objectKey: 'managedData' | 'personalData',
  keys: (keyof CurrentUserPersonalDataFields | keyof ManagedDataFields)[],
): Partial<CurrentUserValues> {
  keys.forEach(key => {
    if (Object.prototype.hasOwnProperty.call(user, key)) {
      if (typeof user[objectKey] === 'undefined') {
        user[objectKey] = {} as CurrentUserPersonalDataFields;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (user[objectKey] as any)[key] = user[key];
      delete user[key];
    }
  });
  return user;
}

const userToApiValues = (user: CurrentUserFields): Partial<CurrentUserValues> => {
  let values: Partial<CurrentUserValues> = Object.assign({}, user, {
    avatar: user.avatar === null ? null : undefined,
    images: typeof user.images !== undefined && user.images ? [] : undefined,
    confirmPassword: undefined,
    aboutMe: user.aboutMe !== undefined ? (user.aboutMe || '').trim() : undefined,
    aboutMeOnIndigo: user.aboutMeOnIndigo !== undefined ? (user.aboutMeOnIndigo || '').trim() : undefined,
  });

  if (Object.prototype.hasOwnProperty.call(user, 'avatar') && user.avatar) {
    values.avatar = extractId(user.avatar as Media) || null;
  }

  if (Object.prototype.hasOwnProperty.call(user, 'images') && user.images) {
    values.images = (user.images as Media[]).filter((item: Media | Record<string, unknown>) => itemIsAMedia(item)).map((media: Media) => media['@id']);
  }

  const managedDataKeys = Object.keys(defaultManagedData) as (keyof ManagedDataFields)[];
  values = getUpdatedObjectKeys(values, 'managedData', managedDataKeys);

  const personalDataKeys = Object.keys(defaultCurrentUserPersonalData) as (keyof CurrentUserPersonalDataFields)[];
  values = getUpdatedObjectKeys(values, 'personalData', personalDataKeys);

  return values;
};

export function flattenCurrentUser(data: CurrentUserApiFields): CurrentUser {
  return flattenUser(data as UserApiFields) as CurrentUser;
}

export function registerUser(currentUser: CurrentUserEditFields): ThunkResult<Promise<CurrentUser>> {
  return async (dispatch): Promise<CurrentUser> => {
    dispatch(userRegisterAction.request());

    return hydraFetch('/users', {
      method: 'POST',
      data: userToApiValues(currentUser),
    })
      .then(response => response.json() as Promise<CurrentUserApiFields>)
      .then(data => flattenCurrentUser(data))
      .then(data => {
        dispatch(userRegisterAction.success(createCurrentUserByDefaultKeys(data)));
        dispatch(
          setUserAreaAction({
            address: data.address,
            location: getLocationFromGoogleMapsLatLngLiteral(data.addressLocation),
          }),
        );
        normalizeAndDispatchEntity(createUserByDefaultKeys(data), dispatch);
        return data;
      })
      .catch(e => {
        dispatch(userRegisterAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function registerNewUser(currentUser: CurrentUserEditFields): ThunkResult<Promise<CurrentUser>> {
  return async (): Promise<CurrentUser> => {
    return (
      fetchWithAuth('/users', {
        method: 'POST',
        data: userToApiValues(currentUser),
      })
        //uses CurrentUserApiFields because the endpoint call return values similar to a currentUser
        .then(response => response.json() as Promise<CurrentUserApiFields>)
        .then(data => flattenCurrentUser(data))
    );
  };
}

export function registerManagedUser(currentUser: CurrentUserEditFields): ThunkResult<Promise<CurrentUser>> {
  return async (dispatch, getState): Promise<CurrentUser> => {
    dispatch(userManagedRegisterAction.request());

    const headers = new Headers();
    headers.set('Authorization', 'Bearer ' + getState().whoAmI.whoAmIToken?.token);
    return hydraFetch('/users/managed', {
      method: 'POST',
      headers,
      data: userToApiValues(currentUser),
    })
      .then(response => response.json() as Promise<CurrentUserApiFields>)
      .then(data => flattenCurrentUser(data))
      .then(data => {
        dispatch(userManagedRegisterAction.success(createCurrentUserByDefaultKeys(data, true)));
        dispatch(
          setUserAreaAction({
            address: data.address,
            location: getLocationFromGoogleMapsLatLngLiteral(data.addressLocation),
          }),
        );
        normalizeAndDispatchEntity(createUserByDefaultKeys(data), dispatch);
        return data;
      })
      .catch(e => {
        dispatch(userManagedRegisterAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function updateUser(user: CurrentUserEditFields): ThunkResult<Promise<CurrentUser>> {
  return async (dispatch, getState): Promise<CurrentUser> => {
    dispatch(editUserAction.request());
    if (undefined === user['@id']) {
      return Promise.reject();
    }

    return fetchWithAuth(user['@id'] as EntityReference, {
      method: 'PUT',
      data: userToApiValues(user),
    })
      .then(response => response.json() as Promise<CurrentUserApiFields>)
      .then(data => flattenCurrentUser(data))
      .then(data => {
        // If the user address changed, we update the userArea
        if (data.address && data.addressLocation && !isEqual(data.address, getState().whoAmI.whoAmIUser?.address)) {
          dispatch(setUserAreaAction({ address: data.address, location: getLocationFromGoogleMapsLatLngLiteral(data.addressLocation) }));
        }
        return data;
      })
      .then(data => {
        dispatch(editUserAction.success(createCurrentUserByDefaultKeys(data, !!data.managed)));
        normalizeAndDispatchEntity(createUserByDefaultKeys(data), dispatch);
        if (isSameHydraEntity(user, getState().whoAmI.whoAmIUser)) {
          dispatch(whoAmIActions.updateWhoAmIAction(createCurrentUserByDefaultKeys(data)));
        }
        return data;
      })
      .catch(e => {
        dispatch(editUserAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function updateCurrentUserThematics(thematics: EntityReference[]): ThunkResult<Promise<void>> {
  return async (dispatch, getState): Promise<void> => {
    const userId: EntityReference = extractId(getState().app.currentUser);
    if (userId === undefined) {
      return;
    }

    dispatch(updateUserThematicsAction.request());
    return fetchWithAuth(userId, {
      method: 'PUT',
      data: { favoriteCategoryThematics: thematics },
    })
      .then(response => response.json() as Promise<CurrentUserApiFields>)
      .then(data => flattenCurrentUser(data))
      .then(data => {
        dispatch(updateUserThematicsAction.success(createCurrentUserByDefaultKeys(data)));
        normalizeAndDispatchEntity(createUserByDefaultKeys(data), dispatch);
      })
      .catch(e => {
        dispatch(updateUserThematicsAction.failure(e));
      });
  };
}

export function fetchLoggedUser(): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(fetchLoggedUserAction.request());

    return fetchWithAuth('/users/me')
      .then(response => response.json() as Promise<CurrentUserApiFields>)
      .then(data => flattenCurrentUser(data))
      .then(data => {
        const loggedUser = createCurrentUserByDefaultKeys(data, data.managed);
        normalizeAndDispatchEntity(createUserByDefaultKeys(data), dispatch);
        dispatch(fetchLoggedUserAction.success(loggedUser));
        if (!loggedUser.managed && !loggedUser.managedData) {
          dispatch(whoAmIActions.updateWhoAmIAction(loggedUser));
        }
      })
      .catch(e => {
        dispatch(fetchLoggedUserAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function linkThirdPartyUser(accessToken: string | null, thirdPartyName: string): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(linkUserToThirdPartyAction.request());

    return fetchWithAuth(`/users/me/${thirdPartyName}/link`, {
      method: 'PUT',
      data: { accessToken },
    })
      .then(response => response.json() as Promise<CurrentUserApiFields>)
      .then(data => flattenCurrentUser(data))
      .then(data => {
        dispatch(linkUserToThirdPartyAction.success(data));
      })
      .catch(e => {
        dispatch(linkUserToThirdPartyAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function unlinkThirdPartyUser(thirdPartyName: string): ThunkResult<Promise<void>> {
  return async (dispatch): Promise<void> => {
    dispatch(unlinkUserToFacebookAction.request());

    return fetchWithAuth(`/users/me/${thirdPartyName}/unlink`, {
      method: 'PUT',
    })
      .then(response => response.json() as Promise<CurrentUserApiFields>)
      .then(data => flattenCurrentUser(data))
      .then(data => {
        dispatch(unlinkUserToFacebookAction.success(data));
      })
      .catch(e => {
        dispatch(unlinkUserToFacebookAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export async function uploadMediaFile(media: File, createdAtAddedSeconds: number): Promise<Media> {
  const formData = new FormData();
  let img;
  let imageName = media.name;

  try {
    img = await resizeImage(media);
    if (img.type && img.type !== media.type) {
      // If the resize function returns another type of image, we must change its extension.
      imageName = imageName.substr(0, imageName.lastIndexOf('.')) + '.' + img.type.replace('image/', '');
    }
  } catch (e) {
    console.error('Error when resizing image', e);
    img = media;
  }

  // We set the createdAt value in order to have the good order of pictures when retrieving the post images
  formData.append('createdAt', moment().add(createdAtAddedSeconds, 'second').toISOString());
  formData.append('file', img, imageName);

  return fetchWithAuth('/media_objects', {
    method: 'POST',
    body: formData,
  })
    .then(response => response.json() as Promise<Media>)
    .catch((e: Error) => {
      const error = errorIsSubmissionError(e) && e.errors.file ? new Error(e.errors.file) : e;

      return Promise.reject(error);
    });
}

export function deleteMediaObject(media: Media): ThunkResult<Promise<void>> {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(deleteMediaObjectAction.request());

    return fetchWithAuth(extractId(media), {
      method: 'DELETE',
    })
      .then(() => {
        dispatch(deleteMediaObjectAction.success());
      })
      .catch((e: Error) => {
        const error = errorIsSubmissionError(e) && e.errors.file ? new Error(e.errors.file) : e;
        dispatch(deleteMediaObjectAction.failure(error));

        return Promise.reject(error);
      });
  };
}

export function requestNewPasswordAction(email: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(userPasswordRecoveryAction.request());

    return hydraFetch('/auth/forgot_password_requests', {
      method: 'POST',
      data: {
        email,
      },
    })
      .then(() => {
        dispatch(userPasswordRecoveryAction.success(null));
      })
      .catch(e => {
        dispatch(userPasswordRecoveryAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function requestResetPasswordAction(newpassword: string, token: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(userPasswordResetAction.request());

    return hydraFetch('/users/me', {
      method: 'PUT',
      data: { plainPassword: newpassword },
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/ld+json',
      }),
    })
      .then(() => {
        dispatch(userPasswordResetAction.success(null));
      })
      .catch(e => {
        dispatch(userPasswordResetAction.failure(e));
        return Promise.reject(e);
      });
  };
}

function uploadMediaList(files: (File | Media)[]): Promise<Media | Error>[] {
  const mediaPromises: Promise<Media | Error>[] = [];

  for (let i = 0; i < files.length; i++) {
    if (itemIsAMedia(files[i])) {
      mediaPromises.push(new Promise(resolve => resolve(files[i] as Media)));
      continue;
    }

    mediaPromises.push(uploadMediaFile(files[i] as File, i).catch(e => e));
  }

  return mediaPromises;
}

export async function uploadMediaListAndGetErrors(files: (File | Media)[]): Promise<(Media | Error)[]> {
  return Promise.all<Media | Error>(uploadMediaList(files));
}

export function helpRequestAction(email: string, subject: string, message: string) {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(userHelpRequestAction.request());

    return hydraFetch('/auth/help_requests', {
      method: 'POST',
      data: {
        email,
        subject,
        message,
      },
    })
      .then(() => {
        dispatch(userHelpRequestAction.success(null));
      })
      .catch(e => {
        dispatch(userHelpRequestAction.failure(e));
        return Promise.reject(e);
      });
  };
}

export function fetchAppVersion() {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(fetchAppVersionAction.request());

    return hydraFetch('/app_version', {
      method: 'GET',
    })
      .then(response => response.json() as Promise<AppVersions>)
      .then(data => {
        dispatch(setUpgradeLayoutStatusAction({ showAlert: minorUpgradeIsAvailable(data), showModal: majorUpgradeIsAvailable(data) }));
        dispatch(fetchAppVersionAction.success(data));
      })
      .catch(e => {
        dispatch(fetchAppVersionAction.failure(e));
        return Promise.reject(e);
      });
  };
}

interface GitlabFFStrategyScope {
  id: number;
  environment_scope: string;
}

interface GitlabFFStrategy {
  id: number;
  name: string;
  parameters: Record<string, never>;
  scopes: GitlabFFStrategyScope[];
  user_list: null;
}

interface GitlabFFResponse {
  name: string;
  description: string;
  active: boolean;
  version: string;
  created_at: string;
  updated_at: string;
  scopes: [];
  strategies: GitlabFFStrategy[];
}

const finder = (data: GitlabFFResponse[], type: string): FFValue[] => {
  const find = data.find(res => res.name === type);

  if (!find) return [];

  return find.strategies[0].scopes.reduce((acc, val) => {
    if (val.environment_scope === 'preprod') {
      return [...acc, 'preprod' as FFValue];
    } else if (val.environment_scope === 'production') {
      return [...acc, 'production' as FFValue];
    } else {
      return acc;
    }
  }, [] as FFValue[]);
};

export function fetchFF() {
  return async (dispatch: Dispatch): Promise<void> => {
    dispatch(fetchFFAction.request());

    const [indigoDevientDonnonsData, indigoFermetureData, indigoFermeBannerData] = await Promise.all([
      fetch('https://s3.eu-west-1.amazonaws.com/assets.indigo.world/tmp/indigo_devient_donnons.json')
        .then(res => res.json() as Promise<IndigoDevientDonnonsData>)
        .catch(() => null),
      fetch('https://s3.eu-west-1.amazonaws.com/assets.indigo.world/tmp/indigo_fermeture.json')
        .then(res => res.json() as Promise<IndigoFermetureData>)
        .catch(() => null),
      fetch('https://s3.eu-west-1.amazonaws.com/assets.indigo.world/tmp/indigo_ferme_banner.json')
        .then(res => res.json() as Promise<IndigoFermeBannerData>)
        .catch(() => null),
    ]);

    return fetch('https://gitlab.com/api/v4/projects/50755569/feature_flags', {
      headers: {
        'PRIVATE-TOKEN': 'glpat-nyHJYyKh_37cRuanNxg2',
      },
    })
      .then(response => response.json() as Promise<GitlabFFResponse[]>)
      .then(data => {
        const indigoDevientDonnonsEnvs = finder(data, 'indigo_devient_donnons');
        const indigoFermetureEnvs = finder(data, 'indigo_fermeture');
        const indigoFermeBannerEnvs = finder(data, 'indigo_ferme_banner');

        const ff = {
          indigo_devient_donnons: {
            envs: indigoDevientDonnonsEnvs,
            data: indigoDevientDonnonsData,
            lastDate: {
              homepage: null,
              give: null,
            },
          },
          indigo_fermeture: {
            envs: indigoFermetureEnvs,
            data: indigoFermetureData,
          },
          indigo_ferme_banner: {
            envs: indigoFermeBannerEnvs,
            data: indigoFermeBannerData,
            lastDate: null,
          },
        } satisfies FF;

        dispatch(fetchFFAction.success(ff));
      })
      .catch(e => {
        dispatch(fetchFFAction.failure(e));
        return Promise.reject(e);
      });
  };
}
