import { HydraEntity } from '../../utils/hydra';
import { Translatable } from '../../utils/translation';
import { PostCategoryType } from '../posts/types';

export type Category = Categories;

export const categoryItemTypes = <const>['clothes', 'shoes', 'bra', 'fashion_accessory'];
export const categoryItemTypesWithSize = categoryItemTypes;
export const categoryItemTypesWithoutUniverse = ['bra', 'fashion_accessory'];
export type CategoryItemType = (typeof categoryItemTypes)[number];

export interface Discriminant extends HydraEntity, Translatable {
  name: string;
}

export interface Categories extends HydraEntity {
  name: string;
  translations: {
    en: string;
    fr: string;
    ar: string;
    el: string;
    fa: string;
    fi: string;
    it: string;
    pl: string;
    pt: string;
    ur: string;
  };
  parent: Record<string, never> | null;
  type: PostCategoryType;
  icon: string;
  itemsType: CategoryItemType | null;
  itemMinWeight: number | null;
  itemMaxWeight: number | null;
  itemDefaultWeight: number | null;
  itemDefaultPrice: number | null;
  discriminants: Discriminant[];
  overrideParentDiscriminants: boolean;
}

export interface CategoryState {
  categories: Category[];
}
