import { schema } from 'normalizr';

const schemaOptions = { idAttribute: '@id' };

export const user = new schema.Entity('users', {}, schemaOptions);
export const post = new schema.Entity('posts', {}, schemaOptions);
export const postRequest = new schema.Entity('postRequests', {}, schemaOptions);
export const userReview = new schema.Entity('userReviews', {}, schemaOptions);
export const chat = new schema.Entity('chats', {}, schemaOptions);
export const userChat = new schema.Entity('userChats', {}, schemaOptions);
export const chatMessage = new schema.Entity('chatMessages', {}, schemaOptions);
export const notification = new schema.Entity('notifications', {}, schemaOptions);
export const userNotification = new schema.Entity('userNotifications', {}, schemaOptions);

user.define({
  collections: {
    posts: post,
  },
});

post.define({
  createdBy: user,
  currentUserOpenRequest: postRequest,
});

postRequest.define({
  post: post,
  user: user,
  userReviews: userReview,
});

userReview.define({
  createdBy: user,
  reviewedUser: user,
  reviewedPostRequest: postRequest,
});

userChat.define({
  chat: chat,
});

chat.define({
  postRequest: postRequest,
  messages: [chatMessage],
  properties: {
    firstUsers: [user],
    lastMessage: chatMessage,
  },
  newMessages: [chatMessage],
});

userNotification.define({
  notification: notification,
});
