import { AnyAction } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import { analyticsEnabled, isTest } from '../../environment';
import { store } from '../../store';
import * as appActions from '../../store/app/actions';
import * as postRequestsActions from '../../store/postRequests/actions';
import * as postsActions from '../../store/posts/actions';
import * as chatsActions from '../../store/chats/actions';
import * as usersActions from '../../store/users/actions';
import { PostRequestTransition } from '../../store/postRequests/types';
import { FullPost, PartialPost, SearchQueryParameters } from '../../store/posts/types';
import { CurrentUser } from '../../store/users/types';
import { extractId, extractRawId } from '../helpers';
import FacebookPixel, { logFacebookEvent } from './FacebookPixel';
import * as amplitude from './amplitudeAnalytics';
import * as eventsName from './eventsName';
import * as firebase from '../../capacitor/FirebaseAnalytics';
import * as reportsActions from '../../store/reports/actions';
import * as userLogActions from '../logs/userLogs';
import { EntityReference } from '../hydra';

let lastUserId: EntityReference | '' = '';

function logUserConnectIfNew(user: CurrentUser): void {
  const userId = extractId(user);
  if (userId === lastUserId) {
    // No change, returning
    return;
  }

  // New current user id
  lastUserId = userId;
  logUserConnectEvent();
}

async function updateUserProfile(user: CurrentUser): Promise<PromiseSettledResult<void | Promise<void>>[]> {
  return Promise.allSettled([amplitude.setAnalyticsUser(user), firebase.setAnalyticsUser(user)]);
}

async function logUserConnectEvent(): Promise<void> {
  return userLogActions.logUserConnect();
}

async function logAccountCreatedEvent(): Promise<PromiseSettledResult<void | Promise<void>>[]> {
  return Promise.allSettled([logFacebookEvent('CompleteRegistration', { value: 0.0, currency: 'USD' })]);
}

async function logUserLoggedInEvent(): Promise<void> {
  return amplitude.logAmplitudeEvent(eventsName.login);
}

async function logPostCreatedEvent(post: FullPost): Promise<PromiseSettledResult<void | Promise<void>>[]> {
  const { type, categoryType, category } = post;
  const managed = store.getState().whoAmI.whoAmIUser?.managed ? 'true' : 'false';

  return Promise.allSettled([
    logFacebookEvent('InitiateCheckout', { type, categoryType, managed, category }),
    amplitude.logAmplitudeEvent(eventsName.postCreated, { type: type, categoryType: categoryType, category: category }),
    firebase.logFirebaseEvent(eventsName.postCreated, { type: type, categoryType: categoryType, category: category }),
  ]);
}

async function logPostEditEvent(post: FullPost): Promise<void> {
  const { type, category } = post;

  return amplitude.logAmplitudeEvent(eventsName.postUpdate, { category: category, type: type });
}

async function logPostRequestCreatedEvent(post: FullPost): Promise<PromiseSettledResult<void | Promise<void>>[]> {
  const type = post.type;
  const categoryType = post.categoryType;
  const category = post.category;
  return Promise.allSettled([
    logFacebookEvent('Contact', { type, categoryType }),
    amplitude.logAmplitudeEvent(eventsName.postRequestCreated, { type: type, categoryType: categoryType, category: category }),
    firebase.logFirebaseEvent(eventsName.postRequestCreated, { type: type, categoryType: categoryType, category: category }),
  ]);
}

async function logUpdatePostRequestEvent(post: FullPost, transition: PostRequestTransition): Promise<PromiseSettledResult<void | Promise<void>>[] | void> {
  const type = post.type;
  const categoryType = post.categoryType;
  const category = post.category;

  switch (transition) {
    case 'complete' || 'accept_and_complete':
      return Promise.allSettled([
        logFacebookEvent('Purchase', { value: 0.0, currency: 'USD' }),
        logFacebookEvent('Schedule'),
        amplitude.logAmplitudeEvent(eventsName.postRequestCompletedReceiver, { type: type, categoryType: categoryType, category: category }),
        amplitude.logAmplitudeEvent(eventsName.postRequestCompletedGiver, { type: type, categoryType: categoryType, category: category }),
        firebase.logFirebaseEvent(eventsName.postRequestCompletedReceiver, { type: type, categoryType: categoryType, category: category }),
        firebase.logFirebaseEvent(eventsName.postRequestCompletedGiver, { type: type, categoryType: categoryType, category: category }),
      ]);
    case 'accept':
      return Promise.allSettled([
        amplitude.logAmplitudeEvent(eventsName.postRequestBooked, { type: type, categoryType: categoryType, category: category }),
        firebase.logFirebaseEvent(eventsName.postRequestBooked, { type: type, categoryType: categoryType, category: category }),
      ]);
    case 'reject':
      return Promise.allSettled([
        amplitude.logAmplitudeEvent(eventsName.postRequestRejected, { type: type, categoryType: categoryType, category: category }),
        firebase.logFirebaseEvent(eventsName.postRequestRejected, { type: type, categoryType: categoryType, category: category }),
      ]);
    case 'cancel':
      return Promise.allSettled([
        amplitude.logAmplitudeEvent(eventsName.postRequestCanceled, { type: type, categoryType: categoryType, category: category }),
        firebase.logFirebaseEvent(eventsName.postRequestCanceled, { type: type, categoryType: categoryType, category: category }),
      ]);
  }
}

async function logMessageSentEvent(): Promise<void> {
  return logFacebookEvent('AddToCart');
}

async function logFavoriteUserEvent(): Promise<void> {
  return amplitude.logAmplitudeEvent(eventsName.follow);
}

async function logCreateUserReportEvent(): Promise<void> {
  return amplitude.logAmplitudeEvent(eventsName.reportUser);
}

async function logCreatePostReportEvent(): Promise<void> {
  return amplitude.logAmplitudeEvent(eventsName.reportPost);
}

export async function sendSearchLog(searchParams: SearchQueryParameters): Promise<void> {
  return amplitude.logAmplitudeEvent(eventsName.search, {
    type: searchParams.postType,
    location: searchParams?.locationAddress?.formatted,
    object: searchParams.categorySelection.object.isSelected,
    service: searchParams.categorySelection.service.isSelected,
    categoryObject: searchParams.categorySelection?.object?.category?.name,
    categoryService: searchParams.categorySelection?.service?.category?.name,
  });
}

export async function sendPostPageViewLog(post: FullPost | PartialPost | undefined): Promise<PromiseSettledResult<void | Promise<void>>[] | Promise<void>> {
  if (!post) {
    return;
  }

  return Promise.allSettled([
    amplitude.logAmplitudeEvent(eventsName.postPageViewed, {
      type: post.type,
      category: 'category' in post && post.category ? extractRawId(post.category) : null,
      categoryType: post.categoryType,
    }),
    firebase.logFirebaseEvent(eventsName.postPageViewed, {
      type: post.type,
      category: 'category' in post && post.category ? extractRawId(post.category) : null,
      categoryType: post.categoryType,
    }),
  ]);
}

export async function sendReviewLog(isWriteReview: boolean, userIsRequester: boolean, comment: string | undefined): Promise<PromiseSettledResult<void | Promise<void>>[]> {
  if (!isWriteReview) {
    return Promise.allSettled([
      amplitude.logAmplitudeEvent(eventsName.replyRecommendation, { author: userIsRequester ? 'receiver' : 'giver', answer: !comment || 0 !== comment.length }),
      firebase.logFirebaseEvent(eventsName.replyRecommendation, { author: userIsRequester ? 'receiver' : 'giver', answer: !comment || 0 !== comment.length }),
    ]);
  }
  return Promise.allSettled([
    amplitude.logAmplitudeEvent(eventsName.writeReview, { author: userIsRequester ? 'receiver' : 'giver' }),
    firebase.logFirebaseEvent(eventsName.writeReview, { author: userIsRequester ? 'receiver' : 'giver' }),
  ]);
}

export async function sendProfilePictureUploadedLog(): Promise<void> {
  return amplitude.logAmplitudeEvent(eventsName.profilePictureUploaded);
}

export async function sendProfilePageViewedLog(): Promise<PromiseSettledResult<void | Promise<void>>[]> {
  return Promise.allSettled([amplitude.logAmplitudeEvent(eventsName.profilePageViewed), firebase.logFirebaseEvent(eventsName.profilePageViewed)]);
}

export async function sendNotificationAuthorizationLog(activate: boolean): Promise<PromiseSettledResult<void | Promise<void>>[]> {
  return Promise.allSettled([amplitude.logAmplitudeEvent(eventsName.pushNotificationAuthorization, { activate }), firebase.logFirebaseEvent(eventsName.pushNotificationAuthorization, { activate })]);
}

export async function sendRegisterCompletedLog(): Promise<PromiseSettledResult<void | Promise<void>>[]> {
  return Promise.allSettled([userLogActions.logUserRegister(), amplitude.logAmplitudeEvent(eventsName.signUpComplete), firebase.logFirebaseEvent(eventsName.signUpComplete)]);
}

export async function sendOpenMapLog(): Promise<void> {
  return amplitude.logAmplitudeEvent(eventsName.openMap);
}

export async function sendSocialBannerClickedLog(type: string): Promise<void> {
  return amplitude.logAmplitudeEvent(eventsName.socialBannerClicked, { bannerId: type });
}

export async function dispatchAnalytics(action: ActionType<AnyAction>): Promise<void | PromiseSettledResult<void | Promise<void>>[]> {
  switch (action.type) {
    case getType(appActions.fetchLoggedUserAction.success): {
      logUserConnectIfNew(action.payload);
      return updateUserProfile(action.payload);
    }
    case getType(appActions.userRegisterAction.success):
    case getType(appActions.userManagedRegisterAction.success):
      return logAccountCreatedEvent();
    case getType(appActions.setIsFullyLoggedInAction):
      return logUserLoggedInEvent();
    case getType(postsActions.createPostAction.success):
      return logPostCreatedEvent(action.payload);
    case getType(postsActions.editPostAction.success):
      return logPostEditEvent(action.payload);
    case getType(postRequestsActions.createPostRequestAction.success):
      return logPostRequestCreatedEvent(action.payload);
    case getType(postRequestsActions.cancelPostRequestAction.success):
    case getType(postRequestsActions.updatePostRequestStateAction.success):
      return logUpdatePostRequestEvent(action.payload.post, action.payload.transition);
    case getType(chatsActions.createChatMessageAction.success):
      return logMessageSentEvent();
    case getType(usersActions.addFavoriteUserAction.success):
      return logFavoriteUserEvent();
    case getType(reportsActions.createUserReportAction.success):
      return logCreateUserReportEvent();
    case getType(reportsActions.createPostReportAction.success):
      return logCreatePostReportEvent();
    case getType(appActions.setUserAreaAction):
      return userLogActions.logUserAreaChange(action.payload);
  }
}

export async function logRouteChangedEvent(path: string): Promise<PromiseSettledResult<void | Promise<void>>[] | Promise<void>> {
  if (new RegExp('\\/posts\\/[\\w-]+$').test(path)) {
    return logFacebookEvent('AddToWishlist');
  }
  if (new RegExp('\\/notification_redirection\\/[\\w-]+$').test(path)) {
    return amplitude.logAmplitudeEvent(eventsName.notificationOpen);
  }
  if ('/register' === path) {
    return Promise.allSettled([amplitude.logAmplitudeEvent(eventsName.signUpPageViewed), firebase.logFirebaseEvent(eventsName.signUpPageViewed)]);
  }
  if ('/login' === path) {
    return Promise.allSettled([amplitude.logAmplitudeEvent(eventsName.loginPageViewed), firebase.logFirebaseEvent(eventsName.loginPageViewed)]);
  }
  if ('/homepage' === path) {
    return amplitude.logAmplitudeEvent(eventsName.loginSignUpPageViewed);
  }
}

let isFirstAppOpenCached = false;

export function isFirstAppOpen(): boolean {
  if (isFirstAppOpenCached) {
    // It's the first app open, and the localstorage has already been updated
    return true;
  }
  if (localStorage.getItem('firstAppOpen')) {
    return false;
  }
  localStorage.setItem('firstAppOpen', '1');
  isFirstAppOpenCached = true;
  return true;
}

export async function initAnalytics(): Promise<void> {
  if (isTest) {
    return;
  }

  userLogActions.init();

  if (!analyticsEnabled) {
    return;
  }

  amplitude.initAmplitude();
  FacebookPixel.init();
  firebase.initFirebase();
}
